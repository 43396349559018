import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import useStyles from '../styles';
import GridLayout from 'components/molecules/grid_layout';
import {
  AppTextField,
  AppMultipleSelect,
} from 'components/molecules/form_controls';
import { useDispatch } from 'react-redux';
import {
  useGetCategory,
  useGetPublisherApplication,
} from '../../../redux/reducers/application_detail_reducer/selectors';
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';
import Actions from '../../../redux/reducers/application_detail_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

interface IFormData {
  description: string | null;
  name: string | null;
  blacklist: string[];
}

// add types or interface
const defaultValues = {
  description: null,
  name: null,
  blacklist: [],
};

export default function General() {
  const category = useGetCategory();
  const dispatch = useDispatch();
  const { id } = useSelectUserData();
  const publisherApplication = useGetPublisherApplication();
  const [categoryOption, setCategoryOption] = useState([]);
  const {
    errors,
    control,
    getValues,
    reset,
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues,
  });
  useEffect(() => {
    if (publisherApplication.status === 'Success') {
      console.log(
        publisherApplication.data.Blacklist.split(',')
          .filter((item: string) => item !== '')
          .map((item: string) => parseInt(item))
      );
      reset({
        name: publisherApplication.data.Name,
        description: publisherApplication.data.Description,
        blacklist: publisherApplication.data.Blacklist.split(',')
          .filter((item: string) => item !== '')
          .map((item: string) => parseInt(item)),
      });
    }
  }, [publisherApplication]);
  useEffect(() => {
    if (category.status === 'Success' && category.data) {
      setCategoryOption(
        category.data.map((cat: any) => {
          return {
            id: cat.id,
            label: cat.name,
          };
        })
      );
    }
  }, [category]);
  function onSubmit(data: IFormData) {
    dispatch(
      Actions.updatePublisherApplicationAction({
        userid: id,
        applid: publisherApplication.data.ID,
        name: data.name,
        descr: data.description,
        blacklist: data.blacklist.join(','),
        deleted: 0,
      })
    );
  }
  function handleDelete() {
    dispatch(
      Actions.updatePublisherApplicationAction({
        userid: id,
        applid: publisherApplication.data.ID,
        name: getValues('name'),
        descr: getValues('description'),
        blacklist: getValues('blacklist').join(','),
        deleted: 1,
      })
    );
  }
  return (
    <form
      //   className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'name',
            element: (
              <AppTextField
                variant="outlined"
                margin="normal"
                error={'name' in errors}
                helperText={errors.name && errors.name.message}
                rules={{ require: true }}
                fullWidth
                id="name"
                control={control}
                label={<TranslatedText textMap={Translations.name_label} />}
                autoComplete="name"
                name="name"
              />
            ),
            size: 6,
          },
          {
            id: 'description',
            element: (
              <AppTextField
                variant="outlined"
                margin="normal"
                error={'description' in errors}
                helperText={errors.description && errors.description.message}
                rules={{ require: true }}
                fullWidth
                id="description"
                control={control}
                label={
                  <TranslatedText textMap={Translations.description_label} />
                }
                autoComplete="description"
                name="description"
              />
            ),
            size: 6,
          },
          {
            id: 'category',
            element: (
              <React.Fragment>
                {categoryOption.length !== 0 ? (
                  <AppMultipleSelect
                    variant="outlined"
                    margin="normal"
                    rules={{}}
                    fullWidth
                    options={categoryOption}
                    id="blacklist"
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.category_label} />
                    }
                    autoComplete="blacklist"
                    name="blacklist"
                  />
                ) : null}
              </React.Fragment>
            ),
            size: 6,
          },
          {
            id: 'action',
            element: (
              <GridLayout
                justify="flex-start"
                elements={[
                  {
                    id: 'update application',
                    size: 2,
                    element: (
                      <Button type="submit" variant="outlined" color="primary">
                        <TranslatedText textMap={Translations.update_button} />
                      </Button>
                    ),
                  },
                  {
                    id: 'delete application',
                    size: 2,
                    element: (
                      <Button
                        onClick={handleDelete}
                        variant="outlined"
                        color="primary"
                      >
                        <TranslatedText textMap={Translations.delete_button} />
                      </Button>
                    ),
                  },
                ]}
              />
            ),
            size: 12,
          },
        ]}
      />
    </form>
  );
}
