import React, { useState, useEffect } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import { Tooltip } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { mapping } from '../../country-latlon';
import { useGetCountryClickView } from '../../../../redux/reducers/adv_dashboard_reducer/selectors';
import './styles.css';
const geoUrl = '/assets/images/countries-110m.json';

export default function Map() {
  const [content, setContent] = useState<any>(null);
  const [markerData, setMarkerData] = useState([]);
  const countryClickView = useGetCountryClickView();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [content]);

  useEffect(() => {
    if (countryClickView.status === 'Success') {
      const countryData = countryClickView.data
        .filter((country: any) => {
          const coordinate = mapping.find(
            (item) => item.alpha2 === country.country
          );
          if (coordinate) return true;
          else return false;
        })
        .map((country: any) => {
          const coordinate = mapping.find(
            (item) => item.alpha2 === country.country
          );
          if (coordinate) {
            return {
              ...country,
              ...coordinate,
            };
          }
        });
      setMarkerData(countryData);
    }
  }, [countryClickView]);

  return (
    <div>
      <ComposableMap data-tip="">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo: any) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: '#005fa3',
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
        {markerData.map((country: any) => {
          return (
            <Marker
              key={country.alpha2}
              coordinates={[country?.longitude, country?.latitude]}
              onMouseEnter={() => {
                setContent(
                  <div>
                    <div>Country: {country.country}</div>
                    <div>Total Views: {country?.totalviews}</div>
                    <div>Total Clicks: {country?.totalclicks}</div>
                  </div>
                );
              }}
              onMouseLeave={() => {
                setContent('');
              }}
              style={{
                default: {
                  fill: '#D6D6DA',
                  outline: 'none',
                },
                hover: {
                  fill: '#F53',
                  outline: 'none',
                },
              }}
            >
              <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
              <text
                textAnchor="middle"
                y={15}
                style={{ fontFamily: 'system-ui', fill: '#5D5A6D' }}
              >
                {country.country}
              </text>
            </Marker>
          );
        })}
      </ComposableMap>
      <ReactTooltip>{content}</ReactTooltip>
    </div>
  );
}
