import _ from 'lodash';
import moment from 'moment';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

function colorGenerator() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

function createLineDataset(data: any[], label: string) {
  const sortedDate = _.uniq(
    data
      .sort(function (a: any, b: any) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((item) => item.date)
  );
  const groupedByName = _.groupBy(data, label);

  const result = Object.keys(groupedByName).map((name: string) => {
    const init = new Array(sortedDate.length).fill(0);

    for (var i = 0; i < sortedDate.length; i++) {
      groupedByName[name].forEach((item) => {
        if (item.date === sortedDate[i]) {
          init[i] = item.passengers;
        }
      });
    }
    const color = colorGenerator();
    return {
      label: name,
      data: init,
      format: {
        label: name,
        fill: false,
        borderColor: color,
        lineTension: 0.1, // make the lines without radius
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
      },
    };
  });
  const dataSet = {
    labels: sortedDate,
    data: result,
  };
  return dataSet;
}

function getLineChartConfig(data: any, label: string, title: string) {
  const dataSet = createLineDataset(data, label);

  const LineChartsConfig: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      props: {
        data: dataSet.data,
        labels: dataSet.labels.map((item: string) =>
          moment(item).format('DD-MM-YYYY')
        ),
        chartType: ChartTypesEnum.LINE,
        options: {
          legend: {
            display: true,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
          },
        },
        title: title,
      },
      fetch: null,
    },
  ];
  return LineChartsConfig;
}

export default getLineChartConfig;
