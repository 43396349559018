import React, { useRef } from 'react';
import useStyles from '../../styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { CampaignInfoSteps, LayoutSteps } from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import BasicInfo from '../campaign_info_basic_info_step';
import BudgetSchedule from '../campaign_info_budget_schedule_step';
import Purpose from '../campaign_info_purpose_step';
import Display from '../campaign_info_display_step';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
// import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import reactLogger from 'utils/logger';
import { StepButton } from '@material-ui/core';
import CampaignInfoAll from '../campaign_info_all';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
  handleChangeStep: { (step: number): void };
}

const CampaignInfoView = React.forwardRef((props: IProps, ref) => {
  reactLogger.renderComponent('CampaignInfoView');
  const { view, handleNext, handlePrevious, handleChangeStep } = props;
  const classes = useStyles();
  const activeStep = view.step as number;

  const campaign = CampaignSelectors.useGetCampaignWizardValues();

  return (
    <div>
      {/* <Stepper className={classes.stepper} activeStep={activeStep} nonLinear>
        {LayoutSteps.map(({ id, label }) => (
          <Step key={id}>
            <StepButton onClick={() => handleChangeStep(id)}>
              <TranslatedText textMap={label} />
            </StepButton>
          </Step>
        ))}
      </Stepper> */}
      <TabActions
        view={view}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
      {/* {activeStep === CampaignInfoSteps.BASIC_INFO && (
        <BasicInfo ref={ref} campaign={campaign} />
      )}
      {activeStep === CampaignInfoSteps.BUDGET_SCHEDULE && (
        <BudgetSchedule ref={ref} campaign={campaign} />
      )}
      {activeStep === CampaignInfoSteps.PURPOSE && (
        <Purpose ref={ref} campaign={campaign} />
      )}
      {activeStep === CampaignInfoSteps.DISPLAY && (
        <Display ref={ref} campaign={campaign} />
      )} */}
      {activeStep === CampaignInfoSteps.CAMPAIGN_INFO && (
        <CampaignInfoAll ref={ref} campaign={campaign} />
      )}
    </div>
  );
});

export default CampaignInfoView;
