import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_DSP_LIST: string = typeCreatorV2('DSPReducer', 'SetDSPList');

export const SET_ADD_DSP: string = typeCreatorV2('DSPReducer', 'SetAddDSP');

export const SET_EDIT_DSP: string = typeCreatorV2('DSPReducer', 'SetEditDSP');
export const SET_DELETE_DSP: string = typeCreatorV2(
  'DSPReducer',
  'SetDeleteÏDSP'
);

export const SET_DSP_DETAIL: string = typeCreatorV2(
  'DSPReducer',
  'SetDSPDetail'
);

export const SET_PARALIST_LIST: string = typeCreatorV2(
  'DSPReducer',
  'SetParalistList'
);

export const SET_USER_BY_ROLE: string = typeCreatorV2(
  'DSPReducer',
  'SetUserByRole'
);

export const SET_LIST_DSP_PUBLISHER: string = typeCreatorV2(
  'DSPReducer',
  'SetListDspPublisher'
);

export const SET_CREATE_DSP_PUBLISHER: string = typeCreatorV2(
  'DSPReducer',
  'SetCreateDspPublisher'
);

export const SET_LIST_PUBLISHER_BY_ROLE: string = typeCreatorV2(
  'DSPReducer',
  'SetListPublisherByRole'
);

export const DELETE_DSP_PUBLISHER: string = typeCreatorV2(
  'DSPReducer',
  'DeleteDspPublisher'
);

export const SET_LIST_ADVERTISEMENT_TYPE_DSP: string = typeCreatorV2(
  'DSPReducer',
  'SetListAdvertisementTypeDsp'
);

export const SET_CREATE_ADVERTISEMENT_TYPE_DSP: string = typeCreatorV2(
  'DSPReducer',
  'SetCreateAdvertisementTypeDsp'
);

export const DELETE_ADVERTISEMENT_TYPE_DSP: string = typeCreatorV2(
  'DSPReducer',
  'SetDeleteAdvertisementTypeDsp'
);

export const SET_LIST_ADVERTISEMENT_TYPE: string = typeCreatorV2(
  'DSPReducer',
  'SetListAdvertisementType'
);
