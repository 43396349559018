import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

interface ITransportStationPublisher {
  style?: React.CSSProperties;
  publisher: string;
}
const TransportStationPublisher = (props: ITransportStationPublisher) => {
  const theme = useTheme();
  return (
    <Typography
      style={{
        fontSize: '18px',
        lineHeight: '16px',
        fontWeight: 700,
        color: theme.palette.secondary.main,
        ...props.style,
      }}
    >
      {props.publisher}
    </Typography>
  );
};

export default TransportStationPublisher;
