import { useSelector } from 'react-redux';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface ITransport {
  id: number;
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string;
  sim: string;
  serial: string;
  pin1: string;
  pin2: string;
  wifi: string;
  type: string;
  version: string;
  status: number;
  comments: string;
}

interface ITransportsListOutput {
  status: StateStatus;
  data: ITransport;
}

export const useGetTransport = (): ITransportsListOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.TRANSPORT_REDUCER].Transport
  );
  return data;
};

export const useGetEditTransportStatus = (): StateStatus | null => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.TRANSPORT_REDUCER].editTransport.status
  );
  return status;
};
