import React from 'react';
import useStyles from './styles';
import CardLayout from 'components/app_common/card_layout';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';
import { APP_COLOR_SECONDARY, CARD_DIVIDER } from 'styles/colors';

interface IOption {
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
  header: React.ReactElement;
  body: React.ReactElement;
  headerStyling?: React.CSSProperties;
  bodyStyling?: React.CSSProperties;
  cardStyling?: React.CSSProperties;
}

const CampaignOption = (props: IOption) => {
  const { isSelected, index, onSelectionChange, header, body } = props;
  const theme = useTheme();
  const classes = useStyles();

  const OptionHeader = () => {
    return (
      <React.Fragment>
        {header}
        <CheckCircleOutlineIcon
          className={clsx({
            [classes.checkIcon]: true,
            [classes.checkIconSelected]: isSelected,
          })}
        />
      </React.Fragment>
    );
  };

  return (
    <div
      onClick={() => {
        onSelectionChange(index);
      }}
    >
      <CardLayout
        cardStyling={{
          ...props.cardStyling,
          width: '100%',
          border: 'none',
          cursor: 'pointer',
        }}
        headerStyling={{
          ...props.headerStyling,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingRight: '1rem',

          // borderColor: CARD_DIVIDER(theme),
          border: `1px solid ${CARD_DIVIDER(theme)}`,
          ...(isSelected && {
            backgroundColor: APP_COLOR_SECONDARY({
              darkMode: theme.palette.type === 'dark',
            }),
            border: `1px solid ${APP_COLOR_SECONDARY({
              darkMode: theme.palette.type === 'dark',
            })}`,
            color: 'white',
          }),
        }}
        bodyStyling={{
          ...props.bodyStyling,
          // borderColor: CARD_DIVIDER(theme),
          borderLeft: `1px solid ${CARD_DIVIDER(theme)}`,
          borderRight: `1px solid ${CARD_DIVIDER(theme)}`,
          borderBottom: `1px solid ${CARD_DIVIDER(theme)}`,
          ...(isSelected && {
            borderLeft: `1px solid ${APP_COLOR_SECONDARY({
              darkMode: theme.palette.type === 'dark',
            })}`,
            borderRight: `1px solid ${APP_COLOR_SECONDARY({
              darkMode: theme.palette.type === 'dark',
            })}`,
            borderBottom: `1px solid ${APP_COLOR_SECONDARY({
              darkMode: theme.palette.type === 'dark',
            })}`,
          }),
        }}
        header={<OptionHeader />}
        body={body}
      />
    </div>
  );
};

export default CampaignOption;
