import React from 'react';
import reactLogger from 'utils/logger';
import { useHistory, useParams, withRouter } from 'react-router';
import CampaignTabView from './components/campaign_tab_view';
import useStyles from './styles';
import FullPageContainer from 'components/app_common/toolbar_fullpage_container';
import PageLayout from 'components/app_common/page_layout';
import { useDispatch } from 'react-redux';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import _ from 'lodash';
import NavigationConfig from 'navigation/config';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import LoadingView from 'components/atoms/loading_view';
import { StateStatus } from 'redux/utils/common';

interface IProps {}
const CampaignPage = (props: IProps) => {
  reactLogger.renderPage('Campaign');
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  React.useEffect(() => {
    const id = _.get(params, 'id', null);
    if (!id) history.push(NavigationConfig.campaignsPage().path);

    if (id === 'new') dispatch(CampaignActions.resetCampaignWizardAction());
    else dispatch(CampaignActions.insertCampaignByIdWizardInfoAction(id));

    dispatch(CampaignActions.fetchSegmentsAction());
  }, [dispatch, params]);

  const wizardStatus = CampaignSelectors.useGetCampaignWizardStatus();

  return (
    <React.Fragment>
      <FullPageContainer>
        <PageLayout style={{ height: '100%', width: '100%' }}>
          <div className={classes.pageContainer}>
            <CampaignTabView />
          </div>
        </PageLayout>
      </FullPageContainer>
      {wizardStatus && wizardStatus === StateStatus.Pending && (
        <LoadingView fullScreen />
      )}
    </React.Fragment>
  );
};

export default withRouter(CampaignPage);
