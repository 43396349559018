import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as AirlinesService from '../../../services/api/airlines';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchAirlinesListInput  */

interface FetchAirlinesListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchAirlinesList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AIRLINES_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AIRLINES_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AIRLINES_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchAirlinesListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AirlinesService.fetchAirlinesList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcAirlinesList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewAirlines  */

interface AddNewAirlinesInput {
  iataid: string | null;
  name: string | null;
  code: string | null;
  country: number | null;
  type: number | null;
  publisherid: number | null;
  fetchAirlinesInput: FetchAirlinesListInput;
}

class AddNewAirlines implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_AIRLINES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_AIRLINES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_AIRLINES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewAirlinesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAirlinesInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await AirlinesService.addAirlines(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchAirlinesList().action(fetchAirlinesInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_AIRLINES,
        //   })
        // );
      } catch (error) {
        console.log('AddNewAirlines Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Airlines  */

interface EditAirlinesInput {
  iataid: string | null;
  name: string | null;
  code: string | null;
  country: number | null;
  type: number | null;
  publisherid: number | null;
  fetchAirlinesInput: FetchAirlinesListInput;
}

class EditAirlines implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_AIRLINES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_AIRLINES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_AIRLINES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditAirlinesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAirlinesInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await AirlinesService.editAirlines(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchAirlinesList().action(fetchAirlinesInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_AIRLINES,
        //   })
        // );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Airlines  */

interface ViewAirlinesInput {
  iataid: number;
}

class ViewAirlines implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AIRLINES_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AIRLINES_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AIRLINES_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewAirlinesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AirlinesService.fetchAirlinesDetail({
          iataid: payload.iataid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewAirlines Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchAirlinesListAction: (payload: FetchAirlinesListInput) =>
    new FetchAirlinesList().action(payload),
  addNewAirlinesAction: (payload: AddNewAirlinesInput) =>
    new AddNewAirlines().action(payload),
  editAirlinesAction: (payload: EditAirlinesInput) =>
    new EditAirlines().action(payload),
  viewAirlinesAction: (payload: ViewAirlinesInput) =>
    new ViewAirlines().action(payload),
};
