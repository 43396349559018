import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetPublisherApplication = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_DETAIL_REDUCER].publisherApplication
  );
  return data;
};
export const useGetPublisherApplicationType = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_DETAIL_REDUCER].publisherApplicationType
  );
  return data;
};
export const useGetCategory = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.APPLICATION_DETAIL_REDUCER].category
  );
  return data;
};
export const useGetLocation = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.APPLICATION_DETAIL_REDUCER].location
  );
  return data;
};

export const useGetDialog = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.APPLICATION_DETAIL_REDUCER].dialog
  );
  return data;
};

export const useGetNewPublisherApplicationType = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_DETAIL_REDUCER].newPublisherApplicationType
  );
  return data;
};
export const useGetCurrentPublisherApplicationType = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_DETAIL_REDUCER].currentlisherApplicationType
  );
  return data;
};
export const useGetIsNewPublisherApplicationType = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_DETAIL_REDUCER].isNewApplicationType
  );
  return data;
};
