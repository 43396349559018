import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface UsersListElement {
  id: string;
  firstname: string;
  lastname: string;
  companyname: string;
  email: string;
  password: string;
  passwordsalt: string;
  afiliateid: string;
  active: string;
  deleted: string;
  lastlogindate: string;
  creationdate: string;
  airlineids: string;
  requirepasswordchange: string;
  passwordlastchangedon: string;
  lastmodifiedon: string;
  lastmodifiedby: string;
  corporate: number;
  address: string;
  country: string;
  vat: string;
  phone: string;
  advertiserpersentage: string;
  advertiserfixcpv: string;
}

export interface UsersListData {
  paging: UsersListPaging;
  items: UsersListElement[];
  length?: number;
}

interface UsersListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface UsersistOutput {
  status: StateStatus;
  data: UsersListData | any;
}

export const useGetUsersList = (): UsersistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USERS_REDUCER].UsersList
  );
  return data;
};

/* selector: useGetUsersDetails */
interface UsersDetailsData {
  id: string;
  firstname: string;
  lastname: string;
  companyname: string;
  email: string;
  password: string;
  passwordsalt: string;
  afiliateid: string;
  active: string;
  deleted: string;
  lastlogindate: string;
  creationdate: string;
  airlineids: string;
  requirepasswordchange: string;
  passwordlastchangedon: string;
  lastmodifiedon: string;
  lastmodifiedby: string;
  corporate: number;
  address: string;
  country: string;
  vat: string;
  phone: string;
  advertiserpersentage: string;
  advertiserfixcpv: string;
}

interface UsersDetailsOutput {
  status: StateStatus;
  data: UsersDetailsData | null;
}

export const useGetUsersDetails = (): UsersDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USERS_REDUCER].viewUsers
  );
  return data;
};
