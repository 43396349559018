import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

export interface IInput {
  data: any[];
  labels: any[];
  title: string;
}

function getRevenueConfig(params: IInput) {
  const { data, labels, title } = params;
  const LineArea: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'area',
        height: 350,

        series: [
          {
            name: title,
            data,
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'area',
          },

          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: title,
            align: 'left',
          },
          subtitle: {
            text: title,
            align: 'left',
          },
          xaxis: {
            type: 'datetime',
            categories: labels,
          },
        },
      },
      fetch: null,
    },
  ];
  return LineArea;
}

export default getRevenueConfig;
