import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  campaignAvertiserment: {
    status: StateStatus | null;
    data: any;
  };
  campaignStatus: {
    status: StateStatus | null;
  };
  campaignAllowStatus: {
    status: StateStatus | null;
  };
  addCampaignCategory: {
    status: StateStatus | null;
  };
  connectedPublisher: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  campaignAvertiserment: {
    status: null,
    data: null,
  },
  campaignStatus: {
    status: null,
  },
  campaignAllowStatus: {
    status: null,
  },
  addCampaignCategory: {
    status: null,
  },
  connectedPublisher: {
    status: null,
    data: null,
  },
};

const AdvertisementReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_CAMPAIGNS_ADVERTISEMENT:
      return {
        ...state,
        campaignAvertiserment: {
          ...state.campaignAvertiserment,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.campaignAvertiserment.data
              : action.data,
        },
      };
    case Types.UPDATE_CAMPAIGNS_STATUS:
      return {
        ...state,
        campaignStatus: {
          ...state.campaignStatus,
          status: action.status || null,
        },
      };
    case Types.UPDATE_PUBLISHER_ALLOW_STATUS:
      return {
        ...state,
        campaignAllowStatus: {
          ...state.campaignAllowStatus,
          status: action.status || null,
        },
      };
    case Types.ADD_CAMPAIGNS_CATEGORY:
      return {
        ...state,
        addCampaignCategory: {
          ...state.addCampaignCategory,
          status: action.status || null,
        },
      };
    case Types.SET_CONNECTED_PUBLISHER:
      return {
        ...state,
        connectedPublisher: {
          ...state.connectedPublisher,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.connectedPublisher.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AdvertisementReducer;
