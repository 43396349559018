import Translations from '../../translations';
export enum AdDetailsSteps {
  AD_DETAIL = 0,
  // IMAGE_VIDEO = 1,
}

export const LayoutSteps = [
  {
    id: AdDetailsSteps.AD_DETAIL,
    label: Translations.ad_detail,
  },
  // {
  //   id: AdDetailsSteps.IMAGE_VIDEO,
  //   label: Translations.image_video,
  // },
];
