import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType2 } from '../configuration';

export default function getConfigCTR(data: any) {
  const DashboardBuilderConfigCTR: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: '',
            format: {
              backgroundColor: [
                '#5EE7EA',
                '#FF5E90',
                '#439CDD',
                '#4FCBDF',
                '#FFFF89',
                '#6366F1',
                '#8B5CF6',
                '#EC4899',
                '#F59E0B',
                '#6B7280',
                '#7F1D1D',
                '#047857',
                '#1D4ED8',
                '#BE185D',
                '#F9FAFB',
              ],
              borderWidth: 1,
            },
          },
        ],
        labels: data.labels,
        chartType: ChartTypesEnum.PIE,
        options: OptionsGraphType2(70000),
        title: '',
      },
      fetch: null,
    },
  ];
  return DashboardBuilderConfigCTR;
}
