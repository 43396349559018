//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from 'redux/utils/common';
export const SET_OSS_SCHEMES: string = typeCreatorV2(
  'RegisterReducer',
  'SetOSSSchemes'
);
export const SET_COUNTRIES: string = typeCreatorV2(
  'RegisterReducer',
  'SetCountries'
);
export const REGISTER_USER: string = typeCreatorV2(
  'RegisterReducer',
  'RegisterUser'
);
