import { useSelector } from 'react-redux';
import { ReducerKeys } from 'redux/config';

export const useSelectForgotPassworddStatus = () => {
  return useSelector(
    (state: any) =>
      state[ReducerKeys.FORGOT_PASSWORD_REDUCER].forgotPasswordStatus
  );
};

// export const useSelectResetPassword = () => {
//   return useSelector(
//     (state: any) =>
//       state[ReducerKeys.RESET_PASSWORD_REDUCER].resetPasswordStatus
//   );
// };
