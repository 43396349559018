import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imagesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
  imageContainer: {
    height: '120px',
    width: '120px',
    marginRight: '.5rem',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    borderRadius: '1rem',
    objectFit: 'cover',
  },
  infoTitle: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  formContainer: {
    width: '80%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
