import React from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import { Container, Grid, makeStyles } from '@material-ui/core';
import ProfileDetails from './components/profile_details';
import ProfileSecurity from './components/profile_security';
import Profile from './components/profile';
import { useDispatch } from 'react-redux';
import UserProfileActions from './store/actions';
import reactLogger from 'utils/logger';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import TabsPanel from 'components/molecules/tabs_panel';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';
import TransportTabView from 'scenes/campaign_page/components/transport_view';
// import TransportTabView from '../campaign_page/components/transport_view';
// import * as AuthSelectors from '../../redux/reducers/gl_auth_reducer/selectors';

interface IProps {}

const ProfilePage = (props: IProps) => {
  reactLogger.renderPage('ProfilePage');
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UserProfileActions.fetchUserProfileAction());
    dispatch(UserProfileActions.fetchUserProfileCountriesAction());
  }, [dispatch]);

  return (
    <Container maxWidth="lg">
      <Grid spacing={5} container>
        <Grid item lg={4} md={6} xs={12}>
          <Profile />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <TabsPanel
            tabsProps={{
              variant: 'fullWidth',
            }}
            elementsConfig={[
              {
                id: 'profileDetails',
                content: <ProfileDetails />,
                title: (
                  <TranslatedText
                    defaultText={'Details'}
                    textMap={Translations.tab_details}
                  />
                ),
              },
              {
                id: 'profileSecurity',
                content: <ProfileSecurity />,
                title: (
                  <TranslatedText
                    defaultText={'Security'}
                    textMap={Translations.tab_security}
                  />
                ),
              },
            ]}
            onChange={() => {}}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const Page = withRouter(ProfilePage);
export default withReducer(ReducerKeys.USER_PROFILE_REDUCER, { unmount: true })(
  Page
);
