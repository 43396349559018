import React, { useRef } from 'react';
import { Box, Drawer, IconButton } from '@material-ui/core';
// import useOutsideClick from 'hooks/useOutsideClick';
import _ from 'lodash';

import useStyles from './styles';
import useOutsideClick from 'custom_hooks/useOutsideClick';
import { useDispatch } from 'react-redux';
import { LastPage } from '@material-ui/icons';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
}

function ViewTransportStationStistics(props: IProps) {
  // const dispatch = useDispatch();
  // const { id } = props;
  const refBox = useRef(null);
  useOutsideClick(refBox, () => props.handleClose());

  return (
    <Drawer anchor="right" open={props.isOpen}>
      <Box
        {...{ ref: refBox }}
        display="flex"
        minWidth="50vw"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Header handleClose={props.handleClose} />
      </Box>
    </Drawer>
  );
}

interface IHeaderProps {
  handleClose: () => void;
}

function Header(props: IHeaderProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Box
        width="30%"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        <IconButton
          onClick={() => {
            props.handleClose();
          }}
        >
          <LastPage />
        </IconButton>
      </Box>
    </Box>
  );
}

export default ViewTransportStationStistics;
