import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  countryClickView: {
    status: StateStatus | null;
    data: any;
  };
  revenueAreaChart: {
    status: StateStatus | null;
    data: any;
  };
  fillrateBarChart: {
    status: StateStatus | null;
    data: any;
  };
  revenueBarChart: {
    status: StateStatus | null;
    data: any;
  };
  impressionAreaChart: {
    status: StateStatus | null;
    data: any;
  };
  campaignClickView: {
    status: StateStatus | null;
    data: any;
  };
  osClickView: {
    status: StateStatus | null;
    data: any;
  };
  fillrateByCountry: {
    status: StateStatus | null;
    data: any;
  };
  totalSpentByDestairport: {
    status: StateStatus | null;
    data: any;
  };
  filterList: {
    status: StateStatus | null;
    data: any;
  };
  userList: {
    status: StateStatus | null;
    data: any;
  };
  busList: {
    status: StateStatus | null;
    data: any;
  };
  topResults: {
    status: StateStatus | null;
    data: any;
  };
  top5ResultsByStation: {
    status: StateStatus | null;
    data: any;
  };
  top5Stations: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  countryClickView: {
    status: null,
    data: null,
  },
  revenueAreaChart: {
    status: null,
    data: null,
  },
  fillrateBarChart: {
    status: null,
    data: null,
  },
  revenueBarChart: {
    status: null,
    data: null,
  },
  impressionAreaChart: {
    status: null,
    data: null,
  },
  campaignClickView: {
    status: null,
    data: null,
  },
  osClickView: {
    status: null,
    data: null,
  },
  fillrateByCountry: {
    status: null,
    data: null,
  },
  totalSpentByDestairport: {
    status: null,
    data: null,
  },
  filterList: {
    status: null,
    data: null,
  },
  userList: {
    status: null,
    data: null,
  },
  busList: {
    status: null,
    data: null,
  },
  topResults: {
    status: null,
    data: null,
  },
  top5ResultsByStation: {
    status: null,
    data: null,
  },
  top5Stations: {
    status: null,
    data: null,
  },
};

const PubDashboardReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_PUB_COUNTRY_CLICK_VIEW:
      return {
        ...state,
        countryClickView: {
          ...state.countryClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.countryClickView.data
              : action.data,
        },
      };
    case Types.SET_GENERAL_PUBLISHER_REVENUE_AREA_CHART:
      return {
        ...state,
        revenueAreaChart: {
          ...state.revenueAreaChart,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.revenueAreaChart.data
              : action.data,
        },
      };
    case Types.SET_GENERAL_PUBLISHER_FILLRATE_BAR_CHART:
      return {
        ...state,
        fillrateBarChart: {
          ...state.fillrateBarChart,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.fillrateBarChart.data
              : action.data,
        },
      };
    case Types.SET_GENERAL_PUBLISHER_REVENUE_BAR_CHART:
      return {
        ...state,
        revenueBarChart: {
          ...state.revenueBarChart,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.revenueBarChart.data
              : action.data,
        },
      };
    case Types.SET_GENERAL_PUBLISHER_IMPRESSION_AREA_CHART:
      return {
        ...state,
        impressionAreaChart: {
          ...state.impressionAreaChart,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.impressionAreaChart.data
              : action.data,
        },
      };
    case Types.SET_PUBLISHER_CAMPAIGN_CLICK_VIEW:
      return {
        ...state,
        campaignClickView: {
          ...state.campaignClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.campaignClickView.data
              : action.data,
        },
      };
    case Types.SET_PUBLISHER_OS_CLICK_VIEW:
      return {
        ...state,
        osClickView: {
          ...state.osClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.osClickView.data
              : action.data,
        },
      };
    case Types.SET_PUBLISHER_FILLRATE_BY_COUNTRY:
      return {
        ...state,
        fillrateByCountry: {
          ...state.fillrateByCountry,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.fillrateByCountry.data
              : action.data,
        },
      };
    case Types.SET_PUBLISHER_TOTAL_SPENT_BY_DESTAIRPORT:
      return {
        ...state,
        totalSpentByDestairport: {
          ...state.totalSpentByDestairport,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalSpentByDestairport.data
              : action.data,
        },
      };
    case Types.SET_FILTER_LIST:
      return {
        ...state,
        filterList: {
          ...state.filterList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.filterList.data : action.data,
        },
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };
    case Types.SET_BUS_LIST:
      return {
        ...state,
        busList: {
          ...state.busList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.busList.data : action.data,
        },
      };
    case Types.SET_TOP_RESULTS:
      return {
        ...state,
        topResults: {
          ...state.topResults,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.topResults.data : action.data,
        },
      };
    case Types.SET_TOP_5_RESULTS_BY_STATION:
      return {
        ...state,
        top5ResultsByStation: {
          ...state.top5ResultsByStation,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top5ResultsByStation.data
              : action.data,
        },
      };
    case Types.SET_TOP_5_STATIONS:
      return {
        ...state,
        top5Stations: {
          ...state.top5Stations,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top5Stations.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default PubDashboardReducer;
