import { Languages } from '../../config';

const Translations = {
  wallet_balance: {
    [Languages.English]: 'Wallet Balance',
    [Languages.Greek]: 'Πορτοφόλι Υπόλοιπο',
    [Languages.Russian]: 'Кошельке',
  },
  search: {
    [Languages.English]: 'Search History',
    [Languages.Greek]: 'Ιστορικό αναζήτησης',
    [Languages.Russian]: 'История поиска',
  },
  user_search: {
    [Languages.English]: 'User',
    [Languages.Greek]: 'Χρήστης',
    [Languages.Russian]: 'Пользователь',
  },
  button_search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  campaign_label: {
    [Languages.English]: 'Campaign',
    [Languages.Greek]: 'καμπάνια',
    [Languages.Russian]: 'Кампания',
  },
  clientname_label: {
    [Languages.English]: 'Client Name',
    [Languages.Greek]: 'Όνομα Πελάτη',
    [Languages.Russian]: 'имя клиента',
  },
  start_date_label: {
    [Languages.English]: 'Start Date',
    [Languages.Greek]: 'Ημερομηνία έναρξης',
    [Languages.Russian]: 'Дата начала',
  },
  end_date_label: {
    [Languages.English]: 'End Date',
    [Languages.Greek]: 'Ημερομηνία λήξης',
    [Languages.Russian]: 'Дата окончания',
  },
  clear_filter: {
    [Languages.English]: 'Clear Filter',
    [Languages.Greek]: 'Απαλοιφή φίλτρου',
    [Languages.Russian]: 'Очистить фильтр',
  },
};

export default Translations;
