import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Languages, AuthRoles } from '../../config';
import TranslatedText from '../../components/atoms/translated_text';
import Actions from '../../redux/reducers/application_reducer/actions';
import Translations from './translations';

function ActionsBlock(props: any) {
  const history = useHistory();

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 10, textTransform: 'capitalize' }}
        onClick={() => history.push(`/transport?id=${props.dataid.id}`)}
      >
        <TranslatedText textMap={Translations.edit} />
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: 'ID',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Publisher',
    id: 'pubCode',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Code',
    id: 'code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Lat',
    id: 'lat',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Lon',
    id: 'lon',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Ip',
    id: 'ip',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        <a href={'http://' + cellValue}>{cellValue}</a>
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Sim',
    id: 'sim',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Serial',
    id: 'serial',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Pin1',
    id: 'pin1',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Pin2',
    id: 'pin2',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Wifi',
    id: 'wifi',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Type',
    id: 'type',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Version',
    id: 'version',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Status',
    id: 'status',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Comments',
    id: 'comments',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'asc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
