import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  publisherApplication: {
    status: StateStatus | null;
    data: any;
  };
  persistPublisherApplication: {
    status: StateStatus | null;
    data: any;
  };
  addPublisherApplication: {
    status: StateStatus | null;
  };
  isOpen: boolean;
}

const initialState: IState = {
  publisherApplication: {
    status: null,
    data: null,
  },
  persistPublisherApplication: {
    status: null,
    data: null,
  },
  isOpen: false,
  addPublisherApplication: {
    status: null,
  },
};

const ApplicationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_PUBLISHER_APPLICATION:
      return {
        ...state,
        publisherApplication: {
          ...state.publisherApplication,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.publisherApplication.data
              : action.data,
        },
      };
    case Types.SET_PERSIST_PUBLISHER_APPLICATION:
      return {
        ...state,
        persistPublisherApplication: {
          ...state.persistPublisherApplication,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.persistPublisherApplication.data
              : action.data,
        },
      };
    case Types.SET_ADD_PUBLISHER_APPLICATION:
      return {
        ...state,
        addPublisherApplication: {
          ...state.addPublisherApplication,
          status: action.status || null,
        },
      };
    case Types.SET_OPEN_DIALOG:
      return {
        ...state,
        isOpen: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApplicationReducer;
