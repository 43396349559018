import { makeStyles } from '@material-ui/core/styles';
import { APP_COLOR_SECONDARY } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: APP_COLOR_SECONDARY({ darkMode: theme.palette.type === 'dark' }),
  },
  checkIconSelected: {
    color: 'white',
  },
}));

export default useStyles;
