import React, { useState } from 'react';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import { CampaignGenders, IGender } from './configuration';
import { Box, useTheme } from '@material-ui/core';
import CampaignOption from 'components/app_common/option';
import clsx from 'clsx';
import ICampaignInfoWizard from 'config/campaign_wizard';
import GridLayout from 'components/molecules/grid_layout';
import CardLayout from 'components/app_common/card_layout';
import Translations from '../../translations';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const Gender = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Array<number>>([]);
  const { campaign } = props;

  React.useEffect(() => {
    if (!campaign.gender) {
      return setSelected(new Array<number>());
    } else {
      let selectedGenders: Array<number> = new Array<number>();
      for (let gender of campaign.gender)
        for (let index in CampaignGenders)
          if (CampaignGenders[index].code === gender) {
            selectedGenders.push(Number.parseInt(index));
            break;
          }
      setSelected(selectedGenders);
    }
  }, [campaign]);

  React.useEffect(() => {
    let selectedGenders: Array<string> = new Array<string>();
    for (let gender of selected)
      selectedGenders.push(CampaignGenders[gender].code);

    ref.current = selectedGenders;
  }, [selected]);

  function onSelectionChange(index: number) {
    const arrayIndex = selected.indexOf(index);
    if (arrayIndex >= 0) selected.splice(arrayIndex, 1);
    else selected.push(index);
    setSelected([...selected]);
  }

  return (
    <div className={classes.pageContainer}>
      <CardLayout
        cardStyling={{ width: '100%' }}
        body={
          <React.Fragment>
            <Box
              style={{
                paddingLeft: '4px',
                fontWeight: 'bold',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              <TranslatedText textMap={Translations.gender} />
            </Box>
            <GridLayout
              justify="center"
              elements={CampaignGenders.map(
                (gender: IGender, index: number) => ({
                  id: index.toString(),
                  element: (
                    <div key={index} className={classes.optionItem}>
                      <GenderOption
                        key={index}
                        gender={gender}
                        isSelected={selected.includes(index)}
                        index={index}
                        onSelectionChange={onSelectionChange}
                      />
                    </div>
                  ),
                  size: 4,
                  md: 12,
                })
              )}
            />
          </React.Fragment>
        }
      />
    </div>
  );
});

interface IGenderProps {
  gender: IGender;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const GenderOption = (props: IGenderProps) => {
  const { gender, isSelected, index, onSelectionChange } = props;
  const theme = useTheme();
  const classes = useStyles();
  const GenderOptionHeader = () => {
    return <TranslatedText textMap={gender.name} />;
  };

  const GenderOptionBody = () => {
    return (
      <div
        className={clsx({
          [classes.optionItemBody]: true,
          [classes.optionItemBodySelected]: isSelected,
        })}
      >
        {gender.image}
      </div>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<GenderOptionHeader />}
      body={<GenderOptionBody />}
    />
  );
};

export default Gender;
