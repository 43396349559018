import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';

export default function Header(props: any) {
  const dispatch = useDispatch();
  const handleCreate = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE,
        params: {},
      })
    );
  };
  return (
    <Box
      display="flex"
      alignItems={'center'}
      justifyContent="space-between"
      fontWeight={500}
      padding={1}
    >
      <Typography variant="h5">
        {/* <TranslatedText textMap={Translations.main} /> */}
        Advertisement type Page
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        style={{
          marginLeft: 15,
          textTransform: 'none',
        }}
      >
        {/* <TranslatedText textMap={Translations.create} /> */}
        Add new
      </Button>
    </Box>
  );
}
