import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { IState } from './interfaces';

export const useGetParaList = () => {
  const { list, status } = <IState>(
    useShallowEqualSelector((state: any) => state[ReducerKeys.PARALIST_REDUCER])
  );
  return {
    fetch: (groupCode: string) =>
      groupCode ? list?.filter((l) => l.groupcode == groupCode) : list,
    paralist_status: status,
  };
};
