import React, { useState } from 'react';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import {
  CampaignDevices,
  IDevice,
  CampaignMobileEnvironments,
  IMobileEnvironment,
  CampaignOperatingSystems,
  IOperatingSystem,
} from './configuration';
import { Box, useTheme } from '@material-ui/core';
import CampaignOption from 'components/app_common/option';
import clsx from 'clsx';
import ICampaignInfoWizard from 'config/campaign_wizard';
import CardLayout from 'components/app_common/card_layout';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const AdvancedOptions = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  const { campaign } = props;
  const [selectedDevices, setSelectedDevices] = useState<Array<number>>([]);
  const [selectedMobileEnvironments, setSelectedMobileEnvironments] = useState<
    Array<number>
  >([]);
  const [selectedOperatingSystems, setSelectedOperatingSystems] = useState<
    Array<number>
  >([]);

  /** Initialize from campaign values */
  React.useEffect(() => {
    /** Devices */
    if (!campaign.device) {
      return setSelectedDevices(new Array<number>());
    } else {
      let tempSelectedDevices: Array<number> = new Array<number>();
      for (let device of campaign.device)
        for (let index in CampaignDevices)
          if (CampaignDevices[index].code === device) {
            tempSelectedDevices.push(Number.parseInt(index));
            break;
          }
      setSelectedDevices(tempSelectedDevices);
    }
    /** Mobile Environments */
    if (!campaign.mobile_environment) {
      return setSelectedMobileEnvironments(new Array<number>());
    } else {
      let tempSelectedMobileEnvironments: Array<number> = new Array<number>();
      for (let mobile_environment of campaign.mobile_environment)
        for (let index in CampaignMobileEnvironments)
          if (CampaignMobileEnvironments[index].code === mobile_environment) {
            tempSelectedMobileEnvironments.push(Number.parseInt(index));
            break;
          }
      setSelectedMobileEnvironments(tempSelectedMobileEnvironments);
    }
    /** Operating Systems */
    if (!campaign.operating_system) {
      return setSelectedOperatingSystems(new Array<number>());
    } else {
      let tempSelectedOperatingSystems: Array<number> = new Array<number>();
      for (let os of campaign.operating_system)
        for (let index in CampaignOperatingSystems)
          if (CampaignOperatingSystems[index].code === os) {
            tempSelectedOperatingSystems.push(Number.parseInt(index));
            break;
          }
      setSelectedOperatingSystems(tempSelectedOperatingSystems);
    }
  }, [campaign]);

  /** Set ref for campaign checks & data save */
  React.useEffect(() => {
    /** Devices */
    let tempSelectedDevices: Array<string> = new Array<string>();
    for (let device of selectedDevices)
      tempSelectedDevices.push(CampaignDevices[device].code);
    /** Mobile Environments */
    let tempSelectedMobileEnvironments: Array<string> = new Array<string>();
    for (let mobileEnvironment of selectedMobileEnvironments)
      tempSelectedMobileEnvironments.push(
        CampaignMobileEnvironments[mobileEnvironment].code
      );
    /** Operating Systems */
    let tempSelectedOperatingSystem: Array<string> = new Array<string>();
    for (let os of selectedOperatingSystems)
      tempSelectedOperatingSystem.push(CampaignOperatingSystems[os].code);

    ref.current = {
      device: tempSelectedDevices,
      mobile_environment: tempSelectedMobileEnvironments,
      operating_system: tempSelectedOperatingSystem,
    };
  }, [selectedDevices, selectedMobileEnvironments, selectedOperatingSystems]);

  /** Add or remove option based on selection */
  function onDeviceSelectionChange(index: number) {
    const arrayIndex = selectedDevices.indexOf(index);
    if (arrayIndex >= 0) selectedDevices.splice(arrayIndex, 1);
    else selectedDevices.push(index);
    setSelectedDevices([...selectedDevices]);
  }

  function onMobileEnvironmentSelectionChange(index: number) {
    const arrayIndex = selectedMobileEnvironments.indexOf(index);
    if (arrayIndex >= 0) selectedMobileEnvironments.splice(arrayIndex, 1);
    else selectedMobileEnvironments.push(index);
    setSelectedMobileEnvironments([...selectedMobileEnvironments]);
  }

  function onOperatingSystemSelectionChange(index: number) {
    const arrayIndex = selectedOperatingSystems.indexOf(index);
    if (arrayIndex >= 0) selectedOperatingSystems.splice(arrayIndex, 1);
    else selectedOperatingSystems.push(index);
    setSelectedOperatingSystems([...selectedOperatingSystems]);
  }

  return (
    <div className={classes.pageContainer}>
      <CardLayout
        cardStyling={{ width: '100%', marginBottom: '1rem' }}
        body={
          <React.Fragment>
            <Box
              style={{
                paddingLeft: '4px',
                fontWeight: 'bold',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              <TranslatedText textMap={Translations.device} />
            </Box>
            <GridLayout
              justify="center"
              elements={CampaignDevices.map(
                (device: IDevice, index: number) => ({
                  id: index.toString(),
                  element: (
                    <div key={index} className={classes.optionItem}>
                      <DeviceOption
                        key={index}
                        device={device}
                        isSelected={selectedDevices.includes(index)}
                        index={index}
                        onSelectionChange={onDeviceSelectionChange}
                      />
                    </div>
                  ),
                  size: 4,
                  md: 12,
                })
              )}
            />
          </React.Fragment>
        }
      />
      <CardLayout
        cardStyling={{ width: '100%', marginBottom: '1rem' }}
        body={
          <React.Fragment>
            <Box
              style={{
                paddingLeft: '4px',
                fontWeight: 'bold',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              <TranslatedText textMap={Translations.mobile_environment} />
            </Box>
            <GridLayout
              justify="center"
              elements={CampaignMobileEnvironments.map(
                (mobileEnvironment: IMobileEnvironment, index: number) => ({
                  id: index.toString(),
                  element: (
                    <div key={index} className={classes.optionItem}>
                      <MobileEnvironmentOption
                        key={index}
                        mobileEnvironment={mobileEnvironment}
                        isSelected={selectedMobileEnvironments.includes(index)}
                        index={index}
                        onSelectionChange={onMobileEnvironmentSelectionChange}
                      />
                    </div>
                  ),
                  size: 4,
                  md: 12,
                })
              )}
            />
          </React.Fragment>
        }
      />
      <CardLayout
        cardStyling={{ width: '100%' }}
        body={
          <React.Fragment>
            <Box
              style={{
                paddingLeft: '4px',
                fontWeight: 'bold',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              <TranslatedText textMap={Translations.operating_system} />
            </Box>
            <GridLayout
              justify="center"
              elements={CampaignOperatingSystems.map(
                (operatingSystem: IOperatingSystem, index: number) => ({
                  id: index.toString(),
                  element: (
                    <div key={index} className={classes.optionItem}>
                      <OperatingSystemOption
                        key={index}
                        operatingSystem={operatingSystem}
                        isSelected={selectedOperatingSystems.includes(index)}
                        index={index}
                        onSelectionChange={onOperatingSystemSelectionChange}
                      />
                    </div>
                  ),
                  size: 4,
                  md: 12,
                })
              )}
            />
          </React.Fragment>
        }
      />
    </div>
  );
});

interface IDeviceProps {
  device: IDevice;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const DeviceOption = (props: IDeviceProps) => {
  const { device, isSelected, index, onSelectionChange } = props;
  const theme = useTheme();
  const classes = useStyles();

  const DeviceOptionHeader = () => {
    return <TranslatedText textMap={device.name} />;
  };

  const DeviceOptionBody = () => {
    return (
      <div
        className={clsx({
          [classes.optionItemBody]: true,
          [classes.optionItemBodySelected]: isSelected,
        })}
      >
        {device.image}
      </div>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<DeviceOptionHeader />}
      body={<DeviceOptionBody />}
    />
  );
};

interface IMobileEnvironmentProps {
  mobileEnvironment: IMobileEnvironment;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const MobileEnvironmentOption = (props: IMobileEnvironmentProps) => {
  const { mobileEnvironment, isSelected, index, onSelectionChange } = props;
  const theme = useTheme();
  const classes = useStyles();

  const MobileEnvironmentOptionHeader = () => {
    return <TranslatedText textMap={mobileEnvironment.name} />;
  };

  const MobileEnvironmentOptionBody = () => {
    return (
      <div
        className={clsx({
          [classes.optionItemBody]: true,
          [classes.optionItemBodySelected]: isSelected,
        })}
      >
        {mobileEnvironment.image}
      </div>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<MobileEnvironmentOptionHeader />}
      body={<MobileEnvironmentOptionBody />}
    />
  );
};

interface IOperatingSystemProps {
  operatingSystem: IOperatingSystem;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const OperatingSystemOption = (props: IOperatingSystemProps) => {
  const { operatingSystem, isSelected, index, onSelectionChange } = props;
  const theme = useTheme();
  const classes = useStyles();

  const OperatingSystemOptionHeader = () => {
    return <TranslatedText textMap={operatingSystem.name} />;
  };

  const OperatingSystemOptionBody = () => {
    return (
      <div
        className={clsx({
          [classes.optionItemBody]: true,
          [classes.optionItemBodySelected]: isSelected,
        })}
      >
        {operatingSystem.image}
      </div>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<OperatingSystemOptionHeader />}
      body={<OperatingSystemOptionBody />}
    />
  );
};

export default AdvancedOptions;
