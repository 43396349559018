import React from 'react';
import { Button, Chip, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import {
  selectExlusiveByPublisher,
  selectPublisher,
} from 'redux/reducers/publisher_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';
import NavigationConfig from 'navigation/config';
import { useHistory } from 'react-router';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { ObjectToQueryString } from 'redux/utils/common';

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = (e: any) => {
    history.push(
      NavigationConfig.publisherEditPage(`${props?.dataid?.id}`).path
    );
  };

  const handleShowExclusivePublisher = (e: any) => {
    const queryString = ObjectToQueryString({
      name: props?.dataid?.name?.trim(),
    });
    history.push(
      `${
        NavigationConfig.publisherExclusivePage(`${props?.dataid?.id}`).path
      }?${queryString}`
    );
  };
  const openConnectionPushlisher = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_PUBLISHER_CONNECTION,
        params: { data: props.dataid },
      })
    );
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleEdit}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
        }}
      >
        Edit
      </Button>
      <Button
        onClick={handleShowExclusivePublisher}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
        }}
      >
        Exclusivity
      </Button>
      <Button
        onClick={openConnectionPushlisher}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
        }}
      >
        Publisher Connections
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: <TranslatedText textMap={Translations.name} />,
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.code} />,
    id: 'code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.type} />,
    id: 'typeName',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.status} />,
    id: 'needsapproval',
    render: (rowIndex: number, cellValue: any, columnIndex: number) =>
      cellValue == 1 ? (
        <Chip
          size="small"
          label={
            cellValue == 1 ? (
              <TranslatedText textMap={Translations.needApprove} />
            ) : (
              ''
            )
          }
          color="primary"
        />
      ) : (
        <></>
      ),
    inData: true,
  },
  {
    label: '',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'asc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
