import useStyles from './styles';
import React from 'react';
import DestinationsForm from './DestinationsForm';
import DestinationsCard from './DestinationsCard';
import DestinationsTable from './DestinationsTable';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import reactLogger from 'utils/logger';
import DestinationsFlightsMap from './DestinationsFlightsMap';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
}

const DestinationTabView = React.forwardRef((props: IProps, ref) => {
  reactLogger.renderComponent('WebsiteTabView');
  const { view, handleNext, handlePrevious } = props;
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <TabActions
        view={view}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />

      <div className={classes.mapContainer}>
        <div className={classes.formContainer}>
          <DestinationsForm ref={ref} />
        </div>
        {/* <div className={classes.destinationsContainer}>
          <DestinationsCard />
        </div> */}
        <DestinationsFlightsMap ref={ref} />
      </div>
      {/* <DestinationsTable /> */}
    </div>
  );
});

export default DestinationTabView;
