import { Languages } from '../../config';

const Translations = {
  page_title: {
    [Languages.English]: 'Application Detail',
    [Languages.Greek]: 'Λεπτομέρειες Εφαρμογής',
    [Languages.Russian]: 'Деталь Применение',
  },
  general_tab: {
    [Languages.English]: 'General',
    [Languages.Greek]: 'Γενικός',
    [Languages.Russian]: 'Общий',
  },
  application_tab: {
    [Languages.English]: 'Application Type',
    [Languages.Greek]: 'Τύπος αίτησης',
    [Languages.Russian]: 'Тип приложения',
  },
  name_label: {
    [Languages.English]: 'Name',
    [Languages.Greek]: 'Ονομα',
    [Languages.Russian]: 'Имя',
  },
  description_label: {
    [Languages.English]: 'Description',
    [Languages.Greek]: 'Περιγραφή',
    [Languages.Russian]: 'Описание',
  },
  category_label: {
    [Languages.English]: 'Category',
    [Languages.Greek]: 'Κατηγορία',
    [Languages.Russian]: 'категория',
  },
  update_button: {
    [Languages.English]: 'Update Application',
    [Languages.Greek]: 'Ενημέρωση εφαρμογών',
    [Languages.Russian]: 'Применение обновления',
  },
  delete_button: {
    [Languages.English]: 'Delete Application',
    [Languages.Greek]: 'Διαγραφή εφαρμογών',
    [Languages.Russian]: 'Удаление приложений',
  },
  new_application_type_button: {
    [Languages.English]: 'New Application Type',
    [Languages.Greek]: 'Νέα Τύπος εφαρμογής',
    [Languages.Russian]: 'Новый тип приложения',
  },
  cancel_button: {
    [Languages.English]: 'Cancel',
    [Languages.Greek]: 'Ματαίωση',
    [Languages.Russian]: 'Отмена',
  },
  location_label: {
    [Languages.English]: 'Location',
    [Languages.Greek]: 'Τοποθεσία',
    [Languages.Russian]: 'Место расположения',
  },
  key_label: {
    [Languages.English]: 'Key',
    [Languages.Greek]: 'Κλειδί',
    [Languages.Russian]: 'Ключ',
  },
  save_button: {
    [Languages.English]: 'Save',
    [Languages.Greek]: 'Σώσει',
    [Languages.Russian]: 'Сохранить',
  },
};

export default Translations;
