import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import DestinationsCard from './DestinationsCard';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import _ from 'lodash';
import DestinationsFlightsMap from './DestinationsFlightsMap';
import WebsiteDestinationsFlightsMap from '../website_view/DestinationsFlightsMap';
import TransportDestinationsMap from './TransportDestinationsMap';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const PreviewDestinations = (props: IProps) => {
  const [renderFlightMap, setRenderFlightMap] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let airlines = props.campaign.publisher ?? [];
    let destCountries = props.campaign.destination_country ?? [];
    let destCities = props.campaign.destination_city ?? [];
    let CSAirlines = airlines.map((e) => e.Code).join(',');
    let CSDestinationCountries = destCountries.map((e) => e.Code).join(',');
    let CSDestinationCities = destCities.map((e) => e.Code).join(',');
    dispatch(
      CampaignActions.calculateEstimateReachOnBoardAction({
        destination_countries: CSDestinationCountries,
        destination_airports: CSDestinationCities,
        airlines: CSAirlines,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setRenderFlightMap(true);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      {props.campaign.display === 0 ? (
        <div className={classes.mapContainer}>
          <DestinationsFlightsMap />
        </div>
      ) : (
        props.campaign.display === 1 && (
          <div className={classes.mapContainer}>
            <WebsiteDestinationsFlightsMap />
          </div>
        )
      )}
      {props.campaign.display === 2 && <TransportDestinationsMap />}
      {/* <div className={classes.destinationsContainer}>
        <DestinationsCard />
      </div> */}
    </React.Fragment>
  );
};

export default PreviewDestinations;
