import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';
import { IExportData } from './reducers';

export const useGetAirportList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].airportsList
  );
  return data;
};

export const useGetReportingCampaignsDetail = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].reportingCampaignDetail
  );
  return data;
};
export const useGetReportingCampaignsWithoutPaging = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.REPORTING_REDUCER].reportingCampaignWithoutPaging
  );
  return data;
};
export const useGetPagination = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].paginationParams
  );
  return data;
};
export const useGetSearchQuery = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].searchQuery
  );
  return data;
};
export const useGetCampaignList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].campaignList
  );
  return data;
};

export const useGetLoading = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].loading
  );
  return data;
};

export const useGetExportData = (): IExportData => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REPORTING_REDUCER].exportData
  );
  return data;
};
