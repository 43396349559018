import { LanguagesEnum } from '../../config/languages';

const Translations = {
  form_field_id: {
    [LanguagesEnum.English]: 'Id',
    [LanguagesEnum.Greek]: 'Ταυτότητα',
    [LanguagesEnum.Russian]: 'Идентификатор',
  },
  form_field_pubCode: {
    [LanguagesEnum.English]: 'Publisher Code',
    [LanguagesEnum.Greek]: 'Κωδικός εκδότη',
    [LanguagesEnum.Russian]: 'Код издателя',
  },
  form_field_code: {
    [LanguagesEnum.English]: 'Code',
    [LanguagesEnum.Greek]: 'Κώδικας',
    [LanguagesEnum.Russian]: 'Код',
  },
  form_field_lat: {
    [LanguagesEnum.English]: 'Latitude',
    [LanguagesEnum.Greek]: 'Γεωγραφικό πλάτος',
    [LanguagesEnum.Russian]: 'Широта',
  },
  form_field_lon: {
    [LanguagesEnum.English]: 'Longitude',
    [LanguagesEnum.Greek]: 'Γεωγραφικό μήκος',
    [LanguagesEnum.Russian]: 'Долгота',
  },
  form_field_ip: {
    [LanguagesEnum.English]: 'Ip',
    [LanguagesEnum.Greek]: 'Ip',
    [LanguagesEnum.Russian]: 'Ip',
  },
  form_field_sim: {
    [LanguagesEnum.English]: 'Sim',
    [LanguagesEnum.Greek]: 'Αριθμός SIM',
    [LanguagesEnum.Russian]: 'Номер сима',
  },
  form_field_serial: {
    [LanguagesEnum.English]: 'Serial Number',
    [LanguagesEnum.Greek]: 'Σειριακός αριθμός',
    [LanguagesEnum.Russian]: 'Серийный номер',
  },
  form_field_pin1: {
    [LanguagesEnum.English]: 'Pin 1',
    [LanguagesEnum.Greek]: 'Pin 1',
    [LanguagesEnum.Russian]: 'Pin 1',
  },
  form_field_pin2: {
    [LanguagesEnum.English]: 'Pin 2',
    [LanguagesEnum.Greek]: 'Pin 2',
    [LanguagesEnum.Russian]: 'Pin 2',
  },
  form_field_wifi: {
    [LanguagesEnum.English]: 'Wi-fi',
    [LanguagesEnum.Greek]: 'Wi-Fi',
    [LanguagesEnum.Russian]: 'Wi-Fi',
  },
  form_field_type: {
    [LanguagesEnum.English]: 'Type',
    [LanguagesEnum.Greek]: 'Τύπος',
    [LanguagesEnum.Russian]: 'Тип',
  },
  form_field_version: {
    [LanguagesEnum.English]: 'Version',
    [LanguagesEnum.Greek]: 'Εκδοχή',
    [LanguagesEnum.Russian]: 'Версия',
  },
  form_field_status: {
    [LanguagesEnum.English]: 'Status',
    [LanguagesEnum.Greek]: 'Κατάσταση',
    [LanguagesEnum.Russian]: 'Статус',
  },
  form_field_comments: {
    [LanguagesEnum.English]: 'Comments',
    [LanguagesEnum.Greek]: 'Σχόλια',
    [LanguagesEnum.Russian]: 'Комментарии',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'Required Field',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
    [LanguagesEnum.Russian]: 'Обязательное поле',
  },
  form_submit: {
    [LanguagesEnum.English]: 'Save Details',
    [LanguagesEnum.Greek]: 'Αποθήκευση Λεπτομέρειες',
    [LanguagesEnum.Russian]: 'Сохранить подробности',
  },
  transport_details: {
    [LanguagesEnum.English]: 'Transport Details',
    [LanguagesEnum.Greek]: 'Στοιχεία μεταφοράς',
    [LanguagesEnum.Russian]: 'Детали транспорта',
  },
};

export default Translations;
