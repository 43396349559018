import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

export interface IGeneralPublisherStatisticInput {
  userid: number;
  appid: string | null;
  publisherid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

function getRevenueConfig(data: any) {
  const LineArea: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'area',
        height: 224,
        series: [
          {
            name: 'Impression',
            data: data.data[0].data,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            type: 'datetime',
            categories: data.labels.xaxis.categories,
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-impression-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return LineArea;
}

export default getRevenueConfig;
