export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
}

const GenderConfig = Object.values(GenderEnum).map((value, index) => ({
  label: value,
  id: index,
  value: value,
}));

export default {
  GenderEnum,
  GenderConfig,
};
