import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';
import { IState } from './reducer';

interface DSPListElement {
  code: string;
  name: string;
  userid: string;
  vasttype: number;
}

interface DSPListData {
  paging: DSPListPaging;
  items: DSPListElement[];
}

interface DSPListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface DSPistOutput {
  status: StateStatus;
  data: DSPListData | null;
}

export const useGetDSPList = (): DSPistOutput => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER].DSPList);
  return data;
};

/* selector: useGetDSPDetails */
interface DSPDetailsData {
  code: string;
  name: string;
  userid: string;
  vasttype: number;
}

interface DSPDetailsOutput {
  status: StateStatus;
  data: DSPDetailsData | null;
}

export const useGetDSPDetails = (): DSPDetailsOutput => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER].viewDSP);
  return data;
};

export const useGetParalist = (): IState['paralist'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER].paralist);
  return data;
};

export const useGetUserByRole = (): IState['userByRole'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER].userByRole);
  return data;
};

export const useEditDSPStattus = (): IState['editDSP']['status'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.editDSP?.status);
  return data;
};

export const useAddDSPStattus = (): IState['addDSP']['status'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.addDSP?.status);
  return data;
};

export const useGetListDspPublisher = (): IState['listDspPublisher'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.listDspPublisher);
  return data;
};

export const useCreateDspPublisherStatus = (): IState['createDSPPublisher']['status'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.createDSPPublisher?.status);
  return data;
};

export const useListPublisherByRole = (): IState['listPublisherByRole'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.listPublisherByRole);
  return data;
};

export const useListDSPAdvertisement = (): IState['listDSPAdvertisement'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.listDSPAdvertisement);
  return data;
};

export const useCreateDSPAdvertisementStatus = (): IState['createDSPAdvertisement']['status'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.createDSPAdvertisement?.status);
  return data;
};

export const useListAdvertisementType = (): IState['listAdvertisementType'] => {
  const data = useShallowEqualSelector((state: any) => state[ReducerKeys.DSP_REDUCER]?.listAdvertisementType);
  return data;
};
