export enum Roles {
  Guest = '0',
  Administrator = '1',
  Advertiser = '2',
  AdvertiserWebsite = '6',
  Publisher = '3',
  Airline = '4',
  Advertiser_Administrator = '5',
  PublisherWebsite = '7',
  AdvertiserTransport = '8',
  PublisherTransport = '9',
}

const AllUsers = [
  Roles.Guest,
  Roles.Administrator,
  Roles.Advertiser,
  Roles.Publisher,
  Roles.Airline,
  Roles.Advertiser_Administrator,
];
// .map((key: any) =>
//   Number.parseInt(Roles[key])
// );
const RegisteredUsers = [
  Roles.Administrator,
  Roles.Advertiser,
  Roles.Publisher,
  Roles.Airline,
  Roles.Advertiser_Administrator,
];
const Advertisers = [
  Roles.Administrator,
  Roles.Advertiser,
  Roles.Advertiser_Administrator,
];

const AdvertisersWebsite = [
  Roles.Administrator,
  Roles.AdvertiserWebsite,
  Roles.Advertiser_Administrator,
];

const AdvertisersTransport = [
  Roles.Administrator,
  Roles.AdvertiserTransport,
  Roles.Advertiser_Administrator,
];

const Publishers = [Roles.Administrator, Roles.Publisher];
const PublishersWebsite = [Roles.Administrator, Roles.PublisherWebsite];
const PublishersTransport = [Roles.Administrator, Roles.PublisherTransport];

const Admin = [Roles.Administrator];
export default {
  ...Roles,
  AdvertisersWebsite,
  AdvertisersTransport,
  PublishersWebsite,
  PublishersTransport,
  All: AllUsers,
  Advertisers,
  Publishers,
  Registered: RegisteredUsers,
  Admin,
};
