import React, { useState } from 'react';
import { Card, CardContent, Button, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Actions from '../../../redux/reducers/administrator_reducer/actions';
import * as Types from '../../../redux/reducers/administrator_reducer/types';
import { StateStatus } from '../../../redux/utils/common';
import {
  useGetAllUsersWithRoles,
  useGetPersistAllUsersWithRoles,
} from '../../../redux/reducers/administrator_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

export default function SearchArea() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const publisherApplication = useGetAllUsersWithRoles();
  const persistPublisherApplication = useGetPersistAllUsersWithRoles();

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    const value: string = event.target.value as string;
    setSearch(value);
  }
  function handleSearch() {
    if (search !== '') {
      const searched = persistPublisherApplication.data.items.filter(
        (app: any) =>
          `${app.firstname} ${app.email}`
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      dispatch({
        type: Types.SET_ALL_USERS_WITH_ROLES,
        status: StateStatus.Success,
        data: {
          ...publisherApplication.data.data,
          items: searched,
        },
      });
    } else {
      dispatch({
        type: Types.SET_ALL_USERS_WITH_ROLES,
        status: StateStatus.Success,
        data: {
          ...publisherApplication.data.data,
          items: persistPublisherApplication.data.items,
        },
      });
    }
  }

  return (
    <Card>
      <CardContent
        style={{
          marginTop: '10px 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            // style={{ height: 40 }}
            variant="outlined"
            label={<TranslatedText textMap={Translations.search_placeholder} />}
            value={search}
            onChange={handleChange}
          />
          <Button
            style={{ marginLeft: 10, height: 55 }}
            variant="outlined"
            color="primary"
            onClick={handleSearch}
          >
            <TranslatedText textMap={Translations.search_button} />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
