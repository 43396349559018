import { Button, Card } from '@material-ui/core';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import NavigationConfig from 'navigation/config';
import React from 'react';
import { Control, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import useStyles from './styles';
import { StatusOptions, Translations } from './configuration';
import TranslatedText from 'components/atoms/translated_text';

interface FormFields {
  activeStatus: string | null;
  campaign: string | null;
}

interface IProps {
  activeStatus: string | null;
  campaign: string | null;
}
export default function Filtering(props: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const defaultValues = {
    activeStatus: props.activeStatus ?? '1',
    campaign: props.campaign ?? null,
  };
  const { control, handleSubmit, reset } = useForm<FormFields>({
    defaultValues: {
      activeStatus: null,
      campaign: null,
    },
  });

  React.useEffect(() => {
    reset(defaultValues);
  }, [props]);

  const onSubmit = (data: FormFields) => {
    let query = [];
    if (data.activeStatus)
      query.push({
        key: 'activeStatus',
        value: data.activeStatus,
      });
    if (data.campaign)
      query.push({
        key: 'campaign',
        value: data.campaign,
      });
    let queryString = '';
    query.map((query, index) => {
      if (index == 0) queryString += `?${query.key}=${query.value}`;
      else queryString += `&${query.key}=${query.value}`;
    });
    history.push(NavigationConfig.campaignsPage().path + queryString);
  };

  const clearFilter = () => {
    history.push(NavigationConfig.campaignsPage().path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '.5rem' }}>
      <GridLayout
        elements={[
          {
            element: renderStatusControl(control),
            size: 4,
            id: 'activeStatus',
          },
          {
            element: renderSearchControl(control),
            size: 8,
            id: 'campaign',
          },
          {
            element: (
              <div className={classes.actions}>
                <Button
                  variant="contained"
                  color="secondary"
                  type={'submit'}
                  className={classes.action_button}
                >
                  <TranslatedText textMap={Translations.search} />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilter}
                  className={classes.action_button}
                >
                  <TranslatedText textMap={Translations.clear} />
                </Button>
              </div>
            ),
            size: 12,
            id: 'actions',
          },
        ]}
      />
    </form>
  );
}

const renderStatusControl = (control: Control<FormFields>) => {
  let options;
  return (
    <AppSelect
      fullWidth
      name={'activeStatus'}
      label={'Status'}
      control={control}
      rules={{}}
      size="medium"
      variant="outlined"
      options={StatusOptions as any}
    />
  );
};

const renderSearchControl = (control: Control<FormFields>) => {
  return (
    <AppTextField
      fullWidth
      variant="outlined"
      id="campaign"
      label="Campaign"
      name="campaign"
      control={control}
      rules={{}}
    />
  );
};
