import React, { useRef } from 'react';
import useStyles from '../../styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { AdDetailsSteps, LayoutSteps } from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import AdDetailsStep from '../ad_details_step';
import ImageView from '../ad_details_image_video_step';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
import reactLogger from 'utils/logger';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import { StepButton } from '@material-ui/core';
import AdDetailsAll from '../ad_details_all';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
  handleChangeStep: { (step: number): void };
}

const AdDetailsView = React.forwardRef((props: IProps, ref) => {
  reactLogger.renderComponent('AdDetailsView');
  const { view, handleNext, handlePrevious, handleChangeStep } = props;
  const classes = useStyles();
  const activeStep = view.step as number;
  const campaign = CampaignSelectors.useGetCampaignWizardValues();

  return (
    <div>
      {/* <Stepper className={classes.stepper} activeStep={activeStep} nonLinear>
        {LayoutSteps.map(({ id, label }) => (
          <Step key={id}>
            <StepButton onClick={() => handleChangeStep(id)}>
              <TranslatedText textMap={label} />
            </StepButton>
          </Step>
        ))}
      </Stepper> */}
      <TabActions
        view={view}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
      {/* {activeStep === AdDetailsSteps.AD_DETAIL && (
        <AdDetailsStep ref={ref} campaign={campaign} />
      )}
      {activeStep === AdDetailsSteps.IMAGE_VIDEO && (
        <ImageView ref={ref} campaign={campaign} />
      )} */}
      {activeStep === AdDetailsSteps.AD_DETAIL && (
        <AdDetailsAll ref={ref} campaign={campaign} />
      )}
    </div>
  );
});

export default AdDetailsView;
