import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import {
  AppTextField,
  PopOverDatePicker,
} from 'components/molecules/form_controls';
import Translations from '../../translations';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import {
  CampaignAllDisplay,
  CampaignInflightOnlyDisplay,
  CampaignTransportOnlyDisplay,
  CampaignWebsiteOnlyDisplay,
  IDisplay,
} from './configuration';
import { Roles } from 'config/authRoles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CampaignOption from 'components/app_common/option';
import AutoCompleteSelect from 'components/molecules/form_controls/autocompleteselect';
import * as CategoryService from 'services/api/category';
import { Box } from '@material-ui/core';

interface IFormData {
  campaign: string | null;
  budget: number | null;
  start_date: Date | null;
  end_date: Date | null;
  display: number | null;
  category: number | null;
}

// add types or interfaces
const defaultValues = {
  campaign: null,
  budget: null,
  start_date: null,
  end_date: null,
  display: null,
  category: null,
};

interface IProps {
  campaign: ICampaignInfoWizard;
}
const CampaignInfoAll = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const roles = useSelectUserRoles();

  const { campaign } = props;
  const { errors, control, getValues, reset, setValue } = useForm<IFormData>({
    defaultValues,
  });

  const [selectedDisplay, setSelectedDisplay] = useState<number>(0);
  const [campaignDisplay, setCampaignDisplay] = useState<IDisplay[]>([]);
  const [categories, setCategories] = useState<[]>([]);

  /** Displays based on User Role */
  React.useEffect(() => {
    if (
      (roles.includes(Roles.Advertiser) &&
        roles.includes(Roles.AdvertiserWebsite)) ||
      roles.includes(Roles.Administrator)
    ) {
      setCampaignDisplay(CampaignAllDisplay);
    } else if (roles.includes(Roles.Advertiser)) {
      setCampaignDisplay(CampaignInflightOnlyDisplay);
    } else if (roles.includes(Roles.AdvertiserWebsite)) {
      setCampaignDisplay(CampaignWebsiteOnlyDisplay);
    } else {
      setCampaignDisplay(CampaignTransportOnlyDisplay);
    }
  }, [roles]);

  React.useEffect(() => {
    CategoryService.getCategory().then((result) => {
      setCategories(result.data);
    });
  }, [setCategories]);

  /** Reset form values to campaign values */
  React.useEffect(() => {
    reset({
      campaign: campaign.campaign,
      budget: campaign.budget,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      display: campaign.display,
      category: categories.find(
        (category: any) => category.id === campaign.category
      ),
    });
  }, [campaign, categories]);

  /** Select correct display based on Campaign's display value */
  React.useEffect(() => {
    if (campaign.display && campaignDisplay.length !== 0)
      for (let display in campaignDisplay) {
        if (campaignDisplay[display].code == campaign.display) {
          setSelectedDisplay(Number.parseInt(display));
          break;
        }
      }
  }, [campaign, setSelectedDisplay, campaignDisplay]);

  /** Set display to Campaign's ref.current for tab/step check */
  React.useEffect(() => {
    if (campaignDisplay?.length > 0) {
      ref.current = {
        display: campaignDisplay[selectedDisplay].code,
      };
    }
    ref.current = { ...ref.current, getValues: getValues };
    // console.log('ref.current: ' + JSON.stringify(ref.current));
  }, [campaignDisplay.length, getValues, selectedDisplay]);

  function onSelectionChange(index: number) {
    const id = _.get(params, 'id', null);
    if (id === 'new') {
      setSelectedDisplay(index);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <CardLayout
          body={
            <React.Fragment>
              <Box style={{ paddingLeft: '4px', fontWeight: 'bold' }}>
                <TranslatedText textMap={Translations.campaign_details} />
              </Box>
              <form className={classes.form} noValidate>
                <GridLayout
                  justify="flex-start"
                  elements={[
                    {
                      id: 'campaign',
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'campaign' in errors}
                          helperText={
                            errors.campaign && errors.campaign.message
                          }
                          rules={{}}
                          fullWidth
                          id="campaign"
                          control={control}
                          label={
                            <TranslatedText
                              textMap={Translations.campaign_name_control}
                            />
                          }
                          autoComplete="campaign"
                          name="campaign"
                        />
                      ),
                      size: 6,
                    },
                    {
                      id: 'budget',
                      element: (
                        <AppTextField
                          type="number"
                          variant="outlined"
                          margin="normal"
                          error={'budget' in errors}
                          helperText={errors.budget && errors.budget.message}
                          rules={{
                            min: 0,
                          }}
                          icon={{
                            position: 'right',
                            Component: <div>€</div>,
                          }}
                          fullWidth
                          id="budget"
                          control={control}
                          label={
                            <TranslatedText
                              textMap={Translations.budget_control}
                            />
                          }
                          autoComplete="budget"
                          name="budget"
                        />
                      ),
                      size: 6,
                    },
                    {
                      id: 'start_date',
                      element: (
                        <PopOverDatePicker
                          fullWidth
                          margin="normal"
                          minDate={new Date()}
                          label={
                            <TranslatedText
                              textMap={Translations.start_date_control}
                            />
                          }
                          autoOk
                          helperText={
                            errors.start_date && errors.start_date.message
                          }
                          error={'start_date' in errors}
                          control={control}
                          rules={{}}
                          name="start_date"
                        />
                      ),
                      size: 6,
                    },
                    {
                      id: 'end_date',
                      element: (
                        <PopOverDatePicker
                          fullWidth
                          minDate={new Date()}
                          margin="normal"
                          label={
                            <TranslatedText
                              textMap={Translations.end_date_control}
                            />
                          }
                          autoOk
                          helperText={
                            errors.end_date && errors.end_date.message
                          }
                          error={'end_date' in errors}
                          control={control}
                          rules={{}}
                          name="end_date"
                        />
                      ),
                      size: 6,
                    },
                    {
                      id: 'category',
                      element: (
                        <AutoCompleteSelect
                          fullWidth
                          margin="normal"
                          error={'category' in errors}
                          helperText={
                            errors.category && errors.category.message
                          }
                          rules={{}}
                          name="category"
                          id="category"
                          label={
                            <TranslatedText
                              textMap={Translations.category_control}
                            />
                          }
                          options={categories}
                          control={control}
                          selectedKey={'name'}
                          handleChange={(data) => {
                            setValue('category', data);
                          }}
                        />
                      ),
                      size: 12,
                    },
                  ]}
                />
              </form>
            </React.Fragment>
          }
        />
      </div>
      <div className={classes.pageContainer}>
        <CardLayout
          cardStyling={{ width: '100%' }}
          body={
            <React.Fragment>
              <Box
                style={{
                  paddingLeft: '4px',
                  fontWeight: 'bold',
                  marginBottom: '16px',
                  fontSize: '16px',
                }}
              >
                <TranslatedText textMap={Translations.display} />
              </Box>
              <GridLayout
                justify="center"
                elements={campaignDisplay.map(
                  (display: IDisplay, index: number) => ({
                    id: index.toString(),
                    element: (
                      <div key={index} className={classes.purposeItem}>
                        <DisplayOption
                          key={index}
                          display={display}
                          isSelected={selectedDisplay === index}
                          index={index}
                          onSelectionChange={onSelectionChange}
                        />
                      </div>
                    ),
                    size: 4,
                    md: 12,
                  })
                )}
              />
            </React.Fragment>
          }
        />
      </div>
    </React.Fragment>
  );
});

interface IDisplayProps {
  display: IDisplay;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const DisplayOption = (props: IDisplayProps) => {
  const { display, isSelected, index, onSelectionChange } = props;
  const classes = useStyles();

  const DisplayOptionHeader = () => {
    return <TranslatedText textMap={display.name} />;
  };

  const DisplayOptionBody = () => {
    return (
      <React.Fragment>
        {display.notes.map((note, index: number) => (
          <div key={index} className={classes.purposeOptionBody}>
            <FiberManualRecordIcon className={classes.bullet} />
            <p className={classes.bulletText}>
              <TranslatedText textMap={note} />
            </p>
          </div>
        ))}
      </React.Fragment>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<DisplayOptionHeader />}
      body={<DisplayOptionBody />}
    />
  );
};

export default CampaignInfoAll;
