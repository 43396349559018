import { IAction, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  OSSSchemesList: any;
  countriesList: any;
  registerUserStatus: StateStatus | null | undefined;
}

const initialState: IState = {
  OSSSchemesList: {
    status: null,
    data: null,
  },
  registerUserStatus: null,
  countriesList: {
    status: null,
    data: null,
  },
};

const RegisterReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_OSS_SCHEMES:
      return {
        ...state,
        OSSSchemesList: {
          ...state.OSSSchemesList,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_COUNTRIES:
      return {
        ...state,
        countriesList: {
          ...state.countriesList,
          status: action.status,
          data: action.data,
        },
      };
    case Types.REGISTER_USER:
      return {
        ...state,
        registerUserStatus: action.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default RegisterReducer;
