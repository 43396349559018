import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import * as RegisterSelectors from '../../store/selectors';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
} from '../../../../components/molecules/form_controls';
import { Control, Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { StateStatus } from 'redux/utils/common';
import RegisterActions from '../../store/actions';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from '../../../../components/atoms/translated_text';
import Configuration from '../../configuration';
import Translations from '../../translations';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from '../../../../config';
import CountriesSelect from './FormCountriesSelect';

interface IFormData {
  firstName: string | null;
  surname: string | null;
  email: string | null;
  confirmEmail: string | null;
  password: string | null;
  confirmPassword: string | null;
  nationality: string | null;
  birthDate: string | null;
  gender: string | null;
}

// add types or interface
const defaultValues = {
  firstName: null,
  surname: null,
  email: null,
  confirmEmail: null,
  password: null,
  confirmPassword: null,
  nationality: null,
  birthDate: null,
  gender: null,
};

export default function RegisterForm(): ReactElement {
  console.log('Render RegisterForm:');

  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (_data: any) => {
    const { confirmPassword, confirmEmail, ..._newdata } = _data;
    let payload = {
      ..._newdata,
      roles: [AuthRoles.Advertiser],
    };
    dispatch(RegisterActions.registerUserAction(payload, history));
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'firstName' in errors}
        helperText={errors.firstName && errors.firstName.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="firstName"
        control={control}
        label={
          <TranslatedText
            defaultText={'Firstname'}
            textMap={Translations.form_field_firstname}
          />
        }
        autoComplete="firstName"
        name="firstName"
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        error={'surname' in errors}
        helperText={errors.surname && errors.surname.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="surname"
        label={
          <TranslatedText
            defaultText={'Surname'}
            textMap={Translations.form_field_surname}
          />
        }
        control={control}
        autoComplete="surname"
        name="surname"
      />
      <AppDatePicker
        fullWidth
        margin="normal"
        label={
          <TranslatedText
            defaultText={'Birth Date'}
            textMap={Translations.form_field_birthdate}
          />
        }
        autoOk
        helperText={errors.birthDate && errors.birthDate.message}
        error={'birthDate' in errors}
        control={control}
        rules={{}}
        // rules={{
        //   required: <RequiredField />,
        // }}
        name="birthDate"
      />
      <AppRadioGroup
        row
        helperText={errors.gender && errors.gender.message}
        error={'gender' in errors}
        control={control}
        rules={{}}
        // rules={{
        //   required: <RequiredField />,
        // }}
        values={Gender.GenderConfig}
        name="gender"
        label={
          <TranslatedText
            defaultText={'Gender'}
            textMap={Translations.form_field_gender}
          />
        }
      />
      <CountriesSelect
        control={control}
        error={'nationality' in errors}
        name="nationality"
        helperText={errors.nationality && errors.nationality.message}
        rules={{}}
        // rules={{
        //   required: 'Required Field',
        // }}
        label={
          <TranslatedText
            defaultText={'Nationality'}
            textMap={Translations.form_field_nationality}
          />
        }
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        control={control}
        error={'password' in errors}
        rules={{
          required: <RequiredField />,
          minLength: {
            value: 8,
            message: 'Password must have at least 8 characters',
          },
        }}
        label={
          <TranslatedText
            defaultText={'Password'}
            textMap={Translations.form_field_password}
          />
        }
        helperText={errors.password && errors.password.message}
        name="password"
        type="password"
        id="password"
        icon={{
          position: 'right',
          Component: <LockOutlinedIcon />,
        }}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        error={'confirmPassword' in errors}
        label={
          <TranslatedText
            defaultText={'Confirm Password'}
            textMap={Translations.form_field_confirm_password}
          />
        }
        helperText={errors.confirmPassword && errors.confirmPassword.message}
        type="password"
        id="confirmPassword"
        control={control}
        rules={{
          required: <RequiredField />,
          validate: () =>
            getValues('password') === getValues('confirmPassword') ||
            'The passwords do not match',
        }}
        icon={{
          position: 'right',
          Component: <LockOutlinedIcon />,
        }}
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={
          <TranslatedText
            defaultText={'Email Address'}
            textMap={Translations.form_email}
          />
        }
        name="email"
        autoComplete="email"
        control={control}
        error={'email' in errors}
        helperText={errors.email && errors.email.message}
        rules={{
          required: <RequiredField />,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid e-mail address',
          },
        }}
        icon={{
          position: 'right',
          Component: <EmailOutlinedIcon />,
        }}
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="confirmEmail"
        label={
          <TranslatedText
            defaultText={'Confirm Email Address'}
            textMap={Translations.form_confirm_email}
          />
        }
        name="confirmEmail"
        autoComplete="confirmEmail"
        control={control}
        error={'confirmEmail' in errors}
        helperText={errors.confirmEmail && errors.confirmEmail.message}
        rules={{
          required: <RequiredField />,
          validate: () =>
            getValues('email') === getValues('confirmEmail') ||
            'The Emails do not match',
        }}
        icon={{
          position: 'right',
          Component: <EmailOutlinedIcon />,
        }}
      />

      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <SubmitWrapper
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
        >
          <TranslatedText textMap={Translations.form_button_register} />
        </SubmitWrapper>
      </Box>
    </form>
  );
}

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const registerStatus = RegisterSelectors.useGetRegisterStatus();

  return (
    <Button
      {...props}
      color="primary"
      disabled={registerStatus === StateStatus.Pending}
    />
  );
};
