import React from 'react';
import useStyles from './styles';
import ToolbarFullPageContainer from 'components/app_common/toolbar_fullpage_container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Button, Paper, Typography, PaperProps } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import * as PathUtils from 'utils/path';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';
import { IView } from '../../tab_stepper';

export interface IBaseMenu {
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  id: string;
  element?: React.ReactNode;
  onClickEventValue?: {
    on: (e: any, value: any) => any;
    clickEvent?: any;
    clickValue?: any;
  };
  loadOn?: boolean;
  index?: number;
  view?: IView;
}

interface IMenuContainer {
  Header?: React.ReactElement;
  Footer?: React.ReactElement;
  menu: IBaseMenu[];
}

export interface BaseNavigationMenuProps {
  menuContainer: IMenuContainer;
  Header?: React.ReactNode;
  Footer?: React.ReactNode;
  topDividerVisibility?: 'visible' | 'invisible';
  dividerVisibility?: 'visible' | 'invisible';
  containerProps?: Omit<PaperProps, 'children'>;
  heightFull?: boolean;
  view: IView;
}

const TOOLBAR_MAX_HEIGHT = '64px';
const SIDE_MENU_WIDTH = 320;

const BaseNavigationMenu = ({
  menuContainer,
  Header,
  Footer,
  topDividerVisibility = 'visible',
  dividerVisibility = 'visible',
  containerProps,
  view,
}: BaseNavigationMenuProps) => {
  const classes = useStyles();
  const history = useHistory();

  const drawer = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {Header}
      {topDividerVisibility === 'visible' && <Divider />}
      <div
        style={
          {
            /** Pushes tabs to the bottom (like sticky) */
            // marginTop: 'auto',
          }
        }
      >
        {
          <React.Fragment>
            <List subheader={menuContainer.Header}>
              {menuContainer.menu.map((data, index) => (
                <MenuItem {...data} key={data.id} index={index} view={view} />
              ))}
            </List>
            <div>{menuContainer.Footer}</div>
          </React.Fragment>
        }
      </div>
      {Footer}
    </div>
  );

  return (
    <Paper
      square
      // variant="outlined"
      elevation={0}
      {...containerProps}
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ...containerProps?.style,
      }}
    >
      {drawer}
    </Paper>
  );
};

const MenuItem = (props: IBaseMenu) => {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { onClickEventValue, loadOn, index, view, ...itemProps } = props;
  const selected = view?.tab === index;

  return (
    <React.Fragment>
      {(loadOn === undefined || loadOn) && (
        <ListItem
          onClick={(e) => {
            onClickEventValue &&
              onClickEventValue.on(
                onClickEventValue.clickEvent,
                onClickEventValue.clickValue
              );
          }}
          selected={selected}
          className={`${classes.listItem} ${selected ? classes.active : ''}`}
          button
        >
          {
            <React.Fragment>
              {props.icon && (
                <ListItemIcon
                  className={`${selected ? classes.active : ''} ${
                    classes.listItemIcon
                  }`}
                >
                  {props.icon}
                </ListItemIcon>
              )}
              {props.iconRight && (
                <ListItemIcon
                  className={`${selected ? classes.active : ''} ${
                    classes.listItemIcon
                  }`}
                  style={{ minWidth: 0 }}
                >
                  {props.iconRight}
                </ListItemIcon>
              )}
              {props?.element}
            </React.Fragment>
          }
        </ListItem>
      )}
    </React.Fragment>
  );
};

export const BaseNavigationMenuContainer = (props: BaseNavigationMenuProps) => {
  return (
    <ToolbarFullPageContainer
      style={{
        width: SIDE_MENU_WIDTH,
        position: 'sticky',
        top: TOOLBAR_MAX_HEIGHT,
        overflowY: 'auto',
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <div style={{ height: props.heightFull ? '100%' : '', width: '100%' }}>
        <BaseNavigationMenu {...props} />
      </div>
    </ToolbarFullPageContainer>
  );
};

export default React.memo(BaseNavigationMenu);
