import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { fetchParalists } from 'redux/reducers/paralist_reducer/actions';
import { useGetParaList } from 'redux/reducers/paralist_reducer/selectors';
import { GET_PARALISTS } from 'redux/reducers/paralist_reducer/types';
import {
  editPublisher,
  fetchDetailPublisherAction,
} from 'redux/reducers/publisher_reducer/actions';
import { Publisher } from 'redux/reducers/publisher_reducer/interfaces';
import { useDetailPublisher } from 'redux/reducers/publisher_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Translations from './translations';

const PublisherEditPage = () => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const detailPublisher = useDetailPublisher();
  const { fetch, paralist_status } = useGetParaList();
  const dispatch = useDispatch();
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Publisher>();

  useEffect(() => {
    dispatch(fetchParalists({}));
    dispatch(
      fetchDetailPublisherAction({
        publisher_id: id,
      })
    );
  }, []);

  useEffect(() => {
    if (detailPublisher.data) reset(detailPublisher.data as any);
  }, [detailPublisher.data]);

  const isLoading =
    detailPublisher?.status == StateStatus.Pending ||
    paralist_status == GET_PARALISTS.Pending;

  const pubTypes = fetch('publisher.type');
  const commissionCalculationTypes = fetch('publisher.commisioncalculation');
  const onSubmit = async (payload: Publisher) => {
    if (!detailPublisher.data) return;
    dispatch(
      editPublisher(parseInt(detailPublisher.data.id), payload as Publisher)
    );
  };
  return (
    <>
      <Box height={'4px'} />
      {isLoading ? <LinearProgress color="primary" /> : <Box height={'4px'} />}
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent="space-between"
        fontWeight={500}
        padding={1}
      >
        <Typography variant="h5">
          {/* <TranslatedText textMap={Translations.main} /> */}
          Edit {detailPublisher.data?.name?.trim() ?? ''}
        </Typography>

        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </Box>
      <Box
        style={{
          maxWidth: '600px',
          marginLeft: '2%',
          backgroundColor: '#fff',
          padding: ' 2%',
        }}
      >
        <AppTextField
          variant="standard"
          rules={{ required: true }}
          name="name"
          control={control}
          label="Name"
          fullWidth
        />
        <Box
          marginTop={'10px'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <AppTextField
            variant="standard"
            rules={{ required: true }}
            name="code"
            control={control}
            label="Code"
          />

          <AppSelect
            variant="standard"
            name="type"
            options={
              pubTypes?.map((type) => ({
                id: type.code,
                label: type.descriptionn,
              })) || []
            }
            label={<TranslatedText textMap={Translations.type} />}
            control={control}
            error={'type' in errors}
            helperText={errors?.type?.message}
            rules={{ required: true }}
          />

          <AppSelect
            variant="standard"
            name="needsapproval"
            options={[
              { id: 1, label: 'Needs Approval' },
              { id: 0, label: 'Approved' },
            ]}
            label={<TranslatedText textMap={Translations.status} />}
            control={control}
            error={'needsapproval' in errors}
            helperText={errors?.needsapproval?.message}
            rules={{ required: true }}
          />
        </Box>

        <Box marginTop={'10px'} fontWeight={'bold'}>
          Commission Calculation
        </Box>
        <AppSelect
          variant="standard"
          name="commisioncalculationtype"
          options={
            commissionCalculationTypes?.map((type) => ({
              id: type.code,
              label: type.descriptionn,
            })) || []
          }
          control={control}
          error={'commisioncalculationtype' in errors}
          helperText={errors?.commisioncalculationtype?.message}
          rules={{ required: true }}
        />

        <Box
          marginTop={'10px'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            marginTop={'10px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Chip label="Publisher" />

            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherfixcpv"
              control={control}
              label="CPV"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherpersentage"
              control={control}
              label="%"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherfixamount"
              control={control}
              label="Amount"
              type="number"
            />
          </Box>

          <Box
            marginTop={'10px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Chip label="Platform" />

            <AppTextField
              variant="standard"
              rules={{}}
              name="platformfixcpv"
              control={control}
              label="CPV"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="platformpercentage"
              control={control}
              label="%"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="platformfixamount"
              control={control}
              label="Amount"
              type="number"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(PublisherEditPage);
