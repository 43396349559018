import {
  IDashboard,
  IGlobal,
  EventsEnum,
} from 'components/compounds/dashboard_builder/plugins/types';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import * as Types from './types';

interface IState {
  dataFields: {
    status: StateStatus | null | undefined;
    data: any;
  };
  builderConfiguration: {
    status: StateStatus | null | undefined;
    data: IDashboard;
  };
  selectedElement: {
    status: StateStatus | null | undefined;
    data: any;
  };
  palleteIsOpen: boolean;
  // globals: IGlobal[];
}

const initialState: IState = {
  dataFields: {
    status: null,
    data: null,
  },
  builderConfiguration: {
    status: null,
    data: {
      dashboardElements: [],
      globals: {
        [EventsEnum.MAP_ONCLICK_KEY]: 'id',
        [EventsEnum.MAP_ONCLICK_VALUE]: '',
        [EventsEnum.MAP_ZOOM_KEY]: 'id',
        [EventsEnum.MAP_ZOOM_VALUE]: '',
      },
    },
  },
  selectedElement: {
    status: null,
    data: null,
  },
  palleteIsOpen: true,
  // globals: [
  //   {
  //     name: 'global1',
  //     value: 'global1',
  //   },
  //   {
  //     name: 'global2',
  //     value: 'global3',
  //   },
  //   {
  //     name: 'global4',
  //     value: 'global4',
  //   },
  // ],
};

const DashboardBuilderReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_DATASETS_FIELDS:
      return {
        ...state,
        dataFields: {
          ...state.dataFields,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME ? state.dataFields.data : action.data,
        },
      };
    case Types.SET_BUILDER_CONFIGURATION:
      return {
        ...state,
        selectedElement: {
          status: null,
          data: null,
        },
        dataFields: {
          status: null,
          data: null,
        },
        builderConfiguration: {
          ...state.builderConfiguration,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.builderConfiguration.data
              : action.data,
        },
      };
    case Types.SET_ELEMENT_SELECTED:
      return {
        ...state,
        selectedElement: {
          ...state.selectedElement,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_PALLETE_VISIBILITY:
      return {
        ...state,
        palleteIsOpen: action.data,
      };
    // case Types.SET_BUILDER_GLOBALS:
    //   return {
    //     ...state,
    //     globals: action.data,
    //   };
    default:
      return {
        ...state,
      };
  }
};

export default DashboardBuilderReducer;
