import CardLayout from 'components/app_common/card_layout';
import React from 'react';
import useStyles from './styles';
import PreviewCampaignInfoForm from './PreviewCampaignInfoForm';
import ICampaignInfoWizard from 'config/campaign_wizard';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const PreviewCampaignInfo = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.formContainer}>
      <CardLayout
        body={<PreviewCampaignInfoForm campaign={props.campaign} />}
      />
    </div>
  );
};

export default PreviewCampaignInfo;
