import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface Users_advertisers_administrationListElement {
  id: string;
  administratorid: string;
  userid: string;
  admincommision: string;
}

interface Users_advertisers_administrationListData {
  paging: Users_advertisers_administrationListPaging;
  items: Users_advertisers_administrationListElement[];
}

interface Users_advertisers_administrationListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface Users_advertisers_administrationistOutput {
  status: StateStatus;
  data: Users_advertisers_administrationListData | null;
}

export const useGetUsers_advertisers_administrationList = (): Users_advertisers_administrationistOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USERS_ADVERTISERS_ADMINISTRATION_REDUCER]
        .Users_advertisers_administrationList
  );
  return data;
};

/* selector: useGetUsers_advertisers_administrationDetails */
interface Users_advertisers_administrationDetailsData {
  id: string;
  administratorid: string;
  userid: string;
  admincommision: string;
}

interface Users_advertisers_administrationDetailsOutput {
  status: StateStatus;
  data: Users_advertisers_administrationDetailsData | null;
}

export const useGetUsers_advertisers_administrationDetails = (): Users_advertisers_administrationDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USERS_ADVERTISERS_ADMINISTRATION_REDUCER]
        .viewUsers_advertisers_administration
  );
  return data;
};
