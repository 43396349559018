import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetCampaignsAdvertisement = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.CAMPAIGNS_ADVERTISEMENT_REDUCER].campaignAvertiserment
  );
  return data;
};
export const useGetConnectedPublisher = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.CAMPAIGNS_ADVERTISEMENT_REDUCER].connectedPublisher
  );
  return data;
};
