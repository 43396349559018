import React from 'react';
import * as Config from '../../config';
import LoginPage from '../../scenes/login_page';
import MainPage from '../../scenes/main_page';
import RegisterPage from '../../scenes/register_page';
import DashboardBuilderPage from 'samples/dashboardBuilder_page';
import RootPage from '../../scenes/root_page';
import AppbarMenu from '../components/appbar';
import ForgotPasswordPage from '../../scenes/forgot_password';
import ResetPasswordPage from '../../scenes/reset_password';
import SecurityPage from '../../scenes/security_page';
import ProfilePage from '../../scenes/profile_page';
import DashboardV2Page from 'samples/dashboardV2_page';
import DatatablesPage from 'samples/datatables_page';
import ChartsPage from 'samples/charts_page';
import MapsPage from 'samples/maps_page';
import FormsPage from 'samples/forms_page';
import CampaignsPage from 'scenes/campaigns_list_page';
import CampaignPage from 'scenes/campaign_page';
import WalletPage from 'scenes/wallet_page';
import ADVDashboardFlightPage from 'scenes/advdashboardflight_page';
import ADVDashboardOnlinePage from 'scenes/advdashboardOnline_page';
import ADVDashboardTransportPage from 'scenes/advdashboardTransport_page';

import PubDashboardInflightPage from 'scenes/pubdashboardinflight_page';
import PubDashboardOnlinePage from 'scenes/pubdashboardonline_page';
import PubDashboardTransportPage from 'scenes/pubdashboardtransport_page';

import AdvertisementAprovalPage from 'scenes/advertisement_approval_page';
import AdvReportingInflightPage from 'scenes/adv_reporting_inflight_page';
import AdvReportingOnlinePage from 'scenes/adv_reporting_online_page';
import AdvReportingTransportPage from 'scenes/adv_reporting_transport_page';
import ApplicationPage from 'scenes/application_page';
import ApplicationDetailPage from 'scenes/application_detail_page';
import IPEAnalyticsPage from 'scenes/ipe_analytics_page';
import AdminPage from 'scenes/administrator_page';
import FlightPage from 'scenes/flights_page';
import TransportPage from 'scenes/transport_page';
import TransportsPage from 'scenes/transports_page';
import PublishersPage from 'scenes/publishers_page';
import PublisherPage from 'scenes/publisher_page';
import PublisherExclusivePage from 'scenes/publisher_exclusive_page';
import ExportPage from 'scenes/export_page';
import TransportsOnlinePage from 'scenes/transports_online_page';
import dspListPage from 'scenes/dsp_list_page';
import advertiserListPage from 'scenes/advertiser_list_page';
import PublisherEditPage from 'scenes/publisher_edit_page';
import AdvertisementTypePage from 'scenes/advertisement_type_page';
import CountryRatePage from 'scenes/country_rate_page';

import UserPriceCalculationPage from 'scenes/user_price_calculation_page';

interface IPage {
  path: string;
  component: any;
  roles: Array<number | string>;
}

interface INavigationConfig {
  rootPage: () => IPage;
  mainPage: () => IPage;
  // mapsPage: () => IPage;
  // formsPage: () => IPage;
  loginPage: () => IPage;
  // loginNoAuthPage: () => IPage;

  registerPage: () => IPage;
  // registerNoAuthPage: () => IPage;
  forgotPasswordPage: () => IPage;
  // forgotPasswordNoAuthPage: () => IPage;

  resetPasswordPage: (token?: string) => IPage;
  // dashboardBuilderPage: () => IPage;
  // securityPage: () => IPage;
  profilePage: () => IPage;
  // dashboardV2Page: () => IPage;
  // datatablesPage: () => IPage;
  // chartsPage: () => IPage;
  // generatorPage: () => IPage;

  campaignsPage: () => IPage;
  campaignPage: (id?: string) => IPage;
  walletPage: () => IPage;
  advDashboardFlightPage: () => IPage;
  advDashboardOnlinePage: () => IPage;
  advDashboardTransportPage: () => IPage;
  pubDashboardInFlightPage: () => IPage;
  pubDashboardOnlinePage: () => IPage;
  pubDashboardTransportPage: () => IPage;
  advertisementApprovalPage: () => IPage;
  advReportingInflightPage: () => IPage;
  advReportingOnlinePage: () => IPage;
  advReportingTransportPage: () => IPage;
  applicationPage: () => IPage;
  applicationDetailPage: () => IPage;
  ifeAnalyticsPage: () => IPage;
  adminPage: () => IPage;
  flightPage: () => IPage;
  transportPage: () => IPage;
  transportsPage: () => IPage;
  publisherPage: () => IPage;
  publishersPage: () => IPage;
  publisherExclusivePage: (id?: string) => IPage;
  exportPage: () => IPage;
  transportsOnlinePage: () => IPage;
  dspListPage: () => IPage;
  advertiserListPage: () => IPage;
  publisherEditPage: (id?: string) => IPage;
  advertisementTypePage: () => IPage;
  countryRatePage: () => IPage;
  advertiserCalculationPage: (id?: string) => IPage;
}

interface IAppbarsConfig {
  primaryNav: INav;
  secondaryNav: INav;
}

interface INav {
  component: any;
  include: 'all' | string[] | null;
  exclude: 'all' | string[] | null;
  select: 'include' | 'exclude';
  // roles: Array<string>;
}

// console.log('All users:', Config.AuthRAuthRoles.All);

const NavigationConfig: INavigationConfig = {
  rootPage: (params?: any): IPage => ({
    path: '*',
    component: RootPage,
    roles: Config.AuthRoles.All,
  }),
  mainPage: (params?: any) => ({
    path: `/`,
    component: MainPage,
    roles: Config.AuthRoles.All,
  }),
  // formsPage: (params?: any) => ({
  //   path: `/forms`,
  //   component: FormsPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // mapsPage: () => ({
  //   path: `/maps`,
  //   component: MapsPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // dashboardV2Page: (params?: any): IPage => ({
  //   path: '/dashboard/v2',
  //   component: DashboardV2Page,
  //   roles: Config.AuthRoles.All,
  // }),
  loginPage: () => ({
    path: '/login',
    component: LoginPage,
    roles: [Config.AuthRoles.Guest],
  }),
  // loginNoAuthPage: () => ({
  //   path: '/no-auth/login',
  //   component: LoginPage,
  //   roles: Config.AuthRoles.All,
  // }),
  registerPage: () => ({
    path: '/register',
    component: RegisterPage,
    roles: [Config.AuthRoles.Guest],
  }),
  // registerNoAuthPage: () => ({
  //   path: '/no-auth/register',
  //   component: RegisterPage,
  //   roles: Config.AuthRoles.All,
  // }),
  forgotPasswordPage: () => ({
    path: '/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  // forgotPasswordNoAuthPage: () => ({
  //   path: '/no-auth/forgot',
  //   component: ForgotPasswordPage,
  //   roles: Config.AuthRoles.All,
  // }),
  resetPasswordPage: (token = ':token') => ({
    path: `/reset-password/${token}`,
    component: ResetPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  // dashboardBuilderPage: () => ({
  //   path: '/dashboard-builder',
  //   component: DashboardBuilderPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // datatablesPage: () => ({
  //   path: `/datatables`,
  //   component: DatatablesPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // securityPage: () => ({
  //   path: `/security`,
  //   component: SecurityPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  profilePage: () => ({
    path: `/profile`,
    component: ProfilePage,
    roles: Config.AuthRoles.Registered,
  }),
  // chartsPage: () => ({
  //   path: `/charts`,
  //   component: ChartsPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  campaignsPage: () => ({
    path: `/campaigns`,
    component: CampaignsPage,
    roles: [
      ...Config.AuthRoles.Advertisers,
      ...Config.AuthRoles.AdvertisersTransport,
    ],
  }),
  campaignPage: (id = ':id') => ({
    path: `/campaign/${id}`,
    component: CampaignPage,
    roles: [
      ...Config.AuthRoles.Advertisers,
      ...Config.AuthRoles.AdvertisersTransport,
    ],
  }),
  walletPage: () => ({
    path: `/wallet`,
    component: WalletPage,
    roles: Config.AuthRoles.Registered,
  }),
  advDashboardFlightPage: () => ({
    path: `/adv-dashboard-flight`,
    component: ADVDashboardFlightPage,
    roles: Config.AuthRoles.Advertisers,
  }),
  advDashboardOnlinePage: () => ({
    path: `/adv-dashboard-online`,
    component: ADVDashboardOnlinePage,
    roles: Config.AuthRoles.AdvertisersWebsite,
  }),
  advDashboardTransportPage: () => ({
    path: `/adv-dashboard-transport`,
    component: ADVDashboardTransportPage,
    roles: Config.AuthRoles.AdvertisersTransport,
  }),
  pubDashboardInFlightPage: () => ({
    path: `/pub-dashboard-inflight`,
    component: PubDashboardInflightPage,
    roles: Config.AuthRoles.Publishers,
  }),
  pubDashboardOnlinePage: () => ({
    path: `/pub-dashboard-online`,
    component: PubDashboardOnlinePage,
    roles: Config.AuthRoles.PublishersWebsite,
  }),
  pubDashboardTransportPage: () => ({
    path: `/pub-dashboard-transport`,
    component: PubDashboardTransportPage,
    roles: Config.AuthRoles.PublishersTransport,
  }),
  advertisementApprovalPage: () => ({
    path: `/advertisement-approval`,
    component: AdvertisementAprovalPage,
    roles: [
      ...Config.AuthRoles.Admin,
      ...Config.AuthRoles.AdvertisersTransport,
    ],
  }),
  advReportingInflightPage: () => ({
    path: `/adv-reporting-inflight`,
    component: AdvReportingInflightPage,
    roles: Config.AuthRoles.Advertisers,
  }),
  advReportingOnlinePage: () => ({
    path: `/adv-reporting-online`,
    component: AdvReportingOnlinePage,
    roles: Config.AuthRoles.AdvertisersWebsite,
  }),
  advReportingTransportPage: () => ({
    path: `/adv-reporting-transport`,
    component: AdvReportingTransportPage,
    roles: Config.AuthRoles.AdvertisersTransport,
  }),
  applicationPage: () => ({
    path: `/application`,
    component: ApplicationPage,
    roles: Config.AuthRoles.Publishers,
  }),
  applicationDetailPage: () => ({
    path: `/application-detail/:uniqueid`,
    component: ApplicationDetailPage,
    roles: Config.AuthRoles.Publishers,
  }),
  ifeAnalyticsPage: () => ({
    path: `/ife-analytics`,
    component: IPEAnalyticsPage,
    roles: Config.AuthRoles.Publishers,
  }),
  adminPage: () => ({
    path: `/admin`,
    component: AdminPage,
    roles: Config.AuthRoles.Admin,
  }),
  flightPage: () => ({
    path: `/flight`,
    component: FlightPage,
    roles: Config.AuthRoles.Publishers,
  }),
  transportsPage: () => ({
    path: `/transports`,
    component: TransportsPage,
    // roles: Config.AuthRoles.Admin,
    roles: [
      ...Config.AuthRoles.AdvertisersTransport,
      ...Config.AuthRoles.PublishersTransport,
    ],
  }),
  transportPage: () => ({
    path: `/transport`,
    component: TransportPage,
    // roles: Config.AuthRoles.Admin,
    roles: [
      ...Config.AuthRoles.AdvertisersTransport,
      ...Config.AuthRoles.PublishersTransport,
    ],
  }),
  publishersPage: () => ({
    path: `/publishers`,
    component: PublishersPage,
    roles: Config.AuthRoles.Publishers,
  }),
  publisherPage: () => ({
    path: `/publisher`,
    component: PublisherPage,
    roles: Config.AuthRoles.Publishers,
  }),
  publisherExclusivePage: (id = ':id') => ({
    path: `/publisher/exclusive/${id}`,
    component: PublisherExclusivePage,
    roles: Config.AuthRoles.Publishers,
  }),
  exportPage: () => ({
    path: `/export-pdf`,
    component: ExportPage,
    roles: Config.AuthRoles.Registered,
  }),
  transportsOnlinePage: () => ({
    path: `/transports-online`,
    component: TransportsOnlinePage,
    // roles: Config.AuthRoles.Admin,
    roles: [
      ...Config.AuthRoles.AdvertisersTransport,
      ...Config.AuthRoles.PublishersTransport,
    ],
  }),
  dspListPage: () => ({
    path: `/dsp-list`,
    component: dspListPage,
    roles: Config.AuthRoles.Admin,
  }),
  advertiserListPage: () => ({
    path: `/advertiser-list`,
    component: advertiserListPage,
    roles: Config.AuthRoles.Admin,
  }),
  publisherEditPage: (id = ':id') => ({
    path: `/publisher/${id}`,
    component: PublisherEditPage,
    roles: Config.AuthRoles.Publishers,
  }),
  advertisementTypePage: () => ({
    path: `/advertisements-type`,
    component: AdvertisementTypePage,
    roles: Config.AuthRoles.Advertisers,
  }),
  countryRatePage: () => ({
    path: `/country-rate`,
    component: CountryRatePage,
    roles: Config.AuthRoles.Admin,
  }),
  advertiserCalculationPage: (id = ':id') => ({
    path: `/advertiser-calculation/${id}`,
    component: UserPriceCalculationPage,
    roles: Config.AuthRoles.Admin,
  }),
};

export const AppbarsConfig: IAppbarsConfig = {
  primaryNav: {
    component: AppbarMenu,
    include: null,
    exclude: [
      NavigationConfig.loginPage().path,
      NavigationConfig.registerPage().path,
      NavigationConfig.forgotPasswordPage().path,
      NavigationConfig.resetPasswordPage().path,
    ],
    select: 'exclude',
  },
  secondaryNav: {
    component: AppbarMenu,
    include: [],
    exclude: 'all',
    select: 'exclude',
  },
};

export const Appbars: Array<INav> = Object.values(AppbarsConfig);
export default NavigationConfig;
