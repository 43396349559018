import { IGridConf, ComponentTypeEnum } from '../configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import _ from 'lodash';
import { Theme } from '@material-ui/core';

const GridConf: IGridConf[] = [
  {
    width: 3,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#4eb8f5',
      title: '',
      description: '',
      titleColor: 'blue',
      descriptionColor: 'white',
    },
    fetch: '/api/dashboard/statisticdata',
  },
  {
    width: 3,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#4eb8f5',
      title: '',
      description: '',
      titleColor: 'blue',
      descriptionColor: 'white',
    },
    fetch: '/api/dashboard/statisticdata',
  },
  {
    width: 3,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#4eb8f5',
      title: '',
      description: '',
      titleColor: 'blue',
      descriptionColor: 'white',
    },
    fetch: '/api/dashboard/statisticdata',
  },
  {
    width: 3,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#4eb8f5',
      title: '',
      description: '',
      titleColor: 'blue',
      descriptionColor: 'white',
    },
    fetch: '/api/dashboard/statisticdata',
  },
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          data: [],
          label: '',
          format: {
            label: 'Dataset 1',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#1e769c',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.COLUMN,
      options: (theme: Theme) => ({
        legend: {
          display: true,
          align: 'start',
          labels: {
            usePointStyle: true,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
        },
      }),
      title: 'This is a sample graph',
    },
    fetch: '/api/dashboard/graphdata',
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          data: [],
          label: '',
          format: {
            label: 'Dataset 1',
            fill: false,
            borderColor: '#27db7e',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#27db7e',
            pointBorderColor: '#27db7e',
            pointBackgroundColor: '#27db7e',
            pointHoverBackgroundColor: '#FF6100',
            pointHoverBorderColor: '#27db7e',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.BAR,
      options: () => ({
        legend: {
          display: true,
          align: 'start',
          labels: {
            usePointStyle: true,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
        },
      }),
      title: 'This is a sample graph',
    },
    fetch: '/api/dashboard/graphdata',
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          data: [],
          label: '',
          format: {
            label: 'Dataset 1',
            fill: false,
            borderColor: '#4eb8f5',
            type: 'line',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#4eb8f5',
            pointBorderColor: '#4eb8f5',
            pointBackgroundColor: '#4eb8f5',
            pointHoverBackgroundColor: '#4eb8f5',
            pointHoverBorderColor: '#4eb8f5',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: 'Dataset 2',
            fill: false,
            borderColor: '#db2745',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#db2745',
            pointBorderColor: '#db2745',
            pointBackgroundColor: '#db2745',
            pointHoverBackgroundColor: '#db2745',
            pointHoverBorderColor: '#db2745',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.COLUMN,
      options: () => ({
        legend: {
          display: true,
          align: 'start',
          labels: {
            usePointStyle: true,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
        },
      }),
      title: 'This is a sample graph',
    },
    fetch: '/api/dashboard/graphdata',
  },
];

export default GridConf;
