import { LanguagesEnum } from '../../config/languages';

const Translations = {
  login_title: {
    [LanguagesEnum.English]: 'Login',
    [LanguagesEnum.Greek]: 'Σύνδεση',
    [LanguagesEnum.Russian]: 'Авторизоваться',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Αυτό είναι απαραίτητο',
    [LanguagesEnum.Russian]: 'Это требуется',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email',
    [LanguagesEnum.Greek]: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    [LanguagesEnum.Russian]: 'Электронное письмо',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός πρόσβασης',
    [LanguagesEnum.Russian]: 'Пароль',
  },
  form_button_login: {
    [LanguagesEnum.English]: 'Login',
    [LanguagesEnum.Greek]: 'Σύνδεση',
    [LanguagesEnum.Russian]: 'Авторизоваться',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password',
    [LanguagesEnum.Greek]: 'Ξεχάσατε τον κωδικό',
    [LanguagesEnum.Russian]: 'Забыл пароль',
  },
  messgae_wrong_credentials: {
    [LanguagesEnum.English]: 'Wrong Credentials',
    [LanguagesEnum.Greek]: 'λάθος Διαπιστευτήρια',
    [LanguagesEnum.Russian]: 'Неверные учетные данные',
  },
  email_format_error: {
    [LanguagesEnum.English]: 'Invalid email address',
    [LanguagesEnum.Greek]: 'Μη έγκυρη διεύθυνση e-mail',
    [LanguagesEnum.Russian]: 'Неверный адрес электронной почты',
  },
};

export default Translations;
