import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  airlinesList: {
    status: StateStatus | null;
    data: any;
  };
  addAirlines: {
    status: StateStatus | null;
  };
  editAirlines: {
    status: StateStatus | null;
  };
  viewAirlines: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  airlinesList: {
    status: null,
    data: null,
  },
  addAirlines: {
    status: null,
  },
  editAirlines: {
    status: null,
  },
  viewAirlines: {
    status: null,
    data: null,
  },
};

const AirlinesReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_AIRLINES_LIST:
      return {
        ...state,
        airlinesList: {
          ...state.airlinesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.airlinesList.data
              : action.data,
        },
      };
    case Types.SET_ADD_AIRLINES:
      return {
        ...state,
        addAirlines: {
          ...state.addAirlines,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_AIRLINES:
      return {
        ...state,
        editAirlines: {
          ...state.editAirlines,
          status: action.status || null,
        },
      };
    case Types.SET_AIRLINES_DETAIL:
      return {
        ...state,
        viewAirlines: {
          ...state.viewAirlines,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewAirlines.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AirlinesReducer;
