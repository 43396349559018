import React, { useEffect } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PageLayout from 'components/app_common/page_layout';
import DashboardBuilder, {
  CustomConfig,
  SimpleConfig,
} from 'components/compounds/dashboard_builder';
import SearchArea from './components/SearchArea';
import GridLayout from 'components/molecules/grid_layout';
import getStatisticConfig from './configuration/StatisticConfig';
import getLineChartConfig from './configuration/LineConfig';
import getTrackingConfig from './configuration/TrackingConfig';
import getViewPerDeviceConfig from './configuration/ViewPerDeviceConfig';
import getPassengerConfig from './configuration/passengerByDestinationAirport';
import getHeatMapConfig from './configuration/HeatMapConfig';
import getBarChartConfig from './configuration/BarChartConfig';

import Actions from 'redux/reducers/ife_analytics_reducer/actions';
import {
  useGetTopResult,
  useGetPassengerByDate,
  useGetPassengerByDestinationAirport,
  useGetPassengerByDepartureAirport,
  useGetTrackingByDeparture,
  useGetTrackingByDestination,
  useGetViewPerDevice,
  useGetTop10VistingTag,
  useGetTop10VistingURL,
} from 'redux/reducers/ife_analytics_reducer/selectors';
import {
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';

export default function IpeAnalytics() {
  const dispatch = useDispatch();
  const topResult = useGetTopResult();
  const { id } = useSelectUserData();
  const roles = useSelectUserRoles();
  const passengerByDate = useGetPassengerByDate();
  const passengerByDestinationAirport = useGetPassengerByDestinationAirport();
  const passengerByDepartureAirport = useGetPassengerByDepartureAirport();

  const top10VisitingTag = useGetTop10VistingTag();
  const top10VisitingURL = useGetTop10VistingURL();

  const trackingByDeparture = useGetTrackingByDeparture();
  const trackingByDestination = useGetTrackingByDestination();
  const viewPerDevice = useGetViewPerDevice();

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  // fetch chart data
  useEffect(() => {
    let userid = getParameterByName('userid');
    const datefrom = getParameterByName('datefrom');
    const dateto = getParameterByName('dateto');
    if (roles.includes(Roles.Publisher)) {
      userid = id;
    }
    dispatch(
      Actions.fetchTopResultAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchPassengerByDateAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchPassengerByDestinationAirportAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchPassengerByDepartureAirportAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchTrackingrByDepartureAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchTrackingrByDestinationAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchViewPerDeviceAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchTop10VistingTagAction({
        datefrom,
        dateto,
        userid,
      })
    );
    dispatch(
      Actions.fetchTop10VistingURLAction({
        datefrom,
        dateto,
        userid,
      })
    );
  }, []);

  // fetch dropdown option
  useEffect(() => {
    dispatch(Actions.fetchUserListAction({}));
  }, []);

  return (
    <PageLayout>
      <GridLayout
        justify="center"
        elements={[
          {
            id: 'search',
            size: 12,
            element: <SearchArea />,
          },
          {
            id: 'statistic',
            size: 12,
            element: (
              <React.Fragment>
                {topResult.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getStatisticConfig(topResult.data)}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'passenger line chart',
            size: 12,
            element: (
              <React.Fragment>
                {passengerByDate.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineChartConfig(passengerByDate.data)}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'passenger destination airport',
            size: 6,
            element: (
              <React.Fragment>
                {passengerByDestinationAirport.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarChartConfig({
                      data: passengerByDestinationAirport.data.map(
                        (item: any) => item.passengers
                      ),
                      labels: passengerByDestinationAirport.data.map(
                        (item: any) => item.ArrAirport
                      ),
                      title: 'Passenger By Destination Airport',
                    })}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'passenger departure airport',
            size: 6,
            element: (
              <React.Fragment>
                {passengerByDepartureAirport.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarChartConfig({
                      data: passengerByDepartureAirport.data.map(
                        (item: any) => item.passengers
                      ),
                      labels: passengerByDepartureAirport.data.map(
                        (item: any) => item.DeptAirport
                      ),
                      title: 'Passenger By Departure Airport',
                    })}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'top 10 visiting tag',
            size: 6,
            element: (
              <React.Fragment>
                {top10VisitingTag.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getHeatMapConfig(
                      top10VisitingTag.data,
                      'DeptAirport',
                      'tag',
                      'Tags Visited by Departure Airport'
                    )}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'top 10 visiting urls',
            size: 6,
            element: (
              <React.Fragment>
                {top10VisitingURL.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getHeatMapConfig(
                      top10VisitingURL.data,
                      'DeptAirport',
                      'title',
                      'Urls Visited by Departure Airport '
                    )}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'tracking by departure',
            size: 6,
            element: (
              <React.Fragment>
                {trackingByDeparture.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getTrackingConfig(
                      trackingByDeparture.data,
                      'Departure',
                      'DeptAirport'
                    )}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'tracking by destination',
            size: 6,
            element: (
              <React.Fragment>
                {trackingByDestination.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getTrackingConfig(
                      trackingByDestination.data,
                      'Destination',
                      'ArrAirport'
                    )}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'view per device',
            size: 12,
            element: (
              <React.Fragment>
                {viewPerDevice.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getViewPerDeviceConfig(viewPerDevice.data)}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
        ]}
      />
    </PageLayout>
  );
}
