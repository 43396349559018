import React, { useEffect, useState } from 'react';
import {
  Star,
  ImportantDevices,
  BarChartOutlined,
  FlightLand,
} from '@material-ui/icons';
import { withRouter, useHistory } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import Datatable from 'components/molecules/datatable';
import PageLayout from 'components/app_common/page_layout';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import DashboardBuilder, {
  CustomConfig,
  SimpleConfig,
} from 'components/compounds/dashboard_builder';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { TableColumns } from './configuration';
import getConfigCTR from './configuration/configCTR';
import getConfigClick from './configuration/configClick';
import getConfigImpressionViewCountry from './configuration/configImpressionViewCountry';
import getConfigImpressionClickCountry from './configuration/configImpressionClickCountry';
import getConfigImpressionDestination from './configuration/configImpressionDestination';
import getConfigClickDestination from './configuration/configClickDestination';
import SearchArea from './components/SearchArea';
import Widget2 from './components/widget2';
import Progress from './components/Progress';
import Map from './components/Map';
import GridLayout from 'components/molecules/grid_layout';
import Actions from 'redux/reducers/adv_dashboard_reducer/actions';
import {
  useGetCampaignsStats,
  useGetCountryClickView,
  useGetTop5Campaign,
  useGetTotalClickByArrCountry,
  useGetTotalViewByArrCountry,
  useGetDeviceClickView,
  useGetTotalCountryClick,
  useGetTotalCountryView,
  useGetTotalImpressionClickByCampaign,
} from 'redux/reducers/adv_dashboard_reducer/selectors';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';
import { CampaignEnv } from 'config/campaign_environemnt'

function LeftWidget(props: any) {
  const ctr = Math.round(
    (props.campaignStats?.data?.totalclicks /
      props.campaignStats?.data?.totalviews) *
      100
  );
  if (props.campaignStats.status === 'Success') {
    return (
      <Widget2
        data={{
          title: `${ctr}%`,
          body: <TranslatedText textMap={Translations.ctr_widget} />,
          description: <TranslatedText textMap={Translations.ctr_widget_description} />

        }}
      />
    );
  } else {
    return (
      <Widget2
        data={{
          title: `0%`,
          body: <TranslatedText textMap={Translations.ctr_widget} />,
          description: <TranslatedText textMap={Translations.ctr_widget_description} />

        }}
      />
    );
  }
}

const AdvDashboardPage = (props: any) => {
  reactLogger.renderPage('Adv Dashboard Page');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useSelectUserData();
  const campaignStats = useGetCampaignsStats();
  const countryClickView = useGetCountryClickView();
  const top5Campaign = useGetTop5Campaign();
  const totalImpressionClickByCampaign = useGetTotalImpressionClickByCampaign();
  const totalClickByArrCountry = useGetTotalClickByArrCountry();
  const totalViewByArrCountry = useGetTotalViewByArrCountry();
  const deviceClickView = useGetDeviceClickView();
  const totalCountryClick = useGetTotalCountryClick();
  const totalCountryView = useGetTotalCountryView();
  const history = useHistory();

  const [progressClicks, setProgressClicks] = useState<any>([]);
  const [progressViews, setProgressViews] = useState<any>([]);
  const [top5campaignData, setTop5CampaignData] = useState([]);

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  useEffect(() => {
    const userid = getParameterByName('userid');
    const datefrom = getParameterByName('datefrom');
    const dateto = getParameterByName('dateto');
    dispatch(
      Actions.fetchCampaignsStatsAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchCountryClickViewAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchTotalImpressionClickByCampaignAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchTotalViewByArrCountryAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchTotalClickByArrCountryAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchDeviceClickViewAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Online
      })
    );
    dispatch(
      Actions.fetchTotalCountryClickAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalCountryViewAction({
        userid,
        datefrom,
        dateto,
      })
    );
  }, []);

  useEffect(() => {
    if (countryClickView.status === 'Success') {
      const maxClicks = Math.max(
        ...countryClickView.data.map((item: any) => {
          return item.totalclicks;
        })
      );
      const maxViews = Math.max(
        ...countryClickView.data.map((item: any) => {
          return item.totalviews;
        })
      );
      setProgressClicks(
        countryClickView.data
          .sort((a: any, b: any) => {
            return b.totalclicks - a.totalclicks;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalclicks / (maxClicks + 10)).toFixed(2)
            );

            return {
              percentage: percent * 100,
              value: item.totalclicks,
              name: item.country,
            };
          })
      );
      setProgressViews(
        countryClickView.data
          .sort((a: any, b: any) => {
            return b.totalviews - a.totalviews;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalviews / (maxViews + 10)).toFixed(2)
            );
            return {
              percentage: percent * 100,
              value: item.totalviews,
              name: item.country,
            };
          })
      );
    }
  }, [countryClickView]);

  // fetch dropdown option
  useEffect(() => {
    dispatch(Actions.fetchUserListAction({}));
  }, []);

  useEffect(() => {
    if (top5Campaign.status === 'Success') {
      setTop5CampaignData(top5Campaign.data);
    }
  }, [top5Campaign]);

  return (
    <PageLayout>
      <GridLayout
        elements={[
          {
            id: 'Search',
            size: 12,
            element: <SearchArea />,
          },
          {
            id: 'widget-left',
            size: 4,
            element: <LeftWidget campaignStats={campaignStats} />,
          },
          {
            id: 'widget-mid',
            size: 4,
            element: (
              <Widget2
                data={{
                  title: campaignStats?.data?.totalviews,
                  body: (
                    <TranslatedText textMap={Translations.impression_widget} />
                  ),
                  description: <TranslatedText textMap={Translations.impression_widget_description} />

                }}
              />
            ),
          },
          {
            id: 'widget-right',
            size: 4,
            element: (
              <Widget2
                data={{
                  title: campaignStats?.data?.totalclicks,
                  body: <TranslatedText textMap={Translations.click_widget} />,
                  description: <TranslatedText textMap={Translations.click_widget_description} />

                }}
              />
            ),
          },
          {
            id: 'Progress-click',
            size: 12,
            element: (
              <Card className={classes.mt12}>
                <CardContent>
                  <GridLayout
                    elements={[
                      {
                        id: 'progress-view',
                        size: 4,
                        element: (
                          <Progress
                            type={
                              <TranslatedText
                                textMap={Translations.impression_widget}
                              />
                            }
                            data={progressViews}
                          />
                        ),
                      },
                      {
                        id: 'progress-click',
                        size: 4,
                        element: (
                          <Progress
                            type={
                              <TranslatedText
                                textMap={Translations.click_widget}
                              />
                            }
                            data={progressClicks}
                          />
                        ),
                      },
                      {
                        id: 'map',
                        size: 4,
                        element: <Map />,
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            id: 'left-size',
            size: 7,
            element: (
              <Card>
                <CardHeader
                  title={
                    <div className={classes.tableHeader}>
                      <div className={classes.textBlue}>
                        <Star />{' '}
                        <TranslatedText
                          textMap={Translations.top_campaign_title}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/campaigns')}
                      >
                        <TranslatedText
                          textMap={Translations.go_to_campaign_button}
                        />
                      </Button>
                    </div>
                  }
                />
                <CardContent>
                  <Datatable
                    height={655}
                    loading={true}
                    data={top5campaignData}
                    columns={TableColumns}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            id: 'right-size',
            size: 5,
            element: (
              <GridLayout
                elements={[
                  {
                    id: 'pie-ctr',
                    size: 12,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <div className={classes.tableHeader}>
                              <div className={classes.textBlue}>
                                <ImportantDevices style={{ marginRight: 10 }} />
                                <TranslatedText
                                  textMap={
                                    Translations.campaign_pie_chart_title
                                  }
                                />
                              </div>
                            </div>
                          }
                        />
                        <CardContent>
                          {totalImpressionClickByCampaign.status ===
                          'Success' ? (
                            <DashboardBuilder
                              configuration={getConfigCTR(
                                totalImpressionClickByCampaign.data
                              )}
                            />
                          ) : null}
                          {/* <PieChart series={ctrs} labels={campaignName} /> */}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'pie-device',
                    size: 12,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <div className={classes.tableHeader}>
                              <div className={classes.textBlue}>
                                <ImportantDevices style={{ marginRight: 10 }} />
                                <TranslatedText
                                  textMap={Translations.device_pie_chart_title}
                                />
                              </div>
                            </div>
                          }
                        />
                        <CardContent>
                          {deviceClickView.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getConfigClick(
                                deviceClickView.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                ]}
              />
            ),
          },
          // {
          //   id: 'Barchart-top',
          //   size: 12,
          //   element: (
          //     <Card className={classes.mt12}>
          //       <CardContent>
          //         <GridLayout
          //           elements={[
          //             {
          //               id: 'impression-per-country',
          //               size: 6,
          //               element: (
          //                 <Card>
          //                   <CardHeader
          //                     title={
          //                       <div className={classes.tableHeader}>
          //                         <div className={classes.textBlue}>
          //                           <BarChartOutlined />{' '}
          //                           <TranslatedText
          //                             textMap={
          //                               Translations.impression_per_country
          //                             }
          //                           />
          //                         </div>
          //                       </div>
          //                     }
          //                   />
          //                   <CardContent>
          //                     {totalCountryView.status === 'Success' ? (
          //                       <DashboardBuilder
          //                         configuration={getConfigImpressionViewCountry(
          //                           totalCountryView.data
          //                         )}
          //                       />
          //                     ) : null}
          //                   </CardContent>
          //                 </Card>
          //               ),
          //             },
          //             {
          //               id: 'click-per-country',
          //               size: 6,
          //               element: (
          //                 <Card>
          //                   <CardHeader
          //                     title={
          //                       <div className={classes.tableHeader}>
          //                         <div className={classes.textBlue}>
          //                           <BarChartOutlined />{' '}
          //                           <TranslatedText
          //                             textMap={Translations.clicks_per_country}
          //                           />
          //                         </div>
          //                       </div>
          //                     }
          //                   />
          //                   <CardContent>
          //                     {totalCountryClick.status === 'Success' ? (
          //                       <DashboardBuilder
          //                         configuration={getConfigImpressionClickCountry(
          //                           totalCountryClick.data
          //                         )}
          //                       />
          //                     ) : null}
          //                   </CardContent>
          //                 </Card>
          //               ),
          //             },
          //           ]}
          //         />
          //       </CardContent>
          //     </Card>
          //   ),
          // },
          // {
          //   id: 'Barchart-bottom',
          //   size: 12,
          //   element: (
          //     <Card className={classes.mt12}>
          //       <CardContent>
          //         <GridLayout
          //           elements={[
          //             {
          //               id: 'impression-per-destination',
          //               size: 6,
          //               element: (
          //                 <Card>
          //                   <CardHeader
          //                     title={
          //                       <div className={classes.tableHeader}>
          //                         <div className={classes.textBlue}>
          //                           <FlightLand />{' '}
          //                           <TranslatedText
          //                             textMap={
          //                               Translations.impression_per_destination
          //                             }
          //                           />
          //                         </div>
          //                       </div>
          //                     }
          //                   />
          //                   <CardContent>
          //                     {totalViewByArrCountry.status === 'Success' ? (
          //                       <DashboardBuilder
          //                         configuration={getConfigImpressionDestination(
          //                           totalViewByArrCountry.data
          //                         )}
          //                       />
          //                     ) : null}
          //                   </CardContent>
          //                 </Card>
          //               ),
          //             },
          //             {
          //               id: 'click-per-country',
          //               size: 6,
          //               element: (
          //                 <Card>
          //                   <CardHeader
          //                     title={
          //                       <div className={classes.tableHeader}>
          //                         <div className={classes.textBlue}>
          //                           <FlightLand />{' '}
          //                           <TranslatedText
          //                             textMap={
          //                               Translations.clicks_per_destination
          //                             }
          //                           />
          //                         </div>
          //                       </div>
          //                     }
          //                   />
          //                   <CardContent>
          //                     {totalClickByArrCountry.status === 'Success' ? (
          //                       <DashboardBuilder
          //                         configuration={getConfigClickDestination(
          //                           totalClickByArrCountry.data
          //                         )}
          //                       />
          //                     ) : null}
          //                   </CardContent>
          //                 </Card>
          //               ),
          //             },
          //           ]}
          //         />
          //       </CardContent>
          //     </Card>
          //   ),
          // },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(AdvDashboardPage);
