import _ from 'lodash';
import React from 'react';
import { Switch, Route } from 'react-router-dom'; // import the react-router-dom components
import NavigationConfig, { Appbars } from '.';
import { RequireAuth, WithNavbar } from '../middlewares';
import UserRoles, { Roles } from '../../config/authRoles';
import * as ArrayUtils from 'utils/arrays';
import Icon from '@material-ui/core/Icon';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import Assessment from '@material-ui/icons/Assessment';
import AppsOutlined from '@material-ui/icons/AppsOutlined';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { DashboardOutlined, DirectionsBus } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import FlightOutlined from '@material-ui/icons/FlightOutlined';
import ReportIcon from 'assets/images/icons/report.svg';
interface IProps {
  routes: Routes[];
}

interface Routes {
  exact: boolean;
  path: string;
  component: any;
  roles: Array<string | number>;
}

const StackNavigator = ({ routes }: IProps) => {
  console.log('Stack naviator...');
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={route.exact}
          path={route.path}
          render={() => (
            <RequireAuth Component={route.component} roles={route.roles} />
          )}
        />
      ))}
    </Switch>
  );
};

export const MainRoutes = () => {
  return (
    <main>
      <Switch>
        {Appbars.map((route, i) => (
          <Route
            key={i}
            exact={false}
            path={'/'}
            render={() => (
              <WithNavbar
                include={route.include}
                exclude={route.exclude}
                select={route.select}
                Component={route.component}
              />
            )}
          />
        ))}
      </Switch>
    </main>
  );
};

/** CHANGE THIS TO PUT NEW ROUTES */
export const Routes = React.memo(() => {
  return (
    <main>
      <StackNavigator
        routes={[
          // { ...NavigationConfig.dashboardV2Page(), exact: true },
          { ...NavigationConfig.profilePage(), exact: true },
          // { ...NavigationConfig.datatablesPage(), exact: true },
          // { ...NavigationConfig.securityPage(), exact: true },
          { ...NavigationConfig.registerPage(), exact: true },
          // { ...NavigationConfig.registerNoAuthPage(), exact: true },
          // { ...NavigationConfig.formsPage(), exact: true },

          { ...NavigationConfig.loginPage(), exact: true },
          // { ...NavigationConfig.loginNoAuthPage(), exact: true },

          { ...NavigationConfig.mainPage(), exact: true },

          { ...NavigationConfig.forgotPasswordPage(), exact: true },
          // { ...NavigationConfig.forgotPasswordNoAuthPage(), exact: true },

          { ...NavigationConfig.resetPasswordPage(), exact: true },
          // { ...NavigationConfig.dashboardBuilderPage(), exact: true },
          // { ...NavigationConfig.chartsPage(), exact: true },
          // { ...NavigationConfig.mapsPage(), exact: true },

          { ...NavigationConfig.campaignsPage(), exact: true },
          { ...NavigationConfig.campaignPage(), exact: true },
          { ...NavigationConfig.walletPage(), exact: true },
          { ...NavigationConfig.advDashboardFlightPage(), exact: true },
          { ...NavigationConfig.advDashboardOnlinePage(), exact: true },
          { ...NavigationConfig.advDashboardTransportPage(), exact: true },
          { ...NavigationConfig.pubDashboardInFlightPage(), exact: true },
          { ...NavigationConfig.pubDashboardOnlinePage(), exact: true },
          { ...NavigationConfig.pubDashboardTransportPage(), exact: true },
          { ...NavigationConfig.advertisementApprovalPage(), exact: true },
          { ...NavigationConfig.advReportingInflightPage(), exact: true },
          { ...NavigationConfig.advReportingOnlinePage(), exact: true },
          { ...NavigationConfig.advReportingTransportPage(), exact: true },
          { ...NavigationConfig.applicationPage(), exact: true },
          { ...NavigationConfig.applicationDetailPage(), exact: true },
          { ...NavigationConfig.ifeAnalyticsPage(), exact: true },
          { ...NavigationConfig.adminPage(), exact: true },
          { ...NavigationConfig.flightPage(), exact: true },
          { ...NavigationConfig.transportPage(), exact: true },
          { ...NavigationConfig.transportsPage(), exact: true },
          { ...NavigationConfig.publisherPage(), exact: true },
          { ...NavigationConfig.publishersPage(), exact: true },
          { ...NavigationConfig.publisherExclusivePage(), exact: true },
          { ...NavigationConfig.publisherEditPage(), exact: true },
          { ...NavigationConfig.transportsOnlinePage(), exact: true },
          { ...NavigationConfig.exportPage(), exact: true },
          { ...NavigationConfig.dspListPage(), exact: true },
          { ...NavigationConfig.advertiserListPage(), exact: true },
          { ...NavigationConfig.advertisementTypePage(), exact: true },
          { ...NavigationConfig.countryRatePage(), exact: true },
          { ...NavigationConfig.advertiserCalculationPage(), exact: true },

          { ...NavigationConfig.rootPage(), exact: true }, // always last
        ]}
      />
    </main>
  );
});

interface ISidebarConfig {
  title: string | React.ReactElement;
  path: string | null;
  Icon: React.FC;
  id: number;
  component: React.FC | null;
  subItems?: ISubItems[];
  requiredRoles: Array<number | string> | null;
}

interface ISubItems {
  path: string;
  title: string | React.ReactElement;
  component: React.FC;
  id: number;
  requiredRoles: Array<number | string>;
}

export const SideBarConfig: ISidebarConfig[] = [
  // {
  //   title: <TranslatedText textMap={Translations.dashboards} />,
  //   path: null,
  //   requiredRoles: UserRoles.Admin,
  //   Icon: () => <DashboardOutlined />,
  //   id: 1,
  //   component: null,
  //   subItems: [
  //     {
  //       path: NavigationConfig.advDashboardFlightPage().path,
  //       title: <TranslatedText textMap={Translations.adv_dashboard_flight} />,
  //       component: NavigationConfig.advDashboardFlightPage().component,
  //       id: 0,
  //       requiredRoles: NavigationConfig.advDashboardFlightPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.advDashboardOnlinePage().path,
  //       title: <TranslatedText textMap={Translations.adv_dashboard_online} />,
  //       component: NavigationConfig.advDashboardOnlinePage().component,
  //       id: 1,
  //       requiredRoles: NavigationConfig.advDashboardOnlinePage().roles,
  //     },
  //     {
  //       path: NavigationConfig.advDashboardTransportPage().path,
  //       title: (
  //         <TranslatedText textMap={Translations.adv_dashboard_transport} />
  //       ),
  //       component: NavigationConfig.advDashboardTransportPage().component,
  //       id: 2,
  //       requiredRoles: NavigationConfig.advDashboardTransportPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.pubDashboardInFlightPage().path,
  //       title: <TranslatedText textMap={Translations.pub_dashboard_flight} />,
  //       component: NavigationConfig.pubDashboardInFlightPage().component,
  //       id: 3,
  //       requiredRoles: NavigationConfig.pubDashboardInFlightPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.pubDashboardOnlinePage().path,
  //       title: <TranslatedText textMap={Translations.pub_dashboard_online} />,
  //       component: NavigationConfig.pubDashboardOnlinePage().component,
  //       id: 4,
  //       requiredRoles: NavigationConfig.pubDashboardOnlinePage().roles,
  //     },
  //     {
  //       path: NavigationConfig.pubDashboardTransportPage().path,
  //       title: (
  //         <TranslatedText textMap={Translations.pub_dashboard_transport} />
  //       ),
  //       component: NavigationConfig.pubDashboardTransportPage().component,
  //       id: 5,
  //       requiredRoles: NavigationConfig.pubDashboardTransportPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.ifeAnalyticsPage().path,
  //       title: <TranslatedText textMap={Translations.ife_analytics} />,
  //       component: NavigationConfig.ifeAnalyticsPage().component,
  //       id: 6,
  //       requiredRoles: NavigationConfig.ifeAnalyticsPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.transportsOnlinePage().path,
  //       title: <TranslatedText textMap={Translations.transports_online} />,
  //       component: NavigationConfig.transportsOnlinePage().component,
  //       id: 7,
  //       requiredRoles: NavigationConfig.transportsOnlinePage().roles,
  //     },
  //   ],
  // },
  // {
  //   title: <TranslatedText textMap={Translations.adv_dashboard_flight} />,
  //   path: NavigationConfig.advDashboardFlightPage().path,
  //   requiredRoles: NavigationConfig.advDashboardFlightPage().roles,
  //   Icon: () => <DashboardOutlined />,
  //   id: 9,
  //   component: NavigationConfig.advDashboardFlightPage().component,
  // },
  // {
  //   title: <TranslatedText textMap={Translations.adv_dashboard_online} />,
  //   path: NavigationConfig.advDashboardOnlinePage().path,
  //   requiredRoles: NavigationConfig.advDashboardOnlinePage().roles,
  //   Icon: () => <DashboardOutlined />,
  //   id: 10,
  //   component: NavigationConfig.advDashboardOnlinePage().component,
  // },
  // {
  //   title: <TranslatedText textMap={Translations.adv_dashboard_transport} />,
  //   path: NavigationConfig.advDashboardTransportPage().path,
  //   requiredRoles: NavigationConfig.advDashboardTransportPage().roles,
  //   Icon: () => <DashboardOutlined />,
  //   id: 13,
  //   component: NavigationConfig.advDashboardTransportPage().component,
  // },
  // {
  //   title: <TranslatedText textMap={Translations.dashboards} />,
  //   path: null,
  //   requiredRoles: [Roles.Administrator, Roles.Advertiser_Administrator],
  //   Icon: () => <DashboardOutlined />,
  //   id: 2,
  //   component: null,
  //   subItems: [

  //   ],
  // },
  {
    title: <TranslatedText textMap={Translations.dashboards} />,
    path: null,
    requiredRoles: [
      Roles.Administrator,
      Roles.Advertiser_Administrator,
      Roles.Publisher,
      Roles.PublisherWebsite,
      Roles.PublisherTransport,
      Roles.Advertiser,
      Roles.AdvertiserWebsite,
      Roles.AdvertiserTransport,
    ],
    Icon: () => <DashboardOutlined />,
    id: 3,
    component: null,
    subItems: [
      {
        path: NavigationConfig.advDashboardFlightPage().path,
        title: <TranslatedText textMap={Translations.adv_dashboard_flight} />,
        component: NavigationConfig.advDashboardFlightPage().component,
        id: 0,
        requiredRoles: NavigationConfig.advDashboardFlightPage().roles,
      },
      {
        path: NavigationConfig.advDashboardOnlinePage().path,
        title: <TranslatedText textMap={Translations.adv_dashboard_online} />,
        component: NavigationConfig.advDashboardOnlinePage().component,
        id: 1,
        requiredRoles: NavigationConfig.advDashboardOnlinePage().roles,
      },
      {
        path: NavigationConfig.advDashboardTransportPage().path,
        title: (
          <TranslatedText textMap={Translations.adv_dashboard_transport} />
        ),
        component: NavigationConfig.advDashboardTransportPage().component,
        id: 2,
        requiredRoles: NavigationConfig.advDashboardTransportPage().roles,
      },
      {
        path: NavigationConfig.pubDashboardInFlightPage().path,
        title: <TranslatedText textMap={Translations.pub_dashboard_flight} />,
        component: NavigationConfig.pubDashboardInFlightPage().component,
        id: 3,
        requiredRoles: NavigationConfig.pubDashboardInFlightPage().roles,
      },
      {
        path: NavigationConfig.pubDashboardOnlinePage().path,
        title: <TranslatedText textMap={Translations.pub_dashboard_online} />,
        component: NavigationConfig.pubDashboardOnlinePage().component,
        id: 4,
        requiredRoles: NavigationConfig.pubDashboardOnlinePage().roles,
      },

      {
        path: NavigationConfig.pubDashboardTransportPage().path,
        title: (
          <TranslatedText textMap={Translations.pub_dashboard_transport} />
        ),
        component: NavigationConfig.pubDashboardTransportPage().component,
        id: 5,
        requiredRoles: NavigationConfig.pubDashboardTransportPage().roles,
      },
      {
        path: NavigationConfig.ifeAnalyticsPage().path,
        title: <TranslatedText textMap={Translations.ife_analytics} />,
        component: NavigationConfig.ifeAnalyticsPage().component,
        id: 6,
        requiredRoles: NavigationConfig.ifeAnalyticsPage().roles,
      },
      {
        path: NavigationConfig.transportsOnlinePage().path,
        title: <TranslatedText textMap={Translations.transports_online} />,
        component: NavigationConfig.transportsOnlinePage().component,
        id: 7,
        requiredRoles: NavigationConfig.transportsOnlinePage().roles,
      },
    ],
  },
  {
    title: <TranslatedText textMap={Translations.campaigns} />,
    path: NavigationConfig.campaignsPage().path,
    requiredRoles: NavigationConfig.campaignsPage().roles,
    Icon: () => <ListOutlinedIcon />,
    id: 4,
    component: NavigationConfig.campaignsPage().component,
  },
  {
    title: <TranslatedText textMap={Translations.campaigns_approval} />,
    path: NavigationConfig.advertisementApprovalPage().path,
    requiredRoles: NavigationConfig.advertisementApprovalPage().roles,
    Icon: () => <ListOutlinedIcon />,
    id: 5,
    component: NavigationConfig.advertisementApprovalPage().component,
  },
  {
    title: <TranslatedText textMap={Translations.reporting} />,
    path: null,
    requiredRoles: [Roles.Administrator, Roles.Advertiser_Administrator],
    Icon: () => <Assessment />,
    id: 6,
    component: null,
    subItems: [
      {
        title: <TranslatedText textMap={Translations.inflight} />,
        path: `${
          NavigationConfig.advReportingInflightPage().path
        }?campaignid=&range=`,
        requiredRoles: NavigationConfig.advReportingInflightPage().roles,
        id: 5,
        component: NavigationConfig.advReportingInflightPage().component,
      },
      {
        title: <TranslatedText textMap={Translations.online} />,
        path: `${
          NavigationConfig.advReportingOnlinePage().path
        }?campaignid=&range=`,
        requiredRoles: NavigationConfig.advReportingOnlinePage().roles,
        id: 6,
        component: NavigationConfig.advReportingOnlinePage().component,
      },
      {
        title: <TranslatedText textMap={Translations.transport} />,
        path: `${
          NavigationConfig.advReportingTransportPage().path
        }?campaignid=&range=`,
        requiredRoles: NavigationConfig.advReportingTransportPage().roles,
        id: 7,
        component: NavigationConfig.advReportingTransportPage().component,
      },
    ],
  },
  {
    title: <TranslatedText textMap={Translations.reporting_inflight} />,
    path: `${
      NavigationConfig.advReportingInflightPage().path
    }?campaignid=&range=`,
    requiredRoles: [Roles.Advertiser],
    Icon: () => <Assessment />,
    id: 11,
    component: NavigationConfig.advReportingInflightPage().component,
  },
  {
    title: <TranslatedText textMap={Translations.reporting_online} />,
    path: `${
      NavigationConfig.advReportingOnlinePage().path
    }?campaignid=&range=`,
    requiredRoles: [Roles.AdvertiserWebsite],
    Icon: () => <Assessment />,
    id: 12,
    component: NavigationConfig.advReportingOnlinePage().component,
  },
  {
    title: <TranslatedText textMap={Translations.reporting_transport} />,
    path: `${
      NavigationConfig.advReportingTransportPage().path
    }?campaignid=&range=`,
    requiredRoles: [Roles.AdvertiserTransport],
    Icon: () => <Assessment />,
    id: 14,
    component: NavigationConfig.advReportingTransportPage().component,
  },
  {
    title: <TranslatedText textMap={Translations.application} />,
    path: NavigationConfig.applicationPage().path,
    requiredRoles: NavigationConfig.applicationPage().roles,
    Icon: () => <AppsOutlined />,
    id: 7,
    component: NavigationConfig.applicationPage().component,
  },
  {
    title: <TranslatedText textMap={Translations.admin} />,
    path: null,
    requiredRoles: UserRoles.Admin,
    Icon: () => <PersonIcon />,
    id: 8,
    component: null,
    subItems: [
      {
        path: NavigationConfig.adminPage().path,
        title: <TranslatedText textMap={Translations.admin_page} />,
        component: NavigationConfig.adminPage().component,
        id: 1,
        requiredRoles: NavigationConfig.adminPage().roles,
      },
      {
        path: NavigationConfig.flightPage().path,
        title: <TranslatedText textMap={Translations.flights} />,
        component: NavigationConfig.flightPage().component,
        id: 2,
        requiredRoles: NavigationConfig.flightPage().roles,
      },
      {
        path: NavigationConfig.transportsPage().path,
        title: <TranslatedText textMap={Translations.transports} />,
        component: NavigationConfig.transportsPage().component,
        id: 3,
        requiredRoles: NavigationConfig.transportsPage().roles,
      },
      {
        path: NavigationConfig.publishersPage().path,
        title: <TranslatedText textMap={Translations.publisher_page} />,
        component: NavigationConfig.publishersPage().component,
        id: 4,
        requiredRoles: NavigationConfig.publishersPage().roles,
      },
      {
        path: NavigationConfig.dspListPage().path,
        title: <TranslatedText textMap={Translations.dsp_page} />,
        component: NavigationConfig.dspListPage().component,
        id: 5,
        requiredRoles: NavigationConfig.dspListPage().roles,
      },
      {
        path: NavigationConfig.advertiserListPage().path,
        title: <TranslatedText textMap={Translations.advertiser_page} />,
        component: NavigationConfig.advertiserListPage().component,
        id: 6,
        requiredRoles: NavigationConfig.advertiserListPage().roles,
      },
      {
        path: NavigationConfig.advertisementTypePage().path,
        title: <TranslatedText textMap={Translations.advertiser_type_page} />,
        component: NavigationConfig.advertisementTypePage().component,
        id: 7,
        requiredRoles: NavigationConfig.advertisementTypePage().roles,
      },
      {
        path: NavigationConfig.countryRatePage().path,
        title: <TranslatedText textMap={Translations.country_rate_page} />,
        component: NavigationConfig.countryRatePage().component,
        id: 8,
        requiredRoles: NavigationConfig.countryRatePage().roles,
      },
    ],
  },
  {
    title: <TranslatedText textMap={Translations.flights} />,
    path: NavigationConfig.flightPage().path,
    requiredRoles: [Roles.Publisher],
    Icon: () => <FlightOutlined />,
    id: 8,
    component: NavigationConfig.flightPage().component,
  },
  // {
  //   title: <TranslatedText textMap={Translations.flights} />,
  //   path: null,
  //   requiredRoles: [
  //     Roles.Advertiser,
  //     Roles.Advertiser_Administrator,
  //     Roles.Airline,
  //     Roles.Publisher,
  //   ],
  //   Icon: () => <PersonIcon />,
  //   id: 3,
  //   component: NavigationConfig.flightPage().component,
  // },
  {
    title: <TranslatedText textMap={Translations.transports} />,
    path: NavigationConfig.transportsPage().path,
    component: NavigationConfig.transportsPage().component,
    Icon: () => <DirectionsBus />,
    id: 15,
    requiredRoles: [Roles.PublisherTransport, Roles.AdvertiserTransport],
  },
];

function filterSubElements(
  subElements: ISubItems[],
  roles: Array<Roles>
): ISubItems[] {
  return (
    subElements.filter(({ requiredRoles }) =>
      ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
    ) || []
  );
}

export const getNavbarConfig = (
  roles: Array<Roles> | null
): ISidebarConfig[] => {
  return (Array.isArray(roles)
    ? SideBarConfig.filter(
        ({ requiredRoles }) =>
          Array.isArray(requiredRoles) &&
          ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
      )
    : []
  ).map((element) => ({
    ...element,
    subItems: Array.isArray(element.subItems)
      ? filterSubElements(element.subItems, roles || [])
      : element.subItems,
  }));
};
