import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'sticky',
    top: 64,
    width: '100%',
    height: 'calc(100vh - 108px)',
  },

  busPin: {
    backgroundColor: 'transparent !important',
    '&>svg': {
      webkitFilter: 'drop-shadow( 5px 1px 3px rgba(0, 0, 0, .4))',
      filter: 'drop-shadow( 5px 1px 3px rgba(0, 0, 0, .4))',
    },
  },
}));

export default useStyles;
