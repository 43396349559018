const Configuration = {
  backgroundImage: require('assets/images/backgrounds/background_login_register.png'),
  registerIcon: require('assets/images/logos/app-logo.png'),
};

export default Configuration;

export enum EnumSchemeTypes {
  eu = 'EU',
  non_eu = 'NON_EU',
  ioss = 'IOSS',
}
