import React, { ReactElement } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { ModalIDs } from '../../../redux/reducers/gl_modal_reducer/types';
import { useModalData } from '../../../redux/reducers/gl_modal_reducer/selectors';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../redux/reducers/gl_modal_reducer/actions';
import { CardContentProps } from '@material-ui/core';

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (() => void) | undefined;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogFooter = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IModalProps extends Omit<DialogProps, 'open'> {}

interface IBaseModal {
  renderContent?: (props: any) => ReactElement;
  renderHeader?: (props: any) => ReactElement;
  renderFooter?: (props: any) => ReactElement;
  modalID: ModalIDs;
  modalProps?: IModalProps;
  contentProps?: DialogContentProps;
  showExitIcon?: boolean;
}

export default function BaseModal({
  renderContent,
  renderHeader,
  renderFooter,
  modalID,
  modalProps = {},
  contentProps = {},
  showExitIcon = true,
}: IBaseModal) {
  const { params, visible } = useModalData(modalID);
  const open = visible;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ModalActions.closeModalAction({ modalID }));
  };

  return (
    <Dialog
      {...modalProps}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {renderHeader && (
        <DialogTitle
          id="customized-dialog-title"
          onClose={showExitIcon ? handleClose : undefined}
        >
          {renderHeader(params)}
        </DialogTitle>
      )}
      {renderContent && (
        <DialogContent dividers {...contentProps}>
          {renderContent(params)}
        </DialogContent>
      )}
      {renderFooter && <DialogFooter>{renderFooter(params)}</DialogFooter>}
    </Dialog>
  );
}
