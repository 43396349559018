import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export interface ITransport {
  id: number;
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string;
  sim: string;
  serial: string;
  pin1: string;
  pin2: string;
  wifi: string;
  type: string;
  version: string;
  status: number;
  comments: string;
}

export const fetchTransportById = async (
  id: string
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transports/${id}`
  );

  let _data: ITransport = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface IEditTransportInput {
  id: number;
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string | null;
  sim: string | null;
  serial: string | null;
  pin1: string | null;
  pin2: string | null;
  wifi: string | null;
  type: string;
  version: string | null;
  status: number | null;
  comments: string | null;
}

export const editTransport = async (
  payload: IEditTransportInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/transports/edit/${payload.id}`,
    payload
  );

  let _data: ITransport = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
