import { Button, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PublishersUsersWhitelistActions from 'redux/reducers/publishers_users_whitelist_reducer/actions';
import { useDeleteExclusivePublisherStatus } from 'redux/reducers/publishers_users_whitelist_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Swal from 'sweetalert2';

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = _.get(params, 'id', null);
  const deleteExclusivePublisherStatus = useDeleteExclusivePublisherStatus();

  const handleEdit = (e: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER,
        params: { data: props?.data },
      })
    );
  };
  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          PublishersUsersWhitelistActions.deleteExclusivePublisher({
            id: props?.data?.Id,
            onFinish: () => {
              dispatch(
                PublishersUsersWhitelistActions.fetchListExclusivityByPublisher(
                  {
                    id,
                    sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
                    page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
                    pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
                    order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
                  }
                )
              );
            },
          })
        );
      }
    });
  };
  return (
    <React.Fragment>
      <Button onClick={handleEdit} variant="contained" color="primary">
        Edit
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        style={{
          backgroundColor: 'red',
          marginLeft: 15,
          textTransform: 'capitalize',
        }}
        color="primary"
        disabled={deleteExclusivePublisherStatus === StateStatus.Pending}
      >
        {deleteExclusivePublisherStatus === StateStatus.Pending ? (
          <CircularProgress size={24} style={{ color: '#fff' }} />
        ) : (
          'Delete'
        )}
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: '#',
    id: 'Id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Advertiser',
    id: 'Userid',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <React.Fragment>{data[rowIndex].UserName}</React.Fragment>,
    inData: true,
  },

  {
    label: 'Category',
    id: 'CategoryId',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <React.Fragment>{data[rowIndex].CategoryName}</React.Fragment>,
    inData: true,
  },

  {
    label: 'Publisher App',
    id: 'PublisherAppId',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <React.Fragment>{data[rowIndex].PublisherAppName}</React.Fragment>,
    inData: true,
  },

  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data[rowIndex]}
        dataid={data[rowIndex].id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'asc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export interface IFormData {
  userId: number | null;
  categoryId: number | null;
  publisherAppId: number | null;
}

export const formDefaultValue: IFormData = {
  userId: null,
  categoryId: null,
  publisherAppId: null,
};
