import { IAction, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  profileData: {
    status: StateStatus | null | undefined;
    data: any;
  };
  countriesList: {
    status: StateStatus | null | undefined;
    data: any;
  };
  editProfile: {
    status: StateStatus | null | undefined;
  };
  changeProfilePassword: {
    status: StateStatus | null | undefined;
  };
}

const initialState: IState = {
  profileData: {
    status: null,
    data: null,
  },
  countriesList: {
    status: null,
    data: null,
  },
  editProfile: {
    status: null,
  },
  changeProfilePassword: {
    status: null,
  },
};

const UserPorifleReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_COUNTRIES:
      return {
        ...state,
        countriesList: {
          ...state.countriesList,
          status: action.status,
          data: action.data,
        },
      };
    case Types.EDIT_PROFILE:
      return {
        ...state,
        editProfile: {
          ...state.editProfile,
          status: action.status,
        },
      };
    case Types.CHANGE_PASSWORD:
      return {
        ...state,
        changeProfilePassword: {
          ...state.changeProfilePassword,
          status: action.status,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserPorifleReducer;
