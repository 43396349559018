import React, { useEffect } from 'react';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Select,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { categoryOption, statusOption } from './selectOptions';
import { Environment } from '../../../config';
import useStyles from '../styles';
import Actions from '../../../redux/reducers/advertiser_reducer/actions';
import { useGetConnectedPublisher } from '../../../redux/reducers/advertiser_reducer/selectors';
import { useSelectUserRoles } from '../../../redux/reducers/gl_auth_reducer/selectors';
import { AuthRoles } from '../../../config';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

export default function CampaignCard(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [categories, setCategories] = React.useState<string[]>([]);
  const [status, setStatus] = React.useState(0);
  const [needAprroval, setNeedAprroval] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const connectedPublisher = useGetConnectedPublisher();
  const roles = useSelectUserRoles();

  const handleAddCategory = (event: any) => {
    Swal.fire({
      title: 'Are you sure to update campaign categories?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          Actions.addCampaignsCategoryActions({
            advid: props.data.id as number,
            category: categories.join(','),
          })
        );
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategories(event.target.value as string[]);
  };
  const handleChangeActive = (
    event: React.ChangeEvent<{ checked: boolean }>
  ) => {
    Swal.fire({
      title: 'Are you sure to update campaign allow status?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const publisherid = connectedPublisher.data.ID;
        const checked = event.target.checked;
        console.log(checked);
        setActive(!checked);
        const allowStatus = !checked ? 1 : 0;
        dispatch(
          Actions.updateCampaignsAllowStatusActions({
            publisherid,
            advertismentid: props.data.id,
            status: allowStatus,
          })
        );
      }
    });
  };
  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    Swal.fire({
      title: 'Are you sure to update campaign status?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        setStatus(event.target.value as number);
        dispatch(
          Actions.updateCampaignsStatusActions({
            campaignid: props.data.campid,
            newstatus: event.target.value as number,
          })
        );
      }
    });
  };
  useEffect(() => {
    setStatus(props.data.status);
    setActive(props.data.show === 1 ? true : false);
    const categorySelected = categoryOption
      .filter((item) => {
        const categoryId = props.data.categoryid.split(',');
        if (categoryId.includes(item.value.toString())) {
          return true;
        } else {
          return false;
        }
      })
      .map((item) => item.value.toString());
    setCategories(categorySelected);
  }, [props.data]);

  useEffect(() => {
    if (connectedPublisher.status === 'Success') {
      const need = connectedPublisher?.data?.NeedsApproval === 0 ? false : true;
      setNeedAprroval(need);
    }
  }, [connectedPublisher]);

  const color = statusOption.find((item) => item.value === status)?.color;
  return (
    <Card>
      <CardHeader
        title={
          <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5" className={classes.textBlue}>
                {props.data.campaignname}
              </Typography>
              <Typography variant="h4" className={classes.textBlue}>
                {props.data.id}
              </Typography>
            </div>
            <Typography variant="body1" className={classes.textLightBlue}>
              {props.data.description}
            </Typography>
          </React.Fragment>
        }
      />
      <CardContent className={classes['w-full']}>
        {/* <FormControl
          variant="outlined"
          className={clsx(classes['w-full'], classes['mb24'])}
        >
          <InputLabel id="demo-mutiple-chip-label">Categories: </InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            disableUnderline
            className={classes.boxShadow}
            disabled={roles.includes(AuthRoles.Administrator) ? false : true}
            fullWidth
            value={categories}
            onClose={handleAddCategory}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div>
                {(selected as string[]).map((value) => (
                  <Chip
                    key={value}
                    label={
                      categoryOption.find(
                        (item) => item.value === parseInt(value)
                      )?.label
                    }
                  />
                ))}
              </div>
            )}
          >
            {categoryOption.map((category) => (
              <MenuItem key={category.value} value={category.value.toString()}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {props.data.contenttype === 'mp4' ? (
          <ReactPlayer
            height={400}
            width="100%"
            controls
            url={`${Environment.API_BASE_URL}${props.data.contenturl}`}
          />
        ) : (
          <img
            height={400}
            width="100%"
            src={`${Environment.API_BASE_URL}${props.data.contenturl}`}
          />
        )}
        <div
          className={clsx(
            classes.flex,
            classes.mt12,
            classes['justify-between']
          )}
        >
          {/* <Button
            className={clsx(
              classes['h-48'],
              props.data.show === 1 ? classes.active : classes['in-active']
            )}
            variant="contained"
            color="primary"
            children={`${props.data.show ? 'Active' : 'In active'}`}
          /> */}
          {needAprroval && (
            <FormControlLabel
              control={
                <Checkbox checked={active} onChange={handleChangeActive} />
              }
              label="Active"
            />
          )}
          <FormControl
            variant="outlined"
            className={clsx(classes['h-48'], classes['w-256'])}
          >
            <Select
              value={status}
              disabled={roles.includes(AuthRoles.Administrator) ? false : true}
              onChange={handleChangeStatus}
              style={{
                background: '#fff',
                padding: '0',
                color: `${color}`,
              }}
              className={clsx(classes['h-48'], classes['boxShadow'])}
              IconComponent="div"
            >
              {statusOption.map((item, key) => {
                return (
                  <MenuItem key={key} value={item.value}>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: 18,
                        justifyContent: 'center',
                      }}
                    >
                      {item.name}
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </CardContent>
    </Card>
  );
}
