import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  topResult: {
    status: StateStatus | null;
    data: any;
  };
  passengerByDate: {
    status: StateStatus | null;
    data: any;
  };
  passengerByDestinationAirport: {
    status: StateStatus | null;
    data: any;
  };
  passengerByDepartureAirport: {
    status: StateStatus | null;
    data: any;
  };
  trackingByDeparture: {
    status: StateStatus | null;
    data: any;
  };
  trackingByDestination: {
    status: StateStatus | null;
    data: any;
  };
  viewPerDevice: {
    status: StateStatus | null;
    data: any;
  };
  top10VisitingTag: {
    status: StateStatus | null;
    data: any;
  };
  top10VisitingUrl: {
    status: StateStatus | null;
    data: any;
  };
  userList: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  topResult: {
    status: null,
    data: null,
  },
  passengerByDate: {
    status: null,
    data: null,
  },
  passengerByDestinationAirport: {
    status: null,
    data: null,
  },
  passengerByDepartureAirport: {
    status: null,
    data: null,
  },
  trackingByDeparture: {
    status: null,
    data: null,
  },
  trackingByDestination: {
    status: null,
    data: null,
  },
  viewPerDevice: {
    status: null,
    data: null,
  },
  top10VisitingTag: {
    status: null,
    data: null,
  },
  top10VisitingUrl: {
    status: null,
    data: null,
  },
  userList: {
    status: null,
    data: null,
  },
};

const IFEAnalyticsService = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_TOP_RESULT:
      return {
        ...state,
        topResult: {
          ...state.topResult,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.topResult.data : action.data,
        },
      };
    case Types.SET_PASSENGER_BY_DATE:
      return {
        ...state,
        passengerByDate: {
          ...state.passengerByDate,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.passengerByDate.data
              : action.data,
        },
      };
    case Types.SET_PASSENGER_BY_DESTINATION_AIRPORT:
      return {
        ...state,
        passengerByDestinationAirport: {
          ...state.passengerByDestinationAirport,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.passengerByDestinationAirport.data
              : action.data,
        },
      };
    case Types.SET_PASSENGER_BY_DEPARTURE_AIRPORT:
      return {
        ...state,
        passengerByDepartureAirport: {
          ...state.passengerByDepartureAirport,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.passengerByDepartureAirport.data
              : action.data,
        },
      };
    case Types.SET_TRACKING_BY_DEPARTURE:
      return {
        ...state,
        trackingByDeparture: {
          ...state.trackingByDeparture,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.trackingByDeparture.data
              : action.data,
        },
      };
    case Types.SET_TRACKING_BY_DESTINATION:
      return {
        ...state,
        trackingByDestination: {
          ...state.trackingByDestination,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.trackingByDestination.data
              : action.data,
        },
      };
    case Types.SET_VIEW_PER_DEVICE:
      return {
        ...state,
        viewPerDevice: {
          ...state.viewPerDevice,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewPerDevice.data
              : action.data,
        },
      };
    case Types.SET_TOP_10_VISITING_TAG:
      return {
        ...state,
        top10VisitingTag: {
          ...state.top10VisitingTag,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top10VisitingTag.data
              : action.data,
        },
      };
    case Types.SET_TOP_10_VISITING_URLS:
      return {
        ...state,
        top10VisitingUrl: {
          ...state.top10VisitingUrl,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top10VisitingUrl.data
              : action.data,
        },
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default IFEAnalyticsService;
