export enum ReducerKeys {
  AUTH_REDUCER = 'AuthReducer',
  TRANSL_REDUCER = 'TranslationReducer',
  NAVIGATION_REDUCER = 'NavigationReducer',
  MODAL_REDUCER = 'ModalReducer',
  THEME_REDUCER = 'ThemeReducer',
  RESET_PASSWORD_REDUCER = 'ResetPasswordReducer',
  REGISTER_REDUCER = 'RegisterReducer',
  CHANGE_PASSWORD_REDUCER = 'ChangePasswordReducer',
  USER_PROFILE_REDUCER = 'UserProfileReducer',
  DASHBOARD_BUILDER_REDUCER = 'DashboardBuilderReducer',
  FORGOT_PASSWORD_REDUCER = 'ForgotPasswordReducer',
  CAMPAIGNS_REDUCER = 'CampaignsReducer',
  WALLET_REDUCER = 'WalletReducer',
  ADV_DASHBOARD_REDUCER = 'AdvDashboardReducer',
  CAMPAIGN_REDUCER = 'CampaignReducer',
  PUB_DASHBOARD_REDUCER = 'PubDashboardReducer',
  CAMPAIGNS_ADVERTISEMENT_REDUCER = 'CampaignAdvertisementReducer',
  REPORTING_REDUCER = 'ReportingReducer',
  APPLICATION_REDUCER = 'ApplicationReducer',
  APPLICATION_DETAIL_REDUCER = 'ApplicationDetailReducer',
  IFE_ANALYTICS_REDUCER = 'IFEAnalyticsReducer',
  ADMINISTRATOR_REDUCER = 'AdministratorReducer',
  FLIGHTS_REDUCER = 'FlightReducer',
  TRANSPORTS_REDUCER = 'TransportsReducer',
  TRANSPORT_REDUCER = 'TransportReducer',
  PUBLISHER_REDUCER = 'PublisherReducer',
  PARALIST_REDUCER = 'ParalistReducer',
  PUBLISHERS_USERS_WHITELIST_REDUCER = 'PublishersUsersWhitelistReducer',
  DSP_REDUCER = 'DspReducer',
  USERS_REDUCER = 'UsersReducer',
  USERS_ADVERTISERS_ADMINISTRATION_REDUCER = 'AdvertiserAdminReducer',
  ADVERTISEMENTSTYPE_REDUCER = 'AdvertisementstypeReducer',
  COUNTRIESRATE_REDUCER = 'CountriesRateReducer',
  COUNTRIES_REDUCER = 'CountriesReducer',
  USER_PRICE_CALCULATION_REDUCER = 'UserPriceCalculationReducer',
  AIRLINES_REDUCER = 'AirlinesReducer',
  AIRPORTS_REDUCER = 'AirportsReducer',
}
