import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import useStyles from './styles';
import Loading from 'components/atoms/loading';
import _ from 'lodash';

am4core.useTheme(am4themes_animated);

export interface IPoint {
  lat: number;
  lng: number;
  name: string;
}

export interface IRoute {
  starting_point: IPoint;
  ending_point: IPoint;
}

interface IProps {
  routes: Array<IRoute>;
  isShowFlight: boolean;
}
const FlightsMap = (props: IProps) => {
  const { routes, isShowFlight } = props;
  const classes = useStyles();

  useLayoutEffect(() => {
    let chart = am4core.create('flighstMap', am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();

    chart.events.on('ready', (ev) => {});
    function addCity(lat: number, lng: number, name: string, cities: any) {
      var city = cities.mapImages.create();
      city.latitude = lat;
      city.longitude = lng;
      city.tooltipText = name;
      return city;
    }
    function addLine(fromName: string, from: any, toName: string, to: any) {
      var line = lineSeries.mapLines.create();
      line.imagesToConnect = [from, to];
      line.line.controlPointDistance = -0.3;
      _.set(line, 'from', fromName);
      _.set(line, 'to', toName);
      var shadowLine = shadowLineSeries.mapLines.create();
      shadowLine.imagesToConnect = [from, to];
      _.set(shadowLine, 'from', fromName);
      _.set(shadowLine, 'to', toName);
      return line;
    }
    function addPoint() {
      const start_points = routes.map((point: any) => point.starting_point);
      const end_points = routes.map((point: any) => point.ending_point);
      const points = _.uniqBy([...start_points, end_points], 'name');

      // let placeSeries = chart.series.push(new am4maps.MapImageSeries());
      var cities = chart.series.push(new am4maps.MapImageSeries());
      cities.mapImages.template.nonScaling = true;

      var city = cities.mapImages.template.createChild(am4core.Circle);
      city.radius = 6;
      city.fill = chart.colors.getIndex(0).brighten(-0.2);
      city.strokeWidth = 2;
      city.stroke = am4core.color('#fff');
      points.map((point) => addCity(point.lat, point.lng, point.name, cities));
    }
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.mapPolygons.template.fill = chart.colors
      .getIndex(0)
      .lighten(0.5);
    polygonSeries.mapPolygons.template.nonScalingStroke = true;
    polygonSeries.exclude = ['AQ'];

    if (isShowFlight) {
      // CITIES - START
      var cities = chart.series.push(new am4maps.MapImageSeries());
      cities.mapImages.template.nonScaling = true;

      var city = cities.mapImages.template.createChild(am4core.Circle);
      city.radius = 6;
      city.fill = chart.colors.getIndex(0).brighten(-0.2);
      city.strokeWidth = 2;
      city.stroke = am4core.color('#fff');

      // CITIES - END

      // LINES - BEGIN
      var lineSeries = chart.series.push(new am4maps.MapArcSeries());
      lineSeries.mapLines.template.line.strokeWidth = 2;
      lineSeries.mapLines.template.line.strokeOpacity = 0.5;
      lineSeries.mapLines.template.line.stroke = city.fill;
      lineSeries.mapLines.template.line.nonScalingStroke = true;
      lineSeries.mapLines.template.line.strokeDasharray = '1,1';
      lineSeries.zIndex = 10;

      var shadowLineSeries = chart.series.push(new am4maps.MapLineSeries());
      shadowLineSeries.mapLines.template.line.strokeOpacity = 0;
      shadowLineSeries.mapLines.template.line.nonScalingStroke = true;
      shadowLineSeries.mapLines.template.shortestDistance = false;
      shadowLineSeries.zIndex = 5;

      // LINES - END

      // INSERT DATA - BEGIN
      let insertedCities: any = {};
      for (let route of routes) {
        let startingCity = _.get(
          insertedCities,
          route.starting_point.name,
          null
        );
        if (!startingCity) {
          let starting_city = addCity(
            route.starting_point.lat,
            route.starting_point.lng,
            route.starting_point.name,
            cities
          );
          _.set(insertedCities, route.starting_point.name, starting_city);
        }

        let endingCity = _.get(insertedCities, route.ending_point.name, null);
        if (!endingCity) {
          let ending_city = addCity(
            route.ending_point.lat,
            route.ending_point.lng,
            route.ending_point.name,
            cities
          );
          _.set(insertedCities, route.ending_point.name, ending_city);
        }
        addLine(
          route.starting_point.name,
          _.get(insertedCities, route.starting_point.name, null),
          route.ending_point.name,
          _.get(insertedCities, route.ending_point.name, null)
        );
      }
      // INSERT DATA - END

      // PLANE - BEGIN
      let countFlights: any = {};
      for (let index = 0; index < lineSeries.mapLines.length; index++) {
        let from = _.get(
          (lineSeries as any).mapLines.getIndex(index),
          'from',
          null
        );
        if (from) {
          let flightExist = _.get(countFlights, from, null);
          if (flightExist) {
            countFlights[from] = countFlights[from] + 1;
            continue;
          } else {
            _.set(countFlights, from, 1);
          }
        }
      }

      const plane = (index: number) => {
        // CREATE PLANE - START
        var plane = (lineSeries as any).mapLines
          .getIndex(index)
          .lineObjects.create();
        plane.position = 0;
        plane.width = 48;
        plane.height = 48;

        plane.adapter.add('scale', function (scale: any, target: any) {
          return 0.5 * (1 - Math.abs(0.5 - target.position));
        });

        var planeImage = plane.createChild(am4core.Sprite);
        planeImage.scale = 0.08;
        planeImage.horizontalCenter = 'middle';
        planeImage.verticalCenter = 'middle';
        planeImage.path =
          'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47';
        planeImage.fill = '#b22222'; //chart.colors.getIndex(2).brighten(-0.2);
        planeImage.strokeOpacity = 0;
        // CREATE PLANE - END
        // CREATE PLANE SHADOWS - START
        var shadowPlane = (shadowLineSeries as any).mapLines
          .getIndex(index)
          .lineObjects.create();
        shadowPlane.position = 0;
        shadowPlane.width = 48;
        shadowPlane.height = 48;

        var shadowPlaneImage = shadowPlane.createChild(am4core.Sprite);
        shadowPlaneImage.scale = 0.05;
        shadowPlaneImage.horizontalCenter = 'middle';
        shadowPlaneImage.verticalCenter = 'middle';
        shadowPlaneImage.path =
          'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47';
        shadowPlaneImage.fill = am4core.color('#000');
        shadowPlaneImage.strokeOpacity = 0;

        shadowPlane.adapter.add('scale', function (scale: any, target: any) {
          target.opacity = 0.6 - Math.abs(0.5 - target.position);
          return 0.5 - 0.3 * (1 - Math.abs(0.5 - target.position));
        });
        // CREATE PLANE SHADOWS - END

        planesAlreadyInserted.push(
          _.get((lineSeries as any).mapLines.getIndex(index), 'from', null)
        );

        var initialLine = index;
        var line = index;
        var direction = 1;
        const flyPlane = () => {
          // Get current line to attach plane to
          let departure = _.get(
            lineSeries.mapLines.getIndex(line),
            'from',
            null
          );
          plane.mapLine = lineSeries.mapLines.getIndex(line);
          plane.parent = lineSeries;
          shadowPlane.mapLine = shadowLineSeries.mapLines.getIndex(line);
          shadowPlane.parent = shadowLineSeries;
          shadowPlaneImage.rotation = planeImage.rotation;

          // Set up animation
          var from, to;
          var numLines = _.get(countFlights, departure, line);
          if (direction == 1) {
            from = 0;
            to = 1;
            if (planeImage.rotation != 0) {
              planeImage
                .animate({ to: 0, property: 'rotation' }, 1000)
                .events.on('animationended', flyPlane);
              return;
            }
          } else {
            from = 1;
            to = 0;
            if (planeImage.rotation != 180) {
              planeImage
                .animate({ to: 180, property: 'rotation' }, 1000)
                .events.on('animationended', flyPlane);
              return;
            }
          }

          // Start the animation
          var animation = plane.animate(
            {
              from: from,
              to: to,
              property: 'position',
            },
            5000,
            am4core.ease.sinInOut
          );
          animation.events.on('animationended', flyPlane);

          shadowPlane.animate(
            {
              from: from,
              to: to,
              property: 'position',
            },
            5000,
            am4core.ease.sinInOut
          );

          // Increment line, or reverse the direction
          // console.log(departure, ': line = ', line, ' initialLine=', initialLine);
          if (line < initialLine + numLines && direction == 1) {
            // console.log('HERE 1', departure);
            direction = -1;
          } else if (line < initialLine + numLines && direction == -1) {
            // console.log('HERE 2', departure);
            line++;
            if (line >= initialLine + numLines) {
              line = initialLine;
            }
            direction = 1;
          }
        };

        flyPlane();
      };

      let planesAlreadyInserted: string[] = [];
      for (let index = 0; index < lineSeries.mapLines.length; index++) {
        if (
          planesAlreadyInserted.includes(
            _.get((lineSeries as any).mapLines.getIndex(index), 'from', null)
          )
        )
          continue;
        plane(index);
      }
      // PLANE - END
    } else {
      // Countries point
      // const start_points = routes.map((point: any) => point.starting_point);
      // const end_points = routes.map((point: any) => point.ending_point);
      if (routes && routes.length > 0) {
        var cities = chart.series.push(new am4maps.MapImageSeries());
        cities.mapImages.template.nonScaling = true;

        var city = cities.mapImages.template.createChild(am4core.Circle);
        city.radius = 6;
        city.fill = chart.colors.getIndex(0).brighten(-0.2);
        city.strokeWidth = 2;
        city.stroke = am4core.color('#fff');
        routes.map((route: any) => {
          addCity(route.lat, route.lon, route.name, cities);
        });
      }
    }
    return () => {
      chart.dispose();
    };
  }, [routes, isShowFlight]);

  return <div id="flighstMap" className={classes.map}></div>;
};

export const MapPlaceholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.placeholder}>
      <Loading />
    </div>
  );
};

export default FlightsMap;
