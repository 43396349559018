export const statusOption = [
  { name: 'Normal', value: 0, icon: 'account_balance', color: '#009688' },
  { name: 'Pending', value: 1, icon: 'alarm', color: '#2196F3' },
  { name: 'Expired', value: 2, icon: 'alarm_off', color: '#fbc531' },
  { name: 'Deleted', value: 3, icon: 'delete', color: '#F44336' },
  {
    name: 'Finished',
    value: 4,
    icon: 'check_circle_outline',
    color: '#4CAF50',
  },
  {
    name: 'Insufficient Fund',
    value: 5,
    icon: 'not_interested',
    color: '#273c75',
  },
];
export const categoryOption = [
  { label: 'Sports', value: 1 },
  { label: 'Airlines', value: 3 },
  { label: 'Americana', value: 4 },
  { label: 'Animals', value: 5 },
  { label: 'Astrology', value: 6 },
  { label: 'Britannia', value: 7 },
  { label: 'Celebrities', value: 8 },
  { label: 'Charities', value: 9 },
  { label: 'Children', value: 10 },
  { label: 'Christmas', value: 11 },
  { label: 'Cinema', value: 12 },
  { label: 'Circus', value: 13 },
  { label: 'Cleaning', value: 14 },
  { label: 'Computers', value: 15 },
  { label: 'Controversial', value: 16 },
  { label: 'Cosmetics', value: 17 },
  { label: 'Crime', value: 18 },
  { label: 'Dance', value: 19 },
  { label: 'Decor', value: 20 },
  { label: 'Dental', value: 21 },
  { label: 'Dieting', value: 22 },
  { label: 'Drinks', value: 23 },
  { label: 'Drugs', value: 24 },
  { label: 'Education', value: 25 },
  { label: 'Elderly', value: 26 },
  { label: 'Electrical', value: 27 },
  { label: 'Energy', value: 28 },
  { label: 'False Claims', value: 29 },
  { label: 'Families', value: 30 },
  { label: 'Fashion', value: 31 },
  { label: 'Festivals', value: 32 },
  { label: 'Financial', value: 33 },
  { label: 'Food', value: 34 },
  { label: 'Gardening', value: 35 },
  { label: 'Govt Campaigns', value: 36 },
  { label: 'Hairdressing', value: 37 },
  { label: 'Home', value: 38 },
  { label: 'Housework', value: 39 },
  { label: 'Innovations', value: 40 },
  { label: 'Jewellery', value: 41 },
  { label: 'Magic', value: 42 },
  { label: 'Materials', value: 43 },
  { label: 'Medical', value: 44 },
  { label: 'Military', value: 45 },
  { label: 'Motoring', value: 46 },
  { label: 'Music', value: 47 },
  { label: 'Nature', value: 48 },
  { label: 'Occupations', value: 49 },
  { label: 'Office', value: 50 },
  { label: 'Optical', value: 51 },
  { label: 'Parties', value: 52 },
  { label: 'Perfume', value: 53 },
  { label: 'Pharmaceuticals', value: 54 },
  { label: 'Photography', value: 55 },
  { label: 'Political', value: 56 },
  { label: 'Race', value: 57 },
  { label: 'Recruitment', value: 58 },
  { label: 'Religions', value: 59 },
  { label: 'Romance', value: 60 },
  { label: 'Royalty', value: 61 },
  { label: 'Saucy', value: 62 },
  { label: 'Science', value: 63 },
  { label: 'Science Fiction', value: 64 },
  { label: 'Self Improvement', value: 65 },
  { label: 'Sewing', value: 66 },
  { label: 'Sex', value: 67 },
  { label: 'Sexist', value: 68 },
  { label: 'Shaving', value: 69 },
  { label: 'Shops', value: 70 },
  { label: 'Soap', value: 71 },
  { label: 'Space', value: 72 },
  { label: 'Teenagers', value: 73 },
  { label: 'Telephones', value: 74 },
  { label: 'Television', value: 75 },
  { label: 'Theatre', value: 76 },
  { label: 'Tobacco', value: 77 },
  { label: 'Toys Hobbies', value: 78 },
  { label: 'Travell', value: 79 },
  { label: 'Cars', value: 80 },
];
