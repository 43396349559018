import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface ITransportsListElement {
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string;
  sim: string;
  serial: string;
  pin1: string;
  pin2: string;
  wifi: string;
  type: string;
}

interface ITransportsListData {
  paging: IPaging;
  items: ITransportsListElement[];
}

interface IPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface ITransportsListOutput {
  status: StateStatus;
  data: ITransportsListData | null;
}

export const useGetTransportsList = (): ITransportsListOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.TRANSPORTS_REDUCER].TransportsList
  );
  return data;
};
