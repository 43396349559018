import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ChartLayout from '../chart_layout';
import { IChartLayoutProps } from '../chart_layout';

export interface IApexChartProps extends Omit<IChartLayoutProps, 'children'> {
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'rangeBar'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick'
    | 'radar'
    | 'polarArea';
  series?: Array<any>;
  width?: string | number;
  height?: string | number;
  options?: any;
}

export default function ApexChart(props: IApexChartProps) {
  const { type, series, width, height, options, ...ChartLayoutProps } = props;

  return (
    <ChartLayout
      layoutProps={{
        variant: 'outlined',
      }}
      {...ChartLayoutProps}
    >
      <ReactApexChart
        series={series}
        options={options}
        width={width}
        type={type}
        height={height || 350}
      />
    </ChartLayout>
  );
}
