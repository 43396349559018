import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as FlightsService from '../../../services/api/flights';
import * as PublisherService from '../../../services/api/publisher';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchFlightsListInput  */

interface FetchFlightsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  publisherid: string | null;
  date: string | null;
  dateto: string | null;
}

class FetchFlightsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_FLIGHTS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_FLIGHTS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_FLIGHTS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchFlightsListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await FlightsService.fetchFlightsList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcFlightsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchPublisherList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getFilterListPublisher(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getFilterListPublisher Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchFlightsListAction: (payload: FetchFlightsListInput) =>
    new FetchFlightsList().action(payload),
  fetchPublisherListAction: (payload: any) =>
    new FetchPublisherList().action(payload),
};
