import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';
import ICampaignInfoWizard from 'config/campaign_wizard';
import _ from 'lodash';
import { CampaignInfoDefaultValue } from 'config';
import { useSelector } from 'react-redux';
import { IDataItems } from 'services/api/transport_stations';

export const useGetCountries = (): any => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'countries.data', [])
  );
  return data;
};

export const useGetPoints = (): any => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'points', [])
  );
  return data;
};

export const useGetCampaignWizardValues = (): ICampaignInfoWizard => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      'CampaignWizard.data',
      CampaignInfoDefaultValue
    )
  );
  return data;
};

export const useGetCampaignWizardStatus = (): StateStatus | null => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'CampaignWizard.status', null)
  );
  return data;
};

interface IClient {
  client_name: string;
}

export const useGetUserClients = (): Array<IClient> => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'Clients.data', [])
  );
  return data;
};

interface ISegment {
  Code: string;
  Name: string;
}

interface ISegments {
  airline: Array<ISegment>;
  destination_country: Array<ISegment>;
  destination_city: Array<ISegment>;
  destination_transport: Array<ISegment>;
}
export const useGetSegments = (): ISegments => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'Segments.data', {})
  );
  return data;
};

interface ITotalCalculations {
  NumberofFlights: number;
  NumberofDays: number;
  LastFlightDate: Date;
  Budget: number;
  Passengers: number;
  Country: string;
}

interface IFlight {
  Code: string;
  FlightDate: Date;
  Airline: string;
  DeptAirport: string;
  ArrAirport: string;
  CPMRate: number;
  country: string;
  EstPassengers: number;
}

interface IRoutes {
  ArrAirport: string;
  ArrLat: number;
  ArrLong: number;
  DepLat: number;
  DepLong: number;
  DeptAirport: string;
}

interface IReachOnBoundOutput {
  destinations: ITotalCalculations;
  flights: Array<IFlight>;
  routes: Array<IRoutes>;
}

export const useGetDestinationReachOnBoard = (): IReachOnBoundOutput => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      'CampaignWizard.DestinationReachOnBoard.data',
      {}
    )
  );
  return data;
};

interface IScrapResult {
  url: string;
  pages: number;
}

interface IScrapOutput {
  data: IScrapResult;
  status: StateStatus;
}

export const useGetScrappingResult = (): IScrapOutput => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(state[ReducerKeys.CAMPAIGN_REDUCER], 'CampaignWizard.ScrapUrl', {})
  );
  return data;
};

export const useGetAnalyzedFileResult = (key?: string) => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      `CampaignWizard.AnalyzeFile${key ? `.${key}` : ''}`,
      {}
    )
  );
  return data;
};

export const useGetCreateCampaignStatus = (): StateStatus | null => {
  const data = useSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      'CampaignWizard.CreateCampaign.status',
      null
    )
  );
  return data;
};

export const useGetUpdateCampaignStatus = (): StateStatus | null => {
  const data = useSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      'CampaignWizard.UpdateCampaign.status',
      null
    )
  );
  return data;
};

export const useGetTransportStations = (): Array<IDataItems> => {
  const data = useShallowEqualSelector((state: any) =>
    _.get(
      state[ReducerKeys.CAMPAIGN_REDUCER],
      'CampaignWizard.TransportStations.data',
      {}
    )
  );
  return data;
};
