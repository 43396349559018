import React from 'react';
import CardLabelTitle from 'components/app_common/card_label_title';
import {
  ICustomChartProps,
  CustomChart,
} from 'components/molecules/custom_chart';
import TranslatedText from 'components/atoms/translated_text';

export default function CustomChartWrapper(props: ICustomChartProps) {
  let _props = overrideProps(props);

  return <CustomChart {..._props} />;
}

const overrideProps = (props: ICustomChartProps): ICustomChartProps => {
  let newProps = { ...props };

  if (!newProps.layoutProps) {
    newProps.layoutProps = {
      variant: 'outlined',
    };
  }
  if (typeof newProps.title === 'string') {
    newProps.title = (
      <CardLabelTitle title={<TranslatedText defaultText={newProps.title} />} />
    );
  }
  return newProps;
};
