import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import { IPaginationParams, ISearchQuery } from './reducers';
import * as CampaignsService from '../../../services/api/campaigns';
import * as AirportService from '../../../services/api/airports';
import { IExportData } from './reducers';

const SetPaginationParams = (data: IPaginationParams): IAction => {
  return {
    type: Types.SET_PAGINATION_PARAMS,
    data,
  };
};
const SetLoading = (data: boolean): IAction => {
  return {
    type: Types.SET_LOADING,
    data,
  };
};
const SetExportData = (data: IExportData): IAction => {
  return {
    type: Types.SET_EXPORT_DATA,
    data,
  };
};
const SetSearchQuery = (data: ISearchQuery): IAction => {
  return {
    type: Types.SET_SEARCH_QUERY,
    data,
  };
};

class FetchAirportList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AIRPORT_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AIRPORT_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AIRPORT_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AirportService.getAirportList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchAirport Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchReportingCampaignsDetail implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_DETAIL,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignsService.fetchReportCampaignsDetail(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchReportingCampaignsWithoutPaging implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_WITHOUT_PAGING,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_WITHOUT_PAGING,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_REPORTING_CAMPAIGNS_WITHOUT_PAGING,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignsService.fetchReportCampaignsDetail(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'fetchReportCampaignsDetail without paging Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IFetchCampaignList {
  userid: number;
}

class FetchCampaignsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMPAIGN_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMPAIGN_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMPAIGN_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignsService.fetchCampaignsListByUserId(
          payload
        );
        dispatch(this.onSuccess(response.data));
        dispatch(SetLoading(false));
      } catch (error) {
        console.log('FetcCampaignsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchReportingCampaignsDetailAction: (payload: any) =>
    new FetchReportingCampaignsDetail().action(payload),
  fetchReportingCampaignsWithoutPagingAction: (payload: any) =>
    new FetchReportingCampaignsWithoutPaging().action(payload),
  setPaginationParams: (payload: IPaginationParams) =>
    SetPaginationParams(payload),
  setSearchQuery: (payload: ISearchQuery) => SetSearchQuery(payload),
  fetchCampaignsListActions: (payload: IFetchCampaignList) =>
    new FetchCampaignsList().action(payload),
  fetchAirportListActions: (payload: any) =>
    new FetchAirportList().action(payload),
  setLoading: (payload: boolean) => SetLoading(payload),
  setExportData: (payload: IExportData) => SetExportData(payload),
};
