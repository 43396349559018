export enum LanguagesEnum {
  Greek = 'Greek',
  English = 'English',
  Russian = 'Russian',
}

export default {
  ...LanguagesEnum,
  All: Object.values(LanguagesEnum),
};
