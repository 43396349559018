import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import ICampaignInfoWizard, {
  CampaignInfoDefaultValue,
} from 'config/campaign_wizard';
import * as CampaignService from 'services/api/campaign';
import * as CountriesService from 'services/api/countries';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import _ from 'lodash';
import * as DateUtils from 'utils/dates';
import NavigationConfig from 'navigation/config';
import * as TransportStationsService from 'services/api/transport_stations';

export const setPoints = (data: any) => {
  return {
    type: Types.SET_POINTS,
    data,
  };
};

class InsertCampaignWizardById implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.INSERT_CAMPAIGN_WIZARD_BY_ID,
      status: StateStatus.Pending,
      data: CampaignInfoDefaultValue,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.INSERT_CAMPAIGN_WIZARD_BY_ID,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.INSERT_CAMPAIGN_WIZARD_BY_ID,
      status: StateStatus.Failed,
      data: CampaignInfoDefaultValue,
    };
  }

  action(id: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.fetchCampaignDetail({
          id: Number.parseInt(id),
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Insert campaign wizard by id Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class UpdateCampaignWizardInfo implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN_WIZARD,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN_WIZARD,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN_WIZARD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: ICampaignInfoWizard): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        dispatch(this.onSuccess(data));
      } catch (error) {
        console.log('Update campaign wizard Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchClients implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CLIENTS,
      status: StateStatus.Pending,
      data: [],
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CLIENTS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CLIENTS,
      status: StateStatus.Failed,
      data: [],
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.fetchUserClients();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchClients Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchSegments implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SEGMENTS,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SEGMENTS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SEGMENTS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.fetchSegments();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchSegments Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

interface IDestinationInput {
  destination_countries: string;
  destination_airports: string;
  airlines: string;
}
class SetCalculateEstimateReachOnBoard implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CALCULATE_REACH_ON_BOARD,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.CALCULATE_REACH_ON_BOARD,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CALCULATE_REACH_ON_BOARD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(destination: IDestinationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const state = getState(ReducerKeys.CAMPAIGN_REDUCER);
        const budget = _.get(state, 'CampaignWizard.data.budget', 0);
        let start_date = _.get(state, 'CampaignWizard.data.start_date', null);
        let end_date = _.get(state, 'CampaignWizard.data.end_date', null);
        if (start_date)
          start_date = DateUtils.DateInQueryFormat(new Date(start_date));
        if (end_date)
          end_date = DateUtils.DateInQueryFormat(new Date(end_date));
        let response = await CampaignService.calculateEstimateDestinationReachOnBoard(
          {
            ...destination,
            budget,
            start_date,
            end_date,
          }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchSegments Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

class ScrapUrl implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SCRAP_URL,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SCRAP_URL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SCRAP_URL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(url: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.scrapWebsite(url);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Scral Website Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

interface IAnalyzeFileInput {
  file: File;
  key: string;
}

interface IActionInput {
  key: string;
  data?: any;
}
class AnalyzeFile implements IActionMethods {
  onPending(data: IActionInput): IAction {
    return {
      type: Types.ANALYZE_FILE,
      status: StateStatus.Pending,
      data: {
        key: data.key,
        data: null,
      },
    };
  }
  onSuccess(data: IActionInput): IAction {
    return {
      type: Types.ANALYZE_FILE,
      status: StateStatus.Success,
      data: {
        key: data.key,
        data: data.data,
      },
    };
  }

  onFailed(data: IActionInput): IAction {
    return {
      type: Types.ANALYZE_FILE,
      status: StateStatus.Failed,
      data: {
        key: data.key,
        data: null,
      },
    };
  }

  action(data: IAnalyzeFileInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(
          this.onPending({
            key: data.key,
          })
        );
        let response = await CampaignService.analyzeFile(data.file);
        dispatch(this.onSuccess({ key: data.key, data: response.data }));
      } catch (error) {
        console.log('Analyze file error:', error.message);
        dispatch(
          this.onFailed({
            key: data.key,
          })
        );
      }
    };
  }
}

interface ICreateCampaign {
  data: ICampaignInfoWizard;
  history: any;
}
class CreateCampaign implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CREATE_CAMPAIGN,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.CREATE_CAMPAIGN,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CREATE_CAMPAIGN,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ICreateCampaign): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.createCampaign(payload.data);
        dispatch(this.onSuccess(response.data));
        payload.history.push(NavigationConfig.campaignsPage().path);
      } catch (error) {
        console.log('Create campaign error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

interface IUpdateCampaign {
  data: ICampaignInfoWizard;
  history: any;
  id: number;
}
class UpdateCampaign implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGN,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IUpdateCampaign): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.updateCampaign(
          payload.data,
          payload.id
        );
        dispatch(this.onSuccess(response.data));
        payload.history.push(NavigationConfig.campaignsPage().path);
      } catch (error) {
        console.log('Update campaign error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CountriesService.getCountriesByCode(payload.code);
        dispatch(this.onSuccess(response.data));
        console.log('darta', response.data);
        // payload.history.push(NavigationConfig.campaignsPage().path);
      } catch (error) {
        console.log('Fetch Countries error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

class ResetCampaignWizard implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.RESET_CAMPAIGN_WIZARD,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET_CAMPAIGN_WIZARD,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.RESET_CAMPAIGN_WIZARD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      dispatch(this.onSuccess());
    };
  }
}

class FetchTransportStations implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRANSPORT_STATIONS,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRANSPORT_STATIONS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRANSPORT_STATIONS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(mapBounds?: TransportStationsService.IMapBounds): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response;
        response = mapBounds
          ? await TransportStationsService.fetchTransportStationsListBounded(
              mapBounds
            )
          : await TransportStationsService.fetchTransportStationsList();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Fetch Transport Stations error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  updateCampaignWizardInfoAction: (payload: ICampaignInfoWizard) =>
    new UpdateCampaignWizardInfo().action(payload),
  fetchClientsAction: () => new FetchClients().action(),
  fetchSegmentsAction: () => new FetchSegments().action(),
  calculateEstimateReachOnBoardAction: (payload: IDestinationInput) =>
    new SetCalculateEstimateReachOnBoard().action(payload),
  scrapWebsiteAction: (url: string) => new ScrapUrl().action(url),
  analyzeFileAction: (key: string, file: File) =>
    new AnalyzeFile().action({
      key,
      file,
    }),
  createCampaignAction: (campaign: ICreateCampaign) =>
    new CreateCampaign().action(campaign),
  resetCampaignWizardAction: () => new ResetCampaignWizard().action(),
  insertCampaignByIdWizardInfoAction: (id: string) =>
    new InsertCampaignWizardById().action(id),
  updateCampaignAction: (campaign: IUpdateCampaign) =>
    new UpdateCampaign().action(campaign),
  fetchCountriesAction: (countries: any) =>
    new FetchCountries().action(countries),
  setPointsAction: (data: any) => setPoints(data),
  fetchTransportStationsAction: (
    mapBounds?: TransportStationsService.IMapBounds
  ) => new FetchTransportStations().action(mapBounds),
};
