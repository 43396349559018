import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    margin: 8,
  },
  submit: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  editButton: {
    color: theme.palette.getContrastText(grey[800]),
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: grey[500],
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: grey[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: grey[500],
      },
    },
    alignSelf: 'flex-start',
  },
  form: {},
}));

export default useStyles;
