import { IAction, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  // forgotPasswordStatus: boolean | null;
  resetPasswordStatus: boolean | null;
}

const initialState: IState = {
  // forgotPasswordStatus: null,
  resetPasswordStatus: null,
};

const ResetPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // FORGOT_PASSWORD
    // case Types.FORGOT_PASSWORD:
    //   return {
    //     ...state,
    //     forgotPasswordStatus: action.data.status,
    //   };
    case Types.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordStatus: action.data.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ResetPasswordReducer;
