import _ from 'lodash';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import * as dummy_data from 'components/molecules/leaflet_map/fake_data/dummy_data.json';
import * as RandomizeUtils from 'utils/randomize';

interface ISerie {
  name: string;
  data: number[];
}

function chunkName(name: string) {
  if (name.length > 10) {
    return name.substr(0, 10) + '...';
  } else {
    return name;
  }
}

function createDataset(data: any[], category: string, y: string) {
  const categories = _.uniq(data.map((item: any) => item[category]));

  const series: ISerie[] = data
    .map((item: any) => item[y])
    .map((name: string) => {
      const result = new Array(categories.length).fill(0);
      for (var i = 0; i < categories.length; i++) {
        data.forEach((item: any) => {
          if (item[y] === name && item[category] === categories[i]) {
            result[i] = item.visits;
          }
        });
      }
      return {
        name,
        data: result,
      };
    });
  return {
    categories,
    series,
  };
}

export default function getHeatMapConfig(
  data: any[],
  category: string,
  y: string,
  title: string
) {
  const dataset = createDataset(data, category, y);
  const HeatMapDashboard: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'heatmap',
        height: 350,
        layoutProps: {
          variant: 'outlined',
        },
        series: dataset.series,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'heatmap',
          },
          xaxis: {
            tickPlacement: 'between',
            type: 'category',
            categories: dataset.categories,
          },
          title: {
            text: title
          },
          colors: ['#008FFB'],
          legend: {
            show: true,
          },
          yaxis: {
            labels: {
              formatter: function (value: string) {
                return chunkName(value);
              },
            },
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              radius: 3,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 10,
                    name: 'low',
                    color: '#00A100',
                  },
                  {
                    from: 11,
                    to: 50,
                    name: 'medium',

                    color: '#128FD9',
                  },
                  {
                    from: 51,
                    to: 100,
                    name: 'high',
                    color: '#FFB200',
                  },
                  {
                    from: 101,
                    to: 100000,
                    name: 'extreme',
                    color: '#FF0000',
                  },
                ],
              },
            },
          },
        },
      },
      fetch: null,
    },
  ];
  return HeatMapDashboard;
}
