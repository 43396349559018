import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import ICampaignInfoWizard from 'config/campaign_wizard';

interface IUserClientsOutput {
  items: string[];
}

export const fetchUserClients = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/client`
  );

  let _data: IUserClientsOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface ISegment {
  Code: string;
  Name: string;
}

interface ISegments {
  airline: Array<ISegment>;
  destination_country: Array<ISegment>;
  destination_city: Array<ISegment>;
  destination_transport: Array<ISegment>;
}

export const fetchSegments = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/segments`
  );

  let _data: ISegments = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface ITotalCalculations {
  NumberofFlights: number;
  NumberofDays: number;
  LastFlightDate: Date;
  Budget: number;
  Passengers: number;
  Country: string;
}

interface IFlight {
  Code: string;
  FlightDate: Date;
  Airline: string;
  DeptAirport: string;
  ArrAirport: string;
  CPMRate: number;
  country: string;
  EstPassengers: number;
}

interface IRoutes {
  ArrAirport: string;
  ArrLat: number;
  ArrLong: number;
  DepLat: number;
  DepLong: number;
  DeptAirport: string;
}

interface IReachOnBoundOutput {
  destinations: ITotalCalculations;
  flights: Array<IFlight>;
  routes: Array<IRoutes>;
}

interface IEstimationInput {
  start_date?: Date;
  end_date?: Date;
  destination_countries: string;
  destination_airports: string;
  airlines: string;
  budget: number;
}

export const calculateEstimateDestinationReachOnBoard = async (
  payload: IEstimationInput
): Promise<Types.ResponseOutput> => {
  let query = '';
  if (payload.start_date) query += `&from_date=${payload.start_date}`;
  if (payload.end_date) query += `&to_date=${payload.end_date}`;

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/destination-estimated-reach?budget=${payload.budget}&destination_countries=${payload.destination_countries}&destination_airports=${payload.destination_airports}&airlines=${payload.airlines}${query}`
  );

  let _data: IReachOnBoundOutput = {
    destinations: data.data[0][0],
    flights: data.data[1],
    routes: data.data[2],
  };
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface IScrapResult {
  url: string;
  pages: number;
}

export const scrapWebsite = async (
  url: string
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns/save-url`,
    {
      url,
    }
  );

  let _data: IScrapResult = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface IAnalyzeFileResult {
  file_name: string;
  file_url: string;
  thumbnail_name: string;
  thumbnail_url: string;
}

export const analyzeFile = async (
  file: File
): Promise<Types.ResponseOutput> => {
  const formData = new FormData();
  formData.append('file', file);
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns/analyze-ad-file`,
    formData
  );
  let _data: IAnalyzeFileResult = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const createCampaign = async (
  body: ICampaignInfoWizard
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns`,
    body
  );
  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const updateCampaign = async (
  body: ICampaignInfoWizard,
  id: number
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/campaigns/${id}`,
    body
  );
  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Campaigns Detail */
interface ICampaignsDetailInput {
  id: number;
}

interface ICampaignsDetailOutput extends Types.ResponseOutput {
  data: {
    id: string | null;
    name: string | null;
    userid: string | null;
    maxbudget: number | null;
    periodfrom: string | null;
    periodto: string | null;
    creationdate: string | null;
    totalbudget: number | null;
    status: number | null;
    objective: number | null;
    clientname: string | null;
  };
}

export const fetchCampaignDetail = async (
  payload: ICampaignsDetailInput
): Promise<ICampaignsDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
