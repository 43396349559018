import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      cursor: 'pointer',
      border: '0.5px solid black',
      borderRadius: 20,
      overflowY: 'visible',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    root: {
      color: '#fff !important',
      borderColor: '#fff !important',
    },
    input: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: 5,
      height: 39,
      marginRight: 10,
      padding: 8,
      background: '#DCFAF8',
    },
    color: {
      color: '#4F4F4F !important',
    },
    progress: {
      height: 20,
      color: '#005FA3',
      borderRadius: '50px',
      backgroundColor: '#E0E0E0 !important',
    },
    barProgess: {
      borderRadius: '50px',
    },
    progressText: {
      color: '#000 !important',
    },
    tab: {
      background: '#F2F2F2 !important',
      color: '#005FA3 !important',
    },
    tabSelected: {
      background: '#005FA3 !important',
      color: '#F2F2F2 !important',
    },
    textBold: {
      fontWeight: 600,
    },
    mt12: {
      marginTop: 12,
    },
    mb24: {
      marginBottom: 20,
    },
    search: {
      display: 'flex',
      width: '100%',
    },
    boxShadow: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: 5,
      height: 39,
      marginRight: 10,
      padding: 8,
    },
    'ml-12': {
      marginLeft: 12,
    },
    'mr-12': {
      marginRight: 12,
    },
    'w-1/4': {
      width: '25%',
    },
    'w-128': {
      width: 128,
    },
    'h-48': {
      height: 48,
    },
    'w-full': {
      width: '100%',
    },
    flex: {
      display: 'flex',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
