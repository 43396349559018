import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

export interface IGeneralPublisherStatisticInput {
  userid: number;
  appid: string | null;
  publisherid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

function getRevenueConfig(data: any) {
  const LineArea: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'area',
        height: 350,

        series: [
          {
            name: 'Revenue',
            data: data.data[0].data,
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'area',
          },

          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: 'Total Revenue',
            align: 'left',
          },
          subtitle: {
            text: 'Total Revenue by month',
            align: 'left',
          },
          xaxis: {
            type: 'datetime',
            categories: data.labels.xaxis.categories,
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return LineArea;
}

export default getRevenueConfig;
