import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USERS_ADVERTISERS_ADMINISTRATION_LIST: string = typeCreatorV2(
  'Users_advertisers_administrationReducer',
  'SetUsers_advertisers_administrationList'
);

export const SET_ADD_USERS_ADVERTISERS_ADMINISTRATION: string = typeCreatorV2(
  'Users_advertisers_administrationReducer',
  'SetAddUsers_advertisers_administration'
);

export const SET_EDIT_USERS_ADVERTISERS_ADMINISTRATION: string = typeCreatorV2(
  'Users_advertisers_administrationReducer',
  'SetEditUsers_advertisers_administration'
);

export const SET_USERS_ADVERTISERS_ADMINISTRATION_DETAIL: string = typeCreatorV2(
  'Users_advertisers_administrationReducer',
  'SetUsers_advertisers_administrationDetail'
);

export const SET_DELETE_USERS_ADVERTISERS_ADMINISTRATION: string = typeCreatorV2(
  'Users_advertisers_administrationReducer',
  'SetDeleteUsers_advertisers_administration'
);
