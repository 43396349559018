import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

export interface IInput {
  data: any[];
  labels: any[];
  title: string;
}

function getRevenueConfig(params: IInput) {
  const { data, labels, title } = params;
  const LineArea: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'line',
        height: 350,
        series: data,
        // series: [
        //   {
        //     name: 'High - 2013',
        //     data: [28, 29, 33, 36, 32, 32, 33],
        //   },
        //   {
        //     name: 'Low - 2013',
        //     data: [12, 11, 14, 18, 17, 13, 13],
        //   },
        // ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'line',
          },

          colors: ['#439CDD', '#545454'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: title,
            align: 'left',
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: labels,
            title: {
              text: 'Date'
            }
          },
        //   yaxis: {
        //     title: {
        //       text: 'Temperature'
        //     },
        //     min: 5,
        //     max: 40
        //   },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
      },
      fetch: null,
    },
  ];
  return LineArea;
}

export default getRevenueConfig;
