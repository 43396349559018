import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  TransportsList: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  TransportsList: {
    status: null,
    data: null,
  },
};

const TransportsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_TRANSPORTS_LIST:
      return {
        ...state,
        TransportsList: {
          ...state.TransportsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.TransportsList.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default TransportsReducer;
