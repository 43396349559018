import { LanguagesEnum } from '../../config/languages';

const Translations = {
  add_modal_btn_submit: {
    [LanguagesEnum.English]: 'Submit',
    [LanguagesEnum.Greek]: 'υποβάλλουν',
    [LanguagesEnum.Russian]: 'Представлять на рассмотрение',
  },
  add_modal_btn_cancel: {
    [LanguagesEnum.English]: 'Cancel',
    [LanguagesEnum.Greek]: 'Ματαίωση',
    [LanguagesEnum.Russian]: 'Отмена',
  },
  add_modal_title: {
    [LanguagesEnum.English]: 'Add Graph',
    [LanguagesEnum.Greek]: 'Προσθήκη γραφήματος',
    [LanguagesEnum.Russian]: 'Добавить график',
  },
  user_search: {
    [LanguagesEnum.English]: 'User',
    [LanguagesEnum.Greek]: 'Χρήστης',
    [LanguagesEnum.Russian]: 'Пользователь',
  },
  publisher_search: {
    [LanguagesEnum.English]: 'Publisher',
    [LanguagesEnum.Greek]: 'Εκδότης',
    [LanguagesEnum.Russian]: 'издатель',
  },
  application_search: {
    [LanguagesEnum.English]: 'Application',
    [LanguagesEnum.Greek]: 'Εφαρμογή',
    [LanguagesEnum.Russian]: 'Заявление',
  },
  range_search: {
    [LanguagesEnum.English]: 'Range',
    [LanguagesEnum.Greek]: 'Εύρος',
    [LanguagesEnum.Russian]: 'Диапазон',
  },
  button_search: {
    [LanguagesEnum.English]: 'Search',
    [LanguagesEnum.Greek]: 'Αναζήτηση',
    [LanguagesEnum.Russian]: 'Поиск',
  },
  total_revenue_chart_title: {
    [LanguagesEnum.English]: 'Total Revenue',
    [LanguagesEnum.Greek]: 'Τα συνολικά έσοδα',
    [LanguagesEnum.Russian]: 'Общий доход',
  },
  total_revenue_chart_sub_title: {
    [LanguagesEnum.English]: 'Total revenue by date',
    [LanguagesEnum.Greek]: 'Το σύνολο των εσόδων από την ημερομηνία',
    [LanguagesEnum.Russian]: 'Общий доход по дате',
  },
  fillrate_chart_title: {
    [LanguagesEnum.English]: 'Fill Rate',
    [LanguagesEnum.Greek]: 'Συμπληρώστε Τιμή',
    [LanguagesEnum.Russian]: 'Скорость заполнения',
  },
  impression_chart_title: {
    [LanguagesEnum.English]: 'Impressions',
    [LanguagesEnum.Greek]: 'εντυπώσεις',
    [LanguagesEnum.Russian]: 'Впечатления',
  },
  revenue_chart_title: {
    [LanguagesEnum.English]: 'Revenue',
    [LanguagesEnum.Greek]: 'Εσοδα',
    [LanguagesEnum.Russian]: 'Доход',
  },
  impressions: {
    [LanguagesEnum.English]: 'Impressions',
    [LanguagesEnum.Greek]: 'εντυπώσεις',
    [LanguagesEnum.Russian]: 'Впечатления',
  },
  click: {
    [LanguagesEnum.English]: 'Clicks',
    [LanguagesEnum.Greek]: 'κλικ',
    [LanguagesEnum.Russian]: 'щелчки',
  },
  application_pie_chart: {
    [LanguagesEnum.English]: 'Impression vs Application',
    [LanguagesEnum.Greek]: 'Εντύπωση vs Εφαρμογή',
    [LanguagesEnum.Russian]: 'Впечатление против применения',
  },
  device_pie_chart: {
    [LanguagesEnum.English]: 'Impression vs Device',
    [LanguagesEnum.Greek]: 'Εντύπωση vs Συσκευή',
    [LanguagesEnum.Russian]: 'Впечатление против устройства',
  },
  fillrate_bar_chart: {
    [LanguagesEnum.English]: 'Fillrate per Geo',
    [LanguagesEnum.Greek]: 'Ρυθμό συμπλήρωσης ανά Geo',
    [LanguagesEnum.Russian]: 'Fillrate за Geo',
  },
  revenue_bar_chart: {
    [LanguagesEnum.English]: 'Revenue per Geo',
    [LanguagesEnum.Greek]: 'Τα έσοδα ανά Geo',
    [LanguagesEnum.Russian]: 'Доход от Geo',
  },
};

export default Translations;
