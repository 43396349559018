import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';
import { IState } from './reducer';

interface Publishers_Users_WhiteListListElement {
  Id: number;
  Userid: string;
  CategoryId: number;
  PublisherAppId: number;
}

interface Publishers_Users_WhiteListListData {
  paging: Publishers_Users_WhiteListListPaging;
  items: Publishers_Users_WhiteListListElement[];
}

interface Publishers_Users_WhiteListListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface Publishers_Users_WhiteLististOutput {
  status: StateStatus;
  data: Publishers_Users_WhiteListListData | null;
}

export const useGetPublishers_Users_WhiteListList = (): Publishers_Users_WhiteLististOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .Publishers_Users_WhiteListList
  );
  return data;
};

/* selector: useGetPublishers_Users_WhiteListDetails */
interface IExclusivePublisherItem {
  id: number;
  userid: string;
  categoryid: number;
  publisherappid: number;
}

interface Publishers_Users_WhiteListDetailsOutput {
  status: StateStatus;
  data: IExclusivePublisherItem | null;
}

export const useGetPublishers_Users_WhiteListDetails = (): Publishers_Users_WhiteListDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .viewPublishers_Users_WhiteList
  );
  return data;
};

export const useGetListExclusiveByPublisher = (): Publishers_Users_WhiteLististOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .listExclusiveByPublisher
  );
  return data;
};

export const useGetCategory = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER].category
  );
  return data;
};

export const useGetListUserByRole = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER].listUserByRole
  );
  return data;
};

export const useUpdateExclusivePublisherStatus = (): IState['updateExclusivePublisher']['status'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .updateExclusivePublisher.status
  );
  return data;
};

export const usePublisherApplicationByPublisher = (): IState['publisherApplicationByPublisher'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .publisherApplicationByPublisher
  );
  return data;
};

export const useAddExclusivePublisherStatus = (): IState['addPublishers_Users_WhiteList']['status'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .addPublishers_Users_WhiteList?.status
  );
  return data;
};

export const useDeleteExclusivePublisherStatus = (): IState['deleteExclusivePublisher']['status'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]
        .deleteExclusivePublisher.status
  );
  return data;
};
