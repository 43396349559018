import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../utils/common';
import * as TransportService from '../../../services/api/transport';
import { showToastAction } from 'components/atoms/toast_message';
import { LanguagesEnum } from 'config/languages';

class FetchTransportById implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(id: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await TransportService.fetchTransportById(id);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Fetch TransportsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IEditTransportInput {
  id: number;
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string | null;
  sim: string | null;
  serial: string | null;
  pin1: string | null;
  pin2: string | null;
  wifi: string | null;
  type: string;
  version: string | null;
  status: number | null;
  comments: string | null;
}

class EditTransport implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRANSPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditTransportInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await TransportService.editTransport(payload);
        dispatch(this.onSuccess(response.data));
        showToastAction(
          dispatch,
          {
            [LanguagesEnum.English]: 'Transport details changed',
            [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
            [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
          },
          'success'
        );
      } catch (error) {
        console.log('Fetch TransportsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchTransportByIdAction: (id: string) => new FetchTransportById().action(id),
  editTransportAction: (payload: IEditTransportInput) =>
    new EditTransport().action(payload),
};
