import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Grid,
  CardContent,
  Button,
} from '@material-ui/core';
import {
  AppSelect,
  AppTextField,
  AppDatePicker,
} from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import { SearchOutlined, CancelOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import Actions from '../../../redux/reducers/wallet_reducer/actions';
import { useGetCampaign } from '../../../redux/reducers/wallet_reducer/selectors';
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';

import { TableDefaultFetchValues } from '../configuration';
import useStyles from '../styles';

import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
interface IFormData {
  startdate: string | null;
  enddate: string | null;
  campaign: string | null;
  clientname: string | null;
}
const defaultValues = {
  startdate: null,
  enddate: null,
  campaign: '',
  clientname: '',
};

export default function Search() {
  const campaign = useGetCampaign();
  const dispatch = useDispatch();
  const [campaignOption, setCampaignOption] = useState([]);
  const [clientnameOption, setClientnameOption] = useState([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { id } = useSelectUserData();

  const classes = useStyles();

  useEffect(() => {
    if (campaign.status === 'Success') {
      setCampaignOption(
        _.uniqBy(
          Array.from(
            campaign.data.map((cam: any) => {
              return {
                id: cam.name,
                label: cam.name,
              };
            })
          ),
          'id'
        )
      );
      setClientnameOption(
        _.uniqBy(
          Array.from(
            campaign.data.map((cam: any) => {
              return {
                id: cam.clientname,
                label: cam.clientname,
              };
            })
          ),
          'id'
        )
      );
    }
  }, [campaign]);
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    reset,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });
  function onSubmit(_data: IFormData) {
    console.log('d', _data);
    dispatch(
      Actions.fetchWalletAction({
        id,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        search: {
          isSearch: true,
          values: {
            campaign: _data.campaign,
            clientname: _data.clientname,
            startdate: _data.startdate
              ? moment(_data.startdate).format('YYYY-MM-DD hh:mm:ss')
              : '',
            enddate: _data.enddate
              ? moment(_data.enddate).format('YYYY-MM-DD hh:mm:ss')
              : '',
          },
        },
      })
    );
  }
  const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion
      className={classes.mb12}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={expanded ? <CancelOutlined /> : <SearchOutlined />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TranslatedText textMap={Translations.search} />
      </AccordionSummary>
      <AccordionDetails className={classes.wfull}>
        <Card className={classes.wfull}>
          <CardContent className={classes.wfull}>
            <form className={classes.wfull} onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                className={clsx(
                  classes.flex,
                  classes.itemsCenter,
                  classes.jusityBetween
                )}
              >
                <Grid item xs={12} md={3}>
                  <AppSelect
                    variant="outlined"
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    options={campaignOption}
                    size="medium"
                    id="campaign"
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.campaign_label} />
                    }
                    name={'campaign'}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AppSelect
                    variant="outlined"
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    options={clientnameOption}
                    size="medium"
                    id="clientname"
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.clientname_label} />
                    }
                    name={'clientname'}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AppDatePicker
                    fullWidth
                    variant="dialog"
                    margin="normal"
                    label={
                      <TranslatedText textMap={Translations.start_date_label} />
                    }
                    autoOk
                    helperText={errors.startdate && errors.startdate.message}
                    error={'startdate' in errors}
                    control={control}
                    rules={{}}
                    name="startdate"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={clsx(
                  classes.flex,
                  classes.itemsCenter,
                  classes.jusityBetween
                )}
              >
                <Grid item xs={12} md={3}>
                  <AppDatePicker
                    fullWidth
                    variant="dialog"
                    margin="normal"
                    label={
                      <TranslatedText textMap={Translations.end_date_label} />
                    }
                    autoOk
                    helperText={errors.enddate && errors.enddate.message}
                    error={'enddate' in errors}
                    control={control}
                    rules={{}}
                    name="enddate"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    onClick={() => {
                      reset(defaultValues);
                      dispatch(
                        Actions.fetchWalletAction({
                          id,
                          page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
                          pageSize:
                            TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
                          sortField:
                            TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
                          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
                          search: {
                            isSearch: false,
                            values: {
                              campaign: '',
                              clientname: '',
                              startdate: '',
                              enddate: '',
                            },
                          },
                        })
                      );
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <TranslatedText textMap={Translations.clear_filter} />
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button type="submit" variant="outlined" color="secondary">
                    <TranslatedText textMap={Translations.search} />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
}
