import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import * as UserProfileSelectors from '../../store/selectors';
import * as StringUtils from 'utils/strings';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7',
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  dateText: {},
}));

const Profile = () => {
  const classes = useStyles();
  const userDetailsData = UserProfileSelectors.useGetUserProfile();

  return (
    <Card variant={'outlined'} className={clsx(classes.root)}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={user.avatar} />
          <Typography
            component={'p'}
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {`${
              StringUtils.capitalizeFirstLetter(
                userDetailsData.data?.firstName
              ) || ''
            } ${
              StringUtils.capitalizeFirstLetter(
                userDetailsData.data?.surname
              ) || ''
            }`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {userDetailsData.data?.email || ''}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {userDetailsData.data?.role}
            {/* {`${moment().format('hh:mm A')} ${
              userDetailsData.data?.registeredDate
            }`} */}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  //   className: PropTypes.string,
};

export default Profile;
