import React from 'react';
import _ from 'lodash';
import PageLayout from 'components/app_common/page_layout';
import GridLayout from 'components/molecules/grid_layout';
import { withRouter } from 'react-router';
import BusMap from './components/BusMap';

const PubDashboardPage = (props: any) => {
  return (
    <PageLayout>
      <GridLayout
        justify="center"
        elements={[
          {
            id: 'bus_map',
            size: 12,
            element: (
              <React.Fragment>
                <BusMap />
              </React.Fragment>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(PubDashboardPage);
