import React, { ReactElement } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

interface Iprops extends StandardTextFieldProps {
  options: Array<any>;
  control: Control;
  rules: any;
  selectedKey: string;
  name: string;
  handleChange?: (data: string | Array<string>) => void;
  controllerExtras?: any;
  style?: React.CSSProperties;
}

export default function AutoCompleteSelect(props: Iprops) {
  const {
    control,
    rules,
    options,
    handleChange,
    selectedKey,
    style,
    id,
    controllerExtras = {},
    ...other
  } = props;
  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          value={props?.value || ''}
          options={options}
          getOptionLabel={(option) => option[selectedKey] || ''}
          renderOption={(option) => option[selectedKey] || ''}
          renderInput={(params) => (
            <TextField variant="outlined" {...params} {...other} />
          )}
          onChange={(_, data) => {
            handleChange && handleChange(data || '');
            props.onChange(data);
          }}
        />
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
