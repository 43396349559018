import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as DSPService from '../../../services/api/dsp';
import * as ParalistService from '../../../services/api/paralist';
import * as UserService from '../../../services/api/user';
import * as PublisherService from '../../../services/api/publisher';
import * as AdvertisementTypeService from '../../../services/api/advertisement_type';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import { IGetParalistByGroupCodeInput } from '../../../services/api/paralist';
import {
  IDeleteListDspAdvertismentInput,
  IDeleteListDspPublisherInput,
  IGetListDspAdvertisementInput,
  IGetListDspPublisherInput,
  IPostDspAdvertisementInput,
  IPostListDspPublisherInput,
} from '../../../services/api/dsp';
import { IGetListAdvertisementTypeInput } from 'services/api/advertisement_type';

/** Fetch FetchDSPListInput  */

interface FetchDSPListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchDSPList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DSP_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DSP_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DSP_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchDSPListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.fetchDSPList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcDSPList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewDSP  */

interface AddNewDSPInput {
  code: string | null;
  name: string | null;
  userid: string | null;
  vasttype: number | null;
  fetchDSPInput: FetchDSPListInput;
}

class AddNewDSP implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewDSPInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchDSPInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await DSPService.addDSP(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchDSPList().action(fetchDSPInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_DSP,
          })
        );
      } catch (error) {
        console.log('AddNewDSP Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt DSP  */

interface EditDSPInput {
  code: string | null;
  name: string | null;
  userid: string | null;
  vasttype: number | null;
  fetchDSPInput: FetchDSPListInput;
}

class EditDSP implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditDSPInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchDSPInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await DSPService.editDSP(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchDSPList().action(fetchDSPInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_DSP,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

// Delete DSP
interface IDeleteDSPInput {
  code: string;
  fetchDSPInput: FetchDSPListInput;
}
class DeleteDSP implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DELETE_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DELETE_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DELETE_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IDeleteDSPInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchDSPInput, code } = payload;

        dispatch(this.onPending());
        let response = await DSPService.deleteDSP({ code });
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchDSPList().action(fetchDSPInput));
      } catch (error) {
        console.log('deleteDSP Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View DSP  */

interface ViewDSPInput {
  id: number;
}

class ViewDSP implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DSP_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DSP_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DSP_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewDSPInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.fetchDSPDetail(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewDSP Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchParalistList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PARALIST_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PARALIST_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PARALIST_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetParalistByGroupCodeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ParalistService.getParalistByGroupCode(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcDSPList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchUserByRoleAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_BY_ROLE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_BY_ROLE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_BY_ROLE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(code: number): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUsersbyRole(code);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcDSPList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchListDspPublisherAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_DSP_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_DSP_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_DSP_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetListDspPublisherInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.getListDspPublisher(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getListDspPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// Add Dsp Publisher

interface IAddDspPublisherAction extends IPostListDspPublisherInput {
  onFinish: () => void;
}
class AddDspPublisherAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CREATE_DSP_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CREATE_DSP_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CREATE_DSP_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ onFinish, ...rest }: IAddDspPublisherAction): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.postListDspPublisher(rest);
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('postListDspPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchPublisherByRoleAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(code: number): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getFilterListPublisher({
          id: code,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getFilterListPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// Delete DSP publisher

interface IDeleteDspPublisherAction extends IDeleteListDspPublisherInput {
  onFinish: () => void;
}
class DeleteDspPublisherAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_PUBLISHER_BY_ROLE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ onFinish, publisherid }: IDeleteDspPublisherAction): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.deleteListDspPublisher({
          publisherid,
        });
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('getFilterListPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// List DSP Advertisement
class FetchListDSPAdvertisementAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetListDspAdvertisementInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.getListDspAdvertisement(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getListDspAdvertisement Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// List Advertisement Type
class FetchListAdvertisementTypeAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_ADVERTISEMENT_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetListAdvertisementTypeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvertisementTypeService.getListAdvertisementType(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getListAdvertisementType Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// Create DSP Advertisement publisher

interface IAddDSPAdvertisementAction extends IPostDspAdvertisementInput {
  onFinish: () => void;
}
class AddDSPAdvertisementAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CREATE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CREATE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CREATE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ onFinish, ...rest }: IAddDSPAdvertisementAction): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.postDspAdvertisement(rest);
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('postDspAdvertisement Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// DELETE DSP Advertisement publisher

interface IDeleteDSPAdvertisementAction extends IDeleteListDspAdvertismentInput {
  onFinish: () => void;
}
class DeleteDSPAdvertisementAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_ADVERTISEMENT_TYPE_DSP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ onFinish, ...rest }: IDeleteDSPAdvertisementAction): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await DSPService.deleteListDspAdvertisment(rest);
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('deleteListDspAdvertisment Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchDSPListAction: (payload: FetchDSPListInput) =>
    new FetchDSPList().action(payload),
  addNewDSPAction: (payload: AddNewDSPInput) => new AddNewDSP().action(payload),
  editDSPAction: (payload: EditDSPInput) => new EditDSP().action(payload),
  deleteDSPAction: (payload: IDeleteDSPInput) =>
    new DeleteDSP().action(payload),
  viewDSPAction: (payload: ViewDSPInput) => new ViewDSP().action(payload),
  fetchParalistAction: (payload: IGetParalistByGroupCodeInput) =>
    new FetchParalistList().action(payload),
  fetchUserByRoleAction: (role: number) =>
    new FetchUserByRoleAction().action(role),
  fetchListDspPublisherAction: (payload: IGetListDspPublisherInput) =>
    new FetchListDspPublisherAction().action(payload),
  addDspPublisherAction: (payload: IAddDspPublisherAction) =>
    new AddDspPublisherAction().action(payload),
  fetchPublisherByRoleAction: (role: number) =>
    new FetchPublisherByRoleAction().action(role),
  deleteDspPublisherAction: (payload: IDeleteDspPublisherAction) =>
    new DeleteDspPublisherAction().action(payload),
  fetchListDSPAdvertisementAction: (payload: IGetListDspAdvertisementInput) =>
    new FetchListDSPAdvertisementAction().action(payload),
  fetchListAdvertisementTypeAction: (payload: IGetListAdvertisementTypeInput) =>
    new FetchListAdvertisementTypeAction().action(payload),
  addDSPAdvertisementAction: (payload: IAddDSPAdvertisementAction) =>
    new AddDSPAdvertisementAction().action(payload),
  deleteDSPAdvertisementAction: (payload: IDeleteDSPAdvertisementAction) =>
    new DeleteDSPAdvertisementAction().action(payload),
};
