import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { Colors } from '../../../styles';
import Configuration from './configuration';
import { SideBarWidth } from 'config';
import {
  APP_COLOR_PRIMARY,
  NAVBAR_ITEM_SELECTED,
  SIDE_BAR_COLOR,
  TEXT_COLOR_PRIMARY,
} from 'styles/colors';

const drawerWidth = SideBarWidth;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  appBar: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.05)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1, //temp
    },
  },
  appBarLogo: {
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.up('md')]: {
      height: '60px',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      // backgroundColor:
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  title: {
    display: 'none',
    // fontFamily: 'Sansita',
    // fontStyle: 'italic',
    // fontWeight: '',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  titleContainer: {
    border: '1px solid #FFFFFF',
    borderRadius: 5,
    display: 'none',
    boxSizing: 'border-box',
    padding: '1px 10px 1px 10px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 70,
    height: 70,
    padding: 4,
  },
  drawerListItemText: {},
  drawerListItemTextSelected: {},
  drawerPaper: {
    background: SIDE_BAR_COLOR(theme),
    color: theme.palette.type === 'dark' ? 'white' : 'dark',
    width: drawerWidth,
  },
  drawerItem: {
    width: '97%',
    backgroundColor: (props: any) =>
      props.isSelected
        ? APP_COLOR_PRIMARY({
            darkMode: theme.palette.type === 'dark',
            transparency: 1,
          })
        : 'transparent',
    color: (props: any) =>
      props.isSelected
        ? NAVBAR_ITEM_SELECTED(theme)
        : TEXT_COLOR_PRIMARY({
            darkMode: theme.palette.type === 'dark',
            transparency: 0.5,
          }),
    '&:hover': {
      backgroundColor: (props: any) =>
        !props.isSelected
          ? APP_COLOR_PRIMARY({
              darkMode: theme.palette.type === 'dark',
              transparency: 0.2,
            })
          : APP_COLOR_PRIMARY({
              darkMode: theme.palette.type === 'dark',
              transparency: 1,
            }),
    },
    '& .MuiTypography-colorTextSecondary, .MuiListItemIcon-root': {
      color: (props: any) =>
        props.isSelected
          ? NAVBAR_ITEM_SELECTED(theme)
          : TEXT_COLOR_PRIMARY({
              darkMode: theme.palette.type === 'dark',
              transparency: 0.5,
            }),
    },
    borderBottomRightRadius: 50,
    borderTopRightRadius: 50,
    marginBottom: 6,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    // color: Configuration.sidebar.config?.textColor1,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subElement: {
    cursor: 'pointer',
    // color: Configuration.sidebar.config?.textColor1,
    paddingLeft: 20,
    marginLeft: 5,
  },
  buttonText: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuItemSelected: {
    color: NAVBAR_ITEM_SELECTED(theme),
    fontWeight: 'bold',
  },
}));

export default useStyles;
