import { Languages } from '../../../config';
import { LanguagesEnum } from '../../../config/languages';
import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';

interface IState {
  language: LanguagesEnum.English | LanguagesEnum.Greek;
}

const initialState: IState = {
  language: Languages.English,
};

const TranslationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_LANGUAGE:
      return {
        ...state,
        language: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default TranslationReducer;
