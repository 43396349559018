import { makeStyles } from '@material-ui/core/styles';
import { CARD_BACKGROUND } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  'mr-12': {
    marginRight: 12,
  },
  flex: {
    display: 'flex',
  },
  wfull: {
    width: '100%',
  },
  jusityBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  p20: {
    padding: 20,
  },
  mb12: {
    marginBottom: 12,
  },
  card: {
    width: '100%',
    borderRadius: 20,
    marginBottom: 20,
  },
  color: { background: '#005FA3', color: '#fff' },
}));

export default useStyles;
