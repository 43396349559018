import { Languages } from 'config';
import Translations from '../../translations';
export interface IDisplay {
  code: number;
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  notes: Array<{
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  }>;
}

const InflightDisplay: IDisplay = {
  code: 0,
  name: Translations.inflight,
  notes: [Translations.display_note_inflight],
};

const WebisteDisplay: IDisplay = {
  code: 1,
  name: Translations.websites,
  notes: [Translations.display_note_websites],
};

const TransportDisplay: IDisplay = {
  code: 2,
  name: Translations.transport,
  notes: [Translations.display_note_transport],
};

export const CampaignAllDisplay: IDisplay[] = [
  InflightDisplay,
  WebisteDisplay,
  TransportDisplay,
];

export const CampaignInflightOnlyDisplay: IDisplay[] = [InflightDisplay];

export const CampaignWebsiteOnlyDisplay: IDisplay[] = [WebisteDisplay];

export const CampaignTransportOnlyDisplay: IDisplay[] = [TransportDisplay];
