import { Box, Typography } from '@material-ui/core';
import L from 'leaflet';
import _, { isArray } from 'lodash';
import React, { ReactElement, useState } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as AdvDashboardActions from 'redux/reducers/adv_dashboard_reducer/actions';
import * as AdvDashboardSelectors from 'redux/reducers/adv_dashboard_reducer/selectors';
import clsx from 'clsx';
import { renderToStaticMarkup } from 'react-dom/server';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import useStyles from './styles';
import request from 'utils/request';
import { useDispatch } from 'react-redux';

export const center: L.LatLngExpression = [34.999711, 33.786028];

interface IMapInstanceProps {
  children: ReactElement;
  setMapBounds?: any;
}
const MapInstance = ({ children, setMapBounds }: IMapInstanceProps) => {
  // const mapIntance = useMap();
  // React.useEffect(() => {
  //   if (mapIntance) {
  //     _.set(window, 'map', mapIntance);
  //     setMapBounds &&
  //       setMapBounds({
  //         tlc: map.getBounds().getNorthWest(),
  //         trc: map.getBounds().getNorthEast(),
  //         blc: map.getBounds().getSouthWest(),
  //         brc: map.getBounds().getSouthEast(),
  //       });
  //   }
  // }, [mapIntance]);

  // const map = useMapEvents({
  //   moveend() {
  //     setMapBounds &&
  //       setMapBounds({
  //         tlc: map.getBounds().getNorthWest(),
  //         trc: map.getBounds().getNorthEast(),
  //         blc: map.getBounds().getSouthWest(),
  //         brc: map.getBounds().getSouthEast(),
  //       });
  //   },
  // });

  // React.useEffect(() => {
  //   if (bounds && map) {
  //     const mapBounds: LatLngBoundsLiteral = [
  //       [Number.parseFloat(bounds.tlc_lat), Number.parseFloat(bounds.tlc_lng)],
  //       [Number.parseFloat(bounds.trc_lat), Number.parseFloat(bounds.trc_lng)],
  //       [Number.parseFloat(bounds.blc_lat), Number.parseFloat(bounds.blc_lng)],
  //       [Number.parseFloat(bounds.brc_lat), Number.parseFloat(bounds.brc_lng)],
  //     ];
  //     map.fitBounds(mapBounds);
  //   }
  // }, [map]);

  return <React.Fragment>{children}</React.Fragment>;
};

interface IProps {
  height?: number | string;
  setMapBounds?: any;
  stationClickView: any;
}
const BusStationMap = React.forwardRef(
  ({ setMapBounds, stationClickView }: IProps, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const stationClickView = AdvDashboardSelectors.useGetStationClickView();
    const [stations, setStations] = useState<any>([]);

    React.useEffect(() => {
      if (stationClickView.status === 'Success') {
        setStations(stationClickView.data);
        // if (
        //   stationClickView.data &&
        //   Array.isArray(stationClickView.data) &&
        //   stationStatistics &&
        //   Array.isArray(stationStatistics)
        // ) {
        //   stationStatistics.reduce((prev, curr) => {
        //     stationClickView.data.map((station: any) => {
        //       if (station.code === curr.nearestStation)
        //         setStations((state: any) => [
        //           ...state,
        //           { ...curr, ...station },
        //         ]);
        //     });
        //   }, []);
        // }
      }
    }, [stationClickView]);

    // React.useEffect(() => {
    //   dispatch(AdvDashboardActions.default.fetchStationClickViewAction());
    // }, [dispatch]);

    // const mouseOverMarker = (id: any) => {
    //   const elementStationMarker = document.getElementsByClassName(
    //     `marker_station_${id}`
    //   )[0] as HTMLElement;
    //   if (
    //     elementStationMarker &&
    //     !elementStationMarker.className.includes(`selected_${id}`)
    //   ) {
    //     (elementStationMarker.children[0]
    //       .children[0] as HTMLElement).style.fill = 'red';
    //     elementStationMarker.style.zIndex = '99999';
    //   }
    // };

    // const mouseOutMarker = (id: any) => {
    //   const elementStationMarker = document.getElementsByClassName(
    //     `marker_station_${id}`
    //   )[0] as HTMLElement;
    //   if (
    //     elementStationMarker &&
    //     !elementStationMarker.className.includes(`selected_${id}`)
    //   ) {
    //     (elementStationMarker.children[0]
    //       .children[0] as HTMLElement).style.removeProperty('fill');
    //     elementStationMarker.style.removeProperty('z-index');
    //   }
    // };

    return (
      <Box className={classes.map}>
        <MapContainer
          zoom={11}
          minZoom={10}
          scrollWheelZoom
          style={{ height: '100%' }}
          center={center}
          doubleClickZoom={false}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapInstance setMapBounds={setMapBounds}>
            <React.Fragment>
              {/** Show markers/labels of transport stations on the map */}
              {stations &&
                isArray(stations) &&
                stations.map(
                  (station, index) =>
                    station.location?.points[0].x &&
                    station.location?.points[0].y && (
                      <Marker
                        position={[
                          station.location.points[0].x,
                          station.location.points[0].y,
                        ]}
                        icon={L.divIcon({
                          className: clsx(
                            classes.pin
                            // `marker_bus_${bus.id}`
                          ),
                          html: renderToStaticMarkup(
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 321.883 321.883"
                            >
                              <path
                                fill={'blue'}
                                d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882
                          C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801
                          c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
                              />
                            </svg>
                          ),
                          iconSize: [33, 33],
                          iconAnchor: [20, 30],
                        })}
                        eventHandlers={
                          {
                            // mouseover: () => {
                            //   mouseOverMarker(station.id);
                            // },
                            // mouseout: () => {
                            //   mouseOutMarker(station.id);
                            // },
                          }
                        }
                        key={index}
                      >
                        <Tooltip direction="top" offset={[1, -40]}>
                          <Box>
                            <Typography
                              component="span"
                              style={{ fontWeight: 'bold' }}
                            >
                              {`Name: `}
                            </Typography>
                            <Typography component="span">
                              {station.Name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              style={{ fontWeight: 'bold' }}
                            >
                              {`Code: `}
                            </Typography>
                            <Typography component="span">
                              {station.Code}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              style={{ fontWeight: 'bold' }}
                            >
                              {`Total views: `}
                            </Typography>
                            <Typography component="span">
                              {station.totalviews}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              style={{ fontWeight: 'bold' }}
                            >
                              {`Total clicks: `}
                            </Typography>
                            <Typography component="span">
                              {station.totalclicks}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Marker>
                    )
                )}

              {/** Add new marker for admins */}
              {/* </React.Fragment>
          <AddNewMarker/>
        <React.Fragment> */}
              {/** Zoom controls position on map */}
              <ZoomControl position="bottomleft" />
            </React.Fragment>
          </MapInstance>
        </MapContainer>
      </Box>
    );
  }
);

export default BusStationMap;
