import { Button, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import { StateStatus } from 'redux/utils/common';
import Swal from 'sweetalert2';
import NavigationConfig from 'navigation/config';

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const openAdvertiserConnection = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADVERTISER_CONNECTION,
        params: { data: props.data },
      })
    );
  };

  const openAdvertiserCalculator = () => {
    history.push(
      NavigationConfig.advertiserCalculationPage(props?.dataid).path
    );
    // dispatch(
    //   ModalActions.openModalAction({
    //     modalID: ModalIDs.MODAL_ADVERTISER_CALCULATOR,
    //     params: { data: props.data },
    //   })
    // );
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
        }}
        onClick={openAdvertiserConnection}
      >
        Connection
      </Button>

      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginLeft: 10,
        }}
        onClick={openAdvertiserCalculator}
      >
        Calculation
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: 'ID',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Email',
    id: 'email',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Role Name',
    id: 'role_name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue.join(', ')}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data[rowIndex]}
        dataid={data[rowIndex].id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'asc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export interface IFormData {
  userid: string | null;
  vasttype: number | null;
  name: string | null;
  code: string | null;
}

export const formDefaultValue: IFormData = {
  userid: null,
  vasttype: null,
  name: null,
  code: null,
};
