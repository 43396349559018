import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { objectToUrlEncoded } from 'utils/objects';

/** fetchPublishers_Users_WhiteListList  */
interface IPublishers_Users_WhiteListListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  id: number;
  userid: string | null;
  categoryid: number;
  publisherappid: number;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IPublishers_Users_WhiteListListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchPublishers_Users_WhiteListList = async (
  payload: IPublishers_Users_WhiteListListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IPublishers_Users_WhiteListListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Publishers_Users_WhiteList Detail */
interface IPublishers_Users_WhiteListDetailInput {
  id: number;
}

interface IPublishers_Users_WhiteListDetailOutput extends Types.ResponseOutput {
  data: {
    id: number | null;
    userid: string | null;
    categoryid: number | null;
    publisherappid: number | null;
  };
}

export const fetchPublishers_Users_WhiteListDetail = async (
  payload: IPublishers_Users_WhiteListDetailInput
): Promise<IPublishers_Users_WhiteListDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export interface IFetchListExclusiveByPublisher
  extends IPublishers_Users_WhiteListListInput {
  id: number;
}

export const fetchListExclusiveByPublisher = async (
  payload: IFetchListExclusiveByPublisher
): Promise<IPublishers_Users_WhiteListDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist/listByPublisher?publisher_id=${payload.id}&page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Publishers_Users_WhiteList */
interface IPublishers_Users_WhiteListAddInput {
  userid: number | null;
  categoryid: number | null;
  publisherappid: number | null;
}

interface IPublishers_Users_WhiteListAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addPublishers_Users_WhiteList = async (
  payload: IPublishers_Users_WhiteListAddInput
): Promise<IPublishers_Users_WhiteListAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Publishers_Users_WhiteList */
interface IPublishers_Users_WhiteListEditInput {
  userid: string | null;
  categoryid: number | null;
  publisherappid: number | null;
}

interface IPublishers_Users_WhiteListEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editPublishers_Users_WhiteList = async (
  payload: IPublishers_Users_WhiteListEditInput
): Promise<IPublishers_Users_WhiteListEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// Update exclusive publisher

export interface IUpdateExclusivePublisherInput {
  id: number;
  userid: number;
  categoryid: number;
  publisherappid: number;
}

export const updateExclusivePublisher = async ({
  id,
  ...payload
}: IUpdateExclusivePublisherInput): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist/${id}`,
    objectToUrlEncoded(payload)
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const deleteExclusivePublisher = async (
  id: string
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/publishers_users_whitelist/${id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
