// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { number } from '@amcharts/amcharts4/core';

export const getFilterListPublisher = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/publisher-list/${payload.id}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPublisherByUserId = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/get-publisher/${payload.userid}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const updatePublisherAllowStatus = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publisher/update-allow-status`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getPublisherApplication = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const queryParams = payload.sortField
    ? `?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
    : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/get-publisher-application/${payload.id}${queryParams}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const addPublisherApplication = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publisher/add-publisher-application/${payload.id}`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getPublisherApplicationByUniqueid = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const query = payload.uniqueid ? `?uniqueid=${payload.uniqueid}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/get/publisher-application/by-uniqueid${query}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const updatePublisherApplication = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publisher/update-publisher-application/${payload.userid}`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPublisherapplicationType = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const query = payload.applicationid
    ? `?applicationid=${payload.applicationid}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/get-publisher-application/type/${payload.userid}${query}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const savePublisherapplicationType = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publisher/save-publisher-application/type`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const deletePublisherapplicationType = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/publisher/delete-publisher-application/type`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

// get publisher application by publisher

export interface IGetPublisherApplicationByPublisherInput {
  id: string;
  page: number;
  pageSize: number;
  order: string;
  sortField: string;
}

export interface IGetPublisherApplicationByPublisherOutput
  extends Types.ResponseOutput {
  data: {
    items: {
      id: number;
      name: string;
      description: string;
    }[];
    paging: {
      page: number;
      pageSize: number;
      total: number;
    };
  };
}

export const getPublisherApplicationByPublisher = async (
  payload: IGetPublisherApplicationByPublisherInput
): Promise<IGetPublisherApplicationByPublisherOutput> => {
  const queryParams = payload.sortField
    ? `?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
    : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/get-publisher-application/by-publisher/${payload.id}${queryParams}`
  );

  let _data: any = data.data;
  let output: IGetPublisherApplicationByPublisherOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

// get Detail Publisher

export interface IGetDetailPublisherInput {
  publisher_id: number;
}

export interface IGetDetailPublisherOutput extends Types.ResponseOutput {
  data: {
    id: string;
    guid: string;
    name: string;
    type: number | null;
    creationdate: string;
    lat: number | null;
    lon: number | null;
    afiliateid: number | null;
    code: string;
    needsapproval: number | null;
    status: number | null;
    defaultuserid: number | null;
    publisherpersentage: number | null;
    publisherfixamount: number | null;
    publisherfixcpv: number | null;
    platformfixcpv: number | null;
    platformpercentage: number | null;
    platformfixamount: number | null;
    commisioncalculationtype: number | null;
  };
}

export const getDetailPublisher = async (
  payload: IGetDetailPublisherInput
): Promise<IGetDetailPublisherOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publisher/${payload.publisher_id}`
  );

  let _data: any = data.data;
  let output: IGetDetailPublisherOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
