import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useHistory, useParams } from 'react-router';
import NavigationConfig from 'navigation/config';
import { StateStatus } from 'redux/utils/common';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import { useAddPublisherConnectionStatus } from 'redux/reducers/publisher_reducer/selectors';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import Datatable from 'components/molecules/datatable';
import Swal from 'sweetalert2';
import {
  useCreateDspPublisherStatus,
  useGetListDspPublisher,
  useListPublisherByRole,
} from 'redux/reducers/dsp_reducer/selectors';

interface IPublisherConnection {
  data?: any;
}

interface IFormData {
  publisherslaveid: string | null;
}

const defaultValues: IFormData = {
  publisherslaveid: null,
};

const PublisherConnection = (props: IPublisherConnection) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const addPublisherConnectionStatus = useAddPublisherConnectionStatus();
  const listDspPublisher = useGetListDspPublisher();
  const listPublisherByRole = useListPublisherByRole();
  const createDspPublisherStatus = useCreateDspPublisherStatus();
  useEffect(() => {
    if (props?.data) {
      dispatch(
        DSPActions.fetchListDspPublisherAction({
          sortField: 'id',
          order: 'asc',
          page: 0,
          pageSize: 9999,
          DSPCode: props?.data?.code,
        })
      );
      if (!listPublisherByRole?.data) {
        dispatch(DSPActions.fetchPublisherByRoleAction(2));
      }
    }
  }, [props?.data]);
  const { control, errors, handleSubmit } = useForm<IFormData>({
    defaultValues,
  });
  const handleCreateConnection = (data: any) => {
    dispatch(
      DSPActions.addDspPublisherAction({
        dspcode: props?.data?.code,
        publisherid: data?.publisherid,
        onFinish: () => {
          dispatch(
            DSPActions.fetchListDspPublisherAction({
              sortField: 'id',
              order: 'asc',
              page: 0,
              pageSize: 9999,
              DSPCode: props?.data?.code,
            })
          );
        },
      })
    );
  };
  const optionListPublisher =
    listPublisherByRole?.data?.publishers?.map((item: any) => ({
      id: item?.id,
      label: item?.name,
    })) ?? [];

  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '32px',
            textAlign: 'center',
          }}
        >
          List of Publishers connected with {props?.data?.name}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            textAlign: 'center',
          }}
        >
          Connect Publishers with {props?.data?.name}
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: 'auto',
            margin: '20px',
          }}
          onSubmit={handleSubmit(handleCreateConnection)}
        >
          <AppSelect
            variant="outlined"
            error={Boolean(_.get(errors, 'publisherid', false))}
            rules={{
              required: 'This is required',
            }}
            options={optionListPublisher}
            id={'publisherid'}
            control={control}
            label={'List publisher'}
            autoComplete={'publisherid'}
            name={'publisherid'}
            style={{
              minWidth: 150,
              marginRight: 15,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            type="submit"
            disabled={addPublisherConnectionStatus === StateStatus.Pending}
          >
            {createDspPublisherStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              'Add connection'
            )}
          </Button>
        </form>

        {listDspPublisher?.data?.items?.length ? (
          <Datatable
            loading={listDspPublisher.status === StateStatus.Pending}
            // sorting={sorting}
            // paging={paging}
            data={listDspPublisher?.data?.items || []}
            columns={TableColumns}
            // changePageHandle={changePageHandler}
            paperProps={{
              style: {
                maxHeight: '50vh',
                overflowY: 'auto',
              },
            }}
          />
        ) : (
          <Box
            fontSize="20px"
            borderTop="1px solid #e1e1e1"
            width="100%"
            textAlign="center"
            paddingTop="15px"
          >
            No data
          </Box>
        )}
      </Box>
    </Box>
  );
};

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (e: any) => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          DSPActions.deleteDspPublisherAction({
            publisherid: props?.dataid?.id,
            onFinish: () => {
              dispatch(
                DSPActions.fetchListDspPublisherAction({
                  sortField: 'id',
                  order: 'asc',
                  page: 0,
                  pageSize: 9999,
                  DSPCode: props?.dataid?.dspcode,
                })
              );
            },
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
          backgroundColor: 'red',
        }}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  // {
  //   label: 'Code',
  //   id: 'dspcode',
  //   render: (rowIndex: number, cellValue: any, columnIndex: number) => (
  //     <React.Fragment>{cellValue}</React.Fragment>
  //   ),
  //   inData: true,
  // },
  {
    label: 'Publisher',
    id: 'publisher_name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface IPublisherConnectionModalProps {}
const PublisherConnectionModal = (props: IPublisherConnectionModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_PUBLISHER_CONNECTION}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IPublisherConnection) => (
        <PublisherConnection {...params} />
      )}
    />
  );
};

export default PublisherConnectionModal;
