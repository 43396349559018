import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Box,
  Button,
  Tooltip,
  CardContent,
  Card,
  CardHeader,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PageLayout from 'components/app_common/page_layout';
import DashboardBuilder, {
  CustomConfig,
  SimpleConfig,
} from 'components/compounds/dashboard_builder';
import GridLayout from 'components/molecules/grid_layout';
import SearchArea from './components/SearchArea';
import getStatisticConfig from './configuration/StatisticConfig';
import { Roles } from 'config/authRoles';
import { withRouter } from 'react-router';
import BusStationMap from './components/BusStationMap';
import useStyles from './styles';
import getRevenueConfig from './configuration/RevenueConfig';
import getFillrateConfig from './configuration/FillRateConfig';
import getImpressionConfig from './configuration/ImpressionConfig';
import getRevenueBarConfig from './configuration/RevenueBarConfig';
import getBarChartConfig from './configuration/BarChartConfig';
import Actions from 'redux/reducers/pub_dashboard_reducer/actions';
import { CampaignEnv } from 'config/campaign_environemnt';
import {
  useGetCountryClickView,
  useGetFilterList,
  useGetRevenueAreaChart,
  useGetFillrateBarChart,
  useGetCampaignClickView,
  useGetFillrateByCountry,
  useGetImpressionAreaChart,
  useGetOSClickView,
  useGetRevenueBarChart,
  useGetTotalSpentByDestAirport,
  useGetUserList,
  useGetTopResults,
  useGetTop5ResultsByStation,
} from 'redux/reducers/pub_dashboard_reducer/selectors';
import {
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import { useForm } from 'react-hook-form';
import TranslatedText from 'components/atoms/translated_text';
import { AppSelect } from 'components/molecules/form_controls';
import { AuthRoles } from 'config';
import clsx from 'clsx';
import Translations from './translations';
import { rangeOption } from './configuration';
import { RestoreOutlined } from '@material-ui/icons';

interface IFormData {
  publisherid: string | null;
  appid: string | null;
  range: string | null;
  userid: string | null;
}
const defaultValues: IFormData = {
  publisherid: null,
  appid: null,
  range: '2000-01-01',
  userid: null,
};

const PubDashboardPage = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelectUserRoles();
  const { id } = useSelectUserData();

  const topResults = useGetTopResults();
  const top5ResultsByStation = useGetTop5ResultsByStation();
  const revenueAreaChart = useGetRevenueAreaChart();
  const fillrateBarChart = useGetFillrateBarChart();
  const impressionAreaChart = useGetImpressionAreaChart();
  const revenueBarChart = useGetRevenueBarChart();

  const filterList = useGetFilterList();
  const userList = useGetUserList();

  const [progressClicks, setProgressClicks] = useState<any>([]);
  const [progressViews, setProgressViews] = useState<any>([]);

  const [publisherOption, setPublisherOption] = useState([]);
  const [applicationOption, setApplicationOption] = useState([]);
  const [userOption, setUserOption] = useState([]);

  React.useEffect(() => {
    if (id) {
      dispatch(Actions.fetchFilterListAction({ id }));
    }
  }, [id]);

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  React.useEffect(() => {
    var userid = getParameterByName('userid');
    const appid = getParameterByName('appid');
    const publisherid = getParameterByName('publisherid');
    const range = getParameterByName('range');
    if (
      !roles.includes(Roles.Administrator) &&
      roles.includes(Roles.PublisherTransport)
    ) {
      userid = id;
    }
    reset({
      userid,
      appid,
      publisherid,
      range,
    });
    dispatch(
      Actions.fetchTopResultsAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTop5ResultsByStationAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(Actions.fetchUserListAction({}));
    dispatch(
      Actions.fetchRevenueAreaChartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchFillrateBarchartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchImpressionAreachartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchRevenueBarchartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    // dispatch(
    //   Actions.fetchCampaignClickViewAction({
    //     userid,
    //     appid,
    //     publisherid,
    //     datefrom: range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchOSClickViewAction({
    //     userid,
    //     appid,
    //     publisherid,
    //     datefrom: range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchFillrateByCountryAction({
    //     userid,
    //     appid,
    //     publisherid,
    //     datefrom: range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalSpentByDestairportAction({
    //     userid,
    //     appid,
    //     publisherid,
    //     datefrom: range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
  }, [dispatch]);

  React.useEffect(() => {
    if (filterList.status === 'Success') {
      setPublisherOption(
        filterList.data.publishers.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
      setApplicationOption(
        filterList.data.applications.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [filterList]);

  React.useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList.data.map((item: any) => {
          return {
            id: item.id,
            label: item.Email,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  function setUrlParams(data: IFormData) {
    const { publisherid, appid, range, userid } = data;
    window.history.pushState(
      '',
      '',
      `/pub-dashboard?userid=${userid}publisherid=${publisherid}&appid=${appid}&range=${range}`
    );
  }

  const onSubmit = (_data: IFormData) => {
    console.log(_data);
    setUrlParams(_data);

    dispatch(
      Actions.fetchTopResultsAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTop5ResultsByStationAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchRevenueAreaChartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchFillrateBarchartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchRevenueBarchartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchImpressionAreachartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    // dispatch(
    //   Actions.fetchCampaignClickViewAction({
    //     userid: _data.userid,
    //     appid: _data.appid,
    //     publisherid: _data.publisherid,
    //     datefrom: _data.range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchOSClickViewAction({
    //     userid: _data.userid,
    //     appid: _data.appid,
    //     publisherid: _data.publisherid,
    //     datefrom: _data.range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchFillrateByCountryAction({
    //     userid: _data.userid,
    //     appid: _data.appid,
    //     publisherid: _data.publisherid,
    //     datefrom: _data.range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalSpentByDestairportAction({
    //     userid: _data.userid,
    //     appid: _data.appid,
    //     publisherid: _data.publisherid,
    //     datefrom: _data.range,
    //     dateto: null,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
  };

  return (
    <PageLayout>
      <GridLayout
        justify="center"
        elements={[
          {
            id: 'search box',
            size: 12,
            element: roles.includes(AuthRoles.Administrator) ? (
              <Card>
                <CardContent>
                  <form
                    className={classes.search}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'userid', false))}
                      helperText={_.get(errors, 'userid', '')}
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={userOption}
                      id={'userid'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.user_search} />
                      }
                      autoComplete={'userid'}
                      name={'userid'}
                    />
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'publisherid', false))}
                      helperText={_.get(errors, 'publisherid', '')}
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={publisherOption}
                      id={'publisherid'}
                      control={control}
                      label={
                        <TranslatedText
                          textMap={Translations.publisher_search}
                        />
                      }
                      autoComplete={'publisherid'}
                      name={'publisherid'}
                    />
                    <AppSelect
                      variant="outlined"
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      error={Boolean(_.get(errors, 'appid', false))}
                      helperText={_.get(errors, 'appid', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={applicationOption}
                      id={'appid'}
                      control={control}
                      label={
                        <TranslatedText
                          textMap={Translations.application_search}
                        />
                      }
                      autoComplete={'appid'}
                      name={'appid'}
                    />
                    <AppSelect
                      variant="outlined"
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      error={Boolean(_.get(errors, 'range', false))}
                      helperText={_.get(errors, 'range', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={rangeOption}
                      id={'range'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.range_search} />
                      }
                      autoComplete={'range'}
                      name={'range'}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 55.68,
                      }}
                    >
                      <Button
                        className={classes['mr-12']}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        <TranslatedText textMap={Translations.button_search} />
                      </Button>

                      <Tooltip title="Refresh result" placement="top-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setUrlParams(defaultValues);

                            reset(defaultValues);
                            dispatch(
                              Actions.fetchTopResultsAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            dispatch(
                              Actions.fetchTop5ResultsByStationAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            // dispatch(
                            //   Actions.fetchCountryClickViewAction({
                            //     userid: null,
                            //     appid: null,
                            //     publisherid: null,
                            //     datefrom: null,
                            //     dateto: null,
                            //     enviromentworlds: CampaignEnv.Transport,
                            //   })
                            // );
                            dispatch(
                              Actions.fetchRevenueAreaChartAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            dispatch(
                              Actions.fetchFillrateBarchartAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            dispatch(
                              Actions.fetchRevenueBarchartAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            dispatch(
                              Actions.fetchImpressionAreachartAction({
                                userid: null,
                                appid: null,
                                publisherid: null,
                                datefrom: null,
                                dateto: null,
                                enviromentworlds: CampaignEnv.Transport,
                              })
                            );
                            // dispatch(
                            //   Actions.fetchCampaignClickViewAction({
                            //     userid: null,
                            //     appid: null,
                            //     publisherid: null,
                            //     datefrom: null,
                            //     dateto: null,
                            //     enviromentworlds: CampaignEnv.Transport,
                            //   })
                            // );
                            // dispatch(
                            //   Actions.fetchOSClickViewAction({
                            //     userid: null,
                            //     appid: null,
                            //     publisherid: null,
                            //     datefrom: null,
                            //     dateto: null,
                            //     enviromentworlds: CampaignEnv.Transport,
                            //   })
                            // );
                            // dispatch(
                            //   Actions.fetchFillrateByCountryAction({
                            //     userid: null,
                            //     appid: null,
                            //     publisherid: null,
                            //     datefrom: null,
                            //     dateto: null,
                            //     enviromentworlds: CampaignEnv.Transport,
                            //   })
                            // );
                            // dispatch(
                            //   Actions.fetchTotalSpentByDestairportAction({
                            //     userid: null,
                            //     appid: null,
                            //     publisherid: null,
                            //     datefrom: null,
                            //     dateto: null,
                            //     enviromentworlds: CampaignEnv.Transport,
                            //   })
                            // );
                          }}
                        >
                          <RestoreOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </form>
                </CardContent>
              </Card>
            ) : (
              <React.Fragment />
            ),
          },
          {
            id: 'statistic',
            size: 12,
            element: (
              <React.Fragment>
                {topResults.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getStatisticConfig(topResults.data)}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          // {
          //   id: 'bus_map',
          //   size: 12,
          //   element: (
          //     <React.Fragment>
          //       <BusMap />
          //     </React.Fragment>
          //   ),
          // },
          {
            id: 'total-revenue',
            size: 12,
            element: (
              <React.Fragment>
                {revenueAreaChart.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getRevenueConfig(revenueAreaChart.data)}
                  />
                ) : null}
              </React.Fragment>
            ),
          },
          {
            id: 'general',
            size: 12,
            element: (
              <GridLayout
                elements={[
                  {
                    id: 'fillrate',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.fillrate_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {fillrateBarChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getFillrateConfig(
                                fillrateBarChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'impression',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.impression_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {impressionAreaChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getImpressionConfig(
                                impressionAreaChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'revenue',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.revenue_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {revenueBarChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getRevenueBarConfig(
                                revenueBarChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'top 5 results by station',
            size: 12,
            element: (
              <GridLayout
                elements={[
                  {
                    id: 'totalviews',
                    size: 4,
                    element: (
                      <React.Fragment>
                        {top5ResultsByStation.status === 'Success' ? (
                          <DashboardBuilder
                            configuration={getBarChartConfig({
                              data: top5ResultsByStation.data.map(
                                (item: any) => item.totalviews
                              ),
                              labels: top5ResultsByStation.data.map(
                                (item: any) => item.Name
                              ),
                              title: Translations.top_views_per_station.English,
                            })}
                          />
                        ) : (
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </React.Fragment>
                    ),
                  },
                  {
                    id: 'totalclicks',
                    size: 4,
                    element: (
                      <React.Fragment>
                        {top5ResultsByStation.status === 'Success' ? (
                          <DashboardBuilder
                            configuration={getBarChartConfig({
                              data: top5ResultsByStation.data.map(
                                (item: any) => item.totalclicks
                              ),
                              labels: top5ResultsByStation.data.map(
                                (item: any) => item.Name
                              ),
                              title:
                                Translations.top_clicks_per_station.English,
                            })}
                          />
                        ) : (
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </React.Fragment>
                    ),
                  },
                  {
                    id: 'amount',
                    size: 4,
                    element: (
                      <React.Fragment>
                        {top5ResultsByStation.status === 'Success' ? (
                          <DashboardBuilder
                            configuration={getBarChartConfig({
                              data: top5ResultsByStation.data.map(
                                (item: any) => item.amount
                              ),
                              labels: top5ResultsByStation.data.map(
                                (item: any) => item.Name
                              ),
                              title:
                                Translations.top_amount_per_station.English,
                            })}
                          />
                        ) : (
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </React.Fragment>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'top 5 stations map',
            size: 12,
            element: (
              <BusStationMap stationStatistics={top5ResultsByStation.data} />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(PubDashboardPage);
