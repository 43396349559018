import { Box, Container, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import TranslatedText from '../../components/atoms/translated_text';
import FlightsActions from '../../redux/reducers/flights_reducer/actions';
import useStyles from './styles';
import DatatableList from './components/datatable';
import SearchArea from './components/SearchArea';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import clsx from 'clsx';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { TableDefaultFetchValues } from './configuration';
import Translations from './translations';

const FlightsPage = (props: any) => {
  console.log('Render FlightsPage');
  const [date, setDate] = useState(null);
  const [dateto, setDateto] = useState(null);

  const [publisherid, setPublisherid] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useSelectUserData();

  React.useEffect(() => {
    dispatch(
      FlightsActions.fetchFlightsListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        date: null,
        dateto: null,
        publisherid: null,
      })
    );
    if (id) dispatch(FlightsActions.fetchPublisherListAction({ id }));
  }, [dispatch, id]);

  return (
    <div>
      <SearchArea
        date={date}
        setDate={setDate}
        dateto={dateto}
        setDateto={setDateto}
        publisherid={publisherid}
        setPublisherid={setPublisherid}
      />
      <DatatableList
        date={date}
        dateto={dateto}
        setDateto={setDateto}
        setDate={setDate}
        publisherid={publisherid}
        setPublisherid={setPublisherid}
      />
    </div>
  );
};

export default withRouter(FlightsPage);
