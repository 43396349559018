import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetPublisherApplication'
);
export const SET_CATEGORY: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetCategory'
);
export const UPDATE_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'UpdatePublisherApplication'
);

export const DELETE_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'DeletePublisherApplication'
);

export const SET_PUBLISHER_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetPublisherApplicationType'
);
export const SET_NEW_PUBLISHER_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetNewPublisherApplicationType'
);
export const SET_CURRENT_PUBLISHER_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetCurrentPublisherApplicationType'
);
export const SET_LOCATION: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetLocation'
);
export const SET_DIALOG: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetDialog'
);
export const SET_IS_NEW_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SetIsNewApplicationType'
);
export const SAVE_PUBLIHSER_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'SavePublisherApplicationType'
);

export const DELETE_PUBLIHSER_APPLICATION_TYPE: string = typeCreatorV2(
  'ApplicationDetailReducer',
  'DeletePublisherApplicationType'
);
