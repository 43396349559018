import React from 'react';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { MainRoutes } from './navigation/config/Routes';
import ToastMessage from './components/atoms/toast_message';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from 'providers/theme_provider';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <MainRoutes />
          <ToastMessage />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
