import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
// import ProductActions from '../../store/actions';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useHistory, useParams } from 'react-router';
import NavigationConfig from 'navigation/config';
import { StateStatus } from 'redux/utils/common';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import {
  addPublishersConnectionsAction,
  deletePublishersConnectionsAction,
  fetchListSlaveFromMaster,
} from 'redux/reducers/publisher_reducer/actions';
import {
  useAddPublisherConnectionStatus,
  useGetAllPublisher,
  useListSlaveFromMaster,
} from 'redux/reducers/publisher_reducer/selectors';
import Datatable from 'components/molecules/datatable';
import Swal from 'sweetalert2';

interface IPublisherConnection {
  data?: any;
}

interface IFormData {
  publisherslaveid: string | null;
}

const defaultValues: IFormData = {
  publisherslaveid: null,
};

const PublisherConnection = (props: IPublisherConnection) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const listSlave = useListSlaveFromMaster();
  const addPublisherConnectionStatus = useAddPublisherConnectionStatus();
  const listIdExceptSelectSlave = [
    props?.data?.id,
    ...(listSlave?.data?.items?.map((item) => item.publisherslaveid) || []),
  ];
  const getAllPublisher = useGetAllPublisher();
  useEffect(() => {
    if (props?.data) {
      dispatch(
        fetchListSlaveFromMaster({
          publishermasterid: props?.data?.id,
          sortField: 'id',
          page: 0,
          pageSize: 9999,
          order: 'asc',
        })
      );
    }
  }, [props?.data]);
  const { control, errors, handleSubmit } = useForm<IFormData>({
    defaultValues,
  });
  const handleCreateConnection = (data: any) => {
    dispatch(
      addPublishersConnectionsAction({
        data: {
          publishermasterid: props?.data?.id,
          publisherslaveid: data?.publisherslaveid,
        },
        onFinish: () => {
          dispatch(
            fetchListSlaveFromMaster({
              publishermasterid: props?.data?.id,
              sortField: 'id',
              page: 0,
              pageSize: 9999,
              order: 'asc',
            })
          );
        },
      })
    );
  };
  const optionListPublisher =
    getAllPublisher?.data?.items
      ?.filter((item: any) => !listIdExceptSelectSlave?.includes(item?.id))
      ?.map((item: any) => ({
        id: item?.id,
        label: item?.name,
      })) ?? [];

  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          Connect with other Publishers
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          Connect with other publisher and share revenues
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: 'auto',
            margin: '20px',
          }}
          onSubmit={handleSubmit(handleCreateConnection)}
        >
          <AppSelect
            variant="outlined"
            error={Boolean(_.get(errors, 'publisherslaveid', false))}
            rules={{
              required: 'This is required',
            }}
            options={optionListPublisher}
            id={'publisherslaveid'}
            control={control}
            label={'List publisher'}
            autoComplete={'publisherslaveid'}
            name={'publisherslaveid'}
            style={{
              minWidth: 150,
              marginRight: 15,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            type="submit"
            disabled={addPublisherConnectionStatus === StateStatus.Pending}
          >
            {addPublisherConnectionStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              'Add connection'
            )}
          </Button>
        </form>

        {listSlave?.data?.items?.length ? (
          <Datatable
            loading={listSlave.status === StateStatus.Pending}
            // sorting={sorting}
            // paging={paging}
            data={listSlave?.data?.items || []}
            columns={TableColumns}
            // changePageHandle={changePageHandler}
            paperProps={{
              style: {
                maxHeight: '50vh',
                overflowY: 'auto',
              },
            }}
          />
        ) : (
          <Box
            fontSize="20px"
            borderTop="1px solid #e1e1e1"
            width="100%"
            textAlign="center"
            paddingTop="15px"
          >
            No data
          </Box>
        )}
      </Box>
    </Box>
  );
};

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (e: any) => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deletePublishersConnectionsAction({
            publisherslaveid: props?.dataid?.id,
            onFinish: () => {
              dispatch(
                fetchListSlaveFromMaster({
                  publishermasterid: props?.dataid?.publishermasterid,
                  sortField: 'id',
                  page: 0,
                  pageSize: 9999,
                  order: 'asc',
                })
              );
            },
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
          backgroundColor: 'red',
        }}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Code',
    id: 'slave_code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'slave_name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface IPublisherConnectionModalProps {}
const PublisherConnectionModal = (props: IPublisherConnectionModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_PUBLISHER_CONNECTION}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IPublisherConnection) => (
        <PublisherConnection {...params} />
      )}
    />
  );
};

export default PublisherConnectionModal;
