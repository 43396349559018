import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme) => ({
  create_campaign: {
    display: 'block',
    marginLeft: 'auto',
    marginBottom: '.5rem',
  },
  cardLayout: {
    marginBottom: 10,
  },
}));

export default useStyles;
