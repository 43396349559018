import React from 'react';
import ActionsBlock from './components/datatable/ActionsBlock';
import * as DatesUtils from '../../utils/dates';
import CircularProgressWithLabel from '../../components/atoms/circular_progress_label';
import Translations from './translations';
import TranslatedText from 'components/atoms/translated_text';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;

export const TableColumns = [
  {
    label: 'Campaign ID',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.table_column_completed} />,
    id: 'completed',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        <CircularProgressWithLabel value={cellValue > 100 ? 100 : cellValue} />
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.table_column_campaign_name} />,
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: (
      <TranslatedText textMap={Translations.table_column_campaign_display} />
    ),
    id: 'display',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  // {
  //   label: <TranslatedText textMap={Translations.table_column_customer_name} />,
  //   id: 'clientname',
  //   render: (rowIndex: number, cellValue: any, columnIndex: number) => (
  //     <React.Fragment>{cellValue}</React.Fragment>
  //   ),
  //   inData: true,
  // },
  {
    label: <TranslatedText textMap={Translations.table_column_active_status} />,
    id: 'active',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue === true ? 'Active' : 'In-Active'}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.table_column_budget} />,
    id: 'maxbudget',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>€ {cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.table_column_status} />,
    id: 'status',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.table_column_action} />,
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex].id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
