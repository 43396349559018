import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import { CountriesistOutput } from './selectors';
import * as Types from './types';

interface IState {
  countriesList: CountriesistOutput;
  addCountries: {
    status: StateStatus | null;
  };
  editCountries: {
    status: StateStatus | null;
  };
  viewCountries: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  countriesList: {
    status: null,
    data: null,
  },
  addCountries: {
    status: null,
  },
  editCountries: {
    status: null,
  },
  viewCountries: {
    status: null,
    data: null,
  },
};

const CountriesReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: {
          ...state.countriesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.countriesList.data
              : action.data,
        },
      };
    case Types.SET_ADD_COUNTRIES:
      return {
        ...state,
        addCountries: {
          ...state.addCountries,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_COUNTRIES:
      return {
        ...state,
        editCountries: {
          ...state.editCountries,
          status: action.status || null,
        },
      };
    case Types.SET_COUNTRIES_DETAIL:
      return {
        ...state,
        viewCountries: {
          ...state.viewCountries,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewCountries.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default CountriesReducer;
