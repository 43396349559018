import { typeCreatorV2 } from '../../utils/common';

export const SET_TRANSPORT: string = typeCreatorV2(
  'TransportReducer',
  'SetTransport'
);

export const EDIT_TRANSPORT: string = typeCreatorV2(
  'TransportReducer',
  'EditTransport'
);
