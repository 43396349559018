import { useSelector } from 'react-redux';
import { getState } from 'redux/store';
import { ReducerKeys } from '../../config';

export const useSidebarIsVisible = (): boolean => {
  return useSelector(
    (state: any) => state[ReducerKeys.NAVIGATION_REDUCER].sideMenuIsOpen
  );
};

export const useNavbarElementIsSelected = (elementID: number): boolean => {
  return useSelector(
    (state: any) =>
      state[ReducerKeys.NAVIGATION_REDUCER].selectedItemID === elementID
  );
};

export const isMobileScreen = (): boolean => {
  return window.innerWidth <= 600;
};

export const sideBarIsVisible = (): boolean => {
  return getState(ReducerKeys.NAVIGATION_REDUCER).sideMenuIsOpen;
};
