import React, { ReactElement } from 'react';
import useStyles from './styles';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';

interface IProps {
  data: any[];
  renderElement: (_data: any, index: number) => ReactElement;
}

export default function DataList(props: IProps): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List>
        {props.data.map((_data, index) => (
          <ListItem key={index}>{props.renderElement(_data, index)}</ListItem>
        ))}
      </List>
    </div>
  );
}
