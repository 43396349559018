import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard, { ISegment } from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

interface IFormData {
  publisher: ISegment | null;
  destination_country: ISegment | null;
  destination_city: ISegment | null;
}

interface IProps {}
const DestinationsForm = React.forwardRef((props: IProps, ref: any) => {
  const campaign = CampaignSelectors.useGetCampaignWizardValues();
  const classes = useStyles();
  const dispatch = useDispatch();
  const segments = CampaignSelectors.useGetSegments();
  const { airline, destination_city, destination_country } = segments;

  const [airlines, setAirlines] = useState<ISegment[] | null>(null);
  const [destCountries, setDestCountries] = useState<ISegment[] | null>(null);
  // const [destCities, setDestCities] = useState<ISegment[] | null>(null);

  React.useEffect(() => {
    setAirlines(campaign.publisher ?? []);
    setDestCountries(campaign.destination_country ?? []);
    // setDestCities(campaign.destination_city ?? []);
  }, [campaign]);

  React.useEffect(() => {
    if (destCountries && airlines) {
      let CSAirlines = airlines.map((e) => e.Code).join(',');
      let CSDestinationCountries = destCountries.map((e) => e.Code).join(',');
      // console.log('countr', CSDestinationCountries)
      dispatch(CampaignActions.setPointsAction(CSDestinationCountries))
      // let CSDestinationCities = destCities.map((e) => e.Code).join(',');

      // dispatch(
      //   CampaignActions.calculateEstimateReachOnBoardAction({
      //     destination_countries: CSDestinationCountries,
      //     destination_airports: CSDestinationCities,
      //     airlines: CSAirlines,
      //   })
      // );

      ref.current = {
        publisher: airlines,
        destination_country: destCountries,
        // destination_city: destCities,
      };
    }
  }, [dispatch, destCountries, airlines]);

  return (
    <CardLayout
      body={
        <form className={classes.form} noValidate>
          <GridLayout
            justify="flex-start"
            elements={[
              {
                id: 'publisher',
                element: (
                  <Autocomplete
                    multiple
                    id="publisher"
                    options={airline}
                    getOptionLabel={(option) => option.Name}
                    filterSelectedOptions
                    limitTags={1}
                    value={airlines ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={
                          <TranslatedText
                            textMap={Translations.destinations_form_publisher}
                          />
                        }
                      />
                    )}
                    onChange={(event: any, value: ISegment[]) =>
                      setAirlines(value)
                    }
                  />
                ),
                size: 12,
              },
              {
                id: 'destination_country',
                element: (
                  <Autocomplete
                    multiple
                    id="destination_country"
                    options={destination_country}
                    getOptionLabel={(option) => option.Name}
                    filterSelectedOptions
                    limitTags={1}
                    value={destCountries ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={
                          <TranslatedText
                            textMap={
                              Translations.destinations_form_destination_country
                            }
                          />
                        }
                      />
                    )}
                    onChange={(event: any, value: ISegment[]) =>
                      setDestCountries(value)
                    }
                  />
                ),
                size: 12,
              },
              // {
              //   id: 'destination_city',
              //   element: (
              //     <Autocomplete
              //       multiple
              //       id="destination_city"
              //       options={destination_city}
              //       getOptionLabel={(option) => option.Name}
              //       filterSelectedOptions
              //       limitTags={1}
              //       value={destCities ?? []}
              //       renderInput={(params) => (
              //         <TextField
              //           {...params}
              //           variant="outlined"
              //           label={
              //             <TranslatedText
              //               textMap={
              //                 Translations.destinations_form_destination_city
              //               }
              //             />
              //           }
              //         />
              //       )}
              //       onChange={(event: any, value: ISegment[]) =>
              //         setDestCities(value)
              //       }
              //     />
              //   ),
              //   size: 12,
              // },
            ]}
          />
        </form>
      }
    />
  );
});

export default DestinationsForm;
