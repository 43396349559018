import { Box, Typography } from '@material-ui/core';
import L from 'leaflet';
import _, { isArray } from 'lodash';
import React, { ReactElement } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as PubDashboardActions from 'redux/reducers/pub_dashboard_reducer/actions';
import * as PubDashboardSelectors from 'redux/reducers/pub_dashboard_reducer/selectors';
import clsx from 'clsx';
import { renderToStaticMarkup } from 'react-dom/server';
import useStyles from './styles';
import { useDispatch } from 'react-redux';

export const center: L.LatLngExpression = [34.999711, 33.786028];

interface IMapInstanceProps {
  children: ReactElement;
  setMapBounds?: any;
}
const MapInstance = ({ children, setMapBounds }: IMapInstanceProps) => {
  return <React.Fragment>{children}</React.Fragment>;
};

interface IProps {
  height?: number | string;
  setMapBounds?: any;
}
const BusMap = React.forwardRef(({ setMapBounds }: IProps, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transportBussesList = PubDashboardSelectors.useGetBusList();

  React.useEffect(() => {
    dispatch(
      PubDashboardActions.default.fetchBusListAction({ type: 'BUS', status: 1 })
    );
  }, [dispatch]);

  return (
    <Box className={classes.map}>
      <MapContainer
        zoom={11}
        minZoom={10}
        scrollWheelZoom
        style={{ height: '100%' }}
        center={center}
        doubleClickZoom={false}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapInstance setMapBounds={setMapBounds}>
          <React.Fragment>
            {/** Show markers/labels of transport stations on the map */}
            {transportBussesList &&
              isArray(transportBussesList) &&
              transportBussesList.map(
                (bus) =>
                  bus.lat &&
                  bus.lon && (
                    <Marker
                      position={[bus.lat, bus.lon]}
                      icon={L.divIcon({
                        className: clsx(classes.busPin),
                        html: renderToStaticMarkup(
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 321.883 321.883"
                          >
                            <path
                              fill={'blue'}
                              d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882
                          C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801
                          c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
                            />
                          </svg>
                        ),
                        iconSize: [33, 33],
                        iconAnchor: [20, 30],
                      })}
                      key={bus.id}
                    >
                      <Tooltip direction="top" offset={[1, -40]}>
                        <Box>
                          <Typography component="span">{bus.code}</Typography>
                        </Box>
                        <Box>
                          <Typography component="span">{bus.ip}</Typography>
                        </Box>
                      </Tooltip>
                    </Marker>
                  )
              )}
            {/** Zoom controls position on map */}
            <ZoomControl position="bottomleft" />
          </React.Fragment>
        </MapInstance>
      </MapContainer>
    </Box>
  );
});

export default BusMap;
