import useStyles from './styles';
import React, { useState } from 'react';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import TransportStationsList from './TransportStationsList';
import GridLayout from 'components/molecules/grid_layout';
import TransportStationsMap from './TransportStationsMap';
import { Box, useMediaQuery } from '@material-ui/core';
import { ISegment } from 'config/campaign_wizard';
import L from 'leaflet';
import TransportLinesForm from './TransportLinesForm';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
}

interface IMapBounds {
  tlc?: L.LatLng;
  trc?: L.LatLng;
  blc?: L.LatLng;
  brc?: L.LatLng;
}

const TransportTabView = React.forwardRef((props: IProps, ref) => {
  reactLogger.renderComponent('TransportTabView');
  const campaign = CampaignSelectors.useGetCampaignWizardValues();
  console.log(campaign);
  const { destination_transport } = CampaignSelectors.useGetSegments();
  const { view, handleNext, handlePrevious } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileUI = useMediaQuery('(max-width:959px)');
  const [transportStationsSelected, setTransportStationsSelected] = useState<
    ISegment[] | null
  >(null);
  const [mapBounds, setMapBounds] = useState<IMapBounds>({});

  React.useEffect(() => {
    setTransportStationsSelected(campaign.destination_transport ?? []);
    console.log(transportStationsSelected);
  }, [campaign]);

  // React.useEffect(() => {
  //   dispatch(CampaignActions.fetchTransportStationsAction());
  // }, [dispatch]);

  React.useEffect(() => {
    if (Object.keys(mapBounds).length != 0) {
      // console.log('Mapbounds: ' + JSON.stringify(mapBounds));
      // console.log('Fetching Stations...');
      dispatch(CampaignActions.fetchTransportStationsAction(mapBounds));
    }
  }, [dispatch, mapBounds]);

  // const data = CampaignSelectors.useGetTransportStations();
  // console.log('Transport Stations:', data);

  const handleCheck = (code: any) => {
    if (transportStationsSelected) {
      return (
        transportStationsSelected.filter(
          (selectedCode: ISegment) => selectedCode.Code === `TRANSP_${code}`
        ).length > 0
      );
    }
    return false;
  };

  const handleSelect = (code: any) => {
    // console.log('Transport handleSelect: ', handleCheck(`${code}`));
    if (handleCheck(`${code}`)) {
      setTransportStationsSelected((state: any) =>
        state.filter(
          (selectedStationSeg: ISegment) =>
            selectedStationSeg.Code !== `TRANSP_${code}`
        )
      );
    } else {
      const selectedStationSeg: ISegment = destination_transport.filter(
        (stationSeg: ISegment) => stationSeg.Code === `TRANSP_${code}`
      )[0];
      if (!selectedStationSeg) {
        console.error('No segment found for code: ' + `TRANSP_${code}`);
      }
      setTransportStationsSelected((state: any) => [
        ...state,
        selectedStationSeg,
      ]);
    }
  };

  const handleMultipleAdd = (stations: any) => {
    var stationSegments: ISegment[] = new Array();
    stations.map((station: any) => {
      stationSegments.push(
        destination_transport.filter(
          (stationSeg: ISegment) => stationSeg.Code === `TRANSP_${station.Code}`
        )[0]
      );
    });
    var selected: ISegment[] = new Array();
    if (transportStationsSelected) {
      selected = [
        ...selected,
        ...stationSegments.filter(
          (station) => !transportStationsSelected.includes(station)
        ),
      ];
      setTransportStationsSelected((state: any) => [...state, ...selected]);
    }
  };

  const handleMultipleRemove = (stations: any) => {
    var stationSegments: ISegment[] = new Array();
    stations.map((station: any) => {
      stationSegments.push(
        destination_transport.filter(
          (stationSeg: ISegment) => stationSeg.Code === `TRANSP_${station.Code}`
        )[0]
      );
    });
    var selected: ISegment[] = new Array();
    if (transportStationsSelected) {
      selected = [
        ...selected,
        ...transportStationsSelected.filter(
          (station) => !stationSegments.includes(station)
        ),
      ];
      setTransportStationsSelected(selected);
    }
  };

  return (
    <React.Fragment>
      <TabActions
        view={view}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
      <Box
        className={classes.transport}
        style={{ flexDirection: mobileUI ? 'column' : 'row' }}
      >
        <Box className={classes.transportList}>
          <TransportStationsList
            ref={ref}
            selected={transportStationsSelected}
            setSelected={setTransportStationsSelected}
            handleCheck={handleCheck}
            handleSelect={handleSelect}
          />
        </Box>
        <Box className={classes.map}>
          <TransportLinesForm
            handleMultipleAdd={handleMultipleAdd}
            handleMultipleRemove={handleMultipleRemove}
          />
          <TransportStationsMap
            ref={ref}
            setSelected={setTransportStationsSelected}
            handleCheck={handleCheck}
            handleSelect={handleSelect}
            setMapBounds={setMapBounds}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
});

export default TransportTabView;
