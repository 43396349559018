import _ from 'lodash';
import { showToastAction } from '../../components/atoms/toast_message';
import store from '../../redux/store';
import axios from './axiosRefreshTokenService';

const request = async (
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  url: string,
  body: any = null,
  headers: any = null
) => {
  const dispatch = store.dispatch;
  try {
    let object = {
      method: method,
      url: url,
      data: body,
      headers: headers || {},
    };

    const result: any = await axios(object);
    // if (result.data.message) {
    //   showToastAction(dispatch as any, result.data.message, 'success');
    // }
    return result;
  } catch (error) {
    if (error.message) {
      showToastAction(
        dispatch as any,
        _.get(error, 'response.data.message', error.message),
        'error'
      );
    }
    throw new Error(_.get(error, 'response.data.message', error.message));
  }
};

export default request;
