import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import {
  AppSelectSearch,
  AppSwitch,
  AppTextField,
} from 'components/molecules/form_controls';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import User_Price_CalculationActions from 'redux/reducers/user_price_calculation_reducer/actions';
import * as User_Price_CalculationSelectors from 'redux/reducers/user_price_calculation_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import { TableDefaultFetchValues, Translations } from '../../configuration';
import useStyles from './styles';
import { useGetAdvertisementsTypeList } from 'redux/reducers/advertisementstype_reducer/selectors';
import { useGetAirlinesList } from 'redux/reducers/airlines_reducer/selectors';
import { useGetAirportsList } from 'redux/reducers/airports_reducer/selectors';
import { useParams } from 'react-router';
import _ from 'lodash';

export default function User_Price_CalculationModal() {
  const dispatch = useDispatch();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION}
      renderHeader={({ type, id }: IModalProps) => (
        <User_Price_CalculationHeader id={id} type={type} />
      )}
      renderContent={({ type, id }: IModalProps) => (
        <React.Fragment>
          {console.log('Content:')}
          <User_Price_CalculationForm id={id} type={type} />
        </React.Fragment>
      )}
    />
  );
}

interface IModalProps {
  type: 'add' | 'edit' | 'view';
  id: string | number;
}

const User_Price_CalculationHeader = ({ type, id }: IModalProps) => {
  console.log('Header::', type);
  return (
    <TranslatedText
      // textMap={
      //   type === 'add'
      //     ? Translations.User_Price_CalculationModalAddTitle
      //     : type === 'edit'
      //     ? Translations.User_Price_CalculationModalEditTitle
      //     : Translations.User_Price_CalculationModalViewTitle
      // }
      defaultText={`${
        type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'View'
      } advertisement calculation`}
    />
  );
};

interface FormTypes {
  flat: boolean;
  airline: any;
  country: any;
  airport: any;
  cpm: number | null;
  cpc: number | null;
  cpa: number | null;
  advertisment_type: any;
  userid: string | null;
}

const defaultValues = {
  flat: false,
  airline: null,
  country: null,
  airport: null,
  cpm: null,
  cpc: null,
  cpa: null,
  advertisment_type: null,
  userid: null,
};

const User_Price_CalculationForm = React.memo(({ type, id }: IModalProps) => {
  console.log('Render User_Price_CalculationForm:');
  const params = useParams();
  const userid = _.get(params, 'id', null);
  const {
    status,
    data,
  } = User_Price_CalculationSelectors.useGetUser_Price_CalculationDetails();
  const { data: dataAdvertisementsTypeList } = useGetAdvertisementsTypeList();
  const dataAirportList = useGetAirportsList();
  const { data: airlinesList } = useGetAirlinesList();
  const addUser_Price_CalculationStatus = User_Price_CalculationSelectors.useAddUser_Price_CalculationStatus();
  const editUser_Price_CalculationStatus = User_Price_CalculationSelectors.useEditUser_Price_CalculationStatus();
  const classes = useStyles();
  const { reset, watch, errors, control, handleSubmit } = useForm<FormTypes>({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (_data: FormTypes) => {
    switch (type) {
      case 'add':
        dispatch(
          User_Price_CalculationActions.addNewUser_Price_CalculationAction({
            flat: _data.flat || false,
            airline: _data.airline?.id || '',
            // country: _data.country || '',
            airport: _data.airport?.id || '',
            cpm: parseInt(`${_data.cpm}`) || 0,
            cpc: parseInt(`${_data.cpc}`) || 0,
            cpa: parseInt(`${_data.cpa}`) || 0,
            advertisment_type: parseInt(`${_data.advertisment_type?.id}`) || 0,
            user_id: userid || '',
            fetchUser_Price_CalculationInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
              User_id: parseInt(userid),
            },
          })
        );
        break;
      case 'edit':
        dispatch(
          User_Price_CalculationActions.editUser_Price_CalculationAction({
            id: `${data?.id}`,
            flat: _data.flat || false,
            airline: _data.airline?.id || '',
            country: _data.country || '',
            airport: _data.airport?.id || '',
            cpm: parseInt(`${_data.cpm}`) || 0,
            cpc: parseInt(`${_data.cpc}`) || 0,
            cpa: parseInt(`${_data.cpa}`) || 0,
            advertisment_type: parseInt(`${_data.advertisment_type?.id}`) || 0,
            user_id: userid || '',
            fetchUser_Price_CalculationInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
              User_id: parseInt(userid),
            },
          })
        );
        break;
    }
  };

  React.useEffect(() => {
    if (['edit', 'view'].includes(type)) {
      dispatch(
        User_Price_CalculationActions.viewUser_Price_CalculationAction({
          id: typeof id === 'string' ? parseInt(id) : id,
        })
      );
    }
  }, [dispatch, id, type]);

  React.useEffect(() => {
    if (status === StateStatus.Success && type !== 'add') {
      reset({
        flat: data?.flat ?? false,
        cpm: data?.CPM || null,
        cpc: data?.CPC || null,
        cpa: data?.CPA || null,
        country: data?.Country || null,
        advertisment_type: data?.Advertisment_type
          ? {
              id: data?.Advertisment_type,
              label: dataAdvertisementsTypeList?.items?.find(
                (val: any) => val?.ID === data?.Advertisment_type
              )?.Name,
            }
          : null,
        airline: data?.Airline
          ? {
              id: data?.Airline,
              label: airlinesList?.items?.find(
                (val: any) => val?.IataId === data?.Airline
              )?.Name,
            }
          : null,
        airport: data?.Airport
          ? {
              id: data?.Airport,
              label: dataAirportList?.data?.find(
                (val: any) => val?.code === data?.Airport
              )?.name,
            }
          : null,
      });
    }
  }, [status, dispatch, type]);
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppSwitch
        rules={{}}
        size="medium"
        label={<TranslatedText defaultText={'Flat'} />}
        id="flat"
        control={control}
        name="flat"
        defaultValue={0}
      />
      <AppSelectSearch
        disabled={Boolean(type === 'view')}
        required
        name="airline"
        control={control}
        title={<TranslatedText defaultText={'Airline'} />}
        helperText={errors.airline && errors.airline.message}
        rules={{}}
        options={
          airlinesList?.items?.map((item) => ({
            id: item?.IataId,
            label: item?.Name,
          })) ?? []
        }
        error={'airline' in errors}
        style={{
          marginTop: '0',
        }}
      />
      <AppSelectSearch
        disabled={Boolean(type === 'view')}
        required
        name="airport"
        control={control}
        title={<TranslatedText defaultText={'Airport'} />}
        helperText={errors.airport && errors.airport.message}
        rules={{}}
        options={
          dataAirportList?.data?.map((item: any) => ({
            id: item?.code,
            label: item?.name,
          })) ?? []
        }
        error={'airport' in errors}
        style={{
          marginTop: '0',
        }}
      />
      <AppSelectSearch
        disabled={Boolean(type === 'view')}
        required
        name="advertisment_type"
        control={control}
        title={<TranslatedText defaultText={'Advertisment type'} />}
        helperText={
          errors.advertisment_type && errors.advertisment_type.message
        }
        rules={{}}
        options={
          dataAdvertisementsTypeList?.items?.map((item) => ({
            id: item?.ID,
            label: item?.Name,
          })) ?? []
        }
        error={'advertisment_type' in errors}
        style={{
          marginTop: '0',
        }}
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="cpm"
        label={<TranslatedText defaultText={'CPM'} />}
        error={'cpm' in errors}
        helperText={errors.cpm && errors.cpm.message}
        rules={{ required: 'Required Field' }}
        name="cpm"
        autoComplete="cpm"
        control={control}
        type="number"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="cpc"
        label={<TranslatedText defaultText={'CPC'} />}
        error={'cpc' in errors}
        helperText={errors.cpc && errors.cpc.message}
        rules={{ required: 'Required Field' }}
        name="cpc"
        autoComplete="cpc"
        control={control}
        type="number"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="cpa"
        label={<TranslatedText defaultText={'CPA'} />}
        error={'cpa' in errors}
        helperText={errors.cpa && errors.cpa.message}
        rules={{ required: 'Required Field' }}
        name="cpa"
        autoComplete="cpa"
        control={control}
        type="number"
      />

      {type !== 'view' && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <SubmitWrapper
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              addUser_Price_CalculationStatus === StateStatus.Pending ||
              editUser_Price_CalculationStatus === StateStatus.Pending
            }
          >
            {addUser_Price_CalculationStatus === StateStatus.Pending ||
            editUser_Price_CalculationStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              <TranslatedText
                textMap={Translations.addEditModalButton}
                defaultText={'Submit'}
              />
            )}
          </SubmitWrapper>
        </Box>
      )}
    </form>
  );
});

const SubmitWrapper = (props: ButtonProps) => {
  return <Button {...props} />;
};
