import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as AdvDashboardService from '../../../services/api/adv_dashboard';
import * as UserService from '../../../services/api/user';

class FetchCampaignsStats implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getCampaignStats(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchCountryClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRY_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRY_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRY_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getCountryClickView(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchCountryClickView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTotalImpressionClickByCampaign implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOTAL_IMPRESSION_CLICK_BY_CAMPAIGN,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOTAL_IMPRESSION_CLICK_BY_CAMPAIGN,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOTAL_IMPRESSION_CLICK_BY_CAMPAIGN,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTotolImpressionClickByCampaign(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'FetchTotalImpressionClickByCampaign Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTop5Campaign implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_5_CAMPAIGN,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_5_CAMPAIGN,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_5_CAMPAIGN,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTop5Campaign(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchTop5Campaign Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTotalViewByArrCountry implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOTAL_VIEW_BY_ARR_COUNTRY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOTAL_VIEW_BY_ARR_COUNTRY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOTAL_VIEW_BY_ARR_COUNTRY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTotalViewByArrCountry(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTotalViewByArrCountry Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTotalClickByArrCountry implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOTAL_CLICK_BY_ARR_COUNTRY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOTAL_CLICK_BY_ARR_COUNTRY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOTAL_CLICK_BY_ARR_COUNTRY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTotalClickByArrCountry(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTotalClickByArrCountry Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchDeviceClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DEVICE_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DEVICE_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DEVICE_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getDeviceClickView(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getDeviceClickView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTotalCountryView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTotalCountryView(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTotalCountryView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTotalCountryClick implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_CLICK,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_CLICK,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOTAL_COUNTRY_CLICK,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTotalCountryClick(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTotalCountryClick Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchUserList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchCampaignsStatsByEnv implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_STATS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getCampaignStatsByEnv(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchStationClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_STATION_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_STATION_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_STATION_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getStationClickView(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTransportsPassengerStatsDaily implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRANSPORTS_PASSENGER_STATS_DAILY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRANSPORTS_PASSENGER_STATS_DAILY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRANSPORTS_PASSENGER_STATS_DAILY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTransportsPassengerStatsDaily(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTop10PassengerStatsByStation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_STATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_STATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_STATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTop10PassengerStatsByStation(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTop10PassengerStatsByTransport implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_TRANSPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_TRANSPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_10_PASSENGER_STATS_BY_TRANSPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvDashboardService.getTop10PassengerStatsByTransport(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCampaignsStats Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchCampaignsStatsAction: (payload: any) =>
    new FetchCampaignsStats().action(payload),
  fetchCountryClickViewAction: (payload: any) =>
    new FetchCountryClickView().action(payload),
  fetchTotalImpressionClickByCampaignAction: (payload: any) =>
    new FetchTotalImpressionClickByCampaign().action(payload),
  fetchTop5CampaignAction: (payload: any) =>
    new FetchTop5Campaign().action(payload),
  fetchTotalViewByArrCountryAction: (payload: any) =>
    new FetchTotalViewByArrCountry().action(payload),
  fetchTotalClickByArrCountryAction: (payload: any) =>
    new FetchTotalClickByArrCountry().action(payload),
  fetchDeviceClickViewAction: (payload: any) =>
    new FetchDeviceClickView().action(payload),
  fetchTotalCountryViewAction: (payload: any) =>
    new FetchTotalCountryView().action(payload),
  fetchTotalCountryClickAction: (payload: any) =>
    new FetchTotalCountryClick().action(payload),
  fetchUserListAction: (payload: any) => new FetchUserList().action(payload),
  fetchCampaignsStatsByEnvAction: (payload: any) =>
    new FetchCampaignsStatsByEnv().action(payload),
  fetchStationClickViewAction: (payload: any) =>
    new FetchStationClickView().action(payload),
  fetchTransportsPassengerStatsDailyAction: (payload: any) =>
    new FetchTransportsPassengerStatsDaily().action(payload),
  fetchTop10PassengerStatsByStationAction: (payload: any) =>
    new FetchTop10PassengerStatsByStation().action(payload),
  fetchTop10PassengerStatsByTransportAction: (payload: any) =>
    new FetchTop10PassengerStatsByTransport().action(payload),
};
