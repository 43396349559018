import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    marginTop: '1rem',
    height: '600px',
    width: '100%',
    position: 'relative',
  },
  destinationsContainer: {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    zIndex: 1,
    width: '20%',
  },
  destinationCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  destinationCardContentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '.5rem',
    fontSize: '1rem',
  },
}));

export default useStyles;
