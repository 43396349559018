import React from 'react';
import TranslatedText from 'components/atoms/translated_text';
import * as DateUtils from 'utils/dates';
import Translations from '../../translations';

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.destinations_table_code} />,
    id: 'Code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: (
      <TranslatedText textMap={Translations.destinations_table_flight_date} />
    ),
    id: 'FlightDate',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{DateUtils.formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.destinations_table_airline} />,
    id: 'Airline',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: (
      <TranslatedText
        textMap={Translations.destinations_table_departure_airport}
      />
    ),
    id: 'DeptAirport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: (
      <TranslatedText
        textMap={Translations.destinations_table_arrival_airport}
      />
    ),
    id: 'ArrAirport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.destinations_table_country} />,
    id: 'country',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: (
      <TranslatedText
        textMap={Translations.destinations_table_estimated_passengers}
      />
    ),
    id: 'EstPassengers',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];
