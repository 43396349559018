import { Environment } from 'config';
import request from 'utils/request';
import { IPaginationInput, ResponseWithPaginationOutput } from '../types';

export interface IGetParalistByGroupCodeInput extends IPaginationInput {
  groupcode: string;
}

export interface IGetParalistByGroupCodeOutput
  extends ResponseWithPaginationOutput<
    {
      groupcode: string;
      code: number;
      descriptionn: string;
      descriptionf: string;
      utimestamp: string | null;
      deleted: number;
    }[]
  > {}

export const getParalistByGroupCode = async ({
  groupcode,
  page,
  pageSize,
  sortField,
  order,
}: IGetParalistByGroupCodeInput): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/paralists/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}&groupcode=${groupcode}`
  );

  let output: IGetParalistByGroupCodeOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
