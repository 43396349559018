import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetWallet = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.WALLET_REDUCER].wallet
  );
  return data;
};
export const useGetCampaign = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.WALLET_REDUCER].campaign
  );
  return data;
};

export const useGetUserList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.WALLET_REDUCER].userList
  );
  return data;
};
