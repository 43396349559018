import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Actions from '../../../redux/reducers/application_detail_reducer/actions';
import ModalActions from '../../../redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from '../../../redux/reducers/gl_modal_reducer/types';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

import {
  useGetLocation,
  useGetDialog,
} from '../../../redux/reducers/application_detail_reducer/selectors';

import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';

interface IFormData {
  key: string;
  locationid: string;
  javascript: string;
}
const defaultValues = {
  key: '',
  locationid: '',
  javascript: '',
};
export default function ResponsiveDialog() {
  const dispatch = useDispatch();
  const { isOpen, type, data } = useGetDialog();
  const locations = useGetLocation();
  const { id } = useSelectUserData();
  const [locationOption, setLocationOption] = useState([]);
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    errors,
    control,
    getValues,
    reset,
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    if (type === 'edit') {
      console.log('d', data);
      reset({
        key: data.TypeUniqueKey,
        locationid: data.locationid,
        javascript: data.javascript,
      });
    }
  }, [type, data]);

  useEffect(() => {
    if (locations.status === 'Success') {
      setLocationOption(
        locations.data.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [locations]);
  const onSubmit = (_data: any) => {
    dispatch(
      Actions.savePublisherApplicationType({
        applicationid: parseInt(data.ApplId),
        applicationtypeid: parseInt(data.AdvId),
        locationid: parseInt(_data.locationid),
        userid: id,
      })
    );
    // dispatch(CampaignActions.scrapWebsiteAction(data.landing_page));
  };
  function handleDelete() {
    dispatch(
      Actions.deletePublisherApplicationTypeActions({
        typeKey: data.TypeUniqueKey,
        applicationid: parseInt(data.ApplId),
        userid: id,
      })
    );
  }
  const handleClose = () => {
    dispatch(
      Actions.setDialog({
        isOpen: false,
        type: 'add',
        data: null,
      })
    );
  };
  function copyToClipboard() {
    navigator.clipboard.writeText(getValues('javascript'));
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.TOAST,
        params: {
          message: 'Copied Success',
          type: 'success',
        },
      })
    );
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {data ? data.AdvName : ''}
        </DialogTitle>
        <DialogContent>
          <form
            // className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            {data && data.TypeUniqueKey && (
              <AppTextField
                variant="outlined"
                margin="normal"
                error={'key' in errors}
                disabled
                helperText={errors.key && errors.key.message}
                rules={{ require: true }}
                fullWidth
                id="key"
                control={control}
                label={<TranslatedText textMap={Translations.location_label} />}
                autoComplete="key"
                name="key"
              />
            )}
            <AppSelect
              variant="outlined"
              margin="normal"
              rules={{}}
              options={locationOption}
              fullWidth
              id="locationid"
              control={control}
              label={<TranslatedText textMap={Translations.location_label} />}
              autoComplete="locationid"
              name="locationid"
            />
            {data && data.TypeUniqueKey && (
              <>
                <AppTextField
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  error={'javascript' in errors}
                  helperText={errors.javascript && errors.javascript.message}
                  rules={{ require: true }}
                  fullWidth
                  id="javascript"
                  control={control}
                  label="Javascript SDK"
                  autoComplete="javascript"
                  name="javascript"
                />
                <div
                  className={clsx(
                    classes.flex,
                    classes['w-full'],
                    classes.mt12
                  )}
                >
                  <Button
                    onClick={copyToClipboard}
                    variant="contained"
                    color="primary"
                  >
                    Copy JavaScript
                  </Button>
                </div>
              </>
            )}
            <div
              className={clsx(
                classes.flex,
                classes['w-full'],
                classes.mt12,
                classes.mb24,
                classes.justifyBetween
              )}
            >
              <Button variant="contained" onClick={handleClose} color="primary">
                <TranslatedText textMap={Translations.cancel_button} />
              </Button>
              <div className={clsx(classes.flex)}>
                {data && data.TypeUniqueKey && (
                  <Button
                    variant="contained"
                    onClick={handleDelete}
                    color="primary"
                    className={classes['mr-12']}
                  >
                    <TranslatedText textMap={Translations.delete_button} />
                  </Button>
                )}
                <Button variant="contained" type="submit" color="primary">
                  <TranslatedText textMap={Translations.save_button} />
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
