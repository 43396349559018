import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux/reducers/application_reducer/actions';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { TableColumns, TableDefaultFetchValues } from './configuration';
import Table from './components/datatable';
import AddApplication from './components/AddApplication';
import SearchArea from './components/SearchArea';

export default function ApplicationPAge() {
  const { id } = useSelectUserData();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      Actions.fetchPublisherApplicationActions({
        id,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
      })
    );
  }, []);
  return (
    <>
      <AddApplication />
      <SearchArea />
      <Table />
    </>
  );
}
