import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as AirportsService from '../../../services/api/airports';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchAirportsListInput  */

interface FetchAirportsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchAirportsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AIRPORTS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AIRPORTS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AIRPORTS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchAirportsListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AirportsService.fetchAirportsList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcAirportsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewAirports  */

interface AddNewAirportsInput {
  name: string | null;
  town: string | null;
  country: string | null;
  countrycode: string | null;
  code: string | null;
  icao: string | null;
  lat: number | null;
  lon: number | null;
  timezone: string | null;
  fetchAirportsInput: FetchAirportsListInput;
}

class AddNewAirports implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_AIRPORTS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_AIRPORTS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_AIRPORTS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewAirportsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAirportsInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await AirportsService.addAirports(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchAirportsList().action(fetchAirportsInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_AIRPORTS,
        //   })
        // );
      } catch (error) {
        console.log('AddNewAirports Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Airports  */

interface EditAirportsInput {
  name: string | null;
  town: string | null;
  country: string | null;
  countrycode: string | null;
  code: string | null;
  icao: string | null;
  lat: number | null;
  lon: number | null;
  timezone: string | null;
  fetchAirportsInput: FetchAirportsListInput;
}

class EditAirports implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_AIRPORTS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_AIRPORTS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_AIRPORTS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditAirportsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAirportsInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await AirportsService.editAirports(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchAirportsList().action(fetchAirportsInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_AIRPORTS,
        //   })
        // );
      } catch (error) {
        console.log('EditNotification Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Airports  */

interface ViewAirportsInput {
  id: number;
}

class ViewAirports implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AIRPORTS_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AIRPORTS_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AIRPORTS_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewAirportsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AirportsService.fetchAirportsDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewAirports Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchAirportsListAction: (payload: FetchAirportsListInput) =>
    new FetchAirportsList().action(payload),
  addNewAirportsAction: (payload: AddNewAirportsInput) =>
    new AddNewAirports().action(payload),
  editAirportsAction: (payload: EditAirportsInput) =>
    new EditAirports().action(payload),
  viewAirportsAction: (payload: ViewAirportsInput) =>
    new ViewAirports().action(payload),
};
