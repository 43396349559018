import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { IState, Publisher } from './interfaces';

export const useGetPublisherList = () =>
  <IState>(
    useShallowEqualSelector(
      (state: any) => state[ReducerKeys.PUBLISHER_REDUCER]
    )
  );

export const useListSlaveFromMaster = (): IState['listSlaveFromMaster'] => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUBLISHER_REDUCER].listSlaveFromMaster
  );
  return data;
};

export const useDeletePublisherConnectionStatus = (): IState['deletePublisherConnection']['status'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHER_REDUCER].deletePublisherConnection
  );
  return data;
};

export const useGetAllPublisher = (): IState['getAllPublisher'] => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUBLISHER_REDUCER].getAllPublisher
  );
  return data;
};

export const useAddPublisherConnectionStatus = (): IState['addPublisherConnection']['status'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHER_REDUCER].addPublisherConnection.status
  );
  return data;
};

export const useDetailPublisher = (): IState['detailPublisher'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUBLISHER_REDUCER].detailPublisher
  );
  return data;
};
