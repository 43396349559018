//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_MODAL_VISIBILITY: string = typeCreatorV2(
  'ModalReducer',
  'SetModalVisibility'
);

export enum ModalIDs {
  TOAST = 1,
  MODAL_DASHBOARD_ADD_GRAPH = 2,
  MODAL_FIXED_ESTABLISHMENT = 3,
  MODAL_IDENTIFICATION_IN_OTHER_EU_COUNTRIES = 4,
  MODAL_PREVIOUS_USE_OF_SPECIAL_SCHEMES = 5,
  MODAL_DASHBOARD_ELEMENT_CONFIG = 6, // dashboardbuilder_page
  MODAL_DASHBOARD_ELEMENT_REMOVE = 7, // dashboardbuilder_page
  MODAL_DASHBOARD_BUILDER_SETTINGS = 8, // dashboardbuilder_page
  MODAL_ADD_EDIT_USER = 9,
  MODAL_CONFIG_WRAPPER = 10,
  MODAL_FORM_WRAPPER = 11, // forms_page
  MODAL_CREATE_CAMPAIGN_CONFIRMATION = 12,
  MODAL_UPDATE_CAMPAIGN_CONFIRMATION = 13,
  MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER = 14,

  // DSP pages
  MODAL_PUBLISHER_CONNECTION = 15,
  MODAL_ADD_EDIT_DSP = 16,
  MODAL_ADVERTISEMENT_TYPE = 17,

  // Advertiser
  MODAL_ADVERTISER_CONNECTION = 18,
  MODAL_ADVERTISER_CALCULATOR = 19,
  MODAL_ADD_EDIT_USER_PRICE_CALCULATION = 19.1,

  // Advertiser Type
  MODAL_ADD_EDIT_ADVERTISEMENT_TYPE = 20,

  // Country rate
  MODAL_ADD_EDIT_COUNTRIESRATE = 21,

  // Add_Edit_Advertiser_admin
  MODAL_ADD_EDIT_USERS_ADVERTISERS_ADMINISTRATION = 21,
}
