import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface AirportsListElement {
  name: string;
  town: string;
  country: string;
  countrycode: string;
  code: string;
  icao: string;
  lat: string;
  lon: string;
  timezone: string;
}

interface AirportsistOutput {
  status: StateStatus;
  data: AirportsListElement[] | null;
}

export const useGetAirportsList = (): AirportsistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AIRPORTS_REDUCER].airportsList
  );
  return data;
};

/* selector: useGetAirportsDetails */
interface AirportsDetailsData {
  name: string;
  town: string;
  country: string;
  countrycode: string;
  code: string;
  icao: string;
  lat: string;
  lon: string;
  timezone: string;
}

interface AirportsDetailsOutput {
  status: StateStatus;
  data: AirportsDetailsData | null;
}

export const useGetAirportsDetails = (): AirportsDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AIRPORTS_REDUCER].viewAirports
  );
  return data;
};
