import { Button, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import { StateStatus } from 'redux/utils/common';
import Swal from 'sweetalert2';

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const handleEdit = (e: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_DSP,
        params: { data: props?.data },
      })
    );
  };
  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          DSPActions.deleteDSPAction({
            code: props?.data?.code,
            fetchDSPInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
      }
    });
  };
  const openConnectionPushlisher = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_PUBLISHER_CONNECTION,
        params: { data: props.data },
      })
    );
  };
  const openAdvertisementTypeModal = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADVERTISEMENT_TYPE,
        params: { data: props.data },
      })
    );
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleEdit}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
        }}
      >
        Edit
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginLeft: 10,
        }}
        onClick={openConnectionPushlisher}
      >
        Publishers
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginLeft: 10,
        }}
        onClick={openAdvertisementTypeModal}
      >
        Ad Types
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginLeft: 10,
          backgroundColor: 'red',
          color: '#fff',
        }}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: 'Code',
    id: 'code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Name',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'User Id',
    id: 'userid',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <React.Fragment>{data[rowIndex]?.useridName}</React.Fragment>,
    inData: true,
  },

  {
    label: 'Vast type',
    id: 'vasttype',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <React.Fragment>{data[rowIndex]?.vasttypeName}</React.Fragment>,
    inData: true,
  },

  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data[rowIndex]}
        dataid={data[rowIndex].id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'asc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export interface IFormData {
  userid: string | null;
  vasttype: number | null;
  name: string | null;
  code: string | null;
}

export const formDefaultValue: IFormData = {
  userid: null,
  vasttype: null,
  name: null,
  code: null,
};
