import React, { useState } from 'react';
import { Card, CardContent, Button, TextField } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import GridLayout from 'components/molecules/grid_layout';

export default function SearchArea(props: any) {
  const { setPublisher, setCode } = props;
  const [searchPublisher, setSearchPublisher] = useState('');
  const [searchCode, setSearchCode] = useState('');

  function handleChangePublisher(event: React.ChangeEvent<{ value: unknown }>) {
    const value: string = event.target.value as string;
    setSearchPublisher(value);
  }

  function handleChangeCode(event: React.ChangeEvent<{ value: unknown }>) {
    const value: string = event.target.value as string;
    setSearchCode(value);
  }

  function handleSearch() {
    if (searchPublisher !== '') {
      setPublisher(searchPublisher);
    } else {
      setPublisher('');
    }
    if (searchCode !== '') {
      setCode(searchCode);
    } else {
      setCode('');
    }
  }

  return (
    <Card style={{ marginBottom: '25px' }}>
      <CardContent
        style={{
          marginTop: '10px 0',
          display: 'flex',
          // justifyContent: 'space-between',
        }}
      >
        <GridLayout
          style={{ justifyContent: 'start' }}
          elements={[
            {
              id: 'publisher',
              size: 3,
              element: (
                <TextField
                  style={{ width: '100%' }}
                  variant="outlined"
                  label={
                    <TranslatedText textMap={Translations.search_publisher} />
                  }
                  value={searchPublisher}
                  onChange={handleChangePublisher}
                />
              ),
            },
            {
              id: 'code',
              size: 3,
              element: (
                <TextField
                  style={{ width: '100%' }}
                  variant="outlined"
                  label={<TranslatedText textMap={Translations.search_code} />}
                  value={searchCode}
                  onChange={handleChangeCode}
                />
              ),
            },
            {
              id: 'search',
              size: 3,
              element: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    style={{ marginLeft: 10, height: 55 }}
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    <TranslatedText textMap={Translations.search_button} />
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
