import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetAllUsersWithRoles = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADMINISTRATOR_REDUCER].allUserWithRoles
  );
  return data;
};

export const useGetPersistAllUsersWithRoles = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADMINISTRATOR_REDUCER].persistAllUserWithRoles
  );
  return data;
};
