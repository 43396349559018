import { typeCreator, typeCreatorV2 } from '../../../redux/utils/common';

export const ReducerName = 'PublisherReducer';

export const GET_PUBLISHERS = typeCreator(ReducerName, 'getAll');
export const GET_PUBLISHER = typeCreator(ReducerName, 'getOne');
export const CREATE_PUBLISHER = typeCreator(ReducerName, 'create');
export const DELETE_PUBLISHER = typeCreator(ReducerName, 'delete');
export const UPDATE_PUBLISHER = typeCreator(ReducerName, 'update');
export const GET_LIST_SLAVE_FROM_MASTER: string = typeCreatorV2(
  ReducerName,
  'GetListSlaveFromMaster'
);
export const DELETE_PUBLISHER_CONNECTION: string = typeCreatorV2(
  ReducerName,
  'DeletePublisherConnection'
);
export const GET_ALL_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'GetAllPublisher'
);
export const ADD_PUBLISHER_CONNECTION: string = typeCreatorV2(
  ReducerName,
  'AddPublisherConnection'
);
export const GET_DETAIL_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'GetDetailPublisher'
);
