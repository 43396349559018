import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  CampaignsStats: {
    status: StateStatus | null;
    data: any;
  };
  countryClickView: {
    status: StateStatus | null;
    data: any;
  };
  totalImpressionClickByCampaign: {
    status: StateStatus | null;
    data: any;
  };
  top5Campaign: {
    status: StateStatus | null;
    data: any;
  };
  totalViewByArrCountry: {
    status: StateStatus | null;
    data: any;
  };
  totalClickByArrCountry: {
    status: StateStatus | null;
    data: any;
  };
  deviceClickView: {
    status: StateStatus | null;
    data: any;
  };
  totalCountryView: {
    status: StateStatus | null;
    data: any;
  };
  totalCountryClick: {
    status: StateStatus | null;
    data: any;
  };
  userList: {
    status: StateStatus | null;
    data: any;
  };
  stationClickView: {
    status: StateStatus | null;
    data: any;
  };
  transportsPassengerStatsDaily: {
    status: StateStatus | null;
    data: any;
  };
  top10PassengerStatsByStation: {
    status: StateStatus | null;
    data: any;
  };
  top10PassengerStatsByTransport: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  CampaignsStats: {
    status: null,
    data: null,
  },
  countryClickView: {
    status: null,
    data: null,
  },
  totalImpressionClickByCampaign: {
    status: null,
    data: null,
  },
  top5Campaign: {
    status: null,
    data: null,
  },
  totalViewByArrCountry: {
    status: null,
    data: null,
  },
  totalClickByArrCountry: {
    status: null,
    data: null,
  },
  deviceClickView: {
    status: null,
    data: null,
  },
  totalCountryView: {
    status: null,
    data: null,
  },
  totalCountryClick: {
    status: null,
    data: null,
  },
  userList: {
    status: null,
    data: null,
  },
  stationClickView: {
    status: null,
    data: null,
  },
  transportsPassengerStatsDaily: {
    status: null,
    data: null,
  },
  top10PassengerStatsByStation: {
    status: null,
    data: null,
  },
  top10PassengerStatsByTransport: {
    status: null,
    data: null,
  },
};

const AdvDashboardReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_CAMPAIGNS_STATS:
      return {
        ...state,
        CampaignsStats: {
          ...state.CampaignsStats,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.CampaignsStats.data
              : action.data,
        },
      };
    case Types.SET_COUNTRY_CLICK_VIEW:
      return {
        ...state,
        countryClickView: {
          ...state.countryClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.countryClickView.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_IMPRESSION_CLICK_BY_CAMPAIGN:
      return {
        ...state,
        totalImpressionClickByCampaign: {
          ...state.totalImpressionClickByCampaign,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalImpressionClickByCampaign.data
              : action.data,
        },
      };
    case Types.SET_TOP_5_CAMPAIGN:
      return {
        ...state,
        top5Campaign: {
          ...state.top5Campaign,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top5Campaign.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_VIEW_BY_ARR_COUNTRY:
      return {
        ...state,
        totalViewByArrCountry: {
          ...state.totalViewByArrCountry,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalViewByArrCountry.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_CLICK_BY_ARR_COUNTRY:
      return {
        ...state,
        totalClickByArrCountry: {
          ...state.totalClickByArrCountry,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalClickByArrCountry.data
              : action.data,
        },
      };
    case Types.SET_DEVICE_CLICK_VIEW:
      return {
        ...state,
        deviceClickView: {
          ...state.deviceClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.deviceClickView.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_COUNTRY_VIEW:
      return {
        ...state,
        totalCountryView: {
          ...state.totalCountryView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalCountryView.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_COUNTRY_CLICK:
      return {
        ...state,
        totalCountryClick: {
          ...state.totalCountryClick,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalCountryClick.data
              : action.data,
        },
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };
    case Types.SET_STATION_CLICK_VIEW:
      return {
        ...state,
        stationClickView: {
          ...state.stationClickView,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.stationClickView.data
              : action.data,
        },
      };
    case Types.SET_TRANSPORTS_PASSENGER_STATS_DAILY:
      return {
        ...state,
        transportsPassengerStatsDaily: {
          ...state.transportsPassengerStatsDaily,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.transportsPassengerStatsDaily.data
              : action.data,
        },
      };
    case Types.SET_TOP_10_PASSENGER_STATS_BY_STATION:
      return {
        ...state,
        top10PassengerStatsByStation: {
          ...state.top10PassengerStatsByStation,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top10PassengerStatsByStation.data
              : action.data,
        },
      };
    case Types.SET_TOP_10_PASSENGER_STATS_BY_TRANSPORT:
      return {
        ...state,
        top10PassengerStatsByTransport: {
          ...state.top10PassengerStatsByTransport,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.top10PassengerStatsByTransport.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AdvDashboardReducer;
