import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  ImportantDevices,
  BarChartOutlined,
  RestoreOutlined,
} from '@material-ui/icons';
import GridLayout from '../../../../components/molecules/grid_layout';

import useStyles from '../../styles';
import { rangeOption } from '../../configuration';
import {
  useGetCampaignList,
  useGetPagination,
  useGetSearchQuery,
} from '../../../../redux/reducers/reporting_reducer/selectors';
import Actions from '../../../../redux/reducers/reporting_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../../translations';

interface IFormData {
  campaignid: string;
  fromdate: string | null;
  todate: string | null;
}
interface IOption {
  label: string;
  value: string | number;
}
const animatedComponents = makeAnimated();

export default function SearchArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaignid, range } = useGetSearchQuery();
  // const [campaign, setCampaign] = React.useState<string[]>([]);
  const [campaignOption, setCampaignOption] = React.useState<any>([]);
  // const [range, setRange] = React.useState(rangeOption[0].id);
  const campaignList = useGetCampaignList();
  const { page, pageSize, sortField, order } = useGetPagination();

  function setUrlParams(data: IFormData) {
    const { campaignid, fromdate, todate } = data;
    window.history.pushState(
      '',
      '',
      `/adv-reporting?campaignid=${campaignid}&range=${fromdate}`
    );
  }
  useEffect(() => {
    if (campaignList.status === 'Success' && campaignList) {
      setCampaignOption(
        _.unionBy(
          campaignList.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
          'value'
        )
      );
    }
  }, [campaignList]);

  const handleChange = (selectedOptions: IOption[]) => {
    selectedOptions.map((item: IOption) => item.value);
    dispatch(
      Actions.setSearchQuery({
        range,
        campaignid: selectedOptions.map(
          (item: IOption) => item.value
        ) as string[],
      })
    );
  };

  const handleChangeRange = (selectedOptions: any) => {
    console.log('s', selectedOptions);
    dispatch(
      Actions.setSearchQuery({
        range: selectedOptions.value as string,
        campaignid,
      })
    );
  };
  function handleReset() {
    setUrlParams({
      campaignid: '',
      fromdate: '',
      todate: '',
    });
    dispatch(
      Actions.setSearchQuery({
        range: '',
        campaignid: [] as string[],
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsDetailAction({
        campaignid: '',
        clientname: '',
        fromdate: '',
        todate: '',
        enviroment: 1,
        destcountry: '',
        active: 1,
        type: '',
        page: 1,
        pageSize: 10,
        order: 'DESC',
        sortField: 'date',
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsWithoutPagingAction({
        campaignid: '',
        clientname: '',
        enviroment: 1,
        fromdate: '',
        todate: '',
        destcountry: '',
        active: 1,
        type: '',
      })
    );
  }

  function handleSubmit() {
    setUrlParams({
      campaignid: campaignid.join(','),
      fromdate: range,
      todate: '',
    });
    dispatch(
      Actions.fetchReportingCampaignsDetailAction({
        campaignid: campaignid.join(','),
        clientname: '',
        fromdate: range,
        todate: '',
        destcountry: '',
        enviroment: 1,
        active: 1,
        type: '',
        page: page,
        pageSize,
        order,
        sortField,
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsWithoutPagingAction({
        campaignid: campaignid.join(','),
        clientname: '',
        fromdate: range,
        todate: '',
        enviroment: 1,
        destcountry: '',
        active: 1,
        type: '',
      })
    );
  }

  return (
    <GridLayout
      elements={[
        {
          id: 'category',
          size: 4,
          element: (
            <FormControl variant="outlined" className={classes['w-full']}>
              <Typography>
                <TranslatedText textMap={Translations.campagin_search} />
              </Typography>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={campaignOption.filter((item: IOption) =>
                  campaignid.includes(item.value)
                )}
                onChange={handleChange}
                options={campaignOption}
              />
              {/* <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    disableUnderline
                    className={classes.boxShadow}
                    fullWidth
                    value={campaignid}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={
                              campaignOption.find(
                                (item: any) => item.value === value
                              )?.label
                            }
                          />
                        ))}
                      </div>
                    )}
                  >
                    {campaignOption.map((campaign: any) => (
                      <MenuItem
                        key={campaign.value}
                        value={campaign.value.toString()}
                      >
                        {campaign.label}
                      </MenuItem>
                    ))}
                  </Select> */}
            </FormControl>
          ),
        },
        {
          id: 'range',
          size: 4,
          element: (
            <FormControl className={clsx(classes['w-full'])}>
              <Typography>
                <TranslatedText textMap={Translations.range_search} />
              </Typography>
              {/* <Select
                    disableUnderline
                    fullWidth
                    className={clsx(classes.boxShadow)}
                    value={range}
                    onChange={handleChangeRange}
                  >
                    {rangeOption.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
              <Select
                components={animatedComponents}
                // isMulti
                value={
                  rangeOption.filter((item: IOption) => range === item.value)[0]
                }
                onChange={handleChangeRange}
                options={rangeOption}
              />
            </FormControl>
          ),
        },
        {
          id: 'button',
          size: 4,
          element: (
            <React.Fragment>
              <Typography>
                <TranslatedText textMap={Translations.action_search} />{' '}
              </Typography>
              <div className={clsx(classes['w-full'], classes.flex)}>
                <Button
                  className={classes['mr-12']}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <TranslatedText textMap={Translations.button_search} />
                </Button>
                <Tooltip title="Refresh result" placement="right">
                  <Button
                    onClick={handleReset}
                    variant="contained"
                    color="primary"
                  >
                    <RestoreOutlined />
                  </Button>
                </Tooltip>
              </div>
            </React.Fragment>
          ),
        },
      ]}
    />
  );
}
