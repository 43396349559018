import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType1 } from '../configuration';

export interface IGeneralPublisherStatisticInput {
  userid: number;
  appid: string | null;
  publisherid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

function getFillRateConfig(data: any) {
  const DashboardBuilderConfigClickDestination: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: 'Fillrate Per Geo',
            format: {
              label: 'Fillrate Per Geo',
              backgroundColor: '#439CDD',
            },
          },
        ],
        labels: data.labels,
        chartType: ChartTypesEnum.COLUMN,
        options: OptionsGraphType1(100),
        title: '',
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/publisher-fillrate-by-country/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return DashboardBuilderConfigClickDestination;
}
export default getFillRateConfig;
