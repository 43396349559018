import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        {...props}
        variant="determinate"
        className={clsx({ [classes.bottom]: true, [classes.spinner]: true })}
        size={40}
        thickness={4}
        value={100}
      />
      <Box className={classes.top} display="inline-flex">
        <CircularProgress
          className={classes.spinner}
          {...props}
          value={props.value}
          // classes={{
          //   circle: classes.circle,
          // }}
          variant="determinate"
          // className={classes.top}
          size={40}
          thickness={4}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
      {/* <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      /> */}
    </div>
  );
};

export default CircularProgressWithLabel;
