// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** getSchemeTypes  */
interface ISchemeTypesOutput {
  applicant_type_id: number;
  code: 'EU' | 'NON_EU' | 'IOSS';
  description: string;
}

export const getSchemeTypes = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/schemeType`
  );

  let _data: ISchemeTypesOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** registerUser  */
interface IRegisterUserInput {
  email: string;
  password: string;
  firstName: string;
  surname: string;
  nationality: string;
  birthDate: string;
  gender: string;
}

export const registerUser = async (
  payload: IRegisterUserInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/oauth/register`,
    payload
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: null,
    status: data.status,
  };
  return output;
};
