import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  ButtonProps,
} from '@material-ui/core';
import * as UserProfileSelectors from '../../store/selectors';
import GridLayout from '../../../../components/molecules/grid_layout';
import {
  AppDatePicker,
  AppRadioGroup,
  AppTextField,
  AppSelect,
} from '../../../../components/molecules/form_controls';
import TranslatedText from '../../../../components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import Translations from '../../translations';
import { StateStatus } from '../../../../redux/utils/common';
import { Gender } from '../../../../config';
import CountriesSelect from './FormCountriesSelect';
import { useDispatch } from 'react-redux';
import UserProfileActions from '../../store/actions';
import { ReducerKeys } from 'redux/config';
import withReducer from 'redux/withReducer';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const defaultValues = {
  oldPassword: null,
  newPassword: null,
  confirmPassword: null,
};

interface IFormData {
  oldPassword: string | null;
  newPassword: string | null;
  confirmPassword: string | null;
}

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileSecurity = () => {
  console.log('Render ProfileSecurity:');
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });
  // const userChangePasswordStatus = UserProfileSelectors.useGetChangeProfilePasswordStatus();
  // console.log('Data:', userChangePasswordStatus);

  const onSubmit = (data: any) => {
    console.log('Data:', data);
    dispatch(
      UserProfileActions.changePasswordAction(
        data.newPassword,
        data.confirmPassword,
        data.oldPassword
      )
    );
    reset(defaultValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className={clsx(classes.root)}
    >
      <Card variant={'outlined'}>
        <CardHeader
          subheader="The information can be edited"
          title={
            <TranslatedText
              defaultText={'Security'}
              textMap={Translations.tab_security}
            />
          }
        />
        <Divider />
        <CardContent>
          <GridLayout
            elements={[
              // {
              //   id: '1',
              //   size: 12,
              //   element: (
              //     <AppTextField
              //       variant="outlined"
              //       margin="normal"
              //       required
              //       fullWidth
              //       name="oldPassword"
              //       error={'oldPassword' in errors}
              //       label={
              //         <TranslatedText
              //           defaultText={'Old Password'}
              //           textMap={Translations.form_field_old_password}
              //         />
              //       }
              //       helperText={
              //         errors.oldPassword && errors.oldPassword.message
              //       }
              //       type="password"
              //       id="oldPassword"
              //       control={control}
              //       rules={{
              //         required: <RequiredField />,
              //       }}
              //       icon={{
              //         position: 'right',
              //         Component: <LockOutlinedIcon />,
              //       }}
              //     />
              //   ),
              // },
              {
                id: '2',
                size: 12,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    error={'newPassword' in errors}
                    label={
                      <TranslatedText
                        defaultText={'New Password'}
                        textMap={Translations.form_field_new_password}
                      />
                    }
                    helperText={
                      errors.newPassword && errors.newPassword.message
                    }
                    type="password"
                    id="newPassword"
                    control={control}
                    rules={{
                      required: <RequiredField />,
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters',
                      },
                    }}
                    icon={{
                      position: 'right',
                      Component: <LockOutlinedIcon />,
                    }}
                  />
                ),
              },
              {
                id: '3',
                size: 12,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    error={'confirmPassword' in errors}
                    label={
                      <TranslatedText
                        defaultText={'Confirm Password'}
                        textMap={Translations.form_field_confirm_password}
                      />
                    }
                    helperText={
                      errors.confirmPassword && errors.confirmPassword.message
                    }
                    type="password"
                    id="confirmPassword"
                    control={control}
                    rules={{
                      required: <RequiredField />,
                      validate: () =>
                        getValues('newPassword') ===
                          getValues('confirmPassword') || <PasswordMatch />,
                    }}
                    icon={{
                      position: 'right',
                      Component: <LockOutlinedIcon />,
                    }}
                  />
                ),
              },
            ]}
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <SubmitWrapper />
        </Box>
      </Card>
    </form>
  );
};

const SubmitWrapper = React.memo(() => {
  const submitStatus = UserProfileSelectors.useGetChangeProfilePasswordStatus();

  return (
    <Button
      type={'submit'}
      color="primary"
      variant="contained"
      disabled={submitStatus === StateStatus.Pending}
    >
      <TranslatedText
        defaultText={'Save details'}
        textMap={Translations.form_submit}
      />
    </Button>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

const PasswordMatch = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_password_match}
      defaultText={'Passwords do not match'}
    />
  );
};

ProfileSecurity.propTypes = {
  className: PropTypes.string,
};

export default ProfileSecurity;
