import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetPublisherApplication = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.APPLICATION_REDUCER].publisherApplication
  );
  return data;
};
export const useGetPersistPublisherApplication = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.APPLICATION_REDUCER].persistPublisherApplication
  );
  return data;
};

export const useGetIsOpen = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.APPLICATION_REDUCER].isOpen
  );
  return data;
};
