import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  UsersList: {
    status: StateStatus | null;
    data: any;
  };
  addUsers: {
    status: StateStatus | null;
  };
  editUsers: {
    status: StateStatus | null;
  };
  viewUsers: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  UsersList: {
    status: null,
    data: null,
  },
  addUsers: {
    status: null,
  },
  editUsers: {
    status: null,
  },
  viewUsers: {
    status: null,
    data: null,
  },
};

const UsersReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USERS_LIST:
      return {
        ...state,
        UsersList: {
          ...state.UsersList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.UsersList.data : action.data,
        },
      };
    case Types.SET_ADD_USERS:
      return {
        ...state,
        addUsers: {
          ...state.addUsers,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_USERS:
      return {
        ...state,
        editUsers: {
          ...state.editUsers,
          status: action.status || null,
        },
      };
    case Types.SET_USERS_DETAIL:
      return {
        ...state,
        viewUsers: {
          ...state.viewUsers,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.viewUsers.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UsersReducer;
