// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

interface IParams {
  userid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

export const getTopResult = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-top-result?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getPassengerByDate = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-passenger-by-date?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getPassengerByDestinationAirport = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-passenger-by-destination-airport?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPassengerByDepartureAirport = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-passenger-by-departure-airport?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTrackingByDeparture = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-tracking-by-departure?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTrackingByDestination = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-tracking-by-destination?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getViewPerDevice = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-view-per-device?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTop10VisitingTagByDeparture = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-top-10-visiting-tags-by-departure?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTop10VisitingURLSByDeparture = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/ife_analytics/get-top-10-visiting-urls-by-departure?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
