import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Chip,
  FormControl,
  Input,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import clsx from 'clsx';
import { RestoreOutlined } from '@material-ui/icons';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import GridLayout from '../../../../components/molecules/grid_layout';

import useStyles from '../../styles';
import { rangeOption } from '../../configuration';
import {
  useGetCampaignList,
  useGetPagination,
  useGetSearchQuery,
} from '../../../../redux/reducers/reporting_reducer/selectors';
import Actions from '../../../../redux/reducers/reporting_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../../translations';
import ApexCharts from 'apexcharts';
import { showToastAction } from 'components/atoms/toast_message';

interface IFormData {
  campaignid: string;
  fromdate: string | null;
  todate: string | null;
}

interface IOption {
  label: string;
  value: string | number;
}
const animatedComponents = makeAnimated();

export default function SearchArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaignid, range } = useGetSearchQuery();
  // const [campaign, setCampaign] = React.useState<string[]>([]);
  const [campaignOption, setCampaignOption] = React.useState<any[]>([]);
  // const [range, setRange] = React.useState(rangeOption[0].id);
  const campaignList = useGetCampaignList();
  const { page, pageSize, sortField, order } = useGetPagination();

  function setUrlParams(data: IFormData) {
    const { campaignid, fromdate, todate } = data;
    window.history.pushState(
      '',
      '',
      `/adv-reporting-inflight?campaignid=${campaignid}&range=${fromdate}`
    );
  }
  useEffect(() => {
    if (campaignList.status === 'Success' && campaignList) {
      console.log(
        'list',
        _.unionBy(
          campaignList.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
          'value'
        )
      );
      setCampaignOption(
        _.unionBy(
          campaignList.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
          'value'
        )
      );
    }
  }, [campaignList]);

  const handleChange = (selectedOptions: IOption[]) => {
    selectedOptions.map((item: IOption) => item.value);
    dispatch(
      Actions.setSearchQuery({
        range,
        campaignid: selectedOptions.map(
          (item: IOption) => item.value
        ) as string[],
      })
    );
  };

  const handleChangeRange = (selectedOptions: any) => {
    dispatch(
      Actions.setSearchQuery({
        range: (selectedOptions?.value as string) || '',
        campaignid,
      })
    );
  };
  async function handleExport() {
    if (
      campaignid !== '' &&
      campaignid !== [] &&
      campaignList.status === 'Success'
    ) {
      const campaignNames = _.uniq(
        campaignList.data
          .filter((campaign: any) => campaignid.includes(campaign.id))
          .map((campaign: any) => campaign.name)
      );

      console.log('range', range);

      const graphImgIds = [
        'Impression Per Country',
        'Impression Per Type',
        'Click Per Country',
        'Click Per Type',
      ];
      const lineId = 'click impression';
      // const base64Img = await graphImgIds.map(async (id: string) => {
      //   return await ApexCharts.exec(id, 'dataURI');
      // });
      const imgData: string[] = [];
      for (let index = 0; index < graphImgIds.length; index++) {
        const element = graphImgIds[index];
        const img = await ApexCharts.exec(element, 'dataURI');
        // _.set(imgData, element, img.imgURI);
        imgData.push(img.imgURI);
      }
      const imgLine = await ApexCharts.exec(lineId, 'dataURI');
      const worldCanvas = await html2canvas(
        document.querySelector('#worldmap') as HTMLElement,
        {
          scale: 3, // use the desired scale
          allowTaint: true,
          useCORS: true,
        }
      );
      const progressClick = await html2canvas(
        document.querySelector('#progressclick') as HTMLElement,
        {
          scale: 1, // use the desired scale
          allowTaint: true,
          useCORS: true,
        }
      );
      const progressView = await html2canvas(
        document.querySelector('#progressview') as HTMLElement,
        {
          scale: 1, // use the desired scale
          allowTaint: true,
          useCORS: true,
        }
      );
      dispatch(
        Actions.setExportData({
          columns: imgData,
          line: imgLine.imgURI,
          map: worldCanvas.toDataURL('image/jpeg'),
          clicks: 0,
          views: 0,
          ctr: 0,
          names: campaignNames.join(', '),
          progressClick: progressClick.toDataURL('image/jpeg'),
          progressView: progressView.toDataURL('image/jpeg'),
          range,
        })
      );
      window.open('/export-pdf');
    } else {
      showToastAction(dispatch, 'Please select at least one campaign', 'error');
    }
  }

  function handleReset() {
    setUrlParams({
      campaignid: '',
      fromdate: '',
      todate: '',
    });
    dispatch(
      Actions.setSearchQuery({
        range: '',
        campaignid: [] as string[],
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsDetailAction({
        campaignid: '',
        clientname: '',
        fromdate: '',
        todate: '',
        destcountry: '',
        enviroment: 0,
        active: 1,
        type: '',
        page: 0,
        pageSize: 1,
        order: 'ASC',
        sortField: 'date',
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsWithoutPagingAction({
        campaignid: '',
        clientname: '',
        enviroment: 0,
        fromdate: '',
        todate: '',
        destcountry: '',
        active: 1,
        type: '',
        order: 'ASC',
        sortField: 'date',
      })
    );
  }

  function handleSubmit() {
    setUrlParams({
      campaignid: campaignid.join(','),
      fromdate: range,
      todate: '',
    });
    dispatch(
      Actions.fetchReportingCampaignsDetailAction({
        campaignid: campaignid.join(','),
        clientname: '',
        fromdate: range,
        todate: '',
        destcountry: '',
        enviroment: 0,
        active: 1,
        type: '',
        page,
        pageSize,
        order,
        sortField,
      })
    );
    dispatch(
      Actions.fetchReportingCampaignsWithoutPagingAction({
        campaignid: campaignid.join(','),
        clientname: '',
        fromdate: range,
        enviroment: 0,
        todate: '',
        destcountry: '',
        active: 1,
        type: '',
        order: 'ASC',
        sortField: 'date',
      })
    );
  }

  return (
    // <Card className={classes['w-full']}>
    //   <CardContent className={classes['w-full']}>
    <GridLayout
      elements={[
        {
          id: 'category',
          size: 4,
          element: (
            <FormControl variant="outlined" className={classes['w-full']}>
              <Typography>
                <TranslatedText textMap={Translations.campagin_search} />
              </Typography>

              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={campaignOption.filter((item: IOption) =>
                  campaignid.includes(item.value)
                )}
                onChange={handleChange}
                options={campaignOption}
              />
              {/* <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                disableUnderline
                className={classes.boxShadow}
                fullWidth
                value={campaignid}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={
                          campaignOption.find(
                            (item: any) => item.value === value
                          )?.label
                        }
                      />
                    ))}
                  </div>
                )}
              >
                {campaignOption.map((campaign: any) => (
                  <MenuItem
                    key={campaign.value}
                    value={campaign.value.toString()}
                  >
                    {campaign.label}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          ),
        },
        {
          id: 'range',
          size: 4,
          element: (
            <FormControl className={clsx(classes['w-full'])}>
              <Typography>
                <TranslatedText textMap={Translations.range_search} />
              </Typography>
              <Select
                components={animatedComponents}
                // isMulti
                isClearable
                value={
                  rangeOption.filter(
                    (item: IOption) => range === item.value
                  )[0] || ''
                }
                onChange={handleChangeRange}
                options={rangeOption}
              />
              {/* <Select
                disableUnderline
                fullWidth
                className={clsx(classes.boxShadow)}
                value={range}
                onChange={handleChangeRange}
              >
                {rangeOption.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          ),
        },
        {
          id: 'button',
          size: 4,
          element: (
            <React.Fragment>
              <Typography>
                <TranslatedText textMap={Translations.action_search} />{' '}
              </Typography>
              <div className={clsx(classes['w-full'], classes.flex)}>
                <Button
                  className={classes['mr-12']}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <TranslatedText textMap={Translations.button_search} />
                </Button>
                <Tooltip title="Refresh result" placement="right">
                  <Button
                    className={classes['mr-12']}
                    onClick={handleReset}
                    variant="contained"
                    color="primary"
                  >
                    <RestoreOutlined />
                  </Button>
                </Tooltip>
                <Button
                  className={classes['mr-12']}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleExport}
                >
                  {/* <TranslatedText textMap={Translations.button_search} /> */}
                  Export
                </Button>
              </div>
            </React.Fragment>
          ),
        },
      ]}
    />
    //   </CardContent>
    // </Card>
  );
}
