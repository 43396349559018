import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

export interface IPaginationParams {
  page: number;
  pageSize: number;
  sortField: string;
  order: string;
}
export interface ISearchQuery {
  campaignid: string[];
  range: string;
}
export interface IExportData {
  columns: any[];
  line: string;
  ctr: number;
  clicks: number;
  views: number;
  map: string;
  progressClick: string;
  progressView: string;
  names: string;
  range: string;
}

interface IState {
  reportingCampaignDetail: {
    status: StateStatus | null;
    data: any;
  };
  reportingCampaignWithoutPaging: {
    status: StateStatus | null;
    data: any;
  };
  campaignList: {
    status: StateStatus | null;
    data: any;
  };
  paginationParams: IPaginationParams;
  searchQuery: ISearchQuery;
  airportsList: any[];
  loading: boolean;
  exportData: IExportData;
}

const initialState: IState = {
  reportingCampaignDetail: {
    status: null,
    data: null,
  },
  reportingCampaignWithoutPaging: {
    status: null,
    data: null,
  },
  campaignList: {
    status: null,
    data: null,
  },
  paginationParams: {
    page: 0,
    pageSize: 10,
    order: 'DESC',
    sortField: 'date',
  },
  searchQuery: {
    campaignid: [] as string[],
    range: '',
  },
  airportsList: [],
  loading: false,
  exportData: {
    columns: [],
    line: '',
    ctr: 0,
    clicks: 0,
    views: 0,
    map: '',
    progressClick: '',
    progressView: '',
    names: '',
    range: '',
  },
};

const ReportingReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_REPORTING_CAMPAIGNS_DETAIL:
      return {
        ...state,
        reportingCampaignDetail: {
          ...state.reportingCampaignDetail,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.reportingCampaignDetail.data
              : action.data,
        },
      };
    case Types.SET_REPORTING_CAMPAIGNS_WITHOUT_PAGING:
      return {
        ...state,
        reportingCampaignWithoutPaging: {
          ...state.reportingCampaignWithoutPaging,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.reportingCampaignWithoutPaging.data
              : action.data,
        },
      };
    case Types.SET_PAGINATION_PARAMS:
      return {
        ...state,
        paginationParams: {
          ...state.paginationParams,
          ...action.data,
        },
      };
    case Types.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          ...action.data,
        },
      };
    case Types.SET_AIRPORT_LIST:
      return {
        ...state,
        airportsList: [...action.data],
      };

    case Types.SET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: {
          ...state.campaignList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.campaignList.data
              : action.data,
        },
      };
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case Types.SET_EXPORT_DATA:
      return {
        ...state,
        exportData: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ReportingReducer;
