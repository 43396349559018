import { Environment } from 'config';
import request from 'utils/request';
import * as Types from '../types';

// Get list slave from master
export interface IGetListSlaveFromMasterInput {
  publishermasterid: string;
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export interface IGetListSlaveFromMasterOutput extends Types.ResponseOutput {
  data: {
    items: {
      id: string;
      publishermasterid: string;
      publisherslaveid: string;
      udts: string | null;
    }[];
    paging: {
      page: number;
      pageSize: number;
      total: number;
    };
  };
}

export const getListSlaveFromMaster = async ({
  publishermasterid,
  sortField,
  page,
  pageSize,
  order,
}: IGetListSlaveFromMasterInput): Promise<IGetListSlaveFromMasterOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/publishers_connections/list?publishermasterid=${publishermasterid}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// Delete publishers connections

export interface IDeletePublishersConnectionsInput {
  publisherslaveid: string;
}

export const deletePublishersConnections = async ({
  publisherslaveid,
}: IDeletePublishersConnectionsInput): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/publishers_connections/${publisherslaveid}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
// add new publishers connections

export interface IAddPublishersConnectionsInput {
  publishermasterid: number;
  publisherslaveid: number;
}

export interface IAddPublishersConnectionsOutput extends Types.ResponseOutput {}

export const addPublishersConnections = async (
  payload: IAddPublishersConnectionsInput
): Promise<IAddPublishersConnectionsOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/publishers_connections`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
