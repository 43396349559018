import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Card, CardContent, AppBar } from '@material-ui/core';
import { showToastAction } from 'components/atoms/toast_message';

import SwipeableViews from 'react-swipeable-views';
import PageLayout from 'components/app_common/page_layout';
import LoadingView from 'components/atoms/loading_view';
import Actions from 'redux/reducers/reporting_reducer/actions';
import Information from './components/Information';
import Analytics from './components/Analytics';
import SearchArea from './components/SearchArea';
import useStyles from './styles';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { useGetLoading } from 'redux/reducers/reporting_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';

export default function AdvReporting() {
  // const dispatch = useDispatch();
  // const classes = useStyles();
  // const { id } = useSelectUserData();
  // const loading = useGetLoading();

  // const [tabValue, setTabValue] = useState(0);

  // useEffect(() => {
  //   dispatch(Actions.setLoading(true));
  //   dispatch(Actions.fetchCampaignsListActions({ userid: parseInt(id) }));
  // }, [id]);

  // function getParameterByName(name: string, url = window.location.href) {
  //   name = name.replace(/[\[\]]/g, '\\$&');
  //   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //     results = regex.exec(url);
  //   if (!results) return null;
  //   if (!results[2]) return '';
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
  // }
  // useEffect(() => {
  //   const campaignid = getParameterByName('campaignid');
  //   const range = getParameterByName('range');
  //   if (campaignid || range) {
  //     const _campaignid = campaignid?.split(',') || ([] as string[]);
  //     const _range = range || '';

  //     dispatch(
  //       Actions.setSearchQuery({ campaignid: _campaignid, range: _range })
  //     );
  //   }
  //   if (!campaignid && !range) {
  //     dispatch(Actions.setSearchQuery({ campaignid: [], range: '' }));
  //   }

  //   dispatch(Actions.fetchAirportListActions({}));

  //   if (campaignid !== '') {
  //     dispatch(
  //       Actions.fetchReportingCampaignsDetailAction({
  //         campaignid,
  //         clientname: '',
  //         fromdate: range,
  //         todate: '',
  //         destcountry: '',
  //         active: 1,
  //         type: '',
  //         enviroment: 1,
  //         page: 0,
  //         pageSize: 10,
  //         order: 'DESC',
  //         sortField: 'date',
  //       })
  //     );
  //     dispatch(
  //       Actions.fetchReportingCampaignsWithoutPagingAction({
  //         campaignid,
  //         clientname: '',
  //         fromdate: range,
  //         enviroment: 1,
  //         todate: '',
  //         destcountry: '',
  //         active: 1,
  //         type: '',
  //       })
  //     );
  //   } else {
  //     showToastAction(dispatch, 'Please select at least one campaign', 'error');
  //   }
  // }, [window]);
  // function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
  //   setTabValue(newValue);
  // }
  return (
    <></>
    // <PageLayout>
    //   <Card className={classes['w-full']}>
    //     <CardContent className={classes['w-full']}>
    //       <SearchArea />
    //       <AppBar className={classes.mt12} position="static" color="default">
    //         <Tabs
    //           value={tabValue}
    //           onChange={handleChange}
    //           indicatorColor="primary"
    //           textColor="primary"
    //           variant="fullWidth"
    //         >
    //           <Tab
    //             classes={{
    //               textColorPrimary: classes.tab,
    //               selected: classes.tabSelected,
    //             }}
    //             label={
    //               <TranslatedText
    //                 textMap={Translations.analytic_tab_title_online}
    //               />
    //             }
    //           />
    //           <Tab
    //             classes={{
    //               textColorPrimary: classes.tab,
    //               selected: classes.tabSelected,
    //             }}
    //             label={
    //               <TranslatedText
    //                 textMap={Translations.information_tab_title_online}
    //               />
    //             }
    //           />
    //         </Tabs>
    //       </AppBar>
    //       <SwipeableViews
    //         index={tabValue}
    //         onChangeIndex={(value) => setTabValue(value)}
    //       >
    //         <Analytics />
    //         <Information />
    //       </SwipeableViews>
    //       {loading && <LoadingView fullScreen />}
    //     </CardContent>
    //   </Card>
    // </PageLayout>
  );
}
