import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

interface ITransportLinesOutput {
  items: ITransportLine[];
}

export interface ITransportLine {
  id: number;
  pubCode: string;
  code: string;
  name: string;
  type: string;
}

export const fetchTransportLines = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transports/lines`
  );

  let _data: ITransportLinesOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface ITransportStationsOutput {
  items: ITransportStation[];
}

export interface ITransportStation {
  id: number;
  pubcode: string;
  code: string;
  name: string | null;
  areacode: string | null;
  areaname: string | null;
  lat: number;
  lon: number;
  status: number | null;
}

export const fetchTranportStationsFromLine = async (
  lineId: number
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transports/stations-from-line?id=${lineId}`
  );

  let _data: ITransportStationsOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
