import { Languages } from 'config';
import Translations from '../../translations';
export interface IPurpose {
  code: number;
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  notes: Array<{
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  }>;
  description: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  }
}

export const CampaignPurposes: IPurpose[] = [
  {
    code: 1,
    name: Translations.awareness,
    notes: [
      Translations.awareness_note1,
      Translations.awareness_note2,
      Translations.awareness_note3,
      Translations.awareness_note4,
    ],
    description: Translations.awareness_description
  },
  {
    code: 2,
    name: Translations.consideration,
    notes: [
      Translations.consideration_note1,
      Translations.consideration_note2,
      Translations.consideration_note3,
      Translations.consideration_note4,
    ],
    description: Translations.consideration_description

  },
];
