import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useHistory, useParams } from 'react-router';
import NavigationConfig from 'navigation/config';
import { StateStatus } from 'redux/utils/common';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { AppSelect } from 'components/molecules/form_controls';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import Datatable from 'components/molecules/datatable';
import Swal from 'sweetalert2';
import {
  useCreateDSPAdvertisementStatus,
  useListAdvertisementType,
  useListDSPAdvertisement,
} from 'redux/reducers/dsp_reducer/selectors';

interface IAdvertisementType {
  data?: any;
}

interface IFormData {
  advertismenettype: string | null;
}

const defaultValues: IFormData = {
  advertismenettype: null,
};

const AdvertisementType = (props: IAdvertisementType) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const listDSPAdvertisement = useListDSPAdvertisement();
  const listAdvertisementType = useListAdvertisementType();
  const createDSPAdvertisementStatus = useCreateDSPAdvertisementStatus();
  useEffect(() => {
    if (props?.data) {
      dispatch(
        DSPActions.fetchListDSPAdvertisementAction({
          sortField: 'id',
          order: 'asc',
          page: 0,
          pageSize: 9999,
          DSPCode: props?.data?.code,
        })
      );
      if (!listAdvertisementType?.data) {
        dispatch(
          DSPActions.fetchListAdvertisementTypeAction({
            sortField: 'id',
            order: 'asc',
            page: 0,
            pageSize: 9999,
            DSPCode: props?.data?.code,
          })
        );
      }
    }
  }, [props?.data]);
  const { control, errors, handleSubmit } = useForm<IFormData>({
    defaultValues,
  });
  const handleCreateAdvertisementDSP = (data: any) => {
    dispatch(
      DSPActions.addDSPAdvertisementAction({
        dspcode: props?.data?.code,
        advertismenettype: data?.advertismenettype,
        onFinish: () => {
          dispatch(
            DSPActions.fetchListDSPAdvertisementAction({
              sortField: 'id',
              order: 'asc',
              page: 0,
              pageSize: 9999,
              DSPCode: props?.data?.code,
            })
          );
        },
      })
    );
  };
  const optionListAdvertisementType =
    listAdvertisementType?.data?.items?.map((item) => ({
      id: item?.ID,
      label: item?.Name,
    })) ?? [];
  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '32px',
            textAlign: 'center',
          }}
        >
          List of Advertisement Types connected with {props?.data?.name}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            textAlign: 'center',
          }}
        >
          Associate Advertisement Types with with {props?.data?.name}
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: 'auto',
            margin: '20px',
          }}
          onSubmit={handleSubmit(handleCreateAdvertisementDSP)}
        >
          <AppSelect
            variant="outlined"
            error={Boolean(_.get(errors, 'advertismenettype', false))}
            rules={{
              required: 'This is required',
            }}
            options={optionListAdvertisementType}
            id={'advertismenettype'}
            control={control}
            label={'Advertismenet type'}
            autoComplete={'advertismenettype'}
            name={'advertismenettype'}
            style={{
              minWidth: 180,
              marginRight: 15,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            type="submit"
            disabled={createDSPAdvertisementStatus === StateStatus.Pending}
          >
            {createDSPAdvertisementStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              'Add new'
            )}
          </Button>
        </form>

        {listDSPAdvertisement?.data?.items?.length ? (
          <Datatable
            loading={listDSPAdvertisement.status === StateStatus.Pending}
            // sorting={sorting}
            // paging={paging}
            data={
              listDSPAdvertisement?.data?.items?.map((item) => ({
                ...item,
                advertismenettype_name: listAdvertisementType.data?.items?.find(
                  (v) => v.ID === item.advertismenettype
                )?.Name,
              })) || []
            }
            columns={TableColumns}
            // changePageHandle={changePageHandler}
            paperProps={{
              style: {
                maxHeight: '50vh',
                overflowY: 'auto',
              },
            }}
          />
        ) : (
          <Box
            fontSize="20px"
            borderTop="1px solid #e1e1e1"
            width="100%"
            textAlign="center"
            paddingTop="15px"
          >
            No data
          </Box>
        )}
      </Box>
    </Box>
  );
};

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (e: any) => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          DSPActions.deleteDSPAdvertisementAction({
            advertisementid: props?.dataid?.id,
            onFinish: () => {
              dispatch(
                DSPActions.fetchListDSPAdvertisementAction({
                  sortField: 'id',
                  order: 'asc',
                  page: 0,
                  pageSize: 9999,
                  DSPCode: props?.dataid?.dspcode,
                })
              );
            },
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
          backgroundColor: 'red',
        }}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  // {
  //   label: 'Code',
  //   id: 'dspcode',
  //   render: (rowIndex: number, cellValue: any, columnIndex: number) => (
  //     <React.Fragment>{cellValue}</React.Fragment>
  //   ),
  //   inData: true,
  // },
  {
    label: 'Advertismenet',
    id: 'advertismenettype',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>{data[rowIndex]?.advertismenettype_name}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface IAdvertisementTypeModalProps {}
const AdvertisementTypeModal = (props: IAdvertisementTypeModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADVERTISEMENT_TYPE}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAdvertisementType) => (
        <AdvertisementType {...params} />
      )}
    />
  );
};

export default AdvertisementTypeModal;
