import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface CampaignsListElement {
  id: string;
  name: string;
  userid: string;
  maxbudget: string;
  periodfrom: string;
  periodto: string;
  creationdate: string;
  active: string;
  deleted: string;
  domain: string;
  dailybudget: string;
  totalbudget: string;
  dailyimpression: number;
  totalimpression: number;
  dailyclick: number;
  totalclick: number;
  isdraft: string;
  draftstep: number;
  status: number;
  estimatedreachselection: number;
  deleted_showinflight: number;
  deleted_showongrount: number;
  objective: number;
  clientname: string;
  lastupdatedate: string;
  orderbyrate: string;
  templateinstanceid: string;
}

interface CampaignsListData {
  paging: CampaignsListPaging;
  items: CampaignsListElement[];
}

interface CampaignsListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface CampaignsistOutput {
  status: StateStatus;
  data: CampaignsListData | null;
}

export const useGetCampaignsList = (): CampaignsistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.CAMPAIGNS_REDUCER].CampaignsList
  );
  return data;
};
