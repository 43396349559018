import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Languages, AuthRoles } from '../../config';
import TranslatedText from '../../components/atoms/translated_text';
import Actions from '../../redux/reducers/application_reducer/actions';
import Translations from './translations';

function getKeyByValue(roles: any, value: string) {
  let key;
  for (var role in roles) {
    if (roles[role] === value) {
      key = role;
    }
  }
  return key;
}

function ActionsBlock(props: any) {
  const history = useHistory();
  const roles = props.dataid.roles ? props.dataid.roles.split(',') : [];

  function isDisabled(role: string) {
    if (roles.includes(AuthRoles.Administrator)) {
      return false;
    }
    if (roles.includes(role)) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <React.Fragment>
      {/* {roles.includes(Roles.Advertiser) && ( */}
      <Button
        variant="contained"
        color="primary"
        disabled={isDisabled(AuthRoles.Advertiser)}
        style={{ marginRight: 10, textTransform: 'capitalize' }}
        onClick={() => history.push(`/adv-dashboard?userid=${props.dataid.id}`)}
      >
        <TranslatedText textMap={Translations.reporting_adv} />
      </Button>
      {/* )}
      {roles.includes(Roles.Publisher) && ( */}
      <Button
        variant="contained"
        color="primary"
        disabled={isDisabled(AuthRoles.Publisher)}
        style={{ marginRight: 10, textTransform: 'capitalize' }}
        onClick={() => history.push(`/pub-dashboard?userid=${props.dataid.id}`)}
      >
        <TranslatedText textMap={Translations.reporting_pub} />
      </Button>
      {/* )} */}
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 10, textTransform: 'capitalize' }}
        onClick={() => history.push(`/wallet?userid=${props.dataid.id}`)}
      >
        <TranslatedText textMap={Translations.wallet} />
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: 'ID',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'First Name',
    id: 'firstname',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Email',
    id: 'email',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Active',
    id: 'active',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue ? 'Active' : 'In active'}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Airlineids',
    id: 'airlineids',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Roles',
    id: 'roles',
    render: (rowIndex: number, cellValue: string, columnIndex: number) => {
      const roles = cellValue
        ? cellValue
            .split(',')
            .map((item: string) => getKeyByValue(AuthRoles, item))
            .join(',')
        : '';
      return <React.Fragment>{roles}</React.Fragment>;
    },
    inData: true,
  },
  {
    label: '',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock rowIndex={rowIndex} data={data} dataid={data[rowIndex]} />
    ),
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
