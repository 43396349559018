import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_REPORTING_CAMPAIGNS_DETAIL: string = typeCreatorV2(
  'ReportingReducer',
  'SetReportingCampaignsDetail'
);
export const SET_REPORTING_CAMPAIGNS_WITHOUT_PAGING: string = typeCreatorV2(
  'ReportingReducer',
  'SetReportingCampaignsWithoutPaging'
);

export const SET_PAGINATION_PARAMS: string = typeCreatorV2(
  'ReportingReducer',
  'SetPaginationParams'
);

export const SET_CAMPAIGN_LIST: string = typeCreatorV2(
  'ReportingReducer',
  'SetCampaignList'
);

export const SET_SEARCH_QUERY: string = typeCreatorV2(
  'ReportingReducer',
  'SetSearchQuery'
);

export const SET_AIRPORT_LIST: string = typeCreatorV2(
  'ReportingReducer',
  'SetAirportList'
);

export const SET_LOADING: string = typeCreatorV2(
  'ReportingReducer',
  'SetLoading'
);
export const SET_EXPORT_DATA: string = typeCreatorV2(
  'ReportingReducer',
  'SetExportData'
);
