import { LanguagesEnum } from '../../config/languages';

const Translations = {
  user_search: {
    [LanguagesEnum.English]: 'User',
    [LanguagesEnum.Greek]: 'Χρήστης',
    [LanguagesEnum.Russian]: 'Пользователь',
  },
  date_from_search: {
    [LanguagesEnum.English]: 'Date from',
    [LanguagesEnum.Greek]: 'Ημερομηνία από',
    [LanguagesEnum.Russian]: 'Дата, с',
  },
  date_to_search: {
    [LanguagesEnum.English]: 'Date to',
    [LanguagesEnum.Greek]: 'ημερομηνία για',
    [LanguagesEnum.Russian]: 'Дата до',
  },
  button_search: {
    [LanguagesEnum.English]: 'Search',
    [LanguagesEnum.Greek]: 'Αναζήτηση',
    [LanguagesEnum.Russian]: 'Поиск',
  },
  ctr_widget: {
    [LanguagesEnum.English]: 'CTR',
    [LanguagesEnum.Greek]: 'CTR',
    [LanguagesEnum.Russian]: 'CTR',
  },
  ctr_widget_description: {
    [LanguagesEnum.English]: 'The CTR (click-through rate) is the number of clicks that your advert received divided by the number of times your advert is shown.',
    [LanguagesEnum.Greek]: 'Το CTR (αναλογία κλικ προς αριθμό εμφανίσεων) είναι ο αριθμός των κλικ που έλαβε η διαφήμισή σας δια του αριθμού των φορών που εμφανίζεται η διαφήμισή σας.',
    [LanguagesEnum.Russian]: 'CTR (рейтинг кликов) - это количество кликов, полученных вашим объявлением, разделенное на количество его показов.',
  },
  click_widget_description: {
    [LanguagesEnum.English]: 'To total number of times users clicked on your advert ',
    [LanguagesEnum.Greek]: 'Synoliká, oi chrístes ékanan klik sti diafímisí sas',
    [LanguagesEnum.Russian]: 'Общее количество раз, когда пользователи нажимали на ваше объявление',
  },
  impression_widget_description: {
    [LanguagesEnum.English]: 'The total number of times your advert was shown on the users’ device ',
    [LanguagesEnum.Greek]: 'Ο συνολικός αριθμός εμφανίσεων της διαφήμισής σας στη συσκευή των χρηστών',
    [LanguagesEnum.Russian]: 'Общее количество раз, когда ваша реклама была показана на устройстве пользователей.',
  },
  impression_widget: {
    [LanguagesEnum.English]: 'Impressions',
    [LanguagesEnum.Greek]: 'εντυπώσεις',
    [LanguagesEnum.Russian]: 'Впечатления',
  },
  click_widget: {
    [LanguagesEnum.English]: 'Total Clicks',
    [LanguagesEnum.Greek]: 'Synoliká klik',
    [LanguagesEnum.Russian]: 'Всего кликов',
  },
  top_campaign_title: {
    [LanguagesEnum.English]: 'Top campaigns',
    [LanguagesEnum.Greek]: 'Top καμπάνιες',
    [LanguagesEnum.Russian]: 'Лучшая кампания',
  },
  go_to_campaign_button: {
    [LanguagesEnum.English]: 'SEE ALL CAMPAIGNS',
    [LanguagesEnum.Greek]: 'ΔΕΙΤΕ ΟΛΕΣ τις καμπάνιες',
    [LanguagesEnum.Russian]: 'ПОСМОТРЕТЬ ВСЕ КАМПАНИИ',
  },
  campaign_name_column: {
    [LanguagesEnum.English]: 'Campaign Name',
    [LanguagesEnum.Greek]: 'Όνομα καμπάνιας',
    [LanguagesEnum.Russian]: 'Название кампании',
  },
  view_column: {
    [LanguagesEnum.English]: 'Impressions',
    [LanguagesEnum.Greek]: 'Εντυπώσεις',
    [LanguagesEnum.Russian]: 'Впечатления',
  },
  campaign_pie_chart_title: {
    [LanguagesEnum.English]: 'Campaigns per CTR',
    [LanguagesEnum.Greek]: 'Καμπάνιες ανά CTR',
    [LanguagesEnum.Russian]: 'Кампании в CTR',
  },
  device_pie_chart_title: {
    [LanguagesEnum.English]: 'Devices',
    [LanguagesEnum.Greek]: 'συσκευές',
    [LanguagesEnum.Russian]: 'приборы',
  },
  impression_per_country: {
    [LanguagesEnum.English]: 'Impressions per Country',
    [LanguagesEnum.Greek]: 'Εντυπώσεις ανά Χώρα',
    [LanguagesEnum.Russian]: 'Количество показов в стране',
  },
  impression_per_destination: {
    [LanguagesEnum.English]: 'Impressions per Destination',
    [LanguagesEnum.Greek]: 'Εντυπώσεις ανά Προορισμός',
    [LanguagesEnum.Russian]: 'Впечатления в Destination',
  },
  clicks_per_country: {
    [LanguagesEnum.English]: 'Clicks per Country',
    [LanguagesEnum.Greek]: 'Κλικ ανά Χώρα',
    [LanguagesEnum.Russian]: 'Кликов в стране',
  },
  clicks_per_destination: {
    [LanguagesEnum.English]: 'Clicks per Destination',
    [LanguagesEnum.Greek]: 'Κλικ ανά Προορισμός',
    [LanguagesEnum.Russian]: 'Кликов в Destination',
  },
};

export default Translations;
