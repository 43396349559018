import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface CountriesListElement {
  code: string;
  name: string;
  capital: string;
  lat: string;
  lon: string;
  code3: string;
}

interface CountriesListData {
  paging: CountriesListPaging;
  items: CountriesListElement[];
}

interface CountriesListPaging {
  page: number;
  pageSize: number;
  total: number;
}

export interface CountriesistOutput {
  status: StateStatus | null;
  data: CountriesListData | null;
}

export const useGetCountriesList = (): CountriesistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COUNTRIES_REDUCER].countriesList
  );
  return data;
};

/* selector: useGetCountriesDetails */
interface CountriesDetailsData {
  code: string;
  name: string;
  capital: string;
  lat: string;
  lon: string;
  code3: string;
}

interface CountriesDetailsOutput {
  status: StateStatus;
  data: CountriesDetailsData | null;
}

export const useGetCountriesDetails = (): CountriesDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COUNTRIES_REDUCER].viewCountries
  );
  return data;
};
