import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

// List advertisement type

export interface IGetListAdvertisementTypeInput extends Types.IPaginationInput {
  DSPCode?: string;
}

export interface IGetListAdvertisementTypeOuput
  extends Types.ResponseWithPaginationOutput<
    {
      ID: number;
      Name: string;
      Description: string;
      Contentype: string;
      Width: number;
      Height: number;
      Deleted: boolean;
      MaxUploadSize: number;
      DeviceType: string;
      Rate: number;
      imageurl: string;
    }[]
  > {}

export const getListAdvertisementType = async ({
  page,
  pageSize,
  sortField,
  order,
  DSPCode,
}: IGetListAdvertisementTypeInput): Promise<IGetListAdvertisementTypeOuput> => {
  const { data }: any = await request(
    'get',
    `${
      Environment.API_BASE_URL
    }/api/advertisementstype/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}${
      DSPCode ? `'&DSPCode='${DSPCode}` : ''
    }`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** AdvertisementsType Detail */
interface IAdvertisementsTypeDetailInput {
  id: number;
}

interface IAdvertisementsTypeDetailOutput extends Types.ResponseOutput {
  data: {
    id: number | null;
    name: string | null;
    description: string | null;
    contentype: string | null;
    width: number | null;
    height: number | null;
    deleted: string | null;
    maxuploadsize: number | null;
    devicetype: number | null;
    rate: number | null;
    imageurl: string | null;
  };
}

export const fetchAdvertisementsTypeDetail = async (
  payload: IAdvertisementsTypeDetailInput
): Promise<IAdvertisementsTypeDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/advertisementstype/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add AdvertisementsType */
interface IAdvertisementsTypeAddInput {
  name: string | null;
  description: string | null;
  contentype: string | null;
  width: number | null;
  height: number | null;
  // deleted: string | null;
  // maxuploadsize: number | null;
  // devicetype: number | null;
  // rate: number | null;
  imageurl: string | null;
}

interface IAdvertisementsTypeAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addAdvertisementsType = async (
  payload: IAdvertisementsTypeAddInput
): Promise<IAdvertisementsTypeAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/advertisementstype`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit AdvertisementsType */
interface IAdvertisementsTypeEditInput {
  id: number | null;
  name: string | null;
  description: string | null;
  contentype: string | null;
  width: number | null;
  height: number | null;
  // deleted: string | null;
  // maxuploadsize: number | null;
  // devicetype: number | null;
  // rate: number | null;
  imageurl: string | null | undefined;
}

interface IAdvertisementsTypeEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editAdvertisementsType = async (
  payload: IAdvertisementsTypeEditInput
): Promise<IAdvertisementsTypeEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/advertisementstype/${payload.id}`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Delete AdvertisementsType */
interface IAdvertisementsTypeDeleteInput {
  id: number | null;
}

interface IAdvertisementsTypeDeleteOutput extends Types.ResponseOutput {
  data: {};
}

export const deleteAdvertisementsType = async (
  payload: IAdvertisementsTypeDeleteInput
): Promise<IAdvertisementsTypeDeleteOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/advertisementstype/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
