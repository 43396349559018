import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_ADVERTISEMENTSTYPE_LIST: string = typeCreatorV2(
  'AdvertisementsTypeReducer',
  'SetAdvertisementsTypeList'
);

export const SET_ADD_ADVERTISEMENTSTYPE: string = typeCreatorV2(
  'AdvertisementsTypeReducer',
  'SetAddAdvertisementsType'
);

export const SET_EDIT_ADVERTISEMENTSTYPE: string = typeCreatorV2(
  'AdvertisementsTypeReducer',
  'SetEditAdvertisementsType'
);

export const SET_ADVERTISEMENTSTYPE_DETAIL: string = typeCreatorV2(
  'AdvertisementsTypeReducer',
  'SetAdvertisementsTypeDetail'
);

export const SET_DELETE_ADVERTISEMENTSTYPE: string = typeCreatorV2(
  'AdvertisementsTypeReducer',
  'SetDeleteAdvertisementsType'
);
