import React from 'react';
import useStyles from '../../styles';
import { CampaignTabs, IView } from '../../tab_stepper';
import Translations from '../../translations';
import { Box, Button } from '@material-ui/core';
import { CampaignInfoSteps } from '../campaign_info_view/configuration';
import TranslatedText from 'components/atoms/translated_text';
import { PreviewSteps } from '../preview_view/configuration';

interface ITabActions {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
  handleSubmit?: { (): void };
  display?: number | null;
}

const TabActions = (props: ITabActions) => {
  const { view, handleNext, handlePrevious, display } = props;
  const classes = useStyles();

  return (
    <div className={classes.tabActionContainer}>
      <Box
        style={{ fontWeight: 'bold', fontSize: '1.25rem', marginRight: 'auto' }}
      >
        {view.tab === CampaignTabs.CAMPAIGN_INFO && (
          <TranslatedText textMap={Translations.campaign_info} />
        )}
        {view.tab === CampaignTabs.AUDIENCE && (
          <TranslatedText textMap={Translations.audience} />
        )}
        {view.tab === CampaignTabs.DESTINATION && (
          <TranslatedText textMap={Translations.destinations} />
        )}
        {view.tab === CampaignTabs.AD_DETAILS && (
          <TranslatedText textMap={Translations.ad_details} />
        )}
        {view.tab === CampaignTabs.PREVIEW && (
          <TranslatedText textMap={Translations.preview_tab} />
        )}
      </Box>
      {
        <Button
          className={classes.tabAction}
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            handlePrevious();
          }}
          // disabled={
          //   view.tab === CampaignTabs.CAMPAIGN_INFO &&
          //   view.step === CampaignInfoSteps.BASIC_INFO
          // }
          disabled={
            view.tab === CampaignTabs.CAMPAIGN_INFO &&
            view.step === CampaignInfoSteps.CAMPAIGN_INFO
          }
        >
          <TranslatedText textMap={Translations.previous} />
        </Button>
      }
      {(view.tab === CampaignTabs.PREVIEW &&
        view.step === PreviewSteps.SUBMIT) ||
      (display !== 2 &&
        view.tab === CampaignTabs.PREVIEW &&
        view.step === PreviewSteps.SUBMIT - 1) ? (
        <Button
          className={classes.tabAction}
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            if (props.handleSubmit) props.handleSubmit();
          }}
        >
          <TranslatedText textMap={Translations.submit} />
        </Button>
      ) : (
        <Button
          className={classes.tabAction}
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            handleNext();
          }}
        >
          <TranslatedText textMap={Translations.next} />
        </Button>
      )}
    </div>
  );
};

export default TabActions;
