import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
// import ProductActions from '../../store/actions';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useHistory, useParams } from 'react-router';
import NavigationConfig from 'navigation/config';
import { StateStatus } from 'redux/utils/common';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import {
  formDefaultValue,
  IFormData,
  TableDefaultFetchValues,
} from '../configuration';
import {
  useAddDSPStattus,
  useEditDSPStattus,
  useGetParalist,
  useGetUserByRole,
} from 'redux/reducers/dsp_reducer/selectors';

interface IAddEditDSP {
  data?: any;
}

const AddEditDSP = (props: IAddEditDSP) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const editDSPStattus = useEditDSPStattus();
  const addDSPStattus = useAddDSPStattus();
  const getUserByRole = useGetUserByRole();
  const getParalist = useGetParalist();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IFormData>({
    defaultValues: formDefaultValue,
  });

  const onSubmit = (data: IFormData) => {
    console.log('data', data);
    if (props?.data) {
      dispatch(
        DSPActions.editDSPAction({
          code: data.code ?? props?.data?.code,
          name: data.name,
          userid: data.userid,
          vasttype: data.vasttype,
          fetchDSPInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    } else {
      dispatch(
        DSPActions.addNewDSPAction({
          code: data.code ?? props?.data?.code,
          name: data.name,
          userid: data.userid,
          vasttype: data.vasttype,
          fetchDSPInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    }
  };
  useEffect(() => {
    if (props?.data) {
      reset({
        code: props?.data?.code ?? '',
        name: props?.data?.name ?? '',
        userid: props?.data?.userid ?? '',
        vasttype: props?.data?.vasttype ?? '',
      });
    }
  }, [props?.data]);
  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          {props?.data ? 'Edit' : 'Add new'} info
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          {props?.data
            ? 'Make modification and them hit save'
            : 'Fill information and save it'}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!props.data ? (
          <AppTextField
            variant="outlined"
            margin="dense"
            error={Boolean(_.get(errors, 'code', false))}
            helperText={_.get(errors, 'code', '')}
            rules={{
              required: 'This is required',
            }}
            fullWidth
            size="small"
            id={'code'}
            control={control}
            label={<TranslatedText defaultText={'Code'} />}
            name={'code'}
          />
        ) : null}
        <AppTextField
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'name', false))}
          helperText={_.get(errors, 'name', '')}
          rules={{
            required: 'This is required',
          }}
          fullWidth
          size="small"
          id={'name'}
          control={control}
          label={<TranslatedText defaultText={'Name'} />}
          autoComplete={'name'}
          name={'name'}
        />
        <AppSelect
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'userid', false))}
          // helperText={_.get(errors, 'userid', '')}
          rules={{
            required: 'This is required',
          }}
          options={
            getUserByRole?.data?.map((item: any) => ({
              id: item?.id,
              label: item?.email,
            })) ?? []
          }
          fullWidth
          size="small"
          id={'userid'}
          control={control}
          label={<TranslatedText defaultText={'User'} />}
          autoComplete={'userid'}
          name={'userid'}
        />
        <AppSelect
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'vasttype', false))}
          // helperText={_.get(errors, 'vasttype', '')}
          rules={{
            required: 'This is required',
          }}
          options={
            getParalist?.data?.items?.map((item) => ({
              id: item?.code,
              label: item?.descriptionn,
            })) ?? []
          }
          fullWidth
          size="small"
          id={'vasttype'}
          control={control}
          label={<TranslatedText defaultText={'Vast type'} />}
          autoComplete={'vasttype'}
          name={'vasttype'}
        />
        <Box display={'flex'} flexDirection={'column'} marginTop={'32px'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            style={{ borderRadius: '24px', marginBottom: '6px' }}
            type={'submit'}
            disabled={
              editDSPStattus === StateStatus.Pending ||
              addDSPStattus === StateStatus.Pending
            }
          >
            {editDSPStattus === StateStatus.Pending ||
            addDSPStattus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              `Save`
            )}
          </Button>
          <Button
            variant={'text'}
            color={'secondary'}
            onClick={() => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_EDIT_DSP,
                })
              );
            }}
          >
            {/* <TranslatedText textMap={Translations.cancel} /> */}
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

interface IAddEditDSPModalProps {}
const AddEditDSPModal = (props: IAddEditDSPModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_DSP}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAddEditDSP) => <AddEditDSP {...params} />}
    />
  );
};

export default AddEditDSPModal;
