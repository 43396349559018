import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import Translations from '../../translations';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { useSelectLanguageID } from 'redux/reducers/gl_translations_reducer/selectors';
import * as DateUtils from 'utils/dates';
import { CampaignPurposes } from '../campaign_info_purpose_step/configuration';
import { Languages } from 'config';
import * as CategoryService from 'services/api/category';

interface IFormData {
  campaign: string | null;
  customer: string | null;
  budget: string | null;
  start_date: string | null;
  end_date: string | null;
  purpose: string | null;
  category: string | null;
}

interface IProps {
  campaign: ICampaignInfoWizard;
}
const PreviewCampaignInfoForm = (props: IProps) => {
  const { campaign } = props;
  const lan = useSelectLanguageID();
  const classes = useStyles();

  const [categories, setCategories] = useState<[]>([]);
  const [categorySelected, setCategorySelected] = useState<any | undefined>({});

  React.useEffect(() => {
    CategoryService.getCategory().then((result) => {
      setCategories(result.data);
    });
  }, [setCategories]);

  React.useEffect(() => {
    setCategorySelected(categories.find((category: any) => category.id === 1));
  }, [categories, setCategorySelected]);

  let purposeObj = CampaignPurposes.find(
    (purpose) => purpose.code === campaign.purpose
  );

  let defaultValues = {
    campaign: campaign.campaign,
    customer: campaign.customer ?? null,
    budget: campaign.budget ? `${campaign.budget}` : null,
    start_date: campaign.start_date
      ? DateUtils.formatDate(`${campaign.start_date}`)
      : null,
    end_date: campaign.end_date
      ? DateUtils.formatDate(`${campaign.end_date}`)
      : null,
    purpose: purposeObj ? purposeObj.name[lan] : null,
    category: null,
  };
  const { errors, control, reset, setValue } = useForm<IFormData>({
    defaultValues,
  });

  React.useEffect(() => {
    categorySelected?.name && setValue('category', categorySelected.name);
  }, [categorySelected, setValue]);

  return (
    <form className={classes.form} noValidate>
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'campaign',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'campaign' in errors}
                helperText={errors.campaign && errors.campaign.message}
                rules={{}}
                fullWidth
                id="campaign"
                control={control}
                label={
                  <TranslatedText
                    textMap={Translations.campaign_name_control}
                  />
                }
                autoComplete="campaign"
                name="campaign"
              />
            ),
            size: 6,
          },
          // {
          //   id: 'customer',
          //   element: (
          //     <AppTextField
          //       disabled={true}
          //       variant="outlined"
          //       margin="normal"
          //       error={'customer' in errors}
          //       helperText={errors.customer && errors.customer.message}
          //       rules={{}}
          //       fullWidth
          //       id="customer"
          //       control={control}
          //       label={
          //         <TranslatedText
          //           textMap={Translations.customer_name_control}
          //         />
          //       }
          //       autoComplete="customer"
          //       name="customer"
          //     />
          //   ),
          //   size: 6,
          // },
          {
            id: 'budget',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'budget' in errors}
                helperText={errors.budget && errors.budget.message}
                rules={{}}
                fullWidth
                id="budget"
                control={control}
                label={<TranslatedText textMap={Translations.budget_control} />}
                autoComplete="budget"
                name="budget"
              />
            ),
            size: 6,
          },
          {
            id: 'start_date',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'start_date' in errors}
                helperText={errors.start_date && errors.start_date.message}
                rules={{}}
                fullWidth
                id="start_date"
                control={control}
                label={
                  <TranslatedText textMap={Translations.start_date_control} />
                }
                autoComplete="start_date"
                name="start_date"
              />
            ),
            size: 6,
          },
          {
            id: 'end_date',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'end_date' in errors}
                helperText={errors.end_date && errors.end_date.message}
                rules={{}}
                fullWidth
                id="end_date"
                control={control}
                label={
                  <TranslatedText textMap={Translations.end_date_control} />
                }
                autoComplete="end_date"
                name="end_date"
              />
            ),
            size: 6,
          },
          // {
          //   id: 'purpose',
          //   element: (
          //     <AppTextField
          //       disabled={true}
          //       variant="outlined"
          //       margin="normal"
          //       error={'purpose' in errors}
          //       helperText={errors.purpose && errors.purpose.message}
          //       rules={{}}
          //       fullWidth
          //       id="purpose"
          //       control={control}
          //       label={
          //         <TranslatedText textMap={Translations.purpose_control} />
          //       }
          //       autoComplete="purpose"
          //       name="purpose"
          //     />
          //   ),
          //   size: 12,
          // },
          {
            id: 'category',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'category' in errors}
                helperText={errors.category && errors.category.message}
                rules={{}}
                fullWidth
                id="category"
                control={control}
                label={
                  <TranslatedText textMap={Translations.category_control} />
                }
                autoComplete="category"
                name="category"
              />
            ),
            size: 12,
          },
        ]}
      />
    </form>
  );
};

export default PreviewCampaignInfoForm;
