export enum MonthsEnum {
  January = '0',
  February = '1',
  March = '2',
  April = '3',
  May = '4',
  June = '5',
  July = '6',
  August = '7',
  September = '8',
  October = '9',
  November = '10',
  December = '11',
}
const Months = [
  {
    id: MonthsEnum.January,
    label: 'January',
  },
  {
    id: MonthsEnum.February,
    label: 'February',
  },
  {
    id: MonthsEnum.March,
    label: 'March',
  },
  {
    id: MonthsEnum.April,
    label: 'April',
  },
  {
    id: MonthsEnum.May,
    label: 'May',
  },
  {
    id: MonthsEnum.June,
    label: 'June',
  },
  {
    id: MonthsEnum.July,
    label: 'July',
  },
  {
    id: MonthsEnum.August,
    label: 'August',
  },
  {
    id: MonthsEnum.September,
    label: 'September',
  },
  {
    id: MonthsEnum.October,
    label: 'October',
  },
  {
    id: MonthsEnum.November,
    label: 'November',
  },
  {
    id: MonthsEnum.December,
    label: 'December',
  },
];

export default Months;
