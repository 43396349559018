import { LanguagesEnum } from '../../config/languages';

const Translations = {
  recover_title: {
    [LanguagesEnum.English]: 'Recover your password',
    [LanguagesEnum.Greek]: 'Ανάκτηση κωδικού πρόσβασης σας',
    [LanguagesEnum.Russian]: 'Восстановите свой пароль',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός πρόσβασης',
    [LanguagesEnum.Russian]: 'Пароль',
  },
  form_field_confirm_password: {
    [LanguagesEnum.English]: 'Confirm password',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Κωδικού',
    [LanguagesEnum.Russian]: 'Подтвердить Пароль',
  },
  form_button_recover: {
    [LanguagesEnum.English]: 'Reset password',
    [LanguagesEnum.Greek]: 'Επαναφέρετε τον κωδικό πρόσβασης',
    [LanguagesEnum.Russian]: 'Сброс пароля',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Αυτό είναι απαραίτητο',
    [LanguagesEnum.Russian]: 'Это требуется',
  },
  form_error_match: {
    [LanguagesEnum.English]: 'Confirm password should much password',
    [LanguagesEnum.Greek]:
      'Επιβεβαίωση κωδικού πρόσβασης θα πρέπει πολύ κωδικό',
    [LanguagesEnum.Russian]: 'Подтверждение пароля должны много пароля',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στην σελίδα εισόδου',
    [LanguagesEnum.Russian]: 'Вернуться на страницу авторизации',
  },
};

export default Translations;
