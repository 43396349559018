import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';

function getStatisticCardConfiguration(data: any) {
  const StatisticCardsConfiguration: IGridConf[] = [
    {
      width: 4,
      type: ComponentTypeEnum.statistic,
      props: {
        title: 'Views',
        description: data['totalviews'] ? data['totalviews'] : 0,
        backgroundColor: 'rgba(240, 30, 2, .5)',
        titleColor: 'white',
        descriptionColor: 'white',
        containerStyle: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        descriptionStyle: {
          fontSize: '2rem',
          marginTop: '0.2rem',
        },
      },
      fetch: null,
    },
    {
      width: 4,
      type: ComponentTypeEnum.statistic,
      props: {
        title: 'Clicks',
        description: data['totalclicks'] ? data['totalclicks'] : 0,
        backgroundColor: 'rgba(66, 135, 245, .5)',
        titleColor: 'white',
        descriptionColor: 'white',
        containerStyle: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        descriptionStyle: {
          fontSize: '2rem',
          marginTop: '0.2rem',
        },
      },
      fetch: null,
    },
    {
      width: 4,
      type: ComponentTypeEnum.statistic,
      props: {
        title: 'Amount',
        description: data['amount'] ? data['amount'].toFixed(2) : 0,
        backgroundColor: 'rgba(8, 199, 30, .5)',
        titleColor: 'white',
        descriptionColor: 'white',
        containerStyle: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        descriptionStyle: {
          fontSize: '2rem',
          marginTop: '0.2rem',
        },
      },
      fetch: null,
    },
  ];
  return StatisticCardsConfiguration;
}

export default getStatisticCardConfiguration;
