import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import TranslatedText from '../../components/atoms/translated_text';
import Translations from './translations';

function ActionsBlock(props: any) {
  const history = useHistory();
  return (
    <InfoOutlined
      onClick={() => history.push(`/application-detail/${props.dataid}`)}
    />
  );
}

export const TableColumns = [
  {
    label: 'ID',
    id: 'ID',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: <TranslatedText textMap={Translations.name_column} />,
    id: 'Name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.description_column} />,
    id: 'Description',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex].UniqueKey}
      />
    ),
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
