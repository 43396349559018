import { useSelector } from 'react-redux';
import { ReducerKeys } from 'redux/config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import { StateStatus } from 'redux/utils/common';

/* selector: useGetSchemeTypes */

interface ISChemeTypesData {
  applicant_type_id: number;
  code: 'EU' | 'NON_EU' | 'IOSS';
  description: string;
}

interface ISChemeTypesOutput {
  status: StateStatus;
  data: ISChemeTypesData[] | null;
}

export const useGetSchemeTypes = (): ISChemeTypesOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REGISTER_REDUCER].OSSSchemesList
  );
  return { status, data };
};

/* selector: useGetCountries */

interface ICountriessData {
  code: string;
  code3: string;
  name: string;
  number: string;
}

interface ICountriesOutput {
  status: StateStatus;
  data: ICountriessData[] | null;
}

export const useGetCountries = (): ICountriesOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.REGISTER_REDUCER].countriesList
  );
  return { status, data };
};

/* selector: useGetRegisterStatus */

export const useGetRegisterStatus = (): StateStatus => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.REGISTER_REDUCER].registerUserStatus
  );
  return status;
};
