import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType1 } from '../configuration';

export interface IBarChartOption {
  data: any[];
  labels: any[];
  title: string;
  id: string;
}

function getBarChartConfig(params: IBarChartOption) {
  const { data, labels, title } = params;
  console.log('data', data);
  console.log('labels', data);
  var optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-data.length / 3));

  const LineArea: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'bar',
        height: 350,
        series: [
          {
            name: title,
            data: data,
          },
        ],
        options: {
          chart: {
            id: params.id,
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              columnWidth: optimalColumnWidthPercent + '%',
            },
          },
          colors: ['#439CDD'],
          dataLabels: {
            enabled: true,
            formatter: function (val: number) {
              return `${val}`;
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },

          xaxis: {
            categories: labels,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val: number) {
                return val;
              },
            },
          },
          title: {
            text: title,
            // floating: true,
            // offsetY: 330,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        },
      },
      fetch: null,
    },
  ];
  return LineArea;
}

// function getBarChartConfig(params: IBarChartOption) {
//   const { data, labels, title } = params;
//   const DashboardBuilderConfigClickDestination: IGridConf[] = [
//     {
//       width: 12,
//       type: ComponentTypeEnum.chart,
//       // height: 300,
//       props: {
//         data: [
//           {
//             data,
//             label: title,
//             format: {
//               label: title,
//               backgroundColor: '#439CDD',
//             },
//           },
//         ],
//         labels,
//         chartType: ChartTypesEnum.COLUMN,
//         options: OptionsGraphType1(100),
//         title: '',
//       },
//       fetch: null,
//     },
//   ];
//   return DashboardBuilderConfigClickDestination;
// }
export default getBarChartConfig;
