import React from 'react';
import { Languages } from 'config';
import { ImMan, ImWoman } from 'react-icons/im';
import Translations from '../../translations';

export interface IGender {
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  image: React.ReactElement;
  code: string;
}

export const CampaignGenders: IGender[] = [
  {
    name: Translations.male_gender,
    image: <ImMan />,
    code: 'MALE',
  },
  {
    name: Translations.female_gender,
    image: <ImWoman />,
    code: 'FEMALE',
  },
];
