import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USER_PRICE_CALCULATION_LIST: string = typeCreatorV2(
  'User_Price_CalculationReducer',
  'SetUser_Price_CalculationList'
);

export const SET_ADD_USER_PRICE_CALCULATION: string = typeCreatorV2(
  'User_Price_CalculationReducer',
  'SetAddUser_Price_Calculation'
);

export const SET_EDIT_USER_PRICE_CALCULATION: string = typeCreatorV2(
  'User_Price_CalculationReducer',
  'SetEditUser_Price_Calculation'
);

export const SET_USER_PRICE_CALCULATION_DETAIL: string = typeCreatorV2(
  'User_Price_CalculationReducer',
  'SetUser_Price_CalculationDetail'
);

export const SET_DELETE_USER_PRICE_CALCULATION: string = typeCreatorV2(
  'User_Price_CalculationReducer',
  'Set_DELETE_Price_Calculation'
);
