
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  Users_advertisers_administrationList: {
    status: StateStatus | null;
    data: any;
  };
  addUsers_advertisers_administration: {
    status: StateStatus | null;
  };
  editUsers_advertisers_administration: {
    status: StateStatus | null;
  };
  viewUsers_advertisers_administration: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  Users_advertisers_administrationList: {
    status: null,
    data: null,
  },
  addUsers_advertisers_administration: {
    status: null,
  },
  editUsers_advertisers_administration: {
    status: null,
  },
  viewUsers_advertisers_administration: {
    status: null,
    data: null,
  },
};

const Users_advertisers_administrationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USERS_ADVERTISERS_ADMINISTRATION_LIST:
      return {
        ...state,
        Users_advertisers_administrationList: {
          ...state.Users_advertisers_administrationList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.Users_advertisers_administrationList.data
              : action.data,
        },
      };
    case Types.SET_ADD_USERS_ADVERTISERS_ADMINISTRATION:
      return {
        ...state,
        addUsers_advertisers_administration: {
          ...state.addUsers_advertisers_administration,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_USERS_ADVERTISERS_ADMINISTRATION:
      return {
        ...state,
        editUsers_advertisers_administration: {
          ...state.editUsers_advertisers_administration,
          status: action.status || null,
        },
      };
    case Types.SET_USERS_ADVERTISERS_ADMINISTRATION_DETAIL:
      return {
        ...state,
        viewUsers_advertisers_administration: {
          ...state.viewUsers_advertisers_administration,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewUsers_advertisers_administration.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default Users_advertisers_administrationReducer;


