import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  airportsList: {
    status: StateStatus | null;
    data: any;
  };
  addAirports: {
    status: StateStatus | null;
  };
  editAirports: {
    status: StateStatus | null;
  };
  viewAirports: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  airportsList: {
    status: null,
    data: null,
  },
  addAirports: {
    status: null,
  },
  editAirports: {
    status: null,
  },
  viewAirports: {
    status: null,
    data: null,
  },
};

const AirportsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_AIRPORTS_LIST:
      return {
        ...state,
        airportsList: {
          ...state.airportsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.airportsList.data
              : action.data,
        },
      };
    case Types.SET_ADD_AIRPORTS:
      return {
        ...state,
        addAirports: {
          ...state.addAirports,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_AIRPORTS:
      return {
        ...state,
        editAirports: {
          ...state.editAirports,
          status: action.status || null,
        },
      };
    case Types.SET_AIRPORTS_DETAIL:
      return {
        ...state,
        viewAirports: {
          ...state.viewAirports,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewAirports.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AirportsReducer;
