import { Languages } from '../../config';

const Translations = {
  status_normal: {
    [Languages.English]: 'Normal',
    [Languages.Greek]: 'Κανονικός',
    [Languages.Russian]: 'Обычный',
  },
  status_pending: {
    [Languages.English]: 'Pending',
    [Languages.Greek]: 'εκκρεμής',
    [Languages.Russian]: 'В ожидании',
  },
  status_expired: {
    [Languages.English]: 'Expired',
    [Languages.Greek]: 'έχει λήξει',
    [Languages.Russian]: 'Истекший',
  },
  status_deleted: {
    [Languages.English]: 'Deleted',
    [Languages.Greek]: 'Διαγράφεται',
    [Languages.Russian]: 'Исключен',
  },
  status_finished: {
    [Languages.English]: 'Finished',
    [Languages.Greek]: 'Πεπερασμένος',
    [Languages.Russian]: 'Законченный',
  },
  status_insufficient: {
    [Languages.English]: 'Insufficient fund',
    [Languages.Greek]: 'Ανεπαρκής ταμείο',
    [Languages.Russian]: 'Недостаточно средств',
  },
  advertiser_category: {
    [Languages.English]: 'Categories',
    [Languages.Greek]: 'Κατηγορίες',
    [Languages.Russian]: 'категории',
  },
  select_title: {
    [Languages.English]: 'Categories:',
    [Languages.Greek]: 'Κατηγορίες:',
    [Languages.Russian]: 'Категории:',
  },
};

export default Translations;
