import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  transport: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px - 36px - 1.75rem - 36.5px - 48px - 8px)',
    maxHeight: 'calc(100vh - 64px - 36px - 1.75rem - 36.5px - 48px - 8px)',
  },
  transportList: {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'relative',
    padding: 30,
    paddingTop: 15,
    width: '60%',
    height: '100%',
    overflowY: 'auto',
  },
  map: {
    position: 'sticky',
    top: 64,
    width: '100%',
    height: '100%',
    // height: 'calc(100vh - 64px - 18px - 0.75rem - 36.5px - 48px)',
    // maxHeight: 'calc(100vh - 64px - 18px - 0.75rem - 36.5px - 48px)',
  },
  backgroundUnset: {
    background: 'unset',
  },
  hoverTransportStation: {
    // cursor: 'pointer',
    '&:hover': {
      '&>div': {
        backgroundColor: '#f2f2f2',
      },
    },
  },
  stationMarker: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: '5px 10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      fontWeight: 'bold',
      letterSpacing: 1,
    },
  },
  selectedMarker: {
    backgroundColor: '#0047e9 !important',
    color: '#fff !important',
  },
  stationPin: {
    backgroundColor: 'transparent !important',
    '&>svg': {
      webkitFilter: 'drop-shadow( 5px 1px 3px rgba(0, 0, 0, .4))',
      filter: 'drop-shadow( 5px 1px 3px rgba(0, 0, 0, .4))',
    },
  },
  selectedPin: {
    '&>svg': {
      '&>path': {
        fill: 'red !important',
      },
    },
  },
  form: {
    width: '100%',
  },
}));

export default useStyles;
