import React from 'react';
import Datatable from '../../../components/molecules/datatable';
import { TableColumns } from '../configuration';
import { useDispatch } from 'react-redux';
import Actions from '../../../redux/reducers/wallet_reducer/actions';
import { useGetWallet } from '../../../redux/reducers/wallet_reducer/selectors';
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';
import { Box, Button } from '@material-ui/core';

export default function DatatableList() {
  const result = useGetWallet();
  const paging = result.data?.history?.paging;
  const dispatch = useDispatch();
  const { id } = useSelectUserData();

  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');

  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={result.data?.history?.items || []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            Actions.fetchWalletAction({
              id,
              page: selectedPage,
              pageSize: pageSize,
              sortField: _sortField,
              order,
              search: {
                isSearch: false,
                values: {
                  campaign: null,
                  clientname: null,
                  startdate: null,
                  enddate: null,
                },
              },
            })
          );
        }}
      />
    </Box>
  );
}
