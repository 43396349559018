import { Button } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import useStyles from './styles';
import NavigationConfig from '../../../../navigation/config';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../../translations';

interface IProps {
  rowIndex: number;
  data: string;
  dataid: string | number;
}

export default function ActionsBlock(props: IProps) {
  const history = useHistory();
  const classes = useStyles();
  const display = _.get(props.data[props.rowIndex], 'display', 'inflight');

  const handleClick = () => {
    history.push(NavigationConfig.campaignPage(`${props.dataid}`).path);
  };
  const handleView = () => {
    history.push(
      `${
        display === 'Inflight'
          ? NavigationConfig.advReportingInflightPage().path
          : NavigationConfig.advReportingOnlinePage().path
      }/?campaignid=${props.dataid}&range=`
    );
  };

  return (
    <div>
      <Button className={classes.editButton} onClick={() => handleClick()}>
        <TranslatedText textMap={Translations.table_action_edit} />
      </Button>
      <Button className={classes.editButton} onClick={() => handleView()}>
        <TranslatedText textMap={Translations.table_action_view} />
      </Button>
    </div>
  );
}
