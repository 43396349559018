import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_COUNTRIESRATE_LIST: string = typeCreatorV2(
  'CountriesRateReducer',
  'SetCountriesRateList'
);

export const SET_ADD_COUNTRIESRATE: string = typeCreatorV2(
  'CountriesRateReducer',
  'SetAddCountriesRate'
);

export const SET_EDIT_COUNTRIESRATE: string = typeCreatorV2(
  'CountriesRateReducer',
  'SetEditCountriesRate'
);

export const SET_COUNTRIESRATE_DETAIL: string = typeCreatorV2(
  'CountriesRateReducer',
  'SetCountriesRateDetail'
);

export const SET_DELETE_COUNTRIESRATE: string = typeCreatorV2(
  'CountriesRateReducer',
  'SetDeleteCountriesRate'
);
