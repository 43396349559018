import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  CARD_BACKGROUND,
  CARD_HEADER_BACKGROUND,
  CARD_DIVIDER,
} from 'styles/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '.5rem',
      background: CARD_BACKGROUND(theme),
      border: `1px solid ${CARD_DIVIDER(theme)}`,
      // marginTop: '1rem',
    },
    cardHeader: {
      padding: '.75rem',
      background: `${CARD_HEADER_BACKGROUND(theme)}`,
      borderTopRightRadius: '.5rem',
      borderTopLeftRadius: '.5rem',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      borderBottom: `1px solid ${CARD_DIVIDER(theme)}`,
    },
    cardBody: {
      padding: '.75rem',
    },
  })
);

export default useStyles;
