import React, { Fragment, ReactElement } from 'react';
import useStyles from './styles';

interface IProps {
  header?: ReactElement;
  body: ReactElement;
  headerStyling?: React.CSSProperties;
  bodyStyling?: React.CSSProperties;
  cardStyling?: React.CSSProperties;
}
export default function CardLayout(props: IProps) {
  const classes = useStyles();
  return (
    <div style={{ ...props.cardStyling }} className={classes.card}>
      {props.header && (
        <div style={{ ...props.headerStyling }} className={classes.cardHeader}>
          {props.header}
        </div>
      )}
      <div style={{ ...props.bodyStyling }} className={classes.cardBody}>
        {props.body}
      </div>
    </div>
  );
}
