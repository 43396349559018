import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as UsersService from '../../../services/api/user';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchUsersListInput  */

interface FetchUsersListInput {
  roleName: string;
}

class FetchUsersList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUsersListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UsersService.getUsersbyRoleName(payload.roleName);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcUsersList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewUsers  */

interface AddNewUsersInput {
  id: string | null;
  firstname: string | null;
  lastname: string | null;
  companyname: string | null;
  email: string | null;
  password: string | null;
  passwordsalt: string | null;
  afiliateid: string | null;
  active: string | null;
  deleted: string | null;
  lastlogindate: string | null;
  creationdate: string | null;
  airlineids: string | null;
  requirepasswordchange: string | null;
  passwordlastchangedon: string | null;
  lastmodifiedon: string | null;
  lastmodifiedby: string | null;
  corporate: number | null;
  address: string | null;
  country: string | null;
  vat: string | null;
  phone: string | null;
  advertiserpersentage: number | null;
  advertiserfixcpv: number | null;
  fetchUsersInput: FetchUsersListInput;
}

class AddNewUsers implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_USERS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_USERS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_USERS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewUsersInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUsersInput, ...rest } = payload;

        dispatch(this.onPending());
        // let response = await UsersService.addUser(rest);
        // dispatch(this.onSuccess(response.data));
        dispatch(new FetchUsersList().action(fetchUsersInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USER,
          })
        );
      } catch (error) {
        console.log('AddNewUsers Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Users  */

interface EditUsersInput {
  id: string | null;
  firstname: string | null;
  lastname: string | null;
  companyname: string | null;
  email: string | null;
  password: string | null;
  passwordsalt: string | null;
  afiliateid: string | null;
  active: string | null;
  deleted: string | null;
  lastlogindate: string | null;
  creationdate: string | null;
  airlineids: string | null;
  requirepasswordchange: string | null;
  passwordlastchangedon: string | null;
  lastmodifiedon: string | null;
  lastmodifiedby: string | null;
  corporate: number | null;
  address: string | null;
  country: string | null;
  vat: string | null;
  phone: string | null;
  advertiserpersentage: number | null;
  advertiserfixcpv: number | null;
  fetchUsersInput: FetchUsersListInput;
}

class EditUsers implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_USERS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }

  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_USERS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_USERS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditUsersInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUsersInput, ...rest } = payload;

        dispatch(this.onPending());
        // let response = await UsersService.editUser(payload);
        // dispatch(this.onSuccess(response.data));
        dispatch(new FetchUsersList().action(fetchUsersInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USER,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Users  */

interface ViewUsersInput {
  id: number;
}

class ViewUsers implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERS_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewUsersInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UsersService.fetchUserDetail(payload.id);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewUsers Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchUsersListAction: (payload: FetchUsersListInput) =>
    new FetchUsersList().action(payload),
  addNewUsersAction: (payload: AddNewUsersInput) =>
    new AddNewUsers().action(payload),
  editUsersAction: (payload: EditUsersInput) => new EditUsers().action(payload),
  viewUsersAction: (payload: ViewUsersInput) => new ViewUsers().action(payload),
};
