import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  advertisementsTypeList: {
    status: StateStatus | null;
    data: any;
  };
  addAdvertisementsType: {
    status: StateStatus | null;
  };
  editAdvertisementsType: {
    status: StateStatus | null;
  };
  viewAdvertisementsType: {
    status: StateStatus | null;
    data: any;
  };
  deleteAdvertisementsType: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  advertisementsTypeList: {
    status: null,
    data: null,
  },
  addAdvertisementsType: {
    status: null,
  },
  editAdvertisementsType: {
    status: null,
  },
  viewAdvertisementsType: {
    status: null,
    data: null,
  },
  deleteAdvertisementsType: {
    status: null,
  },
};

const AdvertisementsTypeReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_ADVERTISEMENTSTYPE_LIST:
      return {
        ...state,
        advertisementsTypeList: {
          ...state.advertisementsTypeList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.advertisementsTypeList.data
              : action.data,
        },
      };
    case Types.SET_ADD_ADVERTISEMENTSTYPE:
      return {
        ...state,
        addAdvertisementsType: {
          ...state.addAdvertisementsType,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_ADVERTISEMENTSTYPE:
      return {
        ...state,
        editAdvertisementsType: {
          ...state.editAdvertisementsType,
          status: action.status || null,
        },
      };
    case Types.SET_ADVERTISEMENTSTYPE_DETAIL:
      return {
        ...state,
        viewAdvertisementsType: {
          ...state.viewAdvertisementsType,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewAdvertisementsType.data
              : action.data,
        },
      };
    case Types.SET_DELETE_ADVERTISEMENTSTYPE:
      return {
        ...state,
        deleteAdvertisementsType: {
          ...state.deleteAdvertisementsType,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AdvertisementsTypeReducer;
