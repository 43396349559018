import React from 'react';
import Datatable from '../../../../components/molecules/datatable';
import { TableColumns } from '../../configuration';
import ActionsBlock from './ActionsBlock';
import * as User_Price_CalculationReducer from '../../../../redux/reducers/user_price_calculation_reducer/selectors';
import { useDispatch } from 'react-redux';
import User_Price_CalculationActions from '../../../../redux/reducers/user_price_calculation_reducer/actions';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';
import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import { StateStatus } from 'redux/utils/common';
import { useGetAdvertisementsTypeList } from 'redux/reducers/advertisementstype_reducer/selectors';
import { useParams } from 'react-router';
import _ from 'lodash';

export default function DatatableList() {
  const result = User_Price_CalculationReducer.useGetUser_Price_CalculationList();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();
  const { data: dataAdvertisementsTypeList } = useGetAdvertisementsTypeList();
  const params = useParams();
  const userid = _.get(params, 'id', null);
  console.log('dataList', result.data?.items);

  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={result.status == StateStatus.Pending}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={
          result.data?.items?.map((item) => ({
            ...item,
            Advertisment_type_name: dataAdvertisementsTypeList?.items?.find(
              (val) => val?.ID === item?.Advertisment_type
            )?.Name,
          })) || []
        }
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            User_Price_CalculationActions.fetchUser_Price_CalculationListAction(
              {
                sortField: _sortField,
                page: selectedPage,
                pageSize: pageSize,
                order: order,
                User_id: parseInt(userid),
              }
            )
          );
        }}
      />
    </Box>
  );
}

export { ActionsBlock };
