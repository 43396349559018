import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { Card, CardContent, TablePagination, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import DashboardBuilder from '../../../components/compounds/dashboard_builder';
import GridLayout from '../../../components/molecules/grid_layout';
import getRevenueConfig from '../configuration/RevenueConfig';
import getBarChartConfig from '../configuration/BarChartConfig';
import getClickImpressionLineConfig from '../configuration/ClickImpressionLineConfig';
import Widget2 from './widget2'
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';
import {
  useGetReportingCampaignsDetail,
  useGetReportingCampaignsWithoutPaging,
  useGetPagination,
} from '../../../redux/reducers/reporting_reducer/selectors';
import Actions from '../../../redux/reducers/reporting_reducer/actions';
import Progress from './Progress';
import Map from './Map';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
interface IOptions {
  data: any[];
  labels: any[];
  title: string;
}

export default function Analytics() {
  const { id } = useSelectUserData();
  const dispatch = useDispatch();
  const classes = useStyles();
  const reportingCampaignDetail = useGetReportingCampaignsWithoutPaging();


  const [clicks, setClicks] = useState(0)
  const [views, setViews] = useState(0)
  const [ctr, setCTR] = useState(0)


  const [impressionCountry, setImpressionCountry] = useState<IOptions | null>(
    null
  );
  const [impressionType, setImpressionType] = useState<IOptions | null>(null);

  const [clickCountry, setClickCountry] = useState<IOptions | null>(null);
  const [clickType, setClickTyoe] = useState<IOptions | null>(null);

  const [progressClicks, setProgressClicks] = useState<any>([]);
  const [progressViews, setProgressViews] = useState<any>([]);

  const [areaChartData, setAreaChartData] = useState<IOptions | null>(null);

  const [barCharts, setBarCharts] = useState<any>([]);
  const [areaCharts, setAreaCharts] = useState<any>([]);
  const [lineCharts, setLineCharts] = useState<any>([]);

  const [
    impressionClickLine,
    setImpressionClickLine,
  ] = useState<IOptions | null>(null);

  function sum(arr: any[], field: string) {
    return arr
      .map((item: any) => item[field])
      .reduce((a: number, b: number) => {
        return a + b;
      });
  }
  useEffect(() => {
    if (reportingCampaignDetail.status === 'Success') {
      setAreaChartData({
        data: reportingCampaignDetail.data.items.map(
          (item: any) => item.totalviews
        ),
        labels: reportingCampaignDetail.data.items.map(
          (item: any) => item.destcountry
        ),
        title: 'Total Impression',
      });
      setImpressionClickLine({
        title: 'Impressions/Click per date',
        labels: reportingCampaignDetail.data.items.map(
          (item: any) => moment(item.date).format('DD-MM-YYYY')
        ),
        data: [
          {
            name: 'Impressions',
            data: reportingCampaignDetail.data.items.map(
              (item: any) => item.totalviews
            ),
          },
          {
            name: 'Clicks',
            data: reportingCampaignDetail.data.items.map(
              (item: any) => item.totalclicks
            ),
          },
        ],
      });
    }
  }, [reportingCampaignDetail]);

  useEffect(() => {
    if (impressionClickLine) {
      setLineCharts([{
        id: 'click impression',
        size: 12,
        element: (
          <DashboardBuilder
            configuration={getClickImpressionLineConfig({
              data: impressionClickLine?.data,
              labels: impressionClickLine?.labels,
              title: impressionClickLine?.title,
            })}
          />
        ),
      }]);
    }
  }, [impressionClickLine]);

  useEffect(() => {
    if (
      reportingCampaignDetail.status === 'Success' &&
      reportingCampaignDetail.data
    ) {
      const reports = reportingCampaignDetail.data.items;
      const impressionGroupByDestCountry = _.mapValues(
        _.groupBy(reports, 'destcountry'),
        (clist) => clist.map((item: any) => _.omit(item, 'destcountry'))
      );
      for (const property in impressionGroupByDestCountry) {
        impressionGroupByDestCountry[property] = sum(
          impressionGroupByDestCountry[property],
          'totalviews'
        );
      }
      setImpressionCountry({
        data: Object.values(impressionGroupByDestCountry),
        labels: Object.keys(impressionGroupByDestCountry),
        title: 'Impression Per Country',
      });

      const impressionGroupByAdvertisementType = _.mapValues(
        _.groupBy(reports, 'advertisementtype'),
        (clist) => clist.map((item: any) => _.omit(item, 'advertisementtype'))
      );
      for (const property in impressionGroupByAdvertisementType) {
        impressionGroupByAdvertisementType[property] = sum(
          impressionGroupByAdvertisementType[property],
          'totalviews'
        );
      }
      setImpressionType({
        data: Object.values(impressionGroupByAdvertisementType),
        labels: Object.keys(impressionGroupByAdvertisementType),
        title: 'Impression Per Type',
      });

      const clickGroupByDestCountry = _.mapValues(
        _.groupBy(reports, 'destcountry'),
        (clist) => clist.map((item: any) => _.omit(item, 'destcountry'))
      );
      for (const property in clickGroupByDestCountry) {
        clickGroupByDestCountry[property] = sum(
          clickGroupByDestCountry[property],
          'totalclicks'
        );
      }
      setClickCountry({
        data: Object.values(clickGroupByDestCountry),
        labels: Object.keys(clickGroupByDestCountry),
        title: 'Click Per Country',
      });

      const clickGroupByAdvertisementType = _.mapValues(
        _.groupBy(reports, 'advertisementtype'),
        (clist) => clist.map((item: any) => _.omit(item, 'advertisementtype'))
      );
      for (const property in clickGroupByAdvertisementType) {
        clickGroupByAdvertisementType[property] = sum(
          clickGroupByAdvertisementType[property],
          'totalclicks'
        );
      }
      setClickTyoe({
        data: Object.values(clickGroupByAdvertisementType),
        labels: Object.keys(clickGroupByAdvertisementType),
        title: 'Click Per Type',
      });
    }
  }, [reportingCampaignDetail]);

  useEffect(() => {
    if (reportingCampaignDetail.status === 'Success') {
      const reports = reportingCampaignDetail.data.items;
      const clickGroupByCountry = _.mapValues(
        _.groupBy(reports, 'country'),
        (clist) => clist.map((item: any) => _.omit(item, 'country'))
      );
      const clicksCountry = [];
      const viewsCountry = [];

      for (const property in clickGroupByCountry) {
        clicksCountry.push({
          country: property,
          totalclicks: sum(clickGroupByCountry[property], 'totalclicks'),
        });
        viewsCountry.push({
          country: property,
          totalviews: sum(clickGroupByCountry[property], 'totalviews'),
        });
      }
      const maxClicks = Math.max(
        ...clicksCountry.map((item: any) => {
          return item.totalclicks;
        })
      );
      const maxViews = Math.max(
        ...viewsCountry.map((item: any) => {
          return item.totalviews;
        })
      );
      setProgressClicks(
        clicksCountry
          .sort((a: any, b: any) => {
            return b.totalclicks - a.totalclicks;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalclicks / (maxClicks + 10)).toFixed(2)
            );

            return {
              percentage: percent * 100,
              value: item.totalclicks,
              name: item.country,
            };
          })
      );
      setProgressViews(
        viewsCountry
          .sort((a: any, b: any) => {
            return b.totalviews - a.totalviews;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalviews / (maxViews + 10)).toFixed(2)
            );
            return {
              percentage: percent * 100,
              value: item.totalviews,
              name: item.country,
            };
          })
      );
    }
  }, [reportingCampaignDetail]);

  useEffect(() => {
    if (impressionCountry && impressionType && clickCountry && clickType) {
      setBarCharts([
        {
          id: impressionCountry?.title || '',
          size: 3,
          element: (
            <DashboardBuilder
              configuration={getBarChartConfig({
                data: impressionCountry?.data,
                labels: impressionCountry?.labels,
                title: impressionCountry?.title,
              })}
            />
          ),
        },
        {
          id: impressionType?.title || '',
          size: 3,
          element: (
            <DashboardBuilder
              configuration={getBarChartConfig({
                data: impressionType?.data,
                labels: impressionType?.labels,
                title: impressionType?.title,
              })}
            />
          ),
        },
        {
          id: clickCountry?.title || '',
          size: 3,
          element: (
            <DashboardBuilder
              configuration={getBarChartConfig({
                data: clickCountry?.data,
                labels: clickCountry?.labels,
                title: clickCountry?.title,
              })}
            />
          ),
        },
        {
          id: clickType?.title || '',
          size: 3,
          element: clickType ? (
            <DashboardBuilder
              configuration={getBarChartConfig({
                data: clickType?.data,
                labels: clickType?.labels,
                title: clickType?.title,
              })}
            />
          ) : (
            <Fragment />
          ),
        },
      ]);
    }
  }, [impressionCountry, impressionType, clickCountry, clickType]);

  useEffect(() => {
    if (areaChartData) {
      setAreaCharts([
        {
          id: 'total impression',
          size: 12,
          Element: (
            <DashboardBuilder configuration={getRevenueConfig(areaChartData)} />
          ),
        },
      ]);
    }
  }, [areaChartData]);

  useEffect(() => {
    if (reportingCampaignDetail.status === 'Success') {
      const totalClicks = reportingCampaignDetail.data.items.length > 0 ? sum(reportingCampaignDetail.data.items, 'totalclicks') : 0
      const totalImpression = reportingCampaignDetail.data.items.length > 0 ? sum(reportingCampaignDetail.data.items, 'totalviews') : 0
      
      setClicks(totalClicks)
      setViews(totalImpression)
      const CTR = totalImpression !== 0 ? Math.floor(totalClicks/totalImpression * 100) : 0
      setCTR(CTR)
    }
  }, [reportingCampaignDetail])

  return (
    <GridLayout
      elements={[
        {
          id: 'left',
          size: 4,
          element:  <Widget2
          data={{
            title: `${ctr}%`,
            body: <TranslatedText textMap={Translations.ctr_widget} />,
            description: <TranslatedText textMap={Translations.ctr_widget_description} />

          }}
        />
        },
        {
          id: 'mid',
          size: 4,
          element:  <Widget2
          data={{
            title: `${clicks}`,
            body: <TranslatedText textMap={Translations.click_widget} />,
            description: <TranslatedText textMap={Translations.click_widget_description} />

          }}
        />
        },
        {
          id: 'right',
          size: 4,
          element:  <Widget2
          data={{
            title: `${views}`,
            body: <TranslatedText textMap={Translations.impression_widget} />,
            description: <TranslatedText textMap={Translations.impression_widget_description} />

          }}
        />
        },
        ...lineCharts,
        {
          id: 'row bar',
          size: 12,
          element: <GridLayout elements={[...barCharts]} />,
        },
        ...areaCharts,
        {
          id: 'Progress-click',
          size: 12,
          element: (
            <Card className={classes.mt12}>
              <CardContent>
                <GridLayout
                  elements={[
                    {
                      id: 'progress-view',
                      size: 4,
                      element: (
                        <Progress
                          type={
                            <TranslatedText textMap={Translations.impression} />
                          }
                          data={progressViews}
                        />
                      ),
                    },
                    {
                      id: 'progress-click',
                      size: 4,
                      element: (
                        <Progress
                          type={<TranslatedText textMap={Translations.click} />}
                          data={progressClicks}
                        />
                      ),
                    },
                    {
                      id: 'map',
                      size: 4,
                      element: <Map />,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          ),
        },
      ]}
    />
  );
}
