import React from 'react';
import useStyles from '../../styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { AudienceSteps, LayoutSteps } from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import Gender from '../audience_gender_step';
import AgeGroup from '../audience_age_group_step';
import Device from '../audience_device_step';
import MobileEnvironment from '../audience_mobile_environment_step';
import OperatingSystem from '../audience_operating_system_step';
import AdvancedOptions from '../audience_advanced_options_step';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
import reactLogger from 'utils/logger';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import { StepButton } from '@material-ui/core';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
  handleChangeStep: { (step: number): void };
}

const AudienceView = React.forwardRef((props: IProps, ref) => {
  reactLogger.renderComponent('AudienceView');
  const { view, handleNext, handlePrevious, handleChangeStep } = props;
  const classes = useStyles();
  const activeStep = view.step as number;
  const campaign = CampaignSelectors.useGetCampaignWizardValues();

  return (
    <div>
      <Stepper className={classes.stepper} activeStep={activeStep} nonLinear>
        {LayoutSteps.map(({ id, label }) => (
          <Step key={id}>
            <StepButton onClick={() => handleChangeStep(id)}>
              <TranslatedText textMap={label} />
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <TabActions
        view={view}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
      {activeStep === AudienceSteps.GENDER && (
        <Gender ref={ref} campaign={campaign} />
      )}
      {activeStep === AudienceSteps.AGE_GROUP && (
        <AgeGroup ref={ref} campaign={campaign} />
      )}
      {/* {activeStep === AudienceSteps.DEVICE && (
        <Device ref={ref} campaign={campaign} />
      )}
      {activeStep === AudienceSteps.MOBILE_ENVIRONMENT && (
        <MobileEnvironment ref={ref} campaign={campaign} />
      )}
      {activeStep === AudienceSteps.OPERATING_SYSTEM && (
        <OperatingSystem ref={ref} campaign={campaign} />
      )} */}
      {activeStep === AudienceSteps.ADVANCED && (
        <AdvancedOptions ref={ref} campaign={campaign} />
      )}
    </div>
  );
});

export default AudienceView;
