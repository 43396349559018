import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetTopResult = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].topResult
  );
  return data;
};
export const useGetPassengerByDate = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].passengerByDate
  );
  return data;
};

export const useGetPassengerByDestinationAirport = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.IFE_ANALYTICS_REDUCER].passengerByDestinationAirport
  );
  return data;
};
export const useGetPassengerByDepartureAirport = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.IFE_ANALYTICS_REDUCER].passengerByDepartureAirport
  );
  return data;
};
export const useGetTrackingByDeparture = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].trackingByDeparture
  );
  return data;
};
export const useGetTrackingByDestination = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.IFE_ANALYTICS_REDUCER].trackingByDestination
  );
  return data;
};

export const useGetViewPerDevice = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].viewPerDevice
  );
  return data;
};

export const useGetTop10VistingTag = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].top10VisitingTag
  );
  return data;
};
export const useGetTop10VistingURL = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].top10VisitingUrl
  );
  return data;
};
export const useGetUserList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.IFE_ANALYTICS_REDUCER].userList
  );
  return data;
};
