import { IGetPublisherApplicationByPublisherOutput } from 'services/api/publisher';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

export interface IState {
  Publishers_Users_WhiteListList: {
    status: StateStatus | null;
    data: any;
  };
  addPublishers_Users_WhiteList: {
    status: StateStatus | null;
  };
  editPublishers_Users_WhiteList: {
    status: StateStatus | null;
  };
  viewPublishers_Users_WhiteList: {
    status: StateStatus | null;
    data: any;
  };
  listExclusiveByPublisher: {
    status: StateStatus | null;
    data: any;
  };
  category: {
    status: StateStatus | null;
    data: any;
  };
  listUserByRole: {
    status: StateStatus | null;
    data: any;
  };
  updateExclusivePublisher: {
    status: StateStatus | null;
  };
  publisherApplicationByPublisher: {
    status: StateStatus | null;
    data: IGetPublisherApplicationByPublisherOutput['data'] | null;
  };
  deleteExclusivePublisher: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  Publishers_Users_WhiteListList: {
    status: null,
    data: null,
  },
  addPublishers_Users_WhiteList: {
    status: null,
  },
  editPublishers_Users_WhiteList: {
    status: null,
  },
  viewPublishers_Users_WhiteList: {
    status: null,
    data: null,
  },
  listExclusiveByPublisher: {
    status: null,
    data: null,
  },
  category: {
    status: null,
    data: null,
  },
  listUserByRole: {
    status: null,
    data: null,
  },
  updateExclusivePublisher: {
    status: null,
  },
  publisherApplicationByPublisher: {
    status: null,
    data: null,
  },
  deleteExclusivePublisher: {
    status: null,
  },
};

const Publishers_Users_WhiteListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_PUBLISHERS_USERS_WHITELIST_LIST:
      return {
        ...state,
        Publishers_Users_WhiteListList: {
          ...state.Publishers_Users_WhiteListList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.Publishers_Users_WhiteListList.data
              : action.data,
        },
      };
    case Types.SET_ADD_PUBLISHERS_USERS_WHITELIST:
      return {
        ...state,
        addPublishers_Users_WhiteList: {
          ...state.addPublishers_Users_WhiteList,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_PUBLISHERS_USERS_WHITELIST:
      return {
        ...state,
        editPublishers_Users_WhiteList: {
          ...state.editPublishers_Users_WhiteList,
          status: action.status || null,
        },
      };
    case Types.SET_PUBLISHERS_USERS_WHITELIST_DETAIL:
      return {
        ...state,
        viewPublishers_Users_WhiteList: {
          ...state.viewPublishers_Users_WhiteList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewPublishers_Users_WhiteList.data
              : action.data,
        },
      };
    case Types.SET_LIST_EXCLUSIVE_BY_PUBLISHER:
      return {
        ...state,
        listExclusiveByPublisher: {
          ...state.listExclusiveByPublisher,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listExclusiveByPublisher.data
              : action.data,
        },
      };
    case Types.SET_LIST_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.category.data : action.data,
        },
      };
    case Types.SET_LIST_USER_BY_ROLE:
      return {
        ...state,
        listUserByRole: {
          ...state.listUserByRole,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listUserByRole.data
              : action.data,
        },
      };
    case Types.UPDATE_EXCLUSIVE_PUBLISHER:
      return {
        ...state,
        updateExclusivePublisher: {
          ...state.updateExclusivePublisher,
          status: action.status || null,
        },
      };
    case Types.GET_PUBLISHER_APPLICATION_BY_PUBLISHER:
      return {
        ...state,
        publisherApplicationByPublisher: {
          ...state.publisherApplicationByPublisher,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.publisherApplicationByPublisher.data
              : action.data,
        },
      };
    case Types.DELETE_EXCLUSIVE_PUBLISHER:
      return {
        ...state,
        deleteExclusivePublisher: {
          ...state.deleteExclusivePublisher,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default Publishers_Users_WhiteListReducer;
