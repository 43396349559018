import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType1 } from '../configuration';

export interface IGeneralPublisherStatisticInput {
  userid: number;
  appid: string | null;
  publisherid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

function getFillRateConfig(data: any) {
  const fillRateConfig: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: 'Revenue',
            format: {
              label: 'Revenue',
              backgroundColor: '#439CDD',
            },
          },
        ],
        labels: data.labels,
        chartType: ChartTypesEnum.COLUMN,
        options: {
          ...OptionsGraphType1(1),
          plugins: {
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: function (context: any) {
                return context.dataset.backgroundColor;
              },
              font: function (context: any) {
                var w = context.chart.width;
                return {
                  size: w < 512 ? 12 : 14,
                  weight: 'bold',
                };
              },
              formatter: function (value: any, context: any) {
                return '';
              },
            },
          },
        },
        title: '',
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-barchart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return fillRateConfig;
}
export default getFillRateConfig;
