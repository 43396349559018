import { IAction, IState } from './interfaces';
import { GET_PARALISTS } from './types';

const initialState: IState = {
  status: undefined,
  list: [],
  selection: undefined,
  paging: {},
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case GET_PARALISTS.Pending:
      return { ...state, status: action.type };

    case GET_PARALISTS.Success:
      return {
        ...state,
        status: action.type,
        list: action.list,
        paging: action.paging,
      };

    default:
      return state;
  }
};

export default Reducer;
