import { Box, Typography } from '@material-ui/core';
import L from 'leaflet';
import _, { isArray } from 'lodash';
import React, { ReactElement } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import clsx from 'clsx';
import { renderToStaticMarkup } from 'react-dom/server';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import useStyles from './styles';

export const center: L.LatLngExpression = [34.9170651225385, 33.6298917314463];

interface IMapInstanceProps {
  children: ReactElement;
  setMapBounds: any;
}
const MapInstance = ({ children, setMapBounds }: IMapInstanceProps) => {
  const mapIntance = useMap();
  React.useEffect(() => {
    if (mapIntance) {
      _.set(window, 'map', mapIntance);
      setMapBounds &&
        setMapBounds({
          tlc: map.getBounds().getNorthWest(),
          trc: map.getBounds().getNorthEast(),
          blc: map.getBounds().getSouthWest(),
          brc: map.getBounds().getSouthEast(),
        });
    }
  }, [mapIntance]);

  const map = useMapEvents({
    moveend() {
      setMapBounds &&
        setMapBounds({
          tlc: map.getBounds().getNorthWest(),
          trc: map.getBounds().getNorthEast(),
          blc: map.getBounds().getSouthWest(),
          brc: map.getBounds().getSouthEast(),
        });
    },
  });

  // React.useEffect(() => {
  //   if (bounds && map) {
  //     const mapBounds: LatLngBoundsLiteral = [
  //       [Number.parseFloat(bounds.tlc_lat), Number.parseFloat(bounds.tlc_lng)],
  //       [Number.parseFloat(bounds.trc_lat), Number.parseFloat(bounds.trc_lng)],
  //       [Number.parseFloat(bounds.blc_lat), Number.parseFloat(bounds.blc_lng)],
  //       [Number.parseFloat(bounds.brc_lat), Number.parseFloat(bounds.brc_lng)],
  //     ];
  //     map.fitBounds(mapBounds);
  //   }
  // }, [map]);

  return <React.Fragment>{children}</React.Fragment>;
};

interface IProps {
  height?: number | string;
  handleCheck?: (code: any) => boolean;
  handleSelect?: (code: any) => void;
  setSelected?: any;
  setMapBounds?: any;
}
const TransportStationsMap = React.forwardRef(
  ({ handleCheck, handleSelect, setSelected, setMapBounds }: IProps, ref) => {
    const classes = useStyles();

    let DefaultIcon = L.icon({
      iconUrl: icon,
      iconAnchor: new L.Point(12, 41),
      shadowUrl: iconShadow,
      shadowAnchor: new L.Point(12, 41),
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    const transportStationsList = CampaignSelectors.useGetTransportStations();

    const onMarkerClick = (code: any) => {
      handleSelect && handleSelect(code);
    };

    const mouseOverMarker = (id: any) => {
      const elementStationMarker = document.getElementsByClassName(
        `marker_station_${id}`
      )[0] as HTMLElement;
      if (
        elementStationMarker &&
        !elementStationMarker.className.includes(`selected_${id}`)
      ) {
        (elementStationMarker.children[0]
          .children[0] as HTMLElement).style.fill = 'red';
        elementStationMarker.style.zIndex = '99999';
      }
    };

    const mouseOutMarker = (id: any) => {
      const elementStationMarker = document.getElementsByClassName(
        `marker_station_${id}`
      )[0] as HTMLElement;
      if (
        elementStationMarker &&
        !elementStationMarker.className.includes(`selected_${id}`)
      ) {
        (elementStationMarker.children[0]
          .children[0] as HTMLElement).style.removeProperty('fill');
        elementStationMarker.style.removeProperty('z-index');
      }
    };

    return (
      <MapContainer
        zoom={12}
        minZoom={12}
        scrollWheelZoom
        style={{ height: '100%' }}
        center={center}
        doubleClickZoom={false}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapInstance setMapBounds={setMapBounds}>
          <React.Fragment>
            {/** Show markers/labels of transport stations on the map */}
            {transportStationsList?.length &&
              isArray(transportStationsList) &&
              transportStationsList.map((station) => (
                <Marker
                  position={[station.lat, station.lon]}
                  icon={L.divIcon({
                    className: clsx(
                      classes.stationPin,
                      `marker_station_${station.id}`,
                      handleCheck &&
                        handleCheck(`${station.code}`) &&
                        `selected_${station.id} ${classes.selectedPin}`
                    ),
                    html: renderToStaticMarkup(
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 321.883 321.883"
                      >
                        <path
                          fill={'blue'}
                          d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882
                        C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801
                        c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
                        />
                      </svg>
                    ),
                    iconSize: [33, 33],
                    iconAnchor: [20, 30],
                  })}
                  eventHandlers={{
                    click: () => {
                      onMarkerClick(station.code);
                    },
                    mouseover: () => {
                      mouseOverMarker(station.id);
                    },
                    mouseout: () => {
                      mouseOutMarker(station.id);
                    },
                  }}
                  key={station.id}
                >
                  <Tooltip direction="top" offset={[1, -40]}>
                    <Box>
                      <Typography component="span">{station.name}</Typography>
                    </Box>
                  </Tooltip>
                </Marker>
              ))}
            {/** Add new marker for admins */}
            {/* </React.Fragment>
          <AddNewMarker/>
        <React.Fragment> */}
            {/** Zoom controls position on map */}
            <ZoomControl position="bottomleft" />
          </React.Fragment>
        </MapInstance>
      </MapContainer>
    );
  }
);

export default TransportStationsMap;
