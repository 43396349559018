import React from 'react';
import ActionsBlock from './components/datatable/ActionsBlock';
import * as DatesUtils from '../../utils/dates';
import { Languages, AuthRoles } from '../../config';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;
//Columns
/*
    id 
   user_id 
    , flat 
    , airline 
    , country 
    , airport 
    , cpm 
    , cpc 
    , cpa 
    , advertisment_type 
    , userid 
    , utc
     */
//table key
//id
export const TableColumns = [
  {
    label: '#',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Airline',
    id: 'Airline',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue || '-'}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Airport',
    id: 'Airport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue || '-'}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Country',
    id: 'Country',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue || '-'}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Advertisment_type',
    id: 'Advertisment_type',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data?: any
    ) => (
      <React.Fragment>
        {data[rowIndex].Advertisment_type_name || '-'}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'CPM',
    id: 'CPM',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'CPC',
    id: 'CPC',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'CPA',
    id: 'CPA',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Flat',
    id: 'flat',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue?.toString()}</React.Fragment>
    ),
    inData: true,
  },

  // {
  //   label: 'utc',
  //   id: 'utc',
  //   render: (rowIndex: number, cellValue: any, columnIndex: number) => (
  //     <React.Fragment>{cellValue}</React.Fragment>
  //   ),
  //   inData: true,
  // },
  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex].id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export const Translations = {
  manage_registration_title: {
    [Languages.Greek]: 'Advertisement Calculations',
    [Languages.English]: 'Advertisement Calculations',
    [Languages.Russian]: 'Advertisement Calculations',
  },
  manage_registration_description: {
    [Languages.Greek]: 'Manage Advertisement Calculation',
    [Languages.English]: 'Manage Advertisement Calculation',
    [Languages.Russian]: 'Manage Advertisement Calculation',
  },
  User_Price_CalculationModalAddTitle: {
    [Languages.Greek]: 'Προσθήκη',
    [Languages.English]: 'Add',
    [Languages.Russian]: 'Add',
  },
  User_Price_CalculationModalEditTitle: {
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.English]: 'Edit',
    [Languages.Russian]: 'Edit',
  },
  User_Price_CalculationModalViewTitle: {
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.English]: 'View',
    [Languages.Russian]: 'View',
  },
  addEditModalButton: {
    [Languages.Greek]: 'Submit',
    [Languages.English]: 'Submit',
    [Languages.Russian]: 'Submit',
  },
};
