import React, { ReactElement } from 'react';
import useStyles from './styles';
import MobileList from './components/mobile_list';
import { IProps, Order } from './configuration';
import AppTable from './components/table';
import LoadingView from 'components/atoms/loading_view';

export default function Datatable(props: IProps): ReactElement {
  const classes = useStyles();
  let page = props.paging?.page || 0;
  let rowsPerPage = props.paging?.pageSize || 10;
  const sortType = React.useRef<Order | undefined>(props.sorting?.order);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (props.changePageHandle) {
      props.changePageHandle(
        newPage,
        rowsPerPage,
        props.sorting?.order || 'asc',
        props.sorting?.sortField || ''
      );
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string //keyof IData
  ) => {
    if (props.changePageHandle) {
      sortType.current = sortType.current === 'asc' ? 'desc' : 'asc';
      props.changePageHandle(page, rowsPerPage, sortType.current, property);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.changePageHandle && props.paging) {
      props.changePageHandle(
        props.paging.page,
        +event.target.value,
        props.sorting?.order || 'asc',
        props.sorting?.sortField || ''
      );
    }
  };

  // keyof Data
  const createSortHandler = (property: string, inData: boolean) => (
    event: React.MouseEvent<unknown>
  ) => {
    if (inData) {
      handleRequestSort(event, property);
    }
  };

  // if (props.loading) return <LoadingView />;

  return (
    <React.Fragment>
      <AppTable
        {...props}
        createSortHandler={createSortHandler}
        handleChangePage={handleChangePage}
        order={props.sorting?.order || 'asc'}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={props.sorting?.sortField || ''}
        page={page}
      />
      {/* hidden up to small */}
      <MobileList
        {...props}
        createSortHandler={createSortHandler}
        handleChangePage={handleChangePage}
        // order={order}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={props.sorting?.sortField || ''}
        page={page}
      />
    </React.Fragment>
  );
}
