import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
// import ProductActions from '../../store/actions';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useHistory, useParams } from 'react-router';
import NavigationConfig from 'navigation/config';
import { StateStatus } from 'redux/utils/common';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import {
  useAddExclusivePublisherStatus,
  useGetCategory,
  useGetListUserByRole,
  usePublisherApplicationByPublisher,
  useUpdateExclusivePublisherStatus,
} from 'redux/reducers/publishers_users_whitelist_reducer/selectors';
import PublishersUsersWhitelistActions from 'redux/reducers/publishers_users_whitelist_reducer/actions';
import {
  formDefaultValue,
  IFormData,
  TableDefaultFetchValues,
} from '../configuration';

interface IAddEditExclusive {
  data?: any;
}

const AddEditExclusive = (props: IAddEditExclusive) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const dataListUserByRole = useGetListUserByRole();
  const updateExclusivePublisherStatus = useUpdateExclusivePublisherStatus();
  const publisherApplicationByPublisher = usePublisherApplicationByPublisher();
  const addExclusivePublisherStatus = useAddExclusivePublisherStatus();

  const dataCategory = useGetCategory();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IFormData>({
    defaultValues: formDefaultValue,
  });

  const onSubmit = (data: IFormData) => {
    if (props?.data) {
      dispatch(
        PublishersUsersWhitelistActions.updateExclusivePublisher({
          data: {
            id: props?.data?.Id,
            userid: parseInt(`${data.userId}`),
            categoryid: parseInt(`${data.categoryId}`),
            publisherappid: parseInt(`${data.publisherAppId}`),
          },
          onFinish: () => {
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER,
              })
            );
            dispatch(
              PublishersUsersWhitelistActions.fetchListExclusivityByPublisher({
                id,
                sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
                page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
                pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
                order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
              })
            );
          },
        })
      );
    } else {
      dispatch(
        PublishersUsersWhitelistActions.addNewPublishers_Users_WhiteListAction({
          userid: parseInt(`${data.userId}`),
          categoryid: parseInt(`${data.categoryId}`),
          publisherappid: parseInt(`${data.publisherAppId}`),
          onFinish: () => {
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER,
              })
            );
            dispatch(
              PublishersUsersWhitelistActions.fetchListExclusivityByPublisher({
                id,
                sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
                page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
                pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
                order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
              })
            );
          },
        })
      );
    }
  };
  useEffect(() => {
    if (props?.data) {
      reset({
        userId: props?.data?.Userid ?? '',
        categoryId: props?.data?.CategoryId ?? '',
        publisherAppId: props?.data?.PublisherAppId ?? '',
      });
    }
  }, [props?.data]);

  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          {props?.data ? 'Edit' : 'Add new'} Exclusivity
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'userId', false))}
          // helperText={_.get(errors, 'userId', '')}
          rules={
            {
              // required: <RequiredField />,
            }
          }
          options={
            dataListUserByRole?.data?.map((item: any) => ({
              id: item?.id,
              label: item?.email,
            })) ?? []
          }
          fullWidth
          size="small"
          id={'userId'}
          control={control}
          label={<TranslatedText defaultText={'Advertiser'} />}
          autoComplete={'userId'}
          name={'userId'}
        />
        <AppSelect
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'categoryId', false))}
          // helperText={_.get(errors, 'categoryId', '')}
          rules={{
            required: 'Required Value',
          }}
          options={
            dataCategory?.data?.map((item: any) => ({
              id: item?.id,
              label: item?.name,
            })) ?? []
          }
          fullWidth
          size="small"
          id={'categoryId'}
          control={control}
          label={<TranslatedText defaultText={'Category'} />}
          autoComplete={'categoryId'}
          name={'categoryId'}
        />
        <AppSelect
          variant="outlined"
          margin="dense"
          error={Boolean(_.get(errors, 'publisherAppId', false))}
          // helperText={_.get(errors, 'publisherAppId', '')}
          rules={{
            required: 'Required Value',
          }}
          options={
            publisherApplicationByPublisher?.data?.items?.map((item) => ({
              id: item?.id,
              label: item?.name,
            })) ?? []
          }
          fullWidth
          size="small"
          id={'publisherAppId'}
          control={control}
          label={<TranslatedText defaultText={'Publisher App'} />}
          autoComplete={'publisherAppId'}
          name={'publisherAppId'}
        />
        <Box display={'flex'} flexDirection={'column'} marginTop={'32px'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            style={{ borderRadius: '24px', marginBottom: '6px' }}
            type={'submit'}
            disabled={
              updateExclusivePublisherStatus === StateStatus.Pending ||
              addExclusivePublisherStatus === StateStatus.Pending
            }
          >
            {updateExclusivePublisherStatus === StateStatus.Pending ||
            addExclusivePublisherStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              `${props?.data ? 'Edit' : 'Add new'} exclusivity`
            )}
          </Button>
          <Button
            variant={'text'}
            color={'secondary'}
            onClick={() => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER,
                })
              );
            }}
          >
            {/* <TranslatedText textMap={Translations.cancel} /> */}
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

interface IAddEditExclusiveModalProps {}
const AddEditExclusiveModal = (props: IAddEditExclusiveModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAddEditExclusive) => (
        <AddEditExclusive {...params} />
      )}
    />
  );
};

export default AddEditExclusiveModal;
