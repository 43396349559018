import { LanguagesEnum } from '../../config/languages';

const Translations = {
  recover_title: {
    [LanguagesEnum.English]: 'Recover your password',
    [LanguagesEnum.Greek]: 'Ανάκτηση κωδικού πρόσβασης σας',
    [LanguagesEnum.Russian]: 'Восстановите свой пароль',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email',
    [LanguagesEnum.Greek]: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    [LanguagesEnum.Russian]: 'Электронное письмо',
  },
  form_button_recover: {
    [LanguagesEnum.English]: 'Recover',
    [LanguagesEnum.Greek]: 'Αναρρώνω',
    [LanguagesEnum.Russian]: 'Восстанавливаться',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Αυτό είναι απαραίτητο',
    [LanguagesEnum.Russian]: 'Это требуется',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στην σελίδα εισόδου',
    [LanguagesEnum.Russian]: 'Вернуться на страницу авторизации',
  },
  invalid_email_format: {
    [LanguagesEnum.English]: 'Invalid email format',
    [LanguagesEnum.Greek]: 'Μη έγκυρη μορφή email',
    [LanguagesEnum.Russian]: 'Неправильный формат электронной почты',
  },
};

export default Translations;
