import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import AdvertisementsTypeActions from 'redux/reducers/advertisementstype_reducer/actions';
import Header from './components/Header';
import { TableColumns, TableDefaultFetchValues } from './configuration';
import AddEditAdvertisementTypeModal from './components/AddEditAdvertisementTypeModal';
import { StateStatus } from 'redux/utils/common';
import { useGetAdvertisementsTypeList } from 'redux/reducers/advertisementstype_reducer/selectors';

const AdvertisementTypePage = () => {
  const { data, status } = useGetAdvertisementsTypeList();
  const dispatch = useDispatch();
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'desc',
  });

  useEffect(() => {
    dispatch(
      AdvertisementsTypeActions.fetchAdvertisementsTypeListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(
      AdvertisementsTypeActions.fetchAdvertisementsTypeListAction({
        sortField,
        order,
        page: selectedPage,
        pageSize,
      })
    );
  };
  return (
    <>
      <Box height={'4px'} />
      {status === StateStatus.Pending ? (
        <LinearProgress color="primary" />
      ) : (
        <Box height={'4px'} />
      )}
      <Header />
      <Datatable
        loading={status === StateStatus.Pending}
        sorting={sorting}
        paging={data?.paging}
        data={data?.items || []}
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <AddEditAdvertisementTypeModal />
    </>
  );
};

export default withRouter(AdvertisementTypePage);
