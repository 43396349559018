import React, { Fragment } from 'react';
import moment from 'moment';
import * as NumberFormattingUtils from '../../utils/format_number';

const CONFIGURATION = {};

export const OptionsGraphType1 = (max: number) => ({
  // legend: {
  //   display: true,
  //   align: 'center',
  //   position: 'bottom',
  //   labels: {
  //     usePointStyle: true,
  //     fontColor: '#333',
  //     // padding: 50,
  //   },
  // },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: function (context: any) {
        return context.dataset.backgroundColor;
      },
      font: function (context: any) {
        var w = context.chart.width;
        return {
          size: w < 512 ? 12 : 14,
          weight: 'bold',
        };
      },
      formatter: function (value: any, context: any) {
        return '';
      },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        // stacked: true,
        gridLines: {
          display: false,
        },
        // https://stackoverflow.com/questions/28716464/hiding-labels-on-y-axis-in-chart-js
        // barThickness: 65, // number (pixels) or 'flex'
        maxBarThickness: 40, // number (pixels)
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          beginAtZero: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${label}`;
          },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});

export const OptionsGraphType2 = (max: number) => ({
  legend: {
    display: true,
    align: 'center',
    position: 'bottom',
    labels: {
      usePointStyle: true,
      fontColor: '#333',
      // padding: 50,
    },
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: function (tooltipItem: any, data: any) {
        var label = data.labels[tooltipItem.index];
        const total = data.datasets[0].data.reduce(
          (a: number, b: number) => a + b
        );
        const value = data.datasets[0].data[tooltipItem.index];
        return `${label}: ${value} ${NumberFormattingUtils.formatNumberWithCommas(
          (value / total) * 100
        )}%`;
      },
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: function (context: any) {
        return context.dataset.backgroundColor;
      },
      font: function (context: any) {
        var w = context.chart.width;
        return {
          size: w < 512 ? 12 : 14,
          weight: 'bold',
        };
      },
      formatter: function (value: any, context: any) {
        const total = context.dataset.data.reduce(
          (a: number, b: number) => a + b
        );
        return `${NumberFormattingUtils.formatNumberWithCommas(
          value
        )} ${NumberFormattingUtils.formatNumberWithCommas(
          (value / total) * 100
        )}%`;
      },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        // stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
          // suggestedMax: max || 70000,
          // callback: function (label: any, index: any, labels: any) {
          //   return ``;
          // },
        },
        // https://stackoverflow.com/questions/28716464/hiding-labels-on-y-axis-in-chart-js
        // barThickness: 65, // number (pixels) or 'flex'
        maxBarThickness: 40, // number (pixels)
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          display: false,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${label} %`;
          },
        },
      },
    ],
  },
});

export const TableColumns: any = [
  {
    label: 'Campaign Name',
    id: 'Name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },

  {
    label: 'Clicks',
    id: 'totalclicks',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Views',
    id: 'totalviews',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
];
export const rangeOption = [
  { label: 'Today', id: moment().format('YYYY-MM-DD') },
  {
    label: 'Yesterday',
    id: moment(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
  },
  {
    label: 'Last Months',
    id: moment(moment().subtract(1, 'months')).format('YYYY-MM-DD'),
  },
  {
    label: 'Last Two Months',
    id: moment(moment().subtract(2, 'months')).format('YYYY-MM-DD'),
  },
  {
    label: 'Last 6 Months',
    id: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
  },
];

export default CONFIGURATION;
