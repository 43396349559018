import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Actions from '../../../redux/reducers/application_reducer/actions';
import { useGetIsOpen } from '../../../redux/reducers/application_reducer/selectors';
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

interface IFormData {
  name: string | null;
  description: string | null;
}
const defaultValues = {
  name: '',
  description: '',
};
export default function ResponsiveDialog() {
  const dispatch = useDispatch();
  const { id } = useSelectUserData();
  const open = useGetIsOpen();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    errors,
    control,
    getValues,
    reset,
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues,
  });
  const onSubmit = (data: any) => {
    dispatch(
      Actions.addPublisherApplicationActions({
        name: data.name,
        description: data.description,
        id,
      })
    );
    // dispatch(CampaignActions.scrapWebsiteAction(data.landing_page));
  };
  const handleClose = () => {
    dispatch(Actions.setOpen(false));
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <TranslatedText textMap={Translations.add_new_button} />
        </DialogTitle>
        <DialogContent>
          <form
            // className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <AppTextField
              variant="outlined"
              margin="normal"
              error={'name' in errors}
              helperText={errors.name && errors.name.message}
              rules={{ require: true }}
              fullWidth
              id="name"
              control={control}
              label={<TranslatedText textMap={Translations.name_column} />}
              autoComplete="name"
              name="name"
            />
            <AppTextField
              variant="outlined"
              margin="normal"
              error={'description' in errors}
              helperText={errors.description && errors.description.message}
              rules={{ require: true }}
              fullWidth
              id="description"
              control={control}
              label={
                <TranslatedText textMap={Translations.description_column} />
              }
              autoComplete="description"
              name="description"
            />
            <Button onClick={handleClose} color="primary">
              <TranslatedText textMap={Translations.cancel_button} />
            </Button>
            <Button type="submit" color="primary" autoFocus>
              <TranslatedText textMap={Translations.add_button} />
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
