import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import CountryRateActions from 'redux/reducers/countriesrate_reducer/actions';
import {
  useAddCountriesRateStatus,
  useEditCountriesRateStatus,
} from 'redux/reducers/countriesrate_reducer/selectors';
import { useGetCountriesList } from 'redux/reducers/countries_reducer/selectors';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  deletePublishersConnectionsAction,
  fetchListSlaveFromMaster,
} from 'redux/reducers/publisher_reducer/actions';
import { StateStatus } from 'redux/utils/common';
import Swal from 'sweetalert2';
import { TableDefaultFetchValues } from '../configuration';
import Translations from '../translations';

interface IAddEditCountryRate {
  data?: any;
}

interface IFormData {
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number | null;
  rate: number | null;
  duration?: number | null;
}

const defaultValues: IFormData = {
  fromcountry: null,
  tocountry: null,
  passengers: null,
  rate: null,
  duration: null,
};

const AddEditCountryRate = (props: IAddEditCountryRate) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addCountriesRateStatus = useAddCountriesRateStatus();
  const editCountriesRateStatus = useEditCountriesRateStatus();
  const { data: dataCountries } = useGetCountriesList();
  const { control, errors, handleSubmit, reset } = useForm<IFormData>({
    defaultValues,
  });
  const handleCreateEditConnection = (data: IFormData) => {
    if (props?.data) {
      dispatch(
        CountryRateActions.editCountriesRateAction({
          ...data,
          id: props?.data?.ID,
          duration: 0,
          fetchCountriesRateInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    } else {
      dispatch(
        CountryRateActions.addNewCountriesRateAction({
          ...data,
          duration: 0,
          fetchCountriesRateInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    }
  };
  const optionListCountry =
    dataCountries?.items?.map((item) => ({
      id: item.code,
      label: item.name,
    })) ?? [];
  const onCloseModal = () => {
    dispatch(
      ModalActions.closeModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_COUNTRIESRATE,
      })
    );
  };
  useEffect(() => {
    if (props.data) {
      reset({
        fromcountry: props.data?.FromCountry,
        tocountry: props.data?.ToCountry,
        passengers: props.data?.Passengers,
        rate: props.data?.Rate,
      });
    }
  }, [props.data]);

  return (
    <Box width={'100%'} margin={'auto'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          {props.data ? 'Edit' : 'Create'} country rate
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          Make modification and then hit save
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            margin: '20px',
            flexWrap: 'wrap',
          }}
          onSubmit={handleSubmit(handleCreateEditConnection)}
        >
          <Box display="flex" width="100%" gridGap="15px" marginBottom="15px">
            <AppSelect
              variant="outlined"
              error={Boolean(_.get(errors, 'fromcountry', false))}
              rules={{
                required: 'This is required',
              }}
              options={optionListCountry}
              id={'fromcountry'}
              control={control}
              label={'From country'}
              autoComplete={'fromcountry'}
              name={'fromcountry'}
              fullWidth
            />
            <AppSelect
              variant="outlined"
              error={Boolean(_.get(errors, 'tocountry', false))}
              rules={{
                required: 'This is required',
              }}
              options={optionListCountry}
              id={'tocountry'}
              control={control}
              label={'To country'}
              autoComplete={'tocountry'}
              name={'tocountry'}
              fullWidth
            />
          </Box>
          <Box display="flex" width="100%" gridGap="15px" marginBottom="15px">
            <AppTextField
              variant="outlined"
              margin="dense"
              error={Boolean(_.get(errors, 'passengers', false))}
              rules={{
                required: 'This is required',
              }}
              fullWidth
              size="small"
              id={'passengers'}
              control={control}
              label={<TranslatedText defaultText={'Passengers'} />}
              autoComplete={'passengers'}
              name={'passengers'}
              type="number"
            />
            <AppTextField
              variant="outlined"
              margin="dense"
              error={Boolean(_.get(errors, 'rate', false))}
              rules={{
                required: 'This is required',
              }}
              fullWidth
              size="small"
              id={'rate'}
              control={control}
              label={<TranslatedText defaultText={'Conversion Rate'} />}
              autoComplete={'rate'}
              name={'rate'}
              type="number"
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'capitalize',
                marginRight: 10,
              }}
              type="submit"
              disabled={
                addCountriesRateStatus === StateStatus.Pending ||
                editCountriesRateStatus === StateStatus.Pending
              }
            >
              {addCountriesRateStatus === StateStatus.Pending ||
              editCountriesRateStatus === StateStatus.Pending ? (
                <CircularProgress size={24} style={{ color: '#fff' }} />
              ) : (
                `${props?.data ? 'Edit' : 'Add new'}`
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              style={{
                textTransform: 'capitalize',
                marginRight: 10,
              }}
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (e: any) => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deletePublishersConnectionsAction({
            publisherslaveid: props?.dataid?.id,
            onFinish: () => {
              dispatch(
                fetchListSlaveFromMaster({
                  publishermasterid: props?.dataid?.publishermasterid,
                  sortField: 'id',
                  page: 0,
                  pageSize: 9999,
                  order: 'asc',
                })
              );
            },
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
          backgroundColor: 'red',
        }}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Code',
    id: 'slave_code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'slave_name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface IAddEditCountryRateModalProps {}
const AddEditCountryRateModal = (props: IAddEditCountryRateModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_COUNTRIESRATE}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAddEditCountryRate) => (
        <AddEditCountryRate {...params} />
      )}
    />
  );
};

export default AddEditCountryRateModal;
