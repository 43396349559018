import { Languages } from '../../config';

const Translations = {
  table_column_completed: {
    [Languages.English]: 'Completed',
    [Languages.Greek]: 'Ολοκληρώθηκε το',
    [Languages.Russian]: 'Завершенный',
  },
  table_column_campaign_name: {
    [Languages.English]: 'Campaign Name',
    [Languages.Greek]: 'Όνομα καμπάνιας',
    [Languages.Russian]: 'Название кампании',
  },
  table_column_campaign_display: {
    [Languages.English]: 'Display',
    [Languages.Greek]: 'Περιβάλλον',
    [Languages.Russian]: 'Среда', 
  },
  table_column_customer_name: {
    [Languages.English]: 'Customer Name',
    [Languages.Greek]: 'Όνομα πελάτη',
    [Languages.Russian]: 'имя покупателя',
  },
  table_column_active_status: {
    [Languages.English]: 'Active Status',
    [Languages.Greek]: 'Ενεργή Κατάσταση',
    [Languages.Russian]: 'Активный статус',
  },
  table_column_budget: {
    [Languages.English]: 'Budget',
    [Languages.Greek]: 'Προϋπολογισμός',
    [Languages.Russian]: 'Бюджет',
  },
  table_column_status: {
    [Languages.English]: 'Status',
    [Languages.Greek]: 'Κατάσταση',
    [Languages.Russian]: 'Статус',
  },
  table_column_action: {
    [Languages.English]: 'Details',
    [Languages.Greek]: 'Λεπτομέριες',
    [Languages.Russian]: 'Подробности',
  },
  table_action_edit: {
    [Languages.English]: 'Edit',
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.Russian]: 'Редактировать',
  },
  table_action_view: {
    [Languages.English]: 'Reporting',
    [Languages.Greek]: 'αναφορά',
    [Languages.Russian]: 'Составление отчетов',
  },
  manage_registration_title: {
    [Languages.English]: 'Campaigns Title',
    [Languages.Greek]: 'καμπάνιες Τίτλος',
    [Languages.Russian]: 'Кампания Название',
  },
  table_title: {
    [Languages.English]: 'Title',
    [Languages.Greek]: 'Τίτλος',
    [Languages.Russian]: 'Заголовок',
  },
  CampaignsModalAddTitle: {
    [Languages.English]: 'Add',
    [Languages.Greek]: 'Προσθήκη',
    [Languages.Russian]: 'Добавлять',
  },
  CampaignsModalEditTitle: {
    [Languages.English]: 'Edit',
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.Russian]: 'Редактировать',
  },
  CampaignsModalViewTitle: {
    [Languages.English]: 'View',
    [Languages.Greek]: 'Θέα',
    [Languages.Russian]: 'Вид',
  },
  addEditModalButton: {
    [Languages.English]: 'Submit',
    [Languages.Greek]: 'υποβάλλουν',
    [Languages.Russian]: 'Представлять на рассмотрение',
  },
  createCampaign: {
    [Languages.English]: 'Create campaign',
    [Languages.Greek]: 'Δημιουργία καμπάνιας',
    [Languages.Russian]: 'Создать кампанию',
  },
};

export default Translations;
