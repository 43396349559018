// {{BASE_URL}}/api/schemeType
import { ObjectToQueryString } from 'redux/utils/common';
import { Environment } from '../../../config';
import * as Types from '../../../services/api/types';
import request from '../../../utils/request';
import { IListPublisherFilter, Publisher } from './interfaces';

export const listPublisherAPI = async ({
  sortField = 'ID',
  page = 0,
  pageSize = 10,
  order = 'asc',
  query,
}: IListPublisherFilter): Promise<Types.ResponseOutput> => {
  const method = 'get';
  const link = `${Environment.API_BASE_URL}/api/publisher/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}&ObjectToQueryString(query)`;
  const { data } = await request(method, link);

  return <Types.ResponseOutput>data;
};

export const editPublisherAPI = async (
  publisher_id: number,
  payload: Publisher
): Promise<Types.ResponseOutput> => {
  const method = 'patch';
  const link = `${Environment.API_BASE_URL}/api/publisher/${publisher_id}`;
  const { data } = await request(method, link, payload);

  return <Types.ResponseOutput>data;
};

export const getExclusiveListByPublisherAPI = async (
  publisher_id: number
): Promise<Types.ResponseOutput> => {
  const method = 'get';
  const link = `${Environment.API_BASE_URL}/api/publishers_users_whitelist/listByPublisher?publisher_id=${publisher_id}`;
  const { data } = await request(method, link);

  return <Types.ResponseOutput>data;
};