import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

interface ITransportStationIdProps {
  style?: React.CSSProperties;
  id: number;
}
const TransportStationId = (props: ITransportStationIdProps) => {
  const theme = useTheme();
  return (
    <Typography
      style={{
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
        ...props.style,
      }}
    >
      ID: {props.id}
    </Typography>
  );
};

export default TransportStationId;
