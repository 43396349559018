import { Languages } from '../config';

const Translations = {
  profile: {
    [Languages.English]: 'Profile',
    [Languages.Greek]: 'Προφίλ',
    [Languages.Russian]: 'Профиль',
  },
  wallet: {
    [Languages.English]: 'Wallet',
    [Languages.Greek]: 'Πορτοφόλι',
    [Languages.Russian]: 'Кошелек',
  },
  logout: {
    [Languages.English]: 'Logout',
    [Languages.Greek]: 'Αποσύνδεση',
    [Languages.Russian]: 'Выйти',
  },
  administrator: {
    [Languages.English]: 'Administrator',
    [Languages.Greek]: 'Διαχειριστής',
    [Languages.Russian]: 'администратор',
  },
  flights: {
    [Languages.English]: 'Flights',
    [Languages.Greek]: 'πτήσεις',
    [Languages.Russian]: 'рейсы',
  },
  adv_dashboard: {
    [Languages.English]: 'ADV Dashboard',
    [Languages.Greek]: 'ADV ταμπλό',
    [Languages.Russian]: 'ADV Dashboard',
  },
  adv_dashboard_flight: {
    [Languages.English]: 'Inflight Advertising',
    [Languages.Greek]: 'Διαφημιστικές πτήσεις',
    [Languages.Russian]: 'Реклама в полете',
  },
  adv_dashboard_online: {
    [Languages.English]: 'Online Advertising',
    [Languages.Greek]: 'Διαδικτυακή διαφήμιση',
    [Languages.Russian]: 'Он-лайн реклама',
  },
  adv_dashboard_transport: {
    [Languages.English]: 'Transport Advertising',
    [Languages.Greek]: 'Διαφήμιση μεταφορών',
    [Languages.Russian]: 'Транспортная реклама',
  },
  pub_dashboard: {
    [Languages.English]: 'PUB Dashboard',
    [Languages.Greek]: 'ταμπλό PUB',
    [Languages.Russian]: 'PUB Dashboard',
  },
  pub_dashboard_flight: {
    [Languages.English]: 'InFlight Publishing',
    [Languages.Greek]: 'Πίνακας ελέγχου PUB κατά την πτήση',
    [Languages.Russian]: 'Панель управления PUB в полете',
  },
  pub_dashboard_online: {
    [Languages.English]: 'Online Publishing',
    [Languages.Greek]: 'Πίνακας ελέγχου PUB σε απευθείας σύνδεση',
    [Languages.Russian]: 'Панель управления PUB в Интернете',
  },
  pub_dashboard_transport: {
    [Languages.English]: 'Transport Publishing',
    [Languages.Greek]: 'Πίνακας ελέγχου PUB σε μεταφορές',
    [Languages.Russian]: 'Панель управления PUB в транспорте',
  },
  ife_analytics: {
    [Languages.English]: 'IFE Analytics',
    [Languages.Greek]: 'IFE Analytics',
    [Languages.Russian]: 'ИТС Analytics',
  },
  campaigns: {
    [Languages.English]: 'Campaigns',
    [Languages.Greek]: 'εκστρατείες',
    [Languages.Russian]: 'Кампании',
  },
  campaigns_approval: {
    [Languages.English]: 'Campaigns Approval',
    [Languages.Greek]: 'καμπάνιες Έγκριση',
    [Languages.Russian]: 'Кампания Утверждение',
  },
  reporting: {
    [Languages.English]: 'Reporting',
    [Languages.Greek]: 'Αναφορά',
    [Languages.Russian]: 'Сообщение',
  },
  reporting_online: {
    [Languages.English]: 'Reporting Online',
    [Languages.Greek]: 'Αναφορά στο Διαδίκτυο',
    [Languages.Russian]: 'Отчетность онлайн',
  },
  reporting_inflight: {
    [Languages.English]: 'Reporting Inflight',
    [Languages.Greek]: 'Αναφορά πτήσεων',
    [Languages.Russian]: 'Сообщение о полете',
  },
  reporting_transport: {
    [Languages.English]: 'Reporting Transport',
    [Languages.Greek]: 'Αναφορά μεταφορών',
    [Languages.Russian]: 'Транспортный отчет',
  },
  online: {
    [Languages.English]: 'Online',
    [Languages.Greek]: 'Σε σύνδεση',
    [Languages.Russian]: 'онлайн',
  },
  inflight: {
    [Languages.English]: 'Inflight',
    [Languages.Greek]: 'πτήση',
    [Languages.Russian]: 'в полете',
  },
  transport: {
    [Languages.English]: 'Transport',
    [Languages.Greek]: 'Μεταφορά',
    [Languages.Russian]: 'Транспорт',
  },
  transports: {
    [Languages.English]: 'Transports',
    [Languages.Greek]: 'Μεταφορές',
    [Languages.Russian]: 'Транспорт',
  },
  application: {
    [Languages.English]: 'Application',
    [Languages.Greek]: 'Εφαρμογή',
    [Languages.Russian]: 'Заявление',
  },
  dashboards: {
    [Languages.English]: 'Dashboards',
    [Languages.Greek]: 'Οι πίνακες ελέγχου',
    [Languages.Russian]: 'Сводки',
  },
  admin: {
    [Languages.English]: 'Admin',
    [Languages.Greek]: 'διαχειριστής',
    [Languages.Russian]: 'Администратор',
  },
  user: {
    [Languages.English]: 'User',
    [Languages.Greek]: 'Χρήστης',
    [Languages.Russian]: 'Пользователь',
  },
  admin_page: {
    [Languages.English]: 'Admin page',
    [Languages.Greek]: 'διαχειριστή της σελίδας',
    [Languages.Russian]: 'Администратор страницы',
  },
  transports_online: {
    [Languages.English]: 'Transports Online',
    [Languages.Greek]: 'Μεταφορές Online',
    [Languages.Russian]: 'Транспорт Онлайн',
  },
  publisher_page: {
    [Languages.English]: 'Publisher page',
    [Languages.Greek]: 'Σελίδα εκδότη',
    [Languages.Russian]: 'Страница издателя',
  },
  dsp_page: {
    [Languages.English]: 'DSP page',
    [Languages.Greek]: 'Σελίδα DSP',
    [Languages.Russian]: 'Cтраница DSP',
  },
  advertiser_page: {
    [Languages.English]: 'Advertiser page',
    [Languages.Greek]: 'Σελίδα διαφημιστή',
    [Languages.Russian]: 'Страница рекламодателя',
  },
  advertiser_type_page: {
    [Languages.English]: 'Advertiser Type',
    [Languages.Greek]: 'Τύπος διαφημιστή',
    [Languages.Russian]: 'Тип рекламодателя',
  },
  country_rate_page: {
    [Languages.English]: 'Country Rate',
    [Languages.Greek]: 'Ποσοστό χώρας',
    [Languages.Russian]: 'Страна Курс',
  },
};

export default Translations;
