import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as AdvertisementsTypeService from '../../../services/api/advertisement_type';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import * as FileService from '../../../services/api/file';

/** Fetch FetchAdvertisementsTypeListInput  */

interface FetchAdvertisementsTypeListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchAdvertisementsTypeList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchAdvertisementsTypeListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvertisementsTypeService.getListAdvertisementType(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcAdvertisementsTypeList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewAdvertisementsType  */

interface AddNewAdvertisementsTypeInput {
  name: string | null;
  description: string | null;
  contentype: string | null;
  width: number | null;
  height: number | null;
  // deleted: string | null;
  // maxuploadsize: number | null;
  // devicetype: number | null;
  // rate: number | null;
  imageurl: File | null;
  fetchAdvertisementsTypeInput: FetchAdvertisementsTypeListInput;
}

class AddNewAdvertisementsType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_ADVERTISEMENTSTYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_ADVERTISEMENTSTYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_ADVERTISEMENTSTYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewAdvertisementsTypeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAdvertisementsTypeInput, ...rest } = payload;
        let uploadResult: FileService.IUploadFileRes | undefined;
        if (rest.imageurl) {
          uploadResult = (await FileService.uploadFile(rest.imageurl)).data;
        }
        dispatch(this.onPending());
        let response = await AdvertisementsTypeService.addAdvertisementsType({
          ...rest,
          imageurl: uploadResult?.payload?.file_url ?? '',
        });
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchAdvertisementsTypeList().action(fetchAdvertisementsTypeInput)
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE,
          })
        );
      } catch (error) {
        console.log('AddNewAdvertisementsType Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt AdvertisementsType  */

interface EditAdvertisementsTypeInput {
  id: number | null;
  name: string | null;
  description: string | null;
  contentype: string | null;
  width: number | null;
  height: number | null;
  // deleted: string | null;
  // maxuploadsize: number | null;
  // devicetype: number | null;
  // rate: number | null;
  imageurl: string | null;
  fetchAdvertisementsTypeInput: FetchAdvertisementsTypeListInput;
}

class EditAdvertisementsType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_ADVERTISEMENTSTYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_ADVERTISEMENTSTYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_ADVERTISEMENTSTYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditAdvertisementsTypeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAdvertisementsTypeInput, ...rest } = payload;
        let uploadResult: FileService.IUploadFileRes | undefined;
        if (typeof rest.imageurl === 'object') {
          uploadResult = (await FileService.uploadFile(rest.imageurl as any))
            .data;
        }
        dispatch(this.onPending());
        let response = await AdvertisementsTypeService.editAdvertisementsType({
          ...rest,
          imageurl:
            typeof rest.imageurl === 'object'
              ? uploadResult?.payload?.file_url
              : rest.imageurl,
        });
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchAdvertisementsTypeList().action(fetchAdvertisementsTypeInput)
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View AdvertisementsType  */

interface ViewAdvertisementsTypeInput {
  id: number;
}

class ViewAdvertisementsType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADVERTISEMENTSTYPE_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewAdvertisementsTypeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AdvertisementsTypeService.fetchAdvertisementsTypeDetail(
          {
            id: payload.id,
          }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewAdvertisementsType Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// Delete Advertisement Type

interface DeleteAdvertisementsTypeInput {
  id: number | null;
  fetchAdvertisementsTypeInput: FetchAdvertisementsTypeListInput;
}

class DeleteAdvertisementsType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DELETE_ADVERTISEMENTSTYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DELETE_ADVERTISEMENTSTYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DELETE_ADVERTISEMENTSTYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteAdvertisementsTypeInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchAdvertisementsTypeInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await AdvertisementsTypeService.deleteAdvertisementsType(
          rest
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchAdvertisementsTypeList().action(fetchAdvertisementsTypeInput)
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

export default {
  fetchAdvertisementsTypeListAction: (
    payload: FetchAdvertisementsTypeListInput
  ) => new FetchAdvertisementsTypeList().action(payload),
  addNewAdvertisementsTypeAction: (payload: AddNewAdvertisementsTypeInput) =>
    new AddNewAdvertisementsType().action(payload),
  editAdvertisementsTypeAction: (payload: EditAdvertisementsTypeInput) =>
    new EditAdvertisementsType().action(payload),
  viewAdvertisementsTypeAction: (payload: ViewAdvertisementsTypeInput) =>
    new ViewAdvertisementsType().action(payload),
  deleteAdvertisementsTypeAction: (payload: DeleteAdvertisementsTypeInput) =>
    new DeleteAdvertisementsType().action(payload),
};
