const monthNames = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];

export const formatDateTime = (datetime: string): string | null => {
  try {
    const date = new Date(datetime);
    // DATE
    var datePart = `${date.getDate()}`;
    if (Number.parseInt(datePart) < 10) datePart = `0${datePart}`;
    var monthPart = `${date.getMonth() + 1}`;
    if (Number.parseInt(monthPart) < 10) monthPart = `0${monthPart}`;
    var yearPart = `${date.getFullYear()}`;
    // TIME
    var hoursPart = `${date.getHours()}`;
    if (Number.parseInt(hoursPart) < 10) hoursPart = `0${hoursPart}`;
    var minutesPart = `${date.getMinutes()}`;
    if (Number.parseInt(minutesPart) < 10) minutesPart = `0${minutesPart}`;

    return `${datePart}/${monthPart}/${yearPart} ${hoursPart}:${minutesPart}`;
  } catch (error) {
    return null;
  }
};

export const formatFullDateTime = (datetime: string): string | null => {
  try {
    const date = new Date(datetime);
    // DATE
    var datePart = `${date.getDate()}`;
    if (Number.parseInt(datePart) < 10) datePart = `0${datePart}`;
    var monthPart = `${date.getMonth() + 1}`;
    var monthName = `${monthNames[date.getMonth()]}`;
    if (Number.parseInt(monthPart) < 10) monthPart = `0${monthPart}`;
    var yearPart = `${date.getFullYear()}`;

    // TIME
    var hoursPart = `${date.getHours()}`;
    if (Number.parseInt(hoursPart) < 10) hoursPart = `0${hoursPart}`;
    var minutesPart = `${date.getMinutes()}`;
    if (Number.parseInt(minutesPart) < 10) minutesPart = `0${minutesPart}`;
    var secondsPart = `${date.getSeconds()}`;
    if (Number.parseInt(secondsPart) < 10) secondsPart = `0${secondsPart}`;
    const time = `${hoursPart}:${minutesPart}:${secondsPart}`;

    return `${monthName} ${datePart}, ${yearPart}, ${time}`;
  } catch (error) {
    return null;
  }
};

export const formatDate = (datetime: string): string | null => {
  try {
    const date = new Date(datetime);
    // DATE
    var datePart = `${date.getDate()}`;
    if (Number.parseInt(datePart) < 10) datePart = `0${datePart}`;
    var monthPart = `${date.getMonth() + 1}`;
    if (Number.parseInt(monthPart) < 10) monthPart = `0${monthPart}`;
    var yearPart = `${date.getFullYear()}`;

    return `${datePart}/${monthPart}/${yearPart}`;
  } catch (error) {
    return null;
  }
};

export const DateInMonthsRange = (
  datetime: Date,
  startingMonth: number,
  endingMonth: number,
  watchYear: string
): boolean => {
  let month = datetime.getMonth() + 1;
  let year = datetime.getFullYear();
  if (
    month >= startingMonth &&
    month <= endingMonth &&
    year === Number.parseInt(watchYear)
  )
    return true;
  return false;
};

export const DateInQueryFormat = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};
