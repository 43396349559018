import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as WalletService from '../../../services/api/wallet';
import * as CampaignService from '../../../services/api/campaigns';
import * as UserService from '../../../services/api/user';

class FetchWallet implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_WALLET,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_WALLET,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_WALLET,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await WalletService.fetchWallet(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchWallet Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchCampaign implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMPAIGN,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMPAIGN,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMPAIGN,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.fetchCampaignsByUserId(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchCampaign Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchUserList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchWalletAction: (payload: any) => new FetchWallet().action(payload),
  fetchCampaignAction: (payload: any) => new FetchCampaign().action(payload),
  fetchUserListAction: (payload: any) => new FetchUserList().action(payload),
};
