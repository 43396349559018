import { showToastAction } from 'components/atoms/toast_message';
import { LanguagesEnum } from 'config/languages';
import { Dispatch } from 'redux';
import {
  editPublisherAPI,
  getExclusiveListByPublisherAPI,
  listPublisherAPI,
} from 'redux/reducers/publisher_reducer/api';
import {
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
  IAction as IActionClass,
} from 'redux/utils/common';
import { IAction, IListPublisherFilter, Publisher } from './interfaces';
import {
  GET_PUBLISHER,
  GET_PUBLISHERS,
  UPDATE_PUBLISHER,
  GET_ALL_PUBLISHER,
} from './types';
import * as Types from './types';
import {
  addPublishersConnections,
  deletePublishersConnections,
  getListSlaveFromMaster,
  IAddPublishersConnectionsInput,
  IDeletePublishersConnectionsInput,
  IGetListSlaveFromMasterInput,
} from 'services/api/publishers_connections';
import { IGetDetailPublisherInput, getDetailPublisher } from 'services/api/publisher';

export const fetchPublishers = (payload: IListPublisherFilter) => async (
  dispatch: Dispatch<IAction>
) => {
  try {
    dispatch({ type: GET_PUBLISHERS.Pending });
    let response = await listPublisherAPI(payload);
    dispatch({
      type: GET_PUBLISHERS.Success,
      list: response.data.items,
      paging: response.data.paging,
    });
  } catch (error) {
    dispatch({
      type: GET_PUBLISHERS.Failed,
      message: (error as Error).message,
    });
  }
};

export const selectPublisher = (payload: Publisher) => (
  dispatch: Dispatch<IAction>
) => {
  dispatch({ type: GET_PUBLISHER.Success, publisherSelected: payload });
};

export const clearSelectedPublisher = () => (dispatch: Dispatch<IAction>) => {
  dispatch({
    type: GET_PUBLISHER.Success,
    publisherSelected: undefined,
    publisherSelectedExclusiveList: undefined,
  });
};

export const selectExlusiveByPublisher = (payload: Publisher) => async (
  dispatch: Dispatch<IAction>
) => {
  try {
    const response = await getExclusiveListByPublisherAPI(payload.id);
    dispatch({
      type: GET_PUBLISHER.Success,
      publisherSelectedExclusiveList: response.data,
      publisherSelected: payload,
    });
  } catch (error) {
    console.log('error', error);
  }
};

export const clearExlusiveByPublisher = () => (dispatch: Dispatch<IAction>) => {
  dispatch({ type: GET_PUBLISHER.Success });
};

// export const deletePublisher = (publisher_id: number) => async (
//   dispatch: Dispatch<IAction>
// ) => {
//   try {
//     dispatch({ type: DELETE_PUBLISHER.Pending });
//     let response = await deletePublisher(publisher_id);
//     dispatch({ type: DELETE_PUBLISHER.Success, message: response.data });
//     showToastAction(dispatch, messages.delete_publisher_sucess, 'success');
//   } catch (error) {
//     dispatch({ type: DELETE_PUBLISHER.Failed, message: error.message });
//   }
// };

export const editPublisher = (
  publisher_id: number,
  payload: Publisher
) => async (dispatch: Dispatch<IAction>) => {
  try {
    dispatch({ type: UPDATE_PUBLISHER.Pending });
    let response = await editPublisherAPI(publisher_id, payload);
    payload.id = publisher_id;
    dispatch({ type: UPDATE_PUBLISHER.Success, publisherSelected: payload });
    showToastAction(dispatch, messages.update_publisher_success, 'success');
  } catch (error) {
    dispatch({
      type: UPDATE_PUBLISHER.Failed,
      message: (error as Error).message,
    });
  }
};

// export const createPublisher = (payload: Publisher) => async (
//   dispatch: Dispatch<IAction>
// ) => {
//   try {
//     dispatch(setStatus('create_publisher/pending'));
//     // let response = await createPublisher(payload);
//     dispatch(setStatus('create_publisher/success'));
//     showToastAction(dispatch, messages.create_publisher_sucess, 'success');
//   } catch (error) {
//     dispatch(setStatus('create_publisher/failed'));
//   }
// };

const messages = {
  create_publisher_success: {
    [LanguagesEnum.English]: 'Publisher created',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
  update_publisher_success: {
    [LanguagesEnum.English]: 'Publisher updated',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
  delete_publisher_success: {
    [LanguagesEnum.English]: 'Publisher Deleted',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
};

class FetchListSlaveFromMaster implements IActionMethods {
  onPending(): IActionClass {
    return {
      type: Types.GET_LIST_SLAVE_FROM_MASTER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IActionClass {
    return {
      type: Types.GET_LIST_SLAVE_FROM_MASTER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IActionClass {
    return {
      type: Types.GET_LIST_SLAVE_FROM_MASTER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetListSlaveFromMasterInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await getListSlaveFromMaster(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchAllPublishers implements IActionMethods {
  onPending(): IActionClass {
    return {
      type: Types.GET_ALL_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IActionClass {
    return {
      type: Types.GET_ALL_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IActionClass {
    return {
      type: Types.GET_ALL_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IListPublisherFilter): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await listPublisherAPI(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('listPublisherAPI Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IDeletePublishersConnections
  extends IDeletePublishersConnectionsInput {
  onFinish: () => void;
}

class DeletePublishersConnections implements IActionMethods {
  onPending(): IActionClass {
    return {
      type: Types.DELETE_PUBLISHER_CONNECTION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IActionClass {
    return {
      type: Types.DELETE_PUBLISHER_CONNECTION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IActionClass {
    return {
      type: Types.DELETE_PUBLISHER_CONNECTION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ publisherslaveid, onFinish }: IDeletePublishersConnections): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await deletePublishersConnections({ publisherslaveid });
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// add publisher connection

interface IPublishersConnectionsAction {
  data: IAddPublishersConnectionsInput;
  onFinish: () => void;
}
class AddPublishersConnectionsAction implements IActionMethods {
  onPending(): IActionClass {
    return {
      type: Types.ADD_PUBLISHER_CONNECTION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IActionClass {
    return {
      type: Types.ADD_PUBLISHER_CONNECTION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IActionClass {
    return {
      type: Types.ADD_PUBLISHER_CONNECTION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action({ data, onFinish }: IPublishersConnectionsAction): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await addPublishersConnections(data);
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('addPublishersConnections Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchDetailPublisherAction implements IActionMethods {
  onPending(): IActionClass {
    return {
      type: Types.GET_DETAIL_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IActionClass {
    return {
      type: Types.GET_DETAIL_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IActionClass {
    return {
      type: Types.GET_DETAIL_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetDetailPublisherInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await getDetailPublisher(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getDetailPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export const fetchListSlaveFromMaster = (
  payload: IGetListSlaveFromMasterInput
) => new FetchListSlaveFromMaster().action(payload);

export const fetchAllPublishers = (payload: IListPublisherFilter) =>
  new FetchAllPublishers().action(payload);

export const deletePublishersConnectionsAction = (
  payload: IDeletePublishersConnections
) => new DeletePublishersConnections().action(payload);

export const addPublishersConnectionsAction = (
  payload: IPublishersConnectionsAction
) => new AddPublishersConnectionsAction().action(payload);

export const fetchDetailPublisherAction = (
  payload: IGetDetailPublisherInput
) => new FetchDetailPublisherAction().action(payload);
