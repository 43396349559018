import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    position: 'absolute',
    left: 0,
  },
  spinner: {
    transform: 'rotate(270deg) !important',
  },
}));

export default useStyles;
