import React from 'react';
import { HiUserGroup } from 'react-icons/hi';

export interface IAgeGroup {
  name: string;
  image: React.ReactElement;
  code: string;
}

export const CampaignAgeGroups: IAgeGroup[] = [
  {
    name: '0-20',
    code: 'AGE-0-20',
    image: <HiUserGroup />,
  },
  {
    name: '20-40',
    code: 'AGE-20-40',
    image: <HiUserGroup />,
  },
  {
    name: '40-60',
    code: 'AGE-40-60',
    image: <HiUserGroup />,
  },
  {
    name: '60+',
    code: 'AGE-60-100',
    image: <HiUserGroup />,
  },
];
