import { makeStyles } from '@material-ui/core/styles';
import { APP_COLOR_SECONDARY } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '80%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  layout_links: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
  pageContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '1rem auto 1rem auto',
  },
  purposeItem: {
    // width: '25%',
    // marginLeft: '.25rem',
    marginRight: '.25rem',
    marginBottom: '.5rem',
    [theme.breakpoints.down('md')]: {
      // width: '35%',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      // width: '45%',
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  purposeOptionHeader: {},
  purposeOptionBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bullet: {
    fontSize: '.6rem',
    marginRight: '.5rem',
    color: APP_COLOR_SECONDARY({ darkMode: theme.palette.type === 'dark' }),
  },
  bulletText: {
    fontSize: '1rem',
  },
}));

export default useStyles;
