import { LanguagesEnum } from '../../config/languages';

const Translations = {
  signup_title: {
    [LanguagesEnum.English]: 'Create Account',
    [LanguagesEnum.Greek]: 'Δημιουργήστε λογαριασμό',
    [LanguagesEnum.Russian]: 'Зарегистрироваться',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Αυτό είναι απαραίτητο',
    [LanguagesEnum.Russian]: 'Это требуется',
  },
  form_field_firstname: {
    [LanguagesEnum.English]: 'First Name',
    [LanguagesEnum.Greek]: 'Ονομα',
    [LanguagesEnum.Russian]: 'Имя',
  },
  form_field_surname: {
    [LanguagesEnum.English]: 'Surname',
    [LanguagesEnum.Greek]: 'Επώνυμο',
    [LanguagesEnum.Russian]: 'Фамилия',
  },
  form_field_birthdate: {
    [LanguagesEnum.English]: 'Birth Date',
    [LanguagesEnum.Greek]: 'Ημερομηνία γέννησης',
    [LanguagesEnum.Russian]: 'Дата рождения',
  },
  form_field_username: {
    [LanguagesEnum.English]: 'Username',
    [LanguagesEnum.Greek]: 'Όνομα Χρήστη',
    [LanguagesEnum.Russian]: 'Имя пользователя',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός πρόσβασης',
    [LanguagesEnum.Russian]: 'Пароль',
  },
  form_field_confirm_password: {
    [LanguagesEnum.English]: 'Confirm Password',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Κωδικού',
    [LanguagesEnum.Russian]: 'Подтвердить Пароль',
  },
  form_button_register: {
    [LanguagesEnum.English]: 'Signup',
    [LanguagesEnum.Greek]: 'Εγγραφείτε',
    [LanguagesEnum.Russian]: 'Зарегистрироваться',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password',
    [LanguagesEnum.Greek]: 'Ξεχάσατε τον κωδικό',
    [LanguagesEnum.Russian]: 'Забыл пароль',
  },
  form_email: {
    [LanguagesEnum.English]: 'Email Address',
    [LanguagesEnum.Greek]: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    [LanguagesEnum.Russian]: 'Адрес электронной почты',
  },
  form_confirm_email: {
    [LanguagesEnum.English]: 'Confirm Email Address',
    [LanguagesEnum.Greek]:
      'Επιβεβαιώστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου',
    [LanguagesEnum.Russian]: 'Подтвердите адрес электронной почты',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στην σελίδα εισόδου',
    [LanguagesEnum.Russian]: 'Вернуться на страницу авторизации',
  },
  already_have_an_account: {
    [LanguagesEnum.English]: 'Already have an account?',
    [LanguagesEnum.Greek]: 'Έχετε ήδη λογαριασμό?',
    [LanguagesEnum.Russian]: 'Уже есть учетная запись?',
  },
  form_field_gender: {
    [LanguagesEnum.English]: 'Gender',
    [LanguagesEnum.Greek]: 'Γένος',
    [LanguagesEnum.Russian]: 'Пол',
  },
  form_field_nationality: {
    [LanguagesEnum.English]: 'Nationality',
    [LanguagesEnum.Greek]: 'Ιθαγένεια',
    [LanguagesEnum.Russian]: 'Национальность',
  },
};

export default Translations;
