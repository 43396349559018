import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchCampaignsList  */
interface ICampaignsListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  id: string | null;
  name: string | null;
  userid: string | null;
  maxbudget: string | null;
  periodfrom: string | null;
  periodto: string | null;
  creationdate: string | null;
  active: string | null;
  deleted: string | null;
  domain: string | null;
  dailybudget: string | null;
  totalbudget: string | null;
  dailyimpression: number;
  totalimpression: number;
  dailyclick: number;
  totalclick: number;
  isdraft: string | null;
  draftstep: number;
  status: string;
  status_id: number;
  estimatedreachselection: number;
  deleted_showinflight: number;
  deleted_showongrount: number;
  objective: number;
  clientname: string | null;
  lastupdatedate: string | null;
  orderbyrate: string | null;
  templateinstanceid: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface ICampaignsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  activeStatus?: string | null;
  campaign?: string | null;
}

export const fetchCampaignsList = async (
  payload: ICampaignsListInput
): Promise<Types.ResponseOutput> => {
  let searchQuery = '';
  if (payload.activeStatus)
    searchQuery += `&activeStatus=${payload.activeStatus}`;
  if (payload.campaign) searchQuery += `&campaign=${payload.campaign}`;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}${searchQuery}`
  );

  let _data: ICampaignsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchCampaignsByUserId = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/user/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Campaigns */
interface ICampaignsAddInput {
  name: string | null;
  userid: string | null;
  maxbudget: number | null;
  periodfrom: string | null;
  periodto: string | null;
  creationdate: string | null;
  active: string | null;
  deleted: string | null;
  domain: string | null;
  dailybudget: number | null;
  totalbudget: number | null;
  dailyimpression: number | null;
  totalimpression: number | null;
  dailyclick: number | null;
  totalclick: number | null;
  isdraft: string | null;
  draftstep: number | null;
  status: number | null;
  estimatedreachselection: number | null;
  deleted_showinflight: number | null;
  deleted_showongrount: number | null;
  objective: number | null;
  clientname: string | null;
  lastupdatedate: string | null;
  orderbyrate: number | null;
  templateinstanceid: string | null;
}

interface ICampaignsAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addCampaigns = async (
  payload: ICampaignsAddInput
): Promise<ICampaignsAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Campaigns */
interface ICampaignsEditInput {
  name: string | null;
  userid: string | null;
  maxbudget: number | null;
  periodfrom: string | null;
  periodto: string | null;
  creationdate: string | null;
  active: string | null;
  deleted: string | null;
  domain: string | null;
  dailybudget: number | null;
  totalbudget: number | null;
  dailyimpression: number | null;
  totalimpression: number | null;
  dailyclick: number | null;
  totalclick: number | null;
  isdraft: string | null;
  draftstep: number | null;
  status: number | null;
  estimatedreachselection: number | null;
  deleted_showinflight: number | null;
  deleted_showongrount: number | null;
  objective: number | null;
  clientname: string | null;
  lastupdatedate: string | null;
  orderbyrate: number | null;
  templateinstanceid: string | null;
}

interface ICampaignsEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editCampaigns = async (
  payload: ICampaignsEditInput
): Promise<ICampaignsEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/campaigns `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

interface ICampaignAdvertierInput {
  userid: number;
  status: number;
  activeStatus: number;
}
export const fetchCampaignsAdvertisement = async (
  payload: ICampaignAdvertierInput
): Promise<Types.ResponseOutput> => {
  let searchQuery = '';
  if (payload.activeStatus && payload.status)
    searchQuery += `?status=${payload.status}&activeStatus=${payload.activeStatus}`;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/advertisement/${payload.userid}${searchQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface IUpdateCampaignStatusInput {
  userid: number;
  status: number;
  activeStatus: number;
}
export const updateCampaignsStatus = async (
  payload: IUpdateCampaignStatusInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns/update-advertisement-status`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

interface IAddCampaignCategoryInput {
  advid: number;
  category: string;
}
export const addCampaignsCategory = async (
  payload: IAddCampaignCategoryInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/campaigns/add-advertisement-category`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const fetchReportCampaignsDetail = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const querySearch = `?campaignid=${payload.campaignid}&enviroment=${payload.enviroment}&clientname=${payload.clientname}&fromdate=${payload.fromdate}&todate=${payload.todate}&destcountry=${payload.destcountry}&active=${payload.active}&type=${payload.type}`;
  const queryParams = payload.sortField
    ? `&page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
    : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/report/campaign-detail${querySearch}${queryParams}`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchCampaignsListByUserId = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/campaigns/campaign-list/${payload.userid}`,
    payload
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
