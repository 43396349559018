import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useGetAirportList } from 'redux/reducers/reporting_reducer/selectors';
import { Typography, Tooltip } from '@material-ui/core';
import { Languages, AuthRoles } from '../../config';
import * as airportServices from '../../services/api/airports';
import TranslatedText from '../../components/atoms/translated_text';
import Translations from './translations';

export const TableColumns: any = [
  {
    label: 'Campaign ID',
    id: 'campaignid',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },

  {
    label: 'Campaign',
    id: 'campaign',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Date',
    id: 'date',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{moment(cellValue).format('DD-MM-YYYY hh:mm')}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Device Type',
    id: 'devicetype',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Environment',
    id: 'environment',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Destination Country',
    id: 'destcountry',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Views',
    id: 'totalviews',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Clicks',
    id: 'totalclicks',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>{cellValue}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Departure Airport',
    id: 'deptairport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>
        <Airport code={cellValue} />
      </Fragment>
    ),
    inData: true,
  },
  {
    label: 'Arrival Airport',
    id: 'destairport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>
        <Airport code={cellValue} />
      </Fragment>
    ),
    inData: true,
  },
  {
    label: 'Amount Spent',
    id: 'amount',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Fragment>€ {cellValue ? cellValue : 0}</Fragment>
    ),
    inData: true,
  },
  {
    label: 'Advertisement Type',
    id: 'advertisementtype',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <div style={{ textTransform: 'capitalize' }}>{cellValue}</div>
    ),
    inData: true,
  },
];

function Airport(props: any) {
  const airports = useGetAirportList();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (airports.length > 0) {
      const airport = airports.filter((item: any) => item.code === props.code);
      if (airport.length !== 0) {
        setName(airport[0].name);
      }
    }
  }, [airports, props.code]);

  return (
    <React.Fragment>
      {name ? (
        <Tooltip title={`${name}`} placement="top-start">
          <Typography>{props.code}</Typography>
        </Tooltip>
      ) : (
        <Typography>{props.code}</Typography>
      )}
    </React.Fragment>
  );
}

export const OptionsGraphType1 = (max: number) => ({
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: function (context: any) {
        return context.dataset.backgroundColor;
      },
      font: function (context: any) {
        var w = context.chart.width;
        return {
          size: w < 512 ? 12 : 14,
          weight: 'bold',
        };
      },
      formatter: function (value: any, context: any) {
        return '';
      },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        // stacked: true,
        gridLines: {
          display: false,
        },
        // https://stackoverflow.com/questions/28716464/hiding-labels-on-y-axis-in-chart-js
        // barThickness: 65, // number (pixels) or 'flex'
        maxBarThickness: 40, // number (pixels)
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          beginAtZero: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${label}`;
          },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
export const rangeOption: any[] = [
  {
    label: <TranslatedText textMap={Translations.range_option_today} />,
    value: moment().format('YYYY-MM-DD'),
  },
  {
    label: <TranslatedText textMap={Translations.range_option_yesterday} />,
    value: moment(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
  },
  {
    label: <TranslatedText textMap={Translations.range_option_last_month} />,
    value: moment(moment().subtract(1, 'months')).format('YYYY-MM-DD'),
  },
  {
    label: <TranslatedText textMap={Translations.range_option_last_2_month} />,
    value: moment(moment().subtract(2, 'months')).format('YYYY-MM-DD'),
  },
  {
    label: <TranslatedText textMap={Translations.range_option_last_6_month} />,
    value: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
  },
];
