import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

interface ITransportsListOutput {
  items: ITransport[];
  paging: IPaging;
}

export interface ITransport {
  id: number;
  pubCode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string;
  sim: string;
  serial: string;
  pin1: string;
  pin2: string;
  wifi: string;
  type: string;
  version: string;
  status: number;
  comments: string;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface ITransportsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  publisher: string | null;
  code: string | null;
}

export const fetchTransportsList = async (
  payload: ITransportsListInput
): Promise<Types.ResponseOutput> => {
  const { sortField, page, pageSize, order, publisher, code } = payload;
  const publisherQuery = publisher ? `&publisher=${publisher}` : '';
  const codeQuery = code ? `&code=${code}` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transports/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}${publisherQuery}${codeQuery}`
  );

  let _data: ITransportsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** fetchTransportBussesList  */
interface ITransportBussesListOutput {
  items: IBus[];
  // paging: IPaging;
}

export interface IBus {
  id: number;
  pubcode: string;
  code: string;
  lat: number;
  lon: number;
  ip: string;
  sim: string;
  serial: string;
  pin1: string;
  pin2: string;
  wifi: string;
  version: string;
  status: number;
  comments: string;
  type: string;
}

export const fetchTransportBussesList = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const statusQuery = payload.status ? `&status=${payload.status}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transports/list-all?type=BUS${statusQuery}`
  );

  let _data: ITransportBussesListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
