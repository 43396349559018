export function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
}

export function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) throw new Error('LocalStorage State');
    return JSON.parse(serializedState);
  } catch (error) {
    console.log(error);
    return {};
  }
}
