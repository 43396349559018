import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';

import {
  Card,
  CardHeader,
  CardContent,
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PageLayout from 'components/app_common/page_layout';
import General from './components/General';
import ApplicationType from './components/ApplicationType';
import ApplicationTypeModal from './components/ApplicationTypeModal';

import useStyles from './styles';
import Actions from 'redux/reducers/application_detail_reducer/actions';
import { useGetPublisherApplication } from 'redux/reducers/application_detail_reducer/selectors';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';

export default function ApplicationDetail(props: any) {
  const publisherApplication = useGetPublisherApplication();
  const { id } = useSelectUserData();
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const uniqueid = _.get(params, 'uniqueid', null);
    if (uniqueid)
      dispatch(Actions.fetchPublisherApplicationActions({ uniqueid }));

    dispatch(Actions.fetchCategoryActions({}));
    dispatch(Actions.fetchLocationActions({}));
  }, [params]);

  useEffect(() => {
    if (publisherApplication.status === 'Success') {
      dispatch(
        Actions.fetchPublisherApplicationTypeActions({
          applicationid: publisherApplication.data.ID,
          userid: id,
        })
      );
    }
  }, [publisherApplication]);
  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setTabValue(newValue);
  }
  return (
    <PageLayout>
      <ApplicationTypeModal />
      <Card>
        <CardHeader title="Application Detail" />
        <CardContent>
          <AppBar className={classes.mt12} position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab
                classes={{
                  textColorPrimary: classes.tab,
                  selected: classes.tabSelected,
                }}
                label={<TranslatedText textMap={Translations.general_tab} />}
              />
              <Tab
                classes={{
                  textColorPrimary: classes.tab,
                  selected: classes.tabSelected,
                }}
                label={
                  <TranslatedText textMap={Translations.application_tab} />
                }
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={tabValue}
            onChangeIndex={(value) => setTabValue(value)}
          >
            <General />
            <ApplicationType />
          </SwipeableViews>
        </CardContent>
      </Card>
    </PageLayout>
  );
}
