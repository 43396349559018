import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { CampaignInfoDefaultValue } from 'config';

interface IState {
  CampaignWizard: {
    status: StateStatus | null;
    data: ICampaignInfoWizard;
    DestinationReachOnBoard: {
      status: StateStatus | null;
      data: {
        destinations: any;
        flights: any;
      };
    };
    ScrapUrl: {
      status: StateStatus | null;
      data: any;
    };
    AnalyzeFile: any;
    CreateCampaign: {
      status: StateStatus | null;
      data: any;
    };
    UpdateCampaign: {
      status: StateStatus | null;
      data: any;
    };
    TransportStations: {
      status: StateStatus | null;
      data: any;
    };
  };
  Clients: {
    status: StateStatus | null;
    data: Array<any>;
  };
  Segments: {
    status: StateStatus | null;
    data: any;
  };
  countries: {
    status: StateStatus | null;
    data: any;
  };
  points: any[];
}

const initialState: IState = {
  CampaignWizard: {
    status: null,
    data: CampaignInfoDefaultValue,
    DestinationReachOnBoard: {
      status: null,
      data: {
        destinations: null,
        flights: null,
      },
    },
    ScrapUrl: {
      status: null,
      data: null,
    },
    AnalyzeFile: {},
    CreateCampaign: {
      status: null,
      data: null,
    },
    UpdateCampaign: {
      status: null,
      data: null,
    },
    TransportStations: {
      status: null,
      data: null,
    },
  },
  Clients: {
    status: null,
    data: [],
  },
  Segments: {
    status: null,
    data: null,
  },
  countries: {
    status: null,
    data: null,
  },
  points: [],
};

const CampaignReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.UPDATE_CAMPAIGN_WIZARD:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          status: action.status || null,
          data: {
            ...state.CampaignWizard.data,
            ...action.data,
          },
        },
      };
    case Types.SET_CLIENTS:
      return {
        ...state,
        Clients: {
          ...state.Clients,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_COUNTRIES:
      return {
        ...state,
        countries: {
          ...state.countries,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_POINTS:
      return {
        ...state,
        points: action.data,
      };
    case Types.SET_SEGMENTS:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.CALCULATE_REACH_ON_BOARD:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          DestinationReachOnBoard: {
            status: action.status || null,
            data:
              action.data === KEEP_THE_SAME
                ? state.CampaignWizard.DestinationReachOnBoard.data
                : action.data,
          },
        },
      };
    case Types.SCRAP_URL:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          ScrapUrl: {
            status: action.status || null,
            data: action.data,
          },
        },
      };
    case Types.ANALYZE_FILE:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          AnalyzeFile: {
            ...state.CampaignWizard.AnalyzeFile,
            [action.data.key]: {
              data: action.data.data,
              status: action.status || null,
            },
          },
        },
      };
    case Types.CREATE_CAMPAIGN:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          CreateCampaign: {
            status: action.status || null,
            data: action.data,
          },
        },
      };
    case Types.UPDATE_CAMPAIGN:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          UpdateCampaign: {
            status: action.status || null,
            data: action.data,
          },
        },
      };
    case Types.RESET_CAMPAIGN_WIZARD:
      return {
        ...state,
        CampaignWizard: initialState.CampaignWizard,
      };
    case Types.INSERT_CAMPAIGN_WIZARD_BY_ID:
      return {
        ...state,
        CampaignWizard: {
          ...initialState.CampaignWizard,
          status: action.status || null,
          data: {
            isNewClient: false,
            ...action.data,
          },
        },
      };
    case Types.SET_TRANSPORT_STATIONS:
      return {
        ...state,
        CampaignWizard: {
          ...state.CampaignWizard,
          TransportStations: {
            status: action.status || null,
            data:
              action.data === KEEP_THE_SAME
                ? state.CampaignWizard.TransportStations.data
                : action.data,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default CampaignReducer;
