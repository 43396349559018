import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

export interface IDialog {
  isOpen: boolean;
  type: string;
  data: any | null;
}
export interface ISavePublisherApplicationType {
  applicationid: number;
  locationid: number;
  applicationtypeid: number;
  userid: number;
}
interface IState {
  publisherApplication: {
    status: StateStatus | null;
    data: any;
  };
  publisherApplicationType: {
    status: StateStatus | null;
    data: any;
  };
  newPublisherApplicationType: {
    data: any;
  };
  currentlisherApplicationType: {
    data: any;
  };
  category: {
    status: StateStatus | null;
    data: any;
  };
  location: {
    status: StateStatus | null;
    data: any;
  };
  updatePublisherApplication: {
    status: StateStatus | null;
  };
  deletePublisherApplicationType: {
    status: StateStatus | null;
  };
  savePublisherApplicationType: {
    status: StateStatus | null;
  };
  isNewApplicationType: boolean;
  dialog: IDialog;
}

const initialState: IState = {
  publisherApplication: {
    status: null,
    data: null,
  },
  publisherApplicationType: {
    status: null,
    data: null,
  },
  category: {
    status: null,
    data: null,
  },
  location: {
    status: null,
    data: null,
  },
  updatePublisherApplication: {
    status: null,
  },
  deletePublisherApplicationType: {
    status: null,
  },
  savePublisherApplicationType: {
    status: null,
  },
  newPublisherApplicationType: {
    data: [],
  },
  currentlisherApplicationType: {
    data: [],
  },
  isNewApplicationType: false,
  dialog: {
    isOpen: false,
    type: 'add',
    data: null,
  },
};

const ApplicationDetailReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_PUBLISHER_APPLICATION:
      return {
        ...state,
        publisherApplication: {
          ...state.publisherApplication,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.publisherApplication.data
              : action.data,
        },
      };
    case Types.SET_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.category.data : action.data,
        },
      };
    case Types.SET_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.location.data : action.data,
        },
      };
    case Types.UPDATE_PUBLISHER_APPLICATION:
      return {
        ...state,
        updatePublisherApplication: {
          ...state.updatePublisherApplication,
          status: action.status || null,
        },
      };
    case Types.DELETE_PUBLIHSER_APPLICATION_TYPE:
      return {
        ...state,
        deletePublisherApplicationType: {
          ...state.deletePublisherApplicationType,
          status: action.status || null,
        },
      };
    case Types.SAVE_PUBLIHSER_APPLICATION_TYPE:
      return {
        ...state,
        savePublisherApplicationType: {
          ...state.savePublisherApplicationType,
          status: action.status || null,
        },
      };
    case Types.SET_PUBLISHER_APPLICATION_TYPE:
      return {
        ...state,
        publisherApplicationType: {
          ...state.publisherApplicationType,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.publisherApplicationType.data
              : action.data,
        },
      };
    case Types.SET_NEW_PUBLISHER_APPLICATION_TYPE:
      return {
        ...state,
        newPublisherApplicationType: {
          ...state.newPublisherApplicationType,
          data:
            action.data === KEEP_THE_SAME
              ? state.newPublisherApplicationType.data
              : action.data,
        },
      };
    case Types.SET_CURRENT_PUBLISHER_APPLICATION_TYPE:
      return {
        ...state,
        currentlisherApplicationType: {
          ...state.currentlisherApplicationType,
          data:
            action.data === KEEP_THE_SAME
              ? state.currentlisherApplicationType.data
              : action.data,
        },
      };

    case Types.SET_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...action.data,
        },
      };
    case Types.SET_IS_NEW_APPLICATION_TYPE:
      return {
        ...state,
        isNewApplicationType: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApplicationDetailReducer;
