import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  map: {
    height: '100%',
    width: '100%',
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    },
  },
  placeholder: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
