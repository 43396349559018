import React, { ReactElement } from 'react';
import useStyles from './styles';
import Card from '@material-ui/core/Card';
import { Box, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';

export interface IStatisticsLabelProps {
  backgroundColor: string;
  title: ReactElement | string;
  description: ReactElement | string;
  titleColor: string;
  descriptionColor: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  height?: number | string;
}

export default function StatisticsLabel(props: IStatisticsLabelProps) {
  const classes = useStyles({ height: props?.height });
  const { titleStyle = {}, descriptionStyle = {}, containerStyle = {} } = props;

  return (
    <Box
      component={'div'}
      style={{ backgroundColor: props.backgroundColor, ...containerStyle }}
      className={classes.root}
    >
      <Typography
        style={{ color: props.titleColor, ...titleStyle }}
        className={classes.title}
        component={'p'}
      >
        {props.title}
      </Typography>
      <Typography
        style={{ color: props.descriptionColor, ...descriptionStyle }}
        className={classes.description}
        component={'p'}
      >
        {props.description}
      </Typography>
    </Box>
  );
}
