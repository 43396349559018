import Translations from '../../translations';

export enum PreviewSteps {
  CAMPAIGN_INFO = 0,
  AUDIENCE = 1,
  DESTINATIONS = 2,
  AD_DETAILS = 3,
  SUBMIT = 4,
}

export const LayoutSteps = [
  {
    id: PreviewSteps.CAMPAIGN_INFO,
    label: Translations.campaign_info,
  },
  {
    id: PreviewSteps.AUDIENCE,
    label: Translations.audience,
  },
  {
    id: PreviewSteps.DESTINATIONS,
    label: Translations.destinations,
  },
  {
    id: PreviewSteps.AD_DETAILS,
    label: Translations.ad_details,
  },
  {
    id: PreviewSteps.SUBMIT,
    label: Translations.submit,
  },
];
