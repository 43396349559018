import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PageLayout from 'components/app_common/page_layout';
import {
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import Action from 'redux/reducers/wallet_reducer/actions';
import Balance from './components/Balance';
import Search from './components/Search';
import SearchArea from './components/SearchArea';
import { Roles } from 'config/authRoles';
import TransactionTable from './components/TransactionTable';
import { TableDefaultFetchValues } from './configuration';
import useStyles from './styles';
export default function Wallet() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useSelectUserData();
  const roles = useSelectUserRoles();
  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  useEffect(() => {
    let userid = getParameterByName('userid');
    userid = userid ? userid : id;
    dispatch(
      Action.fetchWalletAction({
        id: userid,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        search: {
          isSearch: false,
          values: {
            campaign: '',
            clientname: '',
            startdate: '',
            enddate: '',
          },
        },
      })
    );
    dispatch(
      Action.fetchCampaignAction({
        id: userid,
      })
    );
    dispatch(Action.fetchUserListAction({}));
  }, [id]);
  return (
    <PageLayout>
      {roles.includes(Roles.Administrator) ? <SearchArea /> : <></>}
      <Balance />
      <Search />
      <TransactionTable />
    </PageLayout>
  );
}
