import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import { AppTextField } from 'components/molecules/form_controls';
import Translations from '../../translations';
import { Box, Button, CircularProgress } from '@material-ui/core';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import { Environment } from 'config';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { MAXIMUM_FILE_SIZE_IN_MB } from './configuration';
import { showToastAction } from 'components/atoms/toast_message';
import clsx from 'clsx';
import _ from 'lodash';

interface IFormData {
  advertisment_description: string | null;
  landing_page: string | null;
}

// add types or interfaces
const defaultValues = {
  advertisment_description: null,
  landing_page: null,
};

const BYTE_SIZE = 1048576;
interface IProps {
  campaign: ICampaignInfoWizard;
}
const AdDetailsAll = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaign } = props;
  const {
    errors,
    control,
    getValues,
    reset,
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues,
  });

  React.useEffect(() => {
    reset({
      advertisment_description: campaign.advertisment_description,
      landing_page: campaign.landing_page,
    });
  }, [campaign]);

  React.useEffect(() => {
    ref.current = { ...ref.current, getValues: getValues };
  }, [getValues]);

  const onSubmit = (data: any) => {
    dispatch(CampaignActions.scrapWebsiteAction(data.landing_page));
  };

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <CardLayout
          body={
            <React.Fragment>
              <Box style={{ paddingLeft: '4px', fontWeight: 'bold' }}>
                <TranslatedText textMap={Translations.advertisement_details} />
              </Box>
              <form
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <GridLayout
                  justify="flex-start"
                  elements={[
                    {
                      id: 'advertisment_description',
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'advertisment_description' in errors}
                          helperText={
                            errors.advertisment_description &&
                            errors.advertisment_description.message
                          }
                          rules={{ require: true }}
                          fullWidth
                          id="advertisment_description"
                          control={control}
                          label={
                            <TranslatedText
                              textMap={
                                Translations.advertisment_description_control
                              }
                            />
                          }
                          autoComplete="advertisment_description"
                          name="advertisment_description"
                        />
                      ),
                      size: 12,
                    },
                    {
                      id: 'landing_page',
                      element: (
                        <React.Fragment>
                          {
                            <AppTextField
                              variant="outlined"
                              margin="normal"
                              error={'landing_page' in errors}
                              helperText={
                                errors.landing_page &&
                                errors.landing_page.message
                              }
                              rules={{ require: true }}
                              fullWidth
                              id="landing_page"
                              control={control}
                              label={
                                <TranslatedText
                                  textMap={Translations.landing_page_control}
                                />
                              }
                              autoComplete="landing_page"
                              name="landing_page"
                            />
                          }
                        </React.Fragment>
                      ),
                      size: 12,
                    },
                    {
                      id: 'action',
                      element: (
                        <React.Fragment>
                          {campaign.display == 0 && <ButtonWrapper />}
                        </React.Fragment>
                      ),
                      size: 12,
                    },
                  ]}
                />
              </form>
            </React.Fragment>
          }
        />
      </div>

      <div className={classes.uploaderContainer}>
        <CardLayout
          body={
            <React.Fragment>
              <Box style={{ paddingLeft: '4px', fontWeight: 'bold' }}>
                <TranslatedText textMap={Translations.upload_files} />
              </Box>
              <FileUploader
                ref={ref}
                ads={props.campaign.advertisments ?? []}
              />
            </React.Fragment>
          }
        />
      </div>
    </React.Fragment>
  );
});

const ButtonWrapper = () => {
  const classes = useStyles();
  const result = CampaignSelectors.useGetScrappingResult();

  const openUrl = () => {
    window.open(`${Environment.API_BASE_URL}${result.data.url}`, '_blank');
  };

  return (
    <div className={classes.actionsContainer}>
      <Button
        className={classes.submitButton}
        type={'submit'}
        variant={'contained'}
        color="primary"
        disabled={result.status === StateStatus.Pending}
      >
        {result.status === StateStatus.Pending && (
          <CircularProgress
            color={'primary'}
            className={classes.progressWhite}
          />
        )}
        <TranslatedText textMap={Translations.store_preview} />
      </Button>
      {result.status === StateStatus.Success && (
        <Button
          onClick={openUrl}
          style={{ marginLeft: '1%', width: '49%' }}
          type={'button'}
          variant={'contained'}
          color="primary"
        >
          <TranslatedText textMap={Translations.open_url} />
        </Button>
      )}
    </div>
  );
};

interface IImageUploader {
  ads: any;
}
const FileUploader = React.forwardRef((props: IImageUploader, ref: any) => {
  const { ads } = props;
  const [uploadedFiles, setUploadedFiles] = useState<any>({});
  const [filesNumber, setFilesNumber] = useState<number>(0);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, video/*',
    maxSize: MAXIMUM_FILE_SIZE_IN_MB * BYTE_SIZE,
    onDropAccepted: (files: Array<File>, event: any) => onFileUploaded(files),
    onDropRejected: (files: Array<FileRejection>, event: any) =>
      onFileRejected(files[0].errors[0]),
  });

  React.useEffect(() => {
    ref.current = { ...ref.current, uploadedFiles: uploadedFiles };
  }, [uploadedFiles]);

  React.useEffect(() => {
    if (ads) {
      let advertisments: any = {};
      for (let index in ads) {
        advertisments[Number.parseInt(index) + 1] = ads[index];
      }
      setUploadedFiles(advertisments);
      setFilesNumber(ads.length);
    }
  }, [ads]);

  const onFileUploaded = (files: Array<File>) => {
    // let analyzedFiles = _.get(
    //   getState(ReducerKeys.CAMPAIGN_REDUCER),
    //   'CampaignWizard.AnalyzeFile',
    //   {}
    // );
    let key = filesNumber;
    // if (uploadedFiles) key = Object.keys(uploadedFiles).length;

    let filesObj: any = {};
    for (let file of files) {
      key += 1;
      console.log('FILE KEY', key);
      filesObj[key] = {
        size: file.size,
      };
      dispatch(CampaignActions.analyzeFileAction(`${key}`, file));
    }
    setUploadedFiles({ ...uploadedFiles, ...filesObj });
    setFilesNumber(key);
  };

  const onFileRejected = (error: FileError | { code: string }) => {
    let message = null;
    if (error.code === 'file-too-large') {
      message = Translations.file_too_large_error;
    } else if (error.code === 'file-invalid-type') {
      message = Translations.file_invalid_type;
    } else if (error.code === 'file-invalid-dimensions') {
      message = Translations.file_invalid_dimensions;
    } else {
      message = Translations.invalid_file;
    }
    showToastAction(dispatch, <TranslatedText textMap={message} />, 'error');
  };

  const baseStyle = {
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '300px',
  };

  const style: any = React.useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <section
      style={{ marginTop: '1rem', padding: '0 4px 0 4px' }}
      className="container"
    >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className={classes.placeholder}>
          {Object.keys(uploadedFiles).length == 0 && (
            <React.Fragment>
              <span>
                <TranslatedText textMap={Translations.image_allowed} />
                300x250, 320x50, 728x90
              </span>
              <span>
                <TranslatedText textMap={Translations.formats_allowed} />
                PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV
              </span>
              <span>
                <TranslatedText textMap={Translations.add_description} />
              </span>
            </React.Fragment>
          )}
          {Object.keys(uploadedFiles).length > 0 && (
            <FileGrid
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              onFileRejected={onFileRejected}
            />
          )}
        </div>
      </div>
    </section>
  );
});

interface IFileGrid {
  uploadedFiles: any;
  setUploadedFiles: any;
  onFileRejected: any;
}
const FileGrid = (props: IFileGrid) => {
  const classes = useStyles();
  const { uploadedFiles, onFileRejected } = props;
  const filesKeys = Object.keys(uploadedFiles);
  return (
    <div className={classes.imagesGrid}>
      {filesKeys.map((key, index) => (
        <FileView
          key={index}
          setUploadedFiles={props.setUploadedFiles}
          uploadedFiles={uploadedFiles}
          fileKey={key}
          onFileRejected={onFileRejected}
        />
      ))}
    </div>
  );
};

interface IFileViewProps {
  fileKey: string;
  setUploadedFiles: any;
  uploadedFiles: any;
  onFileRejected: any;
}
const FileView = (props: IFileViewProps) => {
  const classes = useStyles();
  const { fileKey, uploadedFiles, setUploadedFiles, onFileRejected } = props;
  const contentUrl = _.get(uploadedFiles, `${fileKey}.file_url`, null);
  const fileType: string = _.get(uploadedFiles, `${fileKey}.type`, null);

  const analyzedFile = CampaignSelectors.useGetAnalyzedFileResult(
    props.fileKey
  );

  React.useEffect(() => {
    let status = _.get(analyzedFile, 'status', null);
    if (status === StateStatus.Success) {
      let uploaded = _.get(analyzedFile, 'data.uploaded', false);
      if (uploaded) {
        // if uploaded successfully update to thumbnail url
        let file_url = _.get(analyzedFile, 'data.payload.file_url', null);
        let type = _.get(analyzedFile, 'data.payload.type', null);
        let type_id = _.get(analyzedFile, 'data.payload.type_id', null);
        setUploadedFiles({
          ...uploadedFiles,
          [fileKey]: {
            ...uploadedFiles[fileKey],
            file_url: file_url,
            type: type,
            type_id: Number.parseInt(type_id),
          },
        });
      } else {
        // if not uploaded successfully then remove
        let fileObjs = { ...uploadedFiles };
        delete fileObjs[fileKey];
        setUploadedFiles(fileObjs);
        onFileRejected({
          code: _.get(analyzedFile, 'data.payload.code', null),
        });
      }
    }
  }, [_.get(analyzedFile, 'status', null)]);

  const removeItem = (event: any) => {
    event.stopPropagation();
    let fileObjs = { ...uploadedFiles };
    delete fileObjs[fileKey];
    setUploadedFiles(fileObjs);
  };

  return (
    <div className={classes.imageContainer}>
      {contentUrl ? (
        <React.Fragment>
          {fileType.toUpperCase() === 'IMAGE' ? (
            <img
              className={clsx({
                [classes.image]: true,
              })}
              src={`${Environment.API_BASE_URL}${contentUrl}`}
            />
          ) : (
            <video width="120" height="120" controls>
              <source
                src={`${Environment.API_BASE_URL}${contentUrl}`}
                type="video/mp4"
              />
            </video>
          )}
          <div className={classes.removeContainer}>
            <span onClick={removeItem}>
              <TranslatedText textMap={Translations.remove} />
            </span>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.imagePlaceholder}>
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
};

export default AdDetailsAll;
