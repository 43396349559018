import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as PubDashboardService from '../../../services/api/pub_dashboard';
import * as PublisherService from '../../../services/api/publisher';
import * as UserService from '../../../services/api/user';
import * as TransportsService from '../../../services/api/transports';

class FetchCountryClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUB_COUNTRY_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUB_COUNTRY_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUB_COUNTRY_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getCountryClickView(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchFilterList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchRevenueAreaChart implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_AREA_CHART,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_AREA_CHART,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_AREA_CHART,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getGeneralPublisherRevenueAreaChart(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'getGeneralPublisherRevenueAreaChart Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchFillrateBarchart implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_FILLRATE_BAR_CHART,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_FILLRATE_BAR_CHART,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_FILLRATE_BAR_CHART,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getGeneralPublisherFillrateBarchart(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'getGeneralPublisherFillrateBarchart Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchRevenueBarchart implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_BAR_CHART,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_BAR_CHART,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_REVENUE_BAR_CHART,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getGeneralPublisherRevenueBarchart(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getGeneralPublisherRevenueBarchart Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchImpressionAreachart implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_IMPRESSION_AREA_CHART,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_IMPRESSION_AREA_CHART,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GENERAL_PUBLISHER_IMPRESSION_AREA_CHART,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getGeneralPublisherImpressionAreachart(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'getGeneralPublisherImpressionAreachart Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchCampaignClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_CAMPAIGN_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_CAMPAIGN_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_CAMPAIGN_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getPublisherCampaignClickView(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPublisherCampaignClickView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchOSClickView implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_OS_CLICK_VIEW,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_OS_CLICK_VIEW,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_OS_CLICK_VIEW,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getPublisherOSClickView(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPublisherOSClickView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchFillrateByCountry implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_FILLRATE_BY_COUNTRY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_FILLRATE_BY_COUNTRY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_FILLRATE_BY_COUNTRY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getPublisherFillrateByCountry(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPublisherFillrateByCountry Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTotalSpentByDestairport implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_TOTAL_SPENT_BY_DESTAIRPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_TOTAL_SPENT_BY_DESTAIRPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_TOTAL_SPENT_BY_DESTAIRPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getPublisherTotalSpentByDestairport(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'getPublisherTotalSpentByDestairport Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchFilterList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_FILTER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_FILTER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_FILTER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getFilterListPublisher(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchCountryClickView Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchUserList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchBusList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_BUS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_BUS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_BUS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await TransportsService.fetchTransportBussesList(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTopResults implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_RESULTS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_RESULTS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_RESULTS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getTopResults(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchTopResults Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTop5ResultsByStation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_5_RESULTS_BY_STATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_5_RESULTS_BY_STATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_5_RESULTS_BY_STATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getTop5ResultsByStation(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchTop5ResultsByStation Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTop5Stations implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_5_STATIONS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_5_STATIONS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_5_STATIONS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PubDashboardService.getTop5Stations(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchTop5Stations Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchCountryClickViewAction: (payload: any) =>
    new FetchCountryClickView().action(payload),
  fetchFilterListAction: (payload: any) =>
    new FetchFilterList().action(payload),
  fetchUserListAction: (payload: any) => new FetchUserList().action(payload),

  fetchRevenueAreaChartAction: (payload: any) =>
    new FetchRevenueAreaChart().action(payload),
  fetchFillrateBarchartAction: (payload: any) =>
    new FetchFillrateBarchart().action(payload),
  fetchRevenueBarchartAction: (payload: any) =>
    new FetchRevenueBarchart().action(payload),
  fetchImpressionAreachartAction: (payload: any) =>
    new FetchImpressionAreachart().action(payload),
  fetchCampaignClickViewAction: (payload: any) =>
    new FetchCampaignClickView().action(payload),
  fetchOSClickViewAction: (payload: any) =>
    new FetchOSClickView().action(payload),
  fetchFillrateByCountryAction: (payload: any) =>
    new FetchFillrateByCountry().action(payload),
  fetchTotalSpentByDestairportAction: (payload: any) =>
    new FetchTotalSpentByDestairport().action(payload),
  fetchBusListAction: (payload: any) => new FetchBusList().action(payload),
  fetchTopResultsAction: (payload: any) =>
    new FetchTopResults().action(payload),
  fetchTop5ResultsByStationAction: (payload: any) =>
    new FetchTop5ResultsByStation().action(payload),
  fetchTop5StationsAction: (payload: any) =>
    new FetchTop5Stations().action(payload),
};
