import { typeCreatorV2 } from '../../../redux/utils/common';

export const UPDATE_CAMPAIGN_WIZARD: string = typeCreatorV2(
  'CampaignReducer',
  'UpdateCampaignWizard'
);

export const SET_CLIENTS: string = typeCreatorV2(
  'CampaignReducer',
  'SetClients'
);

export const SET_SEGMENTS: string = typeCreatorV2(
  'CampaignReducer',
  'SetSegments'
);

export const CALCULATE_REACH_ON_BOARD: string = typeCreatorV2(
  'CampaignReducer',
  'CalculateReachOnBoard'
);

export const SCRAP_URL: string = typeCreatorV2('CampaignReducer', 'ScrapUrl');

export const ANALYZE_FILE: string = typeCreatorV2(
  'CampaignReducer',
  'AnalyzeFile'
);

export const CREATE_CAMPAIGN: string = typeCreatorV2(
  'CampaignReducer',
  'CreateCampaign'
);

export const UPDATE_CAMPAIGN: string = typeCreatorV2(
  'CampaignReducer',
  'UpdateCampaign'
);

export const RESET_CAMPAIGN_WIZARD: string = typeCreatorV2(
  'CampaignReducer',
  'ResetCampaignWizard'
);

export const INSERT_CAMPAIGN_WIZARD_BY_ID: string = typeCreatorV2(
  'CampaignReducer',
  'InsertCampaignWizardById'
);
export const SET_COUNTRIES: string = typeCreatorV2(
  'CampaignReducer',
  'SetCountries'
);

export const SET_POINTS: string = typeCreatorV2('CampaignReducer', 'SetPoint');

export const SET_TRANSPORT_STATIONS: string = typeCreatorV2(
  'CampaignReducer',
  'SetTransportStations'
);
