// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

interface IParams {
  userid: string | null;
  datefrom: string | null;
  dateto: string | null;
  enviromentworlds: number | null;
}

export const getCampaignStats = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  console.log(enviromentworlds, envQuery);

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/campaign-stats?${userQuery}${datefromQuery}${datetoQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getCountryClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/country-clicks-views?${userQuery}${datefromQuery}${datetoQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTotolImpressionClickByCampaign = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/total-impressions-clicks-by-campaign?${userQuery}${datefromQuery}${datetoQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTop5Campaign = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/top-5-campaign?${userQuery}${datefromQuery}${datetoQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTotalViewByArrCountry = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/total-views-by-Arr-Country?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTotalClickByArrCountry = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/total-clicks-by-Arr-Country?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getDeviceClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/device-clicks-views?${userQuery}${datefromQuery}${datetoQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTotalCountryView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/total-country-views?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTotalCountryClick = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, userid } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}` : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/total-country-clicks?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getCampaignStatsByEnv = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { userid, datefrom, dateto, enviromentworlds } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}&`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/campaign-stats-by-env?${userQuery}${envQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getStationClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const { userid, datefrom, dateto } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/station-clicks-views?${userQuery}${datefromQuery}${datetoQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTransportsPassengerStatsDaily = async (payload: {
  datefrom: string | null;
  dateto: string | null;
  publisher: string | null;
  type: string | null;
}): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, publisher, type } = payload;
  // const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisher ? `publisher=${publisher}&` : '';
  const typeQuery = type ? `type=${type}&` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/transports-passenger-stats-daily?${datefromQuery}${datetoQuery}${publisherQuery}${typeQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTop10PassengerStatsByStation = async (payload: {
  datefrom: string | null;
  dateto: string | null;
  publisher: string | null;
}): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, publisher } = payload;
  // const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisher ? `publisher=${publisher}&` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/top-10-passenger-stats-by-station?${datefromQuery}${datetoQuery}${publisherQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getTop10PassengerStatsByTransport = async (payload: {
  datefrom: string | null;
  dateto: string | null;
  publisher: string | null;
}): Promise<Types.ResponseOutput> => {
  const { datefrom, dateto, publisher } = payload;
  // const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisher ? `publisher=${publisher}&` : '';
  const typeQuery = 'type=BUS&';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-adv/top-10-passenger-stats?${datefromQuery}${datetoQuery}${publisherQuery}${typeQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
