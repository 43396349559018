import { LanguagesEnum } from '../../../config/languages';
import { IAction, IActionMethods } from '../../utils/common';
import * as Types from './types';

class ChangeLanguage implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(result: LanguagesEnum): IAction {
    return {
      type: Types.SET_LANGUAGE,
      data: result,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LANGUAGE,
      data: null,
    };
  }

  action(data: LanguagesEnum): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      console.log('ChangeLanguage Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

export default {
  changeLanguageAction: (data: LanguagesEnum) =>
    new ChangeLanguage().action(data),
};
