import { Environment } from '../../../config';
import request from '../../../utils/request';
import * as Types from '../types';

export interface IUploadFileRes {
  uploaded: boolean;
  payload: {
    file_name: string | null;
    file_url: string | null;
    type: string | null;
    type_id: string | null;
  };
}

export interface IUploadFileOutput extends Types.ResponseOutput {
  data: IUploadFileRes;
}

export const uploadFile = async (file: File): Promise<IUploadFileOutput> => {
  const formData = new FormData();
  formData.append('file', file);

  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/files/upload`,
    formData
  );
  return data;
};
