import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

interface ICardLabelProps {
  title: string | React.ReactElement;
  rest?: any;
  style?: React.CSSProperties;
}

export default function CardLabelTitle(props: ICardLabelProps) {
  const classes = useStyles();

  const { title, style = {} } = props;
  return (
    <Typography
      className={classes.title}
      variant="h6"
      id="tableTitle"
      component="div"
      {...(props.rest ? props.rest : {})}
      style={style}
    >
      {title}
    </Typography>
  );
}
