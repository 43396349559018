import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  CampaignsList: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  CampaignsList: {
    status: null,
    data: null,
  },
};

const CampaignsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_CAMPAIGNS_LIST:
      return {
        ...state,
        CampaignsList: {
          ...state.CampaignsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.CampaignsList.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default CampaignsReducer;
