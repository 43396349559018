import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_TOP_RESULT: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetTopResult'
);

export const SET_PASSENGER_BY_DATE: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetPassengerByDate'
);
export const SET_PASSENGER_BY_DESTINATION_AIRPORT: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetPassengerByDestinationAirport'
);

export const SET_PASSENGER_BY_DEPARTURE_AIRPORT: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetPassengerByDepartureAirport'
);

export const SET_TRACKING_BY_DEPARTURE: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetTrackingByDeparture'
);
export const SET_TRACKING_BY_DESTINATION: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetTrackingByDestination'
);

export const SET_VIEW_PER_DEVICE: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetViewPerDevice'
);

export const SET_TOP_10_VISITING_TAG: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetTop10VisitingTag'
);

export const SET_TOP_10_VISITING_URLS: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetTop10VisitingUrls'
);

export const SET_USER_LIST: string = typeCreatorV2(
  'IFEAnalyticsReducer',
  'SetUserList'
);
