import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  wallet: {
    status: StateStatus | null;
    data: any;
  };
  campaign: {
    status: StateStatus | null;
    data: any;
  };
  userList: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  wallet: {
    status: null,
    data: null,
  },
  campaign: {
    status: null,
    data: null,
  },
  userList: {
    status: null,
    data: null,
  },
};

const WalletReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_WALLET:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.wallet.data : action.data,
        },
      };
    case Types.SET_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.campaign.data : action.data,
        },
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default WalletReducer;
