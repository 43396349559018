import React from 'react';
import * as DatesUtils from '../../utils/dates';
import moment from 'moment';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;

export const TableColumns = [
  {
    label: 'Transaction ID',
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Posting Data/Time',
    id: 'postdate',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD/MM/YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Amount',
    id: 'originalamount',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Campaign',
    id: 'campaign',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Client',
    id: 'clientname',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Advertising Spend Type',
    id: 'comments',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Status',
    id: 'trxnstatus_descr',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 20,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};
