import React from 'react';
import Datatable from '../../../../components/molecules/datatable';
import { TableColumns } from '../../configuration';
import ActionsBlock from './ActionsBlock';
import * as CampaignsReducer from '../../../../redux/reducers/campaigns_reducer/selectors';
import { useDispatch } from 'react-redux';
import CampaignsActions from '../../../../redux/reducers/campaigns_reducer/actions';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';

export default function DatatableList() {
  const result = CampaignsReducer.useGetCampaignsList();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('desc');
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={result.data?.items || []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            CampaignsActions.fetchCampaignsListAction({
              sortField: _sortField,
              page: selectedPage,
              pageSize: pageSize,
              order: order,
            })
          );
        }}
      />
    </Box>
  );
}

export { ActionsBlock };
