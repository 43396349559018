// {{BASE_URL}}/api/schemeType
import { ObjectToQueryString } from 'redux/utils/common';
import { Environment } from '../../../config';
import * as Types from '../../../services/api/types';
import request from '../../../utils/request';
import { IListParalistFilter, Paralist } from './interfaces';

export const listParalistAPI = async ({
  sortField = 'groupcode',
  page = 0,
  pageSize = 100,
  order = 'asc',
  query,
}: IListParalistFilter): Promise<Types.ResponseOutput> => {
  const method = 'get';
  const url = Environment.API_BASE_URL;
  const queryStr = ObjectToQueryString(query);
  const link = `${url}/api/paralists/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}&${queryStr}`;
  const { data } = await request(method, link);

  return <Types.ResponseOutput>data;
};
