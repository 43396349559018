import React from 'react';
import { Card, Icon, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white !important',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '20px',
      width: '100%',
      height: '170px',
      padding: 15,
    },
    card: {
      display: 'flex',
      padding: 16,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      // fontFamily: "SF UI Text",
      fontStyle: 'normal',
      color: '#005FA3 !important',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '43px',
      paddingBottom: 8,
    },
    body: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#005FA3 !important',
    },
    description: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: '#005FA3 !important',
    },
    circle: {
      width: 100,
      height: 100,
      borderRadius: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 0px 4px rgba(48, 53, 124, 0.25)',
    },
    firstCircle: {
      width: 40,
      height: 40,
      borderRadius: 20,
      border: '3px solid #FFBB38',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    secondCircle: {
      width: 22,
      height: 22,
      borderRadius: 11,
      border: '3px solid #FFBB38',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    thirdCircle: {
      width: 4.44,
      height: 4.44,
      borderRadius: 2.22,
      border: '3px solid #FFBB38',
    },
  })
);

function Widget2(props: any) {
  const { data } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div>
          <Typography classes={{ root: classes.title }} color="textSecondary">
            {data.title}
          </Typography>
          <Typography classes={{ root: classes.body }} color="textSecondary">
            {data.body}
          </Typography>
          <Typography classes={{ root: classes.description }} color="textSecondary">
            {data.description}
          </Typography>
        </div>
        {data.body === 'CTR' && (
          <div className={classes.circle} style={{ background: '#FFF5D9' }}>
            <div className={classes.firstCircle}>
              <div className={classes.secondCircle}>
                <div className={classes.thirdCircle} />
              </div>
            </div>
          </div>
        )}
        {data.body === 'Impression' && (
          <div className={classes.circle} style={{ background: '#E7EDFF' }}>
            <img alt="impression" src="assets/images/impression.png" />
          </div>
        )}
        {data.body === 'Clicks' && (
          <div className={classes.circle} style={{ background: '#DCFAF8' }}>
            <img alt="clicks" src="assets/images/clicks.png" />
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(Widget2);
