import * as Types from './types';
import * as RegisterService from 'services/api/register';
import { Dispatch } from 'redux';
import { LanguagesEnum } from 'config/languages';
import NavigationConfig from 'navigation/config';
import { IAction, IActionMethods, StateStatus } from 'redux/utils/common';
import * as UserServices from 'services/api/user';
import * as UserRegister from 'services/api/user';
import * as AuthenticationService from 'services/api/authenticate';
import { showToastAction } from 'components/atoms/toast_message';

/** Fetch SchemeTypes  */

class FetchUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserServices.getUserProfile();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchUserProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Countries */
class FetchUserProfileCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.getCountries();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchUserProfileCountries Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Edit Profile */

interface IEditUserProfileInput {
  email: string;
  firstName: string;
  surname: string;
  nationality: string;
  gender: string;
  birthDate: string;
  address: string;
  company: string;
  phone: string;
}
class EditUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditUserProfileInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.editUserProfile(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchUserProfile().action());
        showToastAction(
          dispatch,
          {
            [LanguagesEnum.English]: 'Profile details changed',
            [LanguagesEnum.Greek]: 'Τα στοιχεία προφίλ άλλαξαν',
            [LanguagesEnum.Russian]: 'Данные профиля изменены',
          },
          'success'
        );
      } catch (error) {
        console.log('EditProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        showToastAction(
          dispatch,
          {
            [LanguagesEnum.English]: 'Something went wrong',
            [LanguagesEnum.Greek]: 'Κάτι πήγε στραβά',
            [LanguagesEnum.Russian]: 'Что-то пошло не так',
          },
          'error'
        );
      }
    };
  }
}

/** Change Password */

interface IChangePasswordInput {
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
}

class ChangePassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IChangePasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let result = await AuthenticationService.changePassword({
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
          oldPassword: data.oldPassword,
        });
        dispatch(this.onSuccess());
        // data.history.push(NavigationConfig.loginPage().path);
        showToastAction(
          dispatch,
          {
            [LanguagesEnum.English]: 'Password changed',
            [LanguagesEnum.Greek]: 'Ο κωδικός άλλαξε',
            [LanguagesEnum.Russian]: 'Пароль изменен',
          },
          'success'
        );
      } catch (error) {
        console.log('ResetPassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  editUserProfileAction: (payload: IEditUserProfileInput) =>
    new EditUserProfile().action(payload),
  fetchUserProfileAction: () => new FetchUserProfile().action(),
  fetchUserProfileCountriesAction: () =>
    new FetchUserProfileCountries().action(),
  changePasswordAction: (
    newPassword: string,
    confirmPassword: string,
    oldPassword: string
  ) =>
    new ChangePassword().action({ newPassword, confirmPassword, oldPassword }),
};
