import { KEEP_THE_SAME, IAction as IActionCommon } from 'redux/utils/common';
import { IAction, IState } from './interfaces';
import { GET_PUBLISHER, GET_PUBLISHERS, UPDATE_PUBLISHER } from './types';
import * as Types from './types';

const initialState: IState = {
  status: undefined,
  list: [],
  publisherSelected: undefined,
  publisherSelectedExclusiveList: undefined,
  paging: {},
  listSlaveFromMaster: {
    data: null,
    status: null,
  },
  deletePublisherConnection: {
    status: null,
  },
  getAllPublisher: {
    data: null,
    status: null,
  },
  addPublisherConnection: {
    status: null,
  },
  detailPublisher: {
    data: null,
    status: null,
  },
};

const Reducer = (
  state: IState = initialState,
  action: IAction | any
): IState => {
  switch (action.type) {
    case GET_PUBLISHERS.Pending:
    case GET_PUBLISHER.Pending:
      return { ...state, status: action.type };

    case GET_PUBLISHERS.Success:
      return {
        ...state,
        status: action.type,
        list: action.list,
        paging: action.paging,
      };

    case GET_PUBLISHER.Success:
      return {
        ...state,
        ...action,
      };

    case UPDATE_PUBLISHER.Success:
      return {
        ...state,
        list: (state?.list || []).map((pub) =>
          pub.id == action.publisherSelected?.id
            ? action.publisherSelected
            : pub
        ),
        publisherSelected: undefined,
      };
    case Types.GET_LIST_SLAVE_FROM_MASTER:
      return {
        ...state,
        listSlaveFromMaster: {
          ...state.listSlaveFromMaster,
          status: action?.status || null,
          data: action?.data,
        },
      };
    case Types.DELETE_PUBLISHER_CONNECTION:
      return {
        ...state,
        deletePublisherConnection: {
          ...state.deletePublisherConnection,
          status: action?.status || null,
        },
      };
    case Types.GET_ALL_PUBLISHER:
      return {
        ...state,
        getAllPublisher: {
          ...state.getAllPublisher,
          status: action?.status || null,
          data: action?.data,
        },
      };
    case Types.ADD_PUBLISHER_CONNECTION:
      return {
        ...state,
        addPublisherConnection: {
          ...state.addPublisherConnection,
          status: action?.status || null,
        },
      };

    case Types.GET_DETAIL_PUBLISHER:
      return {
        ...state,
        detailPublisher: {
          ...state.detailPublisher,
          status: action?.status || null,
          data: action?.data,
        },
      };
    default:
      return state;
  }
};

export default Reducer;
