import React, { useState } from 'react';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import { CampaignAgeGroups, IAgeGroup } from './configuration';
import { Box, useTheme } from '@material-ui/core';
import CampaignOption from 'components/app_common/option';
import clsx from 'clsx';
import ICampaignInfoWizard from 'config/campaign_wizard';
import CardLayout from 'components/app_common/card_layout';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const AgeGroup = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Array<number>>([]);
  const { campaign } = props;

  React.useEffect(() => {
    if (!campaign.age_group) {
      return setSelected(new Array<number>());
    } else {
      let selectedAgeGroups: Array<number> = new Array<number>();
      for (let ageGroup of campaign.age_group)
        for (let index in CampaignAgeGroups)
          if (CampaignAgeGroups[index].code === ageGroup) {
            selectedAgeGroups.push(Number.parseInt(index));
            break;
          }
      setSelected(selectedAgeGroups);
    }
  }, [campaign]);

  React.useEffect(() => {
    let selectedAgeGroups: Array<string> = new Array<string>();
    for (let ageGroup of selected)
      selectedAgeGroups.push(CampaignAgeGroups[ageGroup].code);

    ref.current = selectedAgeGroups;
  }, [selected]);

  function onSelectionChange(index: number) {
    const arrayIndex = selected.indexOf(index);
    if (arrayIndex >= 0) selected.splice(arrayIndex, 1);
    else selected.push(index);
    setSelected([...selected]);
  }

  return (
    <div className={classes.pageContainer}>
      <CardLayout
        cardStyling={{ width: '100%' }}
        body={
          <React.Fragment>
            <Box
              style={{
                paddingLeft: '4px',
                fontWeight: 'bold',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              <TranslatedText textMap={Translations.age_group} />
            </Box>
            <GridLayout
              justify="center"
              elements={CampaignAgeGroups.slice(
                0,
                CampaignAgeGroups.length / 2
              ).map((AgeGroup: IAgeGroup, index: number) => ({
                id: index.toString(),
                element: (
                  <div key={index} className={classes.optionItem}>
                    <AgeGroupOption
                      key={index}
                      AgeGroup={AgeGroup}
                      isSelected={selected.includes(index)}
                      index={index}
                      onSelectionChange={onSelectionChange}
                    />
                  </div>
                ),
                size: 4,
                md: 12,
              }))}
            />
            <GridLayout
              justify="center"
              elements={CampaignAgeGroups.slice(
                CampaignAgeGroups.length / 2,
                CampaignAgeGroups.length
              ).map((AgeGroup: IAgeGroup, index: number) => ({
                id: (index + CampaignAgeGroups.length / 2).toString(),
                element: (
                  <div
                    key={index + CampaignAgeGroups.length / 2}
                    className={classes.optionItem}
                  >
                    <AgeGroupOption
                      key={index + CampaignAgeGroups.length / 2}
                      AgeGroup={AgeGroup}
                      isSelected={selected.includes(
                        index + CampaignAgeGroups.length / 2
                      )}
                      index={index + CampaignAgeGroups.length / 2}
                      onSelectionChange={onSelectionChange}
                    />
                  </div>
                ),
                size: 4,
                md: 12,
              }))}
            />
          </React.Fragment>
        }
      />
    </div>
  );
});

interface IAgeGroupProps {
  AgeGroup: IAgeGroup;
  isSelected: boolean;
  index: number;
  onSelectionChange: any;
}

const AgeGroupOption = (props: IAgeGroupProps) => {
  const { AgeGroup, isSelected, index, onSelectionChange } = props;
  const theme = useTheme();
  const classes = useStyles();

  const AgeGroupOptionHeader = () => {
    return <span>{AgeGroup.name}</span>;
  };

  const AgeGroupOptionBody = () => {
    return (
      <div
        className={clsx({
          [classes.optionItemBody]: true,
          [classes.optionItemBodySelected]: isSelected,
        })}
      >
        {AgeGroup.image}
      </div>
    );
  };

  return (
    <CampaignOption
      isSelected={isSelected}
      index={index}
      onSelectionChange={onSelectionChange}
      header={<AgeGroupOptionHeader />}
      body={<AgeGroupOptionBody />}
    />
  );
};

export default AgeGroup;
