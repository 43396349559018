import React from 'react';
import moment from 'moment'
import * as DatesUtils from '../../utils/dates';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;
//Columns
/*
     
   code 
    , flightdate 
    , airline 
    , deptairport 
    , arrairport 
    , estpassengers 
    , actpassengers 
    , views 
    , clicks
     */
//table key
//
export const TableColumns = [
  {
    label: 'Publisher Name',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Flight Code',
    id: 'code',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Flight Date',
    id: 'flightdate',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{moment(cellValue).format("YYYY-MM-DD")}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Airline',
    id: 'airline',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Dept Airport',
    id: 'deptairport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Arr Airport',
    id: 'arrairport',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Passengers',
    id: 'estpassengers',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Actual Passengers',
    id: 'actpassengers',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Impressions',
    id: 'views',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Clicks',
    id: 'clicks',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Tail',
    id: 'tail',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'S/N',
    id: 'hostname',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: 'flightdate',
};
