import React, { ReactElement } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  DatePicker,
  DatePickerProps
} from '@material-ui/pickers';
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import { Control, Controller } from 'react-hook-form';

interface IDatePickerProps
  extends Omit<KeyboardDatePickerProps, 'onChange' | 'value'> {
  control: Control;
  rules: any;
  name: string;
  handleChange?: (data: Date | null) => void;
  controllerExtras?: any;
}

export default function MUIDatePicker(props: IDatePickerProps): ReactElement {
  const {
    control,
    rules,
    handleChange,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            onChange={(date, value) => {
              handleChange && handleChange(date);
              onChange(date, value);
              console.log("date", date, value)
            }}
            {...other}
            format={other.format || 'dd-MM-yyyy'}
            variant={other.variant || 'inline'}
            inputVariant={other.inputVariant || 'outlined'}
            value={value}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      )}
      name={other.name}
      rules={rules}
      control={control}
      {...controllerExtras}
    />
  );
}

interface IDatePickerProps
  extends Omit<DatePickerProps, 'onChange' | 'value'> {
  control: Control;
  rules: any;
  name: string;
  handleChange?: (data: Date | null) => void;
  controllerExtras?: any;
}

export function PopOverDatePicker(props: IDatePickerProps): ReactElement {
  const {
    control,
    rules,
    handleChange,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableToolbar
            onChange={(date) => {

              handleChange && handleChange(date)
              onChange(date, moment(date).format('DD-MM-YYYY'))
            }}
            // onChange={(date, value) => {
            //   handleChange && handleChange(date);
            //   onChange(date, value);
            // }}
            {...other}
            format={other.format || 'dd-MM-yyyy'}
            variant={other.variant || 'inline'}
            inputVariant={other.inputVariant || 'outlined'}
            value={value}
            // KeyboardButtonProps={{
            //   'aria-label': 'change date',
            // }}
          />
        </MuiPickersUtilsProvider>
      )}
      name={other.name}
      rules={rules}
      control={control}
      {...controllerExtras}
    />
  );
}
