import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { LanguagesEnum } from 'config/languages';
import TranslatedText from '../../atoms/translated_text';
import BaseModal from '../../molecules/base_modal';
import Loading from 'components/atoms/loading';

interface ITranslatedField {
  [LanguagesEnum.Greek]: string;
  [LanguagesEnum.English]: string;
  [LanguagesEnum.Russian]: string;
}

interface Action {
  label: ITranslatedField | string;
  onClick: (data?: any) => void;
  loading?: boolean;
  disabled?: boolean;
}

interface IProps {
  MODAL_ID: number;
  title: ITranslatedField | string;
  body: ITranslatedField | string;
  actions: Array<Action>;
  modalProps?: any;
}

export default function Dialog(props: IProps) {
  return (
    <BaseModal
      modalProps={props.modalProps}
      modalID={props.MODAL_ID}
      renderHeader={() => <DialogHeader title={props.title} />}
      renderContent={(contentProps) => (
        <DialogBody
          body={props.body}
          actions={props.actions}
          {...contentProps}
        />
      )}
    />
  );
}

interface IHeaderProps {
  title: ITranslatedField | string;
}
const DialogHeader = (props: IHeaderProps) => {
  return (
    <span>
      {typeof props.title === 'object' ? (
        <TranslatedText textMap={props.title} />
      ) : (
        props.title
      )}
    </span>
  );
};

interface IBodyProps {
  body: ITranslatedField | string;
  actions: Array<Action>;
  params: any;
}
const DialogBody = (props: IBodyProps) => {
  return (
    <Fragment>
      <div>
        {typeof props.body === 'object' ? (
          <TranslatedText textMap={props.body} />
        ) : (
          props.body
        )}
      </div>
      <div style={{ textAlign: 'right', marginTop: '1rem' }}>
        {props.actions.map((value: Action, index: number) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                value.onClick(props.params);
              }}
              key={index}
              style={{
                marginLeft: '.5rem',
              }}
              disabled={value.disabled ?? false}
            >
              {value.loading && (
                <Loading
                  style={{ width: '20px', marginRight: '1rem', height: '20px' }}
                />
              )}
              <span>
                {typeof value.label === 'object' ? (
                  <TranslatedText textMap={value.label} />
                ) : (
                  value.label
                )}
              </span>
            </Button>
          );
        })}
      </div>
    </Fragment>
  );
};
