import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchUser_Price_CalculationList  */
interface IUser_Price_CalculationListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  id: number;
  user_id: string | null;
  flat: string | null;
  airline: string | null;
  country: string | null;
  airport: string | null;
  cpm: string | null;
  cpc: string | null;
  cpa: string | null;
  advertisment_type: number;
  userid: string | null;
  utc: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IUser_Price_CalculationListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  User_id?: number;
}

export const fetchUser_Price_CalculationList = async (
  payload: IUser_Price_CalculationListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user_price_calculation/list?page=${
      payload.page
    }&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${
      payload.order
    }${payload.User_id ? `&User_id=${payload.User_id}` : ''}`
  );

  let _data: IUser_Price_CalculationListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** User_Price_Calculation Detail */
interface IUser_Price_CalculationDetailInput {
  id: number;
}

interface IUser_Price_CalculationDetailOutput extends Types.ResponseOutput {
  data: {
    id: number | null;
    user_id: string | null;
    flat: string | null;
    airline: string | null;
    country: string | null;
    airport: string | null;
    cpm: number | null;
    cpc: number | null;
    cpa: number | null;
    advertisment_type: number | null;
    userid: string | null;
    utc: string | null;
  };
}

export const fetchUser_Price_CalculationDetail = async (
  payload: IUser_Price_CalculationDetailInput
): Promise<IUser_Price_CalculationDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user_price_calculation/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add User_Price_Calculation */
interface IUser_Price_CalculationAddInput {
  flat: boolean | null;
  airline: string | null;
  // country: string | null;
  airport: string | null;
  cpm: number | null;
  cpc: number | null;
  cpa: number | null;
  advertisment_type: number | null;
  user_id: string | null;
}

interface IUser_Price_CalculationAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addUser_Price_Calculation = async (
  payload: IUser_Price_CalculationAddInput
): Promise<IUser_Price_CalculationAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/user_price_calculation `,
    {
      ...payload,
      utc: new Date(new Date()).toISOString(),
    }
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit User_Price_Calculation */
interface IUser_Price_CalculationEditInput {
  id: string | null;
  flat: boolean | null;
  airline: string | null;
  country: string | null;
  airport: string | null;
  cpm: number | null;
  cpc: number | null;
  cpa: number | null;
  advertisment_type: number | null;
  user_id: string | null;
}

interface IUser_Price_CalculationEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editUser_Price_Calculation = async (
  payload: IUser_Price_CalculationEditInput
): Promise<IUser_Price_CalculationEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user_price_calculation/${payload.id}`,
    {
      ...payload,
      utc: new Date(new Date()).toISOString(),
    }
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const deleteUser_Price_Calculation = async (
  id: number
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/user_price_calculation/${id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
