import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

interface ILoadingViewProps extends CircularProgressProps {
  fullScreen?: boolean;
}

export default function LoadingView(props: ILoadingViewProps) {
  const { fullScreen, className, ...rest } = props;
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.fullScreen]: fullScreen,
      })}
    >
      <CircularProgress
        className={clsx(classes.loadingView, className)}
        {...rest}
      />
    </Box>
  );
}
