import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export const fetchWallet = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/wallet/${payload.id}?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}&isSearch=${payload.search.isSearch}&campaign=${payload.search.values.campaign}&clientname=${payload.search.values.clientname}&startdate=${payload.search.values.startdate}&enddate=${payload.search.values.enddate}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
