import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetCampaignsStats = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].CampaignsStats
  );
  return data;
};

export const useGetCountryClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].countryClickView
  );
  return data;
};

export const useGetTotalImpressionClickByCampaign = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].totalImpressionClickByCampaign
  );
  return data;
};
export const useGetTop5Campaign = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].top5Campaign
  );
  return data;
};

export const useGetTotalViewByArrCountry = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].totalViewByArrCountry
  );
  return data;
};

export const useGetTotalClickByArrCountry = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].totalClickByArrCountry
  );
  return data;
};

export const useGetDeviceClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].deviceClickView
  );
  return data;
};
export const useGetTotalCountryView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].totalCountryView
  );
  return data;
};
export const useGetTotalCountryClick = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].totalCountryClick
  );
  return data;
};

export const useGetUserList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].userList
  );
  return data;
};

export const useGetStationClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ADV_DASHBOARD_REDUCER].stationClickView
  );
  return data;
};

export const useGetTransportsPassengerStatsDaily = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].transportsPassengerStatsDaily
  );
  return data;
};

export const useGetTop10PassengerStatsByStation = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].top10PassengerStatsByStation
  );
  return data;
};

export const useGetTop10PassengerStatsByTransport = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADV_DASHBOARD_REDUCER].top10PassengerStatsByTransport
  );
  return data;
};
