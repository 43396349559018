import { Box, Button } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import useStyles from './styles';
import { SetFieldValue } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import ImageShow from '../image_show';

interface IProp {
  name: string;
  setValue: SetFieldValue<any>;
  disabled?: boolean;
  linkPicture?: string;
  isDeletePhoto?: boolean;
}

const ImageUpload = ({
  name,
  setValue,
  disabled = false,
  linkPicture = '',
  isDeletePhoto = false,
}: IProp) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<any>(linkPicture);
  const [demension, setDemension] = useState<any>();
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const handleUploadClick = (event: any) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      setValue(name, undefined);
      setDemension(undefined);
      return;
    }
    setSelectedFile(files[0]);
    setValue(name, files[0]);
    // Get demension of image
    const image = new Image();
    let fr = new FileReader();
    fr.onload = function () {
      if (fr !== null && typeof fr.result == 'string') {
        image.src = fr.result;
      }
    };
    fr.readAsDataURL(files[0]);
    image.onload = async function () {
      const width = image.width;
      const height = image.height;
      setDemension({ width, height });
    };
  };
  const handleClickDelete = () => {
    setSelectedFile(undefined);
    setValue(name, undefined);
    setDemension(undefined);
  };
  return (
    <Box className={classes.imageUploadBox}>
      <Box className="box-image">
        {preview || linkPicture ? (
          <Fragment>
            {isDeletePhoto && (
              <Box onClick={() => handleClickDelete()}>
                <CloseIcon />
              </Box>
            )}
            <ImageShow
              picture={preview ? preview : linkPicture}
              customStyle={{
                width: '100%',
                height: '100%',
              }}
            />
          </Fragment>
        ) : (
          <PermMediaIcon />
        )}
      </Box>
      <Box style={{ textAlign: 'center' }}>
        <label htmlFor={name}>
          {!disabled && (
            <input
              style={{ display: 'none' }}
              accept="image/*"
              id={name}
              name={name}
              type="file"
              onChange={(event: any) => {
                if (event.target.files.length) {
                  handleUploadClick(event);
                }
              }}
            />
          )}
          <Button
            color="default"
            variant="contained"
            component="span"
            className="button-upload"
            disabled={disabled}
          >
            Upload
          </Button>
        </label>
      </Box>
      {demension && (
        <span className="demension">
          Dimensions(w x h): {demension?.width}x{demension?.height}
        </span>
      )}
    </Box>
  );
};

export default ImageUpload;
