import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Select, FormControl, MenuItem } from '@material-ui/core';
import GridLayout from 'components/molecules/grid_layout';
import CampaignCard from './components/CampaignCard';
import useStyles from './styles';
import Actions from 'redux/reducers/advertiser_reducer/actions';
import { useGetCampaignsAdvertisement } from 'redux/reducers/advertiser_reducer/selectors';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';

export default function AdvertisementApproval() {
  const [status, setstatus] = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useSelectUserData();
  const advertisements = useGetCampaignsAdvertisement();
  useEffect(() => {
    dispatch(Actions.fetchCampaignsAdvertisementAction({ userid: id }));
    dispatch(Actions.fetchConnectedPublisherActions({ userid: id }));
  }, [id]);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    setstatus(event.target.value as number);
    dispatch(
      Actions.fetchCampaignsAdvertisementAction({
        userid: id,
        status: event.target.value,
        activeStatus: -1,
      })
    );
  }

  return (
    <React.Fragment>
      <FormControl className={clsx(classes.mt12, classes.mb24)}>
        <Select
          disableUnderline
          className={clsx(
            classes.boxShadow,
            classes['w-256'],
            classes['ml-12']
          )}
          value={status}
          onChange={handleChange}
        >
          <MenuItem value={0}>
            <TranslatedText textMap={Translations.status_normal} />
          </MenuItem>
          <MenuItem value={1}>
            <TranslatedText textMap={Translations.status_pending} />
          </MenuItem>
          <MenuItem value={2}>
            <TranslatedText textMap={Translations.status_expired} />
          </MenuItem>
          <MenuItem value={3}>
            <TranslatedText textMap={Translations.status_deleted} />
          </MenuItem>
          <MenuItem value={4}>
            <TranslatedText textMap={Translations.status_finished} />
          </MenuItem>
          <MenuItem value={5}>
            <TranslatedText textMap={Translations.status_insufficient} />
          </MenuItem>
        </Select>
      </FormControl>
      {advertisements.status === 'Success' && (
        <GridLayout
          elements={advertisements.data.map((ad: any) => {
            return {
              id: ad.id,
              size: 4,
              element: <CampaignCard data={ad} />,
            };
          })}
        />
      )}
    </React.Fragment>
  );
}
