import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType2 } from '../configuration';

export default function getConfigClick(data: any) {
  const DashboardBuilderConfigClick: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: '',
            format: {
              backgroundColor: ['#FF5E90', '#439CDD', '#4FCBDF'],
              borderWidth: 1,
            },
          },
        ],
        // labels: ['Y', 'N'],
        labels: data.labels,
        chartType: ChartTypesEnum.PIE,
        options: OptionsGraphType2(70000),
        title: ``,
      },
      fetch: null,
    },
  ];
  return DashboardBuilderConfigClick;
}
