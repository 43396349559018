import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_WALLET: string = typeCreatorV2('WalletReducer', 'SetWallet');
export const SET_CAMPAIGN: string = typeCreatorV2(
  'WalletReducer',
  'SetCampaign'
);

export const SET_USER_LIST: string = typeCreatorV2(
  'WalletReducer',
  'SetUserList'
);
