import _ from 'lodash';
import moment from 'moment';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

function colorGenerator() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

function createLineDataset(data: any[]) {
  const sortedFlightDate = data
    .sort(function (a: any, b: any) {
      return (
        new Date(a.FlightDate).getTime() - new Date(b.FlightDate).getTime()
      );
    })
    .map((item: any) => item.FlightDate);
  const views_mobile = data
    .sort(function (a: any, b: any) {
      return (
        new Date(a.FlightDate).getTime() - new Date(b.FlightDate).getTime()
      );
    })
    .map((item: any) => item.views_mobile);
  const views_desktop = data
    .sort(function (a: any, b: any) {
      return (
        new Date(a.FlightDate).getTime() - new Date(b.FlightDate).getTime()
      );
    })
    .map((item: any) => item.views_desktop);
  const views_tablet = data
    .sort(function (a: any, b: any) {
      return (
        new Date(a.FlightDate).getTime() - new Date(b.FlightDate).getTime()
      );
    })
    .map((item: any) => item.views_tablet);
  const result = [
    { id: 'Views mobile', data: views_mobile },
    { id: 'Views desktop', data: views_desktop },
    { id: 'Views tablet', data: views_tablet },
  ].map((line: any) => {
    const color = colorGenerator();

    return {
      label: line.id,
      data: line.data,
      format: {
        label: line.id,
        fill: false,
        borderColor: color,
        lineTension: 0.1, // make the lines without radius
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
      },
    };
  });

  const dataSet = {
    labels: sortedFlightDate,
    data: result,
  };
  return dataSet;
}

function getLineChartConfig(data: any) {
  const dataSet = createLineDataset(
    data.filter(
      (item: any) =>
        item.views_mobile && item.views_desktop && item.views_tablet
    )
  );

  const LineChartsConfig: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      props: {
        data: dataSet.data,
        labels: dataSet.labels,
        chartType: ChartTypesEnum.LINE,
        options: {
          legend: {
            display: true,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
          },
        },
        title: `View per device`,
      },
      fetch: null,
    },
  ];
  return LineChartsConfig;
}

export default getLineChartConfig;
