import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, Box, Tooltip } from '@material-ui/core';
import useStyles from '../../styles';

export default function ProgressBar(props: any) {
  const classes = useStyles();
  return (
    <Tooltip title={`${props.percentage} %`} placement="top">
      <Box className={classes.mb24} display="flex" alignItems="center">
        <div style={{ width: 60, margin: '0 10px' }}>
          <Typography
            classes={{ root: classes.progressText }}
            variant="body2"
            color="textSecondary"
          >
            {props.name}
          </Typography>
        </div>
        <Box width="100%" mr={1}>
          <LinearProgress
            classes={{
              root: classes.progress,
              bar1Determinate: classes.barProgess,
            }}
            variant="determinate"
            value={props.percentage}
          />
        </Box>
        <Typography
          classes={{ root: classes.progressText }}
          variant="body2"
          color="textSecondary"
        >
          {props.value}
        </Typography>
      </Box>
    </Tooltip>
  );
}
