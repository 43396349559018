import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { EuroOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment'
import LineChart from 'components/molecules/custom_chart/components/apex_charts';
import { useGetWallet } from '../../../redux/reducers/wallet_reducer/selectors';
import useStyles from '../styles';

import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
var config = {
  series: [
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ],
  options: {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Balance Movement',
      align: 'left',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
  },
};

export default function Balance() {
  const wallet = useGetWallet();
  const classes = useStyles();
  const [balanceGraphData, setBalanceGraphData] = useState([
    { name: '', data: [] },
  ]);
  const [options, setOptions] = useState(config.options);
  const [balance, setBalance] = useState({
    Success: 0,
    Balance: 0,
    Reason: '',
    Currency: '',
  });

  useEffect(() => {
    if (wallet.status === 'Success') {
      const transactions = wallet?.data?.history?.items;
      const balanceData = wallet?.data?.balance;
      setBalanceGraphData([
        {
          name: 'Balance',
          data: transactions.map((tran: any) => tran.originalamount),
        },
      ]);
      setBalance(balanceData);
      setOptions({
        ...config.options,
        xaxis: {
          categories: transactions.map((tran: any) => moment(tran.originaldate).format('DD-MM-YYYY')),
        },
      });
    }
  }, [wallet]);

  return (
    <Card className={clsx(classes.mb12, classes.card)}>
      <CardHeader
        className={classes.color}
        title={
          <div className={clsx(classes.flex, classes.itemsCenter)}>
            <TranslatedText textMap={Translations.wallet_balance} />{' '}
            <EuroOutlined />
            {`${balance.Balance} `}
          </div>
        }
      />
      <CardContent>
        <LineChart series={balanceGraphData} options={options} type="area" />
      </CardContent>
    </Card>
  );
}
