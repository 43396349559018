import { Languages } from '../../config';

const Translations = {
  search_publisher: {
    [Languages.English]: 'Search Publisher',
    [Languages.Greek]: 'Αναζήτηση εκδότη',
    [Languages.Russian]: 'Поиск издателя',
  },
  search_code: {
    [Languages.English]: 'Search Code',
    [Languages.Greek]: 'Κωδικός αναζήτησης',
    [Languages.Russian]: 'Код поиска',
  },
  search_button: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  edit: {
    [Languages.English]: 'Edit',
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.Russian]: 'Редактировать',
  },
};

export default Translations;
