import React from 'react';
import useStyles from '../../styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { PreviewSteps, LayoutSteps } from './configuration';
import Translations from '../../translations';
import TranslatedText from 'components/atoms/translated_text';
import PreviewAdDetails from '../preview_ad_details';
import PreviewAudience from '../preview_audience';
import PreviewCampaignInfo from '../preview_campaign_info';
import PreviewDestinations from '../preview_destinations';
import PreviewSubmit from '../preview_submit';
import TabActions from '../tab_actions';
import { IView } from '../../tab_stepper';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Dialog from 'components/compounds/dialog';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { StepButton } from '@material-ui/core';
import { StateStatus } from 'redux/utils/common';

interface IProps {
  view: IView;
  handleNext: { (): void };
  handlePrevious: { (): void };
  handleChangeStep: { (step: number): void };
}

export default function PreviewView(props: IProps) {
  const { view, handleNext, handlePrevious, handleChangeStep } = props;
  const classes = useStyles();
  const activeStep = view.step as number;
  const campaign = CampaignSelectors.useGetCampaignWizardValues();
  const dispatch = useDispatch();
  const params = useParams();
  const id = _.get(params, 'id', null);

  React.useEffect(() => {}, [campaign.display]);

  const handleSubmit = () => {
    if (id === 'new') {
      dispatch(
        ModalActions.openModalAction({
          modalID: ModalIDs.MODAL_CREATE_CAMPAIGN_CONFIRMATION,
          params: {},
        })
      );
    } else {
      dispatch(
        ModalActions.openModalAction({
          modalID: ModalIDs.MODAL_UPDATE_CAMPAIGN_CONFIRMATION,
          params: {},
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div>
        <Stepper nonLinear className={classes.stepper} activeStep={activeStep}>
          {/* Adjust steps when display is not for transportation */}
          {campaign.display !== 2
            ? LayoutSteps.filter(({ id }) => id !== PreviewSteps.AUDIENCE).map(
                ({ id, label }) => {
                  return (
                    <Step key={id}>
                      <StepButton
                        onClick={() =>
                          handleChangeStep(
                            id > PreviewSteps.AUDIENCE ? id - 1 : id
                          )
                        }
                      >
                        <TranslatedText textMap={label} />
                      </StepButton>
                    </Step>
                  );
                }
              )
            : LayoutSteps.map(({ id, label }) => {
                return (
                  <Step key={id}>
                    <StepButton onClick={() => handleChangeStep(id)}>
                      <TranslatedText textMap={label} />
                    </StepButton>
                  </Step>
                );
              })}
        </Stepper>
        <TabActions
          view={view}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleSubmit={handleSubmit}
          display={campaign.display}
        />
        {activeStep === PreviewSteps.CAMPAIGN_INFO && (
          <PreviewCampaignInfo campaign={campaign} />
        )}
        {/* Load only for Transport display */}
        {activeStep === PreviewSteps.AUDIENCE && campaign.display === 2 && (
          <PreviewAudience campaign={campaign} />
        )}
        {((campaign.display === 2 &&
          activeStep === PreviewSteps.DESTINATIONS) ||
          (campaign.display !== 2 &&
            activeStep === PreviewSteps.DESTINATIONS - 1)) && (
          <PreviewDestinations campaign={campaign} />
        )}
        {((campaign.display === 2 && activeStep === PreviewSteps.AD_DETAILS) ||
          (campaign.display !== 2 &&
            activeStep === PreviewSteps.AD_DETAILS - 1)) && (
          <PreviewAdDetails campaign={campaign} />
        )}
        {((campaign.display === 2 && activeStep === PreviewSteps.SUBMIT) ||
          (campaign.display !== 2 &&
            activeStep === PreviewSteps.SUBMIT - 1)) && <PreviewSubmit />}
      </div>
      {id === 'new' ? (
        <CreateCampaignConfirmationDialog />
      ) : (
        <UpdateCampaignConfirmationDialog id={Number.parseInt(id)} />
      )}
    </React.Fragment>
  );
}

const CreateCampaignConfirmationDialog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const submitCampaign = () => {
    let campaignReducer = getState(ReducerKeys.CAMPAIGN_REDUCER);
    let data = _.get(campaignReducer, 'CampaignWizard.data', {});
    dispatch(CampaignActions.createCampaignAction({ data, history }));
  };

  const createStatus = CampaignSelectors.useGetCreateCampaignStatus();

  return (
    <Dialog
      MODAL_ID={ModalIDs.MODAL_CREATE_CAMPAIGN_CONFIRMATION}
      title={Translations.confirmation_modal_title}
      body={Translations.confirmation_modal_body}
      modalProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      actions={[
        {
          label: Translations.confirmation_modal_yes,
          onClick: submitCampaign,
          loading:
            createStatus && createStatus === StateStatus.Pending ? true : false,
          disabled:
            createStatus && createStatus === StateStatus.Pending ? true : false,
        },
        {
          label: Translations.confirmation_modal_no,
          onClick: () =>
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_CREATE_CAMPAIGN_CONFIRMATION,
              })
            ),
          disabled:
            createStatus && createStatus === StateStatus.Pending ? true : false,
        },
      ]}
    />
  );
};

interface IUpdateCampaign {
  id: number;
}
const UpdateCampaignConfirmationDialog = (props: IUpdateCampaign) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const submitCampaign = () => {
    let campaignReducer = getState(ReducerKeys.CAMPAIGN_REDUCER);
    let data = _.get(campaignReducer, 'CampaignWizard.data', {});
    dispatch(
      CampaignActions.updateCampaignAction({ data, history, id: props.id })
    );
  };

  let editStatus = CampaignSelectors.useGetUpdateCampaignStatus();

  return (
    <Dialog
      MODAL_ID={ModalIDs.MODAL_UPDATE_CAMPAIGN_CONFIRMATION}
      title={Translations.edit_confirmation_modal_title}
      body={Translations.edit_confirmation_modal_body}
      modalProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      actions={[
        {
          label: Translations.confirmation_modal_yes,
          onClick: submitCampaign,
          loading:
            editStatus && editStatus === StateStatus.Pending ? true : false,
          disabled:
            editStatus && editStatus === StateStatus.Pending ? true : false,
        },
        {
          label: Translations.confirmation_modal_no,
          onClick: () =>
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_UPDATE_CAMPAIGN_CONFIRMATION,
              })
            ),
          disabled:
            editStatus && editStatus === StateStatus.Pending ? true : false,
        },
      ]}
    />
  );
};
