import TranslatedText from 'components/atoms/translated_text';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import Actions from 'redux/reducers/transports_reducer/actions';
import TransportsTable from './components/datatable';
import SearchArea from './components/SearchArea';
import { TableDefaultFetchValues } from './configuration';

const TransportsPage = () => {
  console.log('Render TransportPage');

  const dispatch = useDispatch();
  const [publisher, setPublisher] = React.useState<string>('');
  const [code, setCode] = React.useState<string>('');

  useEffect(() => {
    dispatch(
      Actions.fetchTransportsListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        publisher: publisher,
        code: code,
      })
    );
  }, [dispatch, publisher, code]);

  return (
    <>
      <SearchArea setPublisher={setPublisher} setCode={setCode} />
      <TransportsTable publisher={publisher} code={code} />
    </>
  );
};

export default withRouter(TransportsPage);
