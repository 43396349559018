import { Languages } from '../../config';

const Translations = {
  search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Κωδικός αναζήτησης',
    [Languages.Russian]: 'Код поиска',
  },
  search_button: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  edit: {
    [Languages.English]: 'Edit',
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.Russian]: 'Редактировать',
  },
  main: {
    [Languages.English]: 'Publisher page',
    [Languages.Greek]: 'Σελίδα εκδότη',
    [Languages.Russian]: 'Страница издателя',
  },
  id: {
    [Languages.English]: 'ID',
    [Languages.Greek]: 'Κλειδί',
    [Languages.Russian]: 'ID',
  },
  name: {
    [Languages.English]: 'Name',
    [Languages.Greek]: 'Σελίδα εκδότη',
    [Languages.Russian]: 'Name',
  },
  email: {
    [Languages.English]: 'Email',
    [Languages.Greek]: 'Email',
    [Languages.Russian]: 'Email',
  },
  code: {
    [Languages.English]: 'Code',
    [Languages.Greek]: 'Κώδικας',
    [Languages.Russian]: 'Code',
  },
  type: {
    [Languages.English]: 'Type',
    [Languages.Greek]: 'Τύπος',
    [Languages.Russian]: 'Type',
  },
  status: {
    [Languages.English]: 'Status',
    [Languages.Greek]: 'Κατάσταση',
    [Languages.Russian]: 'Status',
  },
  needApprove: {
    [Languages.English]: 'needs approval',
    [Languages.Greek]: 'χρειάζεται έγκριση',
    [Languages.Russian]: 'needs approval',
  },
};

export default Translations;
