// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export const getCategory = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/category/list`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
