import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_CAMPAIGNS_ADVERTISEMENT: string = typeCreatorV2(
  'AdvertisementReducer',
  'SetCampaignsAdvertisement'
);

export const UPDATE_CAMPAIGNS_STATUS: string = typeCreatorV2(
  'AdvertisementReducer',
  'UpdateCampaignsStatus'
);
export const UPDATE_PUBLISHER_ALLOW_STATUS: string = typeCreatorV2(
  'AdvertisementReducer',
  'UpdatePublisherAllowStatus'
);

export const ADD_CAMPAIGNS_CATEGORY: string = typeCreatorV2(
  'AdvertisementReducer',
  'AddCampaignsCategory'
);
export const SET_CONNECTED_PUBLISHER: string = typeCreatorV2(
  'AdvertisementReducer',
  'SetConnectedPublisher'
);
