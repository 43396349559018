import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  allUserWithRoles: {
    status: StateStatus | null;
    data: any;
  };
  persistAllUserWithRoles: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  allUserWithRoles: {
    status: null,
    data: null,
  },
  persistAllUserWithRoles: {
    status: null,
    data: null,
  },
};

const AdministratorReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_ALL_USERS_WITH_ROLES:
      return {
        ...state,
        allUserWithRoles: {
          ...state.allUserWithRoles,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.allUserWithRoles.data
              : action.data,
        },
      };
    case Types.SET_PERSIST_ALL_USERS_WITH_ROLES:
      return {
        ...state,
        persistAllUserWithRoles: {
          ...state.persistAllUserWithRoles,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.persistAllUserWithRoles.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AdministratorReducer;
