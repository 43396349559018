import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageUploadBox: {
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      flex: 1,
      '& .box-image': {
        height: 200,
        borderRadius: 4,
        border: '1px solid #e1e1e1',
        position: 'relative',
        '&>div': {
          position: 'absolute',
          right: 0,
          borderRadius: '50%',
          display: 'flex',
          cursor: 'pointer',
          padding: 1,
          '& svg': {
            height: '100%',
            fontSize: '1.6rem',
            color: '#766b6b',
            '&:hover': {
              color: '#605d5d',
            },
          },
        },
        '& svg': {
          height: '100%',
          fontSize: 40,
        },
        '& img': {
          height: '100%',
          objectFit: 'contain',
          width: '100%',
        },
        '& .MuiButton-root': {
          fontSize: 12,
        },
      },
      '& .button-upload': {
        fontSize: 12,
      },
      '& .demension': {
        fontSize: 14,
      },
    },
  })
);

export default useStyles;
