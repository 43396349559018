import React from 'react';
import { Typography } from '@material-ui/core';

interface ITransportStationCodeProps {
  style?: React.CSSProperties;
  code: string;
}
const TransportStationCode = (props: ITransportStationCodeProps) => {
  return (
    <Typography
      style={{
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 700,
        ...props.style,
      }}
    >
      {props.code}
    </Typography>
  );
};

export default TransportStationCode;
