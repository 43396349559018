import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchDSPList  */
interface IDSPListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  code: string | null;
  name: string | null;
  userid: string | null;
  vasttype: number;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IDSPListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchDSPList = async (
  payload: IDSPListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dsp/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IDSPListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** DSP Detail */
interface IDSPDetailInput {
  id: number;
}

interface IDSPDetailOutput extends Types.ResponseOutput {
  data: {
    code: string | null;
    name: string | null;
    userid: string | null;
    vasttype: number | null;
  };
}

export const fetchDSPDetail = async (
  payload: IDSPDetailInput
): Promise<IDSPDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dsp/${payload}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add DSP */
interface IDSPAddInput {
  code: string | null;
  name: string | null;
  userid: string | null;
  vasttype: number | null;
}

interface IDSPAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addDSP = async (payload: IDSPAddInput): Promise<IDSPAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/dsp `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit DSP */
interface IDSPEditInput {
  code: string | null;
  name: string | null;
  userid: string | null;
  vasttype: number | null;
}

interface IDSPEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editDSP = async ({
  code,
  ...rest
}: IDSPEditInput): Promise<IDSPEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/dsp/${code}`,
    rest
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Delete DSP */
interface IDSPDeleteInput {
  code: string;
}

interface IDSPDeleteOutput extends Types.ResponseOutput { }

export const deleteDSP = async ({
  code,
}: IDSPDeleteInput): Promise<IDSPEditOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/dsp/${code}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// GET DSP publisher

export interface IGetListDspPublisherInput extends Types.IPaginationInput {
  DSPCode: string;
}

export interface IGetListDspPublisherOuput
  extends Types.ResponseWithPaginationOutput<
    {
      id: number;
      dspcode: string;
      publisher_id: string;
      publisher_name: string;
    }[]
  > { }

export const getListDspPublisher = async ({
  page,
  pageSize,
  sortField,
  order,
  DSPCode,
}: IGetListDspPublisherInput): Promise<IGetListDspPublisherOuput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dsp_publishers/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}&DSPCode=${DSPCode}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// POST DSP publisher

export interface IPostListDspPublisherInput {
  dspcode: string;
  publisherid: number;
}

interface IPostListDspPublisherOuput extends Types.ResponseOutput { }

export const postListDspPublisher = async (
  payload: IPostListDspPublisherInput
): Promise<IPostListDspPublisherOuput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/dsp_publishers`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// Delete DSP publisher

export interface IDeleteListDspPublisherInput {
  publisherid: number;
}

interface IDeleteListDspPublisherOuput extends Types.ResponseOutput { }

export const deleteListDspPublisher = async (
  payload: IDeleteListDspPublisherInput
): Promise<IDeleteListDspPublisherOuput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/dsp_publishers/${payload.publisherid}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// GET DSP advertisement type

export interface IGetListDspAdvertisementInput extends Types.IPaginationInput {
  DSPCode: string;
}

export interface IGetListDspAdvertisementOuput
  extends Types.ResponseWithPaginationOutput<{
    id: number;
    dspcode: string;
    advertismenettype: number;
    deleted: number;
    udts: string;
  }[]> { }

export const getListDspAdvertisement = async ({
  page,
  pageSize,
  sortField,
  order,
  DSPCode,
}: IGetListDspAdvertisementInput): Promise<IGetListDspAdvertisementOuput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dsp_advertismenettype/list?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}&dspcode=${DSPCode}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// POST DSP advertisement type

export interface IPostDspAdvertisementInput {
  dspcode: string;
  advertismenettype: string;
}

export interface IPostDspAdvertisementOuput extends Types.ResponseOutput { }

export const postDspAdvertisement = async (
  payload: IPostDspAdvertisementInput
): Promise<IPostDspAdvertisementOuput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/dsp_advertismenettype`,
    { ...payload, deleted: 0 }
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// Delete DSP advertisement type

export interface IDeleteListDspAdvertismentInput {
  advertisementid: number;
}

interface IDeleteListDspAdvertismentOuput extends Types.ResponseOutput { }

export const deleteListDspAdvertisment = async (
  payload: IDeleteListDspAdvertismentInput
): Promise<IDeleteListDspAdvertismentOuput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/dsp_advertismenettype/${payload.advertisementid}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
