import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import publishersUsersWhitelistAction from 'redux/reducers/publishers_users_whitelist_reducer/actions';
import {
  useGetCategory,
  useGetListExclusiveByPublisher,
  useGetListUserByRole,
  usePublisherApplicationByPublisher,
} from 'redux/reducers/publishers_users_whitelist_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import AddEditExclusiveModal from './components/AddEditExclusiveModal';
import Header from './components/Header';
import { TableColumns, TableDefaultFetchValues } from './configuration';

const PublisherExclusivePage = () => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const { data: dataList, status } = useGetListExclusiveByPublisher();
  const publisherApplicationByPublisher = usePublisherApplicationByPublisher();
  const dataCategory = useGetCategory();
  const dataListUserByRole = useGetListUserByRole();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState<Object>({}); // use for later find
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'asc',
  });

  useEffect(() => {
    dispatch(
      publishersUsersWhitelistAction.fetchListExclusivityByPublisher({
        id,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
    dispatch(publishersUsersWhitelistAction.fetchCategoryActions());
    dispatch(
      publishersUsersWhitelistAction.fetchUserByRoleActions({
        roleId: 2,
      })
    );
    dispatch(
      publishersUsersWhitelistAction.getPublisherApplicationByPublisher({
        id,
        sortField: 'ID',
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: 9999,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(
      publishersUsersWhitelistAction.fetchListExclusivityByPublisher({
        sortField,
        order,
        page: selectedPage,
        pageSize,
        id,
      })
    );
  };

  const isLoading = status == StateStatus.Pending;
  const dataListValue =
    dataList?.items?.map((item) => {
      let temp: any = { ...item };
      // filter category
      const valueCaterogy = dataCategory?.data?.find(
        (v: any) => v?.id === item.CategoryId
      );
      temp = {
        ...temp,
        CategoryName: valueCaterogy?.name || temp?.CategoryId,
      };
      // filter user publisher app
      const valuePublisherApp = publisherApplicationByPublisher?.data?.items?.find(
        (v: any) => parseInt(`${v?.id}`) === item.PublisherAppId
      );
      temp = {
        ...temp,
        PublisherAppName: valuePublisherApp?.name || temp?.PublisherAppId,
      };
      // filter userid
      const valueUser = dataListUserByRole?.data?.find(
        (v: any) => v?.id === item.Userid
      );
      temp = {
        ...temp,
        UserName: valueUser?.email || temp?.Userid,
      };
      return temp;
    }) || [];

  return (
    <>
      <Box height={'4px'} />
      {isLoading ? <LinearProgress color="primary" /> : <Box height={'4px'} />}
      <Header />
      <Datatable
        loading={isLoading}
        sorting={sorting}
        paging={dataList?.paging}
        data={dataListValue}
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <AddEditExclusiveModal />
    </>
  );
};

export default withRouter(PublisherExclusivePage);
