import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Card, CardContent, Tooltip } from '@material-ui/core';
import { RestoreOutlined } from '@material-ui/icons';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
  AppDatePicker,
  PopOverDatePicker,
} from '../../../components/molecules/form_controls';
import GridLayout from '../../../components/molecules/grid_layout';

import { useForm, useFieldArray, Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetUserList } from '../../../redux/reducers/adv_dashboard_reducer/selectors';
import Actions from '../../../redux/reducers/adv_dashboard_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import {
  useSelectUserRoles,
  useSelectUserData,
} from '../../../redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';
import useStyles from '../styles';
import { CampaignEnv } from 'config/campaign_environemnt';
import { rangeOption } from '../configuration';

interface IFormData {
  userid: string | null;
  range: string | null;
  // width: number;
  // genericChartType: GenericChartTypes;
}
const defaultValues: IFormData = {
  userid: null,
  range: '2000-01-01',
};

export default function SearchArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userOption, setUserOption] = useState([]);
  const userList = useGetUserList();
  const roles = useSelectUserRoles();
  const { id } = useSelectUserData();

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList.data.map((item: any) => {
          return {
            id: item.id,
            label: item.Email,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    var userid = getParameterByName('userid');
    const range = getParameterByName('range');
    if (
      !roles.includes(Roles.Administrator) &&
      roles.includes(Roles.PublisherTransport)
    ) {
      userid = id;
    }
    reset({ userid, range });
  }, []);

  function handleReset() {
    reset(defaultValues);
    setUrlParams(defaultValues);
    dispatch(
      Actions.fetchCampaignsStatsByEnvAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchStationClickViewAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTransportsPassengerStatsDailyAction({
        userid: null,
        datefrom: null,
        dateto: null,
        publisher: 'OSEA',
        type: 'BUS',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByStationAction({
        userid: null,
        datefrom: null,
        dateto: null,
        publisher: 'OSEA',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByTransportAction({
        userid: null,
        datefrom: null,
        dateto: null,
        publisher: 'OSEA',
      })
    );
  }

  function setUrlParams(data: IFormData) {
    const { userid, range } = data;
    const userQuery = userid ? `userid=${userid}&` : '';
    const datefromQuery = range ? `datefromQuery=${range}&` : '';
    window.history.pushState(
      '',
      '',
      `/adv-dashboard-transport?${userQuery}${datefromQuery}`
    );
  }
  const onSubmit = (_data: IFormData) => {
    console.log(_data);
    let { userid, range } = _data;
    setUrlParams({ userid, range });

    dispatch(
      Actions.fetchCampaignsStatsByEnvAction({
        userid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchStationClickViewAction({
        userid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(
      Actions.fetchTransportsPassengerStatsDailyAction({
        userid,
        datefrom: range,
        dateto: null,
        publisher: 'OSEA',
        type: 'BUS',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByStationAction({
        userid,
        datefrom: range,
        dateto: null,
        publisher: 'OSEA',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByTransportAction({
        userid,
        datefrom: range,
        dateto: null,
        publisher: 'OSEA',
      })
    );
  };
  return (
    <Card>
      <CardContent>
        <form
          // className={classes.search}
          onSubmit={handleSubmit(onSubmit)}
        >
          {roles.includes(Roles.Administrator) ? (
            <GridLayout
              elements={[
                {
                  id: '1',
                  size: 4,
                  element: (
                    <React.Fragment>
                      {roles.includes(Roles.Administrator) ? (
                        <AppSelect
                          variant="outlined"
                          error={Boolean(_.get(errors, 'userid', false))}
                          helperText={_.get(errors, 'userid', '')}
                          //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                          rules={
                            {
                              // required: <RequiredField />,
                            }
                          }
                          fullWidth
                          options={userOption}
                          id={'userid'}
                          control={control}
                          label={
                            <TranslatedText
                              textMap={Translations.user_search}
                            />
                          }
                          autoComplete={'userid'}
                          name={'userid'}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                },
                {
                  id: '2',
                  size: 4,
                  element: (
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'range', false))}
                      helperText={_.get(errors, 'range', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      fullWidth
                      options={rangeOption}
                      id={'range'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.range_search} />
                      }
                      autoComplete={'range'}
                      name={'range'}
                    />
                  ),
                },
                // {
                //   id: '2',
                //   size: 3,
                //   element: (
                //     <PopOverDatePicker
                //       error={Boolean(_.get(errors, 'datefrom', false))}
                //       helperText={_.get(errors, 'datefrom', '')}
                //       //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                //       rules={
                //         {
                //           // required: <RequiredField />,
                //         }
                //       }
                //       fullWidth
                //       id={'datefrom'}
                //       control={control}
                //       label={
                //         <TranslatedText
                //           textMap={Translations.date_from_search}
                //         />
                //       }
                //       autoComplete={'datefrom'}
                //       name={'datefrom'}
                //     />
                //   ),
                // },
                // {
                //   id: '3',
                //   size: 3,
                //   element: (
                //     <PopOverDatePicker
                //       error={Boolean(_.get(errors, 'dateto', false))}
                //       helperText={_.get(errors, 'dateto', '')}
                //       //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                //       rules={
                //         {
                //           // required: <RequiredField />,
                //         }
                //       }
                //       fullWidth
                //       id={'dateto'}
                //       control={control}
                //       label={
                //         <TranslatedText textMap={Translations.date_to_search} />
                //       }
                //       autoComplete={'dateto'}
                //       name={'dateto'}
                //     />
                //   ),
                // },
                {
                  id: '4',
                  size: 4,
                  element: (
                    <div
                      className={clsx(classes.flex, classes.itemsCenter)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 55.68,
                      }}
                    >
                      <Button
                        className={classes['mr-12']}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {
                          <TranslatedText
                            textMap={Translations.button_search}
                          />
                        }
                      </Button>
                      <Tooltip title="Refresh result" placement="right">
                        <Button
                          onClick={handleReset}
                          variant="contained"
                          color="primary"
                        >
                          <RestoreOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: '2',
                  size: 4,
                  element: (
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'range', false))}
                      helperText={_.get(errors, 'range', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      fullWidth
                      options={rangeOption}
                      id={'range'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.range_search} />
                      }
                      autoComplete={'range'}
                      name={'range'}
                    />
                  ),
                },
                // {
                //   id: '2',
                //   size: 4,
                //   element: (
                //     <PopOverDatePicker
                //       error={Boolean(_.get(errors, 'datefrom', false))}
                //       helperText={_.get(errors, 'datefrom', '')}
                //       //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                //       rules={
                //         {
                //           // required: <RequiredField />,
                //         }
                //       }
                //       fullWidth
                //       id={'datefrom'}
                //       control={control}
                //       label={
                //         <TranslatedText
                //           textMap={Translations.date_from_search}
                //         />
                //       }
                //       autoComplete={'datefrom'}
                //       name={'datefrom'}
                //     />
                //   ),
                // },
                // {
                //   id: '3',
                //   size: 4,
                //   element: (
                //     <PopOverDatePicker
                //       error={Boolean(_.get(errors, 'dateto', false))}
                //       helperText={_.get(errors, 'dateto', '')}
                //       //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                //       rules={
                //         {
                //           // required: <RequiredField />,
                //         }
                //       }
                //       fullWidth
                //       id={'dateto'}
                //       control={control}
                //       label={
                //         <TranslatedText textMap={Translations.date_to_search} />
                //       }
                //       autoComplete={'dateto'}
                //       name={'dateto'}
                //     />
                //   ),
                // },
                {
                  id: '4',
                  size: 4,
                  element: (
                    <div
                      className={clsx(classes.flex, classes.itemsCenter)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 55.68,
                      }}
                    >
                      <Button
                        className={classes['mr-12']}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {
                          <TranslatedText
                            textMap={Translations.button_search}
                          />
                        }
                      </Button>
                      <Tooltip title="Refresh result" placement="right">
                        <Button
                          onClick={handleReset}
                          variant="contained"
                          color="primary"
                        >
                          <RestoreOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  ),
                },
              ]}
            />
          )}
        </form>
      </CardContent>
    </Card>
  );
}
