// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { env } from 'process';

interface IParams {
  publisherid: string | null;
  appid: string | null;
  userid: string | null;
  datefrom: string | null;
  dateto: string | null;
  enviromentworlds: number | null;
}

export const getCountryClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/publisher-country-click-view?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getGeneralPublisherRevenueAreaChart = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/general-publisher-revenue-areachart?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getGeneralPublisherFillrateBarchart = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/general-publisher-fillrate-barchart?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getGeneralPublisherRevenueBarchart = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/general-publisher-revenue-barchart?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getGeneralPublisherImpressionAreachart = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/general-publisher-impression-areachart?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPublisherCampaignClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/publisher-campaign-click-view?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPublisherOSClickView = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/publisher-os-click-view?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getPublisherFillrateByCountry = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/publisher-fillrate-by-country?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const getPublisherTotalSpentByDestairport = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/publisher-totalspent-by-destairport?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTopResults = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/get-top-results?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTop5ResultsByStation = async (
  payload: IParams
): Promise<Types.ResponseOutput> => {
  const {
    datefrom,
    dateto,
    userid,
    publisherid,
    appid,
    enviromentworlds,
  } = payload;
  const userQuery = userid ? `userid=${userid}&` : '';
  const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
  const datetoQuery = dateto ? `dateto=${dateto}&` : '';
  const publisherQuery = publisherid ? `publisherid=${publisherid}&` : '';
  const appidQuery = appid ? `appid=${appid}&` : '';
  const envQuery = enviromentworlds
    ? `enviromentworlds=${enviromentworlds}`
    : '';
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/dashboard-pub/get-top-5-results-by-station?${userQuery}${datefromQuery}${datetoQuery}${publisherQuery}${appidQuery}${envQuery}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getTop5Stations = async (
  stationCodes: Array<string>
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/dashboard-pub/get-top-5-stations`,
    stationCodes
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
