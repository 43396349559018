import React, { useEffect, useState } from 'react';
import FlightsMap, {
  MapPlaceholder,
  IRoute,
  IPoint,
} from 'components/app_common/flights_map';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import _ from 'lodash';

interface IProps {}
const DestinationsFlightsMap = (props: IProps) => {
  const data = CampaignSelectors.useGetDestinationReachOnBoard();
  const [renderFlightMap, setRenderFlightMap] = useState(false);
  const [routes, setRoutes] = useState<Array<IRoute>>([]);

  useEffect(() => {
    setTimeout(() => {
      setRenderFlightMap(true);
    }, 500);
  }, []);

  useEffect(() => {
    let destinationReactRoutes = data.routes;
    if (renderFlightMap && destinationReactRoutes) {
      let structuredRoutes = [];
      for (let route of destinationReactRoutes) {
        let starting_point: IPoint = {
          lat: route.DepLat,
          lng: route.DepLong,
          name: route.DeptAirport,
        };
        let ending_point: IPoint = {
          lat: route.ArrLat,
          lng: route.ArrLong,
          name: route.ArrAirport,
        };
        structuredRoutes.push({
          starting_point,
          ending_point,
        });
      }
      setRoutes(structuredRoutes);
    }
  }, [renderFlightMap, data]);

  return (
    <React.Fragment>
      {renderFlightMap && <FlightsMap routes={routes} isShowFlight />}
      {!renderFlightMap && <MapPlaceholder />}
    </React.Fragment>
  );
};

export default DestinationsFlightsMap;
