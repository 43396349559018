import React, { useEffect, useState } from 'react';
import {
  ImportantDevices,
  BarChartOutlined,
  RestoreOutlined,
} from '@material-ui/icons';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { withRouter, useHistory } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
} from 'components/molecules/form_controls';
import { useForm, useFieldArray, Control } from 'react-hook-form';
import PageLayout from 'components/app_common/page_layout';
import { AuthRoles } from 'config';
import { CampaignEnv } from 'config/campaign_environemnt';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import { Button, Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import { rangeOption } from './configuration';
import getRevenueConfig from './configuration/RevenueConfig';
import getFillrateConfig from './configuration/FillRateConfig';
import getRevenueBarConfig from './configuration/RevenueBarConfig';
import getImpressionConfigg from './configuration/ImpressionConfig';
import getImpressionAppConfig from './configuration/ImpressionApplicationConfig';
import getImpressionDeviceConfig from './configuration/ImpressionDeviceConfig';
import getFillrateByCountryConfig from './configuration/FillrateByCountryConfig';
import getRevenueByDestAirportConfig from './configuration/RevenueByDestAirportonfig';
import Progress from './components/Progress';
import Map from './components/Map';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import GridLayout from 'components/molecules/grid_layout';
import Actions from 'redux/reducers/pub_dashboard_reducer/actions';
import { Roles } from 'config/authRoles';
import {
  useGetCountryClickView,
  useGetFilterList,
  useGetRevenueAreaChart,
  useGetFillrateBarChart,
  useGetCampaignClickView,
  useGetFillrateByCountry,
  useGetImpressionAreaChart,
  useGetOSClickView,
  useGetRevenueBarChart,
  useGetTotalSpentByDestAirport,
  useGetUserList,
} from 'redux/reducers/pub_dashboard_reducer/selectors';
import {
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';

interface IFormData {
  publisherid: string | null;
  appid: string | null;
  range: string | null;
  userid: string | null;
  // width: number;
  // genericChartType: GenericChartTypes;
}
const defaultValues: IFormData = {
  publisherid: null,
  appid: null,
  range: '2000-01-01',
  userid: null,
};

const PubDashboardPage = (props: any) => {
  reactLogger.renderPage('Pub Dashboard Page');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useSelectUserData();
  const roles = useSelectUserRoles();
  const countryClickView = useGetCountryClickView();
  const revenueAreaChart = useGetRevenueAreaChart();
  const fillrateBarChart = useGetFillrateBarChart();
  const campaignClickView = useGetCampaignClickView();
  const osClickView = useGetOSClickView();
  const revenueBarChart = useGetRevenueBarChart();
  const fillrateByCountry = useGetFillrateByCountry();
  const impressionAreaChart = useGetImpressionAreaChart();
  const totalSpentByDestAirport = useGetTotalSpentByDestAirport();

  const filterList = useGetFilterList();
  const userList = useGetUserList();

  const [progressClicks, setProgressClicks] = useState<any>([]);
  const [progressViews, setProgressViews] = useState<any>([]);

  const [publisherOption, setPublisherOption] = useState([]);
  const [applicationOption, setApplicationOption] = useState([]);
  const [userOption, setUserOption] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(Actions.fetchFilterListAction({ id }));
    }
  }, [id]);
  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  useEffect(() => {
    let userid = getParameterByName('userid');
    const appid = getParameterByName('appid');
    const publisherid = getParameterByName('publisherid');
    const range = getParameterByName('range');
    if (roles.includes(Roles.Publisher)) {
      userid = id;
    }
    reset({
      userid,
      appid,
      publisherid,
      range,
    });
    dispatch(Actions.fetchUserListAction({}));

    dispatch(
      Actions.fetchCountryClickViewAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchRevenueAreaChartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchFillrateBarchartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchRevenueBarchartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchImpressionAreachartAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchCampaignClickViewAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchOSClickViewAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchFillrateByCountryAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchTotalSpentByDestairportAction({
        userid,
        appid,
        publisherid,
        datefrom: range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
  }, []);

  useEffect(() => {
    if (filterList.status === 'Success') {
      setPublisherOption(
        filterList.data.publishers.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
      setApplicationOption(
        filterList.data.applications.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [filterList]);

  useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList.data.map((item: any) => {
          return {
            id: item.id,
            label: item.Email,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    if (countryClickView.status === 'Success') {
      const maxClicks = Math.max(
        ...countryClickView.data.map((item: any) => {
          return item.totalclicks;
        })
      );
      const maxViews = Math.max(
        ...countryClickView.data.map((item: any) => {
          return item.totalviews;
        })
      );
      setProgressClicks(
        countryClickView.data
          .sort((a: any, b: any) => {
            return b.totalclicks - a.totalclicks;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalclicks / (maxClicks + 10)).toFixed(2)
            );

            return {
              percentage: percent * 100,
              value: item.totalclicks,
              name: item.onlinecountry,
            };
          })
      );
      setProgressViews(
        countryClickView.data
          .sort((a: any, b: any) => {
            return b.totalviews - a.totalviews;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalviews / (maxViews + 10)).toFixed(2)
            );
            return {
              percentage: percent * 100,
              value: item.totalviews,
              name: item.onlinecountry,
            };
          })
      );
    }
  }, [countryClickView]);

  function setUrlParams(data: IFormData) {
    const { publisherid, appid, range, userid } = data;
    window.history.pushState(
      '',
      '',
      `/pub-dashboard?userid=${userid}publisherid=${publisherid}&appid=${appid}&range=${range}`
    );
  }

  const onSubmit = (_data: IFormData) => {
    console.log(_data);
    setUrlParams(_data);
    // setPublisherid(_data.publisherid);
    // setAppid(_data.appid);
    // setRange(_data.range);
    dispatch(
      Actions.fetchCountryClickViewAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchRevenueAreaChartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchFillrateBarchartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchRevenueBarchartAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchImpressionAreachartAction({
        userid: null,
        appid: null,
        publisherid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchCampaignClickViewAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchOSClickViewAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchFillrateByCountryAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
    dispatch(
      Actions.fetchTotalSpentByDestairportAction({
        userid: _data.userid,
        appid: _data.appid,
        publisherid: _data.publisherid,
        datefrom: _data.range,
        dateto: null,
        enviromentworlds: CampaignEnv.InFlight,
      })
    );
  };

  return (
    <PageLayout>
      <GridLayout
        elements={[
          {
            id: 'search box',
            size: 12,
            element: roles.includes(AuthRoles.Administrator) ? (
              <Card>
                <CardContent>
                  <form
                    className={classes.search}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'userid', false))}
                      helperText={_.get(errors, 'userid', '')}
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={userOption}
                      id={'userid'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.user_search} />
                      }
                      autoComplete={'userid'}
                      name={'userid'}
                    />
                    <AppSelect
                      variant="outlined"
                      error={Boolean(_.get(errors, 'publisherid', false))}
                      helperText={_.get(errors, 'publisherid', '')}
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={publisherOption}
                      id={'publisherid'}
                      control={control}
                      label={
                        <TranslatedText
                          textMap={Translations.publisher_search}
                        />
                      }
                      autoComplete={'publisherid'}
                      name={'publisherid'}
                    />
                    <AppSelect
                      variant="outlined"
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      error={Boolean(_.get(errors, 'appid', false))}
                      helperText={_.get(errors, 'appid', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={applicationOption}
                      id={'appid'}
                      control={control}
                      label={
                        <TranslatedText
                          textMap={Translations.application_search}
                        />
                      }
                      autoComplete={'appid'}
                      name={'appid'}
                    />
                    <AppSelect
                      variant="outlined"
                      className={clsx(classes['mr-12'], classes['w-1/4'])}
                      error={Boolean(_.get(errors, 'range', false))}
                      helperText={_.get(errors, 'range', '')}
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      options={rangeOption}
                      id={'range'}
                      control={control}
                      label={
                        <TranslatedText textMap={Translations.range_search} />
                      }
                      autoComplete={'range'}
                      name={'range'}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 55.68,
                      }}
                    >
                      <Button
                        className={classes['mr-12']}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        <TranslatedText textMap={Translations.button_search} />
                      </Button>
                      
                      <Tooltip title="Refresh result" placement="top-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setUrlParams(defaultValues);

                          reset(defaultValues);
                          dispatch(
                            Actions.fetchCountryClickViewAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchRevenueAreaChartAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchFillrateBarchartAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchRevenueBarchartAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchImpressionAreachartAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchCampaignClickViewAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchOSClickViewAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchFillrateByCountryAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                          dispatch(
                            Actions.fetchTotalSpentByDestairportAction({
                              userid: null,
                              appid: null,
                              publisherid: null,
                              datefrom: null,
                              dateto: null,
                              enviromentworlds: CampaignEnv.InFlight,
                            })
                          );
                        }}
                      >
                        <RestoreOutlined />
                      </Button>
                      </Tooltip>
                    </div>
                  </form>
                </CardContent>
              </Card>
            ) : (
              <React.Fragment />
            ),
          },
          {
            id: 'total-revenue',
            size: 12,
            element: (
              <React.Fragment>
                {revenueAreaChart.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getRevenueConfig(revenueAreaChart.data)}
                  />
                ) : null}
              </React.Fragment>
            ),
          },
          {
            id: 'general',
            size: 12,
            element: (
              <GridLayout
                elements={[
                  {
                    id: 'fillrate',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.fillrate_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {fillrateBarChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getFillrateConfig(
                                fillrateBarChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'impression',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.impression_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {impressionAreaChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getImpressionConfigg(
                                impressionAreaChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'revenue',
                    size: 4,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <TranslatedText
                              textMap={Translations.revenue_chart_title}
                            />
                          }
                        />
                        <CardContent>
                          {revenueBarChart.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getRevenueBarConfig(
                                revenueBarChart.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'Progress-click-view',
            size: 12,
            element: (
              <Card className={classes.mt12}>
                <CardContent>
                  <GridLayout
                    elements={[
                      {
                        id: 'progress-view',
                        size: 4,
                        element: (
                          <Progress
                            type={
                              <TranslatedText
                                textMap={Translations.impressions}
                              />
                            }
                            data={progressViews}
                          />
                        ),
                      },
                      {
                        id: 'progress-click',
                        size: 4,
                        element: (
                          <Progress
                            type={
                              <TranslatedText textMap={Translations.click} />
                            }
                            data={progressClicks}
                          />
                        ),
                      },
                      {
                        id: 'map',
                        size: 4,
                        element: <Map />,
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            id: 'right-size',
            size: 12,
            element: (
              <GridLayout
                elements={[
                  {
                    id: 'pie-ctr',
                    size: 6,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <div className={classes.tableHeader}>
                              <div className={classes.textBlue}>
                                <ImportantDevices style={{ marginRight: 10 }} />
                                <TranslatedText
                                  textMap={Translations.application_pie_chart}
                                />
                              </div>
                            </div>
                          }
                        />
                        <CardContent>
                          {campaignClickView.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getImpressionAppConfig(
                                campaignClickView.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                  {
                    id: 'pie-device',
                    size: 6,
                    element: (
                      <Card>
                        <CardHeader
                          title={
                            <div className={classes.tableHeader}>
                              <div className={classes.textBlue}>
                                <ImportantDevices style={{ marginRight: 10 }} />
                                <TranslatedText
                                  textMap={Translations.device_pie_chart}
                                />
                              </div>
                            </div>
                          }
                        />
                        <CardContent>
                          {osClickView.status === 'Success' ? (
                            <DashboardBuilder
                              configuration={getImpressionDeviceConfig(
                                osClickView.data
                              )}
                            />
                          ) : null}
                        </CardContent>
                      </Card>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            id: 'Barchart-top',
            size: 12,
            element: (
              <Card className={classes.mt12}>
                <CardContent>
                  <GridLayout
                    elements={[
                      {
                        id: 'fillrate country',
                        size: 6,
                        element: (
                          <Card>
                            <CardHeader
                              title={
                                <div className={classes.tableHeader}>
                                  <div className={classes.textBlue}>
                                    <BarChartOutlined />{' '}
                                    <TranslatedText
                                      textMap={Translations.fillrate_bar_chart}
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <CardContent>
                              {fillrateByCountry.status === 'Success' ? (
                                <DashboardBuilder
                                  configuration={getFillrateByCountryConfig(
                                    fillrateByCountry.data
                                  )}
                                />
                              ) : null}
                            </CardContent>
                          </Card>
                        ),
                      },
                      {
                        id: 'click-per-country',
                        size: 6,
                        element: (
                          <Card>
                            <CardHeader
                              title={
                                <div className={classes.tableHeader}>
                                  <div className={classes.textBlue}>
                                    <BarChartOutlined />{' '}
                                    <TranslatedText
                                      textMap={Translations.revenue_bar_chart}
                                    />
                                  </div>
                                </div>
                              }
                            />
                            <CardContent>
                              {totalSpentByDestAirport.status === 'Success' ? (
                                <DashboardBuilder
                                  configuration={getRevenueByDestAirportConfig(
                                    totalSpentByDestAirport.data
                                  )}
                                />
                              ) : null}
                            </CardContent>
                          </Card>
                        ),
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(PubDashboardPage);
