import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  submit: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  viewButton: {
    marginRight: 5,
    marginLeft: 5,
    color: theme.palette.getContrastText(blueGrey[800]),
    backgroundColor: blueGrey[500],
    '&:hover': {
      backgroundColor: blueGrey[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blueGrey[300],
      },
    },
    alignSelf: 'flex-start',
  },
  form: {},
}));

export default useStyles;
