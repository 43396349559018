import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import useStyles from '../styles';
import { useDispatch } from 'react-redux';
import GridLayout from 'components/molecules/grid_layout';
import Actions from 'redux/reducers/application_detail_reducer/actions';

import {
  useGetCurrentPublisherApplicationType,
  useGetNewPublisherApplicationType,
  useGetPublisherApplicationType,
  useGetIsNewPublisherApplicationType,
} from '../../../redux/reducers/application_detail_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

export default function ApplicationType() {
  const dispatch = useDispatch();
  const isNewApplicationType = useGetIsNewPublisherApplicationType();
  const currentApplicationType = useGetCurrentPublisherApplicationType();
  const applicationType = useGetPublisherApplicationType();
  const newApplicationType = useGetNewPublisherApplicationType();

  const classes = useStyles();
  return (
    <React.Fragment>
      {isNewApplicationType ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(Actions.setIsNewApplicationType(false));
            dispatch(
              Actions.setCurrentPublisherApplicationType(applicationType.data)
            );
          }}
        >
          <TranslatedText textMap={Translations.cancel_button} />
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(Actions.setIsNewApplicationType(true));
            dispatch(
              Actions.setCurrentPublisherApplicationType(
                newApplicationType.data
              )
            );
          }}
        >
          <TranslatedText textMap={Translations.new_application_type_button} />
        </Button>
      )}

      <GridLayout
        elements={currentApplicationType?.data.map(
          (appType: any, index: number) => {
            return {
              id: index,
              size: 4,
              element: (
                <Card
                  classes={{ root: classes.root }}
                  onClick={() =>
                    dispatch(
                      Actions.setDialog({
                        isOpen: true,
                        type: 'edit',
                        data: appType,
                      })
                    )
                  }
                  className={classes.card}
                >
                  <CardContent
                    className={classes.cardContent}
                    style={{ height: 'auto' }}
                  >
                    <img
                      alt="Ad media"
                      className="w-full"
                      height="215"
                      src={`${appType.AdvImage}`}
                      //   src="https://image.freepik.com/free-photo/image-human-brain_99433-298.jpg"
                    />
                    <div>
                      <Typography className={classes.color} variant="body1">
                        {appType.AdvName}
                      </Typography>
                      {appType.TypeUniqueKey && (
                        <>
                          <TextField
                            className="mt-8 w-full text-white"
                            fullWidth
                            classes={{ root: classes.input }}
                            InputProps={{
                              classes: { input: classes.color },
                              disableUnderline: true,
                            }}
                            autoFocus
                            name="key"
                            value={`Key: ${appType.TypeUniqueKey}`}
                            disabled
                            required
                          />
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              ),
            };
          }
        )}
      />
    </React.Fragment>
  );
}
