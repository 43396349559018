import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux/reducers/administrator_reducer/actions';
import UsersTable from './components/datatable';
import SearchArea from './components/SearchArea';
export default function AdministratorPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      Actions.fetchAllUsersWithRolesAction({
        page: 0,
        pageSize: 10,
        sortField: 'id',
        order: 'DESC',
      })
    );
  }, []);
  return (
    <>
      <SearchArea />
      <UsersTable />
    </>
  );
}
