import { colors, Theme } from '@material-ui/core';

export enum ChartTypesEnum {
  COLUMN,
  BAR,
  LINE,
  DOTS,
  PIE,
  BUBBLE,
  DOUGHNUT,
}
