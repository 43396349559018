import React, { useEffect, useState } from 'react';
import FlightsMap, {
  MapPlaceholder,
  IRoute,
  IPoint,
} from 'components/app_common/flights_map';
import { useDispatch } from 'react-redux';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import _ from 'lodash';
import Actions from 'redux/reducers/campaign_reducer/actions';

interface IProps {}
const DestinationsFlightsMap = React.forwardRef((props: IProps, ref: any) => {
  const dispatch = useDispatch();
  const points = CampaignSelectors.useGetPoints()
  // const data = CampaignSelectors.useGetDestinationReachOnBoard();
  const [renderFlightMap, setRenderFlightMap] = useState(false);
  const [routes, setRoutes] = useState<any>([]);
  const countries = CampaignSelectors.useGetCountries();

  useEffect(() => {
    setTimeout(() => {
      setRenderFlightMap(true);
    }, 500);
  }, []);

  useEffect(() => {
    console.log('rfr,', ref);
    if (points.length !== 0) {
      const countriesCode = points.split(',').map((item: string) => `${item.replace('DSTC_', '')}`) 
     
      dispatch(Actions.fetchCountriesAction({ code: countriesCode }));
    }
  }, [points]);

  useEffect(() => {
    setRoutes(countries);
  }, [countries]);

  // useEffect(() => {
  //   let destinationReactRoutes = data.routes;
  //   if (renderFlightMap && destinationReactRoutes) {
  //     let structuredRoutes = [];
  //     for (let route of destinationReactRoutes) {
  //       let starting_point: IPoint = {
  //         lat: route.DepLat,
  //         lng: route.DepLong,
  //         name: route.DeptAirport,
  //       };
  //       let ending_point: IPoint = {
  //         lat: route.ArrLat,
  //         lng: route.ArrLong,
  //         name: route.ArrAirport,
  //       };
  //       structuredRoutes.push({
  //         starting_point,
  //         ending_point,
  //       });
  //     }
  //     setRoutes(structuredRoutes);
  //   }
  // }, [renderFlightMap, data]);

  return (
    <React.Fragment>
      {renderFlightMap && <FlightsMap routes={routes} isShowFlight={false} />}
      {!renderFlightMap && <MapPlaceholder />}
    </React.Fragment>
  );
});

export default DestinationsFlightsMap;
