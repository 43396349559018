import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface User_Price_CalculationListElement {
  id: number;
  flat: string;
  User_id: string;
  Airline: string;
  Country: string;
  Airport: string;
  CPM: number;
  CPC: number;
  CPA: number;
  Advertisment_type: number;
  Userid: string;
  UTC: string;
}

interface User_Price_CalculationListData {
  paging: User_Price_CalculationListPaging;
  items: User_Price_CalculationListElement[];
}

interface User_Price_CalculationListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface User_Price_CalculationistOutput {
  status: StateStatus;
  data: User_Price_CalculationListData | null;
}

export const useGetUser_Price_CalculationList = (): User_Price_CalculationistOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRICE_CALCULATION_REDUCER]
        .User_Price_CalculationList
  );
  return data;
};

/* selector: useGetUser_Price_CalculationDetails */
interface User_Price_CalculationDetailsData {
  id: number;
  flat: boolean;
  User_id: string;
  Airline: string;
  Country: string;
  Airport: string;
  CPM: number;
  CPC: number;
  CPA: number;
  Advertisment_type: number;
  Userid: string;
  UTC: string;
}

interface User_Price_CalculationDetailsOutput {
  status: StateStatus;
  data: User_Price_CalculationDetailsData | null;
}

export const useGetUser_Price_CalculationDetails = (): User_Price_CalculationDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRICE_CALCULATION_REDUCER]
        .viewUser_Price_Calculation
  );
  return data;
};

export const useAddUser_Price_CalculationStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRICE_CALCULATION_REDUCER]
        .addUser_Price_Calculation?.status
  );
  return data;
};

export const useEditUser_Price_CalculationStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRICE_CALCULATION_REDUCER]
        .editUser_Price_Calculation?.status
  );
  return data;
};

export const useDeleteUser_Price_CalculationStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRICE_CALCULATION_REDUCER]
        .deleteUser_Price_Calculation?.status
  );
  return data;
};
