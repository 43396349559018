import React from 'react';
import reactLogger from '../../utils/logger';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import CampaignsActions from '../../redux/reducers/campaigns_reducer/actions';
import useStyles from './styles';
import DatatableList from './components/datatable';
import { TableDefaultFetchValues } from './configuration';
import PageLayout from '../../components/app_common/page_layout';
import Filtering from './components/filtering';
import useQuery from '../../custom_hooks/useQuery';
import { Button } from '@material-ui/core';
import TranslatedText from '../../components/atoms/translated_text';
import Translations from './translations';
import NavigationConfig from '../../navigation/config';

const CampaignsPage = (props: any) => {
  reactLogger.renderPage('Campaigns');
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const query = useQuery();
  const activeStatus = query.get('activeStatus');
  const campaign = query.get('campaign');
  let searchParams = {
    ...(activeStatus && { activeStatus }),
    ...(campaign && { campaign }),
  };

  React.useEffect(() => {
    dispatch(
      CampaignsActions.fetchCampaignsListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        ...searchParams,
      })
    );
  }, [dispatch, searchParams]);

  return (
    <PageLayout>
      <Button
        variant={'contained'}
        color={'secondary'}
        className={classes.create_campaign}
        onClick={() => {
          history.push(NavigationConfig.campaignPage('new').path);
        }}
      >
        <TranslatedText textMap={Translations.createCampaign} />
      </Button>
      <Filtering activeStatus={activeStatus} campaign={campaign} />
      <DatatableList />
    </PageLayout>
  );
};

export default withRouter(CampaignsPage);
