import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

export const useGetCountryClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].countryClickView
  );
  return data;
};
export const useGetRevenueAreaChart = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].revenueAreaChart
  );
  return data;
};
export const useGetFillrateBarChart = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].fillrateBarChart
  );
  return data;
};
export const useGetRevenueBarChart = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].revenueBarChart
  );
  return data;
};
export const useGetImpressionAreaChart = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].impressionAreaChart
  );
  return data;
};
export const useGetCampaignClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].campaignClickView
  );
  return data;
};
export const useGetOSClickView = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].osClickView
  );
  return data;
};
export const useGetFillrateByCountry = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].fillrateByCountry
  );
  return data;
};
export const useGetTotalSpentByDestAirport = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUB_DASHBOARD_REDUCER].totalSpentByDestairport
  );
  return data;
};

export const useGetFilterList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].filterList
  );
  return data;
};

export const useGetUserList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].userList
  );
  return data;
};

export const useGetBusList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].busList.data
  );
  return data;
};

export const useGetTopResults = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].topResults
  );
  return data;
};

export const useGetTop5ResultsByStation = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.PUB_DASHBOARD_REDUCER].top5ResultsByStation
  );
  return data;
};

export const useGetTop5Stations = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PUB_DASHBOARD_REDUCER].top5Stations
  );
  return data;
};
