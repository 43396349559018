import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface AdvertisementsTypeListElement {
  ID: number;
  Name: string;
  Description: string;
  Contentype: string;
  Width: number;
  Height: number;
  Deleted: string;
  MaxUploadSize: number;
  DeviceType: number;
  Rate: string;
  imageurl: string;
}

interface AdvertisementsTypeListData {
  paging: AdvertisementsTypeListPaging;
  items: AdvertisementsTypeListElement[];
}

interface AdvertisementsTypeListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface AdvertisementsTypeistOutput {
  status: StateStatus;
  data: AdvertisementsTypeListData | null;
}

export const useGetAdvertisementsTypeList = (): AdvertisementsTypeistOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADVERTISEMENTSTYPE_REDUCER].advertisementsTypeList
  );
  return data;
};

/* selector: useGetAdvertisementsTypeDetails */
interface AdvertisementsTypeDetailsData {
  id: number;
  name: string;
  description: string;
  contentype: string;
  width: number;
  height: number;
  deleted: string;
  maxuploadsize: number;
  devicetype: number;
  rate: string;
  imageurl: string;
}

interface AdvertisementsTypeDetailsOutput {
  status: StateStatus;
  data: AdvertisementsTypeDetailsData | null;
}

export const useGetAdvertisementsTypeDetails = (): AdvertisementsTypeDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADVERTISEMENTSTYPE_REDUCER].viewAdvertisementsType
  );
  return data;
};

export const useAddAdvertisementsTypeStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADVERTISEMENTSTYPE_REDUCER].addAdvertisementsType
        ?.status
  );
  return data;
};

export const useEditAdvertisementsTypeStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.ADVERTISEMENTSTYPE_REDUCER].editAdvertisementsType
        ?.status
  );
  return data;
};
