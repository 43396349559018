import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard, { ISegment } from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Divider, TextField } from '@material-ui/core';
import { IDataItems } from 'services/api/transport_stations';
import { useHistory } from 'react-router';
import { AppCheckbox } from 'components/molecules/styled_form_controls';
import TransportStationId from './TransportStationId';
import TransportStationPublisher from './TransportStationPublisher';
import TransportStationCode from './TransportStationCode';
import TransportStationsGeo from './TransportStationsGeo';
import TransportStationStatistics from './TransportStationStatistics';
import ViewTransportStationStatistics from './ViewTransportStationStatistics';

interface ITransportStationProps {
  transportStation: IDataItems;
  setSelected?: any;
  handleCheck?: (code: any) => boolean;
  handleSelect?: (code: any) => void;
}

const TransportStationBox = ({
  transportStation,
  setSelected,
  handleCheck,
  handleSelect,
}: ITransportStationProps) => {
  const { destination_transport } = CampaignSelectors.useGetSegments();

  const onCheckboxClick = (event: any) => {
    event.stopPropagation();
    handleSelect && handleSelect(transportStation.code);
    // event.stopPropagation();
    // if (setSelected) {
    //   if (event.target.checked) {
    //     setSelected((state: any) => [...state, `${transportStation.id}`]);
    //   } else {
    //     setSelected((state: any) =>
    //       state.filter(
    //         (newItem: string) => newItem !== `${transportStation.id}`
    //       )
    //     );
    //   }
    // }
  };

  return (
    <React.Fragment>
      <ListTransportStation
        transportStation={transportStation}
        onCheckboxClick={onCheckboxClick}
        handleCheck={handleCheck}
      />
    </React.Fragment>
  );
};

interface ITransportStation {
  transportStation: IDataItems;
  onCheckboxClick: (event: any) => void;
  handleCheck?: (code: any) => boolean;
}

const ListTransportStation = ({
  transportStation,
  onCheckboxClick,
  handleCheck,
}: ITransportStation) => {
  const classes = useStyles();
  const checkData = handleCheck && handleCheck(`${transportStation.code}`);
  const [
    isOpenTransportStationStatistics,
    setIsOpenTransportStationStatistics,
  ] = React.useState(false);
  const seeTransportStationStatistics = () => {
    setIsOpenTransportStationStatistics(true);
  };

  const onMouseEnter = () => {
    const elementStationMarker = document.getElementsByClassName(
      `marker_station_${transportStation.id}`
    )[0] as HTMLElement;
    // if (
    //   elementStationMarker &&
    //   !elementStationMarker.className.includes(
    //     `selected_${transportStation.id}`
    //   )
    // ) {
    //   (elementStationMarker.children[0].children[0] as HTMLElement).style.fill =
    //     'red';
    //   // elementStationMarker.style.backgroundColor = '#0047e9';
    //   // elementStationMarker.style.color = '#fff';
    //   (elementStationMarker.children[0] as HTMLElement).style.zIndex = '99999';
    // }
    if (
      elementStationMarker &&
      !elementStationMarker.className.includes(
        `selected_${transportStation.id}`
      )
    ) {
      (elementStationMarker.children[0].children[0] as HTMLElement).style.fill =
        'red';
      elementStationMarker.style.zIndex = '99999';
    }
  };

  const onMouseLeave = () => {
    const elementStationMarker = document.getElementsByClassName(
      `marker_station_${transportStation.id}`
    )[0] as HTMLElement;
    // if (
    //   elementStationMarker &&
    //   !elementStationMarker.className.includes(
    //     `selected_${transportStation.id}`
    //   )
    // ) {
    //   (elementStationMarker.children[0]
    //     .children[0] as HTMLElement).style.removeProperty('fill');
    //   // (elementStationMarker.children[0].children[0] as HTMLElement).style.fill =
    //   //   'initial';
    //   // (elementStationMarker.children[0] as HTMLElement).style.zIndex =
    //   //   'initial';
    //   (elementStationMarker.children[0] as HTMLElement).style.removeProperty(
    //     'z-index'
    //   );
    // }
    if (
      elementStationMarker &&
      !elementStationMarker.className.includes(
        `selected_${transportStation.id}`
      )
    ) {
      (elementStationMarker.children[0]
        .children[0] as HTMLElement).style.removeProperty('fill');
      elementStationMarker.style.removeProperty('z-index');
    }
  };

  return (
    <React.Fragment>
      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        height={'220px'}
        className={`${classes.backgroundUnset} ${classes.hoverTransportStation}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Box width={'20%'} position={'relative'}>
          <AppCheckbox
            style={{ background: 'white' }}
            size={'medium'}
            onClick={onCheckboxClick}
            checked={!!checkData}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          width={'80%'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Box>
              <TransportStationId
                style={{ marginBottom: '6px' }}
                id={transportStation.id}
              />
              <TransportStationPublisher
                style={{ marginBottom: '4px' }}
                publisher={transportStation.pubcode}
              />
              <TransportStationCode
                style={{ marginBottom: '6px' }}
                code={transportStation.code}
              />
            </Box>
          </Box>
          <TransportStationsGeo
            lat={transportStation.lat ?? 0}
            lon={transportStation.lon ?? 0}
          />
          <Divider style={{ width: '100%' }} />
          <TransportStationStatistics
            seeTransportStationStatistics={seeTransportStationStatistics}
          />
        </Box>
      </Box>
      {transportStation.id && (
        <ViewTransportStationStatistics
          id={transportStation.id ?? 0}
          isOpen={isOpenTransportStationStatistics}
          handleClose={() => {
            setIsOpenTransportStationStatistics(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TransportStationBox;
