import { IGetListDspAdvertisementOuput, IGetListDspPublisherOuput } from 'services/api/dsp';
import { IGetParalistByGroupCodeOutput } from 'services/api/paralist';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';
import { IGetListAdvertisementTypeOuput } from 'services/api/advertisement_type';

export interface IState {
  DSPList: {
    status: StateStatus | null;
    data: any;
  };
  addDSP: {
    status: StateStatus | null;
  };
  editDSP: {
    status: StateStatus | null;
  };
  deleteDSP: {
    status: StateStatus | null;
  };
  viewDSP: {
    status: StateStatus | null;
    data: any;
  };
  paralist: {
    status: StateStatus | null;
    data: IGetParalistByGroupCodeOutput['data'] | null;
  };
  userByRole: {
    status: StateStatus | null;
    data: any | null;
  };
  listDspPublisher: {
    status: StateStatus | null;
    data: IGetListDspPublisherOuput['data'] | null;
  };
  createDSPPublisher: {
    status: StateStatus | null;
  };
  listPublisherByRole: {
    status: StateStatus | null;
    data: any;
  };
  deleteDSPPublisher: {
    status: StateStatus | null;
  };
  listDSPAdvertisement: {
    status: StateStatus | null;
    data: IGetListDspAdvertisementOuput['data'] | null;
  };
  createDSPAdvertisement: {
    status: StateStatus | null;
  };
  deleteDSPAdvertisement: {
    status: StateStatus | null;
  };
  listAdvertisementType: {
    status: StateStatus | null;
    data: IGetListAdvertisementTypeOuput['data'] | null;
  };
}

const initialState: IState = {
  DSPList: {
    status: null,
    data: null,
  },
  addDSP: {
    status: null,
  },
  editDSP: {
    status: null,
  },
  deleteDSP: {
    status: null,
  },
  viewDSP: {
    status: null,
    data: null,
  },
  paralist: {
    status: null,
    data: null,
  },
  userByRole: {
    status: null,
    data: null,
  },
  listDspPublisher: {
    status: null,
    data: null,
  },
  createDSPPublisher: {
    status: null,
  },
  listPublisherByRole: {
    status: null,
    data: null,
  },
  deleteDSPPublisher: {
    status: null,
  },
  listDSPAdvertisement: {
    status: null,
    data: null,
  },
  createDSPAdvertisement: {
    status: null,
  },
  deleteDSPAdvertisement: {
    status: null,
  },
  listAdvertisementType: {
    status: null,
    data: null,
  }
};

const DSPReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_DSP_LIST:
      return {
        ...state,
        DSPList: {
          ...state.DSPList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.DSPList.data : action.data,
        },
      };
    case Types.SET_ADD_DSP:
      return {
        ...state,
        addDSP: {
          ...state.addDSP,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_DSP:
      return {
        ...state,
        editDSP: {
          ...state.editDSP,
          status: action.status || null,
        },
      };
    case Types.SET_DELETE_DSP:
      return {
        ...state,
        deleteDSP: {
          ...state.deleteDSP,
          status: action.status || null,
        },
      };
    case Types.SET_DSP_DETAIL:
      return {
        ...state,
        viewDSP: {
          ...state.viewDSP,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.viewDSP.data : action.data,
        },
      };
    case Types.SET_PARALIST_LIST:
      return {
        ...state,
        paralist: {
          ...state.paralist,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.paralist.data : action.data,
        },
      };
    case Types.SET_USER_BY_ROLE:
      return {
        ...state,
        userByRole: {
          ...state.userByRole,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userByRole.data : action.data,
        },
      };
    case Types.SET_LIST_DSP_PUBLISHER:
      return {
        ...state,
        listDspPublisher: {
          ...state.listDspPublisher,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listDspPublisher.data
              : action.data,
        },
      };
    case Types.SET_CREATE_DSP_PUBLISHER:
      return {
        ...state,
        createDSPPublisher: {
          ...state.createDSPPublisher,
          status: action.status || null,
        },
      };
    case Types.SET_LIST_PUBLISHER_BY_ROLE:
      return {
        ...state,
        listPublisherByRole: {
          ...state.listPublisherByRole,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listPublisherByRole.data
              : action.data,
        },
      };
    case Types.DELETE_DSP_PUBLISHER:
      return {
        ...state,
        deleteDSPPublisher: {
          ...state.deleteDSPPublisher,
          status: action.status || null,
        },
      };
    case Types.SET_LIST_ADVERTISEMENT_TYPE_DSP:
      return {
        ...state,
        listDSPAdvertisement: {
          ...state.listDSPAdvertisement,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listDSPAdvertisement.data
              : action.data,
        },
      };
    case Types.SET_CREATE_ADVERTISEMENT_TYPE_DSP:
      return {
        ...state,
        createDSPAdvertisement: {
          ...state.createDSPAdvertisement,
          status: action.status || null,
        },
      };
    case Types.DELETE_ADVERTISEMENT_TYPE_DSP:
      return {
        ...state,
        deleteDSPAdvertisement: {
          ...state.deleteDSPAdvertisement,
          status: action.status || null,
        },
      };
    case Types.SET_LIST_ADVERTISEMENT_TYPE:
      return {
        ...state,
        listAdvertisementType: {
          ...state.listAdvertisementType,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.listAdvertisementType.data
              : action.data,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default DSPReducer;
