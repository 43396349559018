import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchParalists } from 'redux/reducers/paralist_reducer/actions';
import { useGetParaList } from 'redux/reducers/paralist_reducer/selectors';
import { GET_PARALISTS } from 'redux/reducers/paralist_reducer/types';
import {
  fetchAllPublishers,
  fetchPublishers,
} from 'redux/reducers/publisher_reducer/actions';
import { useGetPublisherList } from 'redux/reducers/publisher_reducer/selectors';
import { GET_PUBLISHERS } from 'redux/reducers/publisher_reducer/types';
import Header from './components/Header';
import PublisherConnectionModal from './components/PublisherConnectionModal';
import PublisherDetailDialog from './components/PublisherDetailDialog';
import { TableColumns } from './configuration';

const PublishersPage = () => {
  const { list, status, paging } = useGetPublisherList();
  const { fetch, paralist_status } = useGetParaList();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState<Object>({}); // use for later find
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'asc',
  });

  useEffect(() => {
    dispatch(fetchPublishers({}));
    dispatch(fetchParalists({}));
    dispatch(
      fetchAllPublishers({
        pageSize: 99999,
      })
    );
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(
      fetchPublishers({
        sortField,
        order,
        page: selectedPage,
        pageSize,
        query,
      })
    );
  };

  const isLoading =
    status == GET_PUBLISHERS.Pending ||
    paralist_status == GET_PARALISTS.Pending;

  const pubTypes = fetch('publisher.type');

  const listDetail = list?.map((pub) => ({
    ...pub,
    typeName: pubTypes?.find((type) => type.code == pub.type)?.descriptionn,
  }));

  return (
    <>
      <Box height={'4px'} />
      {isLoading ? <LinearProgress color="primary" /> : <Box height={'4px'} />}
      <Header />
      <Datatable
        loading={isLoading}
        sorting={sorting}
        paging={paging}
        data={listDetail || []}
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <PublisherDetailDialog />
      <PublisherConnectionModal />
    </>
  );
};

export default withRouter(PublishersPage);
