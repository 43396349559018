import { makeStyles } from '@material-ui/core/styles';
import { APP_COLOR_SECONDARY } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '1rem auto 1rem auto',
    width: '80%',
    // [theme.breakpoints.down('md')]: {
    //   width: '80%',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  optionItem: {
    // width: '48%',
    marginLeft: '.25rem',
    marginRight: '.25rem',
    marginBottom: '.5rem',
    // [theme.breakpoints.down('xs')]: {
    //   width: '90%',
    // },
  },
  optionItemBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '150px',
    padding: '1rem',
    color: '#BEBEBE',
    transition: '0.3s',

    '&:hover': {
      color: APP_COLOR_SECONDARY({ darkMode: theme.palette.type === 'dark' }),
    },
  },
  optionItemBodySelected: {
    color: APP_COLOR_SECONDARY({ darkMode: theme.palette.type === 'dark' }),
  },
}));

export default useStyles;
