import { Container } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import LanguageDropdown from '../../components/app_common/language_dropdown';
import LoginForm from './components/login_form';
import useStyles from './styles';

interface IProps {}

const LoginPage: React.FC<IProps> = (props) => {
  console.log('Render LoginPage');
  const classes = useStyles();

  return (
    <div>
      <div className={classes.languageButton}>
        <LanguageDropdown />
      </div>
      <LoginForm />
    </div>
  );
};

export default withRouter(LoginPage);
