import React, { useEffect, useState } from 'react';
import {
  Star,
  ImportantDevices,
  BarChartOutlined,
  FlightLand,
} from '@material-ui/icons';
import { withRouter, useHistory } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import Datatable from 'components/molecules/datatable';
import PageLayout from 'components/app_common/page_layout';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import DashboardBuilder, {
  CustomConfig,
  SimpleConfig,
} from 'components/compounds/dashboard_builder';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@material-ui/core';
import { TableColumns } from './configuration';
import getConfigCTR from './configuration/configCTR';
import getConfigClick from './configuration/configClick';
import getConfigImpressionViewCountry from './configuration/configImpressionViewCountry';
import getConfigImpressionClickCountry from './configuration/configImpressionClickCountry';
import getConfigImpressionDestination from './configuration/configImpressionDestination';
import getConfigClickDestination from './configuration/configClickDestination';
import getLineChartConfig from './configuration/LineConfig';
import getBarChartConfig from './configuration/BarChartConfig';
import SearchArea from './components/SearchArea';
import Widget2 from './components/widget2';
import Progress from './components/Progress';
import GridLayout from 'components/molecules/grid_layout';
import Actions from 'redux/reducers/adv_dashboard_reducer/actions';
import {
  useGetCampaignsStats,
  useGetStationClickView,
  useGetTop5Campaign,
  useGetTotalClickByArrCountry,
  useGetTotalViewByArrCountry,
  useGetDeviceClickView,
  useGetTotalCountryClick,
  useGetTotalCountryView,
  useGetTotalImpressionClickByCampaign,
  useGetTransportsPassengerStatsDaily,
  useGetTop10PassengerStatsByStation,
  useGetTop10PassengerStatsByTransport,
} from 'redux/reducers/adv_dashboard_reducer/selectors';
import {
  useSelectUserRoles,
  useSelectUserData,
} from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';
import { CampaignEnv } from 'config/campaign_environemnt';
import BusStationMap from './components/BusStationMap';
import { Roles } from 'config/authRoles';

function LeftWidget(props: any) {
  const ctr = Math.round(
    (props.campaignStats?.data?.totalclicks /
      props.campaignStats?.data?.totalviews) *
      100
  );
  if (props.campaignStats.status === 'Success') {
    return (
      <Widget2
        data={{
          title: `${ctr}%`,
          body: <TranslatedText textMap={Translations.ctr_widget} />,
          description: (
            <TranslatedText textMap={Translations.ctr_widget_description} />
          ),
        }}
      />
    );
  } else {
    return (
      <Widget2
        data={{
          title: `0%`,
          body: <TranslatedText textMap={Translations.ctr_widget} />,
          description: (
            <TranslatedText textMap={Translations.ctr_widget_description} />
          ),
        }}
      />
    );
  }
}

const AdvDashboardPage = (props: any) => {
  reactLogger.renderPage('Adv Dashboard Page');
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelectUserRoles();
  const { id } = useSelectUserData();
  const campaignStats = useGetCampaignsStats();
  const stationClickView = useGetStationClickView();
  const top5Campaign = useGetTop5Campaign();
  //   const totalImpressionClickByCampaign = useGetTotalImpressionClickByCampaign();
  //   const totalClickByArrCountry = useGetTotalClickByArrCountry();
  //   const totalViewByArrCountry = useGetTotalViewByArrCountry();
  //   const deviceClickView = useGetDeviceClickView();
  //   const totalCountryClick = useGetTotalCountryClick();
  //   const totalCountryView = useGetTotalCountryView();
  const transportsPassengerStatsDaily = useGetTransportsPassengerStatsDaily();
  const top10PassengerStatsByStation = useGetTop10PassengerStatsByStation();
  const top10PassengerStatsByTransport = useGetTop10PassengerStatsByTransport();
  const history = useHistory();

  const [progressClicks, setProgressClicks] = useState<any>([]);
  const [progressViews, setProgressViews] = useState<any>([]);
  const [top5campaignData, setTop5CampaignData] = useState([]);

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    var userid = getParameterByName('userid');
    const datefrom = getParameterByName('range');
    const dateto = getParameterByName('dateto');
    if (
      !roles.includes(Roles.Administrator) &&
      roles.includes(Roles.AdvertiserTransport)
    ) {
      userid = id;
    }
    dispatch(
      Actions.fetchCampaignsStatsByEnvAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    dispatch(Actions.fetchStationClickViewAction({ userid, datefrom, dateto }));
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid,
        datefrom,
        dateto,
        enviromentworlds: CampaignEnv.Transport,
      })
    );
    // dispatch(
    //   Actions.fetchTotalImpressionClickByCampaignAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalViewByArrCountryAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalClickByArrCountryAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //   })
    // );
    // dispatch(
    //   Actions.fetchDeviceClickViewAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //     enviromentworlds: CampaignEnv.Transport,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalCountryClickAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //   })
    // );
    // dispatch(
    //   Actions.fetchTotalCountryViewAction({
    //     userid,
    //     datefrom,
    //     dateto,
    //   })
    // );
    dispatch(
      Actions.fetchTransportsPassengerStatsDailyAction({
        userid,
        datefrom,
        dateto,
        publisher: 'OSEA',
        type: 'BUS',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByStationAction({
        userid,
        datefrom,
        dateto,
        publisher: 'OSEA',
      })
    );
    dispatch(
      Actions.fetchTop10PassengerStatsByTransportAction({
        userid,
        datefrom,
        dateto,
        publisher: 'OSEA',
      })
    );
  }, []);

  useEffect(() => {
    if (stationClickView.status === 'Success') {
      const maxClicks = Math.max(
        ...stationClickView.data.map((item: any) => {
          return item.totalclicks;
        })
      );
      const maxViews = Math.max(
        ...stationClickView.data.map((item: any) => {
          return item.totalviews;
        })
      );
      setProgressClicks(
        stationClickView.data
          .sort((a: any, b: any) => {
            return b.totalclicks - a.totalclicks;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalclicks / (maxClicks + 10)).toFixed(2)
            );

            return {
              percentage: percent * 100,
              value: item.totalclicks,
              name: item.Name,
            };
          })
      );
      setProgressViews(
        stationClickView.data
          .sort((a: any, b: any) => {
            return b.totalviews - a.totalviews;
          })
          .map((item: any) => {
            const percent: number = Number(
              Number(item.totalviews / (maxViews + 10)).toFixed(2)
            );
            return {
              percentage: percent * 100,
              value: item.totalviews,
              name: item.Name,
            };
          })
      );
    }
  }, [stationClickView]);

  // fetch dropdown option
  useEffect(() => {
    dispatch(Actions.fetchUserListAction({}));
  }, []);

  useEffect(() => {
    if (top5Campaign.status === 'Success') {
      setTop5CampaignData(top5Campaign.data);
    }
  }, [top5Campaign]);

  return (
    <PageLayout>
      <GridLayout
        elements={[
          {
            id: 'Search',
            size: 12,
            element: <SearchArea />,
          },
          {
            id: 'widget-left',
            size: 4,
            element: <LeftWidget campaignStats={campaignStats} />,
          },
          {
            id: 'widget-mid',
            size: 4,
            element: (
              <Widget2
                data={{
                  title: campaignStats?.data?.totalviews,
                  body: (
                    <TranslatedText textMap={Translations.impression_widget} />
                  ),
                  description: (
                    <TranslatedText
                      textMap={Translations.impression_widget_description}
                    />
                  ),
                }}
              />
            ),
          },
          {
            id: 'widget-right',
            size: 4,
            element: (
              <Widget2
                data={{
                  title: campaignStats?.data?.totalclicks,
                  body: <TranslatedText textMap={Translations.click_widget} />,
                  description: (
                    <TranslatedText
                      textMap={Translations.click_widget_description}
                    />
                  ),
                }}
              />
            ),
          },
          {
            id: 'Progress-click',
            size: 12,
            element: (
              <Card className={classes.mt12}>
                <CardContent>
                  <GridLayout
                    elements={[
                      {
                        id: 'progress-view',
                        size: 6,
                        element: (
                          <Progress
                            type={
                              <TranslatedText
                                textMap={Translations.impression_widget}
                              />
                            }
                            data={progressViews}
                          />
                        ),
                      },
                      {
                        id: 'progress-click',
                        size: 6,
                        element: (
                          <Progress
                            type={
                              <TranslatedText
                                textMap={Translations.click_widget}
                              />
                            }
                            data={progressClicks}
                          />
                        ),
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            id: 'station-map',
            size: 12,
            element: <BusStationMap stationClickView={stationClickView} />,
          },
          // {
          //   id: 'right-size',
          //   size: 5,
          //   element: (
          //     <GridLayout
          //       elements={[
          //         {
          //           id: 'pie-ctr',
          //           size: 12,
          //           element: (
          //             <Card>
          //               <CardHeader
          //                 title={
          //                   <div className={classes.tableHeader}>
          //                     <div className={classes.textBlue}>
          //                       <ImportantDevices style={{ marginRight: 10 }} />
          //                       <TranslatedText
          //                         textMap={
          //                           Translations.campaign_pie_chart_title
          //                         }
          //                       />
          //                     </div>
          //                   </div>
          //                 }
          //               />
          //               <CardContent>
          //                 {totalImpressionClickByCampaign.status ===
          //                 'Success' ? (
          //                   <DashboardBuilder
          //                     configuration={getConfigCTR(
          //                       totalImpressionClickByCampaign.data
          //                     )}
          //                   />
          //                 ) : null}
          //               </CardContent>
          //             </Card>
          //           ),
          //         },
          //         {
          //           id: 'pie-device',
          //           size: 12,
          //           element: (
          //             <Card>
          //               <CardHeader
          //                 title={
          //                   <div className={classes.tableHeader}>
          //                     <div className={classes.textBlue}>
          //                       <ImportantDevices style={{ marginRight: 10 }} />
          //                       <TranslatedText
          //                         textMap={Translations.device_pie_chart_title}
          //                       />
          //                     </div>
          //                   </div>
          //                 }
          //               />
          //               <CardContent>
          //                 {deviceClickView.status === 'Success' ? (
          //                   <DashboardBuilder
          //                     configuration={getConfigClick(
          //                       deviceClickView.data
          //                     )}
          //                   />
          //                 ) : null}
          //               </CardContent>
          //             </Card>
          //           ),
          //         },
          //       ]}
          //     />
          //   ),
          // },
          {
            id: 'transports passenger line chart',
            size: 12,
            element: (
              <React.Fragment>
                {transportsPassengerStatsDaily.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineChartConfig(
                      transportsPassengerStatsDaily.data,
                      'pubcode',
                      'Passenger Statistics Daily'
                    )}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'stations passenger bar chart',
            size: 12,
            element: (
              <React.Fragment>
                {top10PassengerStatsByStation.status === 'Success' ? (
                  // <DashboardBuilder
                  //   configuration={getLineChartConfig(
                  //     top10PassengerStatsByStation.data,
                  //     'Name',
                  //     'Passengers by Station'
                  //   )}
                  // />
                  <DashboardBuilder
                    configuration={getBarChartConfig({
                      data: top10PassengerStatsByStation.data.map(
                        (item: any) => item.passengers
                      ),
                      labels: top10PassengerStatsByStation.data.map(
                        (item: any) => item.Name
                      ),
                      title: Translations.top_10_passengers_by_station.English,
                    })}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'transports passenger bar chart',
            size: 12,
            element: (
              <React.Fragment>
                {top10PassengerStatsByTransport.status === 'Success' ? (
                  // <DashboardBuilder
                  //   configuration={getLineChartConfig(
                  //     top10PassengerStatsByTransport.data,
                  //     'transportCode',
                  //     'Passengers by Transport'
                  //   )}
                  // />
                  <DashboardBuilder
                    configuration={getBarChartConfig({
                      data: top10PassengerStatsByTransport.data.map(
                        (item: any) => item.passengers
                      ),
                      labels: top10PassengerStatsByTransport.data.map(
                        (item: any) => item.code
                      ),
                      title:
                        Translations.top_10_passengers_by_transport.English,
                    })}
                  />
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'top campaigns',
            size: 12,
            element: (
              <Card className={classes.tableLastNoStretch}>
                <CardHeader
                  title={
                    <div className={classes.tableHeader}>
                      <div className={classes.textBlue}>
                        <Star />{' '}
                        <TranslatedText
                          textMap={Translations.top_campaign_title}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/campaigns')}
                      >
                        <TranslatedText
                          textMap={Translations.go_to_campaign_button}
                        />
                      </Button>
                    </div>
                  }
                />
                <CardContent>
                  <Datatable
                    height={655}
                    loading={true}
                    data={top5campaignData}
                    columns={TableColumns}
                  />
                </CardContent>
              </Card>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(AdvDashboardPage);
