import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchTransportStationsList  */
interface ITransportStationsListOutput {
  items: IDataItems[];
  // paging: IPaging;
}

export interface IDataItems {
  id: number;
  pubcode: string;
  code: string;
  name: string | null;
  areacode: string | null;
  areaname: string | null;
  lat: number;
  lon: number;
  status: number | null;
}

export interface IMapBounds {
  tlc?: { lat: number; lng: number };
  trc?: { lat: number; lng: number };
  blc?: { lat: number; lng: number };
  brc?: { lat: number; lng: number };
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

export const fetchTransportStationsList = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transport-stations/list`
  );

  let _data: ITransportStationsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchTransportStationsListBounded = async (
  mapBounds: IMapBounds
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/transport-stations/list-bounded?blc_lat=${mapBounds.blc?.lat}&blc_lng=${mapBounds.blc?.lng}&tlc_lat=${mapBounds.tlc?.lat}&tlc_lng=${mapBounds.tlc?.lng}&brc_lat=${mapBounds.brc?.lat}&brc_lng=${mapBounds.brc?.lng}&trc_lat=${mapBounds.trc?.lat}&trc_lng=${mapBounds.trc?.lng}`
  );

  let _data: ITransportStationsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
