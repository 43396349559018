import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as CountriesRateService from '../../../services/api/countriesrate';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchCountriesRateListInput  */

interface FetchCountriesRateListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchCountriesRateList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchCountriesRateListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CountriesRateService.fetchCountriesRateList(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCountriesRateList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewCountriesRate  */

interface AddNewCountriesRateInput {
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number | null;
  rate: number | null;
  duration: number | null;
  fetchCountriesRateInput: FetchCountriesRateListInput;
}

class AddNewCountriesRate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_COUNTRIESRATE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_COUNTRIESRATE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_COUNTRIESRATE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewCountriesRateInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchCountriesRateInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await CountriesRateService.addCountriesRate(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchCountriesRateList().action(fetchCountriesRateInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_COUNTRIESRATE,
          })
        );
      } catch (error) {
        console.log('AddNewCountriesRate Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt CountriesRate  */

interface EditCountriesRateInput {
  id: number | null;
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number | null;
  rate: number | null;
  duration: number | null;
  fetchCountriesRateInput: FetchCountriesRateListInput;
}

class EditCountriesRate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIESRATE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIESRATE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIESRATE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditCountriesRateInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchCountriesRateInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await CountriesRateService.editCountriesRate(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchCountriesRateList().action(fetchCountriesRateInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_COUNTRIESRATE,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View CountriesRate  */

interface ViewCountriesRateInput {
  id: number;
}

class ViewCountriesRate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIESRATE_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewCountriesRateInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CountriesRateService.fetchCountriesRateDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewCountriesRate Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Delete CountriesRate  */

interface DeleteCountriesRateInput {
  id: number;
  fetchCountriesRateInput: FetchCountriesRateListInput;
}

class DeleteCountriesRate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DELETE_COUNTRIESRATE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DELETE_COUNTRIESRATE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DELETE_COUNTRIESRATE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteCountriesRateInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const { fetchCountriesRateInput, ...rest } = payload;

        let response = await CountriesRateService.deleteCountryRate({
          id: rest.id,
        });
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchCountriesRateList().action(fetchCountriesRateInput));
      } catch (error) {
        console.log('DeleteCountriesRate Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchCountriesRateListAction: (payload: FetchCountriesRateListInput) =>
    new FetchCountriesRateList().action(payload),
  addNewCountriesRateAction: (payload: AddNewCountriesRateInput) =>
    new AddNewCountriesRate().action(payload),
  editCountriesRateAction: (payload: EditCountriesRateInput) =>
    new EditCountriesRate().action(payload),
  viewCountriesRateAction: (payload: ViewCountriesRateInput) =>
    new ViewCountriesRate().action(payload),
  deleteCountriesRateAction: (payload: DeleteCountriesRateInput) =>
    new DeleteCountriesRate().action(payload),
};
