import _ from 'lodash';
import React from 'react';
import { Redirect, withRouter } from 'react-router';
import NavigationConfig from '../../navigation/config';

const RootPage = () => {
  console.log('Render RootPage');

  return <Redirect to={NavigationConfig.mainPage().path} />;
};

export default withRouter(RootPage);
