import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import _ from 'lodash'
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType1 } from '../configuration';
export default function getConfigImpressionDestination(data: any) {
  const DashboardBuilderConfigImpressionDestination: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: 'Impressions Per Destination',
            format: {
              label: 'Impressions Per Destination',
              backgroundColor: '#439CDD',
            },
          },
        ],
        labels: data.labels,
        chartType: ChartTypesEnum.COLUMN,
        options: OptionsGraphType1(_.max(data.data[0])),
        title: '',
      },
      fetch: null,
    },
  ];
  return DashboardBuilderConfigImpressionDestination;
}
