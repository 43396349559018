import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_COUNTRIES_LIST: string = typeCreatorV2(
  'CountriesReducer',
  'SetCountriesList'
);

export const SET_ADD_COUNTRIES: string = typeCreatorV2(
  'CountriesReducer',
  'SetAddCountries'
);

export const SET_EDIT_COUNTRIES: string = typeCreatorV2(
  'CountriesReducer',
  'SetEditCountries'
);

export const SET_COUNTRIES_DETAIL: string = typeCreatorV2(
  'CountriesReducer',
  'SetCountriesDetail'
);
