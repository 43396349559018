import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface CountriesRateListElement {
  ID: number;
  FromCountry: string;
  ToCountry: string;
  Passengers: number;
  Rate: string;
  Duration: number;
}

interface CountriesRateListData {
  paging: CountriesRateListPaging;
  items: CountriesRateListElement[];
}

interface CountriesRateListPaging {
  page: number;
  pageSize: number;
  total: number;
}

export interface CountriesRateOutput {
  status: StateStatus | null;
  data: CountriesRateListData | null;
}

export const useGetCountriesRateList = (): CountriesRateOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COUNTRIESRATE_REDUCER].countriesRateList
  );
  return data;
};

/* selector: useGetCountriesRateDetails */
interface CountriesRateDetailsData {
  id: number;
  fromcountry: string;
  tocountry: string;
  passengers: number;
  rate: string;
  duration: number;
}

interface CountriesRateDetailsOutput {
  status: StateStatus;
  data: CountriesRateDetailsData | null;
}

export const useGetCountriesRateDetails = (): CountriesRateDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COUNTRIESRATE_REDUCER].viewCountriesRate
  );
  return data;
};

export const useAddCountriesRateStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.COUNTRIESRATE_REDUCER].addCountriesRate['status']
  );
  return data;
};

export const useEditCountriesRateStatus = (): StateStatus => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.COUNTRIESRATE_REDUCER].editCountriesRate['status']
  );
  return data;
};
