import { AdDetailsSteps } from './components/ad_details_view/configuration';
import { AudienceSteps } from './components/audience_view/configuration';
import { CampaignInfoSteps } from './components/campaign_info_view/configuration';
import { PreviewSteps } from './components/preview_view/configuration';

export enum CampaignTabs {
  CAMPAIGN_INFO = 0,
  AUDIENCE = 1,
  DESTINATION = 2,
  AD_DETAILS = 3,
  PREVIEW = 4,
}

export interface IView {
  tab: CampaignTabs;
  step: number | null;
}

const CONFIGURATION = {
  stepping: {
    [CampaignTabs.CAMPAIGN_INFO.toString()]: Object.values(
      CampaignInfoSteps
    ).filter((item) => typeof item === 'number'),
    [CampaignTabs.DESTINATION.toString()]: null,
    [CampaignTabs.AUDIENCE.toString()]: Object.values(AudienceSteps).filter(
      (item) => typeof item === 'number'
    ),
    [CampaignTabs.AD_DETAILS.toString()]: Object.values(AdDetailsSteps).filter(
      (item) => typeof item === 'number'
    ),
    [CampaignTabs.PREVIEW.toString()]: Object.values(PreviewSteps).filter(
      (item) => typeof item === 'number'
    ),
  },
};
export default CONFIGURATION;
