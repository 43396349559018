import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface FlightsListElement {
  code: string;
  flightdate: string;
  airline: string;
  deptairport: string;
  arrairport: string;
  estpassengers: number;
  actpassengers: number;
  views: number;
  clicks: number;
}

interface FlightsListData {
  paging: FlightsListPaging;
  items: FlightsListElement[];
}

interface FlightsListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface FlightsistOutput {
  status: StateStatus;
  data: FlightsListData | null;
}

export const useGetFlightsList = (): FlightsistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.FLIGHTS_REDUCER].FlightsList
  );
  return data;
};
export const useGetPublisherList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.FLIGHTS_REDUCER].publisherList
  );
  return data;
};
