import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import useStyles from './styles';

export interface AppCheckboxProps extends CheckboxProps {
  iconSize?: number | string;
}

const AppCheckbox = ({ iconSize, ...props }: AppCheckboxProps) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      icon={<CircleUnchecked style={{ width: iconSize, height: iconSize }} />}
      checkedIcon={
        <CircleCheckedFilled style={{ width: iconSize, height: iconSize }} />
      }
      {...props}
    />
  );
};

export default React.memo(AppCheckbox);
