import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as PublisherService from '../../../services/api/publisher';

const SetOpen = (data: boolean): IAction => {
  return {
    type: Types.SET_OPEN_DIALOG,
    data,
  };
};
class FetchPublisherApplication implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getPublisherApplication(payload);
        dispatch(this.onSuccess(response.data));
        dispatch({
          type: Types.SET_PERSIST_PUBLISHER_APPLICATION,
          status: StateStatus.Success,
          data: response.data,
        });
      } catch (error) {
        console.log('getPublisherApplication Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class AddPublisherApplication implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_PUBLISHER_APPLICATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_PUBLISHER_APPLICATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_PUBLISHER_APPLICATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.addPublisherApplication(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchPublisherApplication().action({
            id: payload.id,
            sortField: 'ID',
            order: 'DESC',
            page: 0,
            pageSize: 10,
          })
        );
        dispatch(SetOpen(false));
      } catch (error) {
        console.log('addPublisherApplication Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchPublisherApplicationActions: (payload: any) =>
    new FetchPublisherApplication().action(payload),
  setOpen: (payload: boolean) => SetOpen(payload),
  addPublisherApplicationActions: (payload: any) =>
    new AddPublisherApplication().action(payload),
};
