import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard, { ISegment } from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import * as TransportLinesServices from 'services/api/transport_lines';

interface IProps {
  handleMultipleAdd: (line: any) => void;
  handleMultipleRemove: (line: any) => void;
}

const TransportLinesForm = (props: IProps) => {
  const classes = useStyles();
  const { handleMultipleAdd, handleMultipleRemove } = props;
  const [lines, setLines] = useState<TransportLinesServices.ITransportLine[]>(
    []
  );
  const [selectedLines, setSelectedLines] = useState<
    TransportLinesServices.ITransportLine[]
  >([]);

  React.useEffect(() => {
    TransportLinesServices.fetchTransportLines().then((output) => {
      setLines(output.data);
    });
  }, [setLines]);

  const updateStations = (
    selected: TransportLinesServices.ITransportLine[]
  ) => {
    const addedLine = selected.filter(
      (line) => !selectedLines.includes(line)
    )[0];
    const removedLines = selectedLines.filter(
      (line) => !selected.includes(line)
    );
    if (addedLine) {
      TransportLinesServices.fetchTranportStationsFromLine(addedLine.id).then(
        (output) => {
          handleMultipleAdd(output.data);
        }
      );
    }
    var removedStations: any[] = [];
    if (removedLines && removedLines.length > 0) {
      Promise.all(
        removedLines.map(async (removedLine) => {
          await TransportLinesServices.fetchTranportStationsFromLine(
            removedLine.id
          ).then((output) => {
            removedStations = [...removedStations, ...output.data];
          });
        })
      ).then(() => {
        handleMultipleRemove(removedStations);
      });
    }
  };

  return (
    <CardLayout
      cardStyling={{
        position: 'absolute',
        zIndex: 99999,
        width: '200px',
        margin: '10px 0 0 10px',
      }}
      body={
        <form className={classes.form} noValidate>
          <GridLayout
            justify="flex-start"
            elements={[
              {
                id: 'lines',
                element: (
                  <Autocomplete
                    multiple
                    id="lines"
                    options={lines}
                    getOptionLabel={(option) => option.code}
                    filterSelectedOptions
                    limitTags={1}
                    value={selectedLines ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={
                          <TranslatedText
                            textMap={Translations.transport_lines_form_lines}
                          />
                        }
                      />
                    )}
                    onChange={(
                      event: any,
                      value: TransportLinesServices.ITransportLine[]
                    ) => {
                      updateStations(value);
                      setSelectedLines(value);
                    }}
                  />
                ),
                size: 12,
              },
            ]}
          />
        </form>
      }
    />
  );
};

export default TransportLinesForm;
