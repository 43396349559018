import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  PDFViewer,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';

import { useGetExportData } from 'redux/reducers/reporting_reducer/selectors';
import { IExportData as IGraphs } from 'redux/reducers/reporting_reducer/reducers';

interface IDetail {
  email: string;
  range: string;
}
interface IExportData extends IGraphs, IDetail {}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  documentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  documentFooter: {
    paddingHorizontal: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  tableCell: {
    border: '1px solod #000',
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  row: { width: '100%', display: 'flex', flexDirection: 'row' },
  cardValue: {
    fontSize: 14,
    color: '#005FA3',
    fontWeight: 700,
    marginBottom: 5,
  },
  cardName: {
    fontSize: 10,
    color: '#005FA3',
    fontWeight: 'semibold',
    marginBottom: 2,
  },
  cardDescription: {
    fontSize: 8,
    color: '#005FA3',
    fontWeight: 'light',
  },
  cardContainer: {
    border: '1px solid gray',
    width: '30%',
    height: 80,
    borderRadius: 10,
    padding: 10,
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }: any) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);
const Header = (props: { email: string; names: string; range: string }) => {
  React.useEffect(() => {
    console.log('range', props.range);
  }, [props.range]);

  return (
    <View style={styles.documentHeader}>
      <Text style={styles.text}>{props.email}</Text>
      <Text style={styles.text}>
        {props.names.length > 10
          ? props.names.slice(0, 10) + '...'
          : props.names}{' '}
        {moment().format('DD/MM/YYYY')} - {moment().format('DD/MM/YYYY')}{' '}
      </Text>
    </View>
  );
};
const Footer = (props: { currentPage: number; totalPage: number }) => {
  return (
    <View style={styles.documentFooter}>
      <Text style={styles.text}>
        Page: {`${props.currentPage} / ${props.totalPage}`}
      </Text>
      <Image
        style={{ width: 150, height: 50 }}
        src={require('assets/images/logos/logo-full.png')}
      />
    </View>
  );
};

const ReportDocument = (props: IExportData) => (
  <Document>
    <Page style={styles.body} wrap>
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          height: '100%',
        }}
      >
        <View>
          <Image
            style={styles.image}
            src={require('assets/images/logos/logo-full.png')}
          />
          <Text style={styles.title}>{props.email}</Text>
        </View>
        <View>
          <Text style={styles.title}>{props.names}</Text>
          <Text style={styles.title}>Performance Report</Text>
        </View>
        <View>
          <Text style={styles.title}>Report Dates</Text>
        </View>
        {props.range === '' && (
          <Text style={styles.textCenter}>
            Over last 6 months - {moment().format('DD/MM/YYYY')}
          </Text>
        )}
        {props.range !== '' && (
          <Text style={styles.textCenter}>
            {moment(new Date(props.range)).format('DD/MM/YYYY')} -{' '}
            {moment().format('DD/MM/YYYY')}
          </Text>
        )}
      </View>
    </Page>
    <Page>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >
        <View>
          <Header names={props.names} email={props.email} range={props.range} />
          <View style={{ paddingHorizontal: 20 }}>
            <Text style={[styles.text, { fontSize: 30 }]}>
              Campaign Details
            </Text>
            <View style={[{ width: '100%' }, styles.tableCell]}>
              <Text>CAMPAIGN AND DATES INFORMATION</Text>
            </View>
            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Campaign Name</Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                <Text>{props.names}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Campaign Dates</Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                {props.range === '' && (
                  <Text style={styles.textCenter}>
                    Over last 6 months - {moment().format('DD/MM/YYYY')}
                  </Text>
                )}
                {props.range !== '' && (
                  <Text style={styles.textCenter}>
                    {moment(new Date(props.range)).format('DD/MM/YYYY')} -{' '}
                    {moment().format('DD/MM/YYYY')}
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Total Duration </Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                {props.range === '' && <Text> 180 Days </Text>}
                {props.range !== '' && (
                  <Text>
                    {`${moment().diff(moment(new Date(props.range)), 'days')} ${
                      moment().diff(moment(new Date(props.range)), 'days') === 1
                        ? 'Day'
                        : 'Days'
                    }`}
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Remaining Duration</Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                <Text>XX Days</Text>
              </View>
            </View>

            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Reporting Period </Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                {/* <Text>DD Month YYYY – DD Month YYYY</Text> */}
                {props.range === '' && (
                  <Text style={styles.textCenter}>
                    Over last 6 months - {moment().format('DD/MM/YYYY')}
                  </Text>
                )}
                {props.range !== '' && (
                  <Text style={styles.textCenter}>
                    {moment(new Date(props.range)).format('DD/MM/YYYY')} -{' '}
                    {moment().format('DD/MM/YYYY')}
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.row}>
              <View
                style={[styles.tableCell, { width: '50%', borderRight: '0px' }]}
              >
                <Text>Airlines Used</Text>
              </View>
              <View style={[{ width: '50%' }, styles.tableCell]}>
                <Text>Airline A</Text>
                <Text>Airline B</Text>
                <Text>Airline C</Text>
              </View>
            </View>
          </View>
        </View>
        <Footer currentPage={2} totalPage={5} />
      </View>
    </Page>
    <Page>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >
        <View>
          <Header names={props.names} email={props.email} range={props.range} />
          <Text style={[styles.text, { fontSize: 30, textAlign: 'center' }]}>
            Overall Campaign Analytics
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              paddingHorizontal: 10,
              marginBottom: 10,
            }}
          >
            <View style={styles.cardContainer}>
              <Text style={styles.cardValue}>{props.ctr} %</Text>
              <Text style={styles.cardName}>CTR</Text>
              <Text style={styles.cardDescription}>
                The CTR (click-through rate) is the number of clicks that your
                advert received divided by the number of times your advert is
                shown.
              </Text>
            </View>
            <View style={styles.cardContainer}>
              <Text style={styles.cardValue}>{props.clicks}</Text>
              <Text style={styles.cardName}>Clicks</Text>
              <Text style={styles.cardDescription}>
                To total number of times users clicked on your advert
              </Text>
            </View>
            <View style={styles.cardContainer}>
              <Text style={styles.cardValue}>{props.views}</Text>
              <Text style={styles.cardName}>Impressions</Text>
              <Text style={styles.cardDescription}>
                The total number of times your advert was shown on the users’
                device
              </Text>
            </View>
          </View>
          {props.line !== '' && (
            <Image style={{ height: 150, width: '100%' }} src={props.line} />
          )}
        </View>
        <Footer currentPage={3} totalPage={5} />
      </View>
    </Page>
    <Page>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >
        <View>
          <Header names={props.names} email={props.email} range={props.range} />
          <Text style={[styles.text, { fontSize: 30, textAlign: 'center' }]}>
            Overall Campaign
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Image style={{ height: 250, width: 250 }} src={props.columns[0]} />
            <Image style={{ height: 250, width: 250 }} src={props.columns[1]} />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Image style={{ height: 250, width: 250 }} src={props.columns[2]} />
            <Image style={{ height: 250, width: 250 }} src={props.columns[3]} />
          </View>
        </View>

        <Footer currentPage={1} totalPage={5} />
      </View>
    </Page>
    <Page>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >
        <View>
          <Header names={props.names} email={props.email} range={props.range} />
          <Text style={[styles.text, { fontSize: 30, textAlign: 'center' }]}>
            Overall Campaign Analytics
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {props.columns.map((item: string) => (
              <Image
                key={item}
                style={{ height: 250, width: 250 }}
                src={item}
              />
            ))}
          </View>

          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '10px',
              paddingHorizontal: 10,
            }}
          >
            {props.progressView !== '' && (
              <Image
                style={{ height: 50, width: '48%' }}
                src={props.progressView}
              />
            )}
            {props.progressClick !== '' && (
              <Image
                style={{ height: 50, width: '48%' }}
                src={props.progressClick}
              />
            )}
          </View>
          <View style={{ width: '100%' }}>
            {props.map !== '' && (
              <Image style={{ height: 450, width: '100%' }} src={props.map} />
            )}
          </View>
        </View>

        <Footer currentPage={4} totalPage={5} />
      </View>
    </Page>

    <Page>
      <Image
        style={styles.image}
        src={require('assets/images/logos/logo-full.png')}
      />
      <Text style={{ fontSize: 40, marginTop: 40, textAlign: 'center' }}>
        Thank you
      </Text>
    </Page>
  </Document>
);
export default () => {
  const exportData = useGetExportData();
  const { email } = useSelectUserData();
  return (
    <React.Fragment>
      {exportData.columns.length > 0 && (
        <PDFViewer width="100%" height="1200px">
          <ReportDocument {...exportData} email={email} />
        </PDFViewer>
      )}
    </React.Fragment>
  );
};
