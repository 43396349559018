import { ThemesModeEnum } from 'config';
import { IAction, IActionMethods } from '../../utils/common';
import * as Types from './types';
// import reactLogger from 'uti'

class ChangeTheme implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(result: ThemesModeEnum): IAction {
    return {
      type: Types.SET_THEME,
      data: result,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_THEME,
      data: null,
    };
  }

  action(data: ThemesModeEnum): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      // reactLogger.log('ChangeTheme Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

export default {
  changeThemeAction: (data: ThemesModeEnum) => new ChangeTheme().action(data),
};
