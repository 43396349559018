import CardLayout from 'components/app_common/card_layout';
import React from 'react';
import useStyles from './styles';
import PreviewAudienceForm from './PreviewAudienceForm';
import ICampaignInfoWizard from 'config/campaign_wizard';

interface IProps {
  campaign: ICampaignInfoWizard;
}

const PreviewCampaignInfo = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.formContainer}>
      <CardLayout body={<PreviewAudienceForm campaign={props.campaign} />} />
    </div>
  );
};

export default PreviewCampaignInfo;
