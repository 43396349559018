import { Container, ContainerProps } from '@material-ui/core';
import React, { ReactElement } from 'react';
import useStyles from './styles';

interface IProps extends Omit<ContainerProps, 'className' | 'maxWidth'> {
  children: ReactElement[] | ReactElement;
  paddingTop?: boolean;
  paddingBottom?: boolean;
}

export default function PageLayout(props: IProps) {
  const { paddingTop = true, paddingBottom = true, children, ...rest } = props;
  const classes = useStyles({ paddingTop, paddingBottom });

  return (
    // <div className={classes.root} {...props} />
    <Container {...rest} className={classes.root} maxWidth="xl">
      {children}
    </Container>
  );
}
