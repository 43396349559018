import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'w-full': {
      width: '100%',
    },
    'w-256': {
      width: 256,
    },
    'justify-between': {
      justifyContent: 'space-between',
    },
    boxShadow: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: 5,
      height: 39,
      marginRight: 10,
      padding: 8,
    },
    flex: {
      display: 'flex',
    },
    root: {
      flexGrow: 1,
    },
    textBlue: {
      color: '#005fa3',
    },
    textLightBlue: {
      color: '#36C2F7',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textBold: {
      fontWeight: 600,
    },
    mt12: {
      marginTop: 12,
    },
    mb24: {
      marginBottom: 20,
    },
    'ml-12': {
      marginLeft: 12,
    },
    'mr-12': {
      marginRight: 12,
    },
    'w-1/4': {
      width: '25%',
    },
    'w-128': {
      width: 128,
    },
    'h-48': {
      height: 48,
    },
    'in-active': {
      borderRadius: 5,
      textTransform: 'capitalize',
      width: 128,
      backgroundColor: '#ffffff',
      color: '#005FA3',
      '&:hover': {
        backgroundColor: '#005FA3',
        color: '#ffffff',
      },
    },
    active: {
      borderRadius: 5,
      textTransform: 'capitalize',
      width: 128,
      backgroundColor: '#005FA3',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#ffffff',
        color: '#005FA3',
      },
    },
  })
);

export default useStyles;
