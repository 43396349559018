import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';

interface IState {
  Transport: {
    status: StateStatus | null;
    data: any;
  };
  editTransport: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  Transport: {
    status: null,
    data: null,
  },
  editTransport: {
    status: null,
  },
};

const TransportReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_TRANSPORT:
      return {
        ...state,
        Transport: {
          ...state.Transport,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.Transport.data : action.data,
        },
      };
    case Types.EDIT_TRANSPORT:
      return {
        ...state,
        editTransport: {
          ...state.editTransport,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default TransportReducer;
