import React from 'react';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import { AppTextField } from 'components/molecules/form_controls';
import Translations from '../../translations';
import ICampaignInfoWizard, { ISegment } from 'config/campaign_wizard';

interface IFormData {
  publishers: string | null;
  gender: string | null;
  age_group: string | null;
  device: string | null;
  mobile_environment: string | null;
  operating_system: string | null;
}

interface IProps {
  campaign: ICampaignInfoWizard;
}

const PreviewAudienceForm = (props: IProps) => {
  const { campaign } = props;
  const classes = useStyles();
  const { errors, control } = useForm<IFormData>({
    defaultValues: {
      publishers: campaign.publisher
        ?.map((airline) => airline.Name)
        ?.join(', '),
      gender: campaign.gender ? campaign.gender.join(', ') : null,
      age_group: campaign.age_group ? campaign.age_group.join(', ') : null,
      device: campaign.device ? campaign.device.join(', ') : null,
      mobile_environment: campaign.mobile_environment
        ? campaign.mobile_environment.join(', ')
        : null,
      operating_system: campaign.operating_system
        ? campaign.operating_system.join(', ')
        : null,
    },
  });

  return (
    <form
      className={classes.form}
      /* onSubmit={handleSubmit(onSubmit)} */ noValidate
    >
      <GridLayout
        justify="flex-start"
        elements={[
          // {
          //   id: 'publishers',
          //   element: (
          //     <AppTextField
          //       disabled={true}
          //       variant="outlined"
          //       margin="normal"
          //       error={'publishers' in errors}
          //       helperText={errors.publishers && errors.publishers.message}
          //       rules={{}}
          //       fullWidth
          //       id="publishers"
          //       control={control}
          //       label={
          //         <TranslatedText textMap={Translations.publishers_control} />
          //       }
          //       autoComplete="publishers"
          //       name="publishers"
          //     />
          //   ),
          //   size: 6,
          // },
          {
            id: 'gender',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'gender' in errors}
                helperText={errors.gender && errors.gender.message}
                rules={{}}
                fullWidth
                id="gender"
                control={control}
                label={<TranslatedText textMap={Translations.gender_control} />}
                autoComplete="gender"
                name="gender"
              />
            ),
            size: 6,
          },
          {
            id: 'device',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'device' in errors}
                helperText={errors.device && errors.device.message}
                rules={{}}
                fullWidth
                id="device"
                control={control}
                label={<TranslatedText textMap={Translations.device_control} />}
                autoComplete="device"
                name="device"
              />
            ),
            size: 6,
          },
          {
            id: 'mobile_environment',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'mobile_environment' in errors}
                helperText={
                  errors.mobile_environment && errors.mobile_environment.message
                }
                rules={{}}
                fullWidth
                id="mobile_environment"
                control={control}
                label={
                  <TranslatedText
                    textMap={Translations.mobile_environment_control}
                  />
                }
                autoComplete="mobile_environment"
                name="mobile_environment"
              />
            ),
            size: 6,
          },
          {
            id: 'operating_system',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'operating_system' in errors}
                helperText={
                  errors.operating_system && errors.operating_system.message
                }
                rules={{}}
                fullWidth
                id="operating_system"
                control={control}
                label={
                  <TranslatedText
                    textMap={Translations.operating_system_control}
                  />
                }
                autoComplete="operating_system"
                name="operating_system"
              />
            ),
            size: 6,
          },
          {
            id: 'age_group',
            element: (
              <AppTextField
                disabled={true}
                variant="outlined"
                margin="normal"
                error={'age_group' in errors}
                helperText={errors.age_group && errors.age_group.message}
                rules={{}}
                fullWidth
                id="age_group"
                control={control}
                label={
                  <TranslatedText textMap={Translations.age_group_control} />
                }
                autoComplete="age_group"
                name="age_group"
              />
            ),
            size: 12,
          },
        ]}
      />
    </form>
  );
};

export default PreviewAudienceForm;
