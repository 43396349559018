import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ModalIDs } from './types';

interface IuseModalDataOutput {
  params: any;
  visible: boolean;
}

export const useModalData = (modalID: ModalIDs): IuseModalDataOutput => {
  const data: IuseModalDataOutput = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.MODAL_REDUCER].modals[modalID]
  );
  return data;
};
