import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchAirlinesList  */
interface IAirlinesListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  iataid: string | null;
  name: string | null;
  code: string | null;
  country: number;
  type: number;
  publisherid: number;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IAirlinesListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchAirlinesList = async (
  payload: IAirlinesListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airlines/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IAirlinesListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Airlines Detail */
interface IAirlinesDetailInput {
  iataid: number;
}

interface IAirlinesDetailOutput extends Types.ResponseOutput {
  data: {
    iataid: string | null;
    name: string | null;
    code: string | null;
    country: number | null;
    type: number | null;
    publisherid: number | null;
  };
}

export const fetchAirlinesDetail = async (
  payload: IAirlinesDetailInput
): Promise<IAirlinesDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airlines/${payload.iataid}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Airlines */
interface IAirlinesAddInput {
  iataid: string | null;
  name: string | null;
  code: string | null;
  country: number | null;
  type: number | null;
  publisherid: number | null;
}

interface IAirlinesAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addAirlines = async (
  payload: IAirlinesAddInput
): Promise<IAirlinesAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/airlines `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Airlines */
interface IAirlinesEditInput {
  iataid: string | null;
  name: string | null;
  code: string | null;
  country: number | null;
  type: number | null;
  publisherid: number | null;
}

interface IAirlinesEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editAirlines = async (
  payload: IAirlinesEditInput
): Promise<IAirlinesEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/airlines `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
