// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export const getCountriesByCode = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/countries?code=${payload.join(',')}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** fetchCountriesList  */
interface ICountriesListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  code: string | null;
  name: string | null;
  capital: string | null;
  lat: string | null;
  lon: string | null;
  code3: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface ICountriesListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchCountriesList = async (
  payload: ICountriesListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/countries/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: ICountriesListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Countries Detail */
interface ICountriesDetailInput {
  id: number;
}

interface ICountriesDetailOutput extends Types.ResponseOutput {
  data: {
    code: string | null;
    name: string | null;
    capital: string | null;
    lat: number | null;
    lon: number | null;
    code3: string | null;
  };
}

export const fetchCountriesDetail = async (
  payload: ICountriesDetailInput
): Promise<ICountriesDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/countries/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Countries */
interface ICountriesAddInput {
  code: string | null;
  name: string | null;
  capital: string | null;
  lat: number | null;
  lon: number | null;
  code3: string | null;
}

interface ICountriesAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addCountries = async (
  payload: ICountriesAddInput
): Promise<ICountriesAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/countries `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Countries */
interface ICountriesEditInput {
  code: string | null;
  name: string | null;
  capital: string | null;
  lat: number | null;
  lon: number | null;
  code3: string | null;
}

interface ICountriesEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editCountries = async (
  payload: ICountriesEditInput
): Promise<ICountriesEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/countries `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
