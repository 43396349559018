import React, { FunctionComponent } from 'react';
import { Environment } from '../../../config';

interface IIamgeShowProps {
  picture: string;
  customStyle?: React.CSSProperties;
  alt?: string;
}

const ImageShow: FunctionComponent<IIamgeShowProps> = ({
  picture,
  customStyle,
  alt,
}) => {
  return (
    <img
      src={
        picture ? picture : `${Environment.API_BASE_URL}/storage/default.jpg`
      }
      onError={(e: any) => {
        e.target.src = `/assets/images/not_found.jpg`;
      }}
      alt={alt ?? ''}
      style={
        customStyle || {
          width: '100%',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          height: '186px',
          objectFit: 'cover',
        }
      }
    />
  );
};

export default ImageShow;
