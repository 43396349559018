import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  action_button: {
    marginLeft: '.5rem',
  },
}));

export default useStyles;
