import {
  IGridConf,
  ComponentTypeEnum,
} from '../../../components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from '../../../components/molecules/custom_chart';
import { OptionsGraphType2 } from '../configuration';

export interface IGeneralPublisherStatisticInput {
  userid: number;
  appid: string | null;
  publisherid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

function getImpressionAppConfig(data: any) {
  const DashboardBuilderConfigImpressionApplication: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data: data.data[0],
            label: '',
            format: {
              backgroundColor: [
                '#5EE7EA',
                '#FF5E90',
                '#439CDD',
                '#4FCBDF',
                '#FFFF89',
                '#A78BFA',
                '#F472B6',
                '#818CF8',
                '#34D399',
                '#F59E0B',
                '#DC2626',
              ],
              borderWidth: 1,
            },
          },
        ],
        // labels: ['Y', 'N'],
        labels: data.labels,
        chartType: ChartTypesEnum.PIE,
        options: OptionsGraphType2(70000),
        title: '',
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/publisher-campaign-click-view/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return DashboardBuilderConfigImpressionApplication;
}
export default getImpressionAppConfig;
