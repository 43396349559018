import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_ALL_USERS_WITH_ROLES: string = typeCreatorV2(
  'AdministratorReducer',
  'SetAllUsersWithRoles'
);
export const SET_PERSIST_ALL_USERS_WITH_ROLES: string = typeCreatorV2(
  'AdministratorReducer',
  'SetPersistAllUsersWithRoles'
);
