import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  User_Price_CalculationList: {
    status: StateStatus | null;
    data: any;
  };
  addUser_Price_Calculation: {
    status: StateStatus | null;
  };
  editUser_Price_Calculation: {
    status: StateStatus | null;
  };
  viewUser_Price_Calculation: {
    status: StateStatus | null;
    data: any;
  };
  deleteUser_Price_Calculation: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  User_Price_CalculationList: {
    status: null,
    data: null,
  },
  addUser_Price_Calculation: {
    status: null,
  },
  editUser_Price_Calculation: {
    status: null,
  },
  viewUser_Price_Calculation: {
    status: null,
    data: null,
  },
  deleteUser_Price_Calculation: {
    status: null,
  },
};

const User_Price_CalculationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_PRICE_CALCULATION_LIST:
      return {
        ...state,
        User_Price_CalculationList: {
          ...state.User_Price_CalculationList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.User_Price_CalculationList.data
              : action.data,
        },
      };
    case Types.SET_ADD_USER_PRICE_CALCULATION:
      return {
        ...state,
        addUser_Price_Calculation: {
          ...state.addUser_Price_Calculation,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_USER_PRICE_CALCULATION:
      return {
        ...state,
        editUser_Price_Calculation: {
          ...state.editUser_Price_Calculation,
          status: action.status || null,
        },
      };
    case Types.SET_USER_PRICE_CALCULATION_DETAIL:
      return {
        ...state,
        viewUser_Price_Calculation: {
          ...state.viewUser_Price_Calculation,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewUser_Price_Calculation.data
              : action.data,
        },
      };
    case Types.SET_DELETE_USER_PRICE_CALCULATION:
      return {
        ...state,
        deleteUser_Price_Calculation: {
          ...state.deleteUser_Price_Calculation,
          status: action.status || null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default User_Price_CalculationReducer;
