import React from 'react';
import ICampaignInfoWizard from 'config/campaign_wizard';
import useStyles from './styles';
import { Environment } from 'config';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';

import Translations from '../../translations';
import GridLayout from 'components/molecules/grid_layout';
import { AppTextField } from 'components/molecules/form_controls';
import CardLayout from 'components/app_common/card_layout';

interface IFormData {
  description: string | null;
  landing_page: string | null;
}

interface IProps {
  campaign: ICampaignInfoWizard;
}
const PreviewAdDetails = (props: IProps) => {
  const classes = useStyles();

  let defaultValues = {
    description: props.campaign.advertisment_description,
    landing_page: props.campaign.landing_page ?? null,
  };
  const { errors, control } = useForm<IFormData>({
    defaultValues,
  });

  return (
    <React.Fragment>
      {/* <AdInfo
        description={props.campaign.advertisment_description ?? null}
        landing_page={props.campaign.landing_page ?? null}
      /> */}
      <div className={classes.formContainer}>
        <CardLayout
          body={
            <React.Fragment>
              <form className={classes.form} noValidate>
                <GridLayout
                  justify="flex-start"
                  elements={[
                    {
                      id: 'description',
                      element: (
                        <AppTextField
                          disabled={true}
                          variant="outlined"
                          margin="normal"
                          error={'description' in errors}
                          helperText={
                            errors.description && errors.description.message
                          }
                          rules={{}}
                          fullWidth
                          id="description"
                          control={control}
                          label={
                            <TranslatedText
                              textMap={
                                Translations.advertisment_description_control
                              }
                            />
                          }
                          autoComplete="description"
                          name="description"
                        />
                      ),
                      size: 12,
                    },
                    {
                      id: 'landing_page',
                      element: props.campaign.landing_page ? (
                        <AppTextField
                          disabled={true}
                          variant="outlined"
                          margin="normal"
                          error={'landing_page' in errors}
                          helperText={
                            errors.landing_page && errors.landing_page.message
                          }
                          rules={{}}
                          fullWidth
                          id="landing_page"
                          control={control}
                          label={
                            <TranslatedText
                              textMap={Translations.landing_page_control}
                            />
                          }
                          autoComplete="landing_page"
                          name="landing_page"
                        />
                      ) : (
                        <></>
                      ),
                      size: 12,
                    },
                  ]}
                />
              </form>
              <FileGrid ads={props.campaign.advertisments} />
            </React.Fragment>
          }
        />
      </div>
      {/* <FileGrid ads={props.campaign.advertisments} /> */}
    </React.Fragment>
  );
};

interface IAdInfo {
  description: string | null;
  landing_page: string | null;
}
const AdInfo = (props: IAdInfo) => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: '1rem' }}>
      <div>
        <span className={classes.infoTitle}>
          <TranslatedText textMap={Translations.description} />
        </span>
        : {props.description}
      </div>
      {props.landing_page && (
        <div>
          <span className={classes.infoTitle}>
            <TranslatedText textMap={Translations.landing_page} />
          </span>
          : {props.landing_page}
        </div>
      )}
    </div>
  );
};

interface IFileGrid {
  ads: any;
}
const FileGrid = (props: IFileGrid) => {
  const classes = useStyles();
  const { ads } = props;
  const filesKeys = Object.keys(ads);
  return (
    <div className={classes.imagesGrid}>
      {filesKeys.map((key, index) => (
        <FileView key={index} ad={ads[key]} />
      ))}
    </div>
  );
};

interface IFileViewProps {
  ad: any;
}
const FileView = (props: IFileViewProps) => {
  const { file_url, size, type, type_id } = props.ad;
  // const { contentUrl } = props;
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      {type.toUpperCase() === 'IMAGE' ? (
        <img
          className={classes.image}
          src={`${Environment.API_BASE_URL}${file_url}`}
        />
      ) : (
        <video width="120" height="120" controls>
          <source
            src={`${Environment.API_BASE_URL}${file_url}`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default PreviewAdDetails;
