import { SideBarConfig } from '../../../navigation/config/Routes';
import { IAction } from '../../utils/common';
import * as Types from './types';

interface IState {
  sideMenuIsOpen: boolean;
  selectedItemID: number | null;
}

const initialState: IState = {
  sideMenuIsOpen: true,
  selectedItemID: SideBarConfig.length > 0 ? SideBarConfig[0].id : null,
};

const NavigationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_SIDE_MENU_VISIBILITY:
      return {
        ...state,
        sideMenuIsOpen: action.data.sideMenuIsOpen,
      };
    case Types.SET_NAVIGATE_TO_SCREEN:
      return {
        ...state,
        sideMenuIsOpen: action.data.visibility,
        selectedItemID: action.data.selectedItemID,
      };
    default:
      return {
        ...state,
      };
  }
};

export default NavigationReducer;
