import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as PublisherService from '../../../services/api/publisher';
import * as ApplicationLocationService from '../../../services/api/application_location';
import * as CategoryService from '../../../services/api/category';
import { IDialog, ISavePublisherApplicationType } from './reducers';

const SetDialog = (data: IDialog): IAction => {
  return {
    type: Types.SET_DIALOG,
    data,
  };
};
const SetIsNewApplicationType = (data: boolean): IAction => {
  return {
    type: Types.SET_IS_NEW_APPLICATION_TYPE,
    data,
  };
};
const SetNewPublisherApplicationType = (data: any): IAction => {
  return {
    type: Types.SET_NEW_PUBLISHER_APPLICATION_TYPE,
    data,
  };
};
const SetCurrentPublisherApplicationType = (data: any): IAction => {
  return {
    type: Types.SET_CURRENT_PUBLISHER_APPLICATION_TYPE,
    data,
  };
};
class FetchPublisherApplication implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getPublisherApplicationByUniqueid(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPublisherApplication Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchCategory implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CATEGORY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CATEGORY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CATEGORY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CategoryService.getCategory();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getCategory Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class UpdatePublisherApplication implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_APPLICATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_APPLICATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_APPLICATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.updatePublisherApplication(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('updatePublisherApplication Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchPublisherApplicationType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION_TYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHER_APPLICATION_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getPublisherapplicationType(
          payload
        );
        if (response.data) {
          const updateApplicationType = response.data.filter(
            (app: any) => app.TypeUniqueKey !== null
          );
          const newApplicationType = response.data.filter(
            (app: any) => app.TypeUniqueKey === null
          );
          dispatch(SetCurrentPublisherApplicationType(updateApplicationType));
          dispatch(SetNewPublisherApplicationType(newApplicationType));

          dispatch(this.onSuccess(updateApplicationType));
        }
      } catch (error) {
        console.log('getPublisherapplicationType Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchLocation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LOCATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LOCATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LOCATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ApplicationLocationService.getLocation(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getLocation Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class SavePublisherApplicationType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SAVE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SAVE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SAVE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ISavePublisherApplicationType): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.savePublisherapplicationType(
          payload
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchPublisherApplicationType().action({
            applicationid: payload.applicationid,
            userid: payload.userid,
          })
        );
        dispatch(
          SetDialog({
            isOpen: false,
            type: 'add',
            data: null,
          })
        );
        dispatch(SetIsNewApplicationType(false));
      } catch (error) {
        console.log('savePublisherapplicationType Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class DeletePublisherApplicationType implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_PUBLIHSER_APPLICATION_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.deletePublisherapplicationType(
          payload
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchPublisherApplicationType().action({
            applicationid: payload.applicationid,
            userid: payload.userid,
          })
        );
        dispatch(
          SetDialog({
            isOpen: false,
            type: 'add',
            data: null,
          })
        );
        dispatch(SetIsNewApplicationType(false));
      } catch (error) {
        console.log('deletePublisherapplicationType Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchPublisherApplicationActions: (payload: any) =>
    new FetchPublisherApplication().action(payload),
  fetchCategoryActions: (payload: any) => new FetchCategory().action(payload),
  updatePublisherApplicationAction: (payload: any) =>
    new UpdatePublisherApplication().action(payload),
  fetchPublisherApplicationTypeActions: (payload: any) =>
    new FetchPublisherApplicationType().action(payload),
  fetchLocationActions: (payload: any) => new FetchLocation().action(payload),
  setDialog: (payload: IDialog) => SetDialog(payload),
  savePublisherApplicationType: (payload: ISavePublisherApplicationType) =>
    new SavePublisherApplicationType().action(payload),
  setCurrentPublisherApplicationType: (payload: any) =>
    SetCurrentPublisherApplicationType(payload),
  setNewPublisherApplicationType: (payload: any) =>
    SetNewPublisherApplicationType(payload),
  setIsNewApplicationType: (payload: boolean) =>
    SetIsNewApplicationType(payload),
  deletePublisherApplicationTypeActions: (payload: any) =>
    new DeletePublisherApplicationType().action(payload),
};
