import React from 'react';
import TranslatedText from 'components/atoms/translated_text';
import { LanguagesEnum } from 'config/languages';

export const Translations = {
  status_control: {
    [LanguagesEnum.English]: 'Status',
    [LanguagesEnum.Greek]: 'Κατάσταση',
    [LanguagesEnum.Russian]: 'Статус',
  },
  status_active: {
    [LanguagesEnum.English]: 'Active',
    [LanguagesEnum.Greek]: 'Ενεργός',
    [LanguagesEnum.Russian]: 'активный',
  },
  status_inactive: {
    [LanguagesEnum.English]: 'Inactive',
    [LanguagesEnum.Greek]: 'Αδρανής',
    [LanguagesEnum.Russian]: 'Неактивный',
  },
  status_placeholder: {
    [LanguagesEnum.English]: 'Select status',
    [LanguagesEnum.Greek]: 'Επιλέξτε την κατάσταση',
    [LanguagesEnum.Russian]: 'Выберите статус',
  },
  campaign_control: {
    [LanguagesEnum.English]: 'Campaign',
    [LanguagesEnum.Greek]: 'καμπάνια',
    [LanguagesEnum.Russian]: 'Кампания',
  },
  search: {
    [LanguagesEnum.English]: 'Search',
    [LanguagesEnum.Greek]: 'Αναζήτηση',
    [LanguagesEnum.Russian]: 'Поиск',
  },
  clear: {
    [LanguagesEnum.English]: 'Clear',
    [LanguagesEnum.Greek]: 'Σαφή',
    [LanguagesEnum.Russian]: 'ясно',
  },
};

export const StatusOptions = [
  {
    id: null,
    label: <TranslatedText textMap={Translations.status_placeholder} />,
  },
  {
    id: '0',
    label: <TranslatedText textMap={Translations.status_inactive} />,
  },
  {
    id: '1',
    label: <TranslatedText textMap={Translations.status_active} />,
  },
];
