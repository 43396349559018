import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';

import Datatable from '../../../components/molecules/datatable';
import { useGetAllUsersWithRoles } from '../../../redux/reducers/administrator_reducer/selectors';

import { TableColumns } from '../configuration';
import Actions from '../../../redux/reducers/administrator_reducer/actions';
export default function Table() {
  const allusers = useGetAllUsersWithRoles();
  const paging = allusers.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={allusers.data?.items || []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            Actions.fetchAllUsersWithRolesAction({
              sortField: _sortField,
              order,
              page: selectedPage,
              pageSize,
            })
          );
        }}
      />
    </Box>
  );
}
