import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as User_Price_CalculationService from '../../../services/api/user_price_calculation';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchUser_Price_CalculationListInput  */

interface FetchUser_Price_CalculationListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  User_id?: number;
}

class FetchUser_Price_CalculationList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUser_Price_CalculationListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await User_Price_CalculationService.fetchUser_Price_CalculationList(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcUser_Price_CalculationList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewUser_Price_Calculation  */

interface AddNewUser_Price_CalculationInput {
  flat: boolean | null;
  airline: string | null;
  // country: string | null;
  airport: string | null;
  cpm: number | null;
  cpc: number | null;
  cpa: number | null;
  advertisment_type: number | null;
  user_id: string | null;
  fetchUser_Price_CalculationInput: FetchUser_Price_CalculationListInput;
}

class AddNewUser_Price_Calculation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_USER_PRICE_CALCULATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_USER_PRICE_CALCULATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_USER_PRICE_CALCULATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewUser_Price_CalculationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUser_Price_CalculationInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await User_Price_CalculationService.addUser_Price_Calculation(
          rest
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUser_Price_CalculationList().action(
            fetchUser_Price_CalculationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION,
          })
        );
      } catch (error) {
        console.log('AddNewUser_Price_Calculation Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt User_Price_Calculation  */

interface EditUser_Price_CalculationInput {
  id: string | null;
  flat: boolean | null;
  airline: string | null;
  country: string | null;
  airport: string | null;
  cpm: number | null;
  cpc: number | null;
  cpa: number | null;
  advertisment_type: number | null;
  user_id: string | null;
  fetchUser_Price_CalculationInput: FetchUser_Price_CalculationListInput;
}

class EditUser_Price_Calculation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_USER_PRICE_CALCULATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_USER_PRICE_CALCULATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_USER_PRICE_CALCULATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditUser_Price_CalculationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUser_Price_CalculationInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await User_Price_CalculationService.editUser_Price_Calculation(
          payload
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUser_Price_CalculationList().action(
            fetchUser_Price_CalculationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View User_Price_Calculation  */

interface ViewUser_Price_CalculationInput {
  id: number;
}

class ViewUser_Price_Calculation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PRICE_CALCULATION_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewUser_Price_CalculationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await User_Price_CalculationService.fetchUser_Price_CalculationDetail(
          {
            id: payload.id,
          }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewUser_Price_Calculation Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
/** Delete User_Price_Calculation  */

interface DeleteUser_Price_CalculationInput {
  id: number;
  fetchUser_Price_CalculationInput: FetchUser_Price_CalculationListInput;
}

class DeleteUser_Price_Calculation implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DELETE_USER_PRICE_CALCULATION,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DELETE_USER_PRICE_CALCULATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DELETE_USER_PRICE_CALCULATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteUser_Price_CalculationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const { fetchUser_Price_CalculationInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await User_Price_CalculationService.deleteUser_Price_Calculation(
          payload.id
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUser_Price_CalculationList().action(
            fetchUser_Price_CalculationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION,
          })
        );
      } catch (error) {
        console.log('ViewUser_Price_Calculation Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchUser_Price_CalculationListAction: (
    payload: FetchUser_Price_CalculationListInput
  ) => new FetchUser_Price_CalculationList().action(payload),
  addNewUser_Price_CalculationAction: (
    payload: AddNewUser_Price_CalculationInput
  ) => new AddNewUser_Price_Calculation().action(payload),
  editUser_Price_CalculationAction: (
    payload: EditUser_Price_CalculationInput
  ) => new EditUser_Price_Calculation().action(payload),
  viewUser_Price_CalculationAction: (
    payload: ViewUser_Price_CalculationInput
  ) => new ViewUser_Price_Calculation().action(payload),
  deleteUser_Price_CalculationAction: (
    payload: DeleteUser_Price_CalculationInput
  ) => new DeleteUser_Price_Calculation().action(payload),
};
