import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetPublisherList } from 'redux/reducers/publisher_reducer/selectors';
import { useGetParaList } from 'redux/reducers/paralist_reducer/selectors';
import {
  clearSelectedPublisher,
  editPublisher,
} from 'redux/reducers/publisher_reducer/actions';
import { Publisher } from 'redux/reducers/publisher_reducer/interfaces';
import Translations from '../translations';

function PublisherDetailDialog() {
  const {
    status,
    publisherSelected,
    publisherSelectedExclusiveList,
  } = useGetPublisherList();

  const { fetch, paralist_status } = useGetParaList();
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Publisher>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (publisherSelected) reset(publisherSelected);
  }, [publisherSelected]);

  const onClose = () => {
    dispatch(clearSelectedPublisher());
  };

  const onSubmit = async (payload: Publisher) => {
    if (!publisherSelected) return;
    dispatch(editPublisher(publisherSelected.id, payload as Publisher));
  };

  const pubTypes = fetch('publisher.type');
  const commissionCalculationTypes = fetch('publisher.commisioncalculation');

  return (
    <Dialog
      open={
        publisherSelected !== undefined &&
        publisherSelectedExclusiveList == undefined
      }
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Publisher / {publisherSelected?.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update the fields and click "Submit" to save your edits.
        </DialogContentText>
        <AppTextField
          variant="standard"
          rules={{ required: true }}
          name="name"
          control={control}
          label="Name"
          fullWidth
        />
        <Box
          marginTop={'10px'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <AppTextField
            variant="standard"
            rules={{ required: true }}
            name="code"
            control={control}
            label="Code"
          />

          <AppSelect
            variant="standard"
            name="type"
            options={
              pubTypes?.map((type) => ({
                id: type.code,
                label: type.descriptionn,
              })) || []
            }
            label={<TranslatedText textMap={Translations.type} />}
            control={control}
            error={'type' in errors}
            helperText={errors?.type?.message}
            rules={{ required: true }}
          />

          <AppSelect
            variant="standard"
            name="needsapproval"
            options={[
              { id: 1, label: 'Needs Approval' },
              { id: 0, label: 'Approved' },
            ]}
            label={<TranslatedText textMap={Translations.status} />}
            control={control}
            error={'needsapproval' in errors}
            helperText={errors?.needsapproval?.message}
            rules={{ required: true }}
          />
        </Box>

        <Box marginTop={'10px'} fontWeight={'bold'}>
          Commission Calculation
        </Box>
        <AppSelect
          variant="standard"
          name="commisioncalculationtype"
          options={
            commissionCalculationTypes?.map((type) => ({
              id: type.code,
              label: type.descriptionn,
            })) || []
          }
          control={control}
          error={'commisioncalculationtype' in errors}
          helperText={errors?.commisioncalculationtype?.message}
          rules={{ required: true }}
        />

        <Box
          marginTop={'10px'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            marginTop={'10px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Chip label="Publisher" />

            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherfixcpv"
              control={control}
              label="CPV"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherpersentage"
              control={control}
              label="%"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="publisherfixamount"
              control={control}
              label="Amount"
              type="number"
            />
          </Box>

          <Box
            marginTop={'10px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Chip label="Platform" />

            <AppTextField
              variant="standard"
              rules={{}}
              name="platformfixcpv"
              control={control}
              label="CPV"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="platformpercentage"
              control={control}
              label="%"
              type="number"
            />
            <AppTextField
              variant="standard"
              rules={{}}
              name="platformfixamount"
              control={control}
              label="Amount"
              type="number"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Discard
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PublisherDetailDialog;
