import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '99%',
    margin: 'auto',
  },
  mapContainer: {
    position: 'relative',
    marginBottom: '1rem',
    height: '500px',
    marginTop: '1rem',
  },
  map: {
    height: '100%',
    width: '100%',
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    },
  },
  formContainer: {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    zIndex: 1,
    width: '25%',
    maxHeight: '400px',
    overflow: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  destinationsContainer: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    zIndex: 1,
    width: '20%',
  },
  destinationCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  destinationCardContentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '.5rem',
    fontSize: '1rem',
  },
}));

export default useStyles;
