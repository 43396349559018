import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import RegisterActions from './store/actions';
import RegisterForm from './components/register_form';
import useStyles from './styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TranslatedText from '../../components/atoms/translated_text';
import Configuration from './configuration';
import Translations from './translations';
import LanguageDropdown from '../../components/app_common/language_dropdown';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

const RegisterPage = (props: any) => {
  console.log('Render RegisterPage');
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(RegisterActions.fetchCountriesAction());
  }, [dispatch]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      >
        <div className={classes.containerBackground} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        className={classes.registrationContainer}
      >
        <div className={classes.paper}>
          <div className={classes.languageButton}>
            <LanguageDropdown />
          </div>
          <img src={Configuration.registerIcon} className={classes.img_logo} />
          <Typography component="h1" variant="h5">
            <TranslatedText textMap={Translations.signup_title} />
          </Typography>
          <RegisterForm />
        </div>
      </Grid>
    </Grid>
  );
};

const Page = withRouter(RegisterPage);
export default withReducer(ReducerKeys.REGISTER_REDUCER, { unmount: true })(
  Page
);

// export default withRouter(RegisterPage);
