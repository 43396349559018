import { Languages } from '../../config';

const Translations = {
  campaign_info_tab: {
    [Languages.English]: 'Campaign Info',
    [Languages.Greek]: 'Πληροφορίες καμπάνιας',
    [Languages.Russian]: 'Информация о кампании',
  },
  destination_tab: {
    [Languages.English]: 'Destination',
    [Languages.Greek]: 'Προορισμός',
    [Languages.Russian]: 'Назначения',
  },
  audience_tab: {
    [Languages.English]: 'Audience',
    [Languages.Greek]: 'Κοινό',
    [Languages.Russian]: 'Аудитория',
  },
  ad_details_tab: {
    [Languages.English]: 'Ad Details',
    [Languages.Greek]: 'Στοιχεία Καταχώρησης',
    [Languages.Russian]: 'Детали объявления',
  },
  preview_tab: {
    [Languages.English]: 'Preview',
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.Russian]: 'предварительный просмотр',
  },
  next: {
    [Languages.English]: 'Next',
    [Languages.Greek]: 'Επόμενο',
    [Languages.Russian]: 'Следующий',
  },
  submit: {
    [Languages.English]: 'Submit',
    [Languages.Greek]: 'υποβάλλουν',
    [Languages.Russian]: 'Представлять на рассмотрение',
  },
  previous: {
    [Languages.English]: 'Previous',
    [Languages.Greek]: 'Προηγούμενος',
    [Languages.Russian]: 'Предыдущий',
  },
  campaign_name_required: {
    [Languages.English]: 'Campaign name is a required field',
    [Languages.Greek]: 'Το όνομα της καμπάνιας είναι ένα απαιτούμενο πεδίο',
    [Languages.Russian]: 'Название кампании является обязательным полем',
  },
  campaign_budget_required: {
    [Languages.English]: 'Budget is a required field',
    [Languages.Greek]: 'Ο προϋπολογισμός είναι ένα απαιτούμενο πεδίο',
    [Languages.Russian]: 'Бюджет является обязательным полем',
  },
  campaign_budget_negative: {
    [Languages.English]: 'Budget should be a positive number',
    [Languages.Greek]: 'Προϋπολογισμός πρέπει να είναι θετικός αριθμός',
    [Languages.Russian]: 'Бюджет должен быть положительным числом',
  },
  campaign_category_required: {
    [Languages.English]: 'Campaign category is a required field',
    [Languages.Greek]: 'Η κατηγορία καμπάνιας είναι υποχρεωτικό πεδίο',
    [Languages.Russian]: 'Категория кампании является обязательным полем',
  },
  campaign_ad_description_required: {
    English: 'Campaign advertisement description is required',
    Greek: 'Απαιτείται περιγραφή της διαφήμισης της καμπάνιας',
    Russian: 'Требуется описание рекламной кампании',
  },
  campaign_ad_landing_page_required: {
    [Languages.English]: 'Campaign advertisment landing page is required',
    [Languages.Greek]: 'Απαιτείται σελίδα προορισμού διαφήμισης καμπάνιας',
    [Languages.Russian]: 'Требуется целевая страница рекламы кампании',
  },
  campaign_ad_image_video_required: {
    [Languages.English]:
      'Campaign advertisement requires at least 1 image or video',
    [Languages.Greek]:
      'Η διαφήμιση καμπάνιας απαιτεί τουλάχιστον 1 εικόνα ή βίντεο',
    [Languages.Russian]:
      'Для рекламы кампании требуется как минимум 1 изображение или видео',
  },
  campaign_destination_required: {
    [Languages.English]: 'Require atleast publisher or country or city',
    [Languages.Greek]: 'Απαιτείται τουλάχιστον εκδότης ή χώρα ή πόλη',
    [Languages.Russian]: 'Требуется хотя бы издатель или страна или город',
  },
  image_allowed: {
    [Languages.English]: 'Allowed Sizes: ',
    [Languages.Greek]: 'Επιτρεπόμενα μεγέθη: ',
    [Languages.Russian]: 'Допустимые размеры: ',
  },
  remove: {
    [Languages.English]: 'Remove',
    [Languages.Greek]: 'Αφαιρώ',
    [Languages.Russian]: 'Удалять',
  },
  formats_allowed: {
    [Languages.English]: 'Allowed formats: ',
    [Languages.Greek]: 'Επιτρέπονται μορφές: ',
    [Languages.Russian]: 'Допустимые форматы: ',
  },
  add_description: {
    [Languages.English]: 'Click Here or Drag and Drop to upload your files',
    [Languages.Greek]:
      'Κάντε κλικ εδώ ή Μεταφορά και απόθεση για να ανεβάσετε τα αρχεία σας',
    [Languages.Russian]: 'Нажмите здесь или перетащите, чтобы загрузить файлы',
  },
  file_too_large_error: {
    [Languages.English]: 'File too big. Maximum file size should be 5MB.',
    [Languages.Greek]:
      'Πολύ μεγάλο αρχείο. Μέγιστο μέγεθος αρχείου θα πρέπει να είναι 5 MB.',
    [Languages.Russian]:
      'Файл слишком большой. Максимальный размер файла должен быть 5МБАМИ.',
  },
  file_invalid_type: {
    [Languages.English]:
      'Invalid file type. Allowed file formats are PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV.',
    [Languages.Greek]:
      'Μη έγκυρος τύπος αρχείου. Επιτρέπονται μορφές αρχείων είναι PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV.',
    [Languages.Russian]:
      'Неверный тип файла. Допустимые форматы файлов PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV.',
  },
  file_invalid_dimensions: {
    [Languages.English]:
      'File has invalid dimensions. Allowed dimensions are 300x250, 320x50, 728x90.',
    [Languages.Greek]:
      'Αρχείο έχει μη έγκυρο διαστάσεις. Επιτρέπονται οι διαστάσεις είναι 300x250, 320x50, 728x90.',
    [Languages.Russian]:
      'Файл содержит недопустимые размеры. Допустимые размеры 300x250, 320x50, 728x90.',
  },
  invalid_file: {
    [Languages.English]:
      'Invalid file uploaded. Maximum file size should be 5MB. Allowed file formats are PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV. Allowed dimensions are 300x250, 320x50, 728x90.',
    [Languages.Greek]:
      'Μη έγκυρο αρχείο. Μέγιστο μέγεθος αρχείου θα πρέπει να είναι 5 MB. Επιτρέπονται μορφές αρχείων είναι PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV. Επιτρέπονται οι διαστάσεις είναι 300x250, 320x50, 728x90.',
    [Languages.Russian]:
      'Недопустимый файл загружен. Максимальный размер файла должен быть 5МБАМИ. Допустимые форматы файлов PNG, BMP, JPG, GIF, AVI, MPEG4, MP4, MOV, FLV, WMV. Допустимые размеры 300x250, 320x50, 728x90.',
  },
  advertisment_description_control: {
    [Languages.English]: 'Advertisment Description',
    [Languages.Greek]: 'Διαφήμιση Περιγραφή',
    [Languages.Russian]: 'Реклама Описание',
  },
  landing_page_control: {
    [Languages.English]: 'Landing page',
    [Languages.Greek]: 'Σελίδα προορισμού',
    [Languages.Russian]: 'Целевая страница',
  },
  store_preview: {
    [Languages.English]: 'Store & Preview',
    [Languages.Greek]: 'Store & Προεπισκόπηση',
    [Languages.Russian]: 'Магазин & Preview',
  },
  open_url: {
    [Languages.English]: 'Open URL',
    [Languages.Greek]: 'Άνοιγμα URL',
    [Languages.Russian]: 'Открыть URL',
  },
  ad_detail: {
    [Languages.English]: 'Ad Details',
    [Languages.Greek]: 'Στοιχεία Καταχώρησης',
    [Languages.Russian]: 'Детали объявления',
  },
  image_video: {
    [Languages.English]: 'Image/Video',
    [Languages.Greek]: 'Εικόνα / Βίντεο',
    [Languages.Russian]: 'Изображение / Видео',
  },
  smartphone_device: {
    [Languages.English]: 'Smartphone',
    [Languages.Greek]: 'smartphone',
    [Languages.Russian]: 'смартфон',
  },
  table_device: {
    [Languages.English]: 'Tablet',
    [Languages.Greek]: 'Δισκίο',
    [Languages.Russian]: 'таблетка',
  },
  desktop_device: {
    [Languages.English]: 'Desktop',
    [Languages.Greek]: 'Επιφάνεια εργασίας',
    [Languages.Russian]: 'рабочий стол',
  },
  male_gender: {
    [Languages.English]: 'Male',
    [Languages.Greek]: 'Αρσενικός',
    [Languages.Russian]: 'Мужчина',
  },
  female_gender: {
    [Languages.English]: 'Female',
    [Languages.Greek]: 'Θηλυκός',
    [Languages.Russian]: 'женский',
  },
  me_app: {
    [Languages.English]: 'App',
    [Languages.Greek]: 'App',
    [Languages.Russian]: 'Приложение',
  },
  me_web: {
    [Languages.English]: 'Web',
    [Languages.Greek]: 'Ιστός',
    [Languages.Russian]: 'Web',
  },
  os_ios: {
    [Languages.English]: 'IOS',
    [Languages.Greek]: 'ΙΟΣ',
    [Languages.Russian]: 'IOS',
  },
  os_android: {
    [Languages.English]: 'Android',
    [Languages.Greek]: 'Android',
    [Languages.Russian]: 'Android',
  },
  gender: {
    [Languages.English]: 'Gender',
    [Languages.Greek]: 'Γένος',
    [Languages.Russian]: 'Пол',
  },
  age_group: {
    [Languages.English]: 'Age Group',
    [Languages.Greek]: 'Ηλικιακή ομάδα',
    [Languages.Russian]: 'Возрастная группа',
  },
  device: {
    [Languages.English]: 'Device',
    [Languages.Greek]: 'Συσκευή',
    [Languages.Russian]: 'Устройство',
  },
  mobile_environment: {
    [Languages.English]: 'Mobile Environment',
    [Languages.Greek]: 'κινητό Περιβάλλον',
    [Languages.Russian]: 'Мобильная среда',
  },
  operating_system: {
    [Languages.English]: 'Operating System',
    [Languages.Greek]: 'Λειτουργικό σύστημα',
    [Languages.Russian]: 'Операционная система',
  },
  campaign_name_control: {
    [Languages.English]: 'Campaign Name',
    [Languages.Greek]: 'Όνομα καμπάνιας',
    [Languages.Russian]: 'Название кампании',
  },
  customer_name_control: {
    [Languages.English]: 'Customer Name',
    [Languages.Greek]: 'Όνομα πελάτη',
    [Languages.Russian]: 'имя покупателя',
  },
  new_customer: {
    [Languages.English]: 'New customer',
    [Languages.Greek]: 'Νέος πελάτης',
    [Languages.Russian]: 'Новый покупатель',
  },
  budget_control: {
    [Languages.English]: 'Total budget',
    [Languages.Greek]: 'Συνολικός προϋπολογισμός',
    [Languages.Russian]: 'Общий бюджет',
  },
  category_control: {
    [Languages.English]: 'Category',
    [Languages.Greek]: 'Κατηγορία',
    [Languages.Russian]: 'Категория',
  },
  start_date_control: {
    [Languages.English]: 'Start date',
    [Languages.Greek]: 'Ημερομηνία έναρξης',
    [Languages.Russian]: 'Дата начала',
  },
  end_date_control: {
    [Languages.English]: 'End date',
    [Languages.Greek]: 'Ημερομηνία λήξης',
    [Languages.Russian]: 'Дата окончания',
  },
  inflight: {
    [Languages.English]: 'Inflight',
    [Languages.Greek]: 'κατά την πτήση',
    [Languages.Russian]: 'в полете',
  },
  websites: {
    [Languages.English]: 'Websites',
    [Languages.Greek]: 'ιστοσελίδες',
    [Languages.Russian]: 'веб-сайты',
  },
  awareness_description: {
    [Languages.English]:
      'This objective is designed to generate more interest in your product or service, and highlight to the audiences what makes your product or service valuable and worth it.',
    [Languages.Greek]:
      'Αυτός ο στόχος έχει σχεδιαστεί για να δημιουργήσει μεγαλύτερο ενδιαφέρον για το προϊόν ή την υπηρεσία σας και να επισημάνει στο κοινό τι κάνει το προϊόν ή την υπηρεσία σας πολύτιμο και αξίζει τον κόπο.',
    [Languages.Russian]:
      'Эта цель призвана вызвать больший интерес к вашему продукту или услуге и привлечь внимание аудитории к тому, что делает ваш продукт или услугу ценными и стоящими.',
  },
  awareness: {
    [Languages.English]: 'Awareness',
    [Languages.Greek]: 'Επίγνωση',
    [Languages.Russian]: 'Осведомленность',
  },
  awareness_note1: {
    [Languages.English]: 'Increase Brand Awareness',
    [Languages.Greek]: 'Αύξηση επωνυμίας',
    [Languages.Russian]: 'Повышение узнаваемости бренда',
  },
  awareness_note2: {
    [Languages.English]: 'Brand Awareness',
    [Languages.Greek]: 'brand Awareness',
    [Languages.Russian]: 'Узнаваемость бренда',
  },
  awareness_note3: {
    [Languages.English]: 'Local Awareness',
    [Languages.Greek]: 'Οι τοπικές συνειδητοποίηση',
    [Languages.Russian]: 'Местное Awareness',
  },
  awareness_note4: {
    [Languages.English]: 'Reach',
    [Languages.Greek]: 'Φθάνω',
    [Languages.Russian]: 'Достигать',
  },
  consideration: {
    [Languages.English]: 'Consideration',
    [Languages.Greek]: 'Θεώρηση',
    [Languages.Russian]: 'Рассмотрение',
  },
  consideration_description: {
    [Languages.English]:
      'This objective brings your business to the attention of the audience, aiming to urge them into taking some action, such as seeking more information, or visiting your store or website.',
    [Languages.Greek]:
      'Αυτός ο στόχος φέρνει την επιχείρησή σας στην προσοχή του κοινού, με στόχο να τους παροτρύνει να προβούν σε κάποια ενέργεια, όπως αναζήτηση περισσότερων πληροφοριών ή επίσκεψη στο κατάστημα ή τον ιστότοπό σας.',
    [Languages.Russian]:
      'Эта цель привлекает внимание аудитории к вашему бизнесу, побуждая их к действию, например поиску дополнительной информации или посещению вашего магазина или веб-сайта.',
  },
  consideration_note1: {
    [Languages.English]: 'Video Views',
    [Languages.Greek]: 'Βίντεο Προβολές',
    [Languages.Russian]: 'видео просмотров',
  },
  consideration_note2: {
    [Languages.English]: 'Engagement',
    [Languages.Greek]: 'Σύμπλεξη',
    [Languages.Russian]: 'Обручение',
  },
  consideration_note3: {
    [Languages.English]: 'Clicks',
    [Languages.Greek]: 'κλικ',
    [Languages.Russian]: 'щелчки',
  },
  consideration_note4: {
    [Languages.English]: 'Ads',
    [Languages.Greek]: 'Διαφημίσεις',
    [Languages.Russian]: 'Объявления',
  },
  basic_info: {
    [Languages.English]: 'Basic Info',
    [Languages.Greek]: 'Βασικές πληροφορίες',
    [Languages.Russian]: 'Базовая информация',
  },
  budget_schedule: {
    [Languages.English]: 'Budget & Schedule',
    [Languages.Greek]: 'Προϋπολογισμός και Πρόγραμμα',
    [Languages.Russian]: 'Бюджет и расписание',
  },
  purpose: {
    [Languages.English]: 'Objective',
    [Languages.Greek]: 'Σκοπός',
    [Languages.Russian]: 'Цель',
  },
  display: {
    [Languages.English]: 'Display',
    [Languages.Greek]: 'Απεικόνιση',
    [Languages.Russian]: 'Отображать',
  },
  destinations_form_publisher: {
    [Languages.English]: 'Publisher',
    [Languages.Greek]: 'Εκδότης',
    [Languages.Russian]: 'издатель',
  },
  destinations_form_destination_country: {
    [Languages.English]: 'Destination Country',
    [Languages.Greek]: 'Χώρα Προορισμού',
    [Languages.Russian]: 'Страна назначения',
  },
  destinations_form_destination_city: {
    [Languages.English]: 'Destination City',
    [Languages.Greek]: 'Προορισμός Πόλη',
    [Languages.Russian]: 'Направление Город',
  },
  destinations_card_title: {
    [Languages.English]: 'Destinations',
    [Languages.Greek]: 'Προορισμοί',
    [Languages.Russian]: 'Направления',
  },
  destinations_card_days: {
    [Languages.English]: 'Days:',
    [Languages.Greek]: 'Μέρες:',
    [Languages.Russian]: 'Дни:',
  },
  destinations_card_passengers: {
    [Languages.English]: 'Passengers:',
    [Languages.Greek]: 'επιβάτες:',
    [Languages.Russian]: 'Пассажиры:',
  },
  destinations_card_number_of_flights: {
    [Languages.English]: 'No of flights:',
    [Languages.Greek]: 'Δεν πτήσεων:',
    [Languages.Russian]: 'Нет рейсов:',
  },
  destinations_card_publishers: {
    [Languages.English]: 'Publishers:',
    [Languages.Greek]: 'Εκδότες:',
    [Languages.Russian]: 'Партнеры:',
  },
  destinations_card_dest_city: {
    [Languages.English]: 'Dest. city:',
    [Languages.Greek]: 'Dest. πόλη:',
    [Languages.Russian]: 'Dest. город:',
  },
  destinations_table_code: {
    [Languages.English]: 'Code',
    [Languages.Greek]: 'Κώδικας',
    [Languages.Russian]: 'Код',
  },
  destinations_table_flight_date: {
    [Languages.English]: 'Flight Date',
    [Languages.Greek]: 'πτήση Ημερομηνία',
    [Languages.Russian]: 'Полет Дата',
  },
  destinations_table_airline: {
    [Languages.English]: 'Airline',
    [Languages.Greek]: 'Αερογραμμή',
    [Languages.Russian]: 'авиакомпания',
  },
  destinations_table_departure_airport: {
    [Languages.English]: 'Departure Airport',
    [Languages.Greek]: 'Αεροδρόμιο αναχώρησης',
    [Languages.Russian]: 'Аэропорт вылета',
  },
  destinations_table_arrival_airport: {
    [Languages.English]: 'Arrival Airport',
    [Languages.Greek]: 'άφιξη Αεροδρόμιο',
    [Languages.Russian]: 'Прибытие в аэропорт',
  },
  destinations_table_country: {
    [Languages.English]: 'Country',
    [Languages.Greek]: 'Χώρα',
    [Languages.Russian]: 'Страна',
  },
  destinations_table_estimated_passengers: {
    [Languages.English]: 'Estimated Passengers',
    [Languages.Greek]: 'εκτιμώμενη επιβάτες',
    [Languages.Russian]: 'Оценочные пассажиров',
  },
  landing_page: {
    [Languages.English]: 'Landing page',
    [Languages.Greek]: 'Σελίδα προορισμού',
    [Languages.Russian]: 'целевая страница',
  },
  description: {
    [Languages.English]: 'Campaign description',
    [Languages.Greek]: 'περιγραφή Καμπάνια',
    [Languages.Russian]: 'описание кампании',
  },
  publishers_control: {
    [Languages.English]: 'Publishers',
    [Languages.Greek]: 'Εκδότες',
    [Languages.Russian]: 'Издатели',
  },
  gender_control: {
    [Languages.English]: 'Gender',
    [Languages.Greek]: 'Γένος',
    [Languages.Russian]: 'Пол',
  },
  age_group_control: {
    [Languages.English]: 'Age Group',
    [Languages.Greek]: 'Ηλικιακή ομάδα',
    [Languages.Russian]: 'Возрастная группа',
  },
  device_control: {
    [Languages.English]: 'Device',
    [Languages.Greek]: 'Συσκευή',
    [Languages.Russian]: 'Устройство',
  },
  mobile_environment_control: {
    [Languages.English]: 'Mobile Environment',
    [Languages.Greek]: 'κινητό Περιβάλλον',
    [Languages.Russian]: 'Мобильная среда',
  },
  operating_system_control: {
    [Languages.English]: 'Operating System',
    [Languages.Greek]: 'Λειτουργικό σύστημα',
    [Languages.Russian]: 'Операционная система',
  },
  purpose_control: {
    [Languages.English]: 'Campaign purpose',
    [Languages.Greek]: 'σκοπός της εκστρατείας',
    [Languages.Russian]: 'цель кампании',
  },
  campaign_info: {
    [Languages.English]: 'Campaign Info',
    [Languages.Greek]: 'Πληροφορίες καμπάνιας',
    [Languages.Russian]: 'Информация о кампании',
  },
  destinations: {
    [Languages.English]: 'Destinations',
    [Languages.Greek]: 'Προορισμοί',
    [Languages.Russian]: 'Направления',
  },
  audience: {
    [Languages.English]: 'Audience',
    [Languages.Greek]: 'Κοινό',
    [Languages.Russian]: 'Аудитория',
  },
  ad_details: {
    [Languages.English]: 'Ad Details',
    [Languages.Greek]: 'Στοιχεία Καταχώρησης',
    [Languages.Russian]: 'Детали объявления',
  },
  confirmation_modal_title: {
    [Languages.English]: 'Campaign creation',
    [Languages.Greek]: 'δημιουργία καμπάνιας',
    [Languages.Russian]: 'создание кампании',
  },
  confirmation_modal_body: {
    [Languages.English]: 'Are you sure you want to submit campaign?',
    [Languages.Greek]: 'Είστε βέβαιοι ότι θέλετε να υποβάλετε καμπάνια;',
    [Languages.Russian]: 'Вы уверены, что хотите отправить кампанию?',
  },
  edit_confirmation_modal_title: {
    [Languages.English]: 'Campaign modification',
    [Languages.Greek]: 'τροποποίηση Καμπάνια',
    [Languages.Russian]: 'изменение кампании',
  },
  edit_confirmation_modal_body: {
    [Languages.English]: 'Are you sure you want to edit campaign?',
    [Languages.Greek]:
      'Είστε σίγουροι ότι θέλετε να επεξεργαστείτε την καμπάνια;',
    [Languages.Russian]: 'Вы уверены, что хотите редактировать кампании?',
  },
  confirmation_modal_yes: {
    [Languages.English]: 'Yes',
    [Languages.Greek]: 'Ναί',
    [Languages.Russian]: 'да',
  },
  confirmation_modal_no: {
    [Languages.English]: 'No',
    [Languages.Greek]: 'Οχι',
    [Languages.Russian]: 'Нет',
  },
  transport: {
    [Languages.English]: 'Transport',
    [Languages.Greek]: 'Μεταφορά',
    [Languages.Russian]: 'Транспорт',
  },
  transport_stations: {
    [Languages.English]: 'Transport Stations',
    [Languages.Greek]: 'Σταθμοί Μεταφορών',
    [Languages.Russian]: 'Транспортные Станции',
  },
  display_note_lorem: {
    [Languages.English]:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    [Languages.Greek]:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    [Languages.Russian]:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  campaign_gender_required: {
    [Languages.English]: 'Select atleast 1 gender group',
    [Languages.Greek]: 'Επιλέξτε τουλάχιστον 1 ομάδα φύλου',
    [Languages.Russian]: 'Выберите хотя бы одну гендерную группу',
  },
  campaign_age_group_required: {
    [Languages.English]: 'Select atleast 1 age group',
    [Languages.Greek]: 'Επιλέξτε τουλάχιστον 1 ηλικιακή ομάδα',
    [Languages.Russian]: 'Выберите хотя бы 1 возрастную группу',
  },
  campaign_device_required: {
    [Languages.English]: 'Select atleast 1 device type',
    [Languages.Greek]: 'Επιλέξτε τουλάχιστον 1 τύπο συσκευής',
    [Languages.Russian]: 'Выберите хотя бы 1 тип устройства',
  },
  campaign_mobile_environment_required: {
    [Languages.English]: 'Select atleast 1 mobile environment type',
    [Languages.Greek]: 'Επιλέξτε τουλάχιστον 1 τύπο περιβάλλοντος κινητού',
    [Languages.Russian]: 'Выберите хотя бы 1 тип мобильной среды',
  },
  campaign_operating_system_required: {
    [Languages.English]: 'Select atleast 1 operating system type',
    [Languages.Greek]: 'Επιλέξτε τουλάχιστον 1 τύπο λειτουργικού συστήματος',
    [Languages.Russian]: 'Выберите хотя бы 1 тип операционной системы',
  },
  campaign_details: {
    [Languages.English]: 'Campaign Details',
    [Languages.Greek]: 'Λεπτομέρειες Καμπάνιας',
    [Languages.Russian]: 'Детали Кампании',
  },
  advertisement_details: {
    [Languages.English]: 'Advertisement Details',
    [Languages.Greek]: 'Στοιχεία Διαφήμισης',
    [Languages.Russian]: 'Детали Рекламы',
  },
  upload_files: {
    [Languages.English]: 'Upload Files',
    [Languages.Greek]: 'Μεταφόρτωση Αρχείων',
    [Languages.Russian]: 'Загрузить Файлы',
  },
  advanced_options: {
    [Languages.English]: 'Advanced Options',
    [Languages.Greek]: 'Προχωρημένες Επιλογές',
    [Languages.Russian]: 'Расширенные Опции',
  },
  transport_lines_form_lines: {
    [Languages.English]: 'Bus Routes',
    [Languages.Greek]: 'Διαδρομές Λεωφορείων',
    [Languages.Russian]: 'Автобусные маршруты',
  },
  display_note_inflight: {
    [Languages.English]:
      'Set campaign target display to be inflight for plane flights',
    [Languages.Greek]:
      'Ορίστε την προβολή στόχου καμπάνιας να είναι εν πτήσει για πτήσεις αεροπλάνων',
    [Languages.Russian]:
      'Настройте целевое отображение кампании для полетов на самолете',
  },
  display_note_websites: {
    [Languages.English]:
      'Set campaign target display to be for online websites',
    [Languages.Greek]:
      'Ορίστε την προβολή στόχου καμπάνιας για διαδικτυακούς ιστότοπους',
    [Languages.Russian]:
      'Установите целевое отображение кампании для веб-сайтов в Интернете',
  },
  display_note_transport: {
    [Languages.English]:
      'Set campaign target display to be transportation like busses & trains',
    [Languages.Greek]:
      'Ορίστε την προβολή στόχου καμπάνιας ως μεταφορικά μέσα, όπως λεωφορεία και τρένα',
    [Languages.Russian]:
      'Установите целевое отображение кампании для транспорта, такого как автобусы и поезда',
  },
};

export default Translations;
