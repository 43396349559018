import { Languages } from '../../config';

const Translations = {
  placeholder_search: {
    [Languages.English]: 'Search Application',
    [Languages.Greek]: 'Αναζήτηση εφαρμογών',
    [Languages.Russian]: 'Поиск приложений',
  },
  button_search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  add_new_button: {
    [Languages.English]: 'Add New Application',
    [Languages.Greek]: 'Προσθήκη νέας εφαρμογής',
    [Languages.Russian]: 'Добавить приложение',
  },
  name_column: {
    [Languages.English]: 'Name',
    [Languages.Greek]: 'Ονομα',
    [Languages.Russian]: 'Имя',
  },
  description_column: {
    [Languages.English]: 'Description',
    [Languages.Greek]: 'Περιγραφή',
    [Languages.Russian]: 'Описание',
  },
  add_button: {
    [Languages.English]: 'Add Application',
    [Languages.Greek]: 'Προσθήκη Εφαρμογή',
    [Languages.Russian]: 'Добавить приложение',
  },
  cancel_button: {
    [Languages.English]: 'Cancel',
    [Languages.Greek]: 'Ματαίωση',
    [Languages.Russian]: 'Отмена',
  },
};

export default Translations;
