import { Languages } from '../../config';

const Translations = {
  search_placeholder: {
    [Languages.English]: 'Search Application',
    [Languages.Greek]: 'Αναζήτηση εφαρμογών',
    [Languages.Russian]: 'Поиск приложений',
  },
  search_button: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  reporting_adv: {
    [Languages.English]: 'Adv Reporting',
    [Languages.Greek]: 'Adv Πληροφόρησης',
    [Languages.Russian]: 'Adv отчетность',
  },
  reporting_pub: {
    [Languages.English]: 'Pub Reporting',
    [Languages.Greek]: 'Αναφορά Pub',
    [Languages.Russian]: 'Pub отчетность',
  },
  wallet: {
    [Languages.English]: 'Wallet',
    [Languages.Greek]: 'Πορτοφόλι',
    [Languages.Russian]: 'Кошелек',
  },
};

export default Translations;
