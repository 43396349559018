import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface AirlinesListElement {
  IataId: string;
  Name: string;
  code: string;
  country: number;
  type: number;
  publisherid: number;
}

interface AirlinesListData {
  paging: AirlinesListPaging;
  items: AirlinesListElement[];
}

interface AirlinesListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface AirlinesistOutput {
  status: StateStatus;
  data: AirlinesListData | null;
}

export const useGetAirlinesList = (): AirlinesistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AIRLINES_REDUCER].airlinesList
  );
  return data;
};

/* selector: useGetAirlinesDetails */
interface AirlinesDetailsData {
  iataid: string;
  name: string;
  code: string;
  country: number;
  type: number;
  publisherid: number;
}

interface AirlinesDetailsOutput {
  status: StateStatus;
  data: AirlinesDetailsData | null;
}

export const useGetAirlinesDetails = (): AirlinesDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AIRLINES_REDUCER].viewAirlines
  );
  return data;
};
