import { IAction, IActionMethods, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UserRegister from 'services/api/user';
import * as RegisterService from 'services/api/register';
import { Dispatch } from 'redux';
import { showToastAction } from 'components/atoms/toast_message';
import { LanguagesEnum } from 'config/languages';
import NavigationConfig from 'navigation/config';

/** Fetch SchemeTypes  */

class FetchSchemeTypes implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_OSS_SCHEMES,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_OSS_SCHEMES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_OSS_SCHEMES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await RegisterService.getSchemeTypes();
        dispatch(this.onSuccess(response.data));
        // if (response.message) {
        //   showToastAction(
        //     dispatch,
        //     {
        //       [LanguagesEnum.English]: response.message,
        //       [LanguagesEnum.Greek]: response.message,
        //     },
        //     'success'
        //   );
        // }
      } catch (error) {
        console.log('FetchSchemeTypes Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        // showToastAction(
        //   dispatch,
        //   {
        //     [LanguagesEnum.English]: error.message,
        //     [LanguagesEnum.Greek]: error.message,
        //   },
        //   'error'
        // );
      }
    };
  }
}

/** Fetch Countries */
class FetchCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.getCountries();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchCountries Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Register User Action */

interface IRegisterInput {
  history: any;
  data: any;
}
class RegisterUser implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IRegisterInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, history } = input;
        dispatch(this.onPending());
        let response = await RegisterService.registerUser(data);
        dispatch(this.onSuccess());
        history.push(NavigationConfig.loginPage().path);
      } catch (error) {
        console.log('RegisterUser Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchSchemeTypesAction: () => new FetchSchemeTypes().action(),
  fetchCountriesAction: () => new FetchCountries().action(),
  registerUserAction: (data: any, history: any) =>
    new RegisterUser().action({ data, history }),
};
