import { makeStyles } from '@material-ui/core/styles';
import { CARD_BACKGROUND } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
    height: '100%',
  },
  tabViewContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: CARD_BACKGROUND(theme),
    boxShadow: 'none',
    borderRadius: '.5rem',
    height: '100%',
    width: '100%',
    padding: '.75rem 0 1rem .5rem',
  },
  tabActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '.5rem',
    width: '80%',
    margin: 'auto',
  },
  tabAction: {
    marginLeft: '.5rem',
  },
  tabContentContainer: {
    overflowY: 'auto',
    paddingRight: '.5rem',
    height: '100%',
    width: '100%',
  },
  stepper: {
    background: CARD_BACKGROUND(theme),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    maxHeight: '100%',
    // overflow: 'auto',
  },
  barIcon: {
    color: 'rgba(0, 71, 233, 0.9)',
  },
  chip: {
    color: theme.palette.secondary.main,
    background: '#0047E9',
    padding: '8px 16px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '6px',
  },
  avatar: {
    height: '165px',
    width: '165px',
    marginBottom: '12px',
  },
}));

export default useStyles;
