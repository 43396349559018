import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_CAMPAIGNS_STATS: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetCampaignsStats'
);
export const SET_COUNTRY_CLICK_VIEW: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetCountryClickView'
);
export const SET_TOTAL_IMPRESSION_CLICK_BY_CAMPAIGN: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTotalImpressClickByCampaign'
);
export const SET_TOP_5_CAMPAIGN: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTop5Campaign'
);

export const SET_TOTAL_VIEW_BY_ARR_COUNTRY: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTotalViewByArrCountry'
);

export const SET_TOTAL_CLICK_BY_ARR_COUNTRY: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTotalClickByArrCountry'
);
export const SET_DEVICE_CLICK_VIEW: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetDeviceClickView'
);

export const SET_TOTAL_COUNTRY_VIEW: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTotalCountryView'
);
export const SET_TOTAL_COUNTRY_CLICK: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTotalCountryClick'
);
export const SET_USER_LIST: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetUserList'
);
export const SET_STATION_CLICK_VIEW: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetStationClickView'
);
export const SET_TRANSPORTS_PASSENGER_STATS_DAILY: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTransportsPassengerStatsDaily'
);
export const SET_TOP_10_PASSENGER_STATS_BY_STATION: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTop10PassengerStatsByStation'
);
export const SET_TOP_10_PASSENGER_STATS_BY_TRANSPORT: string = typeCreatorV2(
  'AdvDashboardReducer',
  'SetTop10PassengerStatsByTransport'
);
