import Translations from '../../translations';

export enum CampaignInfoSteps {
  // BASIC_INFO = 0,
  // BUDGET_SCHEDULE = 1,
  // PURPOSE = 2,
  // DISPLAY = 3,
  CAMPAIGN_INFO = 0,
}

export const LayoutSteps = [
  // {
  //   id: CampaignInfoSteps.BASIC_INFO,
  //   label: Translations.basic_info,
  // },
  // {
  //   id: CampaignInfoSteps.BUDGET_SCHEDULE,
  //   label: Translations.budget_schedule,
  // },
  // {
  //   id: CampaignInfoSteps.PURPOSE,
  //   label: Translations.purpose,
  // },
  // {
  //   id: CampaignInfoSteps.DISPLAY,
  //   label: Translations.display,
  // },
  {
    id: CampaignInfoSteps.CAMPAIGN_INFO,
    label: Translations.campaign_info,
  },
];
