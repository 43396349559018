import { Button } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import NavigationConfig from 'navigation/config';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Translations from './translations';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import Swal from 'sweetalert2';
import AdvertisementsTypeActions from 'redux/reducers/advertisementstype_reducer/actions';
import ImageShow from 'components/app_common/image_show';
import { Environment } from 'config';

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = (e: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE,
        params: {
          data: props?.dataid,
        },
      })
    );
  };
  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          AdvertisementsTypeActions.deleteAdvertisementsTypeAction({
            id: props?.dataid?.ID,
            fetchAdvertisementsTypeInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
      }
    });
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleEdit}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
        }}
      >
        Edit
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        style={{
          backgroundColor: 'red',
          marginLeft: 15,
          textTransform: 'capitalize',
        }}
        color="primary"
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'ID',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText textMap={Translations.name} />,
    id: 'Name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Description',
    id: 'Description',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Contentype',
    id: 'Contentype',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Size (Width x Height)',
    id: 'size',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {data[rowIndex]?.Width}x{data[rowIndex]?.Height}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Imageurl',
    id: 'imageurl',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        <ImageShow
          picture={`${Environment.API_BASE_URL}${cellValue}`}
          customStyle={{
            height: '100px',
          }}
          alt={cellValue}
        />
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export enum EContentType {
  video = 'video',
  image = 'image',
  survey = 'survey',
  track = 'track',
  billboard = 'billboard',
}
