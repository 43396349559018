import { combineReducers } from 'redux';
import AuthReducer from './gl_auth_reducer/reducer';
import TranslationReducer from './gl_translations_reducer/reducer';
import NavigationReducer from './gl_navigation_reducer/reducer';
import ThemeReducer from './gl_theme_reducer/reducer';
import ModalReducer from './gl_modal_reducer/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// ASYNC REDUCERS
import DashboardBuilderReducer from 'samples/dashboardBuilder_page/store/reducer';
import RegisterReducer from 'scenes/register_page/store/reducer';
import UserProfileReducer from 'scenes/profile_page/store/reducer';
import SecurityReducer from 'scenes/security_page/store/reducer';
import ResetPasswordReducer from 'scenes/reset_password/store/reducer';
import ForgotPasswordReducer from 'scenes/forgot_password/store/reducer';
import CampaignsReducer from './campaigns_reducer/reducer';
import WalletReducer from './wallet_reducer/reducers';
import AdvDashboardReducer from './adv_dashboard_reducer/reducers';
import CampaignReducer from './campaign_reducer/reducer';
import PubDashboardReducer from './pub_dashboard_reducer/reducers';
import CampaignAdvertisementReducer from './advertiser_reducer/reducers';
import ReportingReducer from './reporting_reducer/reducers';
import ApplicationReducer from './application_reducer/reducers';
import ApplicationDetailReducer from './application_detail_reducer/reducers';
import IFEAnalyticsReducer from './ife_analytics_reducer/reducers';
import AdministratorReducer from './administrator_reducer/reducers';
import FlightReducer from './flights_reducer/reducer';
import TransportsReducer from './transports_reducer/reducers';
import TransportReducer from './transport_reducer/reducers';
import PublisherReducer from './publisher_reducer/reducers';
import PublisherExclusiveReducer from './publishers_users_whitelist_reducer/reducer';
import ParalistReducer from './paralist_reducer/reducers';
import DSPReducer from './dsp_reducer/reducer';
import AdvertiserAdminReducer from './users_advertisers_administration_reducer/reducer';
import UserPriceCalculationReducer from './user_price_calculation_reducer/reducer';

import CountryRateReducer from './countriesrate_reducer/reducer';
import CountryReducer from './countries_reducer/reducer';
import AdvertisementstypeReducer from './advertisementstype_reducer/reducer';
import AirlinesReducer from './airlines_reducer/reducer';
import AirportsReducer from './airports_reducer/reducer';

import { ReducerKeys } from '../config';
import _ from 'lodash';
import UsersReducer from './users_reducer/reducer';

const authPersistConfig = {
  key: ReducerKeys.AUTH_REDUCER,
  storage: storage,
  blacklist: ['status'],
};

const translationPersistConfig = {
  key: ReducerKeys.TRANSL_REDUCER,
  storage: storage,
};

const navigationPersistConfig = {
  key: ReducerKeys.NAVIGATION_REDUCER,
  storage: storage,
};

const themePersistConfig = {
  key: ReducerKeys.THEME_REDUCER,
  storage: storage,
};
const reportPersistConfig = {
  key: ReducerKeys.REPORTING_REDUCER,
  storage: storage,
};

const dashboardBuilderPersistConfig = {
  key: ReducerKeys.DASHBOARD_BUILDER_REDUCER,
  storage: storage,
  blacklist: ['selectedElement'],
};

export const asyncReducers = {
  [ReducerKeys.DASHBOARD_BUILDER_REDUCER]: persistReducer(
    dashboardBuilderPersistConfig,
    DashboardBuilderReducer
  ),
  [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
  [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  [ReducerKeys.CHANGE_PASSWORD_REDUCER]: SecurityReducer,
  [ReducerKeys.RESET_PASSWORD_REDUCER]: ResetPasswordReducer,
  [ReducerKeys.FORGOT_PASSWORD_REDUCER]: ForgotPasswordReducer,
};

export const staticReducers = {
  // global
  [ReducerKeys.AUTH_REDUCER]: AuthReducer,
  [ReducerKeys.TRANSL_REDUCER]: persistReducer(
    translationPersistConfig,
    TranslationReducer
  ),
  [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
    navigationPersistConfig,
    NavigationReducer
  ),
  [ReducerKeys.THEME_REDUCER]: persistReducer(themePersistConfig, ThemeReducer),
  [ReducerKeys.MODAL_REDUCER]: ModalReducer,
  [ReducerKeys.CAMPAIGNS_REDUCER]: CampaignsReducer,
  [ReducerKeys.WALLET_REDUCER]: WalletReducer,
  [ReducerKeys.ADV_DASHBOARD_REDUCER]: AdvDashboardReducer,
  [ReducerKeys.CAMPAIGN_REDUCER]: CampaignReducer,
  [ReducerKeys.PUB_DASHBOARD_REDUCER]: PubDashboardReducer,
  [ReducerKeys.CAMPAIGNS_ADVERTISEMENT_REDUCER]: CampaignAdvertisementReducer,
  [ReducerKeys.REPORTING_REDUCER]: persistReducer(
    reportPersistConfig,
    ReportingReducer
  ),
  [ReducerKeys.APPLICATION_REDUCER]: ApplicationReducer,
  [ReducerKeys.APPLICATION_DETAIL_REDUCER]: ApplicationDetailReducer,
  [ReducerKeys.IFE_ANALYTICS_REDUCER]: IFEAnalyticsReducer,
  [ReducerKeys.ADMINISTRATOR_REDUCER]: AdministratorReducer,
  [ReducerKeys.FLIGHTS_REDUCER]: FlightReducer,
  [ReducerKeys.TRANSPORTS_REDUCER]: TransportsReducer,
  [ReducerKeys.TRANSPORT_REDUCER]: TransportReducer,
  [ReducerKeys.PUBLISHER_REDUCER]: PublisherReducer,
  [ReducerKeys.PARALIST_REDUCER]: ParalistReducer,
  [ReducerKeys.PUBLISHERS_USERS_WHITELIST_REDUCER]: PublisherExclusiveReducer,
  [ReducerKeys.DSP_REDUCER]: DSPReducer,
  [ReducerKeys.USERS_REDUCER]: UsersReducer,
  [ReducerKeys.USERS_ADVERTISERS_ADMINISTRATION_REDUCER]: AdvertiserAdminReducer,
  [ReducerKeys.USER_PRICE_CALCULATION_REDUCER]: UserPriceCalculationReducer,
  [ReducerKeys.COUNTRIESRATE_REDUCER]: CountryRateReducer,
  [ReducerKeys.COUNTRIES_REDUCER]: CountryReducer,
  [ReducerKeys.ADVERTISEMENTSTYPE_REDUCER]: AdvertisementstypeReducer,
  [ReducerKeys.AIRLINES_REDUCER]: AirlinesReducer,
  [ReducerKeys.AIRPORTS_REDUCER]: AirportsReducer,
};

export default function createReducer(asyncReducers = {}, store = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

// export const rootReducer = combineReducers({
//   // global
//   [ReducerKeys.AUTH_REDUCER]: persistReducer(authPersistConfig, AuthReducer),
//   [ReducerKeys.TRANSL_REDUCER]: persistReducer(
//     translationPersistConfig,
//     TranslationReducer
//   ),
//   [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
//     navigationPersistConfig,
//     NavigationReducer
//   ),
//   [ReducerKeys.MODAL_REDUCER]: ModalReducer,

//   // local
//   [ReducerKeys.RESET_PASSWORD_REDUCER]: ResetPasswordReducer,
//   [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
//   [ReducerKeys.REGISTRATION_LIST_REDUCER]: RegistrationListReducer,
//   [ReducerKeys.CHANGE_PASSWORD_REDUCER]: ChangePasswordReducer,
//   [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
//   [ReducerKeys.STORE_PERFORMANCE_REDUCER]: StorePerformanceReducer,
// });
