import React, { useState } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import useStyles from './styles';
import GridLayout from 'components/molecules/grid_layout';
import Translations from '../../translations';
import CardLayout from 'components/app_common/card_layout';
import ICampaignInfoWizard, { ISegment } from 'config/campaign_wizard';
import { useDispatch } from 'react-redux';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Divider, TextField } from '@material-ui/core';
import { IDataItems } from 'services/api/transport_stations';
import { useHistory } from 'react-router';
import TransportStationBox from './TransportStationBox';

interface ITransportStationsListProps {
  // viewType: ViewType;
  // queryObject: QueryObject;
  selected?: any;
  setSelected?: any;
  handleCheck?: (code: any) => boolean;
  handleSelect?: (code: any) => void;
  // setFavouriteSelected?: any;
}

const TransportStationsList = React.forwardRef(
  (
    {
      selected,
      setSelected,
      handleCheck,
      handleSelect,
    }: ITransportStationsListProps,
    ref: any
  ) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const campaign = CampaignSelectors.useGetCampaignWizardValues();
    const transportStations = CampaignSelectors.useGetTransportStations();
    // console.log('Transport Stations:', transportStations);

    /** Set selected transport stations if the campaign has previous values */
    React.useEffect(() => {
      setSelected(campaign.destination_transport ?? []);
    }, [campaign]);

    /** Give campaignWizardRef the selected transport stations */
    React.useEffect(() => {
      if (ref && selected) {
        ref.current = {
          destination_transport: selected,
        };

        console.log(ref);
      }
    }, [selected]);

    return (
      <React.Fragment>
        <TransportStationsHeader
          total={transportStations ? transportStations.length : 0}
          selected={selected && selected.length}
          // loaded={25}
        />
        {transportStations && transportStations.length > 0 ? (
          <React.Fragment>
            <TransportStationsListings
              transportStations={transportStations}
              setSelected={setSelected}
              handleCheck={handleCheck}
              handleSelect={handleSelect}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>Empty</React.Fragment>
        )}
      </React.Fragment>
    );
  }
);

interface ITransportStationsHeader {
  total: number;
  loaded?: number;
  selected?: number;
  // queryObject: QueryObject;
}
const TransportStationsHeader = ({
  total,
  loaded,
  selected,
}: ITransportStationsHeader) => {
  return (
    <React.Fragment>
      <Box>
        {total} Transport Stations - Selected: {selected}
      </Box>
      {loaded && <Box>{loaded} Stations Listed</Box>}
    </React.Fragment>
  );
};

interface ITransportStationsProps {
  transportStations: Array<IDataItems>;
  setSelected?: any;
  handleCheck?: (code: any) => boolean;
  handleSelect?: (code: any) => void;
  // addToFavourite: (id: number) => void;
  // onClickNoLogin: () => void;
  // removeFromFavourite: (id: number) => void;
  // listViewed: number[] | undefined;
}

const TransportStationsListings = ({
  transportStations,
  setSelected,
  handleCheck,
  handleSelect,
}: ITransportStationsProps) => {
  return (
    <React.Fragment>
      {transportStations
        // .slice(0, 25)
        .map((value: IDataItems, index: number) => (
          <React.Fragment key={value.id}>
            <TransportStationBox
              transportStation={value}
              setSelected={setSelected}
              handleCheck={handleCheck}
              handleSelect={handleSelect}
            />
            {index + 1 !== transportStations.length && (
              <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default TransportStationsList;
