import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as IFEAnalyticsService from '../../../services/api/ife_analytics';
import * as UserService from '../../../services/api/user';

export interface IParams {
  userid: string | null;
  datefrom: string | null;
  dateto: string | null;
}

class FetchTopResult implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_RESULT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_RESULT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_RESULT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getTopResult(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchTopResult Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchPassengerByDate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DATE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DATE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DATE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getPassengerByDate(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchPassengerByDate Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchPassengerByDestinationAirport implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DESTINATION_AIRPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DESTINATION_AIRPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DESTINATION_AIRPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getPassengerByDestinationAirport(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPassengerByDestinationAirport Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchPassengerByDepartureAirport implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DEPARTURE_AIRPORT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DEPARTURE_AIRPORT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PASSENGER_BY_DEPARTURE_AIRPORT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getPassengerByDepartureAirport(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPassengerByDepartureAirport Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTrackingrByDestination implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRACKING_BY_DEPARTURE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRACKING_BY_DEPARTURE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRACKING_BY_DEPARTURE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getTrackingByDeparture(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTrackingByDeparture Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTrackingrByDeparture implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRACKING_BY_DESTINATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRACKING_BY_DESTINATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRACKING_BY_DESTINATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getTrackingByDestination(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTrackingByDestination Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchViewPerDevice implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VIEW_PER_DEVICE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VIEW_PER_DEVICE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VIEW_PER_DEVICE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getViewPerDevice(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getViewPerDevice Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchTop10VistingTag implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_TAG,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_TAG,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_TAG,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getTop10VisitingTagByDeparture(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTop10VisitingTagByDeparture Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchTop10VistingURL implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_URLS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_URLS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TOP_10_VISITING_URLS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await IFEAnalyticsService.getTop10VisitingURLSByDeparture(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getTop10VisitingURLSByDeparture Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchUserList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchTopResultAction: (payload: IParams) =>
    new FetchTopResult().action(payload),
  fetchPassengerByDateAction: (payload: IParams) =>
    new FetchPassengerByDate().action(payload),
  fetchPassengerByDestinationAirportAction: (payload: IParams) =>
    new FetchPassengerByDestinationAirport().action(payload),
  fetchPassengerByDepartureAirportAction: (payload: IParams) =>
    new FetchPassengerByDepartureAirport().action(payload),
  fetchTrackingrByDestinationAction: (payload: IParams) =>
    new FetchTrackingrByDestination().action(payload),
  fetchTrackingrByDepartureAction: (payload: IParams) =>
    new FetchTrackingrByDeparture().action(payload),
  fetchViewPerDeviceAction: (payload: IParams) =>
    new FetchViewPerDevice().action(payload),
  fetchTop10VistingTagAction: (payload: IParams) =>
    new FetchTop10VistingTag().action(payload),
  fetchTop10VistingURLAction: (payload: IParams) =>
    new FetchTop10VistingURL().action(payload),
  fetchUserListAction: (payload: any) => new FetchUserList().action(payload),
};
