import React from 'react';
import { AuthRoles, HideSideBar } from 'config';
import Person from '@material-ui/icons/Person';
import AccountBalanceWalletOutlined from '@material-ui/icons/AccountBalanceWalletOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Dispatch } from 'redux';
import Actions from '../../../redux/reducers/gl_auth_reducer/actions';
import NavigationConfig from 'navigation/config';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../../translations';

// TDispatc
interface IMenuConfigurationElement {
  label: string | React.ReactElement;
  id: string;
  action: (props: Dispatch<any>, history: any) => void;
  submenu: boolean;
  roles: Array<number | string> | number | string;
  icon: any;
}

export const ProfileConfiguration: IMenuConfigurationElement[] = [
  {
    label: <TranslatedText textMap={Translations.profile} />,
    id: '1',
    action: (dispatch: Dispatch<any>, history) =>
      history.push(NavigationConfig.profilePage().path),
    submenu: false,
    roles: AuthRoles.Registered,
    icon: <Person />,
  },
  {
    label: <TranslatedText textMap={Translations.wallet} />,
    id: '2',
    action: (dispatch: Dispatch<any>, history) =>
      history.push(NavigationConfig.walletPage().path),
    submenu: false,
    roles: AuthRoles.Registered,
    icon: <AccountBalanceWalletOutlined />,
  },
  {
    label: <TranslatedText textMap={Translations.logout} />,
    id: '3',
    action: (dispatch: Dispatch<any>, history) =>
      dispatch(Actions.logoutAction(history)),
    submenu: false,
    roles: AuthRoles.Registered,
    icon: <ExitToAppIcon />,
  },
];

interface IElementConfiguration {
  enable: boolean;
  config?: IConfig;
}

interface IConfiguration {
  sidebar: IElementConfiguration;
  search: IElementConfiguration;
  notifications: IElementConfiguration;
  profile: IElementConfiguration;
  language: IElementConfiguration;
  appBar: IElementConfiguration;
}

interface IConfig {
  name?: string;
  iconSrc?: any;
  backgroundColor1?: string;
  backgroundColor2?: string;
  backgroundColor3?: string;
  backgroundColor4?: string;
  backgroundColor5?: string;
  backgroundColor6?: string;
  textColor1?: string;
  textColor2?: string;
  textColor3?: string;
  textColor4?: string;
}

const Configuration: IConfiguration = {
  sidebar: {
    enable: HideSideBar,
    config: {},
  },
  appBar: {
    enable: true,
    config: { iconSrc: require('../../../assets/images/logos/logonew.png') },
  },
  search: {
    enable: false,
    config: {},
  },
  notifications: {
    enable: true,
    config: {},
  },
  profile: {
    enable: true,
    config: {
      iconSrc: require('assets/images/logos/app-logo.png'),
    },
  },
  language: {
    enable: true,
  },
};

export default Configuration;
