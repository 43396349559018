import React, { useRef } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../../translations';
import { CampaignTabs, IView } from '../../tab_stepper';
import useStyles from '../../styles';
import {
  Avatar,
  Box,
  Card,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import CampaignInfoView from '../campaign_info_view';
import AudienceView from '../audience_view';
import AdDetailsView from '../ad_details_view';
import { CampaignInfoSteps } from '../campaign_info_view/configuration';
import DestinationTabView from '../destination_view';
import WebsiteTabView from '../website_view';

import PreviewView from '../preview_view';
import * as CampaignWizardUtils from './utils';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';

import reactLogger from 'utils/logger';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import TransportTabView from '../transport_view';
import { BaseNavigationMenuContainer } from './BaseNavigationMenu';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import * as Icons from './icons';
import ICampaignInfoWizard from 'config/campaign_wizard';
import { AudienceSteps } from '../audience_view/configuration';

export default function CampaignTabView() {
  reactLogger.renderComponent('CampaignTabView');
  // const [view, setView] = React.useState<IView>({
  //   tab: CampaignTabs.CAMPAIGN_INFO,
  //   step: CampaignInfoSteps.BASIC_INFO,
  // });
  const [view, setView] = React.useState<IView>({
    tab: CampaignTabs.CAMPAIGN_INFO,
    step: CampaignInfoSteps.CAMPAIGN_INFO,
  });
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const campaign = CampaignSelectors.useGetCampaignWizardValues();

  const campaignWizardRef = useRef();

  const handleChangeTab = (
    event: React.ChangeEvent<{}> | null,
    newValue: number
  ) => {
    console.log('handleChangeTab triggered');
    CampaignWizardUtils.handleCampaignWizardData(
      campaignWizardRef.current,
      view,
      dispatch
    );
    if (newValue > view.tab) {
      if (!CampaignWizardUtils.requiredFieldsTabValid(view.tab, dispatch))
        return;
      // if (
      //   !CampaignWizardUtils.requiredFieldsValid(view.tab, view.step, dispatch)
      // )
      //   return;
      for (let tab = view.tab; tab < newValue; tab++)
        if (!CampaignWizardUtils.requiredFieldsValid(tab, 0, dispatch)) return;
    }
    setView(CampaignWizardUtils.moveToTab(newValue) as any);
  };

  const handleChangeTabIndex = (index: number) => {
    CampaignWizardUtils.handleCampaignWizardData(
      campaignWizardRef.current,
      view,
      dispatch
    );
    if (!CampaignWizardUtils.requiredFieldsValid(view.tab, view.step, dispatch))
      return;
    setView(CampaignWizardUtils.moveToTab(index) as any);
  };

  const handleChangeStep = (step: number) => {
    CampaignWizardUtils.handleCampaignWizardData(
      campaignWizardRef.current,
      view,
      dispatch
    );
    if (!CampaignWizardUtils.requiredFieldsValid(view.tab, view.step, dispatch))
      return;
    setView({ tab: view.tab, step: step });
  };

  const handleNext = () => {
    const { tab, step } = view;
    // console.log('data', campaignWizardRef.current);
    CampaignWizardUtils.handleCampaignWizardData(
      campaignWizardRef.current,
      view,
      dispatch
    );
    if (!CampaignWizardUtils.requiredFieldsValid(tab, step, dispatch)) return;
    setView(handleNextPreviousAction(tab, step, 'next') as any);
    //Skip tab to adjust to Audience missing
    const current: any = campaignWizardRef.current;
    if (
      ((current.display === undefined && campaign.display !== 2) ||
        (current.display !== undefined && current.display !== 2)) &&
      tab === CampaignTabs.AUDIENCE - 1
    )
      setView(
        handleNextPreviousAction(
          CampaignTabs.AUDIENCE,
          AudienceSteps.ADVANCED,
          'next'
        ) as any
      );
  };

  const handlePrevious = () => {
    const { tab, step } = view;
    CampaignWizardUtils.handleCampaignWizardData(
      campaignWizardRef.current,
      view,
      dispatch
    );
    setView(handleNextPreviousAction(tab, step, 'previous') as any);
    //Skip tab to adjust to Audience missing
    if (campaign.display !== 2 && tab === CampaignTabs.AUDIENCE + 1)
      setView(
        handleNextPreviousAction(
          CampaignTabs.AUDIENCE,
          AudienceSteps.GENDER,
          'previous'
        ) as any
      );
  };

  function handleNextPreviousAction(
    tab: number,
    step: number | null,
    move: 'next' | 'previous'
  ) {
    if (move === 'next') {
      return CampaignWizardUtils.moveToNext(tab, step);
    } else {
      return CampaignWizardUtils.moveToPrevious(tab, step);
    }
  }

  const tabPanels = [];

  return (
    // <Box style={{ flexGrow: 1, display: 'flex' }}>
    <Card className={classes.tabViewContainer}>
      <DrawerBar
        view={view}
        handleChangeTab={handleChangeTab}
        campaign={campaign}
      />
      {/* <Tabs 
            value={view.tab}
            onChange={handleChangeTab}
            textColor="secondary"
            // centered
            indicatorColor="secondary"
            orientation="vertical"
            variant="scrollable"
          >
            <Tab
              label={
                <TranslatedText textMap={Translations.campaign_info_tab} />
              }
            />
            <Tab
              label={
                <TranslatedText
                  textMap={
                    campaign.display === 0
                      ? Translations.destination_tab
                      : campaign.display === 1
                      ? Translations.websites
                      : Translations.transport_stations
                  }
                />
              }
            />
            {campaign.display === 2 && (
              <Tab
                label={<TranslatedText textMap={Translations.audience_tab} />}
              />
            )}
            <Tab
              label={<TranslatedText textMap={Translations.ad_details_tab} />}
            />
            <Tab
              label={<TranslatedText textMap={Translations.preview_tab} />}
            />
          </Tabs> */}
      <div
        className={clsx({
          'custom-scrollbar': true,
          [classes.tabContentContainer]: true,
        })}
      >
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={view.tab}
          onChangeIndex={handleChangeTabIndex}
        >
          <TabPanel
            value={view.tab}
            index={CampaignTabs.CAMPAIGN_INFO}
            key={CampaignTabs.CAMPAIGN_INFO}
          >
            <CampaignInfoView
              view={view}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handleChangeStep={handleChangeStep}
              ref={campaignWizardRef}
            />
          </TabPanel>
          <TabPanel
            value={view.tab}
            index={CampaignTabs.AUDIENCE}
            key={CampaignTabs.AUDIENCE}
          >
            <AudienceView
              view={view}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handleChangeStep={handleChangeStep}
              ref={campaignWizardRef}
            />
          </TabPanel>
          <TabPanel
            value={view.tab}
            index={CampaignTabs.DESTINATION}
            key={CampaignTabs.DESTINATION}
          >
            {campaign.display === 0 ? (
              <DestinationTabView
                view={view}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                ref={campaignWizardRef}
              />
            ) : campaign.display === 1 ? (
              <WebsiteTabView
                view={view}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                ref={campaignWizardRef}
              />
            ) : (
              <TransportTabView
                view={view}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                ref={campaignWizardRef}
              />
            )}
          </TabPanel>
          <TabPanel
            value={view.tab}
            index={CampaignTabs.AD_DETAILS}
            key={CampaignTabs.AD_DETAILS}
          >
            <AdDetailsView
              view={view}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handleChangeStep={handleChangeStep}
              ref={campaignWizardRef}
            />
          </TabPanel>
          <TabPanel
            value={view.tab}
            index={CampaignTabs.PREVIEW}
            key={CampaignTabs.PREVIEW}
          >
            <PreviewView
              view={view}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handleChangeStep={handleChangeStep}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
    </Card>
    // </Box>
  );
}

interface ITabProps {
  index: number;
  value: number;
  children: React.ReactElement;
}
const TabPanel = (props: ITabProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role={'tabpanel'}
      hidden={value !== index}
      id={`tab${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const DrawerBar = (props: {
  view: IView;
  handleChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
  campaign: ICampaignInfoWizard;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleChangeTab, view, campaign } = props;

  return (
    <React.Fragment>
      <BaseNavigationMenuContainer
        Header={
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              padding="1rem"
            >
              <Avatar
                // alt="Remy Sharp"
                // src={
                //   product
                //     ? `${Environment.API_BASE_URL}/api/picture/${product.picture}/picture-watermark`
                //     : `${Environment.API_BASE_URL}/storage/default.jpg`
                // }
                // onError={(e: any) => {
                //   e.target.src = `${Environment.API_BASE_URL}/storage/default.jpg`;
                // }}
                alt="Campaign Image"
                // src={}
                className={classes.avatar}
              />
              {/* <Typography>
              <TranslatedTextV2
                defaultText="Property ID:"
                translationKey={`${PageKey}.property_id`}
              />
              {product ? product.id : ''}
            </Typography> */}
              <Box display="flex">
                {/* <Chip
                size="small"
                label={
                  <React.Fragment>
                    {product ? (
                      product.published ? (
                        <TranslatedTextV2
                          translationKey={`${PageKey}.published`}
                          defaultText={'Published'}
                        />
                      ) : (
                        <TranslatedTextV2
                          translationKey={`${PageKey}.unpublished`}
                          defaultText={'Unpublished'}
                        />
                      )
                    ) : null}
                  </React.Fragment>
                }
                color="secondary"
                className={classes.chip}
              /> */}
              </Box>
            </Box>
            <Box
              display="flex"
              // alignItems="space-between"
              justifyContent="space-around"
            >
              {/* <IconButton
              onClick={() => {
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_SHARE,
                    params: {},
                  })
                );
                dispatch(Actions.fetchSharingProductAction([Number(itemId)]));
              }}
              className={classes.barIcon}
            >
              <ShareOutlinedIcon />
            </IconButton>
            <IconButton
              className={classes.barIcon}
              onClick={() =>
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_PRINT,
                    params: {},
                  })
                )
              }
            >
              <LocalPrintshopOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(
                  NavigationConfig.productDetailsPage({ itemId }).path,
                  '_blank'
                );
              }}
              className={classes.barIcon}
            >
              <SendIcon />
            </IconButton> */}
            </Box>
          </Box>
        }
        menuContainer={{
          menu: [
            {
              id: 'campaign_info_tab',
              // label: <TranslatedText textMap={Translations.audience_tab} />,
              icon: <Icons.DocumentsIcon />,
              element: (
                <Tab
                  disableRipple
                  label={
                    <TranslatedText textMap={Translations.campaign_info_tab} />
                  }
                />
              ),
              onClickEventValue: {
                on: handleChangeTab,
                clickEvent: null,
                clickValue: 0,
              },
            },
            {
              id: 'campaign_audience_tab',
              // label: <TranslatedText textMap={Translations.audience_tab} />,
              icon: <Icons.MatchedClientsIcon />,
              element: (
                <Tab
                  disableRipple
                  label={<TranslatedText textMap={Translations.audience_tab} />}
                />
              ),
              onClickEventValue: {
                on: handleChangeTab,
                clickEvent: null,
                clickValue: 1,
              },
              loadOn: campaign.display === 2,
            },
            {
              id: 'campaign_destination_tab',
              // label: <TranslatedText textMap={Translations.audience_tab} />,
              icon: <Icons.LocationIcon />,
              element: (
                <Tab
                  disableRipple
                  label={
                    <TranslatedText
                      textMap={
                        campaign.display === 0
                          ? Translations.destination_tab
                          : campaign.display === 1
                          ? Translations.websites
                          : Translations.transport_stations
                      }
                    />
                  }
                />
              ),
              onClickEventValue: {
                on: handleChangeTab,
                clickEvent: null,
                clickValue: 2,
              },
            },
            {
              id: 'campaign_ad_details_tab',
              // label: <TranslatedText textMap={Translations.audience_tab} />,
              icon: <Icons.ImagesIcon />,
              element: (
                <Tab
                  disableRipple
                  label={
                    <TranslatedText textMap={Translations.ad_details_tab} />
                  }
                />
              ),
              onClickEventValue: {
                on: handleChangeTab,
                clickEvent: null,
                clickValue: 3,
              },
            },
            {
              id: 'campaign_preview_tab',
              // label: <TranslatedText textMap={Translations.audience_tab} />,
              icon: <Icons.InternalNotesIcon />,
              element: (
                <Tab
                  disableRipple
                  label={<TranslatedText textMap={Translations.preview_tab} />}
                />
              ),
              onClickEventValue: {
                on: handleChangeTab,
                clickEvent: null,
                clickValue: 4,
              },
            },
          ],
        }}
        heightFull={true}
        view={view}
      />
    </React.Fragment>
  );
};
