interface Environment {
  API_BASE_URL: string;
  APP_NAME: string;
}

const Prodution: Environment = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
  APP_NAME: 'BASE WEB',
};

const Development: Environment = {
  API_BASE_URL: 'http://localhost:1338',
  APP_NAME: 'BASE WEB',
};

export default process.env.NODE_ENV === 'production' ? Prodution : Development;
