import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as PublishersUsersWhiteListService from '../../../services/api/publishers_users_whitelist';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import {
  IFetchListExclusiveByPublisher,
  IUpdateExclusivePublisherInput,
} from '../../../services/api/publishers_users_whitelist';
import * as CategoryService from '../../../services/api/category';
import * as UserService from '../../../services/api/user';
import {
  IGetPublisherApplicationByPublisherInput,
  getPublisherApplicationByPublisher,
} from 'services/api/publisher';

/** Fetch FetchPublishers_Users_WhiteListListInput  */

interface FetchPublishers_Users_WhiteListListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchPublishers_Users_WhiteListList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchPublishers_Users_WhiteListListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.fetchPublishers_Users_WhiteListList(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcPublishers_Users_WhiteListList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewPublishers_Users_WhiteList  */

interface AddNewPublishers_Users_WhiteListInput {
  userid: number | null;
  categoryid: number | null;
  publisherappid: number | null;
  onFinish: () => void;
}

class AddNewPublishers_Users_WhiteList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewPublishers_Users_WhiteListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { onFinish, ...rest } = payload;

        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.addPublishers_Users_WhiteList(
          rest
        );
        dispatch(this.onSuccess(response.data));
        onFinish();
      } catch (error) {
        console.log('AddNewPublishers_Users_WhiteList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Publishers_Users_WhiteList  */

interface EditPublishers_Users_WhiteListInput {
  id: number | null;
  userid: string | null;
  categoryid: number | null;
  publisherappid: number | null;
  fetchPublishers_Users_WhiteListInput: FetchPublishers_Users_WhiteListListInput;
}

class EditPublishers_Users_WhiteList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_PUBLISHERS_USERS_WHITELIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditPublishers_Users_WhiteListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchPublishers_Users_WhiteListInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.editPublishers_Users_WhiteList(
          payload
        );
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchPublishers_Users_WhiteListList().action(
            fetchPublishers_Users_WhiteListInput
          )
        );

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_PUBLISHERS_USERS_WHITELIST,
        //   })
        // );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Publishers_Users_WhiteList  */

interface ViewPublishers_Users_WhiteListInput {
  id: number;
}

class ViewPublishers_Users_WhiteList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PUBLISHERS_USERS_WHITELIST_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewPublishers_Users_WhiteListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.fetchPublishers_Users_WhiteListDetail(
          {
            id: payload.id,
          }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewPublishers_Users_WhiteList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchListExclusivityByPublisher implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_EXCLUSIVE_BY_PUBLISHER,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_EXCLUSIVE_BY_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_EXCLUSIVE_BY_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IFetchListExclusiveByPublisher): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.fetchListExclusiveByPublisher(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('fetchListExclusiveByPublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchCategory implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_CATEGORY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_CATEGORY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_CATEGORY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CategoryService.getCategory();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export interface IFetchUserByRoleActionsInput {
  roleId: number;
}
class FetchUserByRoleActions implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_LIST_USER_BY_ROLE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_LIST_USER_BY_ROLE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_LIST_USER_BY_ROLE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IFetchUserByRoleActionsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUsersbyRole(payload.roleId);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IPayloadUpdateExclusivePublisher {
  data: IUpdateExclusivePublisherInput;
  onFinish: () => void;
}

class UpdateExclusivePublisher implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IPayloadUpdateExclusivePublisher): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.updateExclusivePublisher(
          payload.data
        );
        dispatch(this.onSuccess(response.data));
        payload.onFinish();
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class GetPublisherApplicationByPublisher implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.GET_PUBLISHER_APPLICATION_BY_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.GET_PUBLISHER_APPLICATION_BY_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.GET_PUBLISHER_APPLICATION_BY_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IGetPublisherApplicationByPublisherInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await getPublisherApplicationByPublisher(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getCategory Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IDeleteExclusivePublisher {
  id: string;
  onFinish: () => void;
}
class DeleteExclusivePublisher implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_EXCLUSIVE_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IDeleteExclusivePublisher): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublishersUsersWhiteListService.deleteExclusivePublisher(
          payload.id
        );
        dispatch(this.onSuccess(response.data));
        payload.onFinish();
      } catch (error) {
        console.log('deleteExclusivePublisher Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchPublishers_Users_WhiteListListAction: (
    payload: FetchPublishers_Users_WhiteListListInput
  ) => new FetchPublishers_Users_WhiteListList().action(payload),
  addNewPublishers_Users_WhiteListAction: (
    payload: AddNewPublishers_Users_WhiteListInput
  ) => new AddNewPublishers_Users_WhiteList().action(payload),
  editPublishers_Users_WhiteListAction: (
    payload: EditPublishers_Users_WhiteListInput
  ) => new EditPublishers_Users_WhiteList().action(payload),
  viewPublishers_Users_WhiteListAction: (
    payload: ViewPublishers_Users_WhiteListInput
  ) => new ViewPublishers_Users_WhiteList().action(payload),
  fetchListExclusivityByPublisher: (payload: IFetchListExclusiveByPublisher) =>
    new FetchListExclusivityByPublisher().action(payload),
  fetchCategoryActions: () => new FetchCategory().action(),
  fetchUserByRoleActions: (payload: IFetchUserByRoleActionsInput) =>
    new FetchUserByRoleActions().action(payload),
  updateExclusivePublisher: (payload: IPayloadUpdateExclusivePublisher) =>
    new UpdateExclusivePublisher().action(payload),
  getPublisherApplicationByPublisher: (
    payload: IGetPublisherApplicationByPublisherInput
  ) => new GetPublisherApplicationByPublisher().action(payload),
  deleteExclusivePublisher: (payload: IDeleteExclusivePublisher) =>
    new DeleteExclusivePublisher().action(payload),
};
