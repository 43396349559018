import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: (props: any) => props.nestedScrolling && `calc(100vh - 56px)`,
    height: (props: any) => props.fullHeight && `calc(100vh - 56px)`,
    width: '100%',
    overflow: (props: any) => props.nestedScrolling && 'auto',
    '@media (min-width:600px)': {
      height: (props: any) => props.fullHeight && `calc(100vh - 64px)`,
      maxHeight: (props: any) => props.nestedScrolling && `calc(100vh - 64px)`,
    },
  },
}));

const nestedScrolling = true;

interface IFullPage {
  children?: React.ReactElement;
  fullHeight?: boolean;
  style?: React.CSSProperties;
}

export default function FullPageContainer({
  children,
  fullHeight = true,
  style,
}: IFullPage) {
  const classes = useStyles({ nestedScrolling, fullHeight });
  return (
    <Box
      className={clsx({
        [classes.container]: true,
      })}
      style={style}
    >
      {children}
    </Box>
  );
}
