import { ThemesModeEnum } from 'config';
import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';

interface IState {
  themeMode: ThemesModeEnum;
}

const initialState: IState = {
  themeMode: ThemesModeEnum.Light,
};

const ThemeReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_THEME:
      return {
        ...state,
        themeMode: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ThemeReducer;
