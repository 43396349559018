import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_AIRPORTS_LIST: string = typeCreatorV2(
  'AirportsReducer',
  'SetAirportsList'
);

export const SET_ADD_AIRPORTS: string = typeCreatorV2(
  'AirportsReducer',
  'SetAddAirports'
);

export const SET_EDIT_AIRPORTS: string = typeCreatorV2(
  'AirportsReducer',
  'SetEditAirports'
);

export const SET_AIRPORTS_DETAIL: string = typeCreatorV2(
  'AirportsReducer',
  'SetAirportsDetail'
);
