import { Languages } from '../../config';

const Translations = {
  user_search: {
    [Languages.English]: 'User',
    [Languages.Greek]: 'Χρήστης',
    [Languages.Russian]: 'Пользователь',
  },
  datefrom_search: {
    [Languages.English]: 'Date From',
    [Languages.Greek]: 'Ημερομηνία από',
    [Languages.Russian]: 'Дата, с',
  },
  dateto_search: {
    [Languages.English]: 'Date To',
    [Languages.Greek]: 'ημερομηνία Για να',
    [Languages.Russian]: 'Дата до',
  },
  button_search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
  passenger_title: {
    [Languages.English]: 'Passengers',
    [Languages.Greek]: 'επιβάτες',
    [Languages.Russian]: 'Пассажиры',
  },
  visiting_title: {
    [Languages.English]: 'Visitting time',
    [Languages.Greek]: 'χρόνο Visitting',
    [Languages.Russian]: 'Visitting время',
  },
  visits_title: {
    [Languages.English]: 'Visits',
    [Languages.Greek]: 'επισκέψεις',
    [Languages.Russian]: 'просмотров',
  },
  passenger_chart_title: {
    [Languages.English]: 'Passengers',
    [Languages.Greek]: 'επιβάτες',
    [Languages.Russian]: 'Пассажиры',
  },
  passenger_by_destination_chart_title: {
    [Languages.English]: 'Passengers By Destination Airport',
    [Languages.Greek]: 'Επιβατών με προορισμό το αεροδρόμιο',
    [Languages.Russian]: 'Пассажиры по месту назначения аэропорта',
  },
  passenger_by_departure_chart_title: {
    [Languages.English]: 'Passengers By Departure Airport',
    [Languages.Greek]: 'Οι επιβάτες Με Αναχώρηση Αεροδρόμιο',
    [Languages.Russian]: 'Пассажиров аэропорт вылета',
  },
  tracking_by_departure_chart_title: {
    [Languages.English]: 'Tracking By Departure',
    [Languages.Greek]: 'Παρακολούθηση Με Αναχώρηση',
    [Languages.Russian]: 'Отслеживание По вылету',
  },
  tracking_by_destination_chart_title: {
    [Languages.English]: 'Tracking By Destination',
    [Languages.Greek]: 'Παρακολούθηση Με Προορισμός',
    [Languages.Russian]: 'Отслеживание По месту назначения',
  },
  view_per_device_chart_title: {
    [Languages.English]: 'Views per Device',
    [Languages.Greek]: 'Προβολές ανά συσκευή',
    [Languages.Russian]: 'Просмотров за устройство',
  },
};

export default Translations;
