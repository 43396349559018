import React from 'react';
import Datatable from '../../../../components/molecules/datatable';
import { TableColumns } from './configuration';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import _ from 'lodash';

export default function DestinationsTable() {
  const data = CampaignSelectors.useGetDestinationReachOnBoard();
  //   const paging = result.data?.paging;
  const dispatch = useDispatch();
  // const sortField = React.useRef<string>(TableColumns[0].id);
  // const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={true}
        // sorting={{
        //   sortField: sortField.current,
        //   order: sortType.current,
        // }}
        // paging={paging}
        data={_.get(data, 'flights', []) ?? []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // sortType.current = order;
          // sortField.current = _sortField;
          //   dispatch(
          //     CampaignsActions.fetchCampaignsListAction({
          //       sortField: _sortField,
          //       page: selectedPage,
          //       pageSize: pageSize,
          //       order: order,
          //     })
          //   );
        }}
      />
    </Box>
  );
}
