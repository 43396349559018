import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';

interface ICoordinatesProps {
  text: string;
  style: React.CSSProperties;
  size?: 'small' | 'large';
  value: string;
}

const Coordinates = ({ text, style, size, value }: ICoordinatesProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      style={style}
    >
      <Box
        display="flex"
        flexDirection={size === 'large' ? 'column-reverse' : 'row'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: size === 'large' ? '16px' : '14px',
          }}
        >
          {text}:
        </Typography>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: size === 'large' ? '16px' : '14px',
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

interface ITransportStationsGeoProps {
  lat: number;
  lon: number;
}
const TransportStationsGeo = ({ lat, lon }: ITransportStationsGeoProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Coordinates
        text={`Lat`}
        value={`${lat}`}
        size={'small'}
        style={{
          marginRight: '1rem',
        }}
      />
      <Coordinates
        text={`Lon`}
        value={`${lon}`}
        size={'small'}
        style={{
          marginRight: '1rem',
        }}
      />
    </Box>
  );
};

export default TransportStationsGeo;
