import { IGeoJsonData } from './types';

// export interface IGeoJsonData {
//   type: 'FeatureCollection';
//   features: Array<{
//     type: 'Feature';
//     properties: any;
//     geometry: {
//       type: 'Point' | string;
//       coordinates: number[][] | number[][][];
//     };
//   }>;
// }

/** Geomatic Input */
interface IDefaultInput_type1 {
  results: Array<{
    id: number;
    name_gr: string;
    name_en: string;
    has_stats: boolean;
    has_occupied_area: boolean;
    center: {
      lat: number;
      lng: number;
    };
    perimeter: number;
    area: number;
    geometry: {
      type: string;
      crs: {
        type: string;
        properties: {
          name: string;
        };
      };
      coordinates: number[][][];
    };
  }>;
}

export default (geoJsonData: any): IGeoJsonData => {
  // check if the input: geoJsonData is type IDefaultInput_type1
  return parseDefaultInputType1(geoJsonData);
};

const parseDefaultInputType1 = (
  geoJsonData: IDefaultInput_type1
): IGeoJsonData => {
  const realGeo: IGeoJsonData = {
    type: 'FeatureCollection',
    features: [],
  };
  realGeo.features = geoJsonData.results.map(({ geometry, ...rest }) => {
    return {
      type: 'Feature',
      properties: rest,
      geometry: {
        type: geometry.type,
        coordinates: geometry.coordinates,
      },
    };
  });
  return realGeo;
};
