// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export const getAirportName = async (payload: any): Promise<any> => {
  const { code } = payload;

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airports/getName?code=${code}`
  );
  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getAirportList = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airports/list`
  );
  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** fetchAirportsList  */
interface IAirportsListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  name: string | null;
  town: string | null;
  country: string | null;
  countrycode: string | null;
  code: string | null;
  icao: string | null;
  lat: string | null;
  lon: string | null;
  timezone: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IAirportsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchAirportsList = async (
  payload: IAirportsListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airports/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IAirportsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Airports Detail */
interface IAirportsDetailInput {
  id: number;
}

interface IAirportsDetailOutput extends Types.ResponseOutput {
  data: {
    name: string | null;
    town: string | null;
    country: string | null;
    countrycode: string | null;
    code: string | null;
    icao: string | null;
    lat: number | null;
    lon: number | null;
    timezone: string | null;
  };
}

export const fetchAirportsDetail = async (
  payload: IAirportsDetailInput
): Promise<IAirportsDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/airports/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Airports */
interface IAirportsAddInput {
  name: string | null;
  town: string | null;
  country: string | null;
  countrycode: string | null;
  code: string | null;
  icao: string | null;
  lat: number | null;
  lon: number | null;
  timezone: string | null;
}

interface IAirportsAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addAirports = async (
  payload: IAirportsAddInput
): Promise<IAirportsAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/airports `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Airports */
interface IAirportsEditInput {
  name: string | null;
  town: string | null;
  country: string | null;
  countrycode: string | null;
  code: string | null;
  icao: string | null;
  lat: number | null;
  lon: number | null;
  timezone: string | null;
}

interface IAirportsEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editAirports = async (
  payload: IAirportsEditInput
): Promise<IAirportsEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/airports `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
