import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { StateStatus } from 'redux/utils/common';
import AddEditDSPModal from './components/AddEditDSPModal';
import Header from './components/Header';
import { TableColumns, TableDefaultFetchValues } from './configuration';
import UsersActions from 'redux/reducers/users_reducer/actions';
import {
  useGetDSPList,
  useGetParalist,
  useGetUserByRole,
} from 'redux/reducers/dsp_reducer/selectors';
import AdvertiserConnectionModal from './components/AdvertiserConnectionModal';
import AdvertiserCalculationModal from './components/AdvertiserCalculationModal';
import {
  useGetUsersList,
  UsersListData,
} from 'redux/reducers/users_reducer/selectors';

const AdvertiserListPage = () => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const usersList: any = useGetUsersList();
  const getParalist = useGetParalist();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState<Object>({}); // use for later find
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'asc',
  });

  React.useEffect(() => {
    dispatch(UsersActions.fetchUsersListAction({ roleName: 'Advertiser' }));
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(UsersActions.fetchUsersListAction({ roleName: 'Advertiser' }));
  };

  const tableData: UsersListData = {
    paging: {
      page: 0,
      pageSize: usersList?.data?.length || 0,
      total: usersList?.data?.length || 0,
    },
    items: usersList['data'],
  };

  const isLoading =
    usersList.status === StateStatus.Pending && tableData?.items == null;

  if (tableData?.items == null)
    return (
      <>
        <Box height={'4px'} />
        <LinearProgress color="primary" />
        <Header />
      </>
    );

  return (
    <>
      <Box height={'4px'} />
      {isLoading ? <LinearProgress color="primary" /> : <Box height={'4px'} />}
      <Header />
      <Datatable
        loading={isLoading}
        sorting={sorting}
        paging={tableData?.paging}
        data={tableData?.items}
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <AdvertiserConnectionModal />
      <AdvertiserCalculationModal />
    </>
  );
};

export default withRouter(AdvertiserListPage);
