import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { RestoreOutlined } from '@material-ui/icons';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
  AppDatePicker,
  PopOverDatePicker
} from '../../../components/molecules/form_controls';
import GridLayout from '../../../components/molecules/grid_layout';

import { useForm, useFieldArray, Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetPublisherList } from '../../../redux/reducers/flights_reducer/selectors';
import { useSelectUserRoles } from '../../../redux/reducers/gl_auth_reducer/selectors';
import { Roles } from '../../../config/authRoles';
import Actions from '../../../redux/reducers/flights_reducer/actions';

import { TableDefaultFetchValues } from '../configuration';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';

import useStyles from '../styles';

interface IFormData {
  publisherid: string | null;
  date: string | null;
  dateto: string | null;

  // width: number;
  // genericChartType: GenericChartTypes;
}
const defaultValues: IFormData = {
  publisherid: null,
  date: null,
  dateto: null,
};

export default function SearchArea(props: any) {
  const { date, setDate, dateto, setDateto, publisherid, setPublisherid } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelectUserRoles();
  const [userOption, setUserOption] = useState([]);
  const userList = useGetPublisherList();

  useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList?.data?.publishers.map((item: any) => {
          return {
            id: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    reset({
      date,
      publisherid,
      dateto,
    });
  }, [date, publisherid]);

  function handleReset() {
    reset(defaultValues);
    setDate(null);
    setPublisherid(null);
    setDateto(null);
    dispatch(
      Actions.fetchFlightsListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        date: null,
        dateto: null,
        publisherid: null,
      })
    );
  }
  const onSubmit = (_data: IFormData) => {
    setDate(_data.date);
    setPublisherid(_data.publisherid);
    setDateto(_data.dateto);
    dispatch(
      Actions.fetchFlightsListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        date: _data.date ? moment(_data.date).format('YYYY-MM-DD') : _data.date,
        dateto: _data.dateto ? moment(_data.dateto).format('YYYY-MM-DD') : _data.dateto,
        publisherid: _data.publisherid,
      })
    );
  };
  return (
    <Card>
      <CardContent>
        <form
          // className={classes.search}
          onSubmit={handleSubmit(onSubmit)}
        >
          <GridLayout
            elements={[
              {
                id: '1',
                size: 3,
                element: (
                  <React.Fragment>
                    {roles.includes(Roles.Administrator) ? (
                      <AppSelect
                        variant="outlined"
                        error={Boolean(_.get(errors, 'userid', false))}
                        helperText={_.get(errors, 'userid', '')}
                        //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                        rules={
                          {
                            // required: <RequiredField />,
                          }
                        }
                        fullWidth
                        options={userOption}
                        id={'publisherid'}
                        control={control}
                        label={
                          <TranslatedText
                            textMap={Translations.publisher_search}
                          />
                        }
                        autoComplete={'publisherid'}
                        name={'publisherid'}
                      />
                    ) : null}
                  </React.Fragment>
                ),
              },
              {
                id: '2',
                size: 3,
                element: (
                  <PopOverDatePicker
                    error={Boolean(_.get(errors, 'date', false))}
                    helperText={_.get(errors, 'date', '')}
                    //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    id={'date'}
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.date_search} />
                    }
                    autoComplete={'date'}
                    name={'date'}
                  />
                ),
              },
              {
                id: '5',
                size: 3,
                element: (
                  <PopOverDatePicker
                    error={Boolean(_.get(errors, 'dateto', false))}
                    helperText={_.get(errors, 'dateto', '')}
                    //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    id={'dateto'}
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.date_to_search} />
                    }
                    autoComplete={'dateto'}
                    name={'dateto'}
                  />
                ),
              },
              {
                id: '4',
                size: 3,
                element: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      style={{ marginRight: 12 }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <TranslatedText textMap={Translations.button_search} />
                    </Button>
                    <Button
                      onClick={handleReset}
                      variant="contained"
                      color="primary"
                    >
                      <RestoreOutlined />
                    </Button>
                  </div>
                ),
              },
            ]}
          />
        </form>
      </CardContent>
    </Card>
  );
}
