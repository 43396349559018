import React from 'react';
import { LanguagesEnum } from '../../../config/languages';
import TranslatedText from '../../atoms/translated_text';
import useStyles from './styles';

interface ITextMap {
  [LanguagesEnum.English]: string;
  [LanguagesEnum.Greek]: string;
  [LanguagesEnum.Russian]: string;
}

interface IProps {
  data: ITextMap;
}

const FormFeedback = ({ data }: IProps) => {
  const classes = useStyles();

  return (
    <span className={classes.error}>
      <TranslatedText textMap={data} />
    </span>
  );
};

export default FormFeedback;
