import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
    },
    itemsCenter: {
      alignItems: 'center',
    },
    search: {
      display: 'flex',
      width: '100%',
    },
    progress: {
      height: 20,
      color: '#005FA3',
      borderRadius: '50px',
      backgroundColor: '#E0E0E0 !important',
    },
    barProgess: {
      borderRadius: '50px',
    },
    progressText: {
      color: '#000 !important',
    },
    root: {
      flexGrow: 1,
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 16,
    },
    textBlue: {
      color: '#005fa3',
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textBold: {
      fontWeight: 600,
    },
    mt12: {
      marginTop: 12,
    },
    mb24: {
      marginBottom: 20,
    },
    'mr-12': {
      marginRight: 12,
    },
    'w-1/4': {
      width: '25%',
    },
  })
);

export default useStyles;
