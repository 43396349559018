import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as CampaignService from '../../../services/api/campaigns';
import * as PublisherService from '../../../services/api/publisher';
// fetchCampaignsAdvertisement

class FetchCampaignsAdvertisement implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_ADVERTISEMENT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMPAIGNS_ADVERTISEMENT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMPAIGNS_ADVERTISEMENT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.fetchCampaignsAdvertisement(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('fetchCampaignsAdvertisement Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class UpdateCampaignsStatus implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGNS_STATUS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_CAMPAIGNS_STATUS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_CAMPAIGNS_STATUS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.updateCampaignsStatus(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('UpdateCampaignsStatus Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class AddCampaignsCategory implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_CAMPAIGNS_CATEGORY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.ADD_CAMPAIGNS_CATEGORY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_CAMPAIGNS_CATEGORY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CampaignService.addCampaignsCategory(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('AddCampaignsCategory Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchConnectedPublisher implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CONNECTED_PUBLISHER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CONNECTED_PUBLISHER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CONNECTED_PUBLISHER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.getPublisherByUserId(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('getPublisherByUserId Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class UpdateCampaignsAllowStatus implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_ALLOW_STATUS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_ALLOW_STATUS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_PUBLISHER_ALLOW_STATUS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await PublisherService.updatePublisherAllowStatus(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('updatePublisherAllowStatus Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchCampaignsAdvertisementAction: (payload: any) =>
    new FetchCampaignsAdvertisement().action(payload),
  updateCampaignsStatusActions: (payload: any) =>
    new UpdateCampaignsStatus().action(payload),
  addCampaignsCategoryActions: (payload: any) =>
    new AddCampaignsCategory().action(payload),
  updateCampaignsAllowStatusActions: (payload: any) =>
    new UpdateCampaignsAllowStatus().action(payload),
  fetchConnectedPublisherActions: (payload: any) =>
    new FetchConnectedPublisher().action(payload),
};
