import React, { ReactElement } from 'react';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import useStyles from './styles';
import {
  Button,
  Divider,
  LinearProgress,
  List,
  ListItem,
  TableSortLabel,
  Toolbar,
  Typography,
  TableRowProps,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { IProps, Order } from '../../configuration';
import { tableSort, getComparator } from '../../utils';

interface ITableProps extends IProps {
  orderBy: string;
  page: number;
  order: Order;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  createSortHandler: (
    property: string,
    inData: boolean
  ) => (event: React.MouseEvent<unknown>) => void;
}

export default function AppTable(props: ITableProps): ReactElement {
  const classes = useStyles({ height: props.height });
  const {
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    createSortHandler,
    loading,
    tableCellMainRowProps,
    tableCellProps,
    paperProps = {},
    disablePaper = false,
  } = props;

  return (
    <Hidden xsDown>
      <PaperContainer disablePaper={disablePaper} {...paperProps}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={
              typeof props.stickyHeader !== 'boolean'
                ? true
                : props.stickyHeader
            }
            aria-label="sticky table"
          >
            <TableHead className={classes.header}>
              <TableRow>
                {props.columns.map((headCell, index) => (
                  <TableCell
                    className={classes.header}
                    {...(tableCellMainRowProps
                      ? tableCellMainRowProps(index)
                      : {})}
                    key={headCell.id}
                    align={
                      headCell.align === 'right'
                        ? 'right'
                        : headCell.align === 'center'
                        ? 'center'
                        : 'left'
                    }
                    padding={props.disablePadding ? 'none' : 'default'}
                    sortDirection={
                      props.orderBy === headCell.id ? props.order : false
                    }
                  >
                    {props.sorting ? (
                      <TableSortLabel
                        active={props.orderBy === headCell.id}
                        direction={
                          props.orderBy === headCell.id ? props.order : 'asc'
                        }
                        disabled={!headCell.inData}
                        onClick={createSortHandler(
                          headCell.id,
                          headCell.inData
                        )}
                      >
                        {headCell.label}
                        {props.orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {props.order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // tableSort(props.data, getComparator(props.order, props.orderBy))
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                props?.data?.map((row: any, rowIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {props.columns.map((column, columnIndex) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={
                              column.align === 'right'
                                ? 'right'
                                : column.align === 'center'
                                ? 'center'
                                : 'left'
                            }
                            {...(tableCellProps
                              ? tableCellProps(columnIndex, rowIndex, value)
                              : {})}
                          >
                            {column.render(
                              rowIndex,
                              value,
                              columnIndex,
                              props.data
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </PaperContainer>

      {props.paging && (
        <PaperContainer
          disablePaper={disablePaper}
          className={classes.pagingPaper}
          {...paperProps}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={props.paging?.total || props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PaperContainer>
      )}
    </Hidden>
  );
}

interface IPaperProps extends PaperProps {
  disablePaper: boolean;
}

const PaperContainer = (props: IPaperProps) => {
  const classes = useStyles();
  const { disablePaper, children, ...paperProps } = props;

  return (
    <React.Fragment>
      {!disablePaper ? (
        <Paper
          elevation={0}
          variant="outlined"
          square
          className={classes.root}
          {...paperProps}
        >
          {props.children}
        </Paper>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};
