import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import Datatable from 'components/molecules/datatable';
import { AppSelect } from 'components/molecules/form_controls';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import {
  useCreateDspPublisherStatus,
  useListPublisherByRole,
} from 'redux/reducers/dsp_reducer/selectors';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  addNewUsers_advertisers_administrationAction,
  deleteUsers_advertisers_administrationAction,
  fetchUsers_advertisers_administrationListAction,
} from 'redux/reducers/users_advertisers_administration_reducer/actions';
import { useGetUsers_advertisers_administrationList } from 'redux/reducers/users_advertisers_administration_reducer/selectors';
import { useGetUsersList } from 'redux/reducers/users_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Swal from 'sweetalert2';
import Translations from '../translations';

interface IAdvertiserConnection {
  data?: any;
}

interface IFormData {
  publisherslaveid: string | null;
}

const defaultValues: IFormData = {
  publisherslaveid: null,
};

const AdvertiserConnection = (props: IAdvertiserConnection) => {
  const params = useParams();
  const id = _.get(params, 'id', null);

  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const usersList = useGetUsersList();
  const listPublisherByRole = useListPublisherByRole();
  const createDspPublisherStatus = useCreateDspPublisherStatus();
  const slavesList = useGetUsers_advertisers_administrationList();

  useEffect(() => {
    if (!props?.data) return;

    dispatch(
      fetchUsers_advertisers_administrationListAction({
        sortField: 'id',
        order: 'asc',
        page: 0,
        pageSize: 9999,
        administratorid: props?.data?.id,
      })
    );

    if (!listPublisherByRole?.data) {
      dispatch(DSPActions.fetchPublisherByRoleAction(2));
    }
  }, [props?.data]);

  const { control, errors, handleSubmit } = useForm<IFormData>({
    defaultValues,
  });

  const handleCreateConnection = (data: any) => {
    dispatch(
      addNewUsers_advertisers_administrationAction({
        administratorid: props?.data?.id,
        userid: data?.advertiser_id,
        admincommision: 0,
        fetchUsers_advertisers_administrationInput: {
          sortField: 'id',
          order: 'asc',
          page: 0,
          pageSize: 9999,
          administratorid: props?.data?.id,
        },
      })
    );
  };

  const optionListAdvertiser =
    usersList?.data
      ?.filter((u: any) => u.email != props.data.email)
      .map((item: any) => ({
        id: item?.id,
        label: item?.email,
      })) ?? [];

  return (
    <Box width={'100%'} margin={'auto'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          Slave Advertisers
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          Manage Advertiser Connection
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: 'auto',
            margin: '20px',
          }}
          onSubmit={handleSubmit(handleCreateConnection)}
        >
          <AppSelect
            variant="outlined"
            error={Boolean(_.get(errors, 'advertiser_id', false))}
            rules={{
              required: 'This is required',
            }}
            id={'advertiser_id'}
            name={'advertiser_id'}
            autoComplete={'advertiser_id'}
            label={'List advertiser'}
            control={control}
            options={optionListAdvertiser}
            style={{
              minWidth: 150,
              marginRight: 15,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            type="submit"
          >
            {createDspPublisherStatus === StateStatus.Pending ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              'Add connection'
            )}
          </Button>
        </form>

        {slavesList?.data?.items?.length ? (
          <Datatable
            loading={slavesList.status === StateStatus.Pending}
            // sorting={sorting}
            // paging={paging}
            data={slavesList?.data?.items || []}
            columns={TableColumns}
            // changePageHandle={changePageHandler}
            paperProps={{
              style: {
                maxHeight: '50vh',
                overflowY: 'auto',
              },
            }}
          />
        ) : (
          <Box
            fontSize="20px"
            borderTop="1px solid #e1e1e1"
            width="100%"
            textAlign="center"
            paddingTop="15px"
          >
            No advertisers connected
          </Box>
        )}
      </Box>
    </Box>
  );
};

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (e: any) => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUsers_advertisers_administrationAction({
            id: props?.dataid?.uaa_id,
            fetchUsers_advertisers_administrationInput: {
              sortField: 'id',
              order: 'asc',
              page: 0,
              pageSize: 9999,
              administratorid: props?.dataid?.AdministratorId,
            },
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'capitalize',
          marginRight: 10,
          backgroundColor: 'red',
        }}
      >
        Delete
      </Button>
    </React.Fragment>
  );
}

export const TableColumns = [
  {
    label: <TranslatedText textMap={Translations.id} />,
    id: 'id',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Email',
    id: 'email',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Role',
    id: 'role_name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue.join(',')}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Action',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} dataid={data[rowIndex]} />,
    inData: true,
  },
];

interface IAdvertiserConnectionModalProps {}

const AdvertiserConnectionModal = (props: IAdvertiserConnectionModalProps) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADVERTISER_CONNECTION}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAdvertiserConnection) => (
        <AdvertiserConnection {...params} />
      )}
    />
  );
};

export default AdvertiserConnectionModal;
