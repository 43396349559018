import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: '100%',
  //   // overflow: 'auto',
  // },
  // container: {
  //   height: 440,
  // },
  // visuallyHidden: {
  //   border: 0,
  //   clip: 'rect(0 0 0 0)',
  //   height: 1,
  //   margin: -1,
  //   overflow: 'hidden',
  //   padding: 0,
  //   position: 'absolute',
  //   top: 20,
  //   width: 1,
  // },  
  // cardView: {
  //   width: '100%',
  //   padding: 6,
  //   // display: 'flex',
  // },
  // listItem: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   width: '100%',
  // }
}));

export default useStyles;
