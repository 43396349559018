import { Box, Button, TextField, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import React, { useState } from 'react';
import Translations from '../translations';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useLocation, useParams } from 'react-router';
import _ from 'lodash';

const queryString = require('query-string');

export default function Header(props: any) {
  const dispatch = useDispatch();
  const { setPublisher, setCode } = props;
  const [searchPublisher, setSearchPublisher] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const { search } = useLocation();
  const currentSearch = queryString.parse(search);

  function handleChangePublisher(event: React.ChangeEvent<{ value: unknown }>) {
    const value: string = event.target.value as string;
    setSearchPublisher(value);
  }

  function handleChangeCode(event: React.ChangeEvent<{ value: unknown }>) {
    const value: string = event.target.value as string;
    setSearchCode(value);
  }

  function handleSearch() {
    if (searchPublisher !== '') {
      setPublisher(searchPublisher);
    } else {
      setPublisher('');
    }
    if (searchCode !== '') {
      setCode(searchCode);
    } else {
      setCode('');
    }
  }
  const handleCreate = () => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_EXCLUSIVE_PUBLISHER,
        params: {},
      })
    );
  };
  return (
    <Box display="flex" alignItems={'center'} fontWeight={500} padding={1}>
      <Typography variant="h5">
        {/* <TranslatedText textMap={Translations.main} /> */}
        {currentSearch?.name} Exclusivity Page
      </Typography>
      <Box flex={1} />
      {/* <TextField
        size="small"
        variant="outlined"
        label={<TranslatedText textMap={Translations.search_publisher} />}
        value={searchPublisher}
        onChange={handleChangePublisher}
      /> */}

      <Box width={'5px'} />

      {/* <TextField
        size="small"
        variant="outlined"
        label={<TranslatedText textMap={Translations.search} />}
        value={searchCode}
        onChange={handleChangeCode}
      />

      <Box width={'5px'} /> */}

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        style={{
          marginLeft: 15,
        }}
      >
        {/* <TranslatedText textMap={Translations.create} /> */}
        Add new Exclusivity
      </Button>
    </Box>
  );
}
