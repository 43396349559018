import Translations from '../../translations';

export enum AudienceSteps {
  GENDER = 0,
  AGE_GROUP = 1,
  // DEVICE = 2,
  // MOBILE_ENVIRONMENT = 3,
  // OPERATING_SYSTEM = 4,
  ADVANCED = 2,
}

export const LayoutSteps = [
  {
    id: AudienceSteps.GENDER,
    label: Translations.gender,
  },
  {
    id: AudienceSteps.AGE_GROUP,
    label: Translations.age_group,
  },
  // {
  //   id: AudienceSteps.DEVICE,
  //   label: Translations.device,
  // },
  // {
  //   id: AudienceSteps.MOBILE_ENVIRONMENT,
  //   label: Translations.mobile_environment,
  // },
  // {
  //   id: AudienceSteps.OPERATING_SYSTEM,
  //   label: Translations.operating_system,
  // },
  {
    id: AudienceSteps.ADVANCED,
    label: Translations.advanced_options,
  },
];
