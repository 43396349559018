import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Datatable from '../../../components/molecules/datatable';
import { TableColumns } from '../configuration';
import {
  useGetReportingCampaignsDetail,
  useGetSearchQuery,
} from '../../../redux/reducers/reporting_reducer/selectors';
import Actions from '../../../redux/reducers/reporting_reducer/actions';

export default function Information() {
  const reportingCampaignDetail = useGetReportingCampaignsDetail();
  const { campaignid, range } = useGetSearchQuery();

  const [data, setData] = useState([]);
  const paging = reportingCampaignDetail.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  useEffect(() => {
    if (
      reportingCampaignDetail.status === 'Success' &&
      reportingCampaignDetail.data
    ) {
      setData(reportingCampaignDetail.data.items);
    }
  }, [reportingCampaignDetail]);
  return (
    <Datatable
      height={655}
      sorting={{
        sortField: sortField.current,
        order: sortType.current,
      }}
      paging={paging}
      loading={true}
      data={data}
      columns={TableColumns}
      changePageHandle={(selectedPage, pageSize, order, _sortField) => {
        sortType.current = order;
        sortField.current = _sortField;
        dispatch(
          Actions.setPaginationParams({
            page: selectedPage,
            pageSize,
            order,
            sortField: _sortField,
          })
        );
        dispatch(
          Actions.fetchReportingCampaignsDetailAction({
            campaignid,
            clientname: '',
            fromdate: range,
            todate: '',
            enviroment: 1,
            destcountry: '',
            active: 1,
            type: '',
            page: selectedPage,
            pageSize,
            order,
            sortField: _sortField,
          })
        );
      }}
    />
  );
}
