import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router'; // import the react-router-dom components
import TranslatedText from '../../components/atoms/translated_text';
import User_Price_CalculationActions from '../../redux/reducers/user_price_calculation_reducer/actions';
import UserActions from '../../redux/reducers/users_reducer/actions';
import { useGetUsersDetails } from '../../redux/reducers/users_reducer/selectors';
import useStyles from './styles';
import DatatableList from './components/datatable';
import clsx from 'clsx';
import { TableDefaultFetchValues, Translations } from './configuration';
import User_Price_CalculationModal from './components/datatable/ViewEditData';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import _ from 'lodash';
import AirportActions from 'redux/reducers/airports_reducer/actions';
import AirlinesActions from 'redux/reducers/airlines_reducer/actions';
import AdvertisementsTypeActions from 'redux/reducers/advertisementstype_reducer/actions';

const User_Price_CalculationPage = (props: any) => {
  console.log('Render User_Price_CalculationPage');
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const id = _.get(params, 'id', null);
  const usersDetails = useGetUsersDetails();
  React.useEffect(() => {
    dispatch(
      User_Price_CalculationActions.fetchUser_Price_CalculationListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        User_id: parseInt(id),
      })
    );
    id && dispatch(UserActions.viewUsersAction({ id: parseInt(id, 10) }));
    dispatch(
      AirportActions.fetchAirportsListAction({
        sortField: 'icao',
        page: 0,
        pageSize: 9999,
        order: 'asc',
      })
    );
    dispatch(
      AdvertisementsTypeActions.fetchAdvertisementsTypeListAction({
        sortField: 'ID',
        page: 0,
        pageSize: 9999,
        order: 'asc',
      })
    );
    dispatch(
      AirlinesActions.fetchAirlinesListAction({
        sortField: 'IataId',
        page: 0,
        pageSize: 9999,
        order: 'asc',
      })
    );
  }, [dispatch]);
  return (
    <Box padding="15px">
      <User_Price_CalculationModal />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className={classes.cardLayout}>
          <Typography component={'p'} variant={'h5'}>
            <TranslatedText
              textMap={Translations.manage_registration_title}
              defaultText={Translations.manage_registration_title.English}
            />
            ({usersDetails?.data?.email ?? ''})
          </Typography>
          <Typography component={'p'} variant={'subtitle1'}>
            <TranslatedText
              textMap={Translations.manage_registration_description}
              defaultText={Translations.manage_registration_description.English}
            />
          </Typography>
        </div>
        <Button
          onClick={() =>
            dispatch(
              ModalActions.openModalAction({
                modalID: ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION,
                params: { type: 'add' },
              })
            )
          }
          variant="contained"
          color="primary"
        >
          Add new
        </Button>
      </Box>
      <DatatableList />
    </Box>
  );
};

export default withRouter(User_Price_CalculationPage);
