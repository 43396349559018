import { ResponseOutput } from 'services/api/types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchUsers_advertisers_administrationList  */
interface IUsers_advertisers_administrationListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  id: string | null;
  administratorid: string | null;
  userid: string | null;
  admincommision: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IUsers_advertisers_administrationListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  administratorid?: number;
}

export const fetchUsers_advertisers_administrationList = async (
  payload: IUsers_advertisers_administrationListInput
): Promise<ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users_advertisers_administration/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}&AdministratorId=${payload.administratorid}`
  );

  let _data: IUsers_advertisers_administrationListOutput = data.data;
  let output: ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Users_advertisers_administration Detail */
interface IUsers_advertisers_administrationDetailInput {
  id: number;
}

interface IUsers_advertisers_administrationDetailOutput extends ResponseOutput {
  data: {
    id: string | null;
    administratorid: string | null;
    userid: string | null;
    admincommision: number | null;
  };
}

export const fetchUsers_advertisers_administrationDetail = async (
  payload: IUsers_advertisers_administrationDetailInput
): Promise<IUsers_advertisers_administrationDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users_advertisers_administration/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Users_advertisers_administration */
interface IUsers_advertisers_administrationAddInput {
  administratorid: string | null;
  userid: string | null;
  admincommision: number | null;
}

interface IUsers_advertisers_administrationAddOutput extends ResponseOutput {
  data: {};
}

export const addUsers_advertisers_administration = async (
  payload: IUsers_advertisers_administrationAddInput
): Promise<IUsers_advertisers_administrationAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/users_advertisers_administration `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Users_advertisers_administration */
interface IUsers_advertisers_administrationEditInput {
  administratorid: string | null;
  userid: string | null;
  admincommision: number | null;
}

interface IUsers_advertisers_administrationEditOutput extends ResponseOutput {
  data: {};
}

export const editUsers_advertisers_administration = async (
  payload: IUsers_advertisers_administrationEditInput
): Promise<IUsers_advertisers_administrationEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/users_advertisers_administration`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

interface IUsers_advertisers_administrationDeleteInput {
  id: number | null;
}
interface IUsers_advertisers_administrationDeleteOutput extends ResponseOutput {
  data: {};
}

export const deleteUsers_advertisers_administration = async (
  payload: IUsers_advertisers_administrationDeleteInput
): Promise<IUsers_advertisers_administrationDeleteOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/users_advertisers_administration/${payload.id}`
  );
  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
