import { typeCreatorV2 } from '../../../redux/utils/common';

export const ReducerName = 'Publishers_Users_WhiteListReducer';

//  [REDUCER][TYPE]
export const SET_PUBLISHERS_USERS_WHITELIST_LIST: string = typeCreatorV2(
  ReducerName,
  'SetPublishers_Users_WhiteListList'
);

export const SET_ADD_PUBLISHERS_USERS_WHITELIST: string = typeCreatorV2(
  ReducerName,
  'SetAddPublishers_Users_WhiteList'
);

export const SET_EDIT_PUBLISHERS_USERS_WHITELIST: string = typeCreatorV2(
  ReducerName,
  'SetEditPublishers_Users_WhiteList'
);

export const SET_PUBLISHERS_USERS_WHITELIST_DETAIL: string = typeCreatorV2(
  ReducerName,
  'SetPublishers_Users_WhiteListDetail'
);

export const SET_LIST_EXCLUSIVE_BY_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'SetListExclusiveByPublisher'
);

export const SET_LIST_CATEGORY: string = typeCreatorV2(
  ReducerName,
  'SetListCategory'
);

export const SET_LIST_USER_BY_ROLE: string = typeCreatorV2(
  ReducerName,
  'SetListUserByRole'
);

export const UPDATE_EXCLUSIVE_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'UpdateExclusivePublisher'
);

export const GET_PUBLISHER_APPLICATION_BY_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'GetPublisherApplicationByPublisher'
);

export const DELETE_EXCLUSIVE_PUBLISHER: string = typeCreatorV2(
  ReducerName,
  'DeleteExclusivePublisher'
);
