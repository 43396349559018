import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
  style?: React.CSSProperties;
}
const Loading = (props: IProps) => {
  return <CircularProgress style={{ ...props.style }} />;
};

export default Loading;
