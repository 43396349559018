import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchFlightsList  */
interface IFlightsListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  code: string | null;
  flightdate: string | null;
  airline: string | null;
  deptairport: string | null;
  arrairport: string | null;
  estpassengers: number;
  actpassengers: number;
  views: number;
  clicks: number;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IFlightsListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  publisherid: string | null;
  date: string | null;
  dateto: string | null;
}

export const fetchFlightsList = async (
  payload: IFlightsListInput
): Promise<Types.ResponseOutput> => {
  const { publisherid, date, dateto } = payload;
  const publisheridQuery = publisherid ? `&publisherid=${publisherid}` : '';
  const dateQuery = date ? `&date=${date}` : '';
  const datetoQuery = dateto ? `&dateto=${dateto}` : '';

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/flights/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}${publisheridQuery}${dateQuery}${datetoQuery}`
  );

  let _data: IFlightsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
