import React from 'react';
import { useHistory, withRouter } from 'react-router';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import TabsPanel from 'components/molecules/tabs_panel';
import TranslatedText from 'components/atoms/translated_text';
import TransportTabView from 'scenes/campaign_page/components/transport_view';
// import TransportTabView from '../campaign_page/components/transport_view';
// import * as AuthSelectors from '../../redux/reducers/gl_auth_reducer/selectors';
import Actions from 'redux/reducers/transport_reducer/actions';
import {
  useGetTransport,
  useGetEditTransportStatus,
} from 'redux/reducers/transport_reducer/selectors';
import { useForm } from 'react-hook-form';
import GridLayout from 'components/molecules/grid_layout';
import { AppTextField } from 'components/molecules/form_controls';
import Translations from './translations';
import { StateStatus } from 'redux/utils/common';

interface IProps {}

interface IFormData {
  id: number | null;
  pubCode: string | null;
  code: string | null;
  lat: number | null;
  lon: number | null;
  ip: string | null;
  sim: string | null;
  serial: string | null;
  pin1: string | null;
  pin2: string | null;
  wifi: string | null;
  type: string | null;
  version: string | null;
  status: number | null;
  comments: string | null;
}

const defaultValues = {
  id: null,
  pubCode: null,
  code: null,
  lat: null,
  lon: null,
  ip: null,
  sim: null,
  serial: null,
  pin1: null,
  pin2: null,
  wifi: null,
  type: null,
  version: null,
  status: null,
  comments: null,
};

const TransportPage = (props: IProps) => {
  reactLogger.renderPage('TransportPage');
  const dispatch = useDispatch();
  const transport = useGetTransport();
  const history = useHistory();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });
  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  React.useEffect(() => {
    const transportId = getParameterByName('id');

    dispatch(Actions.fetchTransportByIdAction(transportId || ''));
  }, [dispatch]);

  React.useEffect(() => {
    reset({
      id: transport.data?.id || null,
      pubCode: transport.data?.pubCode || null,
      code: transport.data?.code || null,
      lat: transport.data?.lat || null,
      lon: transport.data?.lon || null,
      ip: transport.data?.ip || null,
      sim: transport.data?.sim || null,
      serial: transport.data?.serial || null,
      pin1: transport.data?.pin1 || null,
      pin2: transport.data?.pin2 || null,
      wifi: transport.data?.wifi || null,
      type: transport.data?.type || null,
      version: transport.data?.version || null,
      status: transport.data?.status || null,
      comments: transport.data?.comments || null,
    });
    console.log('Transport Data:', transport.data);
  }, [transport.data]);

  const onSubmit = (data: IFormData) => {
    console.log('Submit Data:', data);
    dispatch(
      Actions.editTransportAction({
        id: transport.data.id,
        pubCode: transport.data.pubCode,
        code: transport.data.code,
        lat: transport.data.lat,
        lon: transport.data.lon,
        ip: data?.ip || null,
        sim: data?.sim || null,
        serial: data?.serial || null,
        pin1: data?.pin1 || null,
        pin2: data?.pin2 || null,
        wifi: data?.wifi || null,
        type: transport.data.type,
        version: data?.version || null,
        status: data?.status || null,
        comments: data?.comments || null,
      })
    );
    history.push('/transports');
  };

  return (
    <Container maxWidth="lg">
      <Grid spacing={5} container>
        <Grid item lg={12} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
            <Card variant={'outlined'}>
              <CardHeader
                title={
                  <TranslatedText
                    defaultText={'Transport Details'}
                    textMap={Translations.transport_details}
                  />
                }
              />
              <Divider />
              <CardContent>
                <GridLayout
                  elements={[
                    {
                      id: '1',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'id' in errors}
                          helperText={errors.id && errors.id.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          disabled
                          fullWidth
                          id="id"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Id'}
                              textMap={Translations.form_field_id}
                            />
                          }
                          autoComplete="id"
                          name="id"
                        />
                      ),
                    },
                    {
                      id: '2',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'pubCode' in errors}
                          helperText={errors.pubCode && errors.pubCode.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          disabled
                          fullWidth
                          id="pubCode"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Publisher Code'}
                              textMap={Translations.form_field_pubCode}
                            />
                          }
                          autoComplete="pubCode"
                          name="pubCode"
                        />
                      ),
                    },
                    {
                      id: '3',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'code' in errors}
                          helperText={errors.code && errors.code.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          disabled
                          fullWidth
                          id="code"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Code'}
                              textMap={Translations.form_field_code}
                            />
                          }
                          autoComplete="code"
                          name="code"
                        />
                      ),
                    },
                    {
                      id: '4',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'lat' in errors}
                          helperText={errors.lat && errors.lat.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          fullWidth
                          disabled
                          id="lat"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Latitude'}
                              textMap={Translations.form_field_lat}
                            />
                          }
                          autoComplete="lat"
                          name="lat"
                          type="number"
                        />
                      ),
                    },
                    {
                      id: '5',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'lon' in errors}
                          helperText={errors.lon && errors.lon.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          fullWidth
                          disabled
                          id="lon"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Longitude'}
                              textMap={Translations.form_field_lon}
                            />
                          }
                          autoComplete="lon"
                          name="lon"
                          type="number"
                        />
                      ),
                    },

                    {
                      id: '6',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'ip' in errors}
                          helperText={errors.ip && errors.ip.message}
                          rules={{}}
                          fullWidth
                          id="ip"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Ip'}
                              textMap={Translations.form_field_ip}
                            />
                          }
                          autoComplete="ip"
                          name="ip"
                        />
                      ),
                    },
                    {
                      id: '7',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'sim' in errors}
                          helperText={errors.sim && errors.sim.message}
                          rules={{}}
                          fullWidth
                          id="sim"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Sim'}
                              textMap={Translations.form_field_sim}
                            />
                          }
                          autoComplete="sim"
                          name="sim"
                        />
                      ),
                    },
                    {
                      id: '8',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'serial' in errors}
                          helperText={errors.serial && errors.serial.message}
                          rules={{}}
                          fullWidth
                          id="serial"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Serial'}
                              textMap={Translations.form_field_serial}
                            />
                          }
                          autoComplete="serial"
                          name="serial"
                        />
                      ),
                    },
                    {
                      id: '9',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'pin1' in errors}
                          helperText={errors.pin1 && errors.pin1.message}
                          rules={{}}
                          fullWidth
                          id="pin1"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Pin 1'}
                              textMap={Translations.form_field_pin1}
                            />
                          }
                          autoComplete="pin1"
                          name="pin1"
                        />
                      ),
                    },
                    {
                      id: '10',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'pin2' in errors}
                          helperText={errors.pin2 && errors.pin2.message}
                          rules={{}}
                          fullWidth
                          id="pin2"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Pin 2'}
                              textMap={Translations.form_field_pin2}
                            />
                          }
                          autoComplete="pin2"
                          name="pin2"
                        />
                      ),
                    },
                    {
                      id: '11',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'wifi' in errors}
                          helperText={errors.wifi && errors.wifi.message}
                          rules={{}}
                          fullWidth
                          id="wifi"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Wifi'}
                              textMap={Translations.form_field_wifi}
                            />
                          }
                          autoComplete="wifi"
                          name="wifi"
                        />
                      ),
                    },
                    {
                      id: '12',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'type' in errors}
                          helperText={errors.type && errors.type.message}
                          rules={{
                            required: <RequiredField />,
                          }}
                          disabled
                          fullWidth
                          id="type"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Type'}
                              textMap={Translations.form_field_type}
                            />
                          }
                          autoComplete="type"
                          name="type"
                        />
                      ),
                    },
                    {
                      id: '13',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'version' in errors}
                          helperText={errors.version && errors.version.message}
                          rules={{}}
                          fullWidth
                          id="version"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Version'}
                              textMap={Translations.form_field_version}
                            />
                          }
                          autoComplete="version"
                          name="version"
                        />
                      ),
                    },
                    {
                      id: '14',
                      size: 6,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'status' in errors}
                          helperText={errors.status && errors.status.message}
                          rules={{}}
                          fullWidth
                          id="status"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Status'}
                              textMap={Translations.form_field_status}
                            />
                          }
                          autoComplete="status"
                          name="status"
                          type="number"
                        />
                      ),
                    },
                    {
                      id: '15',
                      size: 12,
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          error={'comments' in errors}
                          helperText={
                            errors.comments && errors.comments.message
                          }
                          rules={{}}
                          fullWidth
                          id="comments"
                          control={control}
                          label={
                            <TranslatedText
                              defaultText={'Comments'}
                              textMap={Translations.form_field_comments}
                            />
                          }
                          autoComplete="comments"
                          name="comments"
                        />
                      ),
                    },
                  ]}
                />
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <SubmitWrapper />
              </Box>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

const SubmitWrapper = React.memo(() => {
  const submitStatus = useGetEditTransportStatus();

  return (
    <Button
      type={'submit'}
      color="primary"
      variant="contained"
      disabled={submitStatus === StateStatus.Pending}
    >
      <TranslatedText
        defaultText={'Save details'}
        textMap={Translations.form_submit}
      />
    </Button>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

export default withRouter(TransportPage);
