import React from 'react';
import { Languages } from 'config';
import { RiSmartphoneFill, RiTabletFill } from 'react-icons/ri';
import { GoDeviceDesktop } from 'react-icons/go';
import Translations from '../../translations';
import { BsGrid3X3GapFill } from 'react-icons/bs';
import { FaGlobe } from 'react-icons/fa';
import { AiFillAndroid, AiFillApple } from 'react-icons/ai';

export interface IDevice {
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  image: React.ReactElement;
  code: string;
}

export const CampaignDevices: IDevice[] = [
  {
    name: Translations.smartphone_device,
    image: <RiSmartphoneFill />,
    code: 'PHONE',
  },
  {
    name: Translations.table_device,
    image: <RiTabletFill />,
    code: 'TABLET',
  },
  {
    name: Translations.desktop_device,
    image: <GoDeviceDesktop />,
    code: 'DESKTOP',
  },
];

export interface IMobileEnvironment {
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  image: React.ReactElement;
  code: string;
}

export const CampaignMobileEnvironments: IMobileEnvironment[] = [
  {
    name: Translations.me_app,
    image: <BsGrid3X3GapFill />,
    code: 'APP',
  },
  {
    name: Translations.me_web,
    image: <FaGlobe />,
    code: 'WEB',
  },
];

export interface IOperatingSystem {
  name: {
    [Languages.English]: string;
    [Languages.Greek]: string;
    [Languages.Russian]: string;
  };
  image: React.ReactElement;
  code: string;
}

export const CampaignOperatingSystems: IOperatingSystem[] = [
  {
    name: Translations.os_ios,
    image: <AiFillApple />,
    code: 'IOS',
  },
  {
    name: Translations.os_android,
    image: <AiFillAndroid />,
    code: 'ANDROID',
  },
];
