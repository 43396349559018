import { LanguagesEnum } from 'config/languages';
import { Dispatch } from 'redux';
import { listParalistAPI } from './api';
import { IAction, IListParalistFilter } from './interfaces';
import { GET_PARALISTS } from './types';

export const fetchParalists = (payload: IListParalistFilter) => async (
  dispatch: Dispatch<IAction>
) => {
  try {
    dispatch({ type: GET_PARALISTS.Pending });
    let response = await listParalistAPI(payload);
    dispatch({
      type: GET_PARALISTS.Success,
      list: response.data.items,
      paging: response.data.paging,
    });
  } catch (error) {
    dispatch({
      type: GET_PARALISTS.Failed,
      message: (error as Error).message,
    });
  }
};

const messages = {
  create_publisher_sucess: {
    [LanguagesEnum.English]: 'Publisher created',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
  update_publisher_sucess: {
    [LanguagesEnum.English]: 'Publisher updated',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
  delete_publisher_sucess: {
    [LanguagesEnum.English]: 'Publisher Deleted',
    [LanguagesEnum.Greek]: 'Τα στοιχεία μεταφορικού άλλαξαν',
    [LanguagesEnum.Russian]: 'Сведения о транспорте изменены',
  },
};
