// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** getCountries  */
interface IGetCountriesOutput {
  code: string;
  code3: string;
  name: string;
  number: string;
}

export const getCountries = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/nationality`
  );

  let _data: IGetCountriesOutput[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** get user profile  */
interface IGetUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstname: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
    address: string;
    company: string;
    phone: string;
  };
}

export const getUserProfile = async (): Promise<IGetUserProfileOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/profile`
  );

  let output: IGetUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const fetchUserDetail = async (
  user_id: number
): Promise<IGetUserProfileOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/profile?user_id=${user_id}`
  );

  let output: IGetUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** edit user profile  */
interface IEditUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstName: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
    address: string;
    company: string;
    phone: string;
  };
}

interface IEditUserProfileInput {
  email: string;
  firstName: string;
  surname: string;
  nationality: string;
  gender: string;
  birthDate: string;
  address: string;
  company: string;
  phone: string;
}

export const editUserProfile = async (
  payload: IEditUserProfileInput
): Promise<IEditUserProfileOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/profile`,
    payload
  );

  let output: IEditUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getStores  */
interface IGetStoresOutput extends Types.ResponseOutput {
  data: {
    id: string;
    code: string;
    status: string;
  }[];
}

export const getStores = async (): Promise<IGetStoresOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/stores`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const getUserList = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/get-list`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// get Users by Role

export const getUsersbyRole = async (role: number = 0): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/get-users-by-role?role=${role}`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

// get Users by Role
export const getUsersbyRoleName = async (
  roleName: string = ''
): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/get-users-by-role-name?roleName=${roleName}`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

interface IParams {
  page: number;
  pageSize: number;
  order: string;
  sortField: string;
}
export const getAllUserWithRoles = async (payload: IParams): Promise<any> => {
  const { page, pageSize, order, sortField } = payload;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/get-all-users-with-roles?page=${page}&pageSize=${pageSize}&sortField=${sortField}&order=${order}`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
