import { Languages } from '../../config';

const Translations = {
  range_option_today: {
    [Languages.English]: 'Today',
    [Languages.Greek]: 'Σήμερα',
    [Languages.Russian]: 'Сегодня',
  },
  range_option_yesterday: {
    [Languages.English]: 'Yesterday',
    [Languages.Greek]: 'Εχθές',
    [Languages.Russian]: 'Вчера',
  },
  range_option_last_month: {
    [Languages.English]: 'Last Month',
    [Languages.Greek]: 'Τον προηγούμενο μήνα',
    [Languages.Russian]: 'Прошлый месяц',
  },
  range_option_last_2_month: {
    [Languages.English]: 'Last Two Months',
    [Languages.Greek]: 'Τελευταίοι δύο μήνες',
    [Languages.Russian]: 'Последние два месяца',
  },
  range_option_last_6_month: {
    [Languages.English]: 'Last 6 Months',
    [Languages.Greek]: 'Τελευταίοι 6 μήνες',
    [Languages.Russian]: 'Последние 6 месяцев',
  },
  analytic_tab_title: {
    [Languages.English]: 'Reporting Analytic',
    [Languages.Greek]: 'Αναφορά Αναλυτική',
    [Languages.Russian]: 'отчетность Аналитическое',
  },
  information_tab_title: {
    [Languages.English]: 'Reporting Information',
    [Languages.Greek]: 'Πληροφορίες Πληροφόρησης',
    [Languages.Russian]: 'отчетность информация',
  },
  analytic_tab_title_inflight: {
    [Languages.English]: 'Reporting Analytic In Flight',
    [Languages.Greek]: 'Αναφορά αναλυτικών κατά την πτήση',
    [Languages.Russian]: 'Отчетность аналитика в полете',
  },
  information_tab_title_inflight: {
    [Languages.English]: 'Reporting Information In Flight',
    [Languages.Greek]: 'Αναφορά πληροφοριών κατά την πτήση',
    [Languages.Russian]: 'Сообщение информации в полете',
  },
  impression: {
    [Languages.English]: 'Impressions',
    [Languages.Greek]: 'εντυπώσεις',
    [Languages.Russian]: 'Впечатления',
  },
  click: {
    [Languages.English]: 'Clicks',
    [Languages.Greek]: 'κλικ',
    [Languages.Russian]: 'щелчки',
  },
  ctr_widget_description: {
    [Languages.English]: 'The CTR (click-through rate) is the number of clicks that your advert received divided by the number of times your advert is shown.',
    [Languages.Greek]: 'Το CTR (αναλογία κλικ προς αριθμό εμφανίσεων) είναι ο αριθμός των κλικ που έλαβε η διαφήμισή σας δια του αριθμού των φορών που εμφανίζεται η διαφήμισή σας.',
    [Languages.Russian]: 'CTR (рейтинг кликов) - это количество кликов, полученных вашим объявлением, разделенное на количество его показов.',
  },
  click_widget_description: {
    [Languages.English]: 'To total number of times users clicked on your advert ',
    [Languages.Greek]: 'Synoliká, oi chrístes ékanan klik sti diafímisí sas',
    [Languages.Russian]: 'Общее количество раз, когда пользователи нажимали на ваше объявление',
  },
  impression_widget_description: {
    [Languages.English]: 'The total number of times your advert was shown on the users’ device ',
    [Languages.Greek]: 'Ο συνολικός αριθμός εμφανίσεων της διαφήμισής σας στη συσκευή των χρηστών',
    [Languages.Russian]: 'Общее количество раз, когда ваша реклама была показана на устройстве пользователей.',
  },
  ctr_widget: {
    [Languages.English]: 'CTR',
    [Languages.Greek]: 'CTR',
    [Languages.Russian]: 'CTR',
  },
  impression_widget: {
    [Languages.English]: 'Impressions',
    [Languages.Greek]: 'εντυπώσεις',
    [Languages.Russian]: 'Впечатления',
  },
  click_widget: {
    [Languages.English]: 'Clicks',
    [Languages.Greek]: 'κλικ',
    [Languages.Russian]: 'щелчки',
  },
  campagin_search: {
    [Languages.English]: 'Campaigns',
    [Languages.Greek]: 'εκστρατείες',
    [Languages.Russian]: 'Кампании',
  },
  range_search: {
    [Languages.English]: 'Range',
    [Languages.Greek]: 'Εύρος',
    [Languages.Russian]: 'Диапазон',
  },
  action_search: {
    [Languages.English]: 'Actions',
    [Languages.Greek]: 'Ενέργειες',
    [Languages.Russian]: 'действия',
  },
  button_search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
};

export default Translations;
