import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    listItem: {
      padding: '0.5rem !important',
    },
    listItemIcon: {
      minWidth: '0px !important',
      margin: '0.3rem',
    },
    listItemText: {
      marginLeft: '0.4rem !important',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    active: {
      color: theme.palette.primary.main,
    },
  })
);

export default useStyles;
