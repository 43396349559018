import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  ButtonProps,
} from '@material-ui/core';
import * as UserProfileSelectors from '../../store/selectors';
import GridLayout from '../../../../components/molecules/grid_layout';
import {
  AppDatePicker,
  AppRadioGroup,
  AppTextField,
  AppSelect,
} from '../../../../components/molecules/form_controls';
import TranslatedText from '../../../../components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import Translations from '../../translations';
import { StateStatus } from '../../../../redux/utils/common';
import { Gender } from '../../../../config';
import CountriesSelect from './FormCountriesSelect';
import { useDispatch } from 'react-redux';
import UserProfileActions from '../../store/actions';
import { ReducerKeys } from 'redux/config';
import withReducer from 'redux/withReducer';

const defaultValues = {
  firstName: null,
  surname: null,
  email: null,
  gender: null,
  nationality: null,
  registeredDate: null,
  role: null,
  birthDate: null,
  address: null,
  company: null,
  phone: null,
};

interface IFormData {
  firstName: string | null;
  surname: string | null;
  email: string | null;
  gender: string | null;
  nationality: string | null;
  registeredDate: string | null;
  role: string | null;
  birthDate: string | null;
  address: string | null;
  company: string | null;
  phone: string | null;
}

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = () => {
  console.log('Render ProfileDetails:');
  const classes = useStyles();
  const dispatch = useDispatch();
  const userDetailsData = UserProfileSelectors.useGetUserProfile();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });

  // console.log('Data:', userDetailsData.data);

  React.useEffect(() => {
    if (userDetailsData.status === StateStatus.Success) {
      reset({
        firstName: userDetailsData.data?.firstName || '',
        surname: userDetailsData.data?.surname || '',
        email: userDetailsData.data?.email || '',
        gender: userDetailsData.data?.gender || null,
        nationality: userDetailsData.data?.nationality || null,
        role: userDetailsData.data?.role || '',
        birthDate: userDetailsData.data?.birthDate || null,
        registeredDate: userDetailsData.data?.registeredDate || null,
        address: userDetailsData.data?.address || null,
        company: userDetailsData.data?.company || null,
        phone: userDetailsData.data?.phone || null,
      });
      // console.log('Profile Data:', userDetailsData.data);
    }
  }, [userDetailsData.status]);

  const onSubmit = (data: IFormData) => {
    console.log('Data:', data);
    dispatch(
      UserProfileActions.editUserProfileAction({
        email: data.email || '',
        firstName: data.firstName || '',
        surname: data.surname || '',
        nationality: data.nationality || '',
        gender: data.gender || '',
        birthDate: data.birthDate || '',
        address: data.address || '',
        company: data.company || '',
        phone: data.phone || '',
      })
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className={clsx(classes.root)}
    >
      <Card variant={'outlined'}>
        <CardHeader
          subheader="The information can be edited"
          title={
            <TranslatedText
              defaultText={'Details'}
              textMap={Translations.tab_details}
            />
          }
        />
        <Divider />
        <CardContent>
          <GridLayout
            elements={[
              {
                id: '1',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'firstName' in errors}
                    helperText={errors.firstName && errors.firstName.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="firstName"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Firstname'}
                        textMap={Translations.form_field_firstname}
                      />
                    }
                    autoComplete="firstName"
                    name="firstName"
                  />
                ),
              },
              {
                id: '2',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'surname' in errors}
                    helperText={errors.surname && errors.surname.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="surname"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Surname'}
                        textMap={Translations.form_field_surname}
                      />
                    }
                    autoComplete="surname"
                    name="surname"
                  />
                ),
              },
              {
                id: '3',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'email' in errors}
                    helperText={errors.email && errors.email.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    disabled
                    fullWidth
                    id="email"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Email'}
                        textMap={Translations.form_field_email}
                      />
                    }
                    autoComplete="email"
                    name="email"
                    type="email"
                  />
                ),
              },
              {
                id: '4',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'company' in errors}
                    helperText={errors.company && errors.company.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    fullWidth
                    id="company"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Company'}
                        textMap={Translations.form_field_company}
                      />
                    }
                    autoComplete="company"
                    name="company"
                  />
                ),
              },

              {
                id: '5',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'phone' in errors}
                    helperText={errors.phone && errors.phone.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="phone"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Phone'}
                        textMap={Translations.form_field_phone}
                      />
                    }
                    autoComplete="phone"
                    name="phone"
                  />
                ),
              },
              {
                id: '6',
                size: 6,
                element: (
                  <CountriesSelect
                    control={control}
                    error={'nationality' in errors}
                    name="nationality"
                    helperText={
                      errors.nationality && errors.nationality.message
                    }
                    rules={{
                      required: 'Required Field',
                    }}
                    label={
                      <TranslatedText
                        defaultText={'Nationality'}
                        textMap={Translations.form_field_nationality}
                      />
                    }
                  />
                ),
              },
              {
                id: '7',
                size: 12,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'address' in errors}
                    helperText={errors.address && errors.address.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="address"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Address'}
                        textMap={Translations.form_field_address}
                      />
                    }
                    autoComplete="address"
                    name="address"
                  />
                ),
              },

              // {
              //   id: '4',
              //   size: 6,
              //   element: (
              //     <CountriesSelect
              //       control={control}
              //       error={'nationality' in errors}
              //       name="nationality"
              //       helperText={
              //         errors.nationality && errors.nationality.message
              //       }
              //       rules={{
              //         required: 'Required Field',
              //       }}
              //       label={
              //         <TranslatedText
              //           defaultText={'Nationality'}
              //           textMap={Translations.form_field_nationality}
              //         />
              //       }
              //     />
              //   ),
              // },
              // {
              //   id: '5',
              //   size: 6,
              //   element: (
              //     <AppTextField
              //       variant="outlined"
              //       margin="normal"
              //       error={'role' in errors}
              //       helperText={errors.role && errors.role.message}
              //       rules={{}}
              //       fullWidth
              //       disabled={true}
              //       id="role"
              //       control={control}
              //       label={
              //         <TranslatedText
              //           defaultText={'Role'}
              //           textMap={Translations.form_field_role}
              //         />
              //       }
              //       autoComplete="role"
              //       name="role"
              //     />
              //   ),
              // },
              // {
              //   id: '6',
              //   size: 6,
              //   element: (
              //     <AppDatePicker
              //       fullWidth
              //       margin="normal"
              //       label={
              //         <TranslatedText
              //           defaultText={'Birth Date'}
              //           textMap={Translations.form_field_birthdate}
              //         />
              //       }
              //       autoOk
              //       helperText={errors.birthDate && errors.birthDate.message}
              //       error={'birthDate' in errors}
              //       control={control}
              //       rules={{
              //         required: <RequiredField />,
              //       }}
              //       name="birthDate"
              //     />
              //   ),
              // },
              // {
              //   id: '7',
              //   size: 6,
              //   element: (
              //     <AppDatePicker
              //       fullWidth
              //       margin="normal"
              //       disabled={true}
              //       label={
              //         <TranslatedText
              //           defaultText={'Registered Date'}
              //           textMap={Translations.form_field_registereddate}
              //         />
              //       }
              //       autoOk
              //       helperText={
              //         errors.registeredDate && errors.registeredDate.message
              //       }
              //       error={'registeredDate' in errors}
              //       control={control}
              //       rules={{
              //         required: false,
              //       }}
              //       name="registeredDate"
              //     />
              //   ),
              // },
              // {
              //   id: '8',
              //   size: 6,
              //   element: (
              //     <AppRadioGroup
              //       row
              //       helperText={errors.gender && errors.gender.message}
              //       error={'gender' in errors}
              //       control={control}
              //       rules={{
              //         required: <RequiredField />,
              //       }}
              //       values={Gender.GenderConfig}
              //       name="gender"
              //       label={
              //         <TranslatedText
              //           defaultText={'Gender'}
              //           textMap={Translations.form_field_gender}
              //         />
              //       }
              //     />
              //   ),
              // },
            ]}
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <SubmitWrapper />
        </Box>
      </Card>
    </form>
  );
};

const SubmitWrapper = React.memo(() => {
  const submitStatus = UserProfileSelectors.useGetEditUserStatus();

  return (
    <Button
      type={'submit'}
      color="primary"
      variant="contained"
      disabled={submitStatus === StateStatus.Pending}
    >
      <TranslatedText
        defaultText={'Save details'}
        textMap={Translations.form_submit}
      />
    </Button>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
