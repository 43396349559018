import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#FFF',
    opacity: 0.6,
    // width: 30,
    // height: 30,
  },
}));

export default useStyles;
