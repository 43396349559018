import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import ImageUpload from 'components/app_common/image_upload';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import { Environment } from 'config';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AdvertisementsTypeActions from 'redux/reducers/advertisementstype_reducer/actions';
import {
  useAddAdvertisementsTypeStatus,
  useEditAdvertisementsTypeStatus,
} from 'redux/reducers/advertisementstype_reducer/selectors';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { StateStatus } from 'redux/utils/common';
import * as StringUtils from 'utils/strings';
import { EContentType, TableDefaultFetchValues } from '../configuration';

interface IAddEditAdvertisementType {
  data?: any;
}

interface IFormData {
  name: string | null;
  description: string | null;
  contentype: string | null;
  width: number | null;
  height: number | null;
  imageurl: string | null;
}

const defaultValues: IFormData = {
  name: null,
  description: null,
  contentype: null,
  width: null,
  height: null,
  imageurl: null,
};

const AddEditAdvertisementType = (props: IAddEditAdvertisementType) => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [valuePicture, setValuePicture] = useState();
  const addAdvertisementsTypeStatus = useAddAdvertisementsTypeStatus();
  const editAdvertisementsTypeStatus = useEditAdvertisementsTypeStatus();
  const { control, errors, handleSubmit, reset } = useForm<IFormData>({
    defaultValues,
  });
  useEffect(() => {
    if (props?.data) {
      reset({
        name: props.data?.Name,
        description: props.data?.Description,
        contentype: props.data?.Contentype,
        width: props.data?.Width,
        height: props.data?.Height,
        imageurl: props.data?.imageurl,
      });
    }
  }, [props?.data]);
  const handleCreateEditConnection = (data: any) => {
    if (props?.data) {
      dispatch(
        AdvertisementsTypeActions.editAdvertisementsTypeAction({
          ...data,
          id: props.data?.ID,
          imageurl: valuePicture ?? props.data?.imageurl,
          fetchAdvertisementsTypeInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    } else {
      dispatch(
        AdvertisementsTypeActions.addNewAdvertisementsTypeAction({
          ...data,
          imageurl: valuePicture ?? '',
          fetchAdvertisementsTypeInput: {
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          },
        })
      );
    }
  };
  const optionListContentType = [
    {
      id: EContentType.billboard,
      label: StringUtils.capitalizeFirstLetter(
        EContentType.billboard.toString()
      ),
    },
    {
      id: EContentType.image,
      label: StringUtils.capitalizeFirstLetter(EContentType.image.toString()),
    },
    {
      id: EContentType.survey,
      label: StringUtils.capitalizeFirstLetter(EContentType.survey.toString()),
    },
    {
      id: EContentType.track,
      label: StringUtils.capitalizeFirstLetter(EContentType.track.toString()),
    },
    {
      id: EContentType.video,
      label: StringUtils.capitalizeFirstLetter(EContentType.video.toString()),
    },
  ];
  const onCloseModal = () => {
    dispatch(
      ModalActions.closeModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE,
      })
    );
  };
  const setValueImage = (key: string, value: any) => {
    setValuePicture(value);
  };
  return (
    <Box width={'100%'} margin={'auto'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        >
          {props.data ? 'Edit' : 'Create'} advertisement type
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          Make modification and then hit save
        </Typography>
        <form
          style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            margin: '20px',
            flexWrap: 'wrap',
          }}
          onSubmit={handleSubmit(handleCreateEditConnection)}
        >
          <AppTextField
            variant="outlined"
            margin="dense"
            error={Boolean(_.get(errors, 'name', false))}
            rules={{
              required: 'This is required',
            }}
            fullWidth
            size="small"
            id={'name'}
            control={control}
            label={<TranslatedText defaultText={'Name'} />}
            autoComplete={'name'}
            name={'name'}
          />
          <AppTextField
            variant="outlined"
            margin="dense"
            error={Boolean(_.get(errors, 'description', false))}
            rules={
              {
                // required: <RequiredField />,
              }
            }
            fullWidth
            size="small"
            id={'description'}
            control={control}
            label={<TranslatedText defaultText={'Description'} />}
            autoComplete={'description'}
            name={'description'}
          />

          <Box display="flex" width="100%" gridGap="15px" marginBottom="15px">
            <AppTextField
              variant="outlined"
              margin="dense"
              error={Boolean(_.get(errors, 'width', false))}
              rules={{
                required: 'This is required',
              }}
              fullWidth
              size="small"
              id={'width'}
              control={control}
              label={<TranslatedText defaultText={'Width'} />}
              autoComplete={'width'}
              name={'width'}
              type="number"
            />
            <AppTextField
              variant="outlined"
              margin="dense"
              error={Boolean(_.get(errors, 'height', false))}
              rules={{
                required: 'This is required',
              }}
              fullWidth
              size="small"
              id={'height'}
              control={control}
              label={<TranslatedText defaultText={'Height'} />}
              autoComplete={'height'}
              name={'height'}
              type="number"
            />
          </Box>

          <AppSelect
            variant="outlined"
            error={Boolean(_.get(errors, 'contentype', false))}
            rules={{
              required: 'This is required',
            }}
            options={optionListContentType}
            id={'contentype'}
            control={control}
            label={'Content Type'}
            autoComplete={'contentype'}
            name={'contentype'}
            style={{
              minWidth: 150,
              marginTop: 6,
              marginBottom: 6,
            }}
          />
          <Box minWidth="100%" margin="15px 0">
            <ImageUpload
              name={'imageurl'}
              setValue={setValueImage}
              linkPicture={
                props?.data?.imageurl
                  ? `${Environment.API_BASE_URL}${props?.data?.imageurl}`
                  : undefined
              }
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'capitalize',
                marginRight: 10,
              }}
              type="submit"
              disabled={
                addAdvertisementsTypeStatus === StateStatus.Pending ||
                editAdvertisementsTypeStatus === StateStatus.Pending
              }
            >
              {addAdvertisementsTypeStatus === StateStatus.Pending ||
              editAdvertisementsTypeStatus === StateStatus.Pending ? (
                <CircularProgress size={24} style={{ color: '#fff' }} />
              ) : (
                `${props.data ? 'Save' : 'Add new'}`
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              style={{
                textTransform: 'capitalize',
                marginRight: 10,
              }}
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

interface IAddEditAdvertisementTypeModalProps {}
const AddEditAdvertisementTypeModal = (
  props: IAddEditAdvertisementTypeModalProps
) => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_ADVERTISEMENT_TYPE}
      showExitIcon={false}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IAddEditAdvertisementType) => (
        <AddEditAdvertisementType {...params} />
      )}
    />
  );
};

export default AddEditAdvertisementTypeModal;
