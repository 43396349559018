import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import { OptionsGraphType1 } from '../configuration';

export interface IBarChartOption {
  data: any[];
  labels: any[];
  title: string;
}

function getBarChartConfig(params: IBarChartOption) {
  const { data, labels, title } = params;
  const DashboardBuilderConfigClickDestination: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      // height: 300,
      props: {
        data: [
          {
            data,
            label: title,
            format: {
              label: title,
              backgroundColor: '#439CDD',
            },
          },
        ],
        labels,
        chartType: ChartTypesEnum.COLUMN,
        options: OptionsGraphType1(100),
        title: '',
      },
      fetch: null,
    },
  ];
  return DashboardBuilderConfigClickDestination;
}
export default getBarChartConfig;
