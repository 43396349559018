import React from 'react';
import clsx from 'clsx';
import ProgressBar from '../ProgressBar';
import useStyles from '../../styles';
import { Typography } from '@material-ui/core';
import { mapping } from '../../country-latlon';

function getCountryNameFromCode(code: string) {
  const country = mapping.find((item: any) => item.alpha2 === code);
  if (country) {
    return country.country;
  } else {
    return 'Other';
  }
}

export default function Progress(props: any) {
  const classes = useStyles();
  const validCountries = props.data.filter((item: any) =>  getCountryNameFromCode(item.name) !== 'Other')
  const invalidCountries = props.data.filter((item: any) =>  getCountryNameFromCode(item.name) === 'Other')
  const data = [...validCountries, ...invalidCountries]

  return (
    <div>
      <Typography className={clsx(classes.mb24, classes.textBold)} variant="h5">
        {props.type}
      </Typography>
      {data.map((bar: any, index: number) => (
        <ProgressBar
          name={getCountryNameFromCode(bar.name)}
          key={index}
          value={bar.value}
          percentage={bar.percentage}
        />
      ))}
    </div>
  );
}
