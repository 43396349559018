import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_AIRLINES_LIST: string = typeCreatorV2(
  'AirlinesReducer',
  'SetAirlinesList'
);

export const SET_ADD_AIRLINES: string = typeCreatorV2(
  'AirlinesReducer',
  'SetAddAirlines'
);

export const SET_EDIT_AIRLINES: string = typeCreatorV2(
  'AirlinesReducer',
  'SetEditAirlines'
);

export const SET_AIRLINES_DETAIL: string = typeCreatorV2(
  'AirlinesReducer',
  'SetAirlinesDetail'
);
