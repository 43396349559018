export enum RegistrationStatus {
  TEMPORARY = 0,
  SUBMITTED = 1,
  APPROVED = 2,
  REJECTED = 3,
  EXCL = 4,
}

export enum RegistrationState {
  ACTIVE = 'A',
  NEW = 'N',
  UPDATE_REQUEST = 'U',
  HISTORY = 'H',
  EXCLUSION_REQUEST = 'E',
}
