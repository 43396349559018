import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import CountryRateActions from 'redux/reducers/countriesrate_reducer/actions';
import CountryActions from 'redux/reducers/countries_reducer/actions';
import { fetchParalists } from 'redux/reducers/paralist_reducer/actions';
import Header from './components/Header';
import { TableColumns, TableDefaultFetchValues } from './configuration';
import AddEditCountryRateModal from './components/AddEditCountryRateModal';
import { StateStatus } from 'redux/utils/common';
import { useGetCountriesRateList } from 'redux/reducers/countriesrate_reducer/selectors';
import { useGetCountriesList } from 'redux/reducers/countries_reducer/selectors';

const CountryRatePage = () => {
  const { data, status } = useGetCountriesRateList();
  const dispatch = useDispatch();
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'asc',
  });
  const { data: dataCountries } = useGetCountriesList();

  useEffect(() => {
    dispatch(
      CountryRateActions.fetchCountriesRateListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
    dispatch(
      CountryActions.fetchCountriesListAction({
        sortField: 'code',
        page: 0,
        pageSize: 9999,
        order: 'asc',
      })
    );
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(
      CountryRateActions.fetchCountriesRateListAction({
        sortField,
        order,
        page: selectedPage,
        pageSize,
      })
    );
  };
  const dataList =
    data?.items?.map((item) => ({
      ...item,
      FromCountryLabel: dataCountries?.items?.find(
        (val) => val?.code === item.FromCountry
      )?.name,
      ToCountryLabel: dataCountries?.items?.find(
        (val) => val?.code === item.ToCountry
      )?.name,
    })) || [];
  console.log('dataList', dataList);
  return (
    <>
      <Box height={'4px'} />
      {status === StateStatus.Pending ? (
        <LinearProgress color="primary" />
      ) : (
        <Box height={'4px'} />
      )}
      <Header />
      <Datatable
        loading={status === StateStatus.Pending}
        sorting={sorting}
        paging={data?.paging}
        data={dataList}
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <AddEditCountryRateModal />
    </>
  );
};

export default withRouter(CountryRatePage);
