export const formatNumberWithCommas = (
  n: number | null | undefined
): string => {
  let val = Math.round(Number(n) * 100) / 100;
  let parts = val.toString().split('.');
  let num =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? '.' + parts[1] : '');
  return num;
};

export const formatNumberDigits = (
  num: number | null | undefined,
  digits: number = 2
): string => (typeof num === 'number' ? num.toFixed(digits) : '');
