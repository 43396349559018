import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchCountriesRateList  */
interface ICountriesRateListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  id: number;
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number;
  rate: string | null;
  duration: number;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface ICountriesRateListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

export const fetchCountriesRateList = async (
  payload: ICountriesRateListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/countriesrate/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: ICountriesRateListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** CountriesRate Detail */
interface ICountriesRateDetailInput {
  id: number;
}

interface ICountriesRateDetailOutput extends Types.ResponseOutput {
  data: {
    id: number | null;
    fromcountry: string | null;
    tocountry: string | null;
    passengers: number | null;
    rate: number | null;
    duration: number | null;
  };
}

export const fetchCountriesRateDetail = async (
  payload: ICountriesRateDetailInput
): Promise<ICountriesRateDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/countriesrate/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add CountriesRate */
interface ICountriesRateAddInput {
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number | null;
  rate: number | null;
  duration: number | null;
}

interface ICountriesRateAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addCountriesRate = async (
  payload: ICountriesRateAddInput
): Promise<ICountriesRateAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/countriesrate `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit CountriesRate */
interface ICountriesRateEditInput {
  id: number | null;
  fromcountry: string | null;
  tocountry: string | null;
  passengers: number | null;
  rate: number | null;
  duration: number | null;
}

interface ICountriesRateEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editCountriesRate = async (
  payload: ICountriesRateEditInput
): Promise<ICountriesRateEditOutput> => {
  const { id, ...rest } = payload;
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/countriesrate/${id}`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Delete Detail */
interface IDeleteDetailInput {
  id: number;
}

interface IDeleteDetailOutput extends Types.ResponseOutput {}

export const deleteCountryRate = async (
  payload: IDeleteDetailInput
): Promise<IDeleteDetailOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/countriesrate/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
