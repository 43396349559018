import _ from 'lodash';
import moment from 'moment';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

function colorGenerator() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

function createLineDataset(data: any[], selectField: string) {
  const sortedDeptAirport = data
    .sort(function (a: any, b: any) {
      return a[selectField] - b[selectField];
    })
    .map((item: any) => item[selectField]);
  const visitingTime = data
    .sort(function (a: any, b: any) {
      return a[selectField] - b[selectField];
    })
    .map((item: any) => item.visitingtime);
  const visits = data
    .sort(function (a: any, b: any) {
      return a[selectField] - b[selectField];
    })
    .map((item: any) => item.visits);

  const result = [
    { id: 'visiting time (sec)', data: visitingTime },
    { id: 'visits', data: visits },
  ].map((line: any) => {
    const color = colorGenerator();

    return {
      label: line.id,
      data: line.data,
      format: {
        label: line.id,
        fill: false,
        borderColor: color,
        lineTension: 0.1, // make the lines without radius
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
      },
    };
  });

  const dataSet = {
    labels: sortedDeptAirport,
    data: result,
  };
  return dataSet;
}

function getLineChartConfig(data: any, type: string, xAxis: string) {
  const dataSet = createLineDataset(data, xAxis);

  const LineChartsConfig: IGridConf[] = [
    {
      width: 12,
      type: ComponentTypeEnum.chart,
      props: {
        data: dataSet.data,
        labels: dataSet.labels,
        chartType: ChartTypesEnum.LINE,
        options: {
          legend: {
            display: true,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
          },
        },
        title: `Tracking by ${type}`,
      },
      fetch: null,
    },
  ];
  return LineChartsConfig;
}

export default getLineChartConfig;
