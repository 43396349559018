import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import {
  addUsers_advertisers_administration,
  deleteUsers_advertisers_administration,
  editUsers_advertisers_administration,
  fetchUsers_advertisers_administrationDetail,
  fetchUsers_advertisers_administrationList,
} from './api';

/** Fetch FetchUsers_advertisers_administrationListInput  */

interface FetchUsers_advertisers_administrationListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  administratorid?: number;
}

class FetchUsers_advertisers_administrationList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUsers_advertisers_administrationListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await fetchUsers_advertisers_administrationList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'FetcUsers_advertisers_administrationList Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewUsers_advertisers_administration  */

interface AddNewUsers_advertisers_administrationInput {
  id?: string | null;
  administratorid: string | null;
  userid: string | null;
  admincommision: number | null;
  fetchUsers_advertisers_administrationInput: FetchUsers_advertisers_administrationListInput;
}

class AddNewUsers_advertisers_administration implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewUsers_advertisers_administrationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUsers_advertisers_administrationInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await addUsers_advertisers_administration(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUsers_advertisers_administrationList().action(
            fetchUsers_advertisers_administrationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
          })
        );
      } catch (error) {
        console.log(
          'AddNewUsers_advertisers_administration Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Users_advertisers_administration  */

interface EditUsers_advertisers_administrationInput {
  id: string | null;
  administratorid: string | null;
  userid: string | null;
  admincommision: number | null;
  fetchUsers_advertisers_administrationInput: FetchUsers_advertisers_administrationListInput;
}

class EditUsers_advertisers_administration implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditUsers_advertisers_administrationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchUsers_advertisers_administrationInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await editUsers_advertisers_administration(payload);
        dispatch(this.onSuccess(response.data));

        dispatch(
          new FetchUsers_advertisers_administrationList().action(
            fetchUsers_advertisers_administrationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
          })
        );
      } catch (error) {
        console.log('EditNotification Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Users_advertisers_administration  */

interface ViewUsers_advertisers_administrationInput {
  id: number;
}

class ViewUsers_advertisers_administration implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_ADVERTISERS_ADMINISTRATION_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewUsers_advertisers_administrationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await fetchUsers_advertisers_administrationDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log(
          'ViewUsers_advertisers_administration Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface DeleteUsers_advertisers_administrationInput {
  id: number;
  fetchUsers_advertisers_administrationInput: FetchUsers_advertisers_administrationListInput;
}

class DeleteUsers_advertisers_administration implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DELETE_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DELETE_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DELETE_USERS_ADVERTISERS_ADMINISTRATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteUsers_advertisers_administrationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        let response = await deleteUsers_advertisers_administration({
          id: payload.id,
        });

        dispatch(this.onSuccess(response.data));

        dispatch(
          new FetchUsers_advertisers_administrationList().action(
            payload.fetchUsers_advertisers_administrationInput
          )
        );

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_USERS_ADVERTISERS_ADMINISTRATION,
          })
        );
      } catch (error) {
        console.log(
          'DeleteUsers_advertisers_administration Error:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export const fetchUsers_advertisers_administrationListAction = (
  payload: FetchUsers_advertisers_administrationListInput
) => new FetchUsers_advertisers_administrationList().action(payload);

export const addNewUsers_advertisers_administrationAction = (
  payload: AddNewUsers_advertisers_administrationInput
) => new AddNewUsers_advertisers_administration().action(payload);

export const editUsers_advertisers_administrationAction = (
  payload: EditUsers_advertisers_administrationInput
) => new EditUsers_advertisers_administration().action(payload);

export const viewUsers_advertisers_administrationAction = (
  payload: ViewUsers_advertisers_administrationInput
) => new ViewUsers_advertisers_administration().action(payload);

export const deleteUsers_advertisers_administrationAction = (
  payload: DeleteUsers_advertisers_administrationInput
) => new DeleteUsers_advertisers_administration().action(payload);
