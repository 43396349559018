import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Card, CardContent, Tooltip } from '@material-ui/core';
import { RestoreOutlined } from '@material-ui/icons';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
  AppDatePicker,
  PopOverDatePicker,
} from '../../../components/molecules/form_controls';
import GridLayout from '../../../components/molecules/grid_layout';

import { useForm, useFieldArray, Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetUserList } from '../../../redux/reducers/adv_dashboard_reducer/selectors';
import Actions from '../../../redux/reducers/adv_dashboard_reducer/actions';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import { useSelectUserRoles } from '../../../redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';
import useStyles from '../styles';
import { CampaignEnv } from 'config/campaign_environemnt';

interface IFormData {
  userid: string | null;
  datefrom: string | null;
  dateto: string | null;
  // width: number;
  // genericChartType: GenericChartTypes;
}
const defaultValues: IFormData = {
  userid: null,
  datefrom: null,
  dateto: null,
};

export default function SearchArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userOption, setUserOption] = useState([]);
  const userList = useGetUserList();
  const roles = useSelectUserRoles();

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList.data.map((item: any) => {
          return {
            id: item.id,
            label: item.Email,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  useEffect(() => {
    const userid = getParameterByName('userid');
    const datefrom = getParameterByName('datefrom');
    const dateto = getParameterByName('dateto');
    reset({ userid, datefrom, dateto });
  }, []);
  function handleReset() {
    reset(defaultValues);
    setUrlParams(defaultValues);
    dispatch(
      Actions.fetchCampaignsStatsAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchCountryClickViewAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchTotalImpressionClickByCampaignAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchTotalViewByArrCountryAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchTotalClickByArrCountryAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchDeviceClickViewAction({
        userid: null,
        datefrom: null,
        dateto: null,
        enviromentworlds: CampaignEnv.Online,
      })
    );
    dispatch(
      Actions.fetchTotalCountryClickAction({
        userid: null,
        datefrom: null,
        dateto: null,
      })
    );
    dispatch(
      Actions.fetchTotalCountryViewAction({
        userid: null,
        datefrom: null,
        dateto: null,
      })
    );
  }
  function setUrlParams(data: IFormData) {
    const { userid, datefrom, dateto } = data;
    const userQuery = userid ? `userid=${userid}&` : '';
    const datefromQuery = datefrom ? `datefrom=${datefrom}&` : '';
    const datetoQuery = dateto ? `dateto=${dateto}` : '';
    window.history.pushState(
      '',
      '',
      `/adv-dashboard?${userQuery}${datefromQuery}${datetoQuery}`
    );
  }
  const onSubmit = (_data: IFormData) => {
    let { userid, datefrom, dateto } = _data;
    datefrom = datefrom ? moment(datefrom).format('YYYY-MM-DD') : null;
    dateto = dateto ? moment(dateto).format('YYYY-MM-DD') : null;
    setUrlParams({ userid, datefrom, dateto });

    dispatch(
      Actions.fetchCampaignsStatsAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchCountryClickViewAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTop5CampaignAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalImpressionClickByCampaignAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalViewByArrCountryAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalClickByArrCountryAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchDeviceClickViewAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalCountryClickAction({
        userid,
        datefrom,
        dateto,
      })
    );
    dispatch(
      Actions.fetchTotalCountryViewAction({
        userid,
        datefrom,
        dateto,
      })
    );
  };
  return (
    <Card>
      <CardContent>
        <form
          // className={classes.search}
          onSubmit={handleSubmit(onSubmit)}
        >
          <GridLayout
            elements={[
              {
                id: '1',
                size: 3,
                element: (
                  <React.Fragment>
                    {roles.includes(Roles.Administrator) ? (
                      <AppSelect
                        variant="outlined"
                        error={Boolean(_.get(errors, 'userid', false))}
                        helperText={_.get(errors, 'userid', '')}
                        //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                        rules={
                          {
                            // required: <RequiredField />,
                          }
                        }
                        fullWidth
                        options={userOption}
                        id={'userid'}
                        control={control}
                        label={
                          <TranslatedText textMap={Translations.user_search} />
                        }
                        autoComplete={'userid'}
                        name={'userid'}
                      />
                    ) : null}
                  </React.Fragment>
                ),
              },
              {
                id: '2',
                size: 3,
                element: (
                  <PopOverDatePicker
                    error={Boolean(_.get(errors, 'datefrom', false))}
                    helperText={_.get(errors, 'datefrom', '')}
                    //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    id={'datefrom'}
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.date_from_search} />
                    }
                    autoComplete={'datefrom'}
                    name={'datefrom'}
                  />
                ),
              },
              {
                id: '3',
                size: 3,
                element: (
                  <PopOverDatePicker
                    error={Boolean(_.get(errors, 'dateto', false))}
                    helperText={_.get(errors, 'dateto', '')}
                    //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    id={'dateto'}
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.date_to_search} />
                    }
                    autoComplete={'dateto'}
                    name={'dateto'}
                  />
                ),
              },
              {
                id: '4',
                size: 3,
                element: (
                  <div
                    className={clsx(classes.flex, classes.itemsCenter)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 55.68,
                    }}
                  >
                    <Button
                      className={classes['mr-12']}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {<TranslatedText textMap={Translations.button_search} />}
                    </Button>
                    <Tooltip title="Refresh result" placement="right">
                      <Button
                        onClick={handleReset}
                        variant="contained"
                        color="primary"
                      >
                        <RestoreOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
          />
        </form>
      </CardContent>
    </Card>
  );
}
