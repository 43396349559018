import L from 'leaflet';
import _, { isArray } from 'lodash';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  MapContainer,
  Marker,
  TileLayer,
  ZoomControl,
  Tooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as CampaignSelectors from 'redux/reducers/campaign_reducer/selectors';
import clsx from 'clsx';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';

import useStyles from '../transport_view/styles';
import { ISegment } from 'config/campaign_wizard';
import { IDataItems } from 'services/api/transport_stations';

export const center: L.LatLngExpression = [34.9170651225385, 33.6298917314463];

const TransportDestinationsMap = () => {
  const classes = useStyles();
  const {
    destination_transport,
  } = CampaignSelectors.useGetCampaignWizardValues();

  const transportStations = CampaignSelectors.useGetTransportStations();

  const data = destination_transport?.map((stationSeg: ISegment) => {
    const station = transportStations.filter(
      (station: IDataItems) => `TRANSP_${station.code}` === stationSeg.Code
    )[0];
    return {
      segCode: stationSeg.Code,
      code: station.code,
      lat: station.lat,
      lon: station.lon,
      name: station.name,
    };
  });

  return (
    <MapContainer
      zoom={13}
      scrollWheelZoom
      style={{
        height:
          'calc(100vh - (64px + 18px + 18px + 1.75rem + 48px + 72px + 44.5px + 1rem))',
      }}
      center={center}
      doubleClickZoom={false}
      zoomControl={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <React.Fragment>
        {/** Show markers/labels of transport stations on the map */}
        {data?.map((station: any) => (
          <Marker
            position={[station.lat, station.lon]}
            icon={L.divIcon({
              className: clsx(classes.stationPin),
              html: renderToStaticMarkup(
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 321.883 321.883"
                >
                  <path
                    fill={'red'}
                    d="M160.941,0c-69.035,0-125,55.964-125,125.001c0,69.035,85.187,196.882,125,196.882c39.813,0,125-127.847,125-196.882
                  C285.941,55.964,229.977,0,160.941,0z M160.941,182.294c-36.341,0-65.801-29.46-65.801-65.802c0-36.34,29.46-65.801,65.801-65.801
                  c36.341,0,65.801,29.461,65.801,65.801C226.742,152.834,197.282,182.294,160.941,182.294z"
                  />
                </svg>
              ),
              iconSize: [33, 33],
              iconAnchor: [20, 30],
            })}
            key={station.code}
          >
            <Tooltip direction="top" offset={[1, -40]}>
              <Box>
                <Typography component="span">{station.name}</Typography>
              </Box>
            </Tooltip>
          </Marker>
        ))}
        {/** Zoom controls position on map */}
        <ZoomControl position="bottomleft" />
      </React.Fragment>
    </MapContainer>
  );
};

export default TransportDestinationsMap;
