import { LanguagesEnum } from '../../config/languages';

const Translations = {
  form_field_firstname: {
    [LanguagesEnum.English]: 'FirstName',
    [LanguagesEnum.Greek]: 'Ονομα',
    [LanguagesEnum.Russian]: 'Имя',
  },
  form_field_surname: {
    [LanguagesEnum.English]: 'Surname',
    [LanguagesEnum.Greek]: 'Επώνυμο',
    [LanguagesEnum.Russian]: 'Фамилия',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email',
    [LanguagesEnum.Greek]: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    [LanguagesEnum.Russian]: 'Электронное письмо',
  },
  form_field_company: {
    [LanguagesEnum.English]: 'Company',
    [LanguagesEnum.Greek]: 'Εταιρία',
    [LanguagesEnum.Russian]: 'Компания',
  },
  form_field_address: {
    [LanguagesEnum.English]: 'Address',
    [LanguagesEnum.Greek]: 'ΔΙΕΥΘΥΝΣΗ',
    [LanguagesEnum.Russian]: 'Адрес',
  },
  form_field_phone: {
    [LanguagesEnum.English]: 'Phone',
    [LanguagesEnum.Greek]: 'Τηλέφωνο',
    [LanguagesEnum.Russian]: 'Телефон',
  },
  form_field_gender: {
    [LanguagesEnum.English]: 'Gender',
    [LanguagesEnum.Greek]: 'Γένος',
    [LanguagesEnum.Russian]: 'Пол',
  },
  form_field_nationality: {
    [LanguagesEnum.English]: 'Nationality',
    [LanguagesEnum.Greek]: 'Ιθαγένεια',
    [LanguagesEnum.Russian]: 'Национальность',
  },
  form_field_role: {
    [LanguagesEnum.English]: 'Role',
    [LanguagesEnum.Greek]: 'Ρόλος',
    [LanguagesEnum.Russian]: 'Роль',
  },
  form_field_birthdate: {
    [LanguagesEnum.English]: 'Birth Date',
    [LanguagesEnum.Greek]: 'Ημερομηνία γέννησης',
    [LanguagesEnum.Russian]: 'Дата рождения',
  },
  form_field_registereddate: {
    [LanguagesEnum.English]: 'Registered Date',
    [LanguagesEnum.Greek]: 'Εγγεγραμμένοι Ημερομηνία',
    [LanguagesEnum.Russian]: 'Зарегистрирован Дата',
  },
  form_field_old_password: {
    [LanguagesEnum.English]: 'Old Password',
    [LanguagesEnum.Greek]: 'Παλιός Κωδικός πρόσβασης',
    [LanguagesEnum.Russian]: 'Старый Пароль',
  },
  form_field_new_password: {
    [LanguagesEnum.English]: 'New Password',
    [LanguagesEnum.Greek]: 'Νέος Κωδικός πρόσβασης',
    [LanguagesEnum.Russian]: 'Новый Пароль',
  },
  form_field_confirm_password: {
    [LanguagesEnum.English]: 'Confirm Password',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Κωδικού',
    [LanguagesEnum.Russian]: 'Подтвердить Пароль',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'Required Field',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
    [LanguagesEnum.Russian]: 'Обязательное поле',
  },
  form_error_password_match: {
    [LanguagesEnum.English]: 'Passwords do not match',
    [LanguagesEnum.Greek]: 'Οι κωδικοί πρόσβασης δεν ταιριάζουν',
    [LanguagesEnum.Russian]: 'Пароли не совпадают',
  },
  form_submit: {
    [LanguagesEnum.English]: 'Save Details',
    [LanguagesEnum.Greek]: 'Αποθήκευση Λεπτομέρειες',
    [LanguagesEnum.Russian]: 'Сохранить подробности',
  },
  tab_details: {
    [LanguagesEnum.English]: 'Details',
    [LanguagesEnum.Greek]: 'Λεπτομέρειες',
    [LanguagesEnum.Russian]: 'Подробности',
  },
  tab_security: {
    [LanguagesEnum.English]: 'Security',
    [LanguagesEnum.Greek]: 'Ασφάλεια',
    [LanguagesEnum.Russian]: 'Безопасность',
  },
};

export default Translations;
