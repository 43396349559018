import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../utils/common';
import * as TransportsService from '../../../services/api/transports';

interface IParams {
  page: number;
  pageSize: number;
  sortField: string;
  order: 'asc' | 'desc';
  publisher: string | null;
  code: string | null;
}

class FetchTransportsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRANSPORTS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_TRANSPORTS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRANSPORTS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IParams): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await TransportsService.fetchTransportsList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Fetch TransportsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchTransportsListAction: (payload: IParams) =>
    new FetchTransportsList().action(payload),
};
