import React, { ReactElement } from 'react';
import { AppSelect } from '../../../../components/molecules/form_controls';
import { Control, Controller, useForm } from 'react-hook-form';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import * as RegisterSelectors from '../../store/selectors';

interface ICountriesSelectProps {
  control: Control;
  rules: any;
  name: string;
  label: any;
  helperText: string | undefined;
  error: boolean | undefined;
}

const CountriesSelect = (props: ICountriesSelectProps) => {
  const { data } = RegisterSelectors.useGetCountries();
  const options: any = Array.isArray(data)
    ? data.map((element) => ({
        id: element.code,
        label: element.name,
      }))
    : null;

  return (
    <AppSelect
      margin="normal"
      fullWidth
      label={props.label}
      name={props.name}
      required
      error={props.error}
      control={props.control}
      helperText={props.helperText}
      rules={props.rules}
      autoComplete={props.name}
      variant="outlined"
      icon={{
        position: 'right',
        Component: <ClearAllIcon />,
      }}
      options={options || []}
    />
  );
};

export default CountriesSelect;
