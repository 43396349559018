import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_PUB_COUNTRY_CLICK_VIEW: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetPubCountryClickView'
);
export const SET_GENERAL_PUBLISHER_REVENUE_AREA_CHART: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetGeneralPublisherRevenueByAreaChart'
);
export const SET_GENERAL_PUBLISHER_FILLRATE_BAR_CHART: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetGeneralPublisherFillrateByBarChart'
);
export const SET_GENERAL_PUBLISHER_REVENUE_BAR_CHART: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetGeneralPublisherRevenueByBarChart'
);
export const SET_GENERAL_PUBLISHER_IMPRESSION_AREA_CHART: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetGeneralPublisherImpressionByAreaChart'
);
export const SET_PUBLISHER_CAMPAIGN_CLICK_VIEW: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetPublisherCampaignClickView'
);
export const SET_PUBLISHER_OS_CLICK_VIEW: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetPublisherOSClickView'
);
export const SET_PUBLISHER_FILLRATE_BY_COUNTRY: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetPublisherFillrateByCountry'
);
export const SET_PUBLISHER_TOTAL_SPENT_BY_DESTAIRPORT: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetPublisherTotalSpentByDestairport'
);
export const SET_FILTER_LIST: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetFilterList'
);

export const SET_USER_LIST: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetUserList'
);

export const SET_BUS_LIST: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetBusList'
);

export const SET_TOP_RESULTS: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetTopResults'
);

export const SET_TOP_5_RESULTS_BY_STATION: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetTop5ResultsByStation'
);

export const SET_TOP_5_STATIONS: string = typeCreatorV2(
  'PubDashboardReducer',
  'SetTop5Stations'
);
