import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationReducer',
  'SetPublisherApplication'
);
export const SET_PERSIST_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationReducer',
  'SetPersistPublisherApplication'
);
export const SET_OPEN_DIALOG: string = typeCreatorV2(
  'ApplicationReducer',
  'SetOpenDialog'
);

export const SET_ADD_PUBLISHER_APPLICATION: string = typeCreatorV2(
  'ApplicationReducer',
  'SetAddPublisherApplication'
);
