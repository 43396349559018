import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as CountriesService from '../../../services/api/countries';
import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchCountriesListInput  */

interface FetchCountriesListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchCountriesList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchCountriesListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CountriesService.fetchCountriesList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetcCountriesList Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewCountries  */

interface AddNewCountriesInput {
  code: string | null;
  name: string | null;
  capital: string | null;
  lat: number | null;
  lon: number | null;
  code3: string | null;
  fetchCountriesInput: FetchCountriesListInput;
}

class AddNewCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_COUNTRIES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewCountriesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchCountriesInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await CountriesService.addCountries(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchCountriesList().action(fetchCountriesInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_COUNTRIES,
        //   })
        // );
      } catch (error) {
        console.log('AddNewCountries Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Countries  */

interface EditCountriesInput {
  code: string | null;
  name: string | null;
  capital: string | null;
  lat: number | null;
  lon: number | null;
  code3: string | null;
  fetchCountriesInput: FetchCountriesListInput;
}

class EditCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditCountriesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchCountriesInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await CountriesService.editCountries(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchCountriesList().action(fetchCountriesInput));

        // dispatch(
        //   ModalActions.closeModalAction({
        //     modalID: ModalIDs.MODAL_ADD_EDIT_COUNTRIES,
        //   })
        // );
      } catch (error) {
        console.log('EditNotification Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Countries  */

interface ViewCountriesInput {
  id: number;
}

class ViewCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewCountriesInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await CountriesService.fetchCountriesDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('ViewCountries Error:', error); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchCountriesListAction: (payload: FetchCountriesListInput) =>
    new FetchCountriesList().action(payload),
  addNewCountriesAction: (payload: AddNewCountriesInput) =>
    new AddNewCountries().action(payload),
  editCountriesAction: (payload: EditCountriesInput) =>
    new EditCountries().action(payload),
  viewCountriesAction: (payload: ViewCountriesInput) =>
    new ViewCountries().action(payload),
};
