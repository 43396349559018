import { LanguagesEnum } from 'config/languages';

export interface ISegment {
  Code: string;
  Name: string;
}
export default interface ICampaignInfoWizard {
  campaign?: string | null;
  customer?: string | null;
  isNewClient?: boolean;
  purpose?: number | null;
  display?: number | null;
  budget?: number | null;
  start_date?: Date | null;
  end_date?: Date | null;
  publisher?: ISegment[];
  destination_country?: ISegment[];
  destination_city?: ISegment[];
  destination_transport?: ISegment[];
  gender?: Array<string>;
  age_group?: Array<string>;
  device?: Array<string>;
  mobile_environment?: Array<string>;
  operating_system?: Array<string>;
  advertisment_description?: string | null;
  landing_page?: string | null;
  advertisments?: Array<{
    id?: number;
    size: number;
    file_url: string;
    type: number;
  }>;
  category?: number | null;
}

export const CampaignInfoDefaultValue: ICampaignInfoWizard = {
  campaign: null,
  customer: null,
  isNewClient: false,
  purpose: 1, //awareness code
  display: 0,
  budget: null,
  start_date: null,
  end_date: null,
  publisher: new Array<ISegment>(),
  destination_country: new Array<ISegment>(),
  destination_city: new Array<ISegment>(),
  destination_transport: new Array<ISegment>(),
  gender: new Array<string>(),
  age_group: new Array<string>(),
  device: new Array<string>(),
  mobile_environment: new Array<string>(),
  operating_system: new Array<string>(),
  advertisment_description: null,
  landing_page: null,
  advertisments: new Array<{
    id?: number;
    size: number;
    file_url: string;
    type: number;
  }>(),
  category: null,
};
