import React from 'react';
import Datatable from '../../../../components/molecules/datatable';
import { TableColumns } from '../../configuration';
import * as FlightsReducer from '../../../../redux/reducers/flights_reducer/selectors';
import { useDispatch } from 'react-redux';
import FlightsActions from '../../../../redux/reducers/flights_reducer/actions';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';
import moment from 'moment';

import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function DatatableList(props: any) {
  const { date, setDate, publisherid, setPublisherid, dateto } = props;

  const result = FlightsReducer.useGetFlightsList();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" padding="20px">
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => exportToCSV(result.data?.items, 'flight')}
        >
          Export Excel
        </Button>
      </Box>
      <Box display="flex" flexDirection="column">
        {/* <ExcelFile element={<button>Download Data</button>}>
        <ExcelSheet data={result.data?.items} name="Flight">
          <ExcelColumn label="actpassengers" value="actpassengers" />
          <ExcelColumn label="airline" value="airline" />
          <ExcelColumn label="arrairport" value="arrairport" />
          <ExcelColumn label="clicks" value="clicks" />
          <ExcelColumn label="views" value="views" />
          <ExcelColumn label="code" value="code" />
          <ExcelColumn label="deptairport" value="deptairport" />
          <ExcelColumn label="estpassengers" value="estpassengers" />
          <ExcelColumn label="flightdate" value="flightdate" />
          <ExcelColumn label="estpassengers" value="estpassengers" />
          <ExcelColumn label="hostname" value="hostname" />
          <ExcelColumn label="name" value="name" />
          <ExcelColumn label="tail" value="tail" />
        </ExcelSheet>
      </ExcelFile> */}
        <Datatable
          loading={true}
          sorting={{
            sortField: sortField.current,
            order: sortType.current,
          }}
          paging={paging}
          data={result.data?.items || []}
          columns={TableColumns}
          changePageHandle={(selectedPage, pageSize, order, _sortField) => {
            sortType.current = order;
            sortField.current = _sortField;
            dispatch(
              FlightsActions.fetchFlightsListAction({
                sortField: _sortField,
                page: selectedPage,
                pageSize: pageSize,
                order: order,
                publisherid,
                date: date ? moment(date).format('YYYY-MM-DD') : date,
                dateto: dateto ? moment(dateto).format('YYYY-MM-DD') : dateto,
              })
            );
          }}
        />
      </Box>
    </Box>
  );
}
