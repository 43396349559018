import { Languages } from '../../config';

const Translations = {
  page_title: {
    [Languages.English]: 'Flights',
    [Languages.Greek]: 'πτήσεις',
    [Languages.Russian]: 'рейсы',
  },
  manage_registration_title: {
    [Languages.English]: 'Flights Title',
    [Languages.Greek]: 'Αεροπορικά εισιτήρια Τίτλος',
    [Languages.Russian]: 'Авиабилеты Название',
  },
  manage_registration_description: {
    [Languages.English]: 'Flights Description',
    [Languages.Greek]: 'Αεροπορικά εισιτήρια Περιγραφή',
    [Languages.Russian]: 'Авиабилеты Описание',
  },
  table_title: {
    [Languages.English]: 'Title',
    [Languages.Greek]: 'Τίτλος',
    [Languages.Russian]: 'Заголовок',
  },
  FlightsModalAddTitle: {
    [Languages.English]: 'Add',
    [Languages.Greek]: 'Προσθήκη',
    [Languages.Russian]: 'Добавлять',
  },
  FlightsModalEditTitle: {
    [Languages.English]: 'Edit',
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.Russian]: 'Редактировать',
  },
  FlightsModalViewTitle: {
    [Languages.English]: 'View',
    [Languages.Greek]: 'Θέα',
    [Languages.Russian]: 'Вид',
  },
  addEditModalButton: {
    [Languages.English]: 'Submit',
    [Languages.Greek]: 'υποβάλλουν',
    [Languages.Russian]: 'Представлять на рассмотрение',
  },
  publisher_search: {
    [Languages.English]: 'Publisher',
    [Languages.Greek]: 'Εκδότης',
    [Languages.Russian]: 'издатель',
  },
  date_search: {
    [Languages.English]: 'Date from',
    [Languages.Greek]: 'Ημερομηνία από',
    [Languages.Russian]: 'Дата, с',
  },
  date_to_search: {
    [Languages.English]: 'Date to',
    [Languages.Greek]: ' Ημερομηνία έως',
    [Languages.Russian]: 'Дата до',
  },

  button_search: {
    [Languages.English]: 'Search',
    [Languages.Greek]: 'Αναζήτηση',
    [Languages.Russian]: 'Поиск',
  },
};

export default Translations;
