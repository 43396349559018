import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import { CountriesRateOutput } from './selectors';
import * as Types from './types';

interface IState {
  countriesRateList: CountriesRateOutput;
  addCountriesRate: {
    status: StateStatus | null;
  };
  editCountriesRate: {
    status: StateStatus | null;
  };
  viewCountriesRate: {
    status: StateStatus | null;
    data: any;
  };
  deleteCountriesRate: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  countriesRateList: {
    status: null,
    data: null,
  },
  addCountriesRate: {
    status: null,
  },
  editCountriesRate: {
    status: null,
  },
  viewCountriesRate: {
    status: null,
    data: null,
  },
  deleteCountriesRate: {
    status: null,
  },
};

const CountriesRateReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_COUNTRIESRATE_LIST:
      return {
        ...state,
        countriesRateList: {
          ...state.countriesRateList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.countriesRateList.data
              : action.data,
        },
      };
    case Types.SET_ADD_COUNTRIESRATE:
      return {
        ...state,
        addCountriesRate: {
          ...state.addCountriesRate,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_COUNTRIESRATE:
      return {
        ...state,
        editCountriesRate: {
          ...state.editCountriesRate,
          status: action.status || null,
        },
      };
    case Types.SET_COUNTRIESRATE_DETAIL:
      return {
        ...state,
        viewCountriesRate: {
          ...state.viewCountriesRate,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewCountriesRate.data
              : action.data,
        },
      };
    case Types.SET_DELETE_COUNTRIESRATE:
      return {
        ...state,
        deleteCountriesRate: {
          ...state.deleteCountriesRate,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default CountriesRateReducer;
