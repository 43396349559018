import React from 'react';
import { Box, Chip, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import { LocationOn } from '@material-ui/icons';

interface ITransportStationStatisticsProps {
  seeTransportStationStatistics?: () => void;
}
const TransportStationStatistics = (
  props: ITransportStationStatisticsProps
) => {
  const theme = useTheme();
  const handleClick = (e: any) => {
    e.stopPropagation();
    props.seeTransportStationStatistics &&
      props.seeTransportStationStatistics();
  };
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Chip
        icon={<LocationOn />}
        label={'Statistics'}
        variant="outlined"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />
    </Box>
  );
};

export default TransportStationStatistics;
