import { showToastAction } from 'components/atoms/toast_message';
import { CampaignInfoDefaultValue } from 'config';
import ICampaignInfoWizard from 'config/campaign_wizard';
import _ from 'lodash';
import { ReducerKeys } from 'redux/config';
import { getState } from 'redux/store';
import CONFIGURATION, { CampaignTabs, IView } from '../../tab_stepper';
import Translations from '../../translations';
import { CampaignInfoSteps } from '../campaign_info_view/configuration';
import CampaignActions from 'redux/reducers/campaign_reducer/actions';
import { AudienceSteps } from '../audience_view/configuration';
import { AdDetailsSteps } from '../ad_details_view/configuration';

export function moveToNext(tab: number, step: number | null) {
  // console.log(tab, step);
  if (step === null) {
    // return next tab first step
    let tabSteps = CONFIGURATION.stepping[(tab + 1).toString()];
    return {
      tab: tab + 1,
      step: tabSteps && tabSteps.length > 0 ? 0 : null,
    };
  }

  step += 1;
  let tabSteps = CONFIGURATION.stepping[tab.toString()];
  if (tabSteps && tabSteps.length > 0 && tabSteps.includes(step as any)) {
    // return tab, step
    return { tab, step };
  } else {
    // return next tab first step
    let tabSteps = CONFIGURATION.stepping[(tab + 1).toString()];
    return {
      tab: tab + 1,
      step: tabSteps && tabSteps.length > 0 ? 0 : null,
    };
  }
}

export function moveToPrevious(tab: number, step: number | null) {
  if (step === null) {
    // return previous tab last step
    let tabSteps = CONFIGURATION.stepping[(tab - 1).toString()];
    return {
      tab: tab - 1,
      step:
        tabSteps && tabSteps.length > 0 ? tabSteps[tabSteps.length - 1] : null,
    };
  }

  step -= 1;
  let tabSteps = CONFIGURATION.stepping[tab.toString()];
  if (tabSteps && tabSteps.length > 0 && tabSteps.includes(step as any)) {
    return { step, tab };
  } else {
    // return previous tab last step
    let tabSteps = CONFIGURATION.stepping[(tab - 1).toString()];
    return {
      tab: tab - 1,
      step:
        tabSteps && tabSteps.length > 0 ? tabSteps[tabSteps.length - 1] : null,
    };
  }
}

export function moveToTab(tab: number) {
  let step = null;
  let tabSteps = CONFIGURATION.stepping[tab.toString()];
  if (tabSteps && tabSteps.length > 0) {
    step = tabSteps[0];
  }
  let newView = {
    tab: tab,
    step: step,
  };
  return newView;
}

export const requiredFieldsTabValid = (
  currentTab: CampaignTabs,
  dispatch: any
) => {
  const state = getState(ReducerKeys.CAMPAIGN_REDUCER);
  let data: ICampaignInfoWizard = _.get(
    state,
    'CampaignWizard.data',
    CampaignInfoDefaultValue
  );
  if (currentTab === CampaignTabs.CAMPAIGN_INFO) {
    if (!data.campaign) {
      showToastAction(dispatch, Translations.campaign_name_required, 'warning');
      return false;
    }

    if (!data.budget) {
      showToastAction(
        dispatch,
        Translations.campaign_budget_required,
        'warning'
      );
      return false;
    }

    if (data.budget && data.budget < 0) {
      showToastAction(
        dispatch,
        Translations.campaign_budget_negative,
        'warning'
      );
      return false;
    }

    if (!data.category) {
      showToastAction(
        dispatch,
        Translations.campaign_category_required,
        'warning'
      );
      return false;
    }
  }
  return true;
};

export const requiredFieldsValid = (
  currentTab: CampaignTabs,
  currentStep: number | null,
  dispatch: any
): boolean => {
  const state = getState(ReducerKeys.CAMPAIGN_REDUCER);
  let data: ICampaignInfoWizard = _.get(
    state,
    'CampaignWizard.data',
    CampaignInfoDefaultValue
  );
  // if (
  //   currentTab === CampaignTabs.CAMPAIGN_INFO &&
  //   currentStep === CampaignInfoSteps.BASIC_INFO &&
  //   !data.campaign
  // ) {
  //   showToastAction(dispatch, Translations.campaign_name_required, 'warning');
  //   return false;
  // }

  // if (
  //   currentTab === CampaignTabs.CAMPAIGN_INFO &&
  //   currentStep === CampaignInfoSteps.BUDGET_SCHEDULE &&
  //   !data.budget
  // ) {
  //   showToastAction(dispatch, Translations.campaign_budget_required, 'warning');
  //   return false;
  // }

  // if (
  //   currentTab === CampaignTabs.CAMPAIGN_INFO &&
  //   currentStep === CampaignInfoSteps.BUDGET_SCHEDULE &&
  //   data.budget &&
  //   data.budget < 0
  // ) {
  //   showToastAction(dispatch, Translations.campaign_budget_negative, 'warning');
  //   return false;
  // }
  if (
    currentTab === CampaignTabs.CAMPAIGN_INFO &&
    currentStep === CampaignInfoSteps.CAMPAIGN_INFO
  ) {
    if (!data.campaign) {
      showToastAction(dispatch, Translations.campaign_name_required, 'warning');
      return false;
    }
    if (!data.budget) {
      showToastAction(
        dispatch,
        Translations.campaign_budget_required,
        'warning'
      );
      return false;
    }
    if (data.budget && data.budget < 0) {
      showToastAction(
        dispatch,
        Translations.campaign_budget_negative,
        'warning'
      );
      return false;
    }
    if (!data.category) {
      showToastAction(
        dispatch,
        Translations.campaign_category_required,
        'warning'
      );
      return false;
    }
  }
  if (
    currentTab === CampaignTabs.DESTINATION &&
    (data?.publisher?.length === 0 ||
      data?.destination_country?.length === 0 ||
      data?.destination_city?.length === 0 ||
      data?.destination_transport?.length === 0)
  ) {
    // showToastAction(
    //   dispatch,
    //   Translations.campaign_destination_required,
    //   'warning'
    // );
    // return false;
  }
  if (currentTab === CampaignTabs.AUDIENCE) {
    // if (currentStep === AudienceSteps.GENDER && data?.gender?.length === 0) {
    //   showToastAction(
    //     dispatch,
    //     Translations.campaign_gender_required,
    //     'warning'
    //   );
    //   return false;
    // }
    // if (
    //   currentStep === AudienceSteps.AGE_GROUP &&
    //   data?.age_group?.length === 0
    // ) {
    //   showToastAction(
    //     dispatch,
    //     Translations.campaign_age_group_required,
    //     'warning'
    //   );
    //   return false;
    // }
    // if (currentStep === AudienceSteps.DEVICE && data?.device?.length === 0) {
    //   showToastAction(
    //     dispatch,
    //     Translations.campaign_device_required,
    //     'warning'
    //   );
    //   return false;
    // }
    // if (
    //   currentStep === AudienceSteps.MOBILE_ENVIRONMENT &&
    //   data?.mobile_environment?.length === 0
    // ) {
    //   showToastAction(
    //     dispatch,
    //     Translations.campaign_mobile_environment_required,
    //     'warning'
    //   );
    //   return false;
    // }
    // if (
    //   currentStep === AudienceSteps.OPERATING_SYSTEM &&
    //   data?.operating_system?.length === 0
    // ) {
    //   showToastAction(
    //     dispatch,
    //     Translations.campaign_operating_system_required,
    //     'warning'
    //   );
    //   return false;
    // }
  }
  if (
    currentTab === CampaignTabs.AD_DETAILS &&
    currentStep === AdDetailsSteps.AD_DETAIL
  ) {
    if (!data.advertisment_description) {
      showToastAction(
        dispatch,
        Translations.campaign_ad_description_required,
        'warning'
      );
      return false;
    }
    if (!data.landing_page) {
      showToastAction(
        dispatch,
        Translations.campaign_ad_landing_page_required,
        'warning'
      );
      return false;
    }
    if (data.advertisments?.length == 0) {
      showToastAction(
        dispatch,
        Translations.campaign_ad_image_video_required,
        'warning'
      );
      return false;
    }
  }
  return true;
};

// START HANDLE SAVE WIZARD DATA IN REDUX
export const handleCampaignWizardData = (
  output: any,
  view: IView,
  dispatch: any
) => {
  let data = null;
  if (view.tab === CampaignTabs.CAMPAIGN_INFO)
    data = handleCampaignInfoOutput(output, view.step as CampaignInfoSteps);
  else if (view.tab === CampaignTabs.DESTINATION) {
    const {
      publisher,
      destination_country,
      destination_city,
      destination_transport,
    } = output;
    data = {
      publisher,
      destination_country,
      destination_city,
      destination_transport,
    };
  } else if (view.tab === CampaignTabs.AUDIENCE)
    data = handleAudienceInfoOutput(output, view.step as AudienceSteps);
  else if (view.tab === CampaignTabs.AD_DETAILS)
    data = handleAdDetailsInfoOutput(output, view.step as AdDetailsSteps);
  if (data) dispatch(CampaignActions.updateCampaignWizardInfoAction(data));
};

const handleCampaignInfoOutput = (
  output: any,
  activeStep: CampaignInfoSteps
) => {
  let data = null;
  // if (activeStep === CampaignInfoSteps.BASIC_INFO) {
  //   data = {
  //     campaign: (output as any)('campaign'),
  //     customer:
  //       (output as any)('customer') === 'create'
  //         ? (output as any)('new_customer')
  //         : (output as any)('customer'),
  //     isNewClient: (output as any)('customer') === 'create',
  //   };
  // } else if (activeStep === CampaignInfoSteps.BUDGET_SCHEDULE) {
  //   data = {
  //     budget: (output as any)('budget'),
  //     start_date: (output as any)('start_date'),
  //     end_date: (output as any)('end_date'),
  //   };
  // } else if (activeStep === CampaignInfoSteps.PURPOSE) {
  //   data = {
  //     purpose: output,
  //   };
  // } else if (activeStep === CampaignInfoSteps.DISPLAY) {
  //   data = {
  //     display: output,
  //   };
  // }
  if (activeStep === CampaignInfoSteps.CAMPAIGN_INFO) {
    data = {
      campaign: (output.getValues as any)('campaign'),
      budget: (output.getValues as any)('budget'),
      start_date: (output.getValues as any)('start_date'),
      end_date: (output.getValues as any)('end_date'),
      category: (output.getValues as any)('category')?.id,
      display: output.display,
    };
  }
  console.log(data);
  return data;
};

const handleAudienceInfoOutput = (output: any, activeStep: AudienceSteps) => {
  let data = null;
  if (activeStep === AudienceSteps.GENDER) {
    data = { gender: output };
  }

  if (activeStep === AudienceSteps.AGE_GROUP) {
    data = { age_group: output };
  }

  // if (activeStep === AudienceSteps.DEVICE) {
  //   data = { device: output };
  // }

  // if (activeStep === AudienceSteps.MOBILE_ENVIRONMENT) {
  //   data = { mobile_environment: output };
  // }

  // if (activeStep === AudienceSteps.OPERATING_SYSTEM) {
  //   data = { operating_system: output };
  // }

  if (activeStep === AudienceSteps.ADVANCED) {
    data = {
      device: output.device,
      mobile_environment: output.mobile_environment,
      operating_system: output.operating_system,
    };
  }

  return data;
};

const handleAdDetailsInfoOutput = (output: any, activeStep: AdDetailsSteps) => {
  let data = null;
  // if (activeStep === AdDetailsSteps.AD_DETAIL) {
  //   data = {
  //     advertisment_description: (output as any)('advertisment_description'),
  //     landing_page: (output as any)('landing_page'),
  //   };
  // }

  // if (activeStep === AdDetailsSteps.IMAGE_VIDEO) {
  //   let ads: Array<{
  //     id?: number;
  //     size: number;
  //     file_url: string;
  //     type: number;
  //   }> = new Array();
  //   for (let index of Object.keys(output)) {
  //     ads.push(output[index]);
  //   }
  //   data = {
  //     advertisments: ads,
  //   };
  // }
  if (activeStep === AdDetailsSteps.AD_DETAIL) {
    let ads: Array<{
      id?: number;
      size: number;
      file_url: string;
      type: number;
    }> = new Array();
    for (let index of Object.keys(output.uploadedFiles)) {
      ads.push(output.uploadedFiles[index]);
    }
    data = {
      advertisment_description: (output.getValues as any)(
        'advertisment_description'
      ),
      landing_page: (output.getValues as any)('landing_page'),
      advertisments: ads,
    };
  }
  return data;
};
// END HANDLE SAVE WIZARD DATA IN REDUX
