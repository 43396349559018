import React from 'react';

export interface ISvgIconProps extends React.SVGProps<SVGSVGElement> {
  [x: string]: any;
  children?: React.ReactElement;
}

const CustomSvgIcon = ({ children, ...rest }: ISvgIconProps) => {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

export default CustomSvgIcon;
