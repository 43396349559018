import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import { RestoreOutlined } from '@material-ui/icons';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
  AppDatePicker,
} from '../../../components/molecules/form_controls';
import GridLayout from '../../../components/molecules/grid_layout';

import { useForm, useFieldArray, Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetUserList } from '../../../redux/reducers/wallet_reducer/selectors';
import { useSelectUserData } from '../../../redux/reducers/gl_auth_reducer/selectors';

import Actions from '../../../redux/reducers/wallet_reducer/actions';
import { TableDefaultFetchValues } from '../configuration';
import TranslatedText from 'components/atoms/translated_text';
import Translations from '../translations';
import useStyles from '../styles';

interface IFormData {
  userid: string | null;
  // width: number;
  // genericChartType: GenericChartTypes;
}
const defaultValues: IFormData = {
  userid: null,
};

export default function SearchArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userOption, setUserOption] = useState([]);
  const userList = useGetUserList();
  const { id } = useSelectUserData();

  useEffect(() => {
    if (userList.status === 'Success') {
      setUserOption(
        userList.data.map((item: any) => {
          return {
            id: item.id,
            label: item.Email,
          };
        })
      );
    }
  }, [userList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  useEffect(() => {
    let userid = getParameterByName('userid');
    reset({ userid });
  }, []);

  function handleReset() {
    reset(defaultValues);
    setUrlParams({ userid: id });
    dispatch(
      Actions.fetchWalletAction({
        id,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        search: {
          isSearch: false,
          values: {
            campaign: '',
            clientname: '',
            startdate: '',
            enddate: '',
          },
        },
      })
    );
    dispatch(
      Actions.fetchCampaignAction({
        id,
      })
    );
  }
  function setUrlParams(data: IFormData) {
    const { userid } = data;
    const userQuery = userid ? `userid=${userid}` : '';
    window.history.pushState('', '', `/wallet?${userQuery}`);
  }
  const onSubmit = (_data: IFormData) => {
    let { userid } = _data;
    setUrlParams({ userid });
    dispatch(
      Actions.fetchWalletAction({
        id: userid,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        search: {
          isSearch: false,
          values: {
            campaign: '',
            clientname: '',
            startdate: '',
            enddate: '',
          },
        },
      })
    );
    dispatch(
      Actions.fetchCampaignAction({
        id: userid,
      })
    );
  };
  return (
    <Card>
      <CardContent>
        <form
          // className={classes.search}
          onSubmit={handleSubmit(onSubmit)}
        >
          <GridLayout
            elements={[
              {
                id: '1',
                size: 6,
                element: (
                  <AppSelect
                    variant="outlined"
                    error={Boolean(_.get(errors, 'userid', false))}
                    helperText={_.get(errors, 'userid', '')}
                    //   className={clsx(classes['mr-12'], classes['w-1/4'])}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    fullWidth
                    options={userOption}
                    id={'userid'}
                    control={control}
                    label={
                      <TranslatedText textMap={Translations.user_search} />
                    }
                    autoComplete={'userid'}
                    name={'userid'}
                  />
                ),
              },
              {
                id: '4',
                size: 6,
                element: (
                  <div
                    className={clsx(classes.flex, classes.itemsCenter)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 55.68,
                    }}
                  >
                    <Button
                      className={classes['mr-12']}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      <TranslatedText textMap={Translations.button_search} />
                    </Button>
                    <Tooltip title="Refresh result" placement="right">
                    <Button
                      onClick={handleReset}
                      variant="contained"
                      color="primary"
                    >
                      <RestoreOutlined />
                    </Button>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
          />
        </form>
      </CardContent>
    </Card>
  );
}
