import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import useStyles from './styles';
import Swal from 'sweetalert2';
import User_Price_CalculationActions from '../../../../redux/reducers/user_price_calculation_reducer/actions';
import { TableDefaultFetchValues } from 'scenes/campaigns_list_page/configuration';
import _ from 'lodash';

interface IProps {
  rowIndex: number;
  data: string;
  dataid: string | number;
}

export default function ActionsBlock(props: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const userid = _.get(params, 'id', null);

  const handleClick = (type: 'view' | 'edit') => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_USER_PRICE_CALCULATION,
        params: {
          type,
          id: props.dataid,
        },
      })
    );
  };
  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure to delete?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          User_Price_CalculationActions.deleteUser_Price_CalculationAction({
            id: parseInt(`${props.dataid}`),
            fetchUser_Price_CalculationInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
              User_id: parseInt(userid),
            },
          })
        );
      }
    });
  };
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClick('edit')}
      >
        Edit
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        style={{
          backgroundColor: 'red',
          marginLeft: 15,
          textTransform: 'capitalize',
        }}
        color="primary"
      >
        Delete
      </Button>
    </div>
  );
}
