import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '80%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  formControl: {},
  layout_links: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  submitButton: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
  },
  progressWhite: {
    height: '20px !important',
    width: '20px !important',
    marginRight: '1rem',
  },
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
  },
  imagesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
  imageContainer: {
    width: '120px',
    minHeight: '120px',
    marginRight: '.5rem',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '120px',
    overflow: 'auto',
    borderRadius: '1rem',
    objectFit: 'cover',
  },
  imagePlaceholder: {
    width: '100%',
    height: '100%',
    background: '#e5e5e5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
  },
  removeContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  uploaderContainer: {
    width: '80%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

export default useStyles;
