import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_FLIGHTS_LIST: string = typeCreatorV2(
  'FlightsReducer',
  'SetFlightsList'
);

export const SET_PUBLISHER_LIST: string = typeCreatorV2(
  'FlightsReducer',
  'SetPublisherList'
);
