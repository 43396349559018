import { Box, LinearProgress } from '@material-ui/core';
import Datatable from 'components/molecules/datatable';
import { ISorting, Order } from 'components/molecules/datatable/configuration';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { StateStatus } from 'redux/utils/common';
import AddEditDSPModal from './components/AddEditDSPModal';
import Header from './components/Header';
import { TableColumns, TableDefaultFetchValues } from './configuration';
import DSPActions from 'redux/reducers/dsp_reducer/actions';
import {
  useGetDSPList,
  useGetParalist,
  useGetUserByRole,
} from 'redux/reducers/dsp_reducer/selectors';
import PublisherConnectionModal from './components/PublisherConnectionModal';
import AdvertisementTypeModal from './components/AdvertisementTypeModal';

const DSPListPage = () => {
  const params = useParams();
  const id = _.get(params, 'id', null);
  const getDSPList = useGetDSPList();
  const getUserByRole = useGetUserByRole();
  const getParalist = useGetParalist();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState<Object>({}); // use for later find
  const [sorting, setSorting] = React.useState<ISorting>({
    sortField: TableColumns[0].id,
    order: 'asc',
  });

  React.useEffect(() => {
    dispatch(
      DSPActions.fetchDSPListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (!getUserByRole.data) {
      dispatch(DSPActions.fetchUserByRoleAction(2));
    }
    if (!getParalist.data) {
      dispatch(
        DSPActions.fetchParalistAction({
          page: 0,
          pageSize: 9999,
          sortField: 'code',
          order: 'asc',
          groupcode: 'dsp.vasttype',
        })
      );
    }
  }, []);

  const changePageHandler = (
    selectedPage: number,
    pageSize: number,
    order: Order,
    sortField: string
  ) => {
    setSorting({ sortField, order });
    dispatch(
      DSPActions.fetchDSPListAction({
        sortField,
        order,
        page: selectedPage,
        pageSize,
      })
    );
  };
  const isLoading = getDSPList.status === StateStatus.Pending;
  return (
    <>
      <Box height={'4px'} />
      {isLoading ? <LinearProgress color="primary" /> : <Box height={'4px'} />}
      <Header />
      <Datatable
        loading={isLoading}
        sorting={sorting}
        paging={getDSPList?.data?.paging}
        data={
          getDSPList?.data?.items?.map((item) => ({
            ...item,
            useridName:
              getUserByRole?.data?.find((v: any) => v?.id === item.userid)
                ?.email ?? 'Not found',
            vasttypeName:
              getParalist?.data?.items?.find((v) => v?.code === item.vasttype)
                ?.descriptionf ?? item.vasttype,
          })) ?? []
        }
        columns={TableColumns}
        changePageHandle={changePageHandler}
      />
      <AddEditDSPModal />
      <PublisherConnectionModal />
      <AdvertisementTypeModal />
    </>
  );
};

export default withRouter(DSPListPage);
